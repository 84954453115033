const url = process.env.NEXT_PUBLIC_ZENDESK_URL as string;
const authValue = process.env.NEXT_PUBLIC_ZENDESK_TOKEN as string;

const headers = new Headers({
  "Content-Type": "application/json",
  Authorization: `Basic ${authValue}`,
});

const requestOptions: any = {
  method: "POST",
  headers: headers,
};

export const createHelpRequest = ({ comment, user, subject }) => {
  const params: any = { request: { comment: { body: comment }, subject } };

  if (user?.email) {
    params.request.requester = user;
  } else {
    params.request.requester = { name: "Anonymous customer" };
  }
  requestOptions.body = JSON.stringify(params);
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => console.log(JSON.stringify(data)))
    .catch((error) => console.log(error));
};
