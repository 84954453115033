import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export interface IShareProgress {
  geo?: string;
  dataPoint?: string;
  filters?: string;
  lnglat?: string;
  zoom?: number;
  query?: string;
  graphView?: boolean;
  GEOID?: number;
}

interface ProgressQuery {
  progressQuery: IShareProgress;
  setProgressQuery: Dispatch<SetStateAction<IShareProgress>>;
}

const ProgressQueryContext = createContext<ProgressQuery | null>(null);

export const ProgressQueryProvider = ({ children }) => {
  const [progressQuery, setProgressQuery] = useState<IShareProgress>({});

  return (
    <ProgressQueryContext.Provider value={{ progressQuery, setProgressQuery }}>
      {children}
    </ProgressQueryContext.Provider>
  );
};

const useProgressQuery = () => {
  const values = useContext(ProgressQueryContext);

  if (!values) {
    throw new Error("Cannot use ProgressQuery outside of its context");
  }

  return values;
};

export default useProgressQuery;
