import { Geo } from "./MapContext";
import { CurrentDataPoints } from "./cube";

export interface FeatureState extends CurrentDataPoints {
  GEOID: string;
  state: string;
  STATE: string;
  isInvalid: boolean;
  isFiltered: boolean;
  isSearched: boolean;
  isBlocked: boolean;
  isLabelHidden: boolean;
}

export const SourceMap: {
  [key: string]: { source: string; sourceLayer: string; nameProperty: string };
} = {
  [Geo.STATE]: {
    source: "states",
    sourceLayer: "State",
    nameProperty: "NAME",
  },
  [Geo.METRO]: {
    source: "metros",
    sourceLayer: "CBSA",
    nameProperty: "GEOID",
  },
  [Geo.COUNTY]: {
    source: "counties",
    sourceLayer: "County",
    nameProperty: "GEOID",
  },
  [Geo.ZIP]: {
    source: "zips",
    sourceLayer: "ZCTA5",
    nameProperty: "GEOID",
  },
};

export const LayerFillIds: { [key: string]: LayerFill } = {
  [Geo.STATE]: "states-fill",
  [Geo.METRO]: "metros-fill",
  [Geo.COUNTY]: "counties-fill",
  [Geo.ZIP]: "zips-fill",
};

export type LayerFill =
  | "states-fill"
  | "metros-fill"
  | "counties-fill"
  | "zips-fill";
