import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const RoundedCrossIcon: FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "13"}
      height={height || "13"}
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M11.5527 11.5548L6.49834 6.50042L11.5527 1.44602"
        stroke={color || "#5B5B5B"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.44531 11.5548L6.49971 6.50042L1.44531 1.44602"
        stroke={color || "#5B5B5B"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RoundedCrossIcon;
