import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "./firebase";

export async function getUser(user) {
  if (!user?.email) {
    return null;
  }
  const q = query(collection(db, "users"), where("email", "==", user?.email));

  const querySnapshot = await getDocs(q);
  const firstMatch = querySnapshot?.docs[0] ?? null;
  if (firstMatch) {
    return {
      ...firstMatch.data(),
      id: firstMatch.id,
    };
  }
}

/**
 * Checks if the current component is mounted.
 *
 * @returns A single boolean value indicating whether the component is mounted or not.
 */
// eslint-disable-next-line react/no-is-mounted
export function useMounted() {
  const [mounted, setMounted] = useState<boolean>(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted;
}
