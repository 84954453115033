import { useTheme } from "@emotion/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios, { AxiosResponse } from "axios";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Input } from "components/Input";
import { Text } from "components/Text";
import { useContext, useState } from "react";
import { CardUpdateContext } from "lib/context";
import { toast } from "react-toastify";

export const CardForm = ({ user, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const { setCardUpdating } = useContext(CardUpdateContext);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
    setCardUpdating("");
    toast.error("Could not update card. Try again later", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !user?.uid) {
      return;
    }

    if (!name || name.startsWith(" ")) {
      setNameError("Your name is incomplete.");
      return;
    }
    setLoading(true);
    const { error: submitError } = await elements!.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    try {
      setCardUpdating(user.paymentMethod.last4);
      const { data } = await axios.post<
        { userId: string },
        AxiosResponse<{
          data: {
            clientSecret: string;
          };
        }>
      >(`${process.env.NEXT_PUBLIC_APP_URL}/api/stripe/create-payment-method`, {
        userId: user?.uid,
      });
      const { clientSecret } = data.data;
      if (!clientSecret) {
        setLoading(false);
        return;
      }
      const { error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements!.getElement("card")!,
          billing_details: { name },
        },
      });

      if (error) {
        handleError(error);
      } else {
        onSuccess();
      }
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} css={{ width: "100%" }}>
      <Flex flex="1" gap={20} direction="column" css={{ width: "100%" }}>
        <Flex gap={4} direction="column" css={{ width: "100%" }}>
          <Text css={{ fontSize: 16 }} bolder>
            Card Holder Name
          </Text>
          <Flex direction="column" gap={1} css={{ width: "100%" }}>
            <Input
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              value={name}
              placeholder="Name"
              width="100%"
              name="name"
              css={{ ...(nameError ? { border: "2px solid #df1b41" } : {}) }}
            />
            {nameError && (
              <Text
                css={{ marginTop: "0.25rem", color: "#df1b41", fontSize: 16 }}
              >
                {nameError}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex gap={4} direction="column" css={{ width: "100%" }}>
          <Text css={{ fontSize: 16 }} bolder>
            Card Details
          </Text>
          <Flex direction="column" gap={1} css={{ width: "100%" }}>
            <div
              css={{
                width: "100%",
                padding: "12px 16px",
                borderRadius: `${theme.radii.small}`,
                fontSize: `${theme.fontSizes.default}px`,
                border: `1px solid rgba(0, 0, 0, 0.25)`,
                fontFamily: "Lato",
                display: "flex",
                minHeight: 48,
                alignItems: "center",
                background: theme.colors.white,
                input: {
                  lineHeight: "22px",
                },
              }}
            >
              <CardElement
                onChange={() => setErrorMessage("")}
                css={{
                  width: "100%",
                }}
                options={{
                  style: { base: { fontFamily: "Lato" } },
                  hidePostalCode: true,
                  disableLink: true,
                }}
              />
            </div>
            {errorMessage && (
              <Text
                css={{ marginTop: "0.25rem", color: "#df1b41", fontSize: 16 }}
              >
                {errorMessage}
              </Text>
            )}
          </Flex>
        </Flex>

        <Button variant="alternate" css={{ width: "100%", marginTop: 16 }}>
          {loading ? "Loading..." : "Save changes"}
        </Button>
      </Flex>
    </form>
  );
};
