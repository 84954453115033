import theme from "lib/theme";
import { maxBy, minBy, values, zip } from "lodash";
import { Geo } from "types/MapContext";
import { FeatureState, LayerFill } from "types/Mapbox";
import { CurrentDataPoints, Datapoints } from "types/cube";
import { filterOutliers } from "./filterHelpers";

export const getColorScale = (
  datapointValues: (CurrentDataPoints | FeatureState)[],
  exploratory: Datapoints,
  colorRange = theme.map.colorRange,
) => {
  let maxStat = maxBy(datapointValues, (value) => value[exploratory]);
  let maxVal = (maxStat && maxStat[exploratory]) || 0;

  let minStat = minBy(datapointValues, (value) => value[exploratory]);
  let minVal = (minStat && minStat[exploratory]) || 0;

  return quantileMaker(colorRange.length, minVal, maxVal, colorRange);
};

export const quantileMaker = function (
  quantileLength: number,
  minVal: number,
  maxVal: number,
  colorRange: string[],
) {
  const diff = maxVal - minVal;
  const bucket = diff / quantileLength;
  const dataScale: number[] = Array.from(
    { length: quantileLength },
    () => 0,
  ).map(
    function (val, idx, self) {
      if (self.length - 1 === idx) {
        return maxVal;
      }
      if (idx === 0) {
        return minVal;
      }
      // @ts-ignore
      return (this.acc += bucket);
    },
    { acc: minVal },
  );

  return zip(dataScale, colorRange) as [number, string][];
};

export const generateIntervalMap = (filteredFeatureData: number[]) => {
  const intervalMap: { [key: string]: number } = {};
  const intervals = [0.15, 0.3, 0.45, 0.6, 0.75, 0.9];
  intervals.forEach((interval, i) => {
    let intervalVal = percentileMaker(filteredFeatureData, interval);
    for (const [_, value] of Object.entries(intervalMap)) {
      //avoid conflicting same values when generated map color intervals
      if (value === intervalVal) {
        const randomNumber = Math.floor(Math.random() * 899999 + 100000);
        const randomDecimal = Number(0.01 + String(randomNumber));
        intervalVal = intervalVal + randomDecimal;
      }
    }
    intervalMap[i] = intervalVal;
  });

  return intervalMap;
};

export const percentileMaker = (arr: number[], q: number) => {
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const generateMapStep = (
  intervalMap: { [key: string]: number },
  colorRange: string[],
) => {
  const colorToValueArr: Array<string | number> = [];
  values(intervalMap)
    .sort((a, b) => a - b)
    .forEach((val, i) => {
      colorToValueArr.push(colorRange[i]);
      colorToValueArr.push(val);
    });

  colorToValueArr.push(colorRange[colorRange.length - 1]);

  return colorToValueArr;
};

export const generateColorSteps = (values: number[], steps: number) => {
  let minVal = minBy(values, (value) => value) || 0;
  let maxVal = maxBy(values, (value) => value) || 0;

  const stepsMap = returnAllSteps(minVal, maxVal, steps);

  return stepsMap;
};

export const generateHistoricColorSteps = (
  values: (CurrentDataPoints | FeatureState)[],
  exploratory: Datapoints,
  steps: number,
  midPoint: number = 0,
) => {
  const [minVal, maxVal] = filterOutliers(values, exploratory, [10, 90]);

  const minStepsMap = returnAllSteps(minVal, midPoint, steps);
  const maxStepsMap = returnAllSteps(midPoint, maxVal, steps);

  return [...minStepsMap.slice(0, steps - 1), ...maxStepsMap];
};

export const findFillLayer = (
  features: mapboxgl.MapboxGeoJSONFeature[],
  layerId: LayerFill,
) => {
  return features.find((feature) => feature.layer.id === layerId);
};

function returnAllSteps(min: number, max: number, totalSteps: number) {
  let steps: number[] = [];
  let distance = max - min;
  for (let i = 0; i < totalSteps; i++) {
    steps[i] = min + distance * (i / (totalSteps - 1));
  }
  return steps;
}

export const getGeoCodeFromFeature = (feature: FeatureState, geo?: Geo) => {
  return (
    feature.geo_code ||
    (geo === Geo.COUNTY
      ? `${feature.GEOID}`.padStart(5, "0").substring(2)
      : feature.GEOID)
  );
};
