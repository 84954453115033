import { Modal } from "antd";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { useUserData } from "hooks/useUser";
import { useState } from "react";
import { toast } from "react-toastify";
import { createHelpRequest } from "services/ZendeskService";

export const FeedbackModal = ({ show, setShow, texts }) => {
  const user = useUserData();
  const [comment, setComment] = useState<string>("");

  return (
    <Modal
      open={show}
      footer={null}
      bodyStyle={{ padding: 15 }}
      title={texts.title || "Contact Us"}
      destroyOnClose
      zIndex={1500}
      onCancel={() => setShow(false)}
    >
      <Flex direction="column" justify="center" align="center">
        <textarea
          css={(theme) => ({
            borderRadius: theme.radii.small,
            border: `1px solid ${theme.colors.inputBorder}`,
            width: "90%",
            padding: 5,
            ":focus": {
              outline: "none",
            },
            resize: "unset",
          })}
          rows={10}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={texts.placeholder}
        />
        <Button
          css={(theme) => ({
            height: 48,
            background: theme.colors.primary,
            borderRadius: theme.radii.small,
            width: "90%",
            margin: "15px 0",
          })}
          variant="primary"
          disabled={!comment}
          onClick={() => {
            createHelpRequest({
              comment,
              user: { email: user?.email, name: user?.name },
              subject: texts.subject,
            });
            setShow(false);
            setComment("");
            toast.success("Email sent to support");
          }}
        >
          Send Feedback
        </Button>
      </Flex>
    </Modal>
  );
};
