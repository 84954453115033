import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { getRelativePathname } from "lib/getRelativePathname";
import { useUserData } from "hooks/useUser";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import PlanService from "services/PlanService";
import posthog from "posthog-js";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { splitPlanName } from "lib/splitPlanName";
import { PlanCycle } from "backend/utils/plan";
import moment from "moment";

export const DowngradeConfirmation = () => {
  const user = useUserData();
  const theme = useTheme();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { plans } = useContext(PlanModalContext);

  const plan = useMemo(() => {
    return plans.find((pln) => pln.id === router.query.selectedPlanId);
  }, [router.query.selectedPlanId, plans]);

  const handleYes = async () => {
    if (router.query.selectedPlanId) {
      if (loading) return;
      try {
        setLoading(true);
        const currentPlan = `${user.plan} ${user.planCycle}`;
        await PlanService.changePlan(
          user.id!,
          router?.query.selectedPlanId as string,
        );
        posthog.capture("Plan downgraded", {
          nextBillingCycleDate: moment
            .unix(user.nextPaymentDate!)
            .format("MM/DD/YYYY"),
          newPlanName: plan?.name,
          changedPlanFrom: currentPlan,
          cadence: splitPlanName(plan?.name!).planCycle || PlanCycle.Monthly,
          amount: (plan?.amount! / 100).toFixed(2),
        });
        router.replace(
          `${getRelativePathname(router)}?showDowngradeModal=true`,
        );
      } catch (error: any) {
        toast(error.message, {
          type: "error",
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  const handleClose = () => {
    router.replace(getRelativePathname(router));
  };

  return (
    <Modal
      overlayStyle={{ overflow: "auto", padding: "34px 12px" }}
      contentStyle={{
        maxWidth: 360,
        background: "white",
        width: "100%",
        padding: "15px 24px",
        border: `1px solid ${theme.colors.radioBorder}`,
        overflow: "unset",
      }}
      isOpen={!!router.query.downgradeConfirmationModal}
    >
      <Flex
        justify="center"
        align="center"
        css={{
          borderRadius: theme.radii.smaller,
          background: theme.colors.lightBg,
          height: 30,
          position: "absolute",
          width: 30,
          right: 15,
          top: 15,
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
      </Flex>
      <div
        css={{
          marginTop: -45,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            border: "10px solid white",
            borderRadius: theme.radii.full,
          }}
        >
          <Image
            alt="headshot"
            src="/nick-headshot.png"
            css={{ borderRadius: theme.radii.full }}
            width={96}
            height={96}
          />
        </div>
      </div>
      <Flex direction="column" align="center">
        <h1
          css={{
            fontSize: theme.fontSizes.larger,
            fontWeight: theme.fontWeights.heading,
            margin: 0,
            textAlign: "center",
          }}
        >
          Are you sure you want to downgrade your plan?
        </h1>
      </Flex>
      <Flex gap={16} align="center" css={{ marginTop: 30 }}>
        <Button
          onClick={handleYes}
          variant="secondary"
          css={{
            flex: 1,
            border: `1px solid ${theme.colors.primary}`,
            color: theme.colors.primary,
          }}
        >
          {loading ? "Loading..." : "Yes"}
        </Button>
        <Button css={{ flex: 1 }} variant="alternate" onClick={handleClose}>
          No
        </Button>
      </Flex>
    </Modal>
  );
};
