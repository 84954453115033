import { Dispatch, SetStateAction } from "react";

export interface IMapContext {
  scale: Scale[];
  mapStyle: string;
  geoCount: number;
  tableView: boolean;
  graphView: boolean;
  showToolTip: boolean;
  showShareModal: boolean;
  isTabletOrMobile: boolean;
  isShareUrlRendered: boolean;
  graphData: any;
  setScale: Dispatch<SetStateAction<Scale[]>>;
  setMapStyle: Dispatch<SetStateAction<string>>;
  setGeoCount: Dispatch<SetStateAction<number>>;
  setTableView: Dispatch<SetStateAction<boolean>>;
  setShowGraphView: Dispatch<SetStateAction<boolean>>;
  setShowToolTip: Dispatch<SetStateAction<boolean>>;
  setShowShareModal: Dispatch<SetStateAction<boolean>>;
  setIsShareUrlRendered: Dispatch<SetStateAction<boolean>>;
}

export enum Geo {
  STATE = "state",
  METRO = "metro",
  COUNTY = "county",
  ZIP = "zip",
}

export enum SearchFrom {
  LANDING = "Landing Page",
  MAP = "Map",
}

export enum MapBoxGeoType {
  STATE = "region",
  COUNTY = "district",
  CITY = "place",
  ZIP = "postcode",
}

export const MapboxGeoMap = {
  region: Geo.STATE,
  district: Geo.COUNTY,
  place: Geo.ZIP,
  postcode: Geo.ZIP,
};

export const GeoMapboxMap = {
  [Geo.STATE]: "region",
  [Geo.COUNTY]: "district",
  [Geo.ZIP]: "postcode",
};

export interface SearchId {
  id?: string;
  lat?: number;
  long?: number;
  zoom?: number;
  duration?: number;
}

export type Scale = [number, string];
