import { useTheme } from "@emotion/react";
import { Price } from "backend/types/stripe.type";
import { Plan } from "backend/utils/plan";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import React from "react";

type Props = {
  color?: string;
  type: Plan;
  rest?: typeof Flex;
  textProps?: React.ComponentProps<typeof Text>;
  headingProps?: React.ComponentProps<typeof Text>;
  textGap?: number;
  pointsContainerProps?: React.ComponentProps<typeof Flex>;
  planObject?: Price;
};

const freePerks = [
  "One Metro area",
  "Subscription to the Reventure Real Estate Newsletter",
  "Access real-time data on Home Prices, Home Price Growth, and Inventory each month",
];

const premiumPerks = [
  <>
    <b>Unlock 40 Premium Data Points</b> to gain deeper insights into the trends
    of home prices in your market.
  </>,
  <>
    <b>Home Price Forecasts Score</b> is available for{" "}
    <b>all 50 States, 500 Metro Areas, and 30,000 ZIP Codes</b>, ensuring you
    stay ahead in any region.
  </>,
  <>
    <b>Enjoy 5 Free Market Reports</b>, giving you valuable, in-depth analysis
    to guide your decisions.
  </>,
  <>
    <b>Enjoy 60 Free Market Reports</b>, giving you valuable, in-depth analysis
    to guide your decisions.
  </>,
  <>
    <b>Seamlessly Download Data into Excel</b> for easy access
  </>,
];
export const PlanInformation = ({
  type,
  color,
  textProps,
  headingProps,
  textGap = 24,
  pointsContainerProps,
  planObject,
  ...rest
}: Props) => {
  const theme = useTheme();

  const perks =
    type === Plan.Free
      ? freePerks
      : planObject?.name === Plan.Premium_Monthly
      ? premiumPerks.filter((_, index) => index !== 3)
      : premiumPerks.filter((_, index) => index !== 2);

  return (
    <Flex direction="column" {...rest}>
      <Text heading css={{ color: color || "#000" }} bolder {...headingProps}>
        What&apos;s included
      </Text>
      <Flex
        {...pointsContainerProps}
        css={{ marginTop: 16 }}
        direction="column"
        gap={textGap}
      >
        {perks.map((perk, index) => (
          <Flex key={index} align="center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill={theme.colors.primary}
              >
                <path
                  d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
                  fill={theme.colors.primary}
                  fillOpacity="100"
                />
                <path
                  d="M14.7152 6L15.3333 6.59627L7.91515 13.7516L7.60606 14L7.29697 13.7516L4 10.5714L4.61818 9.97516L7.60606 12.882L14.7152 6Z"
                  fill={theme.colors.brightGreen}
                  stroke={theme.colors.white}
                />
              </svg>
            </div>
            <Text
              css={{ lineHeight: "28px", color: color || theme.colors.black }}
              {...textProps}
            >
              {perk}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
