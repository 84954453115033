import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { GroupedExploratoryOption } from "hooks/useGeoExploratory";
import Image from "next/image";

export const DatapointCategory = ({
  category,
}: {
  category: GroupedExploratoryOption;
}) => {
  return (
    <Flex gap={10} direction="column">
      <Text bold fontSize="large">
        {category.label}
      </Text>
      {category.options.map((datapoint) => (
        <Flex
          key={datapoint.value}
          css={{ maxWidth: 453, width: "100%" }}
          gap={5}
        >
          <Image
            css={{ marginTop: 4 }}
            height={12}
            width={12}
            alt="check-icon"
            src="/check-outlined.svg"
          />
          <Text css={{ lineHeight: "normal" }}>{datapoint.label}</Text>
        </Flex>
      ))}
    </Flex>
  );
};
