import { useTheme } from "@emotion/react";
import { Modal } from "antd";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import RoundedCrossIcon from "components/icons/RoundedCrossIcon";
import { useUserData } from "hooks/useUser";
import useWelcomePopUp from "hooks/useWelcomePopUp";
import { useRouter } from "next/router";
import { useEffect } from "react";

const WelcomePopUp = () => {
  const { showWelcomePopup, setShowWelcomePopup } = useWelcomePopUp();
  const theme = useTheme();
  const user = useUserData();
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.startsWith("/checkout")) {
      setShowWelcomePopup(false);
    }
  }, [router.asPath]);

  return (
    <Modal
      open={showWelcomePopup && !router.asPath.startsWith("/checkout")}
      closable={false}
      width={500}
      footer={null}
      bodyStyle={{ padding: 15 }}
      destroyOnClose
    >
      <Flex
        justify="center"
        align="center"
        onClick={() => setShowWelcomePopup(false)}
        css={{
          position: "absolute",
          top: -15,
          right: -15,
          width: 30,
          height: 30,
          borderRadius: theme.radii.full,
          backgroundColor: theme.colors.white,
          filter: `drop-shadow(-6px 6px 12px rgba(0, 0, 0, 0.34))`,
          cursor: "pointer",

          ":hover": {
            backgroundColor: theme.colors.lightBg,
          },
        }}
      >
        <RoundedCrossIcon />
      </Flex>

      <Flex
        direction="column"
        justify="center"
        align="center"
        css={{
          color: theme.colors.black,
          fontSize: theme.fontSizes.heading,
          fontWeight: theme.fontWeights.heading,
        }}
      >
        <iframe
          width={"100%"}
          height={264}
          src={"https://www.youtube.com/embed/KlI5vX9gQTk"}
          css={{ border: "none", marginBottom: theme.margin.default }}
        />

        <span>Hi {user.name} 👋</span>
        <span>Welcome to Reventure App</span>
        <span
          css={{
            color: "rgba(0, 0, 0, 0.72)",
            fontSize: theme.fontSizes.medium,
          }}
        >
          Our founder Nick has a message for you
        </span>

        <Button
          css={{
            marginTop: theme.margin.medium,
            backgroundColor: theme.colors.black,
            color: theme.colors.white,
            width: "98%",
          }}
          onClick={() => setShowWelcomePopup(false)}
        >
          Get Started
        </Button>
      </Flex>
    </Modal>
  );
};

export default WelcomePopUp;
