export const zipMetro = {
  "1001": "44140",
  "1002": "44140",
  "1005": "49340",
  "1007": "44140",
  "1008": "44140",
  "1010": "44140",
  "1011": "44140",
  "1012": "44140",
  "1013": "44140",
  "1020": "44140",
  "1022": "44140",
  "1026": "44140",
  "1027": "44140",
  "1028": "44140",
  "1030": "44140",
  "1031": "49340",
  "1032": "44140",
  "1033": "44140",
  "1034": "44140",
  "1035": "44140",
  "1036": "44140",
  "1037": "49340",
  "1038": "44140",
  "1039": "44140",
  "1040": "44140",
  "1050": "44140",
  "1053": "44140",
  "1054": "44140",
  "1056": "44140",
  "1057": "44140",
  "1060": "44140",
  "1062": "44140",
  "1068": "49340",
  "1069": "44140",
  "1070": "44140",
  "1071": "44140",
  "1072": "44140",
  "1073": "44140",
  "1075": "44140",
  "1077": "44140",
  "1080": "44140",
  "1081": "44140",
  "1082": "44140",
  "1083": "49340",
  "1085": "44140",
  "1088": "44140",
  "1089": "44140",
  "1092": "49340",
  "1093": "44140",
  "1095": "44140",
  "1096": "44140",
  "1098": "44140",
  "1103": "44140",
  "1104": "44140",
  "1105": "44140",
  "1106": "44140",
  "1107": "44140",
  "1108": "44140",
  "1109": "44140",
  "1118": "44140",
  "1119": "44140",
  "1128": "44140",
  "1129": "44140",
  "1151": "44140",
  "1201": "38340",
  "1220": "38340",
  "1222": "38340",
  "1223": "38340",
  "1224": "38340",
  "1225": "38340",
  "1226": "38340",
  "1230": "38340",
  "1235": "38340",
  "1236": "38340",
  "1237": "38340",
  "1238": "38340",
  "1240": "38340",
  "1243": "44140",
  "1245": "38340",
  "1247": "38340",
  "1253": "38340",
  "1254": "38340",
  "1255": "38340",
  "1256": "38340",
  "1257": "38340",
  "1258": "38340",
  "1259": "38340",
  "1262": "38340",
  "1264": "38340",
  "1266": "38340",
  "1267": "38340",
  "1270": "38340",
  "1301": "44140",
  "1330": "44140",
  "1331": "49340",
  "1337": "44140",
  "1338": "44140",
  "1339": "44140",
  "1340": "44140",
  "1341": "44140",
  "1342": "44140",
  "1344": "44140",
  "1346": "44140",
  "1349": "44140",
  "1351": "44140",
  "1354": "44140",
  "1355": "44140",
  "1360": "44140",
  "1364": "44140",
  "1366": "49340",
  "1367": "44140",
  "1368": "49340",
  "1370": "44140",
  "1373": "44140",
  "1375": "44140",
  "1376": "44140",
  "1378": "44140",
  "1379": "44140",
  "1420": "49340",
  "1430": "49340",
  "1431": "14460",
  "1432": "14460",
  "1434": "49340",
  "1436": "49340",
  "1440": "49340",
  "1450": "14460",
  "1451": "49340",
  "1452": "49340",
  "1453": "49340",
  "1460": "14460",
  "1462": "49340",
  "1463": "14460",
  "1464": "14460",
  "1468": "49340",
  "1469": "14460",
  "1473": "49340",
  "1474": "14460",
  "1475": "49340",
  "1501": "49340",
  "1503": "49340",
  "1504": "49340",
  "1505": "49340",
  "1506": "49340",
  "1507": "49340",
  "1510": "49340",
  "1515": "49340",
  "1516": "49340",
  "1518": "49340",
  "1519": "49340",
  "1520": "49340",
  "1521": "44140",
  "1522": "49340",
  "1523": "49340",
  "1524": "49340",
  "1527": "49340",
  "1529": "49340",
  "1531": "49340",
  "1532": "49340",
  "1534": "49340",
  "1535": "49340",
  "1536": "49340",
  "1537": "49340",
  "1540": "49340",
  "1541": "49340",
  "1542": "49340",
  "1543": "49340",
  "1545": "49340",
  "1550": "49340",
  "1560": "49340",
  "1562": "49340",
  "1564": "49340",
  "1566": "49340",
  "1568": "49340",
  "1569": "49340",
  "1570": "49340",
  "1571": "49340",
  "1581": "49340",
  "1583": "49340",
  "1585": "49340",
  "1588": "49340",
  "1590": "49340",
  "1602": "49340",
  "1603": "49340",
  "1604": "49340",
  "1605": "49340",
  "1606": "49340",
  "1607": "49340",
  "1608": "49340",
  "1609": "49340",
  "1610": "49340",
  "1611": "49340",
  "1612": "49340",
  "1701": "14460",
  "1702": "14460",
  "1718": "14460",
  "1719": "14460",
  "1720": "14460",
  "1721": "14460",
  "1730": "14460",
  "1740": "49340",
  "1741": "14460",
  "1742": "14460",
  "1745": "49340",
  "1746": "14460",
  "1747": "49340",
  "1748": "14460",
  "1749": "14460",
  "1752": "14460",
  "1754": "14460",
  "1756": "49340",
  "1757": "49340",
  "1760": "14460",
  "1770": "14460",
  "1772": "49340",
  "1773": "14460",
  "1775": "14460",
  "1776": "14460",
  "1778": "14460",
  "1801": "14460",
  "1803": "14460",
  "1810": "14460",
  "1821": "14460",
  "1824": "14460",
  "1826": "14460",
  "1827": "14460",
  "1830": "14460",
  "1832": "14460",
  "1833": "14460",
  "1834": "14460",
  "1835": "14460",
  "1840": "14460",
  "1841": "14460",
  "1843": "14460",
  "1844": "14460",
  "1845": "14460",
  "1850": "14460",
  "1851": "14460",
  "1852": "14460",
  "1854": "14460",
  "1860": "14460",
  "1862": "14460",
  "1863": "14460",
  "1864": "14460",
  "1867": "14460",
  "1876": "14460",
  "1879": "14460",
  "1880": "14460",
  "1886": "14460",
  "1887": "14460",
  "1890": "14460",
  "1901": "14460",
  "1902": "14460",
  "1904": "14460",
  "1905": "14460",
  "1906": "14460",
  "1907": "14460",
  "1908": "14460",
  "1913": "14460",
  "1915": "14460",
  "1921": "14460",
  "1922": "14460",
  "1923": "14460",
  "1929": "14460",
  "1930": "14460",
  "1938": "14460",
  "1940": "14460",
  "1944": "14460",
  "1945": "14460",
  "1949": "14460",
  "1950": "14460",
  "1951": "14460",
  "1952": "14460",
  "1960": "14460",
  "1966": "14460",
  "1969": "14460",
  "1970": "14460",
  "1982": "14460",
  "1983": "14460",
  "1984": "14460",
  "1985": "14460",
  "2019": "14460",
  "2021": "14460",
  "2025": "14460",
  "2026": "14460",
  "2030": "14460",
  "2032": "14460",
  "2035": "14460",
  "2038": "14460",
  "2043": "14460",
  "2045": "14460",
  "2048": "39300",
  "2050": "14460",
  "2052": "14460",
  "2053": "14460",
  "2054": "14460",
  "2056": "14460",
  "2061": "14460",
  "2062": "14460",
  "2066": "14460",
  "2067": "14460",
  "2071": "14460",
  "2072": "14460",
  "2081": "14460",
  "2090": "14460",
  "2093": "14460",
  "2108": "14460",
  "2109": "14460",
  "2110": "14460",
  "2111": "14460",
  "2113": "14460",
  "2114": "14460",
  "2115": "14460",
  "2116": "14460",
  "2118": "14460",
  "2119": "14460",
  "2120": "14460",
  "2121": "14460",
  "2122": "14460",
  "2124": "14460",
  "2125": "14460",
  "2126": "14460",
  "2127": "14460",
  "2128": "14460",
  "2129": "14460",
  "2130": "14460",
  "2131": "14460",
  "2132": "14460",
  "2134": "14460",
  "2135": "14460",
  "2136": "14460",
  "2138": "14460",
  "2139": "14460",
  "2140": "14460",
  "2141": "14460",
  "2142": "14460",
  "2143": "14460",
  "2144": "14460",
  "2145": "14460",
  "2148": "14460",
  "2149": "14460",
  "2150": "14460",
  "2151": "14460",
  "2152": "14460",
  "2155": "14460",
  "2169": "14460",
  "2170": "14460",
  "2171": "14460",
  "2176": "14460",
  "2180": "14460",
  "2184": "14460",
  "2186": "14460",
  "2188": "14460",
  "2189": "14460",
  "2190": "14460",
  "2191": "14460",
  "2199": "14460",
  "2210": "14460",
  "2215": "14460",
  "2301": "14460",
  "2302": "14460",
  "2322": "14460",
  "2324": "14460",
  "2330": "14460",
  "2332": "14460",
  "2333": "14460",
  "2338": "14460",
  "2339": "14460",
  "2341": "14460",
  "2343": "14460",
  "2346": "14460",
  "2347": "14460",
  "2351": "14460",
  "2356": "39300",
  "2359": "14460",
  "2360": "14460",
  "2364": "14460",
  "2367": "14460",
  "2368": "14460",
  "2370": "14460",
  "2375": "39300",
  "2379": "14460",
  "2382": "14460",
  "2420": "14460",
  "2421": "14460",
  "2445": "14460",
  "2446": "14460",
  "2451": "14460",
  "2452": "14460",
  "2453": "14460",
  "2458": "14460",
  "2459": "14460",
  "2460": "14460",
  "2461": "14460",
  "2462": "14460",
  "2464": "14460",
  "2465": "14460",
  "2466": "14460",
  "2467": "14460",
  "2468": "14460",
  "2472": "14460",
  "2474": "14460",
  "2476": "14460",
  "2478": "14460",
  "2481": "14460",
  "2482": "14460",
  "2492": "14460",
  "2493": "14460",
  "2494": "14460",
  "2532": "12700",
  "2535": "47240",
  "2536": "12700",
  "2537": "12700",
  "2538": "14460",
  "2539": "47240",
  "2540": "12700",
  "2543": "12700",
  "2556": "12700",
  "2557": "47240",
  "2559": "12700",
  "2562": "12700",
  "2563": "12700",
  "2568": "47240",
  "2571": "14460",
  "2575": "47240",
  "2576": "14460",
  "2601": "12700",
  "2630": "12700",
  "2631": "12700",
  "2632": "12700",
  "2633": "12700",
  "2635": "12700",
  "2638": "12700",
  "2639": "12700",
  "2642": "12700",
  "2644": "12700",
  "2645": "12700",
  "2646": "12700",
  "2648": "12700",
  "2649": "12700",
  "2650": "12700",
  "2652": "12700",
  "2653": "12700",
  "2655": "12700",
  "2657": "12700",
  "2659": "12700",
  "2660": "12700",
  "2664": "12700",
  "2666": "12700",
  "2667": "12700",
  "2668": "12700",
  "2670": "12700",
  "2671": "12700",
  "2673": "12700",
  "2675": "12700",
  "2702": "39300",
  "2703": "39300",
  "2713": "47240",
  "2715": "39300",
  "2717": "39300",
  "2718": "39300",
  "2719": "39300",
  "2720": "39300",
  "2721": "39300",
  "2723": "39300",
  "2724": "39300",
  "2725": "39300",
  "2726": "39300",
  "2738": "14460",
  "2739": "14460",
  "2740": "39300",
  "2743": "39300",
  "2744": "39300",
  "2745": "39300",
  "2746": "39300",
  "2747": "39300",
  "2748": "39300",
  "2760": "39300",
  "2762": "14460",
  "2763": "39300",
  "2764": "39300",
  "2766": "39300",
  "2767": "39300",
  "2769": "39300",
  "2770": "14460",
  "2771": "39300",
  "2777": "39300",
  "2779": "39300",
  "2780": "39300",
  "2790": "39300",
  "2791": "39300",
  "2804": "39300",
  "2806": "39300",
  "2807": "39300",
  "2808": "39300",
  "2809": "39300",
  "2812": "39300",
  "2813": "39300",
  "2814": "39300",
  "2815": "39300",
  "2816": "39300",
  "2817": "39300",
  "2818": "39300",
  "2822": "39300",
  "2825": "39300",
  "2826": "39300",
  "2827": "39300",
  "2828": "39300",
  "2830": "39300",
  "2831": "39300",
  "2832": "39300",
  "2833": "39300",
  "2835": "39300",
  "2837": "39300",
  "2838": "39300",
  "2839": "39300",
  "2840": "39300",
  "2842": "39300",
  "2852": "39300",
  "2857": "39300",
  "2858": "39300",
  "2859": "39300",
  "2860": "39300",
  "2861": "39300",
  "2863": "39300",
  "2864": "39300",
  "2865": "39300",
  "2871": "39300",
  "2873": "39300",
  "2874": "39300",
  "2878": "39300",
  "2879": "39300",
  "2881": "39300",
  "2882": "39300",
  "2885": "39300",
  "2886": "39300",
  "2888": "39300",
  "2889": "39300",
  "2891": "39300",
  "2892": "39300",
  "2893": "39300",
  "2894": "39300",
  "2895": "39300",
  "2896": "39300",
  "2898": "39300",
  "2903": "39300",
  "2904": "39300",
  "2905": "39300",
  "2906": "39300",
  "2907": "39300",
  "2908": "39300",
  "2909": "39300",
  "2910": "39300",
  "2911": "39300",
  "2914": "39300",
  "2915": "39300",
  "2916": "39300",
  "2917": "39300",
  "2919": "39300",
  "2920": "39300",
  "2921": "39300",
  "3031": "31700",
  "3032": "14460",
  "3033": "31700",
  "3034": "14460",
  "3036": "14460",
  "3037": "14460",
  "3038": "14460",
  "3042": "14460",
  "3043": "31700",
  "3044": "14460",
  "3045": "31700",
  "3046": "18180",
  "3047": "31700",
  "3048": "31700",
  "3049": "31700",
  "3051": "31700",
  "3052": "31700",
  "3053": "14460",
  "3054": "31700",
  "3055": "31700",
  "3057": "31700",
  "3060": "31700",
  "3062": "31700",
  "3063": "31700",
  "3064": "31700",
  "3070": "31700",
  "3071": "31700",
  "3076": "31700",
  "3077": "14460",
  "3079": "14460",
  "3082": "31700",
  "3084": "31700",
  "3086": "31700",
  "3087": "14460",
  "3101": "31700",
  "3102": "31700",
  "3103": "31700",
  "3104": "31700",
  "3106": "18180",
  "3109": "31700",
  "3110": "31700",
  "3215": "30100",
  "3216": "18180",
  "3217": "30100",
  "3218": "29060",
  "3220": "29060",
  "3221": "18180",
  "3222": "30100",
  "3223": "30100",
  "3224": "18180",
  "3225": "29060",
  "3226": "29060",
  "3229": "18180",
  "3230": "18180",
  "3234": "18180",
  "3235": "18180",
  "3237": "29060",
  "3240": "30100",
  "3241": "30100",
  "3242": "18180",
  "3243": "18180",
  "3244": "31700",
  "3245": "30100",
  "3246": "29060",
  "3249": "29060",
  "3251": "30100",
  "3253": "29060",
  "3255": "18180",
  "3256": "29060",
  "3257": "18180",
  "3258": "18180",
  "3261": "14460",
  "3262": "30100",
  "3263": "18180",
  "3264": "30100",
  "3266": "30100",
  "3268": "18180",
  "3269": "29060",
  "3275": "18180",
  "3276": "18180",
  "3278": "18180",
  "3279": "30100",
  "3280": "30100",
  "3281": "31700",
  "3282": "30100",
  "3284": "30100",
  "3285": "30100",
  "3287": "18180",
  "3290": "14460",
  "3293": "30100",
  "3301": "18180",
  "3303": "18180",
  "3304": "18180",
  "3307": "18180",
  "3431": "28300",
  "3440": "31700",
  "3441": "28300",
  "3442": "31700",
  "3443": "28300",
  "3444": "28300",
  "3445": "28300",
  "3446": "28300",
  "3447": "28300",
  "3448": "28300",
  "3449": "31700",
  "3450": "28300",
  "3451": "28300",
  "3452": "28300",
  "3455": "28300",
  "3456": "28300",
  "3457": "28300",
  "3458": "31700",
  "3461": "28300",
  "3462": "28300",
  "3464": "28300",
  "3465": "28300",
  "3466": "28300",
  "3467": "28300",
  "3470": "28300",
  "3561": "30100",
  "3570": "13620",
  "3574": "30100",
  "3576": "13620",
  "3579": "13620",
  "3580": "30100",
  "3581": "13620",
  "3582": "13620",
  "3583": "13620",
  "3584": "13620",
  "3585": "30100",
  "3586": "30100",
  "3588": "13620",
  "3590": "13620",
  "3592": "13620",
  "3593": "13620",
  "3598": "13620",
  "3601": "30100",
  "3602": "28300",
  "3603": "30100",
  "3605": "30100",
  "3607": "30100",
  "3608": "28300",
  "3609": "28300",
  "3740": "30100",
  "3741": "30100",
  "3743": "30100",
  "3745": "30100",
  "3748": "30100",
  "3750": "30100",
  "3752": "30100",
  "3753": "30100",
  "3755": "30100",
  "3765": "30100",
  "3766": "30100",
  "3768": "30100",
  "3770": "30100",
  "3771": "30100",
  "3773": "30100",
  "3774": "30100",
  "3777": "30100",
  "3779": "30100",
  "3780": "30100",
  "3781": "30100",
  "3782": "30100",
  "3784": "30100",
  "3785": "30100",
  "3801": "14460",
  "3809": "29060",
  "3810": "29060",
  "3811": "14460",
  "3819": "14460",
  "3820": "14460",
  "3823": "14460",
  "3824": "14460",
  "3825": "14460",
  "3826": "14460",
  "3827": "14460",
  "3833": "14460",
  "3835": "14460",
  "3837": "29060",
  "3839": "14460",
  "3840": "14460",
  "3841": "14460",
  "3842": "14460",
  "3844": "14460",
  "3848": "14460",
  "3851": "14460",
  "3852": "14460",
  "3854": "14460",
  "3855": "14460",
  "3856": "14460",
  "3857": "14460",
  "3858": "14460",
  "3861": "14460",
  "3862": "14460",
  "3865": "14460",
  "3867": "14460",
  "3868": "14460",
  "3869": "14460",
  "3870": "14460",
  "3871": "14460",
  "3873": "14460",
  "3874": "14460",
  "3878": "14460",
  "3884": "14460",
  "3885": "14460",
  "3887": "14460",
  "3901": "38860",
  "3902": "38860",
  "3903": "38860",
  "3904": "38860",
  "3905": "38860",
  "3906": "38860",
  "3907": "38860",
  "3908": "38860",
  "3909": "38860",
  "4001": "38860",
  "4002": "38860",
  "4003": "38860",
  "4005": "38860",
  "4008": "38860",
  "4009": "38860",
  "4011": "38860",
  "4015": "38860",
  "4017": "38860",
  "4020": "38860",
  "4021": "38860",
  "4027": "38860",
  "4029": "38860",
  "4030": "38860",
  "4032": "38860",
  "4038": "38860",
  "4039": "38860",
  "4040": "38860",
  "4042": "38860",
  "4043": "38860",
  "4046": "38860",
  "4047": "38860",
  "4048": "38860",
  "4049": "38860",
  "4050": "38860",
  "4055": "38860",
  "4061": "38860",
  "4062": "38860",
  "4064": "38860",
  "4066": "38860",
  "4069": "38860",
  "4071": "38860",
  "4072": "38860",
  "4073": "38860",
  "4074": "38860",
  "4076": "38860",
  "4079": "38860",
  "4083": "38860",
  "4084": "38860",
  "4085": "38860",
  "4086": "38860",
  "4087": "38860",
  "4090": "38860",
  "4092": "38860",
  "4093": "38860",
  "4095": "38860",
  "4096": "38860",
  "4097": "38860",
  "4101": "38860",
  "4102": "38860",
  "4103": "38860",
  "4105": "38860",
  "4106": "38860",
  "4107": "38860",
  "4108": "38860",
  "4109": "38860",
  "4110": "38860",
  "4210": "30340",
  "4222": "30340",
  "4236": "30340",
  "4240": "30340",
  "4250": "30340",
  "4252": "30340",
  "4253": "30340",
  "4254": "30340",
  "4256": "30340",
  "4258": "30340",
  "4259": "12300",
  "4260": "38860",
  "4263": "30340",
  "4265": "12300",
  "4274": "30340",
  "4280": "30340",
  "4282": "30340",
  "4284": "12300",
  "4287": "38860",
  "4330": "12300",
  "4344": "12300",
  "4345": "12300",
  "4346": "12300",
  "4347": "12300",
  "4349": "12300",
  "4350": "12300",
  "4351": "12300",
  "4352": "12300",
  "4355": "12300",
  "4357": "38860",
  "4358": "12300",
  "4363": "12300",
  "4364": "12300",
  "4401": "12620",
  "4411": "12620",
  "4412": "12620",
  "4418": "12620",
  "4419": "12620",
  "4422": "12620",
  "4427": "12620",
  "4428": "12620",
  "4429": "12620",
  "4430": "12620",
  "4434": "12620",
  "4435": "12620",
  "4444": "12620",
  "4448": "12620",
  "4449": "12620",
  "4450": "12620",
  "4455": "12620",
  "4456": "12620",
  "4457": "12620",
  "4461": "12620",
  "4462": "12620",
  "4468": "12620",
  "4473": "12620",
  "4474": "12620",
  "4487": "12620",
  "4488": "12620",
  "4493": "12620",
  "4530": "38860",
  "4548": "38860",
  "4562": "38860",
  "4579": "38860",
  "4765": "12620",
  "4901": "12300",
  "4910": "12300",
  "4917": "12300",
  "4927": "12300",
  "4928": "12620",
  "4930": "12620",
  "4932": "12620",
  "4953": "12620",
  "4963": "12300",
  "4969": "12620",
  "4989": "12300",
  "5001": "30100",
  "5032": "30100",
  "5033": "30100",
  "5034": "30100",
  "5035": "30100",
  "5036": "30100",
  "5037": "30100",
  "5038": "30100",
  "5039": "30100",
  "5040": "30100",
  "5043": "30100",
  "5045": "30100",
  "5048": "30100",
  "5051": "30100",
  "5053": "30100",
  "5055": "30100",
  "5056": "30100",
  "5058": "30100",
  "5059": "30100",
  "5060": "30100",
  "5061": "30100",
  "5062": "30100",
  "5065": "30100",
  "5067": "30100",
  "5068": "30100",
  "5069": "30100",
  "5070": "30100",
  "5071": "30100",
  "5072": "30100",
  "5075": "30100",
  "5076": "30100",
  "5077": "30100",
  "5079": "30100",
  "5081": "30100",
  "5086": "30100",
  "5089": "30100",
  "5091": "30100",
  "5142": "30100",
  "5143": "30100",
  "5149": "30100",
  "5150": "30100",
  "5151": "30100",
  "5152": "13540",
  "5153": "30100",
  "5156": "30100",
  "5161": "30100",
  "5201": "13540",
  "5250": "13540",
  "5251": "13540",
  "5252": "13540",
  "5253": "13540",
  "5255": "13540",
  "5257": "13540",
  "5261": "13540",
  "5262": "13540",
  "5340": "13540",
  "5350": "13540",
  "5352": "13540",
  "5401": "15540",
  "5403": "15540",
  "5404": "15540",
  "5408": "15540",
  "5440": "15540",
  "5441": "15540",
  "5445": "15540",
  "5446": "15540",
  "5448": "15540",
  "5450": "15540",
  "5452": "15540",
  "5454": "15540",
  "5455": "15540",
  "5457": "15540",
  "5458": "15540",
  "5459": "15540",
  "5461": "15540",
  "5462": "15540",
  "5463": "15540",
  "5465": "15540",
  "5468": "15540",
  "5471": "15540",
  "5474": "15540",
  "5476": "15540",
  "5477": "15540",
  "5478": "15540",
  "5482": "15540",
  "5483": "15540",
  "5486": "15540",
  "5488": "15540",
  "5489": "15540",
  "5494": "15540",
  "5495": "15540",
  "5602": "12740",
  "5641": "12740",
  "5647": "12740",
  "5648": "12740",
  "5649": "12740",
  "5650": "12740",
  "5651": "12740",
  "5654": "12740",
  "5658": "12740",
  "5660": "12740",
  "5663": "12740",
  "5667": "12740",
  "5669": "12740",
  "5673": "12740",
  "5674": "12740",
  "5675": "30100",
  "5676": "12740",
  "5677": "12740",
  "5679": "30100",
  "5681": "12740",
  "5682": "12740",
  "5701": "40860",
  "5730": "40860",
  "5732": "40860",
  "5733": "40860",
  "5735": "40860",
  "5736": "40860",
  "5737": "40860",
  "5738": "40860",
  "5739": "40860",
  "5742": "40860",
  "5743": "40860",
  "5744": "40860",
  "5751": "40860",
  "5757": "40860",
  "5758": "40860",
  "5759": "40860",
  "5761": "40860",
  "5762": "40860",
  "5763": "40860",
  "5764": "40860",
  "5765": "40860",
  "5767": "30100",
  "5772": "30100",
  "5773": "40860",
  "5774": "40860",
  "5775": "40860",
  "5776": "13540",
  "5777": "40860",
  "6001": "25540",
  "6002": "25540",
  "6010": "25540",
  "6013": "25540",
  "6016": "25540",
  "6018": "45860",
  "6019": "25540",
  "6021": "45860",
  "6023": "25540",
  "6024": "45860",
  "6026": "25540",
  "6027": "25540",
  "6029": "25540",
  "6031": "45860",
  "6032": "25540",
  "6033": "25540",
  "6035": "25540",
  "6037": "25540",
  "6039": "45860",
  "6040": "25540",
  "6042": "25540",
  "6043": "25540",
  "6051": "25540",
  "6052": "25540",
  "6053": "25540",
  "6057": "45860",
  "6058": "45860",
  "6060": "25540",
  "6062": "25540",
  "6063": "45860",
  "6065": "25540",
  "6066": "25540",
  "6067": "25540",
  "6068": "45860",
  "6069": "45860",
  "6070": "25540",
  "6071": "25540",
  "6073": "25540",
  "6074": "25540",
  "6076": "25540",
  "6078": "25540",
  "6081": "25540",
  "6082": "25540",
  "6084": "25540",
  "6085": "25540",
  "6088": "25540",
  "6089": "25540",
  "6090": "25540",
  "6091": "25540",
  "6092": "25540",
  "6093": "25540",
  "6095": "25540",
  "6096": "25540",
  "6098": "45860",
  "6103": "25540",
  "6105": "25540",
  "6106": "25540",
  "6107": "25540",
  "6108": "25540",
  "6109": "25540",
  "6110": "25540",
  "6111": "25540",
  "6112": "25540",
  "6114": "25540",
  "6117": "25540",
  "6118": "25540",
  "6119": "25540",
  "6120": "25540",
  "6226": "49340",
  "6231": "25540",
  "6232": "25540",
  "6234": "49340",
  "6235": "49340",
  "6237": "25540",
  "6238": "25540",
  "6239": "49340",
  "6241": "49340",
  "6242": "49340",
  "6247": "49340",
  "6248": "25540",
  "6249": "35980",
  "6250": "25540",
  "6254": "35980",
  "6255": "49340",
  "6256": "49340",
  "6259": "49340",
  "6260": "49340",
  "6264": "49340",
  "6266": "49340",
  "6268": "25540",
  "6277": "49340",
  "6278": "49340",
  "6279": "25540",
  "6280": "49340",
  "6281": "49340",
  "6282": "49340",
  "6320": "35980",
  "6330": "35980",
  "6331": "49340",
  "6333": "35980",
  "6334": "35980",
  "6335": "35980",
  "6339": "35980",
  "6340": "35980",
  "6351": "35980",
  "6354": "49340",
  "6355": "35980",
  "6357": "35980",
  "6359": "35980",
  "6360": "35980",
  "6365": "35980",
  "6370": "35980",
  "6371": "35980",
  "6374": "49340",
  "6375": "35980",
  "6377": "49340",
  "6378": "35980",
  "6379": "35980",
  "6380": "35980",
  "6382": "35980",
  "6384": "35980",
  "6385": "35980",
  "6401": "35300",
  "6403": "35300",
  "6405": "35300",
  "6409": "25540",
  "6410": "35300",
  "6412": "25540",
  "6413": "25540",
  "6415": "35980",
  "6416": "25540",
  "6417": "25540",
  "6418": "35300",
  "6419": "25540",
  "6420": "35980",
  "6422": "25540",
  "6423": "25540",
  "6424": "25540",
  "6426": "25540",
  "6437": "35300",
  "6438": "25540",
  "6441": "25540",
  "6442": "25540",
  "6443": "35300",
  "6447": "25540",
  "6450": "35300",
  "6451": "35300",
  "6455": "25540",
  "6457": "25540",
  "6460": "35300",
  "6461": "35300",
  "6468": "14860",
  "6469": "25540",
  "6470": "14860",
  "6471": "35300",
  "6472": "35300",
  "6473": "35300",
  "6475": "25540",
  "6477": "35300",
  "6478": "35300",
  "6479": "25540",
  "6480": "25540",
  "6481": "25540",
  "6482": "14860",
  "6483": "35300",
  "6484": "14860",
  "6488": "35300",
  "6489": "25540",
  "6492": "35300",
  "6498": "25540",
  "6510": "35300",
  "6511": "35300",
  "6512": "35300",
  "6513": "35300",
  "6514": "35300",
  "6515": "35300",
  "6516": "35300",
  "6517": "35300",
  "6518": "35300",
  "6519": "35300",
  "6524": "35300",
  "6525": "35300",
  "6604": "14860",
  "6605": "14860",
  "6606": "14860",
  "6607": "14860",
  "6608": "14860",
  "6610": "14860",
  "6611": "14860",
  "6612": "14860",
  "6614": "14860",
  "6615": "14860",
  "6704": "35300",
  "6705": "35300",
  "6706": "35300",
  "6708": "35300",
  "6710": "35300",
  "6712": "35300",
  "6716": "35300",
  "6750": "45860",
  "6751": "45860",
  "6752": "45860",
  "6753": "45860",
  "6754": "45860",
  "6755": "45860",
  "6756": "45860",
  "6757": "45860",
  "6759": "45860",
  "6762": "35300",
  "6763": "45860",
  "6770": "35300",
  "6776": "45860",
  "6777": "45860",
  "6778": "45860",
  "6779": "45860",
  "6782": "45860",
  "6783": "45860",
  "6784": "14860",
  "6785": "45860",
  "6786": "45860",
  "6787": "45860",
  "6790": "45860",
  "6791": "45860",
  "6793": "45860",
  "6794": "45860",
  "6795": "45860",
  "6796": "45860",
  "6798": "45860",
  "6801": "14860",
  "6804": "14860",
  "6807": "14860",
  "6810": "14860",
  "6811": "14860",
  "6812": "14860",
  "6820": "14860",
  "6824": "14860",
  "6825": "14860",
  "6830": "14860",
  "6831": "14860",
  "6840": "14860",
  "6850": "14860",
  "6851": "14860",
  "6853": "14860",
  "6854": "14860",
  "6855": "14860",
  "6870": "14860",
  "6877": "14860",
  "6878": "14860",
  "6880": "14860",
  "6883": "14860",
  "6890": "14860",
  "6896": "14860",
  "6897": "14860",
  "6901": "14860",
  "6902": "14860",
  "6903": "14860",
  "6905": "14860",
  "6906": "14860",
  "6907": "14860",
  "7001": "35620",
  "7002": "35620",
  "7003": "35620",
  "7004": "35620",
  "7005": "35620",
  "7006": "35620",
  "7008": "35620",
  "7009": "35620",
  "7010": "35620",
  "7011": "35620",
  "7012": "35620",
  "7013": "35620",
  "7014": "35620",
  "7016": "35620",
  "7017": "35620",
  "7018": "35620",
  "7020": "35620",
  "7021": "35620",
  "7022": "35620",
  "7023": "35620",
  "7024": "35620",
  "7026": "35620",
  "7027": "35620",
  "7028": "35620",
  "7029": "35620",
  "7030": "35620",
  "7031": "35620",
  "7032": "35620",
  "7033": "35620",
  "7034": "35620",
  "7035": "35620",
  "7036": "35620",
  "7039": "35620",
  "7040": "35620",
  "7041": "35620",
  "7042": "35620",
  "7043": "35620",
  "7044": "35620",
  "7045": "35620",
  "7046": "35620",
  "7047": "35620",
  "7050": "35620",
  "7052": "35620",
  "7054": "35620",
  "7055": "35620",
  "7057": "35620",
  "7058": "35620",
  "7059": "35620",
  "7060": "35620",
  "7062": "35620",
  "7063": "35620",
  "7064": "35620",
  "7065": "35620",
  "7066": "35620",
  "7067": "35620",
  "7068": "35620",
  "7069": "35620",
  "7070": "35620",
  "7071": "35620",
  "7072": "35620",
  "7073": "35620",
  "7074": "35620",
  "7075": "35620",
  "7076": "35620",
  "7077": "35620",
  "7078": "35620",
  "7079": "35620",
  "7080": "35620",
  "7081": "35620",
  "7082": "35620",
  "7083": "35620",
  "7086": "35620",
  "7087": "35620",
  "7088": "35620",
  "7090": "35620",
  "7092": "35620",
  "7093": "35620",
  "7094": "35620",
  "7095": "35620",
  "7102": "35620",
  "7103": "35620",
  "7104": "35620",
  "7105": "35620",
  "7106": "35620",
  "7107": "35620",
  "7108": "35620",
  "7109": "35620",
  "7110": "35620",
  "7111": "35620",
  "7112": "35620",
  "7114": "35620",
  "7201": "35620",
  "7202": "35620",
  "7203": "35620",
  "7204": "35620",
  "7205": "35620",
  "7206": "35620",
  "7208": "35620",
  "7302": "35620",
  "7304": "35620",
  "7305": "35620",
  "7306": "35620",
  "7307": "35620",
  "7310": "35620",
  "7401": "35620",
  "7403": "35620",
  "7405": "35620",
  "7407": "35620",
  "7410": "35620",
  "7416": "35620",
  "7417": "35620",
  "7418": "35620",
  "7419": "35620",
  "7420": "35620",
  "7421": "35620",
  "7422": "35620",
  "7423": "35620",
  "7424": "35620",
  "7430": "35620",
  "7432": "35620",
  "7435": "35620",
  "7436": "35620",
  "7438": "35620",
  "7439": "35620",
  "7440": "35620",
  "7442": "35620",
  "7444": "35620",
  "7446": "35620",
  "7450": "35620",
  "7452": "35620",
  "7456": "35620",
  "7457": "35620",
  "7458": "35620",
  "7460": "35620",
  "7461": "35620",
  "7462": "35620",
  "7463": "35620",
  "7465": "35620",
  "7470": "35620",
  "7480": "35620",
  "7481": "35620",
  "7501": "35620",
  "7502": "35620",
  "7503": "35620",
  "7504": "35620",
  "7505": "35620",
  "7506": "35620",
  "7508": "35620",
  "7512": "35620",
  "7513": "35620",
  "7514": "35620",
  "7522": "35620",
  "7524": "35620",
  "7601": "35620",
  "7603": "35620",
  "7604": "35620",
  "7605": "35620",
  "7606": "35620",
  "7607": "35620",
  "7620": "35620",
  "7621": "35620",
  "7624": "35620",
  "7626": "35620",
  "7627": "35620",
  "7628": "35620",
  "7630": "35620",
  "7631": "35620",
  "7632": "35620",
  "7640": "35620",
  "7641": "35620",
  "7642": "35620",
  "7643": "35620",
  "7644": "35620",
  "7645": "35620",
  "7646": "35620",
  "7647": "35620",
  "7648": "35620",
  "7649": "35620",
  "7650": "35620",
  "7652": "35620",
  "7656": "35620",
  "7657": "35620",
  "7660": "35620",
  "7661": "35620",
  "7662": "35620",
  "7663": "35620",
  "7666": "35620",
  "7670": "35620",
  "7675": "35620",
  "7676": "35620",
  "7677": "35620",
  "7701": "35620",
  "7702": "35620",
  "7704": "35620",
  "7711": "35620",
  "7712": "35620",
  "7716": "35620",
  "7717": "35620",
  "7718": "35620",
  "7719": "35620",
  "7720": "35620",
  "7721": "35620",
  "7722": "35620",
  "7723": "35620",
  "7724": "35620",
  "7726": "35620",
  "7727": "35620",
  "7728": "35620",
  "7730": "35620",
  "7731": "35620",
  "7732": "35620",
  "7733": "35620",
  "7734": "35620",
  "7735": "35620",
  "7737": "35620",
  "7738": "35620",
  "7739": "35620",
  "7740": "35620",
  "7746": "35620",
  "7747": "35620",
  "7748": "35620",
  "7750": "35620",
  "7751": "35620",
  "7753": "35620",
  "7755": "35620",
  "7756": "35620",
  "7757": "35620",
  "7758": "35620",
  "7760": "35620",
  "7762": "35620",
  "7764": "35620",
  "7801": "35620",
  "7803": "35620",
  "7821": "35620",
  "7822": "35620",
  "7823": "10900",
  "7825": "10900",
  "7826": "35620",
  "7827": "35620",
  "7828": "35620",
  "7830": "35620",
  "7832": "10900",
  "7834": "35620",
  "7836": "35620",
  "7838": "10900",
  "7840": "10900",
  "7843": "35620",
  "7847": "35620",
  "7848": "35620",
  "7849": "35620",
  "7850": "35620",
  "7852": "35620",
  "7853": "35620",
  "7856": "35620",
  "7857": "35620",
  "7860": "35620",
  "7863": "10900",
  "7865": "35620",
  "7866": "35620",
  "7869": "35620",
  "7871": "35620",
  "7874": "35620",
  "7876": "35620",
  "7878": "35620",
  "7882": "10900",
  "7885": "35620",
  "7901": "35620",
  "7920": "35620",
  "7921": "35620",
  "7922": "35620",
  "7924": "35620",
  "7927": "35620",
  "7928": "35620",
  "7930": "35620",
  "7931": "35620",
  "7932": "35620",
  "7933": "35620",
  "7934": "35620",
  "7935": "35620",
  "7936": "35620",
  "7940": "35620",
  "7945": "35620",
  "7946": "35620",
  "7950": "35620",
  "7960": "35620",
  "7974": "35620",
  "7976": "35620",
  "7980": "35620",
  "7981": "35620",
  "8001": "37980",
  "8002": "37980",
  "8003": "37980",
  "8004": "37980",
  "8005": "35620",
  "8006": "35620",
  "8007": "37980",
  "8008": "35620",
  "8009": "37980",
  "8010": "37980",
  "8012": "37980",
  "8014": "37980",
  "8015": "37980",
  "8016": "37980",
  "8019": "37980",
  "8020": "37980",
  "8021": "37980",
  "8022": "37980",
  "8026": "37980",
  "8027": "37980",
  "8028": "37980",
  "8029": "37980",
  "8030": "37980",
  "8031": "37980",
  "8032": "37980",
  "8033": "37980",
  "8034": "37980",
  "8035": "37980",
  "8036": "37980",
  "8037": "12100",
  "8041": "37980",
  "8043": "37980",
  "8045": "37980",
  "8046": "37980",
  "8048": "37980",
  "8049": "37980",
  "8050": "35620",
  "8051": "37980",
  "8052": "37980",
  "8053": "37980",
  "8054": "37980",
  "8055": "37980",
  "8056": "37980",
  "8057": "37980",
  "8059": "37980",
  "8060": "37980",
  "8061": "37980",
  "8062": "37980",
  "8063": "37980",
  "8065": "37980",
  "8066": "37980",
  "8067": "37980",
  "8068": "37980",
  "8069": "37980",
  "8070": "37980",
  "8071": "37980",
  "8075": "37980",
  "8077": "37980",
  "8078": "37980",
  "8079": "37980",
  "8080": "37980",
  "8081": "37980",
  "8083": "37980",
  "8084": "37980",
  "8085": "37980",
  "8086": "37980",
  "8087": "35620",
  "8088": "37980",
  "8089": "37980",
  "8090": "37980",
  "8091": "37980",
  "8092": "35620",
  "8093": "37980",
  "8094": "37980",
  "8096": "37980",
  "8097": "37980",
  "8098": "37980",
  "8102": "37980",
  "8103": "37980",
  "8104": "37980",
  "8105": "37980",
  "8106": "37980",
  "8107": "37980",
  "8108": "37980",
  "8109": "37980",
  "8110": "37980",
  "8201": "12100",
  "8202": "36140",
  "8203": "12100",
  "8204": "36140",
  "8205": "12100",
  "8210": "36140",
  "8212": "36140",
  "8215": "12100",
  "8221": "12100",
  "8223": "36140",
  "8225": "12100",
  "8226": "36140",
  "8230": "36140",
  "8232": "12100",
  "8234": "12100",
  "8241": "12100",
  "8242": "36140",
  "8243": "36140",
  "8244": "12100",
  "8247": "36140",
  "8248": "36140",
  "8251": "36140",
  "8260": "36140",
  "8270": "36140",
  "8302": "47220",
  "8310": "12100",
  "8311": "47220",
  "8312": "37980",
  "8317": "12100",
  "8318": "37980",
  "8319": "12100",
  "8321": "47220",
  "8322": "37980",
  "8323": "47220",
  "8326": "12100",
  "8327": "47220",
  "8328": "37980",
  "8330": "12100",
  "8332": "47220",
  "8340": "12100",
  "8341": "12100",
  "8343": "37980",
  "8344": "37980",
  "8345": "47220",
  "8346": "12100",
  "8349": "47220",
  "8350": "12100",
  "8353": "47220",
  "8360": "47220",
  "8361": "47220",
  "8401": "12100",
  "8402": "12100",
  "8403": "12100",
  "8406": "12100",
  "8501": "35620",
  "8502": "35620",
  "8505": "37980",
  "8510": "35620",
  "8511": "37980",
  "8512": "35620",
  "8514": "35620",
  "8515": "37980",
  "8518": "37980",
  "8520": "45940",
  "8525": "45940",
  "8527": "35620",
  "8530": "35620",
  "8533": "35620",
  "8534": "45940",
  "8535": "35620",
  "8536": "35620",
  "8540": "45940",
  "8542": "45940",
  "8550": "45940",
  "8551": "35620",
  "8553": "35620",
  "8554": "37980",
  "8555": "35620",
  "8558": "35620",
  "8559": "35620",
  "8560": "45940",
  "8562": "37980",
  "8608": "45940",
  "8609": "45940",
  "8610": "45940",
  "8611": "45940",
  "8618": "45940",
  "8619": "45940",
  "8620": "45940",
  "8628": "45940",
  "8629": "45940",
  "8638": "45940",
  "8648": "45940",
  "8690": "45940",
  "8691": "45940",
  "8701": "35620",
  "8720": "35620",
  "8721": "35620",
  "8722": "35620",
  "8723": "35620",
  "8724": "35620",
  "8730": "35620",
  "8731": "35620",
  "8732": "35620",
  "8733": "35620",
  "8734": "35620",
  "8735": "35620",
  "8736": "35620",
  "8738": "35620",
  "8739": "35620",
  "8740": "35620",
  "8741": "35620",
  "8742": "35620",
  "8750": "35620",
  "8751": "35620",
  "8752": "35620",
  "8753": "35620",
  "8755": "35620",
  "8757": "35620",
  "8758": "35620",
  "8759": "35620",
  "8801": "35620",
  "8802": "35620",
  "8804": "35620",
  "8805": "35620",
  "8807": "35620",
  "8809": "35620",
  "8810": "35620",
  "8812": "35620",
  "8816": "35620",
  "8817": "35620",
  "8820": "35620",
  "8822": "35620",
  "8823": "35620",
  "8824": "35620",
  "8825": "35620",
  "8826": "35620",
  "8827": "35620",
  "8828": "35620",
  "8829": "35620",
  "8830": "35620",
  "8831": "35620",
  "8833": "35620",
  "8835": "35620",
  "8836": "35620",
  "8837": "35620",
  "8840": "35620",
  "8844": "35620",
  "8846": "35620",
  "8848": "35620",
  "8850": "35620",
  "8852": "35620",
  "8853": "35620",
  "8854": "35620",
  "8857": "35620",
  "8859": "35620",
  "8861": "35620",
  "8863": "35620",
  "8865": "10900",
  "8867": "35620",
  "8869": "35620",
  "8872": "35620",
  "8873": "35620",
  "8876": "35620",
  "8879": "35620",
  "8880": "35620",
  "8882": "35620",
  "8884": "35620",
  "8886": "10900",
  "8887": "35620",
  "8889": "35620",
  "8901": "35620",
  "8902": "35620",
  "8904": "35620",
  "10001": "35620",
  "10002": "35620",
  "10003": "35620",
  "10004": "35620",
  "10005": "35620",
  "10006": "35620",
  "10007": "35620",
  "10009": "35620",
  "10010": "35620",
  "10011": "35620",
  "10012": "35620",
  "10013": "35620",
  "10014": "35620",
  "10016": "35620",
  "10017": "35620",
  "10018": "35620",
  "10019": "35620",
  "10021": "35620",
  "10022": "35620",
  "10023": "35620",
  "10024": "35620",
  "10025": "35620",
  "10026": "35620",
  "10027": "35620",
  "10028": "35620",
  "10029": "35620",
  "10030": "35620",
  "10031": "35620",
  "10032": "35620",
  "10033": "35620",
  "10034": "35620",
  "10035": "35620",
  "10036": "35620",
  "10037": "35620",
  "10039": "35620",
  "10040": "35620",
  "10044": "35620",
  "10065": "35620",
  "10069": "35620",
  "10075": "35620",
  "10128": "35620",
  "10280": "35620",
  "10282": "35620",
  "10301": "35620",
  "10302": "35620",
  "10303": "35620",
  "10304": "35620",
  "10305": "35620",
  "10306": "35620",
  "10307": "35620",
  "10308": "35620",
  "10309": "35620",
  "10310": "35620",
  "10312": "35620",
  "10314": "35620",
  "10451": "35620",
  "10452": "35620",
  "10453": "35620",
  "10454": "35620",
  "10455": "35620",
  "10456": "35620",
  "10457": "35620",
  "10458": "35620",
  "10459": "35620",
  "10460": "35620",
  "10461": "35620",
  "10462": "35620",
  "10463": "35620",
  "10464": "35620",
  "10465": "35620",
  "10466": "35620",
  "10467": "35620",
  "10468": "35620",
  "10469": "35620",
  "10470": "35620",
  "10471": "35620",
  "10472": "35620",
  "10473": "35620",
  "10501": "35620",
  "10502": "35620",
  "10504": "35620",
  "10505": "35620",
  "10506": "35620",
  "10507": "35620",
  "10509": "35620",
  "10510": "35620",
  "10511": "35620",
  "10512": "35620",
  "10514": "35620",
  "10516": "35620",
  "10520": "35620",
  "10522": "35620",
  "10523": "35620",
  "10524": "35620",
  "10526": "35620",
  "10527": "35620",
  "10528": "35620",
  "10530": "35620",
  "10532": "35620",
  "10533": "35620",
  "10536": "35620",
  "10537": "35620",
  "10538": "35620",
  "10541": "35620",
  "10543": "35620",
  "10546": "35620",
  "10547": "35620",
  "10548": "35620",
  "10549": "35620",
  "10550": "35620",
  "10552": "35620",
  "10553": "35620",
  "10560": "35620",
  "10562": "35620",
  "10566": "35620",
  "10567": "35620",
  "10570": "35620",
  "10573": "35620",
  "10576": "35620",
  "10577": "35620",
  "10578": "35620",
  "10579": "35620",
  "10580": "35620",
  "10583": "35620",
  "10588": "35620",
  "10589": "35620",
  "10590": "35620",
  "10591": "35620",
  "10594": "35620",
  "10595": "35620",
  "10596": "35620",
  "10597": "35620",
  "10598": "35620",
  "10601": "35620",
  "10603": "35620",
  "10604": "35620",
  "10605": "35620",
  "10606": "35620",
  "10607": "35620",
  "10701": "35620",
  "10703": "35620",
  "10704": "35620",
  "10705": "35620",
  "10706": "35620",
  "10707": "35620",
  "10708": "35620",
  "10709": "35620",
  "10710": "35620",
  "10801": "35620",
  "10803": "35620",
  "10804": "35620",
  "10805": "35620",
  "10901": "35620",
  "10913": "35620",
  "10914": "39100",
  "10916": "39100",
  "10917": "39100",
  "10918": "39100",
  "10919": "39100",
  "10920": "35620",
  "10921": "39100",
  "10922": "39100",
  "10923": "35620",
  "10924": "39100",
  "10925": "39100",
  "10926": "39100",
  "10927": "35620",
  "10928": "39100",
  "10930": "39100",
  "10931": "35620",
  "10940": "39100",
  "10941": "39100",
  "10950": "39100",
  "10952": "35620",
  "10954": "35620",
  "10956": "35620",
  "10958": "39100",
  "10960": "35620",
  "10962": "35620",
  "10963": "39100",
  "10964": "35620",
  "10965": "35620",
  "10968": "35620",
  "10969": "39100",
  "10970": "35620",
  "10973": "39100",
  "10974": "35620",
  "10976": "35620",
  "10977": "35620",
  "10980": "35620",
  "10983": "35620",
  "10984": "35620",
  "10986": "35620",
  "10987": "39100",
  "10988": "39100",
  "10989": "35620",
  "10990": "39100",
  "10992": "39100",
  "10993": "35620",
  "10994": "35620",
  "10998": "39100",
  "11001": "35620",
  "11003": "35620",
  "11004": "35620",
  "11010": "35620",
  "11020": "35620",
  "11021": "35620",
  "11023": "35620",
  "11024": "35620",
  "11030": "35620",
  "11040": "35620",
  "11050": "35620",
  "11096": "35620",
  "11101": "35620",
  "11102": "35620",
  "11103": "35620",
  "11104": "35620",
  "11105": "35620",
  "11106": "35620",
  "11201": "35620",
  "11203": "35620",
  "11204": "35620",
  "11205": "35620",
  "11206": "35620",
  "11207": "35620",
  "11208": "35620",
  "11209": "35620",
  "11210": "35620",
  "11211": "35620",
  "11212": "35620",
  "11213": "35620",
  "11214": "35620",
  "11215": "35620",
  "11216": "35620",
  "11217": "35620",
  "11218": "35620",
  "11219": "35620",
  "11220": "35620",
  "11221": "35620",
  "11222": "35620",
  "11223": "35620",
  "11224": "35620",
  "11225": "35620",
  "11226": "35620",
  "11228": "35620",
  "11229": "35620",
  "11230": "35620",
  "11231": "35620",
  "11232": "35620",
  "11233": "35620",
  "11234": "35620",
  "11235": "35620",
  "11236": "35620",
  "11237": "35620",
  "11238": "35620",
  "11239": "35620",
  "11249": "35620",
  "11354": "35620",
  "11355": "35620",
  "11356": "35620",
  "11357": "35620",
  "11358": "35620",
  "11360": "35620",
  "11361": "35620",
  "11362": "35620",
  "11363": "35620",
  "11364": "35620",
  "11365": "35620",
  "11366": "35620",
  "11367": "35620",
  "11368": "35620",
  "11369": "35620",
  "11370": "35620",
  "11372": "35620",
  "11373": "35620",
  "11374": "35620",
  "11375": "35620",
  "11377": "35620",
  "11378": "35620",
  "11379": "35620",
  "11385": "35620",
  "11411": "35620",
  "11412": "35620",
  "11413": "35620",
  "11414": "35620",
  "11415": "35620",
  "11416": "35620",
  "11417": "35620",
  "11418": "35620",
  "11419": "35620",
  "11420": "35620",
  "11421": "35620",
  "11422": "35620",
  "11423": "35620",
  "11426": "35620",
  "11427": "35620",
  "11428": "35620",
  "11429": "35620",
  "11432": "35620",
  "11433": "35620",
  "11434": "35620",
  "11435": "35620",
  "11436": "35620",
  "11501": "35620",
  "11507": "35620",
  "11509": "35620",
  "11510": "35620",
  "11514": "35620",
  "11516": "35620",
  "11518": "35620",
  "11520": "35620",
  "11530": "35620",
  "11542": "35620",
  "11545": "35620",
  "11547": "35620",
  "11548": "35620",
  "11550": "35620",
  "11552": "35620",
  "11553": "35620",
  "11554": "35620",
  "11557": "35620",
  "11558": "35620",
  "11559": "35620",
  "11560": "35620",
  "11561": "35620",
  "11563": "35620",
  "11565": "35620",
  "11566": "35620",
  "11568": "35620",
  "11569": "35620",
  "11570": "35620",
  "11572": "35620",
  "11575": "35620",
  "11576": "35620",
  "11577": "35620",
  "11579": "35620",
  "11580": "35620",
  "11581": "35620",
  "11590": "35620",
  "11596": "35620",
  "11598": "35620",
  "11691": "35620",
  "11692": "35620",
  "11693": "35620",
  "11694": "35620",
  "11701": "35620",
  "11702": "35620",
  "11703": "35620",
  "11704": "35620",
  "11705": "35620",
  "11706": "35620",
  "11709": "35620",
  "11710": "35620",
  "11713": "35620",
  "11714": "35620",
  "11715": "35620",
  "11716": "35620",
  "11717": "35620",
  "11718": "35620",
  "11719": "35620",
  "11720": "35620",
  "11721": "35620",
  "11722": "35620",
  "11724": "35620",
  "11725": "35620",
  "11726": "35620",
  "11727": "35620",
  "11729": "35620",
  "11730": "35620",
  "11731": "35620",
  "11732": "35620",
  "11733": "35620",
  "11735": "35620",
  "11738": "35620",
  "11739": "35620",
  "11740": "35620",
  "11741": "35620",
  "11742": "35620",
  "11743": "35620",
  "11746": "35620",
  "11747": "35620",
  "11749": "35620",
  "11751": "35620",
  "11752": "35620",
  "11753": "35620",
  "11754": "35620",
  "11755": "35620",
  "11756": "35620",
  "11757": "35620",
  "11758": "35620",
  "11762": "35620",
  "11763": "35620",
  "11764": "35620",
  "11765": "35620",
  "11766": "35620",
  "11767": "35620",
  "11768": "35620",
  "11769": "35620",
  "11770": "35620",
  "11771": "35620",
  "11772": "35620",
  "11776": "35620",
  "11777": "35620",
  "11778": "35620",
  "11779": "35620",
  "11780": "35620",
  "11782": "35620",
  "11783": "35620",
  "11784": "35620",
  "11786": "35620",
  "11787": "35620",
  "11788": "35620",
  "11789": "35620",
  "11790": "35620",
  "11791": "35620",
  "11792": "35620",
  "11793": "35620",
  "11795": "35620",
  "11796": "35620",
  "11797": "35620",
  "11798": "35620",
  "11801": "35620",
  "11803": "35620",
  "11804": "35620",
  "11901": "35620",
  "11930": "35620",
  "11932": "35620",
  "11933": "35620",
  "11934": "35620",
  "11935": "35620",
  "11937": "35620",
  "11939": "35620",
  "11940": "35620",
  "11941": "35620",
  "11942": "35620",
  "11944": "35620",
  "11946": "35620",
  "11948": "35620",
  "11949": "35620",
  "11950": "35620",
  "11952": "35620",
  "11953": "35620",
  "11954": "35620",
  "11955": "35620",
  "11957": "35620",
  "11958": "35620",
  "11959": "35620",
  "11960": "35620",
  "11961": "35620",
  "11962": "35620",
  "11963": "35620",
  "11964": "35620",
  "11965": "35620",
  "11967": "35620",
  "11968": "35620",
  "11971": "35620",
  "11972": "35620",
  "11975": "35620",
  "11976": "35620",
  "11977": "35620",
  "11978": "35620",
  "11980": "35620",
  "12008": "10580",
  "12009": "10580",
  "12010": "11220",
  "12017": "26460",
  "12018": "10580",
  "12019": "10580",
  "12020": "10580",
  "12022": "10580",
  "12023": "10580",
  "12024": "26460",
  "12025": "24100",
  "12027": "10580",
  "12028": "24020",
  "12029": "26460",
  "12032": "24100",
  "12033": "10580",
  "12035": "10580",
  "12036": "10580",
  "12037": "26460",
  "12041": "10580",
  "12043": "10580",
  "12045": "10580",
  "12046": "10580",
  "12047": "10580",
  "12052": "10580",
  "12053": "10580",
  "12054": "10580",
  "12056": "10580",
  "12057": "24020",
  "12059": "10580",
  "12060": "26460",
  "12061": "10580",
  "12062": "10580",
  "12063": "10580",
  "12064": "36580",
  "12065": "10580",
  "12066": "10580",
  "12067": "10580",
  "12068": "11220",
  "12070": "24100",
  "12071": "10580",
  "12072": "11220",
  "12074": "10580",
  "12075": "26460",
  "12076": "10580",
  "12077": "10580",
  "12078": "24100",
  "12083": "10580",
  "12084": "10580",
  "12086": "24100",
  "12090": "10580",
  "12092": "10580",
  "12093": "10580",
  "12094": "10580",
  "12095": "24100",
  "12106": "26460",
  "12110": "10580",
  "12116": "36580",
  "12117": "24100",
  "12118": "10580",
  "12120": "10580",
  "12121": "10580",
  "12122": "10580",
  "12123": "10580",
  "12125": "26460",
  "12130": "26460",
  "12134": "24100",
  "12136": "26460",
  "12137": "10580",
  "12138": "10580",
  "12140": "10580",
  "12143": "10580",
  "12144": "10580",
  "12147": "10580",
  "12148": "10580",
  "12149": "10580",
  "12150": "10580",
  "12151": "10580",
  "12153": "10580",
  "12154": "10580",
  "12155": "36580",
  "12156": "10580",
  "12157": "10580",
  "12158": "10580",
  "12159": "10580",
  "12160": "10580",
  "12165": "26460",
  "12166": "11220",
  "12168": "10580",
  "12169": "10580",
  "12170": "10580",
  "12173": "26460",
  "12175": "10580",
  "12177": "11220",
  "12180": "10580",
  "12182": "10580",
  "12183": "10580",
  "12184": "26460",
  "12185": "10580",
  "12186": "10580",
  "12187": "10580",
  "12188": "10580",
  "12189": "10580",
  "12193": "10580",
  "12194": "10580",
  "12196": "10580",
  "12197": "36580",
  "12198": "10580",
  "12202": "10580",
  "12203": "10580",
  "12204": "10580",
  "12205": "10580",
  "12206": "10580",
  "12208": "10580",
  "12209": "10580",
  "12210": "10580",
  "12211": "10580",
  "12302": "10580",
  "12303": "10580",
  "12304": "10580",
  "12305": "10580",
  "12306": "10580",
  "12307": "10580",
  "12308": "10580",
  "12309": "10580",
  "12401": "28740",
  "12404": "28740",
  "12406": "28740",
  "12409": "28740",
  "12410": "28740",
  "12411": "28740",
  "12412": "28740",
  "12416": "28740",
  "12419": "28740",
  "12420": "28740",
  "12428": "28740",
  "12433": "28740",
  "12435": "28740",
  "12440": "28740",
  "12443": "28740",
  "12446": "28740",
  "12448": "28740",
  "12449": "28740",
  "12453": "28740",
  "12456": "28740",
  "12457": "28740",
  "12458": "28740",
  "12460": "10580",
  "12461": "28740",
  "12464": "28740",
  "12465": "28740",
  "12466": "28740",
  "12469": "10580",
  "12472": "28740",
  "12477": "28740",
  "12480": "28740",
  "12481": "28740",
  "12484": "28740",
  "12486": "28740",
  "12487": "28740",
  "12489": "28740",
  "12491": "28740",
  "12493": "28740",
  "12494": "28740",
  "12495": "28740",
  "12498": "28740",
  "12501": "39100",
  "12502": "26460",
  "12503": "26460",
  "12508": "39100",
  "12513": "26460",
  "12514": "39100",
  "12515": "28740",
  "12516": "26460",
  "12517": "26460",
  "12518": "39100",
  "12520": "39100",
  "12521": "26460",
  "12522": "39100",
  "12523": "26460",
  "12524": "39100",
  "12525": "28740",
  "12526": "26460",
  "12528": "28740",
  "12529": "26460",
  "12531": "39100",
  "12533": "39100",
  "12534": "26460",
  "12538": "39100",
  "12540": "39100",
  "12542": "28740",
  "12543": "39100",
  "12545": "39100",
  "12546": "39100",
  "12547": "28740",
  "12548": "28740",
  "12549": "39100",
  "12550": "39100",
  "12553": "39100",
  "12561": "28740",
  "12563": "35620",
  "12564": "39100",
  "12565": "26460",
  "12566": "28740",
  "12567": "39100",
  "12569": "39100",
  "12570": "39100",
  "12571": "39100",
  "12572": "39100",
  "12574": "39100",
  "12575": "39100",
  "12577": "39100",
  "12578": "39100",
  "12580": "39100",
  "12581": "39100",
  "12582": "39100",
  "12583": "26460",
  "12585": "39100",
  "12586": "39100",
  "12589": "28740",
  "12590": "39100",
  "12592": "39100",
  "12594": "39100",
  "12601": "39100",
  "12603": "39100",
  "12701": "35620",
  "12721": "35620",
  "12754": "35620",
  "12790": "35620",
  "12758": "35620",
  "12788": "35620",
  "12747": "35620",
  "12776": "35620",
  "12783": "35620",
  "12775": "35620",
  "12764": "35620",
  "12723": "35620",
  "12789": "35620",
  "12768": "35620",
  "12740": "35620",
  "12748": "35620",
  "12737": "35620",
  "12779": "35620",
  "12763": "35620",
  "12734": "35620",
  "12726": "35620",
  "12719": "35620",
  "12733": "35620",
  "12759": "35620",
  "12732": "35620",
  "12777": "35620",
  "12765": "35620",
  "12751": "35620",
  "12760": "35620",
  "12762": "35620",
  "12786": "35620",
  "12766": "35620",
  "12742": "35620",
  "12743": "35620",
  "12770": "35620",
  "12778": "35620",
  "12741": "35620",
  "12720": "35620",
  "12745": "35620",
  "12738": "35620",
  "12750": "35620",
  "12724": "35620",
  "12749": "35620",
  "12736": "35620",
  "12769": "35620",
  "12787": "35620",
  "12791": "35620",
  "12792": "35620",
  "12752": "35620",
  "12767": "35620",
  "12781": "35620",
  "12784": "35620",
  "12722": "35620",
  "12727": "35620",
  "12725": "28740",
  "12729": "39100",
  "12746": "39100",
  "12771": "39100",
  "12780": "39100",
  "12785": "39100",
  "12801": "24020",
  "12803": "10580",
  "12804": "24020",
  "12808": "24020",
  "12809": "24020",
  "12810": "24020",
  "12811": "24020",
  "12814": "24020",
  "12815": "24020",
  "12816": "24020",
  "12817": "24020",
  "12819": "24020",
  "12820": "24020",
  "12821": "24020",
  "12822": "10580",
  "12823": "24020",
  "12824": "24020",
  "12827": "24020",
  "12828": "24020",
  "12831": "10580",
  "12832": "24020",
  "12833": "10580",
  "12834": "24020",
  "12835": "10580",
  "12836": "24020",
  "12837": "24020",
  "12838": "24020",
  "12839": "24020",
  "12841": "24020",
  "12843": "24020",
  "12844": "24020",
  "12845": "24020",
  "12846": "24020",
  "12849": "24020",
  "12850": "10580",
  "12853": "24020",
  "12856": "24020",
  "12859": "10580",
  "12860": "24020",
  "12861": "24020",
  "12863": "10580",
  "12865": "24020",
  "12866": "10580",
  "12871": "10580",
  "12873": "24020",
  "12874": "24020",
  "12878": "24020",
  "12884": "10580",
  "12885": "24020",
  "12886": "24020",
  "12887": "24020",
  "12901": "38460",
  "12903": "38460",
  "12910": "38460",
  "12913": "31660",
  "12914": "31660",
  "12916": "31660",
  "12917": "31660",
  "12918": "38460",
  "12919": "38460",
  "12920": "31660",
  "12921": "38460",
  "12923": "38460",
  "12926": "31660",
  "12927": "36300",
  "12929": "38460",
  "12930": "31660",
  "12934": "38460",
  "12935": "38460",
  "12937": "31660",
  "12945": "31660",
  "12952": "38460",
  "12953": "31660",
  "12955": "38460",
  "12957": "31660",
  "12958": "38460",
  "12959": "38460",
  "12962": "38460",
  "12965": "36300",
  "12966": "31660",
  "12967": "36300",
  "12969": "31660",
  "12970": "31660",
  "12972": "38460",
  "12973": "36300",
  "12976": "31660",
  "12978": "38460",
  "12979": "38460",
  "12980": "31660",
  "12981": "38460",
  "12983": "31660",
  "12985": "38460",
  "12986": "31660",
  "12989": "31660",
  "12992": "38460",
  "13021": "12180",
  "13026": "12180",
  "13027": "45060",
  "13028": "45060",
  "13029": "45060",
  "13030": "45060",
  "13031": "45060",
  "13032": "45060",
  "13033": "12180",
  "13034": "12180",
  "13035": "45060",
  "13036": "45060",
  "13037": "45060",
  "13039": "45060",
  "13040": "18660",
  "13041": "45060",
  "13042": "46540",
  "13044": "45060",
  "13045": "18660",
  "13052": "45060",
  "13053": "27060",
  "13054": "46540",
  "13057": "45060",
  "13060": "45060",
  "13061": "45060",
  "13063": "45060",
  "13066": "45060",
  "13068": "27060",
  "13069": "45060",
  "13071": "12180",
  "13072": "45060",
  "13073": "27060",
  "13074": "45060",
  "13076": "45060",
  "13077": "18660",
  "13078": "45060",
  "13080": "45060",
  "13081": "12180",
  "13082": "45060",
  "13083": "45060",
  "13084": "45060",
  "13088": "45060",
  "13090": "45060",
  "13092": "12180",
  "13101": "18660",
  "13104": "45060",
  "13108": "45060",
  "13110": "45060",
  "13111": "12180",
  "13112": "45060",
  "13114": "45060",
  "13116": "45060",
  "13118": "12180",
  "13120": "45060",
  "13122": "45060",
  "13126": "45060",
  "13131": "45060",
  "13132": "45060",
  "13135": "45060",
  "13140": "12180",
  "13141": "18660",
  "13142": "45060",
  "13143": "40380",
  "13144": "45060",
  "13145": "45060",
  "13146": "40380",
  "13147": "12180",
  "13148": "42900",
  "13152": "45060",
  "13156": "12180",
  "13157": "46540",
  "13158": "18660",
  "13159": "45060",
  "13160": "12180",
  "13162": "46540",
  "13164": "45060",
  "13165": "42900",
  "13166": "12180",
  "13167": "45060",
  "13202": "45060",
  "13203": "45060",
  "13204": "45060",
  "13205": "45060",
  "13206": "45060",
  "13207": "45060",
  "13208": "45060",
  "13209": "45060",
  "13210": "45060",
  "13211": "45060",
  "13212": "45060",
  "13214": "45060",
  "13215": "45060",
  "13219": "45060",
  "13224": "45060",
  "13302": "45060",
  "13303": "46540",
  "13304": "46540",
  "13308": "46540",
  "13309": "46540",
  "13310": "45060",
  "13313": "46540",
  "13314": "45060",
  "13315": "36580",
  "13316": "46540",
  "13317": "11220",
  "13318": "46540",
  "13319": "46540",
  "13320": "36580",
  "13321": "46540",
  "13322": "46540",
  "13323": "46540",
  "13324": "46540",
  "13326": "36580",
  "13328": "46540",
  "13329": "24100",
  "13331": "46540",
  "13332": "45060",
  "13334": "45060",
  "13335": "36580",
  "13337": "36580",
  "13338": "46540",
  "13339": "11220",
  "13340": "46540",
  "13346": "45060",
  "13348": "36580",
  "13350": "46540",
  "13354": "46540",
  "13355": "45060",
  "13357": "46540",
  "13361": "46540",
  "13363": "46540",
  "13365": "46540",
  "13402": "45060",
  "13403": "46540",
  "13406": "46540",
  "13407": "46540",
  "13408": "45060",
  "13409": "45060",
  "13410": "11220",
  "13411": "36580",
  "13413": "46540",
  "13416": "46540",
  "13417": "46540",
  "13420": "46540",
  "13421": "45060",
  "13424": "46540",
  "13425": "46540",
  "13428": "11220",
  "13431": "46540",
  "13435": "46540",
  "13437": "45060",
  "13438": "46540",
  "13439": "46540",
  "13440": "46540",
  "13450": "36580",
  "13452": "24100",
  "13454": "46540",
  "13456": "46540",
  "13459": "10580",
  "13461": "46540",
  "13468": "36580",
  "13469": "46540",
  "13470": "24100",
  "13471": "46540",
  "13472": "46540",
  "13476": "46540",
  "13477": "46540",
  "13478": "46540",
  "13480": "46540",
  "13485": "45060",
  "13486": "46540",
  "13488": "36580",
  "13490": "46540",
  "13491": "46540",
  "13492": "46540",
  "13493": "45060",
  "13494": "46540",
  "13495": "46540",
  "13501": "46540",
  "13502": "46540",
  "13601": "48060",
  "13605": "48060",
  "13606": "48060",
  "13607": "48060",
  "13608": "48060",
  "13612": "48060",
  "13613": "36300",
  "13614": "36300",
  "13615": "48060",
  "13616": "48060",
  "13617": "36300",
  "13618": "48060",
  "13619": "48060",
  "13621": "36300",
  "13622": "48060",
  "13624": "48060",
  "13625": "36300",
  "13630": "36300",
  "13634": "48060",
  "13635": "36300",
  "13636": "48060",
  "13637": "48060",
  "13638": "48060",
  "13640": "48060",
  "13642": "36300",
  "13646": "36300",
  "13650": "48060",
  "13652": "36300",
  "13654": "36300",
  "13656": "48060",
  "13658": "36300",
  "13659": "48060",
  "13660": "36300",
  "13661": "48060",
  "13662": "36300",
  "13664": "36300",
  "13666": "36300",
  "13667": "36300",
  "13668": "36300",
  "13669": "36300",
  "13670": "36300",
  "13672": "36300",
  "13673": "48060",
  "13676": "36300",
  "13679": "48060",
  "13680": "36300",
  "13681": "36300",
  "13682": "48060",
  "13684": "36300",
  "13685": "48060",
  "13687": "36300",
  "13690": "36300",
  "13691": "48060",
  "13693": "48060",
  "13694": "36300",
  "13697": "36300",
  "13732": "13780",
  "13734": "13780",
  "13736": "13780",
  "13743": "13780",
  "13744": "13780",
  "13746": "13780",
  "13748": "13780",
  "13760": "13780",
  "13776": "36580",
  "13777": "13780",
  "13784": "18660",
  "13787": "13780",
  "13790": "13780",
  "13795": "13780",
  "13796": "36580",
  "13797": "13780",
  "13802": "13780",
  "13803": "18660",
  "13807": "36580",
  "13808": "36580",
  "13809": "36580",
  "13810": "36580",
  "13811": "13780",
  "13812": "13780",
  "13813": "13780",
  "13820": "36580",
  "13825": "36580",
  "13827": "13780",
  "13833": "13780",
  "13835": "13780",
  "13843": "36580",
  "13849": "36580",
  "13850": "13780",
  "13861": "36580",
  "13862": "13780",
  "13863": "18660",
  "13864": "13780",
  "13865": "13780",
  "13901": "13780",
  "13903": "13780",
  "13904": "13780",
  "13905": "13780",
  "14001": "15380",
  "14004": "15380",
  "14005": "12860",
  "14006": "15380",
  "14008": "15380",
  "14012": "15380",
  "14013": "12860",
  "14020": "12860",
  "14025": "15380",
  "14026": "15380",
  "14028": "15380",
  "14030": "15380",
  "14031": "15380",
  "14032": "15380",
  "14033": "15380",
  "14034": "15380",
  "14036": "12860",
  "14040": "12860",
  "14042": "36460",
  "14043": "15380",
  "14047": "15380",
  "14048": "27460",
  "14051": "15380",
  "14052": "15380",
  "14054": "12860",
  "14055": "15380",
  "14057": "15380",
  "14058": "12860",
  "14059": "15380",
  "14062": "27460",
  "14063": "27460",
  "14065": "36460",
  "14067": "15380",
  "14068": "15380",
  "14069": "15380",
  "14070": "36460",
  "14072": "15380",
  "14075": "15380",
  "14080": "15380",
  "14081": "15380",
  "14085": "15380",
  "14086": "15380",
  "14091": "15380",
  "14092": "15380",
  "14094": "15380",
  "14098": "40380",
  "14101": "36460",
  "14102": "15380",
  "14103": "40380",
  "14105": "15380",
  "14108": "15380",
  "14111": "15380",
  "14120": "15380",
  "14125": "12860",
  "14126": "15380",
  "14127": "15380",
  "14129": "36460",
  "14131": "15380",
  "14132": "15380",
  "14134": "15380",
  "14136": "27460",
  "14138": "36460",
  "14139": "15380",
  "14141": "15380",
  "14143": "12860",
  "14150": "15380",
  "14170": "15380",
  "14171": "36460",
  "14172": "15380",
  "14173": "36460",
  "14174": "15380",
  "14201": "15380",
  "14202": "15380",
  "14204": "15380",
  "14206": "15380",
  "14207": "15380",
  "14208": "15380",
  "14209": "15380",
  "14210": "15380",
  "14211": "15380",
  "14212": "15380",
  "14213": "15380",
  "14214": "15380",
  "14215": "15380",
  "14216": "15380",
  "14217": "15380",
  "14218": "15380",
  "14219": "15380",
  "14220": "15380",
  "14221": "15380",
  "14222": "15380",
  "14223": "15380",
  "14224": "15380",
  "14225": "15380",
  "14226": "15380",
  "14227": "15380",
  "14228": "15380",
  "14301": "15380",
  "14303": "15380",
  "14304": "15380",
  "14305": "15380",
  "14411": "40380",
  "14414": "40380",
  "14416": "12860",
  "14418": "40380",
  "14420": "40380",
  "14422": "12860",
  "14423": "40380",
  "14424": "40380",
  "14425": "40380",
  "14428": "40380",
  "14432": "40380",
  "14433": "40380",
  "14435": "40380",
  "14437": "40380",
  "14441": "40380",
  "14445": "40380",
  "14450": "40380",
  "14454": "40380",
  "14456": "40380",
  "14462": "40380",
  "14464": "40380",
  "14466": "40380",
  "14467": "40380",
  "14468": "40380",
  "14469": "40380",
  "14470": "40380",
  "14471": "40380",
  "14472": "40380",
  "14475": "40380",
  "14476": "40380",
  "14477": "40380",
  "14478": "40380",
  "14480": "40380",
  "14481": "40380",
  "14482": "12860",
  "14485": "40380",
  "14486": "40380",
  "14487": "40380",
  "14489": "40380",
  "14502": "40380",
  "14504": "40380",
  "14505": "40380",
  "14506": "40380",
  "14507": "40380",
  "14510": "40380",
  "14511": "40380",
  "14512": "40380",
  "14513": "40380",
  "14514": "40380",
  "14516": "40380",
  "14517": "40380",
  "14519": "40380",
  "14521": "42900",
  "14522": "40380",
  "14525": "12860",
  "14526": "40380",
  "14527": "40380",
  "14532": "40380",
  "14533": "40380",
  "14534": "40380",
  "14537": "40380",
  "14541": "42900",
  "14543": "40380",
  "14544": "40380",
  "14546": "40380",
  "14548": "40380",
  "14551": "40380",
  "14555": "40380",
  "14559": "40380",
  "14560": "40380",
  "14561": "40380",
  "14564": "40380",
  "14568": "40380",
  "14571": "40380",
  "14572": "18500",
  "14580": "40380",
  "14586": "40380",
  "14589": "40380",
  "14590": "40380",
  "14592": "40380",
  "14605": "40380",
  "14606": "40380",
  "14607": "40380",
  "14608": "40380",
  "14609": "40380",
  "14610": "40380",
  "14611": "40380",
  "14612": "40380",
  "14613": "40380",
  "14615": "40380",
  "14616": "40380",
  "14617": "40380",
  "14618": "40380",
  "14619": "40380",
  "14620": "40380",
  "14621": "40380",
  "14622": "40380",
  "14623": "40380",
  "14624": "40380",
  "14625": "40380",
  "14626": "40380",
  "14701": "27460",
  "14706": "36460",
  "14710": "27460",
  "14712": "27460",
  "14716": "27460",
  "14718": "27460",
  "14719": "36460",
  "14720": "27460",
  "14722": "27460",
  "14723": "27460",
  "14724": "27460",
  "14726": "36460",
  "14728": "27460",
  "14729": "36460",
  "14731": "36460",
  "14733": "27460",
  "14736": "27460",
  "14737": "36460",
  "14738": "27460",
  "14740": "27460",
  "14741": "36460",
  "14742": "27460",
  "14743": "36460",
  "14747": "27460",
  "14748": "36460",
  "14750": "27460",
  "14752": "27460",
  "14753": "36460",
  "14755": "36460",
  "14757": "27460",
  "14760": "36460",
  "14767": "27460",
  "14769": "27460",
  "14770": "36460",
  "14772": "36460",
  "14775": "27460",
  "14779": "36460",
  "14781": "27460",
  "14782": "27460",
  "14784": "27460",
  "14787": "27460",
  "14801": "18500",
  "14807": "18500",
  "14808": "18500",
  "14809": "18500",
  "14810": "18500",
  "14814": "21300",
  "14816": "21300",
  "14817": "27060",
  "14819": "18500",
  "14820": "18500",
  "14821": "18500",
  "14823": "18500",
  "14825": "21300",
  "14826": "18500",
  "14830": "18500",
  "14836": "40380",
  "14837": "40380",
  "14838": "21300",
  "14839": "18500",
  "14840": "18500",
  "14842": "40380",
  "14843": "18500",
  "14845": "21300",
  "14846": "40380",
  "14847": "42900",
  "14850": "27060",
  "14855": "18500",
  "14858": "18500",
  "14859": "13780",
  "14860": "42900",
  "14861": "21300",
  "14867": "27060",
  "14870": "18500",
  "14871": "21300",
  "14872": "21300",
  "14873": "18500",
  "14874": "18500",
  "14877": "18500",
  "14879": "18500",
  "14882": "27060",
  "14883": "13780",
  "14885": "18500",
  "14886": "27060",
  "14889": "21300",
  "14892": "13780",
  "14894": "21300",
  "14898": "18500",
  "14901": "21300",
  "14903": "21300",
  "14904": "21300",
  "14905": "21300",
  "15001": "38300",
  "15003": "38300",
  "15005": "38300",
  "15006": "38300",
  "15007": "38300",
  "15009": "38300",
  "15010": "38300",
  "15012": "38300",
  "15014": "38300",
  "15015": "38300",
  "15017": "38300",
  "15018": "38300",
  "15019": "38300",
  "15020": "38300",
  "15021": "38300",
  "15022": "38300",
  "15024": "38300",
  "15025": "38300",
  "15026": "38300",
  "15027": "38300",
  "15030": "38300",
  "15031": "38300",
  "15033": "38300",
  "15034": "38300",
  "15035": "38300",
  "15037": "38300",
  "15038": "38300",
  "15042": "38300",
  "15043": "38300",
  "15044": "38300",
  "15045": "38300",
  "15046": "38300",
  "15047": "38300",
  "15049": "38300",
  "15050": "38300",
  "15051": "38300",
  "15052": "38300",
  "15053": "38300",
  "15054": "38300",
  "15055": "38300",
  "15056": "38300",
  "15057": "38300",
  "15059": "38300",
  "15060": "38300",
  "15061": "38300",
  "15062": "38300",
  "15063": "38300",
  "15064": "38300",
  "15065": "38300",
  "15066": "38300",
  "15067": "38300",
  "15068": "38300",
  "15071": "38300",
  "15074": "38300",
  "15076": "38300",
  "15078": "38300",
  "15081": "38300",
  "15082": "38300",
  "15083": "38300",
  "15084": "38300",
  "15085": "38300",
  "15086": "38300",
  "15088": "38300",
  "15089": "38300",
  "15090": "38300",
  "15101": "38300",
  "15102": "38300",
  "15104": "38300",
  "15106": "38300",
  "15108": "38300",
  "15110": "38300",
  "15112": "38300",
  "15116": "38300",
  "15120": "38300",
  "15122": "38300",
  "15126": "38300",
  "15129": "38300",
  "15131": "38300",
  "15132": "38300",
  "15133": "38300",
  "15135": "38300",
  "15136": "38300",
  "15137": "38300",
  "15139": "38300",
  "15140": "38300",
  "15142": "38300",
  "15143": "38300",
  "15144": "38300",
  "15145": "38300",
  "15146": "38300",
  "15147": "38300",
  "15148": "38300",
  "15201": "38300",
  "15202": "38300",
  "15203": "38300",
  "15204": "38300",
  "15205": "38300",
  "15206": "38300",
  "15207": "38300",
  "15208": "38300",
  "15209": "38300",
  "15210": "38300",
  "15211": "38300",
  "15212": "38300",
  "15213": "38300",
  "15214": "38300",
  "15215": "38300",
  "15216": "38300",
  "15217": "38300",
  "15218": "38300",
  "15219": "38300",
  "15220": "38300",
  "15221": "38300",
  "15222": "38300",
  "15223": "38300",
  "15224": "38300",
  "15225": "38300",
  "15226": "38300",
  "15227": "38300",
  "15228": "38300",
  "15229": "38300",
  "15232": "38300",
  "15233": "38300",
  "15234": "38300",
  "15235": "38300",
  "15236": "38300",
  "15237": "38300",
  "15238": "38300",
  "15239": "38300",
  "15241": "38300",
  "15243": "38300",
  "15301": "38300",
  "15311": "38300",
  "15312": "38300",
  "15313": "38300",
  "15314": "38300",
  "15317": "38300",
  "15321": "38300",
  "15323": "38300",
  "15324": "38300",
  "15329": "38300",
  "15330": "38300",
  "15331": "38300",
  "15332": "38300",
  "15333": "38300",
  "15340": "38300",
  "15342": "38300",
  "15345": "38300",
  "15347": "38300",
  "15350": "38300",
  "15358": "38300",
  "15360": "38300",
  "15363": "38300",
  "15367": "38300",
  "15368": "38300",
  "15376": "38300",
  "15377": "38300",
  "15401": "38300",
  "15410": "38300",
  "15411": "43740",
  "15412": "38300",
  "15413": "38300",
  "15417": "38300",
  "15419": "38300",
  "15420": "38300",
  "15422": "38300",
  "15423": "38300",
  "15424": "43740",
  "15425": "38300",
  "15428": "38300",
  "15429": "38300",
  "15430": "38300",
  "15431": "38300",
  "15432": "38300",
  "15433": "38300",
  "15435": "38300",
  "15436": "38300",
  "15437": "38300",
  "15438": "38300",
  "15440": "38300",
  "15442": "38300",
  "15443": "38300",
  "15444": "38300",
  "15445": "38300",
  "15446": "38300",
  "15447": "38300",
  "15449": "38300",
  "15450": "38300",
  "15451": "38300",
  "15455": "38300",
  "15456": "38300",
  "15458": "38300",
  "15459": "38300",
  "15461": "38300",
  "15462": "38300",
  "15464": "38300",
  "15466": "38300",
  "15468": "38300",
  "15469": "38300",
  "15470": "38300",
  "15473": "38300",
  "15474": "38300",
  "15475": "38300",
  "15476": "38300",
  "15477": "38300",
  "15478": "38300",
  "15479": "38300",
  "15480": "38300",
  "15482": "38300",
  "15483": "38300",
  "15486": "38300",
  "15489": "38300",
  "15490": "38300",
  "15501": "43740",
  "15502": "43740",
  "15530": "43740",
  "15531": "43740",
  "15538": "43740",
  "15540": "43740",
  "15541": "43740",
  "15542": "43740",
  "15546": "43740",
  "15547": "43740",
  "15551": "43740",
  "15552": "43740",
  "15557": "43740",
  "15558": "43740",
  "15563": "43740",
  "15601": "38300",
  "15610": "38300",
  "15611": "38300",
  "15612": "38300",
  "15613": "38300",
  "15615": "38300",
  "15617": "38300",
  "15618": "38300",
  "15619": "38300",
  "15620": "38300",
  "15622": "38300",
  "15623": "38300",
  "15624": "38300",
  "15626": "38300",
  "15627": "38300",
  "15628": "38300",
  "15629": "38300",
  "15631": "38300",
  "15632": "38300",
  "15634": "38300",
  "15635": "38300",
  "15636": "38300",
  "15637": "38300",
  "15639": "38300",
  "15640": "38300",
  "15641": "38300",
  "15642": "38300",
  "15644": "38300",
  "15646": "38300",
  "15647": "38300",
  "15650": "38300",
  "15655": "38300",
  "15656": "38300",
  "15658": "38300",
  "15660": "38300",
  "15661": "38300",
  "15663": "38300",
  "15665": "38300",
  "15666": "38300",
  "15668": "38300",
  "15670": "38300",
  "15671": "38300",
  "15672": "38300",
  "15673": "38300",
  "15675": "38300",
  "15677": "38300",
  "15678": "38300",
  "15679": "38300",
  "15681": "26860",
  "15683": "38300",
  "15684": "38300",
  "15686": "38300",
  "15687": "38300",
  "15688": "38300",
  "15690": "38300",
  "15692": "38300",
  "15697": "38300",
  "15698": "38300",
  "15701": "26860",
  "15713": "26860",
  "15714": "27780",
  "15716": "26860",
  "15717": "26860",
  "15722": "27780",
  "15724": "26860",
  "15725": "26860",
  "15727": "26860",
  "15728": "26860",
  "15729": "26860",
  "15731": "26860",
  "15732": "26860",
  "15734": "26860",
  "15736": "38300",
  "15739": "26860",
  "15742": "26860",
  "15747": "26860",
  "15748": "26860",
  "15753": "20180",
  "15754": "26860",
  "15756": "26860",
  "15757": "20180",
  "15759": "26860",
  "15765": "26860",
  "15771": "26860",
  "15772": "26860",
  "15774": "26860",
  "15801": "20180",
  "15821": "41260",
  "15823": "41260",
  "15827": "41260",
  "15845": "41260",
  "15846": "41260",
  "15848": "20180",
  "15849": "20180",
  "15853": "41260",
  "15856": "20180",
  "15857": "41260",
  "15866": "20180",
  "15868": "41260",
  "15870": "41260",
  "15901": "27780",
  "15902": "27780",
  "15904": "27780",
  "15905": "27780",
  "15906": "27780",
  "15909": "27780",
  "15920": "26860",
  "15923": "38300",
  "15924": "43740",
  "15926": "43740",
  "15928": "43740",
  "15931": "27780",
  "15935": "43740",
  "15936": "43740",
  "15937": "43740",
  "15938": "27780",
  "15942": "27780",
  "15943": "27780",
  "15944": "38300",
  "15946": "27780",
  "15949": "26860",
  "15954": "26860",
  "15955": "27780",
  "15956": "27780",
  "15957": "26860",
  "15958": "27780",
  "15961": "26860",
  "15963": "27780",
  "16001": "38300",
  "16002": "38300",
  "16020": "38300",
  "16022": "38300",
  "16023": "38300",
  "16025": "38300",
  "16028": "38300",
  "16029": "38300",
  "16033": "38300",
  "16034": "38300",
  "16037": "38300",
  "16038": "38300",
  "16040": "38300",
  "16041": "38300",
  "16045": "38300",
  "16046": "38300",
  "16049": "38300",
  "16050": "38300",
  "16051": "38300",
  "16052": "38300",
  "16053": "38300",
  "16055": "38300",
  "16056": "38300",
  "16057": "38300",
  "16059": "38300",
  "16061": "38300",
  "16063": "38300",
  "16066": "38300",
  "16101": "35260",
  "16102": "35260",
  "16105": "35260",
  "16110": "32740",
  "16111": "32740",
  "16112": "35260",
  "16113": "49660",
  "16114": "49660",
  "16115": "38300",
  "16116": "35260",
  "16117": "35260",
  "16120": "35260",
  "16121": "49660",
  "16123": "38300",
  "16124": "49660",
  "16125": "49660",
  "16127": "49660",
  "16130": "49660",
  "16131": "32740",
  "16132": "35260",
  "16133": "49660",
  "16134": "32740",
  "16136": "38300",
  "16137": "49660",
  "16141": "35260",
  "16142": "49660",
  "16143": "35260",
  "16145": "49660",
  "16146": "49660",
  "16148": "49660",
  "16150": "49660",
  "16153": "49660",
  "16154": "49660",
  "16156": "35260",
  "16157": "35260",
  "16159": "49660",
  "16160": "35260",
  "16161": "49660",
  "16201": "38300",
  "16210": "38300",
  "16212": "38300",
  "16218": "38300",
  "16222": "38300",
  "16226": "38300",
  "16228": "38300",
  "16229": "38300",
  "16238": "38300",
  "16240": "38300",
  "16242": "38300",
  "16244": "38300",
  "16245": "38300",
  "16249": "38300",
  "16250": "38300",
  "16256": "26860",
  "16259": "38300",
  "16262": "38300",
  "16263": "38300",
  "16301": "36340",
  "16311": "49660",
  "16313": "47620",
  "16314": "32740",
  "16316": "32740",
  "16317": "36340",
  "16319": "36340",
  "16323": "36340",
  "16327": "32740",
  "16329": "47620",
  "16335": "32740",
  "16340": "47620",
  "16341": "36340",
  "16342": "36340",
  "16343": "36340",
  "16344": "36340",
  "16345": "47620",
  "16346": "36340",
  "16347": "47620",
  "16350": "47620",
  "16351": "47620",
  "16354": "32740",
  "16360": "32740",
  "16362": "36340",
  "16364": "36340",
  "16365": "47620",
  "16371": "47620",
  "16372": "36340",
  "16373": "36340",
  "16374": "36340",
  "16401": "21500",
  "16402": "47620",
  "16403": "32740",
  "16404": "32740",
  "16405": "47620",
  "16406": "32740",
  "16407": "21500",
  "16410": "21500",
  "16411": "21500",
  "16412": "21500",
  "16415": "21500",
  "16417": "21500",
  "16420": "47620",
  "16421": "21500",
  "16423": "21500",
  "16424": "32740",
  "16426": "21500",
  "16428": "21500",
  "16430": "21500",
  "16433": "32740",
  "16434": "32740",
  "16435": "32740",
  "16436": "47620",
  "16438": "21500",
  "16440": "32740",
  "16441": "21500",
  "16442": "21500",
  "16443": "21500",
  "16502": "21500",
  "16503": "21500",
  "16504": "21500",
  "16505": "21500",
  "16506": "21500",
  "16507": "21500",
  "16508": "21500",
  "16509": "21500",
  "16510": "21500",
  "16511": "21500",
  "16601": "11020",
  "16602": "11020",
  "16611": "26500",
  "16613": "27780",
  "16617": "11020",
  "16620": "20180",
  "16621": "26500",
  "16623": "26500",
  "16625": "11020",
  "16627": "20180",
  "16630": "27780",
  "16634": "26500",
  "16635": "11020",
  "16637": "11020",
  "16639": "27780",
  "16640": "27780",
  "16641": "27780",
  "16646": "27780",
  "16647": "26500",
  "16648": "11020",
  "16651": "20180",
  "16652": "26500",
  "16656": "20180",
  "16657": "26500",
  "16661": "20180",
  "16662": "11020",
  "16666": "20180",
  "16668": "27780",
  "16669": "26500",
  "16671": "20180",
  "16673": "11020",
  "16674": "26500",
  "16677": "44300",
  "16678": "26500",
  "16680": "20180",
  "16682": "11020",
  "16683": "26500",
  "16685": "26500",
  "16686": "11020",
  "16692": "20180",
  "16693": "11020",
  "16701": "14620",
  "16731": "14620",
  "16734": "41260",
  "16735": "14620",
  "16743": "14620",
  "16749": "14620",
  "16801": "44300",
  "16803": "44300",
  "16820": "44300",
  "16821": "20180",
  "16822": "30820",
  "16823": "44300",
  "16826": "44300",
  "16827": "44300",
  "16828": "44300",
  "16829": "44300",
  "16830": "20180",
  "16832": "44300",
  "16833": "20180",
  "16834": "20180",
  "16836": "20180",
  "16837": "20180",
  "16838": "20180",
  "16839": "20180",
  "16840": "20180",
  "16841": "44300",
  "16843": "20180",
  "16844": "44300",
  "16845": "44300",
  "16847": "20180",
  "16849": "20180",
  "16851": "44300",
  "16852": "44300",
  "16853": "44300",
  "16854": "44300",
  "16858": "20180",
  "16859": "44300",
  "16860": "20180",
  "16861": "20180",
  "16863": "20180",
  "16865": "44300",
  "16866": "44300",
  "16868": "44300",
  "16870": "44300",
  "16871": "30820",
  "16872": "44300",
  "16874": "44300",
  "16875": "44300",
  "16876": "20180",
  "16877": "44300",
  "16878": "20180",
  "16879": "20180",
  "16881": "20180",
  "16882": "44300",
  "16914": "42380",
  "16925": "42380",
  "16926": "42380",
  "16947": "42380",
  "17002": "30380",
  "17003": "30140",
  "17004": "30380",
  "17005": "25420",
  "17006": "25420",
  "17007": "25420",
  "17009": "30380",
  "17011": "25420",
  "17013": "25420",
  "17015": "25420",
  "17017": "44980",
  "17018": "25420",
  "17019": "49620",
  "17020": "25420",
  "17022": "29540",
  "17023": "25420",
  "17024": "25420",
  "17025": "25420",
  "17026": "30140",
  "17028": "25420",
  "17029": "30380",
  "17030": "25420",
  "17032": "25420",
  "17033": "25420",
  "17034": "25420",
  "17036": "25420",
  "17037": "25420",
  "17038": "30140",
  "17040": "25420",
  "17042": "30140",
  "17043": "25420",
  "17044": "30380",
  "17045": "25420",
  "17046": "30140",
  "17047": "25420",
  "17048": "25420",
  "17050": "25420",
  "17051": "30380",
  "17052": "26500",
  "17053": "25420",
  "17055": "25420",
  "17057": "25420",
  "17060": "26500",
  "17061": "25420",
  "17062": "25420",
  "17063": "30380",
  "17064": "30140",
  "17065": "25420",
  "17066": "26500",
  "17067": "30140",
  "17068": "25420",
  "17070": "49620",
  "17073": "30140",
  "17074": "25420",
  "17078": "30140",
  "17080": "25420",
  "17084": "30380",
  "17087": "39740",
  "17088": "30140",
  "17090": "25420",
  "17093": "25420",
  "17098": "25420",
  "17099": "30380",
  "17101": "25420",
  "17102": "25420",
  "17103": "25420",
  "17104": "25420",
  "17109": "25420",
  "17110": "25420",
  "17111": "25420",
  "17112": "25420",
  "17113": "25420",
  "17201": "16540",
  "17202": "16540",
  "17213": "26500",
  "17214": "16540",
  "17219": "16540",
  "17220": "16540",
  "17221": "16540",
  "17222": "16540",
  "17224": "16540",
  "17225": "16540",
  "17235": "16540",
  "17236": "16540",
  "17237": "16540",
  "17240": "25420",
  "17241": "25420",
  "17243": "26500",
  "17244": "16540",
  "17249": "26500",
  "17252": "16540",
  "17255": "26500",
  "17257": "25420",
  "17260": "26500",
  "17262": "16540",
  "17264": "26500",
  "17265": "16540",
  "17266": "25420",
  "17268": "16540",
  "17271": "16540",
  "17301": "23900",
  "17302": "49620",
  "17304": "23900",
  "17306": "23900",
  "17307": "23900",
  "17309": "49620",
  "17313": "49620",
  "17314": "49620",
  "17315": "49620",
  "17316": "23900",
  "17318": "49620",
  "17319": "49620",
  "17320": "23900",
  "17321": "49620",
  "17322": "49620",
  "17324": "25420",
  "17325": "23900",
  "17327": "49620",
  "17329": "49620",
  "17331": "49620",
  "17339": "49620",
  "17340": "23900",
  "17344": "23900",
  "17345": "49620",
  "17347": "49620",
  "17349": "49620",
  "17350": "23900",
  "17352": "49620",
  "17353": "23900",
  "17356": "49620",
  "17360": "49620",
  "17361": "49620",
  "17362": "49620",
  "17363": "49620",
  "17364": "49620",
  "17365": "49620",
  "17366": "49620",
  "17368": "49620",
  "17370": "49620",
  "17372": "23900",
  "17401": "49620",
  "17402": "49620",
  "17403": "49620",
  "17404": "49620",
  "17406": "49620",
  "17407": "49620",
  "17408": "49620",
  "17501": "29540",
  "17502": "29540",
  "17505": "29540",
  "17508": "29540",
  "17509": "29540",
  "17512": "29540",
  "17516": "29540",
  "17517": "29540",
  "17518": "29540",
  "17519": "29540",
  "17520": "29540",
  "17522": "29540",
  "17527": "29540",
  "17529": "29540",
  "17532": "29540",
  "17535": "29540",
  "17536": "29540",
  "17538": "29540",
  "17540": "29540",
  "17543": "29540",
  "17545": "29540",
  "17547": "29540",
  "17550": "29540",
  "17551": "29540",
  "17552": "29540",
  "17554": "29540",
  "17555": "29540",
  "17557": "29540",
  "17560": "29540",
  "17562": "29540",
  "17563": "29540",
  "17565": "29540",
  "17566": "29540",
  "17569": "29540",
  "17572": "29540",
  "17578": "29540",
  "17579": "29540",
  "17581": "29540",
  "17582": "29540",
  "17584": "29540",
  "17601": "29540",
  "17602": "29540",
  "17603": "29540",
  "17701": "48700",
  "17702": "48700",
  "17721": "30820",
  "17723": "48700",
  "17724": "42380",
  "17727": "48700",
  "17728": "48700",
  "17737": "48700",
  "17740": "48700",
  "17744": "48700",
  "17745": "30820",
  "17747": "30820",
  "17751": "30820",
  "17752": "48700",
  "17754": "48700",
  "17756": "48700",
  "17760": "30820",
  "17762": "48700",
  "17763": "48700",
  "17764": "30820",
  "17765": "48700",
  "17771": "48700",
  "17772": "44980",
  "17774": "48700",
  "17776": "48700",
  "17777": "44980",
  "17778": "30820",
  "17801": "44980",
  "17810": "30260",
  "17812": "42780",
  "17813": "42780",
  "17814": "14100",
  "17815": "14100",
  "17820": "14100",
  "17821": "14100",
  "17823": "44980",
  "17824": "44980",
  "17827": "42780",
  "17830": "44980",
  "17832": "44980",
  "17834": "44980",
  "17837": "30260",
  "17840": "44980",
  "17841": "30380",
  "17842": "42780",
  "17844": "30260",
  "17845": "30260",
  "17847": "44980",
  "17850": "44980",
  "17851": "44980",
  "17853": "42780",
  "17855": "30260",
  "17856": "30260",
  "17857": "44980",
  "17860": "44980",
  "17864": "42780",
  "17866": "44980",
  "17870": "42780",
  "17872": "44980",
  "17876": "42780",
  "17878": "14100",
  "17881": "44980",
  "17886": "30260",
  "17889": "42780",
  "17901": "39060",
  "17921": "39060",
  "17922": "39060",
  "17929": "39060",
  "17931": "39060",
  "17935": "39060",
  "17938": "39060",
  "17948": "39060",
  "17954": "39060",
  "17959": "39060",
  "17960": "39060",
  "17961": "39060",
  "17963": "39060",
  "17965": "39060",
  "17967": "39060",
  "17970": "39060",
  "17972": "39060",
  "17976": "39060",
  "17978": "25420",
  "17980": "39060",
  "17981": "39060",
  "17983": "39060",
  "17985": "14100",
  "18011": "39740",
  "18013": "10900",
  "18014": "10900",
  "18015": "10900",
  "18017": "10900",
  "18018": "10900",
  "18020": "10900",
  "18030": "10900",
  "18031": "10900",
  "18032": "10900",
  "18034": "10900",
  "18035": "10900",
  "18036": "10900",
  "18037": "10900",
  "18038": "10900",
  "18040": "10900",
  "18041": "37980",
  "18042": "10900",
  "18045": "10900",
  "18049": "10900",
  "18051": "10900",
  "18052": "10900",
  "18053": "10900",
  "18054": "37980",
  "18055": "10900",
  "18056": "39740",
  "18058": "20700",
  "18059": "10900",
  "18062": "10900",
  "18063": "10900",
  "18064": "10900",
  "18066": "10900",
  "18067": "10900",
  "18069": "10900",
  "18070": "37980",
  "18071": "10900",
  "18072": "10900",
  "18073": "37980",
  "18074": "37980",
  "18076": "37980",
  "18077": "37980",
  "18078": "10900",
  "18079": "10900",
  "18080": "10900",
  "18083": "10900",
  "18085": "10900",
  "18087": "10900",
  "18088": "10900",
  "18091": "10900",
  "18092": "10900",
  "18101": "10900",
  "18102": "10900",
  "18103": "10900",
  "18104": "10900",
  "18106": "10900",
  "18109": "10900",
  "18201": "42540",
  "18202": "42540",
  "18210": "10900",
  "18211": "39060",
  "18214": "39060",
  "18216": "10900",
  "18218": "39060",
  "18219": "42540",
  "18222": "42540",
  "18224": "42540",
  "18229": "10900",
  "18232": "10900",
  "18235": "10900",
  "18237": "39060",
  "18240": "10900",
  "18241": "42540",
  "18249": "42540",
  "18250": "10900",
  "18252": "39060",
  "18254": "10900",
  "18255": "10900",
  "18301": "20700",
  "18302": "20700",
  "18321": "20700",
  "18322": "20700",
  "18323": "20700",
  "18324": "35620",
  "18325": "20700",
  "18326": "20700",
  "18327": "20700",
  "18328": "35620",
  "18330": "20700",
  "18331": "20700",
  "18332": "20700",
  "18333": "20700",
  "18334": "20700",
  "18336": "35620",
  "18337": "35620",
  "18343": "10900",
  "18344": "20700",
  "18346": "20700",
  "18347": "20700",
  "18350": "20700",
  "18353": "20700",
  "18354": "20700",
  "18355": "20700",
  "18360": "20700",
  "18370": "20700",
  "18371": "35620",
  "18372": "20700",
  "18403": "42540",
  "18407": "42540",
  "18411": "42540",
  "18414": "42540",
  "18419": "42540",
  "18425": "35620",
  "18426": "35620",
  "18428": "35620",
  "18433": "42540",
  "18434": "42540",
  "18435": "35620",
  "18444": "42540",
  "18445": "35620",
  "18446": "42540",
  "18447": "42540",
  "18451": "35620",
  "18452": "42540",
  "18458": "35620",
  "18464": "35620",
  "18466": "20700",
  "18471": "42540",
  "18503": "42540",
  "18504": "42540",
  "18505": "42540",
  "18507": "42540",
  "18508": "42540",
  "18509": "42540",
  "18510": "42540",
  "18512": "42540",
  "18517": "42540",
  "18518": "42540",
  "18519": "42540",
  "18603": "14100",
  "18610": "20700",
  "18612": "42540",
  "18615": "42540",
  "18617": "42540",
  "18618": "42540",
  "18621": "42540",
  "18623": "42540",
  "18624": "10900",
  "18629": "42540",
  "18634": "42540",
  "18635": "14100",
  "18636": "42540",
  "18640": "42540",
  "18641": "42540",
  "18642": "42540",
  "18643": "42540",
  "18644": "42540",
  "18651": "42540",
  "18653": "42540",
  "18655": "42540",
  "18656": "42540",
  "18657": "42540",
  "18660": "42540",
  "18661": "42540",
  "18701": "42540",
  "18702": "42540",
  "18704": "42540",
  "18705": "42540",
  "18706": "42540",
  "18707": "42540",
  "18708": "42540",
  "18709": "42540",
  "18810": "42380",
  "18829": "42380",
  "18831": "42380",
  "18832": "42380",
  "18833": "42380",
  "18837": "42380",
  "18840": "42380",
  "18845": "42380",
  "18846": "42380",
  "18848": "42380",
  "18850": "42380",
  "18851": "42380",
  "18853": "42380",
  "18854": "42380",
  "18901": "37980",
  "18902": "37980",
  "18913": "37980",
  "18914": "37980",
  "18915": "37980",
  "18917": "37980",
  "18920": "37980",
  "18923": "37980",
  "18925": "37980",
  "18929": "37980",
  "18930": "37980",
  "18932": "37980",
  "18933": "37980",
  "18934": "37980",
  "18936": "37980",
  "18938": "37980",
  "18940": "37980",
  "18942": "37980",
  "18944": "37980",
  "18947": "37980",
  "18951": "37980",
  "18954": "37980",
  "18955": "37980",
  "18960": "37980",
  "18964": "37980",
  "18966": "37980",
  "18969": "37980",
  "18972": "37980",
  "18974": "37980",
  "18976": "37980",
  "18977": "37980",
  "19001": "37980",
  "19002": "37980",
  "19003": "37980",
  "19004": "37980",
  "19006": "37980",
  "19007": "37980",
  "19008": "37980",
  "19009": "37980",
  "19010": "37980",
  "19012": "37980",
  "19013": "37980",
  "19014": "37980",
  "19015": "37980",
  "19018": "37980",
  "19020": "37980",
  "19021": "37980",
  "19022": "37980",
  "19023": "37980",
  "19025": "37980",
  "19026": "37980",
  "19027": "37980",
  "19029": "37980",
  "19030": "37980",
  "19031": "37980",
  "19032": "37980",
  "19033": "37980",
  "19034": "37980",
  "19035": "37980",
  "19036": "37980",
  "19038": "37980",
  "19040": "37980",
  "19041": "37980",
  "19043": "37980",
  "19044": "37980",
  "19046": "37980",
  "19047": "37980",
  "19050": "37980",
  "19053": "37980",
  "19054": "37980",
  "19055": "37980",
  "19056": "37980",
  "19057": "37980",
  "19060": "37980",
  "19061": "37980",
  "19063": "37980",
  "19064": "37980",
  "19066": "37980",
  "19067": "37980",
  "19070": "37980",
  "19072": "37980",
  "19073": "37980",
  "19074": "37980",
  "19075": "37980",
  "19076": "37980",
  "19078": "37980",
  "19079": "37980",
  "19081": "37980",
  "19082": "37980",
  "19083": "37980",
  "19085": "37980",
  "19086": "37980",
  "19087": "37980",
  "19090": "37980",
  "19094": "37980",
  "19095": "37980",
  "19096": "37980",
  "19102": "37980",
  "19103": "37980",
  "19104": "37980",
  "19106": "37980",
  "19107": "37980",
  "19111": "37980",
  "19114": "37980",
  "19115": "37980",
  "19116": "37980",
  "19118": "37980",
  "19119": "37980",
  "19120": "37980",
  "19121": "37980",
  "19122": "37980",
  "19123": "37980",
  "19124": "37980",
  "19125": "37980",
  "19126": "37980",
  "19127": "37980",
  "19128": "37980",
  "19129": "37980",
  "19130": "37980",
  "19131": "37980",
  "19132": "37980",
  "19133": "37980",
  "19134": "37980",
  "19135": "37980",
  "19136": "37980",
  "19137": "37980",
  "19138": "37980",
  "19139": "37980",
  "19140": "37980",
  "19141": "37980",
  "19142": "37980",
  "19143": "37980",
  "19144": "37980",
  "19145": "37980",
  "19146": "37980",
  "19147": "37980",
  "19148": "37980",
  "19149": "37980",
  "19150": "37980",
  "19151": "37980",
  "19152": "37980",
  "19153": "37980",
  "19154": "37980",
  "19301": "37980",
  "19310": "37980",
  "19311": "37980",
  "19312": "37980",
  "19317": "37980",
  "19319": "37980",
  "19320": "37980",
  "19330": "37980",
  "19333": "37980",
  "19335": "37980",
  "19341": "37980",
  "19342": "37980",
  "19343": "37980",
  "19344": "37980",
  "19348": "37980",
  "19350": "37980",
  "19352": "37980",
  "19355": "37980",
  "19362": "37980",
  "19363": "37980",
  "19365": "37980",
  "19372": "37980",
  "19373": "37980",
  "19374": "37980",
  "19380": "37980",
  "19382": "37980",
  "19390": "37980",
  "19401": "37980",
  "19403": "37980",
  "19405": "37980",
  "19406": "37980",
  "19422": "37980",
  "19425": "37980",
  "19426": "37980",
  "19428": "37980",
  "19435": "37980",
  "19437": "37980",
  "19438": "37980",
  "19440": "37980",
  "19444": "37980",
  "19446": "37980",
  "19453": "37980",
  "19454": "37980",
  "19456": "37980",
  "19460": "37980",
  "19462": "37980",
  "19464": "37980",
  "19465": "37980",
  "19468": "37980",
  "19473": "37980",
  "19474": "37980",
  "19475": "37980",
  "19481": "37980",
  "19492": "37980",
  "19501": "29540",
  "19503": "39740",
  "19504": "39740",
  "19505": "39740",
  "19506": "39740",
  "19507": "39740",
  "19508": "39740",
  "19510": "39740",
  "19512": "39740",
  "19518": "39740",
  "19520": "37980",
  "19522": "39740",
  "19525": "37980",
  "19526": "39740",
  "19529": "39740",
  "19530": "39740",
  "19533": "39740",
  "19534": "39740",
  "19539": "39740",
  "19540": "39740",
  "19541": "39740",
  "19543": "39740",
  "19547": "39740",
  "19551": "39740",
  "19555": "39740",
  "19560": "39740",
  "19562": "39740",
  "19565": "39740",
  "19567": "39740",
  "19601": "39740",
  "19602": "39740",
  "19604": "39740",
  "19605": "39740",
  "19606": "39740",
  "19607": "39740",
  "19608": "39740",
  "19609": "39740",
  "19610": "39740",
  "19611": "39740",
  "19701": "37980",
  "19702": "37980",
  "19703": "37980",
  "19706": "37980",
  "19707": "37980",
  "19709": "37980",
  "19711": "37980",
  "19713": "37980",
  "19720": "37980",
  "19730": "37980",
  "19731": "37980",
  "19732": "37980",
  "19733": "37980",
  "19734": "37980",
  "19801": "37980",
  "19802": "37980",
  "19803": "37980",
  "19804": "37980",
  "19805": "37980",
  "19806": "37980",
  "19807": "37980",
  "19808": "37980",
  "19809": "37980",
  "19810": "37980",
  "19901": "20100",
  "19904": "20100",
  "19930": "41540",
  "19931": "41540",
  "19933": "41540",
  "19934": "20100",
  "19938": "20100",
  "19939": "41540",
  "19940": "41540",
  "19941": "41540",
  "19943": "20100",
  "19944": "41540",
  "19945": "41540",
  "19946": "20100",
  "19947": "41540",
  "19950": "41540",
  "19951": "41540",
  "19952": "20100",
  "19953": "20100",
  "19954": "20100",
  "19956": "41540",
  "19958": "41540",
  "19960": "41540",
  "19962": "20100",
  "19963": "20100",
  "19964": "20100",
  "19966": "41540",
  "19967": "41540",
  "19968": "41540",
  "19970": "41540",
  "19971": "41540",
  "19973": "41540",
  "19975": "41540",
  "19977": "20100",
  "19979": "20100",
  "20001": "47900",
  "20002": "47900",
  "20003": "47900",
  "20004": "47900",
  "20005": "47900",
  "20006": "47900",
  "20007": "47900",
  "20008": "47900",
  "20009": "47900",
  "20010": "47900",
  "20011": "47900",
  "20012": "47900",
  "20015": "47900",
  "20016": "47900",
  "20017": "47900",
  "20018": "47900",
  "20019": "47900",
  "20020": "47900",
  "20024": "47900",
  "20032": "47900",
  "20036": "47900",
  "20037": "47900",
  "20105": "47900",
  "20106": "47900",
  "20109": "47900",
  "20110": "47900",
  "20111": "47900",
  "20112": "47900",
  "20115": "47900",
  "20117": "47900",
  "20119": "47900",
  "20120": "47900",
  "20121": "47900",
  "20124": "47900",
  "20129": "47900",
  "20130": "47900",
  "20132": "47900",
  "20135": "47900",
  "20136": "47900",
  "20137": "47900",
  "20141": "47900",
  "20143": "47900",
  "20144": "47900",
  "20147": "47900",
  "20148": "47900",
  "20151": "47900",
  "20152": "47900",
  "20155": "47900",
  "20158": "47900",
  "20164": "47900",
  "20165": "47900",
  "20166": "47900",
  "20169": "47900",
  "20170": "47900",
  "20171": "47900",
  "20175": "47900",
  "20176": "47900",
  "20180": "47900",
  "20181": "47900",
  "20184": "47900",
  "20186": "47900",
  "20187": "47900",
  "20190": "47900",
  "20191": "47900",
  "20194": "47900",
  "20197": "47900",
  "20198": "47900",
  "20601": "47900",
  "20602": "47900",
  "20603": "47900",
  "20606": "15680",
  "20607": "47900",
  "20608": "47900",
  "20609": "15680",
  "20611": "47900",
  "20612": "47900",
  "20613": "47900",
  "20615": "47900",
  "20616": "47900",
  "20617": "47900",
  "20618": "15680",
  "20619": "15680",
  "20620": "15680",
  "20621": "15680",
  "20622": "15680",
  "20623": "47900",
  "20624": "15680",
  "20625": "47900",
  "20626": "15680",
  "20628": "15680",
  "20629": "47900",
  "20630": "15680",
  "20632": "47900",
  "20634": "15680",
  "20636": "15680",
  "20637": "47900",
  "20639": "47900",
  "20640": "47900",
  "20645": "47900",
  "20646": "47900",
  "20650": "15680",
  "20653": "15680",
  "20657": "47900",
  "20658": "47900",
  "20659": "15680",
  "20662": "47900",
  "20664": "47900",
  "20667": "15680",
  "20674": "15680",
  "20675": "47900",
  "20676": "47900",
  "20677": "47900",
  "20678": "47900",
  "20680": "15680",
  "20684": "15680",
  "20685": "47900",
  "20687": "15680",
  "20688": "47900",
  "20689": "47900",
  "20690": "15680",
  "20692": "15680",
  "20693": "47900",
  "20695": "47900",
  "20705": "47900",
  "20706": "47900",
  "20707": "47900",
  "20708": "47900",
  "20710": "47900",
  "20711": "12580",
  "20712": "47900",
  "20714": "47900",
  "20715": "47900",
  "20716": "47900",
  "20720": "47900",
  "20721": "47900",
  "20722": "47900",
  "20723": "12580",
  "20724": "12580",
  "20732": "47900",
  "20733": "12580",
  "20735": "47900",
  "20736": "47900",
  "20737": "47900",
  "20740": "47900",
  "20743": "47900",
  "20744": "47900",
  "20745": "47900",
  "20746": "47900",
  "20747": "47900",
  "20748": "47900",
  "20751": "12580",
  "20754": "47900",
  "20758": "12580",
  "20759": "12580",
  "20763": "12580",
  "20764": "12580",
  "20765": "12580",
  "20769": "47900",
  "20770": "47900",
  "20772": "47900",
  "20774": "47900",
  "20776": "12580",
  "20777": "12580",
  "20778": "12580",
  "20779": "12580",
  "20781": "47900",
  "20782": "47900",
  "20783": "47900",
  "20784": "47900",
  "20785": "47900",
  "20794": "12580",
  "20812": "47900",
  "20814": "47900",
  "20815": "47900",
  "20816": "47900",
  "20817": "47900",
  "20818": "47900",
  "20832": "47900",
  "20833": "47900",
  "20837": "47900",
  "20838": "47900",
  "20839": "47900",
  "20841": "47900",
  "20842": "47900",
  "20850": "47900",
  "20851": "47900",
  "20852": "47900",
  "20853": "47900",
  "20854": "47900",
  "20855": "47900",
  "20860": "47900",
  "20861": "47900",
  "20862": "47900",
  "20866": "47900",
  "20868": "47900",
  "20871": "47900",
  "20872": "47900",
  "20874": "47900",
  "20876": "47900",
  "20877": "47900",
  "20878": "47900",
  "20879": "47900",
  "20880": "47900",
  "20882": "47900",
  "20886": "47900",
  "20895": "47900",
  "20896": "47900",
  "20901": "47900",
  "20902": "47900",
  "20903": "47900",
  "20904": "47900",
  "20905": "47900",
  "20906": "47900",
  "20910": "47900",
  "20912": "47900",
  "21001": "12580",
  "21009": "12580",
  "21012": "12580",
  "21013": "12580",
  "21014": "12580",
  "21015": "12580",
  "21017": "12580",
  "21028": "12580",
  "21029": "12580",
  "21030": "12580",
  "21032": "12580",
  "21034": "12580",
  "21035": "12580",
  "21036": "12580",
  "21037": "12580",
  "21040": "12580",
  "21042": "12580",
  "21043": "12580",
  "21044": "12580",
  "21045": "12580",
  "21046": "12580",
  "21047": "12580",
  "21048": "12580",
  "21050": "12580",
  "21051": "12580",
  "21052": "12580",
  "21053": "12580",
  "21054": "12580",
  "21056": "12580",
  "21057": "12580",
  "21060": "12580",
  "21061": "12580",
  "21071": "12580",
  "21074": "12580",
  "21075": "12580",
  "21076": "12580",
  "21077": "12580",
  "21078": "12580",
  "21082": "12580",
  "21084": "12580",
  "21085": "12580",
  "21087": "12580",
  "21090": "12580",
  "21093": "12580",
  "21102": "12580",
  "21104": "12580",
  "21108": "12580",
  "21111": "12580",
  "21113": "12580",
  "21114": "12580",
  "21117": "12580",
  "21120": "12580",
  "21122": "12580",
  "21128": "12580",
  "21131": "12580",
  "21132": "12580",
  "21133": "12580",
  "21136": "12580",
  "21140": "12580",
  "21144": "12580",
  "21146": "12580",
  "21152": "12580",
  "21153": "12580",
  "21154": "12580",
  "21155": "12580",
  "21156": "12580",
  "21157": "12580",
  "21158": "12580",
  "21160": "12580",
  "21161": "12580",
  "21162": "12580",
  "21163": "12580",
  "21201": "12580",
  "21202": "12580",
  "21204": "12580",
  "21205": "12580",
  "21206": "12580",
  "21207": "12580",
  "21208": "12580",
  "21209": "12580",
  "21210": "12580",
  "21211": "12580",
  "21212": "12580",
  "21213": "12580",
  "21214": "12580",
  "21215": "12580",
  "21216": "12580",
  "21217": "12580",
  "21218": "12580",
  "21219": "12580",
  "21220": "12580",
  "21221": "12580",
  "21222": "12580",
  "21223": "12580",
  "21224": "12580",
  "21225": "12580",
  "21226": "12580",
  "21227": "12580",
  "21228": "12580",
  "21229": "12580",
  "21230": "12580",
  "21231": "12580",
  "21234": "12580",
  "21236": "12580",
  "21237": "12580",
  "21239": "12580",
  "21244": "12580",
  "21286": "12580",
  "21401": "12580",
  "21403": "12580",
  "21405": "12580",
  "21409": "12580",
  "21502": "19060",
  "21524": "19060",
  "21529": "19060",
  "21530": "19060",
  "21532": "19060",
  "21539": "19060",
  "21545": "19060",
  "21555": "19060",
  "21557": "19060",
  "21562": "19060",
  "21601": "20660",
  "21607": "12580",
  "21612": "20660",
  "21613": "15700",
  "21617": "12580",
  "21619": "12580",
  "21622": "15700",
  "21623": "12580",
  "21624": "20660",
  "21625": "20660",
  "21628": "12580",
  "21631": "15700",
  "21634": "15700",
  "21638": "12580",
  "21643": "15700",
  "21647": "20660",
  "21648": "15700",
  "21651": "12580",
  "21652": "20660",
  "21654": "20660",
  "21657": "12580",
  "21658": "12580",
  "21659": "15700",
  "21662": "20660",
  "21663": "20660",
  "21664": "15700",
  "21665": "20660",
  "21666": "12580",
  "21668": "12580",
  "21669": "15700",
  "21671": "20660",
  "21672": "15700",
  "21673": "20660",
  "21676": "20660",
  "21677": "15700",
  "21679": "20660",
  "21701": "47900",
  "21702": "47900",
  "21703": "47900",
  "21704": "47900",
  "21710": "47900",
  "21711": "25180",
  "21713": "25180",
  "21716": "47900",
  "21719": "25180",
  "21722": "25180",
  "21723": "12580",
  "21727": "47900",
  "21733": "25180",
  "21734": "25180",
  "21737": "12580",
  "21738": "12580",
  "21740": "25180",
  "21742": "25180",
  "21750": "25180",
  "21754": "47900",
  "21755": "47900",
  "21756": "25180",
  "21757": "47900",
  "21758": "47900",
  "21762": "47900",
  "21766": "19060",
  "21767": "25180",
  "21769": "47900",
  "21770": "47900",
  "21771": "47900",
  "21773": "47900",
  "21774": "47900",
  "21776": "12580",
  "21777": "47900",
  "21778": "47900",
  "21779": "25180",
  "21780": "47900",
  "21782": "25180",
  "21783": "25180",
  "21784": "12580",
  "21787": "12580",
  "21788": "47900",
  "21791": "47900",
  "21793": "47900",
  "21794": "12580",
  "21795": "25180",
  "21797": "12580",
  "21798": "47900",
  "21801": "41540",
  "21804": "41540",
  "21811": "41540",
  "21813": "41540",
  "21814": "41540",
  "21817": "41540",
  "21821": "41540",
  "21822": "41540",
  "21824": "41540",
  "21826": "41540",
  "21829": "41540",
  "21830": "41540",
  "21837": "41540",
  "21838": "41540",
  "21840": "41540",
  "21841": "41540",
  "21842": "41540",
  "21849": "41540",
  "21850": "41540",
  "21851": "41540",
  "21853": "41540",
  "21856": "41540",
  "21861": "41540",
  "21863": "41540",
  "21864": "41540",
  "21865": "41540",
  "21869": "15700",
  "21871": "41540",
  "21872": "41540",
  "21874": "41540",
  "21875": "41540",
  "21901": "37980",
  "21903": "37980",
  "21904": "37980",
  "21911": "37980",
  "21912": "37980",
  "21913": "37980",
  "21914": "37980",
  "21915": "37980",
  "21917": "37980",
  "21918": "37980",
  "21919": "37980",
  "21920": "37980",
  "21921": "37980",
  "22003": "47900",
  "22015": "47900",
  "22025": "47900",
  "22026": "47900",
  "22027": "47900",
  "22030": "47900",
  "22031": "47900",
  "22032": "47900",
  "22033": "47900",
  "22039": "47900",
  "22041": "47900",
  "22042": "47900",
  "22043": "47900",
  "22044": "47900",
  "22046": "47900",
  "22060": "47900",
  "22066": "47900",
  "22079": "47900",
  "22101": "47900",
  "22102": "47900",
  "22124": "47900",
  "22125": "47900",
  "22134": "47900",
  "22150": "47900",
  "22151": "47900",
  "22152": "47900",
  "22153": "47900",
  "22172": "47900",
  "22180": "47900",
  "22181": "47900",
  "22182": "47900",
  "22191": "47900",
  "22192": "47900",
  "22193": "47900",
  "22201": "47900",
  "22202": "47900",
  "22203": "47900",
  "22204": "47900",
  "22205": "47900",
  "22206": "47900",
  "22207": "47900",
  "22209": "47900",
  "22213": "47900",
  "22301": "47900",
  "22302": "47900",
  "22303": "47900",
  "22304": "47900",
  "22305": "47900",
  "22306": "47900",
  "22307": "47900",
  "22308": "47900",
  "22309": "47900",
  "22310": "47900",
  "22311": "47900",
  "22312": "47900",
  "22314": "47900",
  "22315": "47900",
  "22401": "47900",
  "22405": "47900",
  "22406": "47900",
  "22407": "47900",
  "22408": "47900",
  "22534": "47900",
  "22551": "47900",
  "22553": "47900",
  "22554": "47900",
  "22556": "47900",
  "22601": "49020",
  "22602": "49020",
  "22603": "49020",
  "22610": "47900",
  "22611": "47900",
  "22620": "47900",
  "22623": "47900",
  "22624": "49020",
  "22625": "49020",
  "22627": "47900",
  "22630": "47900",
  "22637": "49020",
  "22639": "47900",
  "22640": "47900",
  "22642": "47900",
  "22643": "47900",
  "22645": "49020",
  "22654": "49020",
  "22655": "49020",
  "22656": "49020",
  "22663": "47900",
  "22701": "47900",
  "22709": "47900",
  "22712": "47900",
  "22713": "47900",
  "22714": "47900",
  "22715": "47900",
  "22716": "47900",
  "22718": "47900",
  "22719": "47900",
  "22720": "47900",
  "22722": "47900",
  "22724": "47900",
  "22726": "47900",
  "22727": "47900",
  "22728": "47900",
  "22731": "47900",
  "22732": "47900",
  "22733": "47900",
  "22734": "47900",
  "22735": "47900",
  "22736": "47900",
  "22737": "47900",
  "22738": "47900",
  "22740": "47900",
  "22742": "47900",
  "22743": "47900",
  "22747": "47900",
  "22749": "47900",
  "22801": "25500",
  "22802": "25500",
  "22812": "25500",
  "22815": "25500",
  "22821": "25500",
  "22827": "25500",
  "22830": "25500",
  "22831": "25500",
  "22834": "25500",
  "22840": "25500",
  "22841": "25500",
  "22843": "44420",
  "22846": "25500",
  "22850": "25500",
  "22853": "25500",
  "22901": "16820",
  "22902": "16820",
  "22903": "16820",
  "22911": "16820",
  "22920": "16820",
  "22922": "16820",
  "22931": "16820",
  "22932": "16820",
  "22935": "16820",
  "22936": "16820",
  "22937": "16820",
  "22938": "16820",
  "22939": "44420",
  "22940": "16820",
  "22943": "16820",
  "22947": "16820",
  "22949": "16820",
  "22952": "44420",
  "22958": "16820",
  "22959": "16820",
  "22963": "16820",
  "22964": "16820",
  "22967": "16820",
  "22968": "16820",
  "22969": "16820",
  "22971": "16820",
  "22973": "16820",
  "22974": "16820",
  "22976": "16820",
  "22980": "44420",
  "23002": "40060",
  "23005": "40060",
  "23009": "40060",
  "23011": "40060",
  "23015": "40060",
  "23022": "16820",
  "23030": "40060",
  "23035": "47260",
  "23038": "40060",
  "23039": "40060",
  "23047": "40060",
  "23050": "47260",
  "23055": "16820",
  "23056": "47260",
  "23059": "40060",
  "23060": "40060",
  "23061": "47260",
  "23062": "47260",
  "23063": "40060",
  "23065": "40060",
  "23069": "40060",
  "23072": "47260",
  "23075": "40060",
  "23076": "47260",
  "23083": "40060",
  "23084": "16820",
  "23086": "40060",
  "23089": "40060",
  "23102": "40060",
  "23103": "40060",
  "23106": "40060",
  "23109": "47260",
  "23110": "40060",
  "23111": "40060",
  "23112": "40060",
  "23113": "40060",
  "23114": "40060",
  "23116": "40060",
  "23120": "40060",
  "23124": "40060",
  "23128": "47260",
  "23129": "40060",
  "23138": "47260",
  "23139": "40060",
  "23140": "40060",
  "23141": "40060",
  "23146": "40060",
  "23148": "40060",
  "23150": "40060",
  "23153": "40060",
  "23156": "40060",
  "23168": "47260",
  "23177": "40060",
  "23181": "40060",
  "23185": "47260",
  "23188": "47260",
  "23192": "40060",
  "23219": "40060",
  "23220": "40060",
  "23221": "40060",
  "23222": "40060",
  "23223": "40060",
  "23224": "40060",
  "23225": "40060",
  "23226": "40060",
  "23227": "40060",
  "23228": "40060",
  "23229": "40060",
  "23230": "40060",
  "23231": "40060",
  "23233": "40060",
  "23234": "40060",
  "23235": "40060",
  "23236": "40060",
  "23237": "40060",
  "23238": "40060",
  "23294": "40060",
  "23314": "47260",
  "23315": "47260",
  "23320": "47260",
  "23321": "47260",
  "23322": "47260",
  "23323": "47260",
  "23324": "47260",
  "23325": "47260",
  "23430": "47260",
  "23432": "47260",
  "23433": "47260",
  "23434": "47260",
  "23435": "47260",
  "23436": "47260",
  "23437": "47260",
  "23438": "47260",
  "23451": "47260",
  "23452": "47260",
  "23453": "47260",
  "23454": "47260",
  "23455": "47260",
  "23456": "47260",
  "23457": "47260",
  "23462": "47260",
  "23464": "47260",
  "23487": "47260",
  "23502": "47260",
  "23503": "47260",
  "23504": "47260",
  "23505": "47260",
  "23507": "47260",
  "23508": "47260",
  "23509": "47260",
  "23510": "47260",
  "23513": "47260",
  "23517": "47260",
  "23518": "47260",
  "23523": "47260",
  "23601": "47260",
  "23602": "47260",
  "23603": "47260",
  "23605": "47260",
  "23606": "47260",
  "23607": "47260",
  "23608": "47260",
  "23661": "47260",
  "23662": "47260",
  "23663": "47260",
  "23664": "47260",
  "23666": "47260",
  "23669": "47260",
  "23690": "47260",
  "23692": "47260",
  "23693": "47260",
  "23696": "47260",
  "23701": "47260",
  "23702": "47260",
  "23703": "47260",
  "23704": "47260",
  "23707": "47260",
  "23803": "40060",
  "23805": "40060",
  "23827": "47260",
  "23828": "47260",
  "23829": "47260",
  "23830": "40060",
  "23831": "40060",
  "23832": "40060",
  "23833": "40060",
  "23834": "40060",
  "23836": "40060",
  "23837": "47260",
  "23838": "40060",
  "23840": "40060",
  "23841": "40060",
  "23842": "40060",
  "23844": "47260",
  "23850": "40060",
  "23851": "47260",
  "23860": "40060",
  "23866": "47260",
  "23872": "40060",
  "23874": "47260",
  "23875": "40060",
  "23878": "47260",
  "23882": "40060",
  "23885": "40060",
  "23890": "40060",
  "23894": "40060",
  "23897": "40060",
  "23898": "47260",
  "24012": "40220",
  "24013": "40220",
  "24014": "40220",
  "24015": "40220",
  "24016": "40220",
  "24017": "40220",
  "24018": "40220",
  "24019": "40220",
  "24054": "32300",
  "24055": "32300",
  "24059": "40220",
  "24060": "13980",
  "24064": "40220",
  "24065": "40220",
  "24066": "40220",
  "24067": "40220",
  "24069": "19260",
  "24070": "40220",
  "24073": "13980",
  "24077": "40220",
  "24078": "32300",
  "24083": "40220",
  "24084": "13980",
  "24085": "40220",
  "24087": "13980",
  "24088": "40220",
  "24089": "32300",
  "24090": "40220",
  "24092": "40220",
  "24095": "31340",
  "24101": "40220",
  "24102": "40220",
  "24104": "31340",
  "24112": "32300",
  "24121": "31340",
  "24122": "31340",
  "24124": "13980",
  "24127": "40220",
  "24128": "13980",
  "24134": "13980",
  "24136": "13980",
  "24137": "40220",
  "24138": "13980",
  "24139": "19260",
  "24141": "13980",
  "24147": "13980",
  "24148": "32300",
  "24149": "13980",
  "24150": "13980",
  "24151": "40220",
  "24153": "40220",
  "24161": "19260",
  "24162": "13980",
  "24165": "32300",
  "24167": "13980",
  "24174": "31340",
  "24175": "40220",
  "24176": "40220",
  "24179": "40220",
  "24184": "40220",
  "24201": "28700",
  "24202": "28700",
  "24210": "28700",
  "24211": "28700",
  "24216": "13720",
  "24219": "13720",
  "24230": "13720",
  "24236": "28700",
  "24244": "28700",
  "24245": "28700",
  "24250": "28700",
  "24251": "28700",
  "24258": "28700",
  "24270": "28700",
  "24271": "28700",
  "24273": "13720",
  "24279": "13720",
  "24283": "13720",
  "24290": "28700",
  "24293": "13720",
  "24301": "13980",
  "24314": "14140",
  "24315": "14140",
  "24318": "14140",
  "24324": "13980",
  "24340": "28700",
  "24347": "13980",
  "24361": "28700",
  "24366": "14140",
  "24401": "44420",
  "24421": "44420",
  "24430": "44420",
  "24431": "44420",
  "24437": "44420",
  "24440": "44420",
  "24441": "44420",
  "24464": "16820",
  "24467": "44420",
  "24471": "25500",
  "24477": "44420",
  "24479": "44420",
  "24482": "44420",
  "24485": "44420",
  "24486": "44420",
  "24501": "31340",
  "24502": "31340",
  "24503": "31340",
  "24504": "31340",
  "24517": "31340",
  "24521": "31340",
  "24522": "31340",
  "24523": "31340",
  "24526": "31340",
  "24527": "19260",
  "24528": "31340",
  "24530": "19260",
  "24531": "19260",
  "24536": "31340",
  "24538": "31340",
  "24540": "19260",
  "24541": "19260",
  "24549": "19260",
  "24550": "31340",
  "24551": "31340",
  "24553": "16820",
  "24554": "31340",
  "24556": "31340",
  "24557": "19260",
  "24563": "19260",
  "24565": "19260",
  "24566": "19260",
  "24569": "19260",
  "24571": "31340",
  "24572": "31340",
  "24574": "31340",
  "24586": "19260",
  "24588": "31340",
  "24590": "16820",
  "24593": "31340",
  "24602": "14140",
  "24605": "14140",
  "24609": "14140",
  "24613": "14140",
  "24630": "14140",
  "24635": "14140",
  "24637": "14140",
  "24639": "14140",
  "24641": "14140",
  "24651": "14140",
  "24701": "14140",
  "24712": "14140",
  "24714": "14140",
  "24715": "14140",
  "24724": "14140",
  "24731": "14140",
  "24733": "14140",
  "24736": "14140",
  "24737": "14140",
  "24739": "14140",
  "24740": "14140",
  "24747": "14140",
  "25003": "16620",
  "25007": "13220",
  "25009": "16620",
  "25015": "16620",
  "25019": "16620",
  "25024": "16620",
  "25028": "16620",
  "25030": "16620",
  "25031": "13220",
  "25033": "26580",
  "25035": "16620",
  "25039": "16620",
  "25043": "16620",
  "25045": "16620",
  "25048": "13220",
  "25049": "16620",
  "25053": "16620",
  "25059": "13220",
  "25060": "13220",
  "25063": "16620",
  "25064": "16620",
  "25067": "16620",
  "25070": "26580",
  "25071": "16620",
  "25075": "16620",
  "25081": "16620",
  "25082": "38580",
  "25083": "13220",
  "25085": "13220",
  "25086": "16620",
  "25093": "16620",
  "25102": "16620",
  "25103": "16620",
  "25106": "38580",
  "25107": "16620",
  "25108": "16620",
  "25109": "26580",
  "25110": "16620",
  "25111": "16620",
  "25113": "16620",
  "25114": "16620",
  "25118": "13220",
  "25119": "13220",
  "25121": "34350",
  "25123": "38580",
  "25124": "26580",
  "25125": "16620",
  "25126": "16620",
  "25130": "16620",
  "25132": "16620",
  "25133": "16620",
  "25134": "16620",
  "25136": "13220",
  "25139": "13220",
  "25140": "13220",
  "25142": "16620",
  "25143": "16620",
  "25154": "16620",
  "25159": "26580",
  "25161": "13220",
  "25162": "16620",
  "25164": "16620",
  "25165": "16620",
  "25168": "26580",
  "25169": "16620",
  "25173": "13220",
  "25174": "13220",
  "25177": "16620",
  "25181": "16620",
  "25186": "13220",
  "25187": "38580",
  "25193": "16620",
  "25202": "16620",
  "25208": "16620",
  "25209": "13220",
  "25213": "26580",
  "25214": "16620",
  "25239": "16620",
  "25241": "16620",
  "25244": "16620",
  "25245": "16620",
  "25247": "38580",
  "25248": "16620",
  "25252": "16620",
  "25253": "38580",
  "25260": "38580",
  "25262": "16620",
  "25264": "38580",
  "25265": "38580",
  "25271": "16620",
  "25275": "16620",
  "25285": "16620",
  "25287": "38580",
  "25301": "16620",
  "25302": "16620",
  "25303": "16620",
  "25304": "16620",
  "25306": "16620",
  "25309": "16620",
  "25311": "16620",
  "25312": "16620",
  "25313": "16620",
  "25314": "16620",
  "25315": "16620",
  "25320": "16620",
  "25387": "16620",
  "25401": "25180",
  "25403": "25180",
  "25404": "25180",
  "25405": "25180",
  "25411": "25180",
  "25413": "25180",
  "25414": "47900",
  "25419": "25180",
  "25420": "25180",
  "25422": "25180",
  "25425": "47900",
  "25427": "25180",
  "25428": "25180",
  "25430": "47900",
  "25431": "49020",
  "25434": "49020",
  "25437": "49020",
  "25438": "47900",
  "25442": "47900",
  "25443": "47900",
  "25444": "49020",
  "25446": "47900",
  "25501": "16620",
  "25502": "38580",
  "25503": "38580",
  "25504": "26580",
  "25506": "16620",
  "25507": "26580",
  "25508": "34350",
  "25510": "26580",
  "25511": "26580",
  "25512": "26580",
  "25514": "26580",
  "25515": "38580",
  "25517": "26580",
  "25520": "26580",
  "25521": "16620",
  "25523": "16620",
  "25524": "34350",
  "25526": "26580",
  "25529": "16620",
  "25530": "26580",
  "25535": "26580",
  "25537": "26580",
  "25541": "26580",
  "25545": "26580",
  "25547": "34350",
  "25550": "38580",
  "25555": "26580",
  "25557": "16620",
  "25559": "26580",
  "25560": "26580",
  "25564": "16620",
  "25565": "16620",
  "25567": "16620",
  "25570": "26580",
  "25571": "16620",
  "25573": "16620",
  "25601": "34350",
  "25606": "34350",
  "25607": "34350",
  "25617": "34350",
  "25625": "34350",
  "25632": "34350",
  "25635": "34350",
  "25638": "34350",
  "25646": "34350",
  "25649": "34350",
  "25669": "26580",
  "25701": "26580",
  "25702": "26580",
  "25703": "26580",
  "25704": "26580",
  "25705": "26580",
  "25801": "13220",
  "25811": "13220",
  "25812": "13220",
  "25813": "13220",
  "25817": "13220",
  "25820": "14140",
  "25823": "13220",
  "25825": "13220",
  "25827": "13220",
  "25831": "13220",
  "25832": "13220",
  "25837": "13220",
  "25839": "13220",
  "25840": "13220",
  "25841": "14140",
  "25843": "13220",
  "25844": "13220",
  "25849": "13220",
  "25854": "13220",
  "25862": "13220",
  "25865": "13220",
  "25868": "13220",
  "25880": "13220",
  "25901": "13220",
  "25902": "13220",
  "25908": "13220",
  "25915": "13220",
  "25917": "13220",
  "25918": "13220",
  "25920": "13220",
  "25921": "13220",
  "25922": "14140",
  "25932": "13220",
  "25938": "13220",
  "25962": "13220",
  "25971": "14140",
  "25976": "13220",
  "25989": "13220",
  "26003": "48540",
  "26030": "48260",
  "26031": "48540",
  "26032": "48260",
  "26033": "48540",
  "26034": "48260",
  "26035": "48260",
  "26036": "48540",
  "26037": "48260",
  "26038": "48540",
  "26039": "48540",
  "26040": "48540",
  "26041": "48540",
  "26047": "48260",
  "26050": "48260",
  "26059": "48540",
  "26060": "48540",
  "26062": "48260",
  "26070": "48260",
  "26074": "48540",
  "26075": "48260",
  "26101": "37620",
  "26104": "37620",
  "26105": "37620",
  "26133": "37620",
  "26142": "37620",
  "26143": "37620",
  "26150": "37620",
  "26160": "37620",
  "26164": "16620",
  "26169": "37620",
  "26180": "37620",
  "26181": "37620",
  "26184": "37620",
  "26187": "37620",
  "26241": "21180",
  "26253": "21180",
  "26254": "21180",
  "26257": "21180",
  "26263": "21180",
  "26267": "21180",
  "26268": "21180",
  "26270": "21180",
  "26273": "21180",
  "26276": "21180",
  "26278": "21180",
  "26280": "21180",
  "26282": "21180",
  "26283": "21180",
  "26285": "21180",
  "26293": "21180",
  "26294": "21180",
  "26301": "17220",
  "26323": "17220",
  "26330": "17220",
  "26347": "17220",
  "26354": "17220",
  "26369": "17220",
  "26374": "34060",
  "26385": "17220",
  "26386": "17220",
  "26408": "17220",
  "26410": "34060",
  "26411": "17220",
  "26425": "34060",
  "26426": "17220",
  "26431": "17220",
  "26440": "17220",
  "26444": "34060",
  "26448": "17220",
  "26451": "17220",
  "26456": "17220",
  "26501": "34060",
  "26505": "34060",
  "26508": "34060",
  "26519": "34060",
  "26521": "34060",
  "26525": "34060",
  "26537": "34060",
  "26541": "34060",
  "26542": "34060",
  "26547": "34060",
  "26554": "21900",
  "26559": "21900",
  "26560": "21900",
  "26563": "21900",
  "26568": "17220",
  "26570": "34060",
  "26571": "21900",
  "26574": "21900",
  "26576": "21900",
  "26582": "21900",
  "26585": "21900",
  "26587": "21900",
  "26588": "21900",
  "26590": "34060",
  "26591": "21900",
  "26656": "13220",
  "26704": "49020",
  "26705": "34060",
  "26710": "19060",
  "26711": "49020",
  "26714": "49020",
  "26716": "34060",
  "26717": "19060",
  "26719": "19060",
  "26722": "49020",
  "26726": "19060",
  "26743": "19060",
  "26750": "19060",
  "26753": "19060",
  "26755": "49020",
  "26757": "49020",
  "26761": "49020",
  "26763": "49020",
  "26764": "34060",
  "26767": "19060",
  "26808": "49020",
  "26817": "49020",
  "26852": "49020",
  "26865": "49020",
  "27006": "49180",
  "27007": "34340",
  "27009": "49180",
  "27011": "49180",
  "27012": "49180",
  "27013": "16740",
  "27014": "49180",
  "27016": "49180",
  "27017": "34340",
  "27018": "49180",
  "27019": "49180",
  "27020": "49180",
  "27021": "49180",
  "27022": "49180",
  "27023": "49180",
  "27024": "34340",
  "27025": "24660",
  "27027": "24660",
  "27028": "49180",
  "27030": "34340",
  "27040": "49180",
  "27041": "34340",
  "27042": "49180",
  "27043": "49180",
  "27045": "49180",
  "27046": "49180",
  "27048": "24660",
  "27050": "49180",
  "27051": "49180",
  "27052": "49180",
  "27053": "49180",
  "27054": "16740",
  "27055": "49180",
  "27101": "49180",
  "27103": "49180",
  "27104": "49180",
  "27105": "49180",
  "27106": "49180",
  "27107": "49180",
  "27127": "49180",
  "27203": "24660",
  "27205": "24660",
  "27207": "20500",
  "27208": "20500",
  "27214": "24660",
  "27215": "15500",
  "27217": "15500",
  "27231": "20500",
  "27233": "24660",
  "27235": "24660",
  "27239": "49180",
  "27242": "38240",
  "27243": "20500",
  "27244": "15500",
  "27248": "24660",
  "27249": "24660",
  "27252": "20500",
  "27253": "15500",
  "27258": "15500",
  "27260": "24660",
  "27262": "24660",
  "27263": "24660",
  "27265": "24660",
  "27278": "20500",
  "27281": "38240",
  "27282": "24660",
  "27283": "24660",
  "27284": "49180",
  "27288": "24660",
  "27292": "49180",
  "27295": "49180",
  "27298": "24660",
  "27299": "49180",
  "27301": "24660",
  "27302": "15500",
  "27310": "24660",
  "27312": "20500",
  "27313": "24660",
  "27316": "24660",
  "27317": "24660",
  "27320": "24660",
  "27325": "38240",
  "27326": "24660",
  "27330": "41820",
  "27332": "41820",
  "27341": "24660",
  "27343": "20500",
  "27344": "20500",
  "27349": "15500",
  "27350": "24660",
  "27355": "24660",
  "27357": "24660",
  "27358": "24660",
  "27360": "49180",
  "27370": "24660",
  "27376": "38240",
  "27377": "24660",
  "27401": "24660",
  "27403": "24660",
  "27405": "24660",
  "27406": "24660",
  "27407": "24660",
  "27408": "24660",
  "27409": "24660",
  "27410": "24660",
  "27455": "24660",
  "27501": "22180",
  "27502": "39580",
  "27503": "20500",
  "27504": "39580",
  "27505": "22180",
  "27507": "25780",
  "27508": "39580",
  "27509": "20500",
  "27510": "20500",
  "27511": "39580",
  "27513": "39580",
  "27514": "20500",
  "27516": "20500",
  "27517": "20500",
  "27518": "39580",
  "27519": "39580",
  "27520": "39580",
  "27521": "22180",
  "27522": "20500",
  "27523": "39580",
  "27524": "39580",
  "27525": "39580",
  "27526": "39580",
  "27527": "39580",
  "27529": "39580",
  "27530": "24140",
  "27534": "24140",
  "27536": "25780",
  "27537": "25780",
  "27539": "39580",
  "27540": "39580",
  "27541": "20500",
  "27542": "39580",
  "27544": "25780",
  "27545": "39580",
  "27546": "22180",
  "27549": "39580",
  "27553": "25780",
  "27557": "40580",
  "27559": "20500",
  "27560": "39580",
  "27562": "39580",
  "27565": "20500",
  "27569": "39580",
  "27571": "39580",
  "27572": "20500",
  "27573": "20500",
  "27574": "20500",
  "27576": "39580",
  "27577": "39580",
  "27581": "20500",
  "27583": "20500",
  "27587": "39580",
  "27591": "39580",
  "27592": "39580",
  "27596": "39580",
  "27597": "39580",
  "27601": "39580",
  "27603": "39580",
  "27604": "39580",
  "27605": "39580",
  "27606": "39580",
  "27607": "39580",
  "27608": "39580",
  "27609": "39580",
  "27610": "39580",
  "27612": "39580",
  "27613": "39580",
  "27614": "39580",
  "27615": "39580",
  "27616": "39580",
  "27617": "39580",
  "27701": "20500",
  "27703": "20500",
  "27704": "20500",
  "27705": "20500",
  "27707": "20500",
  "27712": "20500",
  "27713": "20500",
  "27801": "40580",
  "27803": "40580",
  "27804": "40580",
  "27806": "47820",
  "27807": "40580",
  "27808": "47820",
  "27809": "40580",
  "27810": "47820",
  "27812": "40580",
  "27813": "48980",
  "27814": "47820",
  "27816": "39580",
  "27817": "47820",
  "27820": "40260",
  "27822": "48980",
  "27823": "40260",
  "27828": "24780",
  "27829": "24780",
  "27830": "24140",
  "27831": "40260",
  "27832": "40260",
  "27834": "24780",
  "27837": "24780",
  "27839": "40260",
  "27842": "40260",
  "27844": "40260",
  "27845": "40260",
  "27849": "40260",
  "27850": "40260",
  "27851": "48980",
  "27852": "40580",
  "27853": "40260",
  "27856": "40580",
  "27858": "24780",
  "27860": "47820",
  "27862": "40260",
  "27863": "24140",
  "27864": "40580",
  "27866": "40260",
  "27869": "40260",
  "27870": "40260",
  "27874": "40260",
  "27876": "40260",
  "27880": "48980",
  "27882": "40580",
  "27884": "24780",
  "27886": "40580",
  "27889": "47820",
  "27890": "40260",
  "27891": "40580",
  "27893": "48980",
  "27896": "48980",
  "27897": "40260",
  "27909": "21020",
  "27915": "28620",
  "27916": "47260",
  "27917": "47260",
  "27919": "21020",
  "27920": "28620",
  "27921": "47260",
  "27923": "47260",
  "27926": "47260",
  "27927": "47260",
  "27929": "47260",
  "27935": "47260",
  "27936": "28620",
  "27937": "47260",
  "27938": "47260",
  "27939": "47260",
  "27941": "47260",
  "27943": "28620",
  "27944": "21020",
  "27946": "47260",
  "27947": "47260",
  "27948": "28620",
  "27949": "28620",
  "27950": "47260",
  "27953": "28620",
  "27954": "28620",
  "27956": "47260",
  "27958": "47260",
  "27959": "28620",
  "27964": "47260",
  "27965": "47260",
  "27966": "47260",
  "27968": "28620",
  "27972": "28620",
  "27973": "47260",
  "27974": "47260",
  "27976": "47260",
  "27978": "28620",
  "27979": "47260",
  "27981": "28620",
  "27982": "28620",
  "28001": "10620",
  "28006": "16740",
  "28009": "10620",
  "28012": "16740",
  "28016": "16740",
  "28017": "43140",
  "28018": "22580",
  "28020": "43140",
  "28021": "16740",
  "28023": "16740",
  "28025": "16740",
  "28027": "16740",
  "28031": "16740",
  "28032": "16740",
  "28033": "16740",
  "28034": "16740",
  "28036": "16740",
  "28037": "16740",
  "28038": "43140",
  "28040": "22580",
  "28042": "43140",
  "28043": "22580",
  "28052": "16740",
  "28054": "16740",
  "28056": "16740",
  "28071": "16740",
  "28073": "43140",
  "28075": "16740",
  "28078": "16740",
  "28079": "16740",
  "28080": "16740",
  "28081": "16740",
  "28083": "16740",
  "28086": "43140",
  "28088": "16740",
  "28090": "43140",
  "28091": "16740",
  "28092": "16740",
  "28097": "10620",
  "28098": "16740",
  "28101": "16740",
  "28103": "16740",
  "28104": "16740",
  "28105": "16740",
  "28107": "16740",
  "28110": "16740",
  "28112": "16740",
  "28114": "22580",
  "28115": "16740",
  "28117": "16740",
  "28119": "16740",
  "28120": "16740",
  "28124": "16740",
  "28125": "16740",
  "28127": "10620",
  "28128": "10620",
  "28129": "10620",
  "28133": "16740",
  "28134": "16740",
  "28135": "16740",
  "28136": "43140",
  "28137": "10620",
  "28138": "16740",
  "28139": "22580",
  "28144": "16740",
  "28146": "16740",
  "28147": "16740",
  "28150": "43140",
  "28152": "43140",
  "28159": "16740",
  "28160": "22580",
  "28163": "10620",
  "28164": "16740",
  "28166": "16740",
  "28167": "22580",
  "28168": "25860",
  "28170": "16740",
  "28173": "16740",
  "28174": "16740",
  "28202": "16740",
  "28203": "16740",
  "28204": "16740",
  "28205": "16740",
  "28206": "16740",
  "28207": "16740",
  "28208": "16740",
  "28209": "16740",
  "28210": "16740",
  "28211": "16740",
  "28212": "16740",
  "28213": "16740",
  "28214": "16740",
  "28215": "16740",
  "28216": "16740",
  "28217": "16740",
  "28226": "16740",
  "28227": "16740",
  "28262": "16740",
  "28269": "16740",
  "28270": "16740",
  "28273": "16740",
  "28277": "16740",
  "28278": "16740",
  "28301": "22180",
  "28303": "22180",
  "28304": "22180",
  "28305": "22180",
  "28306": "22180",
  "28311": "22180",
  "28312": "22180",
  "28314": "22180",
  "28315": "38240",
  "28323": "22180",
  "28326": "22180",
  "28327": "38240",
  "28333": "24140",
  "28334": "22180",
  "28338": "40460",
  "28339": "22180",
  "28340": "31300",
  "28343": "29900",
  "28344": "22180",
  "28345": "40460",
  "28348": "22180",
  "28351": "29900",
  "28352": "29900",
  "28356": "22180",
  "28357": "22180",
  "28358": "31300",
  "28360": "31300",
  "28363": "29900",
  "28364": "31300",
  "28369": "31300",
  "28371": "31300",
  "28372": "31300",
  "28373": "38240",
  "28374": "38240",
  "28376": "22180",
  "28377": "31300",
  "28379": "40460",
  "28383": "31300",
  "28384": "31300",
  "28386": "22180",
  "28387": "38240",
  "28390": "22180",
  "28391": "22180",
  "28394": "38240",
  "28395": "22180",
  "28396": "29900",
  "28401": "48900",
  "28403": "48900",
  "28405": "48900",
  "28409": "48900",
  "28411": "48900",
  "28412": "48900",
  "28420": "34820",
  "28421": "48900",
  "28422": "34820",
  "28425": "48900",
  "28428": "48900",
  "28429": "48900",
  "28435": "48900",
  "28443": "48900",
  "28445": "27340",
  "28449": "48900",
  "28451": "34820",
  "28454": "27340",
  "28457": "48900",
  "28460": "27340",
  "28461": "34820",
  "28462": "34820",
  "28465": "34820",
  "28467": "34820",
  "28468": "34820",
  "28469": "34820",
  "28470": "34820",
  "28478": "48900",
  "28479": "34820",
  "28480": "48900",
  "28501": "28820",
  "28504": "28820",
  "28510": "35100",
  "28511": "33980",
  "28512": "33980",
  "28513": "24780",
  "28515": "35100",
  "28516": "33980",
  "28520": "33980",
  "28523": "35100",
  "28524": "33980",
  "28525": "28820",
  "28526": "35100",
  "28527": "35100",
  "28528": "33980",
  "28529": "35100",
  "28530": "24780",
  "28531": "33980",
  "28532": "35100",
  "28539": "27340",
  "28540": "27340",
  "28544": "27340",
  "28546": "27340",
  "28551": "28820",
  "28552": "35100",
  "28553": "33980",
  "28555": "27340",
  "28556": "35100",
  "28557": "33980",
  "28560": "35100",
  "28562": "35100",
  "28570": "33980",
  "28571": "35100",
  "28572": "28820",
  "28573": "35100",
  "28574": "27340",
  "28577": "33980",
  "28578": "24140",
  "28579": "33980",
  "28581": "33980",
  "28582": "27340",
  "28584": "33980",
  "28585": "35100",
  "28586": "35100",
  "28587": "35100",
  "28590": "24780",
  "28594": "33980",
  "28601": "25860",
  "28602": "25860",
  "28605": "14380",
  "28606": "35900",
  "28607": "14380",
  "28609": "25860",
  "28610": "25860",
  "28611": "25860",
  "28612": "25860",
  "28613": "25860",
  "28618": "14380",
  "28621": "34340",
  "28624": "35900",
  "28625": "16740",
  "28630": "25860",
  "28634": "16740",
  "28635": "35900",
  "28636": "25860",
  "28638": "25860",
  "28642": "49180",
  "28645": "25860",
  "28650": "25860",
  "28651": "35900",
  "28654": "35900",
  "28655": "25860",
  "28658": "25860",
  "28659": "35900",
  "28660": "16740",
  "28665": "35900",
  "28669": "35900",
  "28670": "35900",
  "28673": "25860",
  "28676": "34340",
  "28677": "16740",
  "28678": "25860",
  "28679": "14380",
  "28681": "25860",
  "28682": "25860",
  "28683": "34340",
  "28684": "14380",
  "28685": "35900",
  "28689": "16740",
  "28690": "25860",
  "28692": "14380",
  "28697": "35900",
  "28698": "14380",
  "28701": "11700",
  "28704": "11700",
  "28707": "19000",
  "28708": "14820",
  "28709": "11700",
  "28711": "11700",
  "28712": "14820",
  "28713": "19000",
  "28715": "11700",
  "28716": "11700",
  "28717": "19000",
  "28718": "14820",
  "28721": "11700",
  "28723": "19000",
  "28726": "11700",
  "28729": "11700",
  "28730": "11700",
  "28731": "11700",
  "28732": "11700",
  "28736": "19000",
  "28739": "11700",
  "28742": "11700",
  "28743": "11700",
  "28745": "11700",
  "28746": "22580",
  "28747": "14820",
  "28748": "11700",
  "28751": "11700",
  "28752": "32000",
  "28753": "11700",
  "28754": "11700",
  "28759": "11700",
  "28761": "32000",
  "28762": "32000",
  "28766": "14820",
  "28768": "14820",
  "28772": "14820",
  "28774": "14820",
  "28778": "11700",
  "28779": "19000",
  "28783": "19000",
  "28785": "11700",
  "28786": "11700",
  "28787": "11700",
  "28789": "19000",
  "28790": "11700",
  "28791": "11700",
  "28792": "11700",
  "28801": "11700",
  "28803": "11700",
  "28804": "11700",
  "28805": "11700",
  "28806": "11700",
  "29001": "44940",
  "29006": "17900",
  "29009": "17900",
  "29014": "16740",
  "29015": "17900",
  "29016": "17900",
  "29018": "36700",
  "29020": "17900",
  "29030": "17900",
  "29031": "16740",
  "29032": "17900",
  "29033": "17900",
  "29036": "17900",
  "29037": "35140",
  "29038": "36700",
  "29039": "36700",
  "29040": "44940",
  "29044": "17900",
  "29045": "17900",
  "29047": "36700",
  "29048": "36700",
  "29051": "44940",
  "29052": "17900",
  "29053": "17900",
  "29054": "17900",
  "29055": "16740",
  "29058": "16740",
  "29059": "36700",
  "29061": "17900",
  "29063": "17900",
  "29065": "17900",
  "29067": "16740",
  "29069": "22500",
  "29070": "17900",
  "29072": "17900",
  "29073": "17900",
  "29074": "17900",
  "29075": "35140",
  "29078": "17900",
  "29080": "44940",
  "29102": "44940",
  "29105": "12260",
  "29107": "36700",
  "29108": "35140",
  "29112": "36700",
  "29114": "22500",
  "29115": "36700",
  "29118": "36700",
  "29123": "17900",
  "29125": "44940",
  "29126": "35140",
  "29127": "35140",
  "29128": "44940",
  "29129": "12260",
  "29130": "17900",
  "29135": "17900",
  "29137": "12260",
  "29138": "17900",
  "29142": "36700",
  "29146": "12260",
  "29148": "44940",
  "29150": "44940",
  "29153": "44940",
  "29154": "44940",
  "29160": "17900",
  "29161": "22500",
  "29162": "44940",
  "29163": "36700",
  "29164": "12260",
  "29168": "44940",
  "29169": "17900",
  "29170": "17900",
  "29172": "17900",
  "29175": "17900",
  "29178": "35140",
  "29180": "17900",
  "29201": "17900",
  "29203": "17900",
  "29204": "17900",
  "29205": "17900",
  "29206": "17900",
  "29209": "17900",
  "29210": "17900",
  "29212": "17900",
  "29223": "17900",
  "29229": "17900",
  "29301": "43900",
  "29302": "43900",
  "29303": "43900",
  "29306": "43900",
  "29307": "43900",
  "29316": "43900",
  "29320": "43900",
  "29322": "43900",
  "29323": "43900",
  "29325": "24860",
  "29330": "23500",
  "29332": "24860",
  "29333": "43900",
  "29334": "43900",
  "29335": "43900",
  "29340": "23500",
  "29341": "23500",
  "29349": "43900",
  "29351": "24860",
  "29353": "46420",
  "29355": "24860",
  "29356": "24860",
  "29360": "24860",
  "29365": "43900",
  "29368": "43900",
  "29369": "43900",
  "29370": "24860",
  "29372": "43900",
  "29374": "43900",
  "29376": "43900",
  "29377": "43900",
  "29379": "46420",
  "29384": "24860",
  "29385": "43900",
  "29388": "43900",
  "29401": "16700",
  "29403": "16700",
  "29405": "16700",
  "29406": "16700",
  "29407": "16700",
  "29410": "16700",
  "29412": "16700",
  "29414": "16700",
  "29418": "16700",
  "29420": "16700",
  "29426": "16700",
  "29429": "16700",
  "29431": "16700",
  "29432": "36700",
  "29434": "16700",
  "29436": "16700",
  "29437": "16700",
  "29438": "16700",
  "29439": "16700",
  "29440": "23860",
  "29445": "16700",
  "29448": "16700",
  "29449": "16700",
  "29450": "16700",
  "29451": "16700",
  "29453": "16700",
  "29455": "16700",
  "29456": "16700",
  "29458": "16700",
  "29461": "16700",
  "29464": "16700",
  "29466": "16700",
  "29468": "16700",
  "29469": "16700",
  "29470": "16700",
  "29471": "16700",
  "29472": "16700",
  "29477": "16700",
  "29479": "16700",
  "29482": "16700",
  "29483": "16700",
  "29485": "16700",
  "29486": "16700",
  "29487": "16700",
  "29492": "16700",
  "29501": "22500",
  "29505": "22500",
  "29506": "22500",
  "29510": "23860",
  "29511": "34820",
  "29512": "13500",
  "29516": "13500",
  "29525": "13500",
  "29526": "34820",
  "29527": "34820",
  "29530": "22500",
  "29532": "22500",
  "29540": "22500",
  "29541": "22500",
  "29544": "34820",
  "29545": "34820",
  "29550": "22500",
  "29555": "22500",
  "29560": "22500",
  "29566": "34820",
  "29568": "34820",
  "29569": "34820",
  "29570": "13500",
  "29572": "34820",
  "29575": "34820",
  "29576": "34820",
  "29577": "34820",
  "29579": "34820",
  "29581": "34820",
  "29582": "34820",
  "29583": "22500",
  "29585": "23860",
  "29588": "34820",
  "29591": "22500",
  "29596": "13500",
  "29601": "24860",
  "29605": "24860",
  "29607": "24860",
  "29609": "24860",
  "29611": "24860",
  "29615": "24860",
  "29617": "24860",
  "29621": "24860",
  "29624": "24860",
  "29625": "24860",
  "29626": "24860",
  "29627": "24860",
  "29630": "24860",
  "29631": "24860",
  "29635": "24860",
  "29640": "24860",
  "29642": "24860",
  "29643": "42860",
  "29644": "24860",
  "29645": "24860",
  "29646": "24940",
  "29649": "24940",
  "29650": "24860",
  "29651": "24860",
  "29653": "24940",
  "29654": "24860",
  "29655": "24860",
  "29657": "24860",
  "29658": "42860",
  "29661": "24860",
  "29662": "24860",
  "29664": "42860",
  "29666": "24940",
  "29667": "24860",
  "29669": "24860",
  "29670": "24860",
  "29671": "24860",
  "29672": "42860",
  "29673": "24860",
  "29676": "42860",
  "29678": "42860",
  "29680": "24860",
  "29681": "24860",
  "29682": "24860",
  "29683": "24860",
  "29684": "24860",
  "29685": "24860",
  "29686": "42860",
  "29687": "24860",
  "29689": "24860",
  "29690": "24860",
  "29691": "42860",
  "29692": "24860",
  "29693": "42860",
  "29696": "42860",
  "29697": "24860",
  "29702": "23500",
  "29704": "16740",
  "29706": "16740",
  "29707": "16740",
  "29708": "16740",
  "29710": "16740",
  "29712": "16740",
  "29714": "16740",
  "29715": "16740",
  "29717": "16740",
  "29720": "16740",
  "29726": "16740",
  "29729": "16740",
  "29730": "16740",
  "29732": "16740",
  "29742": "16740",
  "29743": "16740",
  "29745": "16740",
  "29801": "12260",
  "29803": "12260",
  "29805": "12260",
  "29809": "12260",
  "29819": "24940",
  "29821": "12260",
  "29824": "12260",
  "29828": "12260",
  "29829": "12260",
  "29831": "12260",
  "29832": "12260",
  "29841": "12260",
  "29842": "12260",
  "29847": "12260",
  "29848": "24940",
  "29851": "12260",
  "29856": "12260",
  "29860": "12260",
  "29902": "25940",
  "29906": "25940",
  "29907": "25940",
  "29909": "25940",
  "29910": "25940",
  "29915": "25940",
  "29920": "25940",
  "29922": "25940",
  "29926": "25940",
  "29927": "25940",
  "29928": "25940",
  "29935": "25940",
  "29936": "25940",
  "29940": "25940",
  "29941": "25940",
  "29945": "25940",
  "30002": "12060",
  "30004": "12060",
  "30005": "12060",
  "30008": "12060",
  "30009": "12060",
  "30011": "12060",
  "30012": "12060",
  "30013": "12060",
  "30014": "12060",
  "30016": "12060",
  "30017": "12060",
  "30019": "12060",
  "30021": "12060",
  "30022": "12060",
  "30024": "12060",
  "30025": "12060",
  "30028": "12060",
  "30030": "12060",
  "30032": "12060",
  "30033": "12060",
  "30034": "12060",
  "30035": "12060",
  "30038": "12060",
  "30039": "12060",
  "30040": "12060",
  "30041": "12060",
  "30043": "12060",
  "30044": "12060",
  "30045": "12060",
  "30046": "12060",
  "30047": "12060",
  "30052": "12060",
  "30054": "12060",
  "30055": "12060",
  "30056": "12060",
  "30058": "12060",
  "30060": "12060",
  "30062": "12060",
  "30064": "12060",
  "30066": "12060",
  "30067": "12060",
  "30068": "12060",
  "30071": "12060",
  "30072": "12060",
  "30075": "12060",
  "30076": "12060",
  "30078": "12060",
  "30079": "12060",
  "30080": "12060",
  "30082": "12060",
  "30083": "12060",
  "30084": "12060",
  "30087": "12060",
  "30088": "12060",
  "30092": "12060",
  "30093": "12060",
  "30094": "12060",
  "30096": "12060",
  "30097": "12060",
  "30101": "12060",
  "30102": "12060",
  "30103": "12060",
  "30104": "16340",
  "30105": "40660",
  "30106": "12060",
  "30107": "12060",
  "30108": "12060",
  "30110": "12060",
  "30113": "12060",
  "30114": "12060",
  "30115": "12060",
  "30116": "12060",
  "30117": "12060",
  "30120": "12060",
  "30121": "12060",
  "30122": "12060",
  "30124": "40660",
  "30125": "16340",
  "30126": "12060",
  "30127": "12060",
  "30132": "12060",
  "30134": "12060",
  "30135": "12060",
  "30137": "12060",
  "30139": "15660",
  "30141": "12060",
  "30143": "12060",
  "30144": "12060",
  "30145": "12060",
  "30147": "40660",
  "30148": "12060",
  "30152": "12060",
  "30153": "16340",
  "30157": "12060",
  "30161": "40660",
  "30165": "40660",
  "30168": "12060",
  "30170": "12060",
  "30171": "12060",
  "30173": "40660",
  "30175": "12060",
  "30176": "12060",
  "30177": "12060",
  "30178": "16340",
  "30179": "12060",
  "30180": "12060",
  "30182": "12060",
  "30183": "12060",
  "30184": "12060",
  "30185": "12060",
  "30187": "12060",
  "30188": "12060",
  "30189": "12060",
  "30204": "12060",
  "30205": "12060",
  "30206": "12060",
  "30213": "12060",
  "30214": "12060",
  "30215": "12060",
  "30216": "12060",
  "30217": "12060",
  "30218": "12060",
  "30220": "12060",
  "30222": "12060",
  "30223": "12060",
  "30224": "12060",
  "30228": "12060",
  "30230": "29300",
  "30233": "12060",
  "30234": "12060",
  "30236": "12060",
  "30238": "12060",
  "30240": "29300",
  "30241": "29300",
  "30248": "12060",
  "30251": "12060",
  "30252": "12060",
  "30253": "12060",
  "30256": "12060",
  "30257": "12060",
  "30258": "12060",
  "30259": "12060",
  "30260": "12060",
  "30263": "12060",
  "30265": "12060",
  "30268": "12060",
  "30269": "12060",
  "30273": "12060",
  "30274": "12060",
  "30276": "12060",
  "30277": "12060",
  "30281": "12060",
  "30285": "45580",
  "30286": "45580",
  "30288": "12060",
  "30290": "12060",
  "30291": "12060",
  "30292": "12060",
  "30293": "12060",
  "30294": "12060",
  "30295": "12060",
  "30296": "12060",
  "30297": "12060",
  "30303": "12060",
  "30305": "12060",
  "30306": "12060",
  "30307": "12060",
  "30308": "12060",
  "30309": "12060",
  "30310": "12060",
  "30311": "12060",
  "30312": "12060",
  "30313": "12060",
  "30314": "12060",
  "30315": "12060",
  "30316": "12060",
  "30317": "12060",
  "30318": "12060",
  "30319": "12060",
  "30324": "12060",
  "30326": "12060",
  "30327": "12060",
  "30328": "12060",
  "30329": "12060",
  "30331": "12060",
  "30336": "12060",
  "30337": "12060",
  "30338": "12060",
  "30339": "12060",
  "30340": "12060",
  "30341": "12060",
  "30342": "12060",
  "30344": "12060",
  "30345": "12060",
  "30346": "12060",
  "30349": "12060",
  "30350": "12060",
  "30354": "12060",
  "30360": "12060",
  "30363": "12060",
  "30410": "47080",
  "30415": "44340",
  "30426": "12260",
  "30436": "47080",
  "30445": "47080",
  "30450": "44340",
  "30452": "44340",
  "30454": "20140",
  "30456": "12260",
  "30457": "20140",
  "30458": "44340",
  "30461": "44340",
  "30470": "20140",
  "30473": "47080",
  "30474": "47080",
  "30501": "23580",
  "30504": "23580",
  "30506": "23580",
  "30507": "23580",
  "30510": "18460",
  "30517": "12060",
  "30518": "12060",
  "30519": "12060",
  "30523": "18460",
  "30527": "23580",
  "30529": "27600",
  "30531": "18460",
  "30534": "12060",
  "30535": "18460",
  "30538": "45740",
  "30542": "23580",
  "30543": "23580",
  "30548": "12060",
  "30549": "27600",
  "30554": "23580",
  "30557": "45740",
  "30563": "18460",
  "30565": "27600",
  "30566": "23580",
  "30567": "27600",
  "30575": "23580",
  "30577": "45740",
  "30601": "12020",
  "30605": "12020",
  "30606": "12020",
  "30607": "12020",
  "30619": "12020",
  "30620": "12060",
  "30621": "12020",
  "30622": "12020",
  "30625": "12060",
  "30627": "12020",
  "30628": "12020",
  "30629": "12020",
  "30630": "12020",
  "30633": "12020",
  "30641": "12060",
  "30646": "12020",
  "30648": "12020",
  "30650": "12060",
  "30655": "12060",
  "30656": "12060",
  "30663": "12060",
  "30666": "12060",
  "30667": "12020",
  "30677": "12020",
  "30680": "12060",
  "30683": "12020",
  "30701": "15660",
  "30705": "19140",
  "30707": "16860",
  "30708": "19140",
  "30710": "19140",
  "30711": "19140",
  "30720": "19140",
  "30721": "19140",
  "30725": "16860",
  "30728": "16860",
  "30730": "44900",
  "30731": "16860",
  "30733": "15660",
  "30734": "15660",
  "30735": "15660",
  "30736": "16860",
  "30738": "16860",
  "30739": "16860",
  "30740": "19140",
  "30741": "16860",
  "30742": "16860",
  "30746": "15660",
  "30747": "44900",
  "30750": "16860",
  "30752": "16860",
  "30753": "16860",
  "30755": "19140",
  "30757": "16860",
  "30802": "12260",
  "30805": "12260",
  "30808": "12260",
  "30809": "12260",
  "30813": "12260",
  "30814": "12260",
  "30815": "12260",
  "30817": "12260",
  "30824": "12260",
  "30830": "12260",
  "30901": "12260",
  "30904": "12260",
  "30906": "12260",
  "30907": "12260",
  "30909": "12260",
  "31005": "47580",
  "31008": "47580",
  "31009": "20140",
  "31015": "18380",
  "31016": "31420",
  "31017": "31420",
  "31019": "20140",
  "31020": "31420",
  "31021": "20140",
  "31022": "20140",
  "31025": "47580",
  "31027": "20140",
  "31028": "47580",
  "31029": "31420",
  "31030": "47580",
  "31032": "31420",
  "31033": "31420",
  "31038": "31420",
  "31044": "31420",
  "31046": "31420",
  "31047": "47580",
  "31049": "20140",
  "31050": "31420",
  "31052": "31420",
  "31061": "33300",
  "31064": "12060",
  "31065": "20140",
  "31066": "31420",
  "31069": "47580",
  "31075": "20140",
  "31078": "31420",
  "31085": "12060",
  "31087": "33300",
  "31088": "47580",
  "31093": "47580",
  "31096": "20140",
  "31097": "45580",
  "31201": "31420",
  "31204": "31420",
  "31206": "31420",
  "31210": "31420",
  "31211": "31420",
  "31216": "31420",
  "31217": "31420",
  "31220": "31420",
  "31301": "25980",
  "31302": "42340",
  "31303": "42340",
  "31305": "15260",
  "31307": "42340",
  "31308": "42340",
  "31309": "25980",
  "31312": "42340",
  "31313": "25980",
  "31316": "25980",
  "31318": "42340",
  "31320": "25980",
  "31321": "42340",
  "31322": "42340",
  "31323": "25980",
  "31324": "42340",
  "31326": "42340",
  "31328": "42340",
  "31329": "42340",
  "31331": "15260",
  "31401": "42340",
  "31404": "42340",
  "31405": "42340",
  "31406": "42340",
  "31407": "42340",
  "31408": "42340",
  "31410": "42340",
  "31411": "42340",
  "31415": "42340",
  "31419": "42340",
  "31501": "48180",
  "31503": "48180",
  "31512": "20060",
  "31516": "48180",
  "31519": "20060",
  "31520": "15260",
  "31522": "15260",
  "31523": "15260",
  "31525": "15260",
  "31527": "15260",
  "31533": "20060",
  "31535": "20060",
  "31542": "15260",
  "31543": "15260",
  "31545": "27700",
  "31546": "27700",
  "31548": "41220",
  "31550": "48180",
  "31551": "48180",
  "31552": "48180",
  "31553": "15260",
  "31554": "20060",
  "31555": "27700",
  "31557": "48180",
  "31558": "41220",
  "31560": "27700",
  "31561": "15260",
  "31565": "41220",
  "31566": "15260",
  "31567": "20060",
  "31568": "41220",
  "31569": "41220",
  "31601": "46660",
  "31602": "46660",
  "31605": "46660",
  "31606": "46660",
  "31624": "20060",
  "31625": "46660",
  "31626": "45620",
  "31629": "46660",
  "31631": "46660",
  "31632": "46660",
  "31635": "46660",
  "31636": "46660",
  "31638": "46660",
  "31641": "46660",
  "31642": "20060",
  "31643": "46660",
  "31648": "46660",
  "31649": "46660",
  "31650": "20060",
  "31701": "10500",
  "31705": "10500",
  "31707": "10500",
  "31709": "11140",
  "31711": "11140",
  "31712": "18380",
  "31719": "11140",
  "31721": "10500",
  "31722": "34220",
  "31733": "45700",
  "31735": "11140",
  "31738": "45620",
  "31743": "10500",
  "31744": "34220",
  "31750": "22340",
  "31756": "34220",
  "31757": "45620",
  "31763": "10500",
  "31764": "11140",
  "31765": "45620",
  "31768": "34220",
  "31771": "34220",
  "31773": "45620",
  "31775": "34220",
  "31778": "46660",
  "31780": "11140",
  "31781": "10500",
  "31787": "10500",
  "31788": "34220",
  "31789": "10500",
  "31791": "10500",
  "31792": "45620",
  "31793": "45700",
  "31794": "45700",
  "31795": "10500",
  "31796": "10500",
  "31801": "17980",
  "31803": "17980",
  "31804": "17980",
  "31805": "17980",
  "31806": "11140",
  "31807": "17980",
  "31808": "17980",
  "31811": "17980",
  "31812": "17980",
  "31815": "17980",
  "31816": "12060",
  "31820": "17980",
  "31821": "17980",
  "31822": "29300",
  "31823": "17980",
  "31825": "17980",
  "31826": "17980",
  "31827": "17980",
  "31829": "17980",
  "31830": "12060",
  "31831": "17980",
  "31833": "29300",
  "31836": "17980",
  "31901": "17980",
  "31903": "17980",
  "31904": "17980",
  "31906": "17980",
  "31907": "17980",
  "31909": "17980",
  "32003": "27260",
  "32009": "27260",
  "32011": "27260",
  "32024": "29380",
  "32025": "29380",
  "32033": "27260",
  "32034": "27260",
  "32038": "29380",
  "32040": "27260",
  "32043": "27260",
  "32046": "27260",
  "32055": "29380",
  "32063": "27260",
  "32065": "27260",
  "32068": "27260",
  "32073": "27260",
  "32080": "27260",
  "32081": "27260",
  "32082": "27260",
  "32084": "27260",
  "32086": "27260",
  "32087": "27260",
  "32092": "27260",
  "32095": "27260",
  "32097": "27260",
  "32102": "36740",
  "32110": "19660",
  "32112": "37260",
  "32113": "36100",
  "32114": "19660",
  "32117": "19660",
  "32118": "19660",
  "32119": "19660",
  "32124": "19660",
  "32127": "19660",
  "32128": "19660",
  "32129": "19660",
  "32130": "19660",
  "32131": "37260",
  "32132": "19660",
  "32134": "36100",
  "32136": "19660",
  "32137": "19660",
  "32139": "37260",
  "32140": "37260",
  "32141": "19660",
  "32145": "27260",
  "32148": "37260",
  "32159": "36740",
  "32162": "45540",
  "32163": "45540",
  "32164": "19660",
  "32168": "19660",
  "32169": "19660",
  "32174": "19660",
  "32176": "19660",
  "32177": "37260",
  "32179": "36100",
  "32180": "19660",
  "32181": "37260",
  "32187": "37260",
  "32189": "37260",
  "32190": "19660",
  "32193": "37260",
  "32195": "36100",
  "32202": "27260",
  "32204": "27260",
  "32205": "27260",
  "32206": "27260",
  "32207": "27260",
  "32208": "27260",
  "32209": "27260",
  "32210": "27260",
  "32211": "27260",
  "32216": "27260",
  "32217": "27260",
  "32218": "27260",
  "32219": "27260",
  "32220": "27260",
  "32221": "27260",
  "32222": "27260",
  "32223": "27260",
  "32224": "27260",
  "32225": "27260",
  "32226": "27260",
  "32233": "27260",
  "32234": "27260",
  "32244": "27260",
  "32246": "27260",
  "32250": "27260",
  "32254": "27260",
  "32256": "27260",
  "32257": "27260",
  "32258": "27260",
  "32259": "27260",
  "32266": "27260",
  "32277": "27260",
  "32301": "45220",
  "32303": "45220",
  "32304": "45220",
  "32305": "45220",
  "32308": "45220",
  "32309": "45220",
  "32310": "45220",
  "32311": "45220",
  "32312": "45220",
  "32317": "45220",
  "32324": "45220",
  "32327": "45220",
  "32330": "45220",
  "32332": "45220",
  "32333": "45220",
  "32336": "45220",
  "32343": "45220",
  "32344": "45220",
  "32346": "45220",
  "32351": "45220",
  "32352": "45220",
  "32355": "45220",
  "32358": "45220",
  "32401": "37460",
  "32404": "37460",
  "32405": "37460",
  "32407": "37460",
  "32408": "37460",
  "32409": "37460",
  "32413": "37460",
  "32433": "18880",
  "32435": "18880",
  "32438": "37460",
  "32439": "18880",
  "32444": "37460",
  "32455": "18880",
  "32459": "18880",
  "32461": "18880",
  "32466": "37460",
  "32501": "37860",
  "32502": "37860",
  "32503": "37860",
  "32504": "37860",
  "32505": "37860",
  "32506": "37860",
  "32507": "37860",
  "32514": "37860",
  "32526": "37860",
  "32531": "18880",
  "32533": "37860",
  "32534": "37860",
  "32535": "37860",
  "32536": "18880",
  "32539": "18880",
  "32541": "18880",
  "32547": "18880",
  "32548": "18880",
  "32550": "18880",
  "32561": "37860",
  "32563": "37860",
  "32564": "18880",
  "32565": "37860",
  "32566": "37860",
  "32567": "18880",
  "32568": "37860",
  "32569": "18880",
  "32570": "37860",
  "32571": "37860",
  "32577": "37860",
  "32578": "18880",
  "32579": "18880",
  "32580": "18880",
  "32583": "37860",
  "32601": "23540",
  "32603": "23540",
  "32605": "23540",
  "32606": "23540",
  "32607": "23540",
  "32608": "23540",
  "32609": "23540",
  "32615": "23540",
  "32617": "36100",
  "32618": "23540",
  "32619": "23540",
  "32621": "23540",
  "32625": "23540",
  "32626": "23540",
  "32631": "23540",
  "32640": "23540",
  "32641": "23540",
  "32643": "23540",
  "32653": "23540",
  "32656": "27260",
  "32664": "36100",
  "32666": "27260",
  "32667": "23540",
  "32668": "23540",
  "32669": "23540",
  "32686": "36100",
  "32693": "23540",
  "32694": "23540",
  "32696": "23540",
  "32701": "36740",
  "32702": "36100",
  "32703": "36740",
  "32707": "36740",
  "32708": "36740",
  "32709": "36740",
  "32712": "36740",
  "32713": "19660",
  "32714": "36740",
  "32720": "19660",
  "32724": "19660",
  "32725": "19660",
  "32726": "36740",
  "32730": "36740",
  "32732": "36740",
  "32735": "36740",
  "32736": "36740",
  "32738": "19660",
  "32744": "19660",
  "32746": "36740",
  "32750": "36740",
  "32751": "36740",
  "32754": "37340",
  "32757": "36740",
  "32759": "19660",
  "32763": "19660",
  "32764": "19660",
  "32765": "36740",
  "32766": "36740",
  "32767": "36740",
  "32771": "36740",
  "32773": "36740",
  "32776": "36740",
  "32778": "36740",
  "32779": "36740",
  "32780": "37340",
  "32784": "36740",
  "32789": "36740",
  "32792": "36740",
  "32796": "37340",
  "32798": "36740",
  "32801": "36740",
  "32803": "36740",
  "32804": "36740",
  "32805": "36740",
  "32806": "36740",
  "32807": "36740",
  "32808": "36740",
  "32809": "36740",
  "32810": "36740",
  "32811": "36740",
  "32812": "36740",
  "32814": "36740",
  "32817": "36740",
  "32818": "36740",
  "32819": "36740",
  "32820": "36740",
  "32821": "36740",
  "32822": "36740",
  "32824": "36740",
  "32825": "36740",
  "32826": "36740",
  "32827": "36740",
  "32828": "36740",
  "32829": "36740",
  "32832": "36740",
  "32833": "36740",
  "32835": "36740",
  "32836": "36740",
  "32837": "36740",
  "32839": "36740",
  "32901": "37340",
  "32903": "37340",
  "32904": "37340",
  "32905": "37340",
  "32907": "37340",
  "32908": "37340",
  "32909": "37340",
  "32920": "37340",
  "32922": "37340",
  "32926": "37340",
  "32927": "37340",
  "32931": "37340",
  "32934": "37340",
  "32935": "37340",
  "32937": "37340",
  "32940": "37340",
  "32948": "42680",
  "32949": "37340",
  "32950": "37340",
  "32951": "37340",
  "32952": "37340",
  "32953": "37340",
  "32955": "37340",
  "32958": "42680",
  "32960": "42680",
  "32962": "42680",
  "32963": "42680",
  "32966": "42680",
  "32967": "42680",
  "32968": "42680",
  "32976": "37340",
  "33001": "28580",
  "33004": "33100",
  "33009": "33100",
  "33010": "33100",
  "33012": "33100",
  "33013": "33100",
  "33014": "33100",
  "33015": "33100",
  "33016": "33100",
  "33018": "33100",
  "33019": "33100",
  "33020": "33100",
  "33021": "33100",
  "33023": "33100",
  "33024": "33100",
  "33025": "33100",
  "33026": "33100",
  "33027": "33100",
  "33028": "33100",
  "33029": "33100",
  "33030": "33100",
  "33031": "33100",
  "33032": "33100",
  "33033": "33100",
  "33034": "33100",
  "33035": "33100",
  "33036": "28580",
  "33037": "28580",
  "33040": "28580",
  "33042": "28580",
  "33043": "28580",
  "33050": "28580",
  "33051": "28580",
  "33054": "33100",
  "33055": "33100",
  "33056": "33100",
  "33060": "33100",
  "33062": "33100",
  "33063": "33100",
  "33064": "33100",
  "33065": "33100",
  "33066": "33100",
  "33067": "33100",
  "33068": "33100",
  "33069": "33100",
  "33070": "28580",
  "33071": "33100",
  "33073": "33100",
  "33076": "33100",
  "33109": "33100",
  "33122": "33100",
  "33125": "33100",
  "33126": "33100",
  "33127": "33100",
  "33128": "33100",
  "33129": "33100",
  "33130": "33100",
  "33131": "33100",
  "33132": "33100",
  "33133": "33100",
  "33134": "33100",
  "33135": "33100",
  "33136": "33100",
  "33137": "33100",
  "33138": "33100",
  "33139": "33100",
  "33140": "33100",
  "33141": "33100",
  "33142": "33100",
  "33143": "33100",
  "33144": "33100",
  "33145": "33100",
  "33146": "33100",
  "33147": "33100",
  "33149": "33100",
  "33150": "33100",
  "33154": "33100",
  "33155": "33100",
  "33156": "33100",
  "33157": "33100",
  "33158": "33100",
  "33160": "33100",
  "33161": "33100",
  "33162": "33100",
  "33165": "33100",
  "33166": "33100",
  "33167": "33100",
  "33168": "33100",
  "33169": "33100",
  "33170": "33100",
  "33172": "33100",
  "33173": "33100",
  "33174": "33100",
  "33175": "33100",
  "33176": "33100",
  "33177": "33100",
  "33178": "33100",
  "33179": "33100",
  "33180": "33100",
  "33181": "33100",
  "33182": "33100",
  "33183": "33100",
  "33184": "33100",
  "33185": "33100",
  "33186": "33100",
  "33187": "33100",
  "33189": "33100",
  "33190": "33100",
  "33193": "33100",
  "33194": "33100",
  "33196": "33100",
  "33301": "33100",
  "33304": "33100",
  "33305": "33100",
  "33306": "33100",
  "33308": "33100",
  "33309": "33100",
  "33311": "33100",
  "33312": "33100",
  "33313": "33100",
  "33314": "33100",
  "33315": "33100",
  "33316": "33100",
  "33317": "33100",
  "33319": "33100",
  "33321": "33100",
  "33322": "33100",
  "33323": "33100",
  "33324": "33100",
  "33325": "33100",
  "33326": "33100",
  "33327": "33100",
  "33328": "33100",
  "33330": "33100",
  "33331": "33100",
  "33332": "33100",
  "33334": "33100",
  "33351": "33100",
  "33401": "33100",
  "33403": "33100",
  "33404": "33100",
  "33405": "33100",
  "33406": "33100",
  "33407": "33100",
  "33408": "33100",
  "33409": "33100",
  "33410": "33100",
  "33411": "33100",
  "33412": "33100",
  "33413": "33100",
  "33414": "33100",
  "33415": "33100",
  "33417": "33100",
  "33418": "33100",
  "33426": "33100",
  "33428": "33100",
  "33430": "33100",
  "33431": "33100",
  "33432": "33100",
  "33433": "33100",
  "33434": "33100",
  "33435": "33100",
  "33436": "33100",
  "33437": "33100",
  "33440": "17500",
  "33441": "33100",
  "33442": "33100",
  "33444": "33100",
  "33445": "33100",
  "33446": "33100",
  "33449": "33100",
  "33455": "38940",
  "33458": "33100",
  "33460": "33100",
  "33461": "33100",
  "33462": "33100",
  "33463": "33100",
  "33467": "33100",
  "33469": "33100",
  "33470": "33100",
  "33472": "33100",
  "33473": "33100",
  "33476": "33100",
  "33477": "33100",
  "33478": "33100",
  "33480": "33100",
  "33483": "33100",
  "33484": "33100",
  "33486": "33100",
  "33487": "33100",
  "33493": "33100",
  "33496": "33100",
  "33498": "33100",
  "33510": "45300",
  "33511": "45300",
  "33513": "45540",
  "33514": "45540",
  "33523": "45300",
  "33525": "45300",
  "33527": "45300",
  "33534": "45300",
  "33538": "45540",
  "33540": "45300",
  "33541": "45300",
  "33542": "45300",
  "33543": "45300",
  "33544": "45300",
  "33545": "45300",
  "33547": "45300",
  "33548": "45300",
  "33549": "45300",
  "33556": "45300",
  "33558": "45300",
  "33559": "45300",
  "33563": "45300",
  "33565": "45300",
  "33566": "45300",
  "33567": "45300",
  "33569": "45300",
  "33570": "45300",
  "33572": "45300",
  "33573": "45300",
  "33576": "45300",
  "33578": "45300",
  "33579": "45300",
  "33584": "45300",
  "33585": "45540",
  "33592": "45300",
  "33594": "45300",
  "33596": "45300",
  "33597": "45540",
  "33598": "45300",
  "33602": "45300",
  "33603": "45300",
  "33604": "45300",
  "33605": "45300",
  "33606": "45300",
  "33607": "45300",
  "33609": "45300",
  "33610": "45300",
  "33611": "45300",
  "33612": "45300",
  "33613": "45300",
  "33614": "45300",
  "33615": "45300",
  "33616": "45300",
  "33617": "45300",
  "33618": "45300",
  "33619": "45300",
  "33624": "45300",
  "33625": "45300",
  "33626": "45300",
  "33629": "45300",
  "33634": "45300",
  "33635": "45300",
  "33637": "45300",
  "33647": "45300",
  "33701": "45300",
  "33702": "45300",
  "33703": "45300",
  "33704": "45300",
  "33705": "45300",
  "33706": "45300",
  "33707": "45300",
  "33708": "45300",
  "33709": "45300",
  "33710": "45300",
  "33711": "45300",
  "33712": "45300",
  "33713": "45300",
  "33714": "45300",
  "33715": "45300",
  "33716": "45300",
  "33755": "45300",
  "33756": "45300",
  "33759": "45300",
  "33760": "45300",
  "33761": "45300",
  "33762": "45300",
  "33763": "45300",
  "33764": "45300",
  "33765": "45300",
  "33767": "45300",
  "33770": "45300",
  "33771": "45300",
  "33772": "45300",
  "33773": "45300",
  "33774": "45300",
  "33776": "45300",
  "33777": "45300",
  "33778": "45300",
  "33781": "45300",
  "33782": "45300",
  "33785": "45300",
  "33786": "45300",
  "33801": "29460",
  "33803": "29460",
  "33805": "29460",
  "33809": "29460",
  "33810": "29460",
  "33811": "29460",
  "33812": "29460",
  "33813": "29460",
  "33815": "29460",
  "33823": "29460",
  "33825": "42700",
  "33827": "29460",
  "33830": "29460",
  "33834": "48100",
  "33837": "29460",
  "33838": "29460",
  "33839": "29460",
  "33840": "29460",
  "33841": "29460",
  "33843": "29460",
  "33844": "29460",
  "33848": "36740",
  "33849": "29460",
  "33850": "29460",
  "33851": "29460",
  "33852": "42700",
  "33853": "29460",
  "33854": "29460",
  "33855": "29460",
  "33857": "42700",
  "33859": "29460",
  "33860": "29460",
  "33865": "48100",
  "33867": "36740",
  "33868": "29460",
  "33870": "42700",
  "33872": "42700",
  "33873": "48100",
  "33875": "42700",
  "33876": "42700",
  "33877": "29460",
  "33880": "29460",
  "33881": "29460",
  "33884": "29460",
  "33890": "48100",
  "33896": "29460",
  "33897": "29460",
  "33898": "29460",
  "33901": "15980",
  "33903": "15980",
  "33904": "15980",
  "33905": "15980",
  "33907": "15980",
  "33908": "15980",
  "33909": "15980",
  "33912": "15980",
  "33913": "15980",
  "33914": "15980",
  "33916": "15980",
  "33917": "15980",
  "33919": "15980",
  "33920": "15980",
  "33921": "39460",
  "33922": "15980",
  "33924": "15980",
  "33928": "15980",
  "33930": "17500",
  "33935": "17500",
  "33936": "15980",
  "33946": "39460",
  "33947": "39460",
  "33948": "39460",
  "33950": "39460",
  "33952": "39460",
  "33953": "39460",
  "33954": "39460",
  "33955": "39460",
  "33956": "15980",
  "33957": "15980",
  "33960": "42700",
  "33966": "15980",
  "33967": "15980",
  "33971": "15980",
  "33972": "15980",
  "33973": "15980",
  "33974": "15980",
  "33976": "15980",
  "33980": "39460",
  "33981": "39460",
  "33982": "39460",
  "33983": "39460",
  "33990": "15980",
  "33991": "15980",
  "33993": "15980",
  "34102": "34940",
  "34103": "34940",
  "34104": "34940",
  "34105": "34940",
  "34108": "34940",
  "34109": "34940",
  "34110": "34940",
  "34112": "34940",
  "34113": "34940",
  "34114": "34940",
  "34116": "34940",
  "34117": "34940",
  "34119": "34940",
  "34120": "34940",
  "34134": "15980",
  "34135": "15980",
  "34138": "34940",
  "34139": "34940",
  "34140": "34940",
  "34142": "34940",
  "34145": "34940",
  "34201": "35840",
  "34202": "35840",
  "34203": "35840",
  "34205": "35840",
  "34207": "35840",
  "34208": "35840",
  "34209": "35840",
  "34210": "35840",
  "34211": "35840",
  "34212": "35840",
  "34215": "35840",
  "34216": "35840",
  "34217": "35840",
  "34219": "35840",
  "34221": "35840",
  "34222": "35840",
  "34223": "35840",
  "34224": "39460",
  "34228": "35840",
  "34229": "35840",
  "34231": "35840",
  "34232": "35840",
  "34233": "35840",
  "34234": "35840",
  "34235": "35840",
  "34236": "35840",
  "34237": "35840",
  "34238": "35840",
  "34239": "35840",
  "34240": "35840",
  "34241": "35840",
  "34242": "35840",
  "34243": "35840",
  "34250": "35840",
  "34251": "35840",
  "34266": "11580",
  "34269": "11580",
  "34275": "35840",
  "34285": "35840",
  "34286": "35840",
  "34287": "35840",
  "34288": "35840",
  "34289": "35840",
  "34291": "35840",
  "34292": "35840",
  "34293": "35840",
  "34420": "36100",
  "34428": "26140",
  "34429": "26140",
  "34431": "36100",
  "34432": "36100",
  "34433": "26140",
  "34434": "26140",
  "34436": "26140",
  "34442": "26140",
  "34446": "26140",
  "34448": "26140",
  "34449": "23540",
  "34450": "26140",
  "34452": "26140",
  "34453": "26140",
  "34461": "26140",
  "34465": "26140",
  "34470": "36100",
  "34471": "36100",
  "34472": "36100",
  "34473": "36100",
  "34474": "36100",
  "34475": "36100",
  "34476": "36100",
  "34479": "36100",
  "34480": "36100",
  "34481": "36100",
  "34482": "36100",
  "34484": "45540",
  "34488": "36100",
  "34491": "36100",
  "34498": "23540",
  "34601": "45300",
  "34602": "45300",
  "34604": "45300",
  "34606": "45300",
  "34607": "45300",
  "34608": "45300",
  "34609": "45300",
  "34610": "45300",
  "34613": "45300",
  "34614": "45300",
  "34637": "45300",
  "34638": "45300",
  "34639": "45300",
  "34652": "45300",
  "34653": "45300",
  "34654": "45300",
  "34655": "45300",
  "34661": "45300",
  "34667": "45300",
  "34668": "45300",
  "34669": "45300",
  "34677": "45300",
  "34681": "45300",
  "34683": "45300",
  "34684": "45300",
  "34685": "45300",
  "34688": "45300",
  "34689": "45300",
  "34690": "45300",
  "34691": "45300",
  "34695": "45300",
  "34698": "45300",
  "34705": "36740",
  "34711": "36740",
  "34714": "36740",
  "34715": "36740",
  "34731": "36740",
  "34734": "36740",
  "34736": "36740",
  "34737": "36740",
  "34739": "36740",
  "34741": "36740",
  "34743": "36740",
  "34744": "36740",
  "34746": "36740",
  "34747": "36740",
  "34748": "36740",
  "34753": "36740",
  "34756": "36740",
  "34758": "36740",
  "34759": "29460",
  "34760": "36740",
  "34761": "36740",
  "34762": "36740",
  "34769": "36740",
  "34771": "36740",
  "34772": "36740",
  "34773": "36740",
  "34785": "45540",
  "34786": "36740",
  "34787": "36740",
  "34788": "36740",
  "34797": "36740",
  "34945": "38940",
  "34946": "38940",
  "34947": "38940",
  "34949": "38940",
  "34950": "38940",
  "34951": "38940",
  "34952": "38940",
  "34953": "38940",
  "34956": "38940",
  "34957": "38940",
  "34972": "36380",
  "34974": "36380",
  "34981": "38940",
  "34982": "38940",
  "34983": "38940",
  "34984": "38940",
  "34986": "38940",
  "34987": "38940",
  "34990": "38940",
  "34994": "38940",
  "34996": "38940",
  "34997": "38940",
  "35004": "13820",
  "35005": "13820",
  "35006": "13820",
  "35007": "13820",
  "35010": "10760",
  "35014": "45180",
  "35016": "10700",
  "35019": "18980",
  "35020": "13820",
  "35022": "13820",
  "35023": "13820",
  "35031": "13820",
  "35033": "18980",
  "35034": "13820",
  "35035": "13820",
  "35040": "13820",
  "35042": "13820",
  "35043": "13820",
  "35044": "45180",
  "35045": "13820",
  "35046": "13820",
  "35049": "13820",
  "35051": "13820",
  "35053": "18980",
  "35054": "13820",
  "35055": "18980",
  "35057": "18980",
  "35058": "18980",
  "35060": "13820",
  "35061": "13820",
  "35062": "13820",
  "35063": "27530",
  "35064": "13820",
  "35068": "13820",
  "35071": "13820",
  "35073": "13820",
  "35077": "18980",
  "35078": "13820",
  "35079": "13820",
  "35080": "13820",
  "35083": "18980",
  "35085": "13820",
  "35087": "18980",
  "35089": "10760",
  "35091": "13820",
  "35094": "13820",
  "35096": "45180",
  "35097": "13820",
  "35098": "18980",
  "35111": "46220",
  "35114": "13820",
  "35115": "13820",
  "35116": "13820",
  "35117": "13820",
  "35118": "13820",
  "35120": "13820",
  "35121": "13820",
  "35124": "13820",
  "35125": "13820",
  "35126": "13820",
  "35127": "13820",
  "35128": "13820",
  "35130": "27530",
  "35131": "13820",
  "35133": "13820",
  "35135": "13820",
  "35136": "10760",
  "35143": "13820",
  "35146": "13820",
  "35147": "13820",
  "35148": "27530",
  "35150": "45180",
  "35151": "45180",
  "35160": "45180",
  "35171": "13820",
  "35172": "13820",
  "35173": "13820",
  "35175": "10700",
  "35178": "13820",
  "35179": "18980",
  "35180": "13820",
  "35184": "13820",
  "35186": "13820",
  "35188": "13820",
  "35203": "13820",
  "35204": "13820",
  "35205": "13820",
  "35206": "13820",
  "35207": "13820",
  "35208": "13820",
  "35209": "13820",
  "35210": "13820",
  "35211": "13820",
  "35212": "13820",
  "35213": "13820",
  "35214": "13820",
  "35215": "13820",
  "35216": "13820",
  "35217": "13820",
  "35218": "13820",
  "35221": "13820",
  "35222": "13820",
  "35223": "13820",
  "35224": "13820",
  "35226": "13820",
  "35228": "13820",
  "35233": "13820",
  "35234": "13820",
  "35235": "13820",
  "35242": "13820",
  "35243": "13820",
  "35244": "13820",
  "35401": "46220",
  "35404": "46220",
  "35405": "46220",
  "35406": "46220",
  "35441": "46220",
  "35442": "46220",
  "35443": "46220",
  "35444": "46220",
  "35446": "46220",
  "35447": "46220",
  "35452": "46220",
  "35453": "46220",
  "35456": "46220",
  "35461": "46220",
  "35462": "46220",
  "35463": "46220",
  "35466": "46220",
  "35473": "46220",
  "35474": "46220",
  "35475": "46220",
  "35476": "46220",
  "35480": "46220",
  "35481": "46220",
  "35490": "46220",
  "35501": "27530",
  "35503": "27530",
  "35504": "27530",
  "35549": "27530",
  "35550": "27530",
  "35578": "27530",
  "35579": "27530",
  "35580": "27530",
  "35601": "19460",
  "35603": "19460",
  "35610": "22520",
  "35611": "26620",
  "35613": "26620",
  "35614": "26620",
  "35616": "22520",
  "35618": "19460",
  "35619": "19460",
  "35620": "26620",
  "35621": "19460",
  "35622": "19460",
  "35630": "22520",
  "35633": "22520",
  "35634": "22520",
  "35640": "19460",
  "35643": "19460",
  "35645": "22520",
  "35646": "22520",
  "35647": "26620",
  "35648": "22520",
  "35650": "19460",
  "35651": "19460",
  "35652": "22520",
  "35660": "22520",
  "35661": "22520",
  "35670": "19460",
  "35671": "26620",
  "35672": "19460",
  "35673": "19460",
  "35674": "22520",
  "35677": "22520",
  "35739": "26620",
  "35740": "42460",
  "35741": "26620",
  "35744": "42460",
  "35747": "10700",
  "35748": "26620",
  "35749": "26620",
  "35750": "26620",
  "35752": "42460",
  "35754": "19460",
  "35755": "42460",
  "35756": "26620",
  "35757": "26620",
  "35758": "26620",
  "35759": "26620",
  "35760": "26620",
  "35761": "26620",
  "35763": "26620",
  "35765": "42460",
  "35768": "42460",
  "35769": "42460",
  "35771": "42460",
  "35772": "42460",
  "35773": "26620",
  "35776": "42460",
  "35801": "26620",
  "35802": "26620",
  "35803": "26620",
  "35805": "26620",
  "35806": "26620",
  "35810": "26620",
  "35811": "26620",
  "35816": "26620",
  "35824": "26620",
  "35901": "23460",
  "35903": "23460",
  "35904": "23460",
  "35905": "23460",
  "35906": "23460",
  "35907": "23460",
  "35950": "10700",
  "35951": "10700",
  "35952": "23460",
  "35953": "13820",
  "35954": "23460",
  "35956": "23460",
  "35957": "10700",
  "35958": "42460",
  "35961": "22840",
  "35962": "22840",
  "35963": "22840",
  "35966": "42460",
  "35967": "22840",
  "35968": "22840",
  "35971": "22840",
  "35972": "23460",
  "35974": "22840",
  "35975": "22840",
  "35976": "10700",
  "35978": "22840",
  "35979": "22840",
  "35980": "10700",
  "35981": "22840",
  "35984": "22840",
  "35986": "22840",
  "35987": "13820",
  "35988": "22840",
  "35989": "22840",
  "36003": "33860",
  "36005": "45980",
  "36006": "33860",
  "36010": "45980",
  "36013": "33860",
  "36016": "21640",
  "36020": "33860",
  "36022": "33860",
  "36024": "33860",
  "36025": "33860",
  "36026": "10760",
  "36027": "21640",
  "36032": "33860",
  "36034": "45980",
  "36035": "45980",
  "36036": "33860",
  "36043": "33860",
  "36047": "33860",
  "36051": "33860",
  "36052": "33860",
  "36054": "33860",
  "36064": "33860",
  "36066": "33860",
  "36067": "33860",
  "36069": "33860",
  "36078": "33860",
  "36079": "45980",
  "36080": "33860",
  "36081": "45980",
  "36091": "13820",
  "36092": "33860",
  "36093": "33860",
  "36104": "33860",
  "36105": "33860",
  "36106": "33860",
  "36107": "33860",
  "36108": "33860",
  "36109": "33860",
  "36110": "33860",
  "36111": "33860",
  "36116": "33860",
  "36117": "33860",
  "36201": "11500",
  "36203": "11500",
  "36205": "11500",
  "36206": "11500",
  "36207": "11500",
  "36250": "11500",
  "36256": "10760",
  "36260": "11500",
  "36265": "11500",
  "36268": "45180",
  "36271": "11500",
  "36272": "11500",
  "36277": "11500",
  "36279": "11500",
  "36301": "20020",
  "36303": "20020",
  "36305": "20020",
  "36310": "20020",
  "36311": "37120",
  "36312": "20020",
  "36314": "20020",
  "36316": "20020",
  "36317": "20020",
  "36318": "20020",
  "36319": "20020",
  "36320": "20020",
  "36321": "20020",
  "36322": "37120",
  "36323": "21460",
  "36330": "21460",
  "36340": "20020",
  "36343": "20020",
  "36344": "20020",
  "36345": "20020",
  "36346": "21460",
  "36350": "37120",
  "36351": "21460",
  "36352": "20020",
  "36353": "20020",
  "36360": "37120",
  "36370": "20020",
  "36371": "37120",
  "36373": "20020",
  "36374": "37120",
  "36375": "20020",
  "36376": "20020",
  "36426": "12120",
  "36441": "12120",
  "36453": "21460",
  "36477": "20020",
  "36483": "12120",
  "36502": "12120",
  "36505": "33660",
  "36507": "19300",
  "36509": "33660",
  "36511": "19300",
  "36521": "33660",
  "36522": "33660",
  "36523": "33660",
  "36525": "33660",
  "36526": "19300",
  "36527": "19300",
  "36528": "33660",
  "36530": "19300",
  "36532": "19300",
  "36535": "19300",
  "36541": "33660",
  "36542": "19300",
  "36544": "33660",
  "36549": "19300",
  "36551": "19300",
  "36555": "19300",
  "36558": "33660",
  "36560": "33660",
  "36561": "19300",
  "36562": "19300",
  "36567": "19300",
  "36571": "33660",
  "36572": "33660",
  "36574": "19300",
  "36575": "33660",
  "36576": "19300",
  "36578": "19300",
  "36579": "19300",
  "36580": "19300",
  "36582": "33660",
  "36587": "33660",
  "36602": "33660",
  "36603": "33660",
  "36604": "33660",
  "36605": "33660",
  "36606": "33660",
  "36607": "33660",
  "36608": "33660",
  "36609": "33660",
  "36610": "33660",
  "36611": "33660",
  "36612": "33660",
  "36613": "33660",
  "36617": "33660",
  "36618": "33660",
  "36619": "33660",
  "36693": "33660",
  "36695": "33660",
  "36701": "42820",
  "36702": "42820",
  "36703": "42820",
  "36740": "46220",
  "36744": "46220",
  "36749": "33860",
  "36750": "13820",
  "36752": "33860",
  "36758": "42820",
  "36759": "42820",
  "36761": "33860",
  "36765": "46220",
  "36767": "42820",
  "36773": "42820",
  "36775": "42820",
  "36785": "33860",
  "36792": "13820",
  "36793": "13820",
  "36801": "12220",
  "36804": "12220",
  "36830": "12220",
  "36832": "12220",
  "36850": "10760",
  "36852": "29300",
  "36853": "10760",
  "36854": "29300",
  "36855": "29300",
  "36856": "17980",
  "36858": "17980",
  "36860": "17980",
  "36861": "10760",
  "36862": "29300",
  "36863": "29300",
  "36867": "17980",
  "36869": "17980",
  "36870": "12220",
  "36871": "17980",
  "36874": "12220",
  "36875": "17980",
  "36877": "12220",
  "36879": "12220",
  "37010": "17300",
  "37013": "34980",
  "37014": "34980",
  "37015": "34980",
  "37016": "34980",
  "37018": "46100",
  "37019": "30280",
  "37020": "34980",
  "37022": "34980",
  "37023": "17300",
  "37026": "34980",
  "37027": "34980",
  "37028": "17300",
  "37029": "34980",
  "37030": "34980",
  "37031": "34980",
  "37032": "34980",
  "37034": "30280",
  "37035": "34980",
  "37036": "34980",
  "37037": "34980",
  "37040": "17300",
  "37042": "17300",
  "37043": "17300",
  "37046": "34980",
  "37047": "30280",
  "37048": "34980",
  "37049": "34980",
  "37051": "34980",
  "37052": "17300",
  "37055": "34980",
  "37057": "34980",
  "37058": "17300",
  "37060": "34980",
  "37062": "34980",
  "37064": "34980",
  "37066": "34980",
  "37067": "34980",
  "37069": "34980",
  "37072": "34980",
  "37073": "34980",
  "37074": "34980",
  "37075": "34980",
  "37076": "34980",
  "37079": "17300",
  "37080": "34980",
  "37082": "34980",
  "37083": "34980",
  "37085": "34980",
  "37086": "34980",
  "37087": "34980",
  "37090": "34980",
  "37091": "30280",
  "37110": "32660",
  "37115": "34980",
  "37118": "34980",
  "37122": "34980",
  "37127": "34980",
  "37128": "34980",
  "37129": "34980",
  "37130": "34980",
  "37135": "34980",
  "37138": "34980",
  "37141": "34980",
  "37142": "17300",
  "37143": "34980",
  "37145": "34980",
  "37146": "34980",
  "37148": "34980",
  "37149": "34980",
  "37150": "34980",
  "37151": "34980",
  "37153": "34980",
  "37160": "43180",
  "37167": "34980",
  "37171": "17300",
  "37172": "34980",
  "37174": "34980",
  "37175": "17300",
  "37178": "17300",
  "37179": "34980",
  "37180": "43180",
  "37181": "34980",
  "37183": "43180",
  "37184": "34980",
  "37186": "34980",
  "37187": "34980",
  "37188": "34980",
  "37189": "34980",
  "37190": "34980",
  "37191": "17300",
  "37201": "34980",
  "37203": "34980",
  "37204": "34980",
  "37205": "34980",
  "37206": "34980",
  "37207": "34980",
  "37208": "34980",
  "37209": "34980",
  "37210": "34980",
  "37211": "34980",
  "37212": "34980",
  "37214": "34980",
  "37215": "34980",
  "37216": "34980",
  "37217": "34980",
  "37218": "34980",
  "37219": "34980",
  "37220": "34980",
  "37221": "34980",
  "37302": "16860",
  "37303": "11940",
  "37306": "46100",
  "37307": "17420",
  "37308": "16860",
  "37309": "11940",
  "37310": "17420",
  "37311": "17420",
  "37312": "17420",
  "37317": "17420",
  "37318": "46100",
  "37321": "19420",
  "37323": "17420",
  "37324": "46100",
  "37325": "17420",
  "37327": "16860",
  "37329": "11940",
  "37330": "46100",
  "37331": "11940",
  "37332": "19420",
  "37333": "17420",
  "37337": "19420",
  "37340": "16860",
  "37341": "16860",
  "37342": "46100",
  "37343": "16860",
  "37345": "46100",
  "37347": "16860",
  "37350": "16860",
  "37351": "16860",
  "37352": "46100",
  "37353": "17420",
  "37355": "46100",
  "37356": "16860",
  "37357": "32660",
  "37359": "46100",
  "37360": "43180",
  "37361": "17420",
  "37362": "17420",
  "37363": "16860",
  "37369": "17420",
  "37370": "11940",
  "37373": "16860",
  "37374": "16860",
  "37375": "46100",
  "37376": "46100",
  "37377": "16860",
  "37379": "16860",
  "37380": "16860",
  "37381": "19420",
  "37388": "46100",
  "37391": "17420",
  "37396": "16860",
  "37397": "16860",
  "37398": "46100",
  "37402": "16860",
  "37403": "16860",
  "37404": "16860",
  "37405": "16860",
  "37406": "16860",
  "37407": "16860",
  "37408": "16860",
  "37409": "16860",
  "37410": "16860",
  "37411": "16860",
  "37412": "16860",
  "37415": "16860",
  "37416": "16860",
  "37419": "16860",
  "37421": "16860",
  "37601": "27740",
  "37604": "27740",
  "37615": "27740",
  "37616": "24620",
  "37617": "28700",
  "37618": "28700",
  "37620": "28700",
  "37641": "24620",
  "37642": "28700",
  "37643": "27740",
  "37645": "28700",
  "37650": "27740",
  "37656": "27740",
  "37657": "27740",
  "37658": "27740",
  "37659": "27740",
  "37660": "28700",
  "37663": "28700",
  "37664": "28700",
  "37665": "28700",
  "37681": "27740",
  "37686": "28700",
  "37687": "27740",
  "37690": "27740",
  "37692": "27740",
  "37694": "27740",
  "37701": "28940",
  "37705": "28940",
  "37708": "34100",
  "37709": "34100",
  "37710": "28940",
  "37711": "28700",
  "37713": "35460",
  "37714": "28940",
  "37716": "28940",
  "37721": "28940",
  "37722": "35460",
  "37723": "18900",
  "37725": "34100",
  "37726": "28940",
  "37727": "35460",
  "37729": "28940",
  "37731": "28700",
  "37737": "28940",
  "37738": "42940",
  "37742": "28940",
  "37743": "24620",
  "37745": "24620",
  "37748": "28940",
  "37753": "35460",
  "37754": "28940",
  "37757": "28940",
  "37760": "34100",
  "37762": "28940",
  "37763": "28940",
  "37764": "42940",
  "37766": "28940",
  "37769": "28940",
  "37770": "28940",
  "37771": "28940",
  "37772": "28940",
  "37774": "28940",
  "37777": "28940",
  "37779": "28940",
  "37801": "28940",
  "37803": "28940",
  "37804": "28940",
  "37806": "28940",
  "37807": "28940",
  "37809": "24620",
  "37810": "24620",
  "37811": "28700",
  "37813": "34100",
  "37814": "34100",
  "37818": "24620",
  "37819": "28940",
  "37820": "34100",
  "37821": "35460",
  "37826": "11940",
  "37828": "28940",
  "37829": "28940",
  "37830": "28940",
  "37840": "28940",
  "37843": "35460",
  "37846": "28940",
  "37847": "28940",
  "37848": "34100",
  "37849": "28940",
  "37853": "28940",
  "37854": "28940",
  "37857": "28700",
  "37860": "34100",
  "37861": "34100",
  "37862": "42940",
  "37863": "42940",
  "37865": "42940",
  "37866": "28940",
  "37871": "28940",
  "37872": "28940",
  "37873": "28700",
  "37876": "42940",
  "37877": "34100",
  "37878": "28940",
  "37880": "28940",
  "37882": "28940",
  "37886": "28940",
  "37887": "28940",
  "37888": "34100",
  "37890": "34100",
  "37891": "28700",
  "37902": "28940",
  "37909": "28940",
  "37912": "28940",
  "37914": "28940",
  "37915": "28940",
  "37916": "28940",
  "37917": "28940",
  "37918": "28940",
  "37919": "28940",
  "37920": "28940",
  "37921": "28940",
  "37922": "28940",
  "37923": "28940",
  "37924": "28940",
  "37931": "28940",
  "37932": "28940",
  "37934": "28940",
  "37938": "28940",
  "38001": "27180",
  "38002": "32820",
  "38004": "32820",
  "38006": "27180",
  "38011": "32820",
  "38012": "15140",
  "38015": "32820",
  "38016": "32820",
  "38017": "32820",
  "38018": "32820",
  "38019": "32820",
  "38023": "32820",
  "38024": "20540",
  "38028": "32820",
  "38030": "20540",
  "38034": "27180",
  "38037": "15140",
  "38049": "32820",
  "38053": "32820",
  "38057": "32820",
  "38058": "32820",
  "38059": "20540",
  "38060": "32820",
  "38066": "32820",
  "38068": "32820",
  "38069": "15140",
  "38076": "32820",
  "38103": "32820",
  "38104": "32820",
  "38105": "32820",
  "38106": "32820",
  "38107": "32820",
  "38108": "32820",
  "38109": "32820",
  "38111": "32820",
  "38112": "32820",
  "38114": "32820",
  "38115": "32820",
  "38116": "32820",
  "38117": "32820",
  "38118": "32820",
  "38119": "32820",
  "38120": "32820",
  "38122": "32820",
  "38125": "32820",
  "38126": "32820",
  "38127": "32820",
  "38128": "32820",
  "38133": "32820",
  "38134": "32820",
  "38135": "32820",
  "38138": "32820",
  "38139": "32820",
  "38141": "32820",
  "38222": "37540",
  "38224": "37540",
  "38225": "32280",
  "38226": "32280",
  "38229": "32280",
  "38230": "32280",
  "38231": "37540",
  "38232": "46460",
  "38233": "27180",
  "38236": "37540",
  "38237": "32280",
  "38240": "46460",
  "38241": "32280",
  "38242": "37540",
  "38251": "37540",
  "38253": "46460",
  "38255": "32280",
  "38256": "37540",
  "38257": "46460",
  "38259": "20540",
  "38260": "46460",
  "38261": "46460",
  "38301": "27180",
  "38305": "27180",
  "38313": "27180",
  "38316": "27180",
  "38330": "27180",
  "38332": "27180",
  "38337": "27180",
  "38340": "27180",
  "38343": "27180",
  "38347": "27180",
  "38352": "27180",
  "38355": "27180",
  "38356": "27180",
  "38358": "27180",
  "38362": "27180",
  "38366": "27180",
  "38369": "27180",
  "38382": "27180",
  "38391": "27180",
  "38392": "27180",
  "38401": "34980",
  "38451": "34980",
  "38456": "29980",
  "38457": "29980",
  "38461": "34980",
  "38464": "29980",
  "38468": "29980",
  "38469": "29980",
  "38474": "34980",
  "38481": "29980",
  "38482": "34980",
  "38483": "29980",
  "38486": "29980",
  "38487": "34980",
  "38501": "18260",
  "38506": "18260",
  "38541": "18260",
  "38543": "18260",
  "38544": "18260",
  "38545": "18260",
  "38547": "34980",
  "38548": "18260",
  "38552": "34980",
  "38554": "18260",
  "38555": "18900",
  "38558": "18900",
  "38560": "34980",
  "38562": "18260",
  "38563": "34980",
  "38564": "18260",
  "38567": "34980",
  "38570": "18260",
  "38571": "18900",
  "38572": "18900",
  "38573": "18260",
  "38574": "18260",
  "38580": "18260",
  "38581": "32660",
  "38582": "18260",
  "38588": "18260",
  "38601": "37060",
  "38611": "32820",
  "38614": "17260",
  "38617": "17260",
  "38618": "32820",
  "38626": "32820",
  "38627": "37060",
  "38631": "17260",
  "38632": "32820",
  "38635": "32820",
  "38637": "32820",
  "38639": "17260",
  "38641": "32820",
  "38645": "17260",
  "38651": "32820",
  "38654": "32820",
  "38655": "37060",
  "38659": "32820",
  "38661": "32820",
  "38664": "32820",
  "38665": "32820",
  "38668": "32820",
  "38671": "32820",
  "38672": "32820",
  "38673": "37060",
  "38676": "32820",
  "38680": "32820",
  "38683": "18420",
  "38685": "32820",
  "38701": "24740",
  "38703": "24740",
  "38720": "17260",
  "38722": "24740",
  "38725": "17380",
  "38732": "17380",
  "38736": "26940",
  "38737": "26940",
  "38744": "24740",
  "38748": "24740",
  "38751": "26940",
  "38753": "26940",
  "38756": "24740",
  "38759": "17380",
  "38760": "24740",
  "38761": "26940",
  "38769": "17380",
  "38771": "26940",
  "38773": "26940",
  "38778": "26940",
  "38801": "46180",
  "38804": "46180",
  "38824": "46180",
  "38826": "46180",
  "38829": "46180",
  "38834": "18420",
  "38841": "46180",
  "38843": "46180",
  "38847": "46180",
  "38849": "46180",
  "38855": "46180",
  "38856": "46180",
  "38857": "46180",
  "38858": "46180",
  "38859": "46180",
  "38862": "46180",
  "38863": "46180",
  "38864": "46180",
  "38865": "18420",
  "38866": "46180",
  "38868": "46180",
  "38871": "46180",
  "38876": "46180",
  "38901": "24980",
  "38917": "24900",
  "38923": "24900",
  "38924": "27140",
  "38929": "24980",
  "38930": "24900",
  "38940": "24980",
  "38941": "24900",
  "38943": "24900",
  "38944": "24900",
  "38946": "24900",
  "38952": "24900",
  "38954": "24900",
  "38963": "17260",
  "39039": "27140",
  "39040": "27140",
  "39041": "27140",
  "39042": "27140",
  "39044": "27140",
  "39046": "27140",
  "39047": "27140",
  "39056": "27140",
  "39059": "27140",
  "39063": "27140",
  "39066": "27140",
  "39071": "27140",
  "39073": "27140",
  "39079": "27140",
  "39082": "27140",
  "39083": "27140",
  "39095": "27140",
  "39110": "27140",
  "39111": "27140",
  "39114": "27140",
  "39119": "25620",
  "39120": "35020",
  "39145": "27140",
  "39146": "27140",
  "39149": "27140",
  "39154": "27140",
  "39157": "27140",
  "39169": "27140",
  "39170": "27140",
  "39175": "27140",
  "39176": "24900",
  "39179": "27140",
  "39180": "46980",
  "39183": "46980",
  "39191": "15020",
  "39194": "27140",
  "39202": "27140",
  "39203": "27140",
  "39204": "27140",
  "39206": "27140",
  "39208": "27140",
  "39209": "27140",
  "39211": "27140",
  "39212": "27140",
  "39213": "27140",
  "39216": "27140",
  "39218": "27140",
  "39232": "27140",
  "39272": "27140",
  "39301": "32940",
  "39305": "32940",
  "39307": "32940",
  "39320": "32940",
  "39325": "32940",
  "39326": "32940",
  "39328": "32940",
  "39330": "32940",
  "39335": "32940",
  "39338": "29860",
  "39342": "32940",
  "39347": "32940",
  "39354": "32940",
  "39355": "32940",
  "39356": "29860",
  "39360": "32940",
  "39363": "32940",
  "39364": "32940",
  "39366": "29860",
  "39401": "25620",
  "39402": "25620",
  "39422": "29860",
  "39423": "25620",
  "39425": "25620",
  "39426": "38100",
  "39428": "25620",
  "39437": "29860",
  "39439": "29860",
  "39440": "29860",
  "39443": "29860",
  "39455": "25620",
  "39459": "29860",
  "39462": "25620",
  "39464": "29860",
  "39465": "25620",
  "39466": "38100",
  "39470": "38100",
  "39475": "25620",
  "39476": "25620",
  "39479": "25620",
  "39480": "29860",
  "39481": "29860",
  "39482": "25620",
  "39501": "25060",
  "39503": "25060",
  "39507": "25060",
  "39520": "25060",
  "39525": "25060",
  "39530": "25060",
  "39531": "25060",
  "39532": "25060",
  "39540": "25060",
  "39553": "25060",
  "39556": "25060",
  "39560": "25060",
  "39561": "25060",
  "39562": "25060",
  "39563": "25060",
  "39564": "25060",
  "39565": "25060",
  "39567": "25060",
  "39571": "25060",
  "39572": "25060",
  "39573": "25060",
  "39574": "25060",
  "39576": "25060",
  "39577": "25060",
  "39581": "25060",
  "39601": "15020",
  "39629": "15020",
  "39648": "32620",
  "39652": "32620",
  "39662": "15020",
  "39664": "15020",
  "39666": "32620",
  "39701": "18060",
  "39702": "18060",
  "39705": "18060",
  "39737": "44260",
  "39740": "18060",
  "39741": "48500",
  "39743": "18060",
  "39744": "44260",
  "39750": "44260",
  "39751": "44260",
  "39752": "44260",
  "39755": "48500",
  "39759": "44260",
  "39766": "18060",
  "39767": "44260",
  "39769": "44260",
  "39773": "48500",
  "39815": "12460",
  "39817": "12460",
  "39819": "12460",
  "39825": "12460",
  "39826": "10500",
  "39834": "12460",
  "39842": "10500",
  "39854": "21640",
  "40003": "31140",
  "40004": "12680",
  "40008": "12680",
  "40010": "31140",
  "40011": "31140",
  "40012": "12680",
  "40013": "12680",
  "40014": "31140",
  "40019": "31140",
  "40022": "31140",
  "40023": "31140",
  "40026": "31140",
  "40031": "31140",
  "40036": "31140",
  "40046": "31140",
  "40047": "31140",
  "40050": "31140",
  "40051": "12680",
  "40052": "12680",
  "40056": "31140",
  "40057": "31140",
  "40059": "31140",
  "40065": "31140",
  "40067": "31140",
  "40068": "31140",
  "40070": "31140",
  "40071": "31140",
  "40075": "31140",
  "40076": "31140",
  "40077": "31140",
  "40104": "21060",
  "40107": "12680",
  "40108": "21060",
  "40109": "31140",
  "40117": "21060",
  "40118": "31140",
  "40142": "21060",
  "40150": "31140",
  "40155": "21060",
  "40157": "21060",
  "40160": "21060",
  "40162": "21060",
  "40165": "31140",
  "40175": "21060",
  "40177": "31140",
  "40202": "31140",
  "40203": "31140",
  "40204": "31140",
  "40205": "31140",
  "40206": "31140",
  "40207": "31140",
  "40208": "31140",
  "40209": "31140",
  "40210": "31140",
  "40211": "31140",
  "40212": "31140",
  "40213": "31140",
  "40214": "31140",
  "40215": "31140",
  "40216": "31140",
  "40217": "31140",
  "40218": "31140",
  "40219": "31140",
  "40220": "31140",
  "40222": "31140",
  "40223": "31140",
  "40228": "31140",
  "40229": "31140",
  "40241": "31140",
  "40242": "31140",
  "40243": "31140",
  "40245": "31140",
  "40258": "31140",
  "40272": "31140",
  "40291": "31140",
  "40299": "31140",
  "40316": "34460",
  "40322": "34460",
  "40324": "30460",
  "40328": "19220",
  "40336": "40080",
  "40337": "34460",
  "40342": "23180",
  "40346": "34460",
  "40347": "30460",
  "40348": "30460",
  "40353": "34460",
  "40356": "30460",
  "40358": "34460",
  "40360": "34460",
  "40361": "30460",
  "40370": "30460",
  "40371": "34460",
  "40374": "34460",
  "40379": "30460",
  "40383": "30460",
  "40385": "40080",
  "40387": "34460",
  "40390": "30460",
  "40391": "30460",
  "40403": "40080",
  "40419": "19220",
  "40422": "19220",
  "40437": "19220",
  "40440": "19220",
  "40442": "19220",
  "40461": "40080",
  "40464": "19220",
  "40468": "19220",
  "40472": "40080",
  "40475": "40080",
  "40484": "19220",
  "40489": "19220",
  "40502": "30460",
  "40503": "30460",
  "40504": "30460",
  "40505": "30460",
  "40507": "30460",
  "40508": "30460",
  "40509": "30460",
  "40510": "30460",
  "40511": "30460",
  "40513": "30460",
  "40514": "30460",
  "40515": "30460",
  "40516": "30460",
  "40517": "30460",
  "40601": "23180",
  "40701": "30940",
  "40729": "30940",
  "40734": "30940",
  "40737": "30940",
  "40740": "30940",
  "40741": "30940",
  "40744": "30940",
  "40759": "30940",
  "40763": "30940",
  "40769": "30940",
  "40771": "30940",
  "40813": "33180",
  "40845": "33180",
  "40856": "33180",
  "40902": "33180",
  "40903": "30940",
  "40906": "30940",
  "40915": "30940",
  "40923": "30940",
  "40935": "30940",
  "40939": "33180",
  "40940": "33180",
  "40943": "30940",
  "40949": "30940",
  "40953": "30940",
  "40958": "33180",
  "40962": "30940",
  "40965": "33180",
  "40977": "33180",
  "40988": "33180",
  "41001": "17140",
  "41002": "17140",
  "41004": "17140",
  "41005": "17140",
  "41006": "17140",
  "41007": "17140",
  "41010": "17140",
  "41011": "17140",
  "41014": "17140",
  "41015": "17140",
  "41016": "17140",
  "41017": "17140",
  "41018": "17140",
  "41030": "17140",
  "41033": "17140",
  "41034": "32500",
  "41035": "17140",
  "41040": "17140",
  "41042": "17140",
  "41043": "17140",
  "41044": "17140",
  "41046": "17140",
  "41048": "17140",
  "41051": "17140",
  "41052": "17140",
  "41055": "32500",
  "41056": "32500",
  "41059": "17140",
  "41063": "17140",
  "41071": "17140",
  "41073": "17140",
  "41074": "17140",
  "41075": "17140",
  "41076": "17140",
  "41080": "17140",
  "41083": "17140",
  "41085": "17140",
  "41086": "17140",
  "41091": "17140",
  "41092": "17140",
  "41094": "17140",
  "41097": "17140",
  "41101": "26580",
  "41102": "26580",
  "41121": "26580",
  "41129": "26580",
  "41132": "26580",
  "41139": "26580",
  "41143": "26580",
  "41144": "26580",
  "41146": "26580",
  "41164": "26580",
  "41168": "26580",
  "41169": "26580",
  "41174": "26580",
  "41175": "26580",
  "41183": "26580",
  "42001": "37140",
  "42003": "37140",
  "42020": "34660",
  "42024": "37140",
  "42027": "32460",
  "42028": "37140",
  "42036": "34660",
  "42040": "32460",
  "42045": "37140",
  "42047": "37140",
  "42049": "34660",
  "42051": "32460",
  "42053": "37140",
  "42054": "34660",
  "42056": "37140",
  "42058": "37140",
  "42066": "32460",
  "42069": "32460",
  "42071": "34660",
  "42076": "34660",
  "42078": "37140",
  "42079": "32460",
  "42081": "37140",
  "42082": "32460",
  "42083": "37140",
  "42085": "32460",
  "42086": "37140",
  "42087": "37140",
  "42088": "32460",
  "42101": "14540",
  "42103": "14540",
  "42104": "14540",
  "42120": "14540",
  "42122": "14540",
  "42123": "23980",
  "42127": "23980",
  "42129": "23980",
  "42133": "23980",
  "42141": "23980",
  "42154": "23980",
  "42156": "23980",
  "42159": "14540",
  "42160": "23980",
  "42164": "14540",
  "42166": "23980",
  "42170": "14540",
  "42171": "14540",
  "42207": "14540",
  "42210": "14540",
  "42211": "17300",
  "42214": "23980",
  "42215": "17300",
  "42217": "17300",
  "42232": "17300",
  "42236": "17300",
  "42240": "17300",
  "42254": "17300",
  "42259": "14540",
  "42261": "14540",
  "42262": "17300",
  "42266": "17300",
  "42273": "14540",
  "42274": "14540",
  "42275": "14540",
  "42285": "14540",
  "42301": "36980",
  "42303": "36980",
  "42321": "16420",
  "42323": "16420",
  "42324": "16420",
  "42325": "16420",
  "42326": "16420",
  "42327": "36980",
  "42330": "16420",
  "42337": "16420",
  "42339": "16420",
  "42344": "16420",
  "42345": "16420",
  "42348": "36980",
  "42350": "36980",
  "42351": "36980",
  "42352": "36980",
  "42355": "36980",
  "42366": "36980",
  "42367": "16420",
  "42371": "36980",
  "42372": "36980",
  "42376": "36980",
  "42378": "36980",
  "42406": "21780",
  "42408": "31580",
  "42410": "31580",
  "42413": "31580",
  "42420": "21780",
  "42431": "31580",
  "42436": "31580",
  "42440": "31580",
  "42441": "31580",
  "42442": "31580",
  "42451": "21780",
  "42452": "21780",
  "42453": "31580",
  "42458": "21780",
  "42464": "31580",
  "42501": "43700",
  "42503": "43700",
  "42518": "43700",
  "42519": "43700",
  "42533": "43700",
  "42544": "43700",
  "42553": "43700",
  "42567": "43700",
  "42701": "21060",
  "42716": "21060",
  "42718": "15820",
  "42724": "21060",
  "42732": "21060",
  "42733": "15820",
  "42740": "21060",
  "42743": "15820",
  "42748": "21060",
  "42764": "15820",
  "42776": "21060",
  "42782": "15820",
  "42784": "21060",
  "42788": "21060",
  "43001": "18140",
  "43003": "18140",
  "43004": "18140",
  "43008": "18140",
  "43009": "46500",
  "43010": "44220",
  "43011": "34540",
  "43013": "18140",
  "43014": "34540",
  "43015": "18140",
  "43016": "18140",
  "43017": "18140",
  "43018": "18140",
  "43019": "34540",
  "43021": "18140",
  "43022": "34540",
  "43023": "18140",
  "43025": "18140",
  "43026": "18140",
  "43028": "34540",
  "43029": "18140",
  "43031": "18140",
  "43033": "18140",
  "43035": "18140",
  "43036": "18140",
  "43037": "34540",
  "43040": "18140",
  "43044": "46500",
  "43045": "18140",
  "43046": "18140",
  "43050": "34540",
  "43054": "18140",
  "43055": "18140",
  "43056": "18140",
  "43060": "46500",
  "43061": "18140",
  "43062": "18140",
  "43064": "18140",
  "43065": "18140",
  "43066": "18140",
  "43067": "18140",
  "43068": "18140",
  "43071": "18140",
  "43072": "46500",
  "43074": "18140",
  "43076": "18140",
  "43077": "18140",
  "43078": "46500",
  "43080": "18140",
  "43081": "18140",
  "43082": "18140",
  "43084": "46500",
  "43085": "18140",
  "43102": "18140",
  "43103": "18140",
  "43105": "18140",
  "43106": "47920",
  "43107": "18140",
  "43110": "18140",
  "43112": "18140",
  "43113": "18140",
  "43115": "17060",
  "43116": "18140",
  "43119": "18140",
  "43123": "18140",
  "43125": "18140",
  "43128": "47920",
  "43130": "18140",
  "43135": "18140",
  "43136": "18140",
  "43137": "18140",
  "43138": "18140",
  "43140": "18140",
  "43143": "18140",
  "43145": "18140",
  "43146": "18140",
  "43147": "18140",
  "43148": "18140",
  "43149": "18140",
  "43150": "18140",
  "43151": "18140",
  "43152": "18140",
  "43153": "18140",
  "43154": "18140",
  "43155": "18140",
  "43156": "18140",
  "43157": "18140",
  "43160": "47920",
  "43162": "18140",
  "43164": "18140",
  "43201": "18140",
  "43202": "18140",
  "43203": "18140",
  "43204": "18140",
  "43205": "18140",
  "43206": "18140",
  "43207": "18140",
  "43209": "18140",
  "43211": "18140",
  "43212": "18140",
  "43213": "18140",
  "43214": "18140",
  "43215": "18140",
  "43217": "18140",
  "43219": "18140",
  "43220": "18140",
  "43221": "18140",
  "43222": "18140",
  "43223": "18140",
  "43224": "18140",
  "43227": "18140",
  "43228": "18140",
  "43229": "18140",
  "43230": "18140",
  "43231": "18140",
  "43232": "18140",
  "43235": "18140",
  "43240": "18140",
  "43302": "32020",
  "43310": "13340",
  "43311": "13340",
  "43314": "32020",
  "43315": "18140",
  "43318": "13340",
  "43319": "13340",
  "43320": "18140",
  "43321": "18140",
  "43322": "32020",
  "43324": "13340",
  "43331": "13340",
  "43332": "32020",
  "43333": "13340",
  "43334": "18140",
  "43337": "32020",
  "43338": "18140",
  "43341": "32020",
  "43342": "32020",
  "43343": "13340",
  "43344": "18140",
  "43347": "13340",
  "43348": "13340",
  "43356": "32020",
  "43357": "13340",
  "43358": "13340",
  "43360": "13340",
  "43402": "45780",
  "43406": "45780",
  "43407": "23380",
  "43410": "23380",
  "43412": "45780",
  "43413": "45780",
  "43416": "45780",
  "43420": "23380",
  "43430": "45780",
  "43431": "23380",
  "43432": "45780",
  "43435": "23380",
  "43437": "45780",
  "43438": "41780",
  "43440": "45780",
  "43442": "23380",
  "43443": "45780",
  "43445": "45780",
  "43446": "45780",
  "43447": "45780",
  "43449": "45780",
  "43450": "45780",
  "43451": "45780",
  "43452": "45780",
  "43456": "45780",
  "43457": "23380",
  "43460": "45780",
  "43462": "45780",
  "43463": "45780",
  "43464": "41780",
  "43465": "45780",
  "43466": "45780",
  "43469": "23380",
  "43502": "45780",
  "43504": "45780",
  "43511": "45780",
  "43512": "19580",
  "43515": "45780",
  "43517": "19580",
  "43521": "45780",
  "43522": "45780",
  "43525": "45780",
  "43526": "19580",
  "43528": "45780",
  "43533": "45780",
  "43536": "19580",
  "43537": "45780",
  "43540": "45780",
  "43542": "45780",
  "43547": "45780",
  "43549": "19580",
  "43551": "45780",
  "43556": "19580",
  "43558": "45780",
  "43560": "45780",
  "43565": "45780",
  "43566": "45780",
  "43567": "45780",
  "43569": "45780",
  "43571": "45780",
  "43604": "45780",
  "43605": "45780",
  "43606": "45780",
  "43607": "45780",
  "43608": "45780",
  "43609": "45780",
  "43610": "45780",
  "43611": "45780",
  "43612": "45780",
  "43613": "45780",
  "43614": "45780",
  "43615": "45780",
  "43616": "45780",
  "43617": "45780",
  "43619": "45780",
  "43620": "45780",
  "43623": "45780",
  "43701": "49780",
  "43713": "48540",
  "43718": "48540",
  "43719": "48540",
  "43720": "49780",
  "43723": "15740",
  "43725": "15740",
  "43727": "49780",
  "43730": "18140",
  "43732": "15740",
  "43734": "49780",
  "43735": "49780",
  "43739": "18140",
  "43746": "49780",
  "43748": "18140",
  "43749": "15740",
  "43755": "15740",
  "43759": "48540",
  "43760": "18140",
  "43762": "49780",
  "43764": "18140",
  "43766": "18140",
  "43767": "49780",
  "43771": "49780",
  "43773": "15740",
  "43777": "49780",
  "43778": "15740",
  "43782": "18140",
  "43783": "18140",
  "43802": "49780",
  "43811": "18740",
  "43812": "18740",
  "43821": "49780",
  "43822": "18740",
  "43824": "18740",
  "43830": "49780",
  "43832": "35420",
  "43837": "35420",
  "43842": "49780",
  "43843": "34540",
  "43844": "18740",
  "43845": "18740",
  "43901": "48260",
  "43903": "48260",
  "43906": "48540",
  "43908": "48260",
  "43910": "48260",
  "43912": "48540",
  "43913": "48260",
  "43917": "48260",
  "43920": "41400",
  "43930": "48260",
  "43932": "48260",
  "43933": "48540",
  "43935": "48540",
  "43938": "48260",
  "43940": "48540",
  "43942": "48540",
  "43943": "48260",
  "43944": "48260",
  "43945": "41400",
  "43947": "48540",
  "43950": "48540",
  "43952": "48260",
  "43953": "48260",
  "43963": "48260",
  "43964": "48260",
  "43968": "41400",
  "43971": "48260",
  "43977": "48540",
  "43983": "48540",
  "44001": "17460",
  "44003": "11780",
  "44004": "11780",
  "44010": "11780",
  "44011": "17460",
  "44012": "17460",
  "44017": "17460",
  "44021": "17460",
  "44022": "17460",
  "44023": "17460",
  "44024": "17460",
  "44026": "17460",
  "44028": "17460",
  "44030": "11780",
  "44032": "11780",
  "44035": "17460",
  "44039": "17460",
  "44040": "17460",
  "44041": "11780",
  "44044": "17460",
  "44045": "17460",
  "44046": "17460",
  "44047": "11780",
  "44048": "11780",
  "44049": "17460",
  "44050": "17460",
  "44052": "17460",
  "44053": "17460",
  "44054": "17460",
  "44055": "17460",
  "44056": "10420",
  "44057": "17460",
  "44060": "17460",
  "44062": "17460",
  "44064": "17460",
  "44065": "17460",
  "44067": "10420",
  "44068": "11780",
  "44070": "17460",
  "44072": "17460",
  "44074": "17460",
  "44076": "11780",
  "44077": "17460",
  "44081": "17460",
  "44082": "11780",
  "44084": "11780",
  "44085": "11780",
  "44086": "17460",
  "44087": "10420",
  "44089": "41780",
  "44090": "17460",
  "44092": "17460",
  "44093": "11780",
  "44094": "17460",
  "44095": "17460",
  "44099": "11780",
  "44102": "17460",
  "44103": "17460",
  "44104": "17460",
  "44105": "17460",
  "44106": "17460",
  "44107": "17460",
  "44108": "17460",
  "44109": "17460",
  "44110": "17460",
  "44111": "17460",
  "44112": "17460",
  "44113": "17460",
  "44114": "17460",
  "44115": "17460",
  "44116": "17460",
  "44117": "17460",
  "44118": "17460",
  "44119": "17460",
  "44120": "17460",
  "44121": "17460",
  "44122": "17460",
  "44123": "17460",
  "44124": "17460",
  "44125": "17460",
  "44126": "17460",
  "44127": "17460",
  "44128": "17460",
  "44129": "17460",
  "44130": "17460",
  "44131": "17460",
  "44132": "17460",
  "44133": "17460",
  "44134": "17460",
  "44135": "17460",
  "44136": "17460",
  "44137": "17460",
  "44138": "17460",
  "44139": "17460",
  "44140": "17460",
  "44141": "17460",
  "44142": "17460",
  "44143": "17460",
  "44144": "17460",
  "44145": "17460",
  "44146": "17460",
  "44147": "17460",
  "44149": "17460",
  "44201": "10420",
  "44202": "10420",
  "44203": "10420",
  "44212": "17460",
  "44214": "49300",
  "44215": "17460",
  "44216": "10420",
  "44217": "49300",
  "44221": "10420",
  "44223": "10420",
  "44224": "10420",
  "44230": "49300",
  "44231": "10420",
  "44233": "17460",
  "44234": "10420",
  "44235": "17460",
  "44236": "10420",
  "44240": "10420",
  "44241": "10420",
  "44250": "10420",
  "44251": "17460",
  "44253": "17460",
  "44254": "17460",
  "44255": "10420",
  "44256": "17460",
  "44260": "10420",
  "44262": "10420",
  "44264": "10420",
  "44266": "10420",
  "44270": "49300",
  "44272": "10420",
  "44273": "17460",
  "44275": "17460",
  "44276": "49300",
  "44278": "10420",
  "44280": "17460",
  "44281": "17460",
  "44286": "10420",
  "44287": "49300",
  "44288": "10420",
  "44301": "10420",
  "44302": "10420",
  "44303": "10420",
  "44304": "10420",
  "44305": "10420",
  "44306": "10420",
  "44307": "10420",
  "44310": "10420",
  "44311": "10420",
  "44312": "10420",
  "44313": "10420",
  "44314": "10420",
  "44319": "10420",
  "44320": "10420",
  "44321": "10420",
  "44333": "10420",
  "44401": "49660",
  "44402": "49660",
  "44403": "49660",
  "44404": "49660",
  "44405": "49660",
  "44406": "49660",
  "44408": "41400",
  "44410": "49660",
  "44411": "10420",
  "44412": "10420",
  "44413": "41400",
  "44417": "49660",
  "44418": "49660",
  "44420": "49660",
  "44423": "41400",
  "44425": "49660",
  "44427": "15940",
  "44428": "49660",
  "44429": "49660",
  "44430": "49660",
  "44431": "41400",
  "44432": "41400",
  "44436": "49660",
  "44437": "49660",
  "44438": "49660",
  "44440": "49660",
  "44441": "41400",
  "44442": "49660",
  "44443": "49660",
  "44444": "49660",
  "44445": "41400",
  "44446": "49660",
  "44449": "49660",
  "44450": "49660",
  "44451": "49660",
  "44452": "49660",
  "44454": "49660",
  "44455": "41400",
  "44460": "41400",
  "44470": "49660",
  "44471": "49660",
  "44473": "49660",
  "44481": "49660",
  "44483": "49660",
  "44484": "49660",
  "44485": "49660",
  "44490": "41400",
  "44491": "49660",
  "44502": "49660",
  "44504": "49660",
  "44505": "49660",
  "44506": "49660",
  "44507": "49660",
  "44509": "49660",
  "44510": "49660",
  "44511": "49660",
  "44512": "49660",
  "44514": "49660",
  "44515": "49660",
  "44601": "15940",
  "44606": "49300",
  "44608": "15940",
  "44609": "49660",
  "44612": "35420",
  "44613": "15940",
  "44614": "15940",
  "44615": "15940",
  "44618": "49300",
  "44619": "49660",
  "44620": "15940",
  "44621": "35420",
  "44622": "35420",
  "44624": "35420",
  "44625": "41400",
  "44626": "15940",
  "44629": "35420",
  "44632": "15940",
  "44634": "41400",
  "44641": "15940",
  "44643": "15940",
  "44644": "15940",
  "44645": "49300",
  "44646": "15940",
  "44647": "15940",
  "44651": "15940",
  "44656": "35420",
  "44657": "41400",
  "44662": "15940",
  "44663": "35420",
  "44666": "15940",
  "44667": "49300",
  "44669": "15940",
  "44672": "49660",
  "44675": "15940",
  "44676": "49300",
  "44677": "49300",
  "44680": "35420",
  "44683": "35420",
  "44685": "10420",
  "44688": "15940",
  "44691": "49300",
  "44695": "15940",
  "44703": "15940",
  "44704": "15940",
  "44705": "15940",
  "44706": "15940",
  "44707": "15940",
  "44708": "15940",
  "44709": "15940",
  "44710": "15940",
  "44714": "15940",
  "44718": "15940",
  "44720": "15940",
  "44721": "15940",
  "44730": "15940",
  "44802": "22300",
  "44804": "22300",
  "44805": "11740",
  "44807": "45660",
  "44811": "35940",
  "44813": "31900",
  "44814": "41780",
  "44815": "45660",
  "44817": "45780",
  "44818": "45660",
  "44820": "15340",
  "44822": "31900",
  "44824": "41780",
  "44826": "35940",
  "44827": "15340",
  "44830": "45660",
  "44833": "15340",
  "44836": "45660",
  "44837": "35940",
  "44838": "11740",
  "44839": "41780",
  "44840": "11740",
  "44841": "45660",
  "44842": "11740",
  "44843": "31900",
  "44846": "41780",
  "44847": "35940",
  "44851": "35940",
  "44853": "45660",
  "44854": "15340",
  "44855": "35940",
  "44857": "35940",
  "44859": "11740",
  "44864": "11740",
  "44865": "35940",
  "44866": "11740",
  "44867": "45660",
  "44870": "41780",
  "44875": "31900",
  "44878": "31900",
  "44880": "11740",
  "44883": "45660",
  "44887": "15340",
  "44889": "35940",
  "44890": "35940",
  "44902": "31900",
  "44903": "31900",
  "44904": "31900",
  "44905": "31900",
  "44906": "31900",
  "44907": "31900",
  "45001": "17140",
  "45002": "17140",
  "45005": "17140",
  "45011": "17140",
  "45013": "17140",
  "45014": "17140",
  "45015": "17140",
  "45030": "17140",
  "45032": "17140",
  "45033": "17140",
  "45034": "17140",
  "45036": "17140",
  "45039": "17140",
  "45040": "17140",
  "45042": "17140",
  "45044": "17140",
  "45050": "17140",
  "45052": "17140",
  "45053": "17140",
  "45054": "17140",
  "45056": "17140",
  "45062": "17140",
  "45065": "17140",
  "45066": "17140",
  "45067": "17140",
  "45068": "17140",
  "45069": "17140",
  "45101": "17140",
  "45102": "17140",
  "45103": "17140",
  "45106": "17140",
  "45107": "48940",
  "45111": "17140",
  "45113": "48940",
  "45118": "17140",
  "45120": "17140",
  "45121": "17140",
  "45122": "17140",
  "45130": "17140",
  "45140": "17140",
  "45146": "48940",
  "45148": "48940",
  "45150": "17140",
  "45152": "17140",
  "45153": "17140",
  "45154": "17140",
  "45157": "17140",
  "45159": "48940",
  "45160": "17140",
  "45162": "17140",
  "45167": "17140",
  "45168": "17140",
  "45169": "48940",
  "45171": "17140",
  "45174": "17140",
  "45176": "17140",
  "45177": "48940",
  "45202": "17140",
  "45203": "17140",
  "45204": "17140",
  "45205": "17140",
  "45206": "17140",
  "45207": "17140",
  "45208": "17140",
  "45209": "17140",
  "45211": "17140",
  "45212": "17140",
  "45213": "17140",
  "45214": "17140",
  "45215": "17140",
  "45216": "17140",
  "45217": "17140",
  "45218": "17140",
  "45219": "17140",
  "45220": "17140",
  "45223": "17140",
  "45224": "17140",
  "45225": "17140",
  "45226": "17140",
  "45227": "17140",
  "45229": "17140",
  "45230": "17140",
  "45231": "17140",
  "45232": "17140",
  "45233": "17140",
  "45236": "17140",
  "45237": "17140",
  "45238": "17140",
  "45239": "17140",
  "45240": "17140",
  "45241": "17140",
  "45242": "17140",
  "45243": "17140",
  "45244": "17140",
  "45245": "17140",
  "45246": "17140",
  "45247": "17140",
  "45248": "17140",
  "45249": "17140",
  "45251": "17140",
  "45252": "17140",
  "45255": "17140",
  "45302": "43380",
  "45303": "24820",
  "45304": "24820",
  "45305": "19430",
  "45306": "43380",
  "45307": "19430",
  "45308": "24820",
  "45309": "19430",
  "45310": "16380",
  "45312": "19430",
  "45314": "19430",
  "45315": "19430",
  "45317": "19430",
  "45318": "19430",
  "45322": "19430",
  "45323": "44220",
  "45324": "19430",
  "45325": "19430",
  "45326": "19430",
  "45327": "19430",
  "45328": "24820",
  "45331": "24820",
  "45332": "24820",
  "45333": "43380",
  "45334": "43380",
  "45335": "19430",
  "45337": "19430",
  "45339": "19430",
  "45340": "43380",
  "45341": "44220",
  "45342": "19430",
  "45344": "44220",
  "45345": "19430",
  "45346": "24820",
  "45348": "24820",
  "45349": "44220",
  "45351": "24820",
  "45354": "19430",
  "45356": "19430",
  "45358": "24820",
  "45359": "19430",
  "45360": "43380",
  "45362": "24820",
  "45363": "43380",
  "45365": "43380",
  "45368": "44220",
  "45369": "44220",
  "45370": "19430",
  "45371": "19430",
  "45372": "44220",
  "45373": "19430",
  "45377": "19430",
  "45380": "24820",
  "45383": "19430",
  "45385": "19430",
  "45387": "19430",
  "45388": "24820",
  "45389": "46500",
  "45390": "24820",
  "45402": "19430",
  "45403": "19430",
  "45404": "19430",
  "45405": "19430",
  "45406": "19430",
  "45409": "19430",
  "45410": "19430",
  "45414": "19430",
  "45415": "19430",
  "45416": "19430",
  "45417": "19430",
  "45419": "19430",
  "45420": "19430",
  "45424": "19430",
  "45426": "19430",
  "45429": "19430",
  "45430": "19430",
  "45431": "19430",
  "45432": "19430",
  "45434": "19430",
  "45439": "19430",
  "45440": "19430",
  "45449": "19430",
  "45458": "19430",
  "45459": "19430",
  "45502": "44220",
  "45503": "44220",
  "45504": "44220",
  "45505": "44220",
  "45506": "44220",
  "45601": "17060",
  "45612": "17060",
  "45614": "38580",
  "45619": "26580",
  "45620": "38580",
  "45623": "38580",
  "45628": "17060",
  "45629": "39020",
  "45631": "38580",
  "45638": "26580",
  "45640": "27160",
  "45644": "17060",
  "45645": "26580",
  "45648": "39020",
  "45652": "39020",
  "45653": "39020",
  "45654": "18140",
  "45656": "27160",
  "45657": "39020",
  "45658": "38580",
  "45659": "26580",
  "45662": "39020",
  "45663": "39020",
  "45669": "26580",
  "45671": "39020",
  "45674": "38580",
  "45678": "26580",
  "45680": "26580",
  "45681": "17060",
  "45682": "39020",
  "45684": "39020",
  "45685": "38580",
  "45686": "38580",
  "45688": "26580",
  "45692": "27160",
  "45694": "39020",
  "45696": "26580",
  "45701": "11900",
  "45710": "11900",
  "45711": "11900",
  "45714": "31930",
  "45716": "11900",
  "45719": "11900",
  "45723": "11900",
  "45724": "31930",
  "45729": "31930",
  "45732": "11900",
  "45735": "11900",
  "45740": "11900",
  "45742": "31930",
  "45744": "31930",
  "45746": "31930",
  "45750": "31930",
  "45761": "11900",
  "45764": "11900",
  "45766": "11900",
  "45767": "31930",
  "45768": "31930",
  "45778": "11900",
  "45780": "11900",
  "45782": "11900",
  "45784": "31930",
  "45786": "31930",
  "45788": "31930",
  "45801": "30620",
  "45804": "30620",
  "45805": "30620",
  "45806": "30620",
  "45807": "30620",
  "45808": "30620",
  "45809": "30620",
  "45814": "22300",
  "45817": "30620",
  "45819": "47540",
  "45820": "30620",
  "45822": "16380",
  "45828": "16380",
  "45832": "46780",
  "45833": "46780",
  "45840": "22300",
  "45841": "22300",
  "45845": "43380",
  "45846": "16380",
  "45850": "30620",
  "45858": "22300",
  "45860": "16380",
  "45862": "16380",
  "45863": "46780",
  "45865": "47540",
  "45866": "16380",
  "45867": "22300",
  "45868": "22300",
  "45869": "47540",
  "45871": "43380",
  "45872": "45780",
  "45874": "46780",
  "45881": "22300",
  "45882": "16380",
  "45883": "16380",
  "45885": "47540",
  "45886": "46780",
  "45887": "30620",
  "45889": "22300",
  "45890": "22300",
  "45891": "46780",
  "45894": "46780",
  "45895": "47540",
  "45896": "47540",
  "45898": "46780",
  "46001": "26900",
  "46011": "26900",
  "46012": "26900",
  "46013": "26900",
  "46016": "26900",
  "46017": "26900",
  "46030": "26900",
  "46031": "26900",
  "46032": "26900",
  "46033": "26900",
  "46034": "26900",
  "46035": "23140",
  "46036": "26900",
  "46037": "26900",
  "46038": "26900",
  "46039": "23140",
  "46040": "26900",
  "46041": "23140",
  "46044": "26900",
  "46048": "26900",
  "46050": "23140",
  "46051": "26900",
  "46052": "26900",
  "46055": "26900",
  "46056": "26900",
  "46057": "23140",
  "46058": "23140",
  "46060": "26900",
  "46062": "26900",
  "46063": "26900",
  "46064": "26900",
  "46065": "23140",
  "46069": "26900",
  "46070": "26900",
  "46071": "26900",
  "46074": "26900",
  "46075": "26900",
  "46077": "26900",
  "46102": "26900",
  "46103": "26900",
  "46105": "26900",
  "46106": "26900",
  "46107": "26900",
  "46110": "26900",
  "46111": "26900",
  "46112": "26900",
  "46113": "26900",
  "46117": "26900",
  "46118": "26900",
  "46120": "26900",
  "46121": "26900",
  "46122": "26900",
  "46123": "26900",
  "46124": "26900",
  "46126": "26900",
  "46128": "26900",
  "46130": "26900",
  "46131": "26900",
  "46135": "26900",
  "46140": "26900",
  "46142": "26900",
  "46143": "26900",
  "46144": "26900",
  "46147": "26900",
  "46148": "35220",
  "46149": "26900",
  "46151": "26900",
  "46157": "26900",
  "46158": "26900",
  "46160": "26900",
  "46161": "26900",
  "46162": "26900",
  "46163": "26900",
  "46164": "26900",
  "46165": "26900",
  "46166": "26900",
  "46167": "26900",
  "46168": "26900",
  "46171": "26900",
  "46172": "26900",
  "46175": "26900",
  "46176": "26900",
  "46180": "26900",
  "46181": "26900",
  "46182": "26900",
  "46184": "26900",
  "46186": "26900",
  "46201": "26900",
  "46202": "26900",
  "46203": "26900",
  "46204": "26900",
  "46205": "26900",
  "46208": "26900",
  "46214": "26900",
  "46216": "26900",
  "46217": "26900",
  "46218": "26900",
  "46219": "26900",
  "46220": "26900",
  "46221": "26900",
  "46222": "26900",
  "46224": "26900",
  "46225": "26900",
  "46226": "26900",
  "46227": "26900",
  "46228": "26900",
  "46229": "26900",
  "46231": "26900",
  "46234": "26900",
  "46235": "26900",
  "46236": "26900",
  "46237": "26900",
  "46239": "26900",
  "46240": "26900",
  "46241": "26900",
  "46250": "26900",
  "46254": "26900",
  "46256": "26900",
  "46259": "26900",
  "46260": "26900",
  "46268": "26900",
  "46278": "26900",
  "46280": "26900",
  "46301": "16980",
  "46303": "16980",
  "46304": "16980",
  "46307": "16980",
  "46310": "16980",
  "46311": "16980",
  "46312": "16980",
  "46319": "16980",
  "46320": "16980",
  "46321": "16980",
  "46322": "16980",
  "46323": "16980",
  "46324": "16980",
  "46327": "16980",
  "46340": "33140",
  "46341": "16980",
  "46342": "16980",
  "46346": "33140",
  "46347": "16980",
  "46348": "33140",
  "46349": "16980",
  "46350": "33140",
  "46356": "16980",
  "46360": "33140",
  "46365": "33140",
  "46368": "16980",
  "46371": "33140",
  "46373": "16980",
  "46375": "16980",
  "46376": "16980",
  "46377": "16980",
  "46379": "16980",
  "46382": "33140",
  "46383": "16980",
  "46385": "16980",
  "46390": "33140",
  "46391": "33140",
  "46392": "16980",
  "46393": "16980",
  "46394": "16980",
  "46402": "16980",
  "46403": "16980",
  "46404": "16980",
  "46405": "16980",
  "46406": "16980",
  "46407": "16980",
  "46408": "16980",
  "46409": "16980",
  "46410": "16980",
  "46501": "38500",
  "46504": "38500",
  "46506": "38500",
  "46507": "21140",
  "46510": "47700",
  "46511": "38500",
  "46514": "21140",
  "46516": "21140",
  "46517": "21140",
  "46524": "47700",
  "46526": "21140",
  "46528": "21140",
  "46530": "43780",
  "46536": "43780",
  "46537": "38500",
  "46538": "47700",
  "46539": "47700",
  "46540": "21140",
  "46542": "47700",
  "46543": "21140",
  "46544": "43780",
  "46545": "43780",
  "46550": "21140",
  "46552": "43780",
  "46553": "21140",
  "46554": "43780",
  "46555": "47700",
  "46561": "43780",
  "46562": "47700",
  "46563": "38500",
  "46567": "47700",
  "46570": "38500",
  "46573": "43780",
  "46574": "43780",
  "46580": "47700",
  "46582": "47700",
  "46590": "47700",
  "46601": "43780",
  "46613": "43780",
  "46614": "43780",
  "46615": "43780",
  "46616": "43780",
  "46617": "43780",
  "46619": "43780",
  "46628": "43780",
  "46635": "43780",
  "46637": "43780",
  "46701": "28340",
  "46702": "26540",
  "46703": "11420",
  "46705": "12140",
  "46706": "12140",
  "46710": "28340",
  "46711": "19540",
  "46714": "14160",
  "46721": "12140",
  "46723": "23060",
  "46725": "23060",
  "46730": "12140",
  "46731": "14160",
  "46732": "28340",
  "46733": "19540",
  "46737": "11420",
  "46738": "12140",
  "46740": "19540",
  "46741": "23060",
  "46742": "11420",
  "46743": "23060",
  "46745": "23060",
  "46747": "11420",
  "46748": "23060",
  "46750": "26540",
  "46755": "28340",
  "46759": "14160",
  "46760": "28340",
  "46763": "28340",
  "46764": "23060",
  "46765": "23060",
  "46766": "14160",
  "46767": "28340",
  "46770": "14160",
  "46772": "19540",
  "46773": "23060",
  "46774": "23060",
  "46776": "11420",
  "46777": "14160",
  "46779": "11420",
  "46781": "14160",
  "46783": "26540",
  "46784": "28340",
  "46785": "12140",
  "46787": "23060",
  "46788": "23060",
  "46791": "14160",
  "46792": "26540",
  "46793": "12140",
  "46794": "28340",
  "46797": "23060",
  "46798": "23060",
  "46799": "14160",
  "46802": "23060",
  "46803": "23060",
  "46804": "23060",
  "46805": "23060",
  "46806": "23060",
  "46807": "23060",
  "46808": "23060",
  "46809": "23060",
  "46814": "23060",
  "46815": "23060",
  "46816": "23060",
  "46818": "23060",
  "46819": "23060",
  "46825": "23060",
  "46835": "23060",
  "46845": "23060",
  "46901": "29020",
  "46902": "29020",
  "46911": "37940",
  "46913": "29200",
  "46914": "37940",
  "46915": "29200",
  "46917": "29200",
  "46919": "31980",
  "46920": "29200",
  "46923": "29200",
  "46926": "37940",
  "46928": "31980",
  "46929": "29200",
  "46932": "30900",
  "46933": "31980",
  "46936": "29020",
  "46938": "31980",
  "46940": "47340",
  "46941": "47340",
  "46943": "47340",
  "46947": "30900",
  "46950": "30900",
  "46951": "37940",
  "46952": "31980",
  "46953": "31980",
  "46957": "31980",
  "46958": "37940",
  "46959": "37940",
  "46962": "47340",
  "46970": "37940",
  "46974": "47340",
  "46978": "30900",
  "46979": "29020",
  "46982": "47700",
  "46984": "47340",
  "46986": "31980",
  "46987": "31980",
  "46988": "30900",
  "46989": "31980",
  "46990": "47340",
  "46991": "31980",
  "46992": "47340",
  "46994": "30900",
  "47001": "17140",
  "47003": "17140",
  "47010": "17140",
  "47012": "17140",
  "47016": "17140",
  "47018": "17140",
  "47022": "17140",
  "47024": "17140",
  "47025": "17140",
  "47030": "17140",
  "47032": "17140",
  "47036": "17140",
  "47040": "17140",
  "47060": "17140",
  "47102": "42500",
  "47106": "31140",
  "47108": "31140",
  "47110": "31140",
  "47111": "31140",
  "47112": "31140",
  "47114": "31140",
  "47115": "31140",
  "47117": "31140",
  "47119": "31140",
  "47120": "31140",
  "47122": "31140",
  "47124": "31140",
  "47125": "31140",
  "47126": "31140",
  "47129": "31140",
  "47130": "31140",
  "47135": "31140",
  "47136": "31140",
  "47138": "31500",
  "47141": "31140",
  "47142": "31140",
  "47143": "31140",
  "47147": "42500",
  "47150": "31140",
  "47160": "31140",
  "47161": "31140",
  "47162": "31140",
  "47163": "31140",
  "47164": "31140",
  "47165": "31140",
  "47166": "31140",
  "47167": "31140",
  "47170": "42500",
  "47172": "31140",
  "47177": "31140",
  "47201": "18020",
  "47203": "18020",
  "47220": "42980",
  "47223": "35860",
  "47224": "31500",
  "47227": "35860",
  "47229": "42980",
  "47230": "31500",
  "47231": "31500",
  "47232": "18020",
  "47234": "26900",
  "47235": "42980",
  "47240": "24700",
  "47243": "31500",
  "47244": "18020",
  "47246": "18020",
  "47250": "31500",
  "47260": "42980",
  "47264": "42980",
  "47265": "35860",
  "47270": "35860",
  "47272": "26900",
  "47273": "35860",
  "47274": "42980",
  "47280": "18020",
  "47281": "31140",
  "47282": "35860",
  "47283": "24700",
  "47302": "34620",
  "47303": "34620",
  "47304": "34620",
  "47305": "34620",
  "47320": "34620",
  "47325": "17140",
  "47327": "39980",
  "47330": "39980",
  "47331": "18220",
  "47334": "34620",
  "47338": "34620",
  "47339": "39980",
  "47341": "39980",
  "47342": "34620",
  "47345": "39980",
  "47346": "39980",
  "47351": "35220",
  "47352": "35220",
  "47353": "17140",
  "47356": "35220",
  "47357": "39980",
  "47360": "35220",
  "47361": "35220",
  "47362": "35220",
  "47374": "39980",
  "47383": "34620",
  "47384": "35220",
  "47385": "35220",
  "47386": "35220",
  "47387": "35220",
  "47388": "35220",
  "47393": "39980",
  "47396": "34620",
  "47401": "14020",
  "47403": "14020",
  "47404": "14020",
  "47408": "14020",
  "47421": "13260",
  "47427": "14020",
  "47429": "14020",
  "47431": "14020",
  "47433": "14020",
  "47436": "13260",
  "47446": "13260",
  "47448": "26900",
  "47451": "13260",
  "47456": "14020",
  "47460": "14020",
  "47462": "13260",
  "47464": "14020",
  "47468": "14020",
  "47470": "13260",
  "47501": "47780",
  "47512": "47180",
  "47513": "27540",
  "47516": "47180",
  "47519": "47780",
  "47521": "27540",
  "47524": "47180",
  "47527": "27540",
  "47528": "47180",
  "47529": "47780",
  "47532": "27540",
  "47535": "47180",
  "47541": "27540",
  "47542": "27540",
  "47546": "27540",
  "47557": "47180",
  "47558": "47780",
  "47561": "47180",
  "47562": "47780",
  "47564": "27540",
  "47567": "27540",
  "47568": "47780",
  "47575": "27540",
  "47578": "47180",
  "47580": "27540",
  "47585": "27540",
  "47590": "27540",
  "47591": "47180",
  "47596": "47180",
  "47597": "47180",
  "47598": "27540",
  "47601": "21780",
  "47610": "21780",
  "47612": "21780",
  "47613": "21780",
  "47619": "21780",
  "47620": "21780",
  "47630": "21780",
  "47631": "21780",
  "47633": "21780",
  "47637": "21780",
  "47638": "21780",
  "47708": "21780",
  "47710": "21780",
  "47711": "21780",
  "47712": "21780",
  "47713": "21780",
  "47714": "21780",
  "47715": "21780",
  "47720": "21780",
  "47725": "21780",
  "47802": "45460",
  "47803": "45460",
  "47804": "45460",
  "47805": "45460",
  "47807": "45460",
  "47832": "45460",
  "47833": "45460",
  "47834": "45460",
  "47837": "45460",
  "47838": "45460",
  "47840": "45460",
  "47841": "45460",
  "47842": "45460",
  "47846": "45460",
  "47847": "45460",
  "47848": "45460",
  "47849": "45460",
  "47850": "45460",
  "47854": "45460",
  "47855": "45460",
  "47857": "45460",
  "47858": "45460",
  "47859": "45460",
  "47861": "45460",
  "47862": "45460",
  "47866": "45460",
  "47868": "14020",
  "47872": "45460",
  "47874": "45460",
  "47879": "45460",
  "47881": "45460",
  "47882": "45460",
  "47884": "45460",
  "47885": "45460",
  "47901": "29200",
  "47904": "29200",
  "47905": "29200",
  "47906": "29200",
  "47909": "29200",
  "47917": "29200",
  "47920": "29200",
  "47921": "29200",
  "47922": "16980",
  "47928": "45460",
  "47930": "29200",
  "47933": "18820",
  "47940": "18820",
  "47941": "29200",
  "47942": "29200",
  "47943": "16980",
  "47944": "29200",
  "47948": "16980",
  "47951": "16980",
  "47954": "18820",
  "47955": "18820",
  "47963": "16980",
  "47965": "18820",
  "47966": "45460",
  "47967": "18820",
  "47968": "18820",
  "47970": "29200",
  "47971": "29200",
  "47974": "45460",
  "47975": "29200",
  "47977": "16980",
  "47978": "16980",
  "47981": "29200",
  "47989": "18820",
  "47990": "18820",
  "47991": "29200",
  "47992": "29200",
  "47993": "29200",
  "47994": "18820",
  "48001": "19820",
  "48002": "19820",
  "48003": "19820",
  "48005": "19820",
  "48006": "19820",
  "48009": "19820",
  "48014": "19820",
  "48015": "19820",
  "48017": "19820",
  "48021": "19820",
  "48022": "19820",
  "48023": "19820",
  "48025": "19820",
  "48026": "19820",
  "48027": "19820",
  "48028": "19820",
  "48030": "19820",
  "48032": "19820",
  "48033": "19820",
  "48034": "19820",
  "48035": "19820",
  "48036": "19820",
  "48038": "19820",
  "48039": "19820",
  "48040": "19820",
  "48041": "19820",
  "48042": "19820",
  "48043": "19820",
  "48044": "19820",
  "48045": "19820",
  "48047": "19820",
  "48048": "19820",
  "48049": "19820",
  "48050": "19820",
  "48051": "19820",
  "48054": "19820",
  "48059": "19820",
  "48060": "19820",
  "48062": "19820",
  "48063": "19820",
  "48064": "19820",
  "48065": "19820",
  "48066": "19820",
  "48067": "19820",
  "48069": "19820",
  "48070": "19820",
  "48071": "19820",
  "48072": "19820",
  "48073": "19820",
  "48074": "19820",
  "48075": "19820",
  "48076": "19820",
  "48079": "19820",
  "48080": "19820",
  "48081": "19820",
  "48082": "19820",
  "48083": "19820",
  "48084": "19820",
  "48085": "19820",
  "48088": "19820",
  "48089": "19820",
  "48091": "19820",
  "48092": "19820",
  "48093": "19820",
  "48094": "19820",
  "48095": "19820",
  "48096": "19820",
  "48097": "19820",
  "48098": "19820",
  "48101": "19820",
  "48103": "11460",
  "48104": "11460",
  "48105": "11460",
  "48108": "11460",
  "48111": "19820",
  "48114": "19820",
  "48116": "19820",
  "48117": "33780",
  "48118": "11460",
  "48120": "19820",
  "48122": "19820",
  "48124": "19820",
  "48125": "19820",
  "48126": "19820",
  "48127": "19820",
  "48128": "19820",
  "48130": "11460",
  "48131": "33780",
  "48133": "33780",
  "48134": "19820",
  "48135": "19820",
  "48137": "19820",
  "48138": "19820",
  "48139": "19820",
  "48140": "33780",
  "48141": "19820",
  "48144": "33780",
  "48145": "33780",
  "48146": "19820",
  "48150": "19820",
  "48152": "19820",
  "48154": "19820",
  "48157": "33780",
  "48158": "11460",
  "48159": "33780",
  "48160": "11460",
  "48161": "33780",
  "48162": "33780",
  "48164": "19820",
  "48165": "19820",
  "48166": "33780",
  "48167": "19820",
  "48168": "19820",
  "48169": "19820",
  "48170": "19820",
  "48173": "19820",
  "48174": "19820",
  "48176": "11460",
  "48178": "19820",
  "48179": "33780",
  "48180": "19820",
  "48182": "33780",
  "48183": "19820",
  "48184": "19820",
  "48185": "19820",
  "48186": "19820",
  "48187": "19820",
  "48188": "19820",
  "48189": "11460",
  "48190": "11460",
  "48191": "11460",
  "48192": "19820",
  "48193": "19820",
  "48195": "19820",
  "48197": "11460",
  "48198": "11460",
  "48201": "19820",
  "48202": "19820",
  "48203": "19820",
  "48204": "19820",
  "48205": "19820",
  "48206": "19820",
  "48207": "19820",
  "48208": "19820",
  "48209": "19820",
  "48210": "19820",
  "48211": "19820",
  "48212": "19820",
  "48213": "19820",
  "48214": "19820",
  "48215": "19820",
  "48216": "19820",
  "48217": "19820",
  "48218": "19820",
  "48219": "19820",
  "48220": "19820",
  "48221": "19820",
  "48223": "19820",
  "48224": "19820",
  "48225": "19820",
  "48226": "19820",
  "48227": "19820",
  "48228": "19820",
  "48229": "19820",
  "48230": "19820",
  "48234": "19820",
  "48235": "19820",
  "48236": "19820",
  "48237": "19820",
  "48238": "19820",
  "48239": "19820",
  "48240": "19820",
  "48301": "19820",
  "48302": "19820",
  "48304": "19820",
  "48306": "19820",
  "48307": "19820",
  "48309": "19820",
  "48310": "19820",
  "48312": "19820",
  "48313": "19820",
  "48314": "19820",
  "48315": "19820",
  "48316": "19820",
  "48317": "19820",
  "48320": "19820",
  "48322": "19820",
  "48323": "19820",
  "48324": "19820",
  "48326": "19820",
  "48327": "19820",
  "48328": "19820",
  "48329": "19820",
  "48331": "19820",
  "48334": "19820",
  "48335": "19820",
  "48336": "19820",
  "48340": "19820",
  "48341": "19820",
  "48342": "19820",
  "48346": "19820",
  "48348": "19820",
  "48350": "19820",
  "48353": "19820",
  "48356": "19820",
  "48357": "19820",
  "48359": "19820",
  "48360": "19820",
  "48362": "19820",
  "48363": "19820",
  "48367": "19820",
  "48370": "19820",
  "48371": "19820",
  "48374": "19820",
  "48375": "19820",
  "48377": "19820",
  "48380": "19820",
  "48381": "19820",
  "48382": "19820",
  "48383": "19820",
  "48386": "19820",
  "48390": "19820",
  "48393": "19820",
  "48412": "19820",
  "48414": "29620",
  "48415": "40980",
  "48417": "40980",
  "48418": "29620",
  "48420": "22420",
  "48421": "19820",
  "48423": "22420",
  "48428": "19820",
  "48429": "29620",
  "48430": "22420",
  "48433": "22420",
  "48436": "22420",
  "48437": "22420",
  "48438": "22420",
  "48439": "22420",
  "48440": "19820",
  "48442": "19820",
  "48444": "19820",
  "48446": "19820",
  "48449": "22420",
  "48451": "22420",
  "48455": "19820",
  "48457": "22420",
  "48458": "22420",
  "48460": "29620",
  "48461": "19820",
  "48462": "19820",
  "48463": "22420",
  "48464": "19820",
  "48473": "22420",
  "48476": "29620",
  "48503": "22420",
  "48504": "22420",
  "48505": "22420",
  "48506": "22420",
  "48507": "22420",
  "48509": "22420",
  "48519": "22420",
  "48529": "22420",
  "48532": "22420",
  "48601": "40980",
  "48602": "40980",
  "48603": "40980",
  "48604": "40980",
  "48607": "40980",
  "48609": "40980",
  "48611": "13020",
  "48613": "13020",
  "48614": "40980",
  "48615": "33220",
  "48616": "40980",
  "48618": "33220",
  "48623": "40980",
  "48626": "40980",
  "48628": "33220",
  "48631": "13020",
  "48634": "13020",
  "48637": "40980",
  "48638": "40980",
  "48640": "33220",
  "48642": "33220",
  "48649": "40980",
  "48650": "13020",
  "48652": "33220",
  "48655": "40980",
  "48657": "33220",
  "48662": "10940",
  "48706": "13020",
  "48708": "13020",
  "48722": "40980",
  "48724": "40980",
  "48727": "19820",
  "48732": "13020",
  "48734": "40980",
  "48747": "13020",
  "48760": "19820",
  "48801": "10940",
  "48806": "10940",
  "48807": "40980",
  "48808": "29620",
  "48809": "24340",
  "48811": "24340",
  "48813": "29620",
  "48815": "24340",
  "48817": "29620",
  "48818": "24340",
  "48819": "29620",
  "48820": "29620",
  "48821": "29620",
  "48822": "29620",
  "48823": "29620",
  "48827": "29620",
  "48829": "24340",
  "48831": "29620",
  "48832": "10940",
  "48834": "24340",
  "48835": "29620",
  "48836": "19820",
  "48837": "29620",
  "48838": "24340",
  "48840": "29620",
  "48841": "29620",
  "48842": "29620",
  "48843": "19820",
  "48845": "24340",
  "48846": "24340",
  "48847": "10940",
  "48848": "29620",
  "48849": "24340",
  "48850": "24340",
  "48851": "24340",
  "48853": "29620",
  "48854": "29620",
  "48855": "19820",
  "48856": "10940",
  "48857": "29620",
  "48858": "34380",
  "48860": "24340",
  "48861": "29620",
  "48864": "29620",
  "48865": "24340",
  "48866": "29620",
  "48867": "29620",
  "48871": "10940",
  "48872": "29620",
  "48873": "24340",
  "48875": "24340",
  "48876": "29620",
  "48877": "10940",
  "48878": "34380",
  "48879": "29620",
  "48880": "10940",
  "48881": "24340",
  "48883": "34380",
  "48884": "24340",
  "48885": "24340",
  "48886": "24340",
  "48888": "24340",
  "48889": "10940",
  "48890": "29620",
  "48891": "24340",
  "48892": "29620",
  "48893": "34380",
  "48894": "29620",
  "48895": "29620",
  "48906": "29620",
  "48910": "29620",
  "48911": "29620",
  "48912": "29620",
  "48915": "29620",
  "48917": "29620",
  "48933": "29620",
  "49001": "28020",
  "49002": "28020",
  "49004": "28020",
  "49006": "28020",
  "49007": "28020",
  "49008": "28020",
  "49009": "28020",
  "49010": "26090",
  "49011": "12980",
  "49012": "28020",
  "49014": "12980",
  "49015": "12980",
  "49017": "12980",
  "49021": "29620",
  "49022": "35660",
  "49024": "28020",
  "49028": "17740",
  "49029": "12980",
  "49030": "44780",
  "49031": "43780",
  "49032": "44780",
  "49033": "12980",
  "49034": "28020",
  "49036": "17740",
  "49037": "12980",
  "49038": "35660",
  "49040": "44780",
  "49042": "44780",
  "49047": "43780",
  "49048": "28020",
  "49051": "12980",
  "49052": "28020",
  "49053": "28020",
  "49061": "43780",
  "49066": "44780",
  "49067": "43780",
  "49068": "12980",
  "49070": "26090",
  "49072": "44780",
  "49076": "29620",
  "49078": "26090",
  "49080": "26090",
  "49082": "17740",
  "49083": "28020",
  "49085": "35660",
  "49087": "28020",
  "49088": "28020",
  "49089": "17740",
  "49091": "44780",
  "49092": "12980",
  "49093": "44780",
  "49094": "17740",
  "49095": "43780",
  "49096": "29620",
  "49097": "28020",
  "49098": "35660",
  "49099": "44780",
  "49101": "35660",
  "49102": "35660",
  "49103": "35660",
  "49106": "35660",
  "49107": "35660",
  "49111": "35660",
  "49112": "43780",
  "49113": "35660",
  "49115": "35660",
  "49116": "35660",
  "49117": "35660",
  "49120": "35660",
  "49125": "35660",
  "49126": "35660",
  "49127": "35660",
  "49128": "35660",
  "49129": "35660",
  "49130": "43780",
  "49201": "27100",
  "49202": "27100",
  "49203": "27100",
  "49220": "10300",
  "49221": "10300",
  "49224": "12980",
  "49227": "25880",
  "49228": "10300",
  "49229": "10300",
  "49230": "27100",
  "49232": "25880",
  "49233": "10300",
  "49234": "27100",
  "49235": "10300",
  "49236": "11460",
  "49237": "27100",
  "49238": "10300",
  "49240": "27100",
  "49241": "27100",
  "49242": "25880",
  "49245": "12980",
  "49246": "27100",
  "49247": "10300",
  "49248": "10300",
  "49249": "25880",
  "49250": "25880",
  "49251": "29620",
  "49252": "25880",
  "49253": "10300",
  "49254": "27100",
  "49255": "17740",
  "49256": "10300",
  "49259": "27100",
  "49262": "25880",
  "49264": "29620",
  "49265": "10300",
  "49266": "25880",
  "49267": "33780",
  "49268": "10300",
  "49269": "27100",
  "49270": "33780",
  "49271": "25880",
  "49272": "27100",
  "49274": "25880",
  "49276": "10300",
  "49277": "27100",
  "49279": "10300",
  "49282": "25880",
  "49283": "27100",
  "49284": "27100",
  "49285": "29620",
  "49286": "10300",
  "49287": "10300",
  "49288": "25880",
  "49301": "24340",
  "49302": "24340",
  "49303": "34740",
  "49305": "13660",
  "49306": "24340",
  "49307": "13660",
  "49310": "34380",
  "49315": "24340",
  "49316": "24340",
  "49318": "34740",
  "49319": "24340",
  "49320": "13660",
  "49321": "24340",
  "49322": "24340",
  "49323": "26090",
  "49326": "24340",
  "49328": "26090",
  "49329": "24340",
  "49330": "24340",
  "49331": "24340",
  "49332": "13660",
  "49336": "13660",
  "49339": "24340",
  "49340": "13660",
  "49341": "24340",
  "49342": "13660",
  "49343": "24340",
  "49344": "26090",
  "49345": "24340",
  "49346": "13660",
  "49347": "24340",
  "49348": "26090",
  "49401": "24340",
  "49402": "31220",
  "49403": "24340",
  "49404": "24340",
  "49405": "31220",
  "49406": "26090",
  "49408": "26090",
  "49410": "31220",
  "49411": "31220",
  "49415": "34740",
  "49417": "24340",
  "49418": "24340",
  "49419": "26090",
  "49423": "24340",
  "49424": "24340",
  "49425": "34740",
  "49426": "24340",
  "49428": "24340",
  "49431": "31220",
  "49434": "24340",
  "49435": "24340",
  "49437": "34740",
  "49440": "34740",
  "49441": "34740",
  "49442": "34740",
  "49444": "34740",
  "49445": "34740",
  "49448": "24340",
  "49450": "26090",
  "49451": "34740",
  "49453": "26090",
  "49454": "31220",
  "49456": "24340",
  "49457": "34740",
  "49460": "24340",
  "49461": "34740",
  "49464": "24340",
  "49503": "24340",
  "49504": "24340",
  "49505": "24340",
  "49506": "24340",
  "49507": "24340",
  "49508": "24340",
  "49509": "24340",
  "49512": "24340",
  "49519": "24340",
  "49525": "24340",
  "49534": "24340",
  "49544": "24340",
  "49546": "24340",
  "49548": "24340",
  "49601": "15620",
  "49612": "45900",
  "49616": "45900",
  "49617": "45900",
  "49618": "15620",
  "49620": "15620",
  "49621": "45900",
  "49630": "45900",
  "49632": "15620",
  "49633": "45900",
  "49635": "45900",
  "49636": "45900",
  "49637": "45900",
  "49638": "15620",
  "49640": "45900",
  "49643": "45900",
  "49646": "45900",
  "49649": "45900",
  "49650": "45900",
  "49651": "15620",
  "49653": "45900",
  "49654": "45900",
  "49657": "15620",
  "49663": "15620",
  "49664": "45900",
  "49668": "15620",
  "49670": "45900",
  "49674": "45900",
  "49676": "45900",
  "49680": "45900",
  "49682": "45900",
  "49683": "45900",
  "49684": "45900",
  "49685": "45900",
  "49686": "45900",
  "49690": "45900",
  "49696": "45900",
  "49707": "10980",
  "49710": "42300",
  "49715": "42300",
  "49724": "42300",
  "49725": "42300",
  "49726": "42300",
  "49736": "42300",
  "49747": "10980",
  "49752": "42300",
  "49753": "10980",
  "49766": "10980",
  "49768": "42300",
  "49774": "42300",
  "49780": "42300",
  "49783": "42300",
  "49788": "42300",
  "49793": "42300",
  "49801": "27020",
  "49802": "27020",
  "49805": "26340",
  "49807": "21540",
  "49812": "31940",
  "49814": "32100",
  "49818": "21540",
  "49821": "31940",
  "49829": "21540",
  "49831": "27020",
  "49833": "32100",
  "49835": "21540",
  "49837": "21540",
  "49841": "32100",
  "49847": "31940",
  "49848": "31940",
  "49849": "32100",
  "49855": "32100",
  "49858": "31940",
  "49861": "32100",
  "49863": "31940",
  "49866": "32100",
  "49870": "27020",
  "49871": "32100",
  "49873": "31940",
  "49874": "31940",
  "49878": "21540",
  "49879": "32100",
  "49880": "21540",
  "49885": "32100",
  "49886": "31940",
  "49887": "31940",
  "49892": "27020",
  "49893": "31940",
  "49896": "31940",
  "49905": "26340",
  "49913": "26340",
  "49916": "26340",
  "49918": "26340",
  "49922": "26340",
  "49930": "26340",
  "49931": "26340",
  "49934": "26340",
  "49945": "26340",
  "49950": "26340",
  "49958": "26340",
  "49963": "26340",
  "49967": "26340",
  "50001": "19780",
  "50002": "19780",
  "50003": "19780",
  "50005": "32260",
  "50007": "19780",
  "50009": "19780",
  "50010": "11180",
  "50014": "11180",
  "50021": "19780",
  "50023": "19780",
  "50026": "19780",
  "50027": "36820",
  "50028": "19780",
  "50029": "19780",
  "50032": "19780",
  "50035": "19780",
  "50036": "11180",
  "50039": "19780",
  "50040": "11180",
  "50044": "37800",
  "50046": "11180",
  "50047": "19780",
  "50051": "32260",
  "50054": "19780",
  "50055": "11180",
  "50056": "11180",
  "50057": "37800",
  "50058": "19780",
  "50061": "19780",
  "50062": "37800",
  "50063": "19780",
  "50066": "19780",
  "50069": "19780",
  "50070": "19780",
  "50072": "19780",
  "50073": "19780",
  "50105": "11180",
  "50106": "32260",
  "50109": "19780",
  "50111": "19780",
  "50115": "19780",
  "50116": "37800",
  "50118": "19780",
  "50119": "37800",
  "50120": "32260",
  "50124": "11180",
  "50125": "19780",
  "50128": "19780",
  "50131": "19780",
  "50134": "11180",
  "50135": "19780",
  "50138": "37800",
  "50139": "19780",
  "50141": "32260",
  "50142": "32260",
  "50143": "36820",
  "50146": "19780",
  "50148": "32260",
  "50149": "19780",
  "50153": "19780",
  "50154": "11180",
  "50155": "19780",
  "50156": "11180",
  "50158": "32260",
  "50160": "19780",
  "50161": "19780",
  "50162": "32260",
  "50163": "37800",
  "50164": "19780",
  "50166": "19780",
  "50167": "19780",
  "50168": "19780",
  "50169": "19780",
  "50170": "37800",
  "50201": "11180",
  "50207": "36820",
  "50208": "19780",
  "50210": "19780",
  "50211": "19780",
  "50212": "11180",
  "50214": "37800",
  "50216": "19780",
  "50219": "37800",
  "50220": "19780",
  "50222": "19780",
  "50223": "11180",
  "50225": "37800",
  "50226": "19780",
  "50228": "19780",
  "50229": "19780",
  "50232": "19780",
  "50233": "19780",
  "50234": "32260",
  "50236": "11180",
  "50237": "19780",
  "50239": "32260",
  "50240": "19780",
  "50243": "19780",
  "50244": "19780",
  "50247": "32260",
  "50248": "11180",
  "50251": "19780",
  "50252": "19780",
  "50256": "37800",
  "50257": "19780",
  "50261": "19780",
  "50263": "19780",
  "50265": "19780",
  "50266": "19780",
  "50273": "19780",
  "50276": "19780",
  "50277": "19780",
  "50278": "11180",
  "50309": "19780",
  "50310": "19780",
  "50311": "19780",
  "50312": "19780",
  "50313": "19780",
  "50314": "19780",
  "50315": "19780",
  "50316": "19780",
  "50317": "19780",
  "50320": "19780",
  "50321": "19780",
  "50322": "19780",
  "50323": "19780",
  "50324": "19780",
  "50325": "19780",
  "50327": "19780",
  "50401": "32380",
  "50428": "32380",
  "50433": "32380",
  "50434": "32380",
  "50440": "32380",
  "50444": "32380",
  "50446": "32380",
  "50448": "32380",
  "50456": "32380",
  "50457": "32380",
  "50459": "32380",
  "50464": "32380",
  "50469": "32380",
  "50479": "32380",
  "50482": "32380",
  "50501": "22700",
  "50510": "44740",
  "50516": "22700",
  "50518": "22700",
  "50523": "22700",
  "50524": "22700",
  "50530": "22700",
  "50532": "22700",
  "50543": "22700",
  "50544": "22700",
  "50557": "22700",
  "50565": "44740",
  "50566": "22700",
  "50568": "44740",
  "50569": "22700",
  "50576": "44740",
  "50585": "44740",
  "50588": "44740",
  "50594": "22700",
  "50609": "47940",
  "50613": "47940",
  "50621": "47940",
  "50622": "47940",
  "50624": "47940",
  "50626": "47940",
  "50634": "47940",
  "50638": "47940",
  "50642": "47940",
  "50643": "47940",
  "50647": "47940",
  "50648": "47940",
  "50651": "47940",
  "50666": "47940",
  "50667": "47940",
  "50668": "47940",
  "50669": "47940",
  "50673": "47940",
  "50676": "47940",
  "50677": "47940",
  "50680": "47940",
  "50701": "47940",
  "50702": "47940",
  "50703": "47940",
  "50707": "47940",
  "51002": "44740",
  "51004": "43580",
  "51007": "43580",
  "51016": "43580",
  "51019": "43580",
  "51026": "43580",
  "51030": "43580",
  "51033": "44740",
  "51039": "43580",
  "51044": "43580",
  "51047": "43980",
  "51048": "43580",
  "51054": "43580",
  "51055": "43580",
  "51056": "43580",
  "51101": "43580",
  "51103": "43580",
  "51104": "43580",
  "51105": "43580",
  "51106": "43580",
  "51108": "43580",
  "51109": "43580",
  "51301": "43980",
  "51331": "44020",
  "51333": "43980",
  "51338": "43980",
  "51340": "43980",
  "51343": "43980",
  "51347": "44020",
  "51351": "44020",
  "51355": "44020",
  "51357": "43980",
  "51360": "44020",
  "51364": "44020",
  "51366": "43980",
  "51401": "16140",
  "51430": "16140",
  "51436": "16140",
  "51440": "16140",
  "51443": "16140",
  "51444": "16140",
  "51455": "16140",
  "51463": "16140",
  "51501": "36540",
  "51503": "36540",
  "51510": "36540",
  "51521": "36540",
  "51525": "36540",
  "51526": "36540",
  "51529": "36540",
  "51533": "36540",
  "51534": "36540",
  "51540": "36540",
  "51541": "36540",
  "51542": "36540",
  "51545": "36540",
  "51546": "36540",
  "51551": "36540",
  "51553": "36540",
  "51554": "36540",
  "51555": "36540",
  "51556": "36540",
  "51557": "36540",
  "51559": "36540",
  "51560": "36540",
  "51561": "36540",
  "51563": "36540",
  "51564": "36540",
  "51571": "36540",
  "51575": "36540",
  "51576": "36540",
  "51577": "36540",
  "51579": "36540",
  "52001": "20220",
  "52002": "20220",
  "52003": "20220",
  "52032": "20220",
  "52033": "16300",
  "52037": "17540",
  "52039": "20220",
  "52040": "20220",
  "52045": "20220",
  "52046": "20220",
  "52053": "20220",
  "52056": "20220",
  "52068": "20220",
  "52073": "20220",
  "52078": "20220",
  "52201": "26980",
  "52202": "16300",
  "52205": "16300",
  "52206": "16300",
  "52208": "16300",
  "52209": "16300",
  "52212": "16300",
  "52213": "16300",
  "52214": "16300",
  "52218": "16300",
  "52227": "16300",
  "52228": "16300",
  "52229": "16300",
  "52233": "16300",
  "52235": "26980",
  "52240": "26980",
  "52241": "26980",
  "52245": "26980",
  "52246": "26980",
  "52247": "26980",
  "52249": "16300",
  "52253": "16300",
  "52254": "17540",
  "52257": "16300",
  "52302": "16300",
  "52305": "16300",
  "52310": "16300",
  "52313": "16300",
  "52314": "16300",
  "52315": "16300",
  "52317": "26980",
  "52318": "16300",
  "52320": "16300",
  "52321": "16300",
  "52322": "26980",
  "52323": "16300",
  "52324": "16300",
  "52327": "26980",
  "52328": "16300",
  "52332": "16300",
  "52333": "26980",
  "52336": "16300",
  "52338": "26980",
  "52340": "26980",
  "52341": "16300",
  "52345": "16300",
  "52346": "16300",
  "52349": "16300",
  "52351": "16300",
  "52352": "16300",
  "52353": "26980",
  "52354": "16300",
  "52356": "26980",
  "52359": "26980",
  "52362": "16300",
  "52401": "16300",
  "52402": "16300",
  "52403": "16300",
  "52404": "16300",
  "52405": "16300",
  "52411": "16300",
  "52501": "36900",
  "52530": "36900",
  "52533": "21840",
  "52534": "36820",
  "52536": "36900",
  "52540": "21840",
  "52543": "36820",
  "52553": "36820",
  "52554": "36900",
  "52556": "21840",
  "52561": "36820",
  "52567": "21840",
  "52577": "36820",
  "52580": "21840",
  "52586": "36820",
  "52595": "36820",
  "52601": "15460",
  "52619": "22800",
  "52621": "26980",
  "52623": "15460",
  "52624": "22800",
  "52625": "22800",
  "52627": "22800",
  "52630": "22800",
  "52632": "22800",
  "52635": "21840",
  "52637": "15460",
  "52638": "15460",
  "52639": "22800",
  "52640": "15460",
  "52650": "15460",
  "52655": "15460",
  "52656": "22800",
  "52658": "22800",
  "52660": "15460",
  "52720": "34700",
  "52722": "19340",
  "52726": "19340",
  "52727": "17540",
  "52728": "19340",
  "52729": "17540",
  "52730": "17540",
  "52731": "17540",
  "52732": "17540",
  "52742": "17540",
  "52745": "19340",
  "52746": "19340",
  "52748": "19340",
  "52749": "34700",
  "52750": "17540",
  "52751": "17540",
  "52753": "19340",
  "52754": "34700",
  "52755": "26980",
  "52756": "19340",
  "52757": "17540",
  "52758": "19340",
  "52760": "34700",
  "52761": "34700",
  "52765": "19340",
  "52766": "34700",
  "52767": "19340",
  "52768": "19340",
  "52769": "34700",
  "52773": "19340",
  "52776": "34700",
  "52777": "17540",
  "52778": "34700",
  "52802": "19340",
  "52803": "19340",
  "52804": "19340",
  "52806": "19340",
  "52807": "19340",
  "53001": "43100",
  "53002": "33340",
  "53004": "33340",
  "53005": "33340",
  "53006": "13180",
  "53007": "33340",
  "53010": "22540",
  "53011": "43100",
  "53012": "33340",
  "53013": "43100",
  "53014": "11540",
  "53015": "31820",
  "53017": "33340",
  "53018": "33340",
  "53019": "22540",
  "53020": "43100",
  "53021": "33340",
  "53022": "33340",
  "53023": "43100",
  "53024": "33340",
  "53027": "33340",
  "53029": "33340",
  "53032": "13180",
  "53033": "33340",
  "53034": "13180",
  "53035": "13180",
  "53036": "48020",
  "53037": "33340",
  "53038": "48020",
  "53039": "13180",
  "53040": "33340",
  "53042": "31820",
  "53044": "43100",
  "53045": "33340",
  "53046": "33340",
  "53048": "13180",
  "53049": "22540",
  "53050": "13180",
  "53051": "33340",
  "53057": "22540",
  "53058": "33340",
  "53059": "13180",
  "53061": "11540",
  "53063": "31820",
  "53065": "22540",
  "53066": "33340",
  "53069": "33340",
  "53070": "43100",
  "53072": "33340",
  "53073": "43100",
  "53074": "33340",
  "53075": "43100",
  "53076": "33340",
  "53079": "22540",
  "53080": "33340",
  "53081": "43100",
  "53083": "43100",
  "53085": "43100",
  "53086": "33340",
  "53089": "33340",
  "53090": "33340",
  "53091": "13180",
  "53092": "33340",
  "53093": "43100",
  "53094": "48020",
  "53095": "33340",
  "53097": "33340",
  "53098": "13180",
  "53103": "33340",
  "53104": "16980",
  "53105": "39540",
  "53108": "39540",
  "53110": "33340",
  "53114": "48580",
  "53115": "48580",
  "53118": "33340",
  "53119": "33340",
  "53120": "48580",
  "53121": "48580",
  "53122": "33340",
  "53125": "48580",
  "53126": "39540",
  "53128": "48580",
  "53129": "33340",
  "53130": "33340",
  "53132": "33340",
  "53137": "48020",
  "53139": "39540",
  "53140": "16980",
  "53142": "16980",
  "53143": "16980",
  "53144": "16980",
  "53146": "33340",
  "53147": "48580",
  "53149": "33340",
  "53150": "33340",
  "53151": "33340",
  "53153": "33340",
  "53154": "33340",
  "53156": "48020",
  "53158": "16980",
  "53168": "16980",
  "53170": "16980",
  "53172": "33340",
  "53177": "39540",
  "53178": "48020",
  "53179": "16980",
  "53181": "16980",
  "53182": "39540",
  "53183": "33340",
  "53184": "48580",
  "53185": "39540",
  "53186": "33340",
  "53188": "33340",
  "53189": "33340",
  "53190": "48580",
  "53191": "48580",
  "53202": "33340",
  "53203": "33340",
  "53204": "33340",
  "53205": "33340",
  "53206": "33340",
  "53207": "33340",
  "53208": "33340",
  "53209": "33340",
  "53210": "33340",
  "53211": "33340",
  "53212": "33340",
  "53213": "33340",
  "53214": "33340",
  "53215": "33340",
  "53216": "33340",
  "53217": "33340",
  "53218": "33340",
  "53219": "33340",
  "53220": "33340",
  "53221": "33340",
  "53222": "33340",
  "53223": "33340",
  "53224": "33340",
  "53225": "33340",
  "53226": "33340",
  "53227": "33340",
  "53228": "33340",
  "53233": "33340",
  "53235": "33340",
  "53402": "39540",
  "53403": "39540",
  "53404": "39540",
  "53405": "39540",
  "53406": "39540",
  "53502": "31540",
  "53503": "31540",
  "53505": "27500",
  "53508": "31540",
  "53511": "27500",
  "53515": "31540",
  "53517": "31540",
  "53520": "27500",
  "53521": "31540",
  "53523": "31540",
  "53525": "27500",
  "53527": "31540",
  "53528": "31540",
  "53529": "31540",
  "53531": "31540",
  "53532": "31540",
  "53533": "31540",
  "53534": "27500",
  "53536": "27500",
  "53537": "27500",
  "53538": "48020",
  "53543": "31540",
  "53544": "31540",
  "53545": "27500",
  "53546": "27500",
  "53548": "27500",
  "53549": "48020",
  "53551": "48020",
  "53553": "31540",
  "53554": "38420",
  "53555": "31540",
  "53558": "31540",
  "53559": "31540",
  "53560": "31540",
  "53561": "12660",
  "53562": "31540",
  "53563": "27500",
  "53565": "31540",
  "53566": "31540",
  "53569": "38420",
  "53570": "31540",
  "53572": "31540",
  "53574": "31540",
  "53575": "31540",
  "53576": "27500",
  "53577": "12660",
  "53578": "12660",
  "53579": "13180",
  "53583": "31540",
  "53585": "48580",
  "53588": "12660",
  "53589": "31540",
  "53590": "31540",
  "53593": "31540",
  "53594": "13180",
  "53597": "31540",
  "53598": "31540",
  "53703": "31540",
  "53704": "31540",
  "53705": "31540",
  "53711": "31540",
  "53713": "31540",
  "53714": "31540",
  "53715": "31540",
  "53716": "31540",
  "53717": "31540",
  "53718": "31540",
  "53719": "31540",
  "53726": "31540",
  "53801": "38420",
  "53804": "38420",
  "53805": "38420",
  "53806": "38420",
  "53807": "38420",
  "53808": "38420",
  "53809": "38420",
  "53811": "38420",
  "53813": "38420",
  "53818": "38420",
  "53820": "38420",
  "53901": "31540",
  "53911": "31540",
  "53913": "12660",
  "53916": "13180",
  "53919": "22540",
  "53923": "31540",
  "53925": "31540",
  "53932": "31540",
  "53933": "13180",
  "53937": "12660",
  "53941": "12660",
  "53943": "12660",
  "53951": "12660",
  "53954": "31540",
  "53955": "31540",
  "53956": "31540",
  "53959": "12660",
  "53960": "31540",
  "53961": "12660",
  "53963": "22540",
  "53965": "31540",
  "54002": "33460",
  "54003": "33460",
  "54007": "33460",
  "54011": "33460",
  "54013": "33460",
  "54014": "33460",
  "54015": "33460",
  "54016": "33460",
  "54017": "33460",
  "54021": "33460",
  "54022": "33460",
  "54023": "33460",
  "54025": "33460",
  "54027": "33460",
  "54028": "33460",
  "54082": "33460",
  "54101": "24580",
  "54102": "31940",
  "54104": "31940",
  "54106": "11540",
  "54107": "43020",
  "54110": "11540",
  "54111": "43020",
  "54112": "31940",
  "54113": "11540",
  "54114": "31940",
  "54115": "24580",
  "54119": "31940",
  "54120": "27020",
  "54121": "27020",
  "54124": "24580",
  "54125": "31940",
  "54126": "24580",
  "54128": "43020",
  "54130": "11540",
  "54135": "43020",
  "54136": "11540",
  "54137": "43020",
  "54138": "24580",
  "54139": "24580",
  "54140": "11540",
  "54141": "24580",
  "54143": "31940",
  "54149": "24580",
  "54151": "31940",
  "54153": "24580",
  "54154": "24580",
  "54155": "24580",
  "54156": "31940",
  "54157": "31940",
  "54159": "31940",
  "54161": "24580",
  "54162": "24580",
  "54165": "11540",
  "54166": "43020",
  "54170": "11540",
  "54171": "24580",
  "54173": "24580",
  "54174": "24580",
  "54175": "24580",
  "54177": "31940",
  "54180": "24580",
  "54201": "24580",
  "54205": "24580",
  "54208": "24580",
  "54216": "24580",
  "54217": "24580",
  "54220": "31820",
  "54227": "31820",
  "54228": "31820",
  "54229": "24580",
  "54230": "31820",
  "54241": "31820",
  "54245": "31820",
  "54247": "31820",
  "54301": "24580",
  "54302": "24580",
  "54303": "24580",
  "54304": "24580",
  "54311": "24580",
  "54313": "24580",
  "54401": "48140",
  "54403": "48140",
  "54406": "44620",
  "54407": "44620",
  "54408": "48140",
  "54410": "49220",
  "54411": "48140",
  "54412": "49220",
  "54414": "43020",
  "54416": "43020",
  "54421": "48140",
  "54423": "44620",
  "54426": "48140",
  "54427": "48140",
  "54435": "48140",
  "54440": "48140",
  "54441": "49220",
  "54442": "48140",
  "54443": "44620",
  "54448": "48140",
  "54449": "49220",
  "54452": "48140",
  "54455": "48140",
  "54457": "49220",
  "54466": "49220",
  "54467": "44620",
  "54469": "49220",
  "54471": "48140",
  "54473": "44620",
  "54474": "48140",
  "54475": "49220",
  "54476": "48140",
  "54481": "44620",
  "54482": "44620",
  "54484": "48140",
  "54486": "43020",
  "54487": "48140",
  "54488": "48140",
  "54489": "49220",
  "54494": "49220",
  "54495": "49220",
  "54499": "48140",
  "54601": "29100",
  "54603": "29100",
  "54614": "29100",
  "54623": "29100",
  "54636": "29100",
  "54644": "29100",
  "54650": "29100",
  "54669": "29100",
  "54701": "20740",
  "54703": "20740",
  "54720": "20740",
  "54722": "20740",
  "54723": "33460",
  "54724": "20740",
  "54725": "32860",
  "54726": "20740",
  "54727": "20740",
  "54729": "20740",
  "54730": "32860",
  "54732": "20740",
  "54734": "32860",
  "54737": "32860",
  "54738": "20740",
  "54739": "32860",
  "54740": "33460",
  "54741": "20740",
  "54742": "20740",
  "54745": "20740",
  "54748": "20740",
  "54749": "32860",
  "54750": "33460",
  "54751": "32860",
  "54757": "20740",
  "54761": "33460",
  "54763": "32860",
  "54767": "33460",
  "54772": "32860",
  "54820": "20260",
  "54836": "20260",
  "54838": "20260",
  "54849": "20260",
  "54854": "20260",
  "54864": "20260",
  "54873": "20260",
  "54874": "20260",
  "54880": "20260",
  "54901": "36780",
  "54902": "36780",
  "54904": "36780",
  "54909": "44620",
  "54911": "11540",
  "54913": "11540",
  "54914": "11540",
  "54915": "11540",
  "54927": "36780",
  "54931": "11540",
  "54932": "22540",
  "54935": "22540",
  "54937": "22540",
  "54942": "11540",
  "54944": "11540",
  "54947": "36780",
  "54952": "36780",
  "54956": "36780",
  "54963": "36780",
  "54964": "22540",
  "54971": "22540",
  "54974": "22540",
  "54979": "22540",
  "54986": "36780",
  "55001": "33460",
  "55003": "33460",
  "55005": "33460",
  "55008": "33460",
  "55009": "39860",
  "55011": "33460",
  "55012": "33460",
  "55013": "33460",
  "55014": "33460",
  "55016": "33460",
  "55017": "33460",
  "55018": "39860",
  "55019": "22060",
  "55020": "33460",
  "55021": "22060",
  "55024": "33460",
  "55025": "33460",
  "55026": "39860",
  "55027": "39860",
  "55031": "33460",
  "55032": "33460",
  "55033": "33460",
  "55038": "33460",
  "55040": "33460",
  "55041": "40340",
  "55042": "33460",
  "55043": "33460",
  "55044": "33460",
  "55045": "33460",
  "55046": "22060",
  "55047": "33460",
  "55049": "36940",
  "55052": "22060",
  "55053": "22060",
  "55054": "33460",
  "55055": "33460",
  "55056": "33460",
  "55057": "22060",
  "55060": "36940",
  "55065": "33460",
  "55066": "39860",
  "55068": "33460",
  "55069": "33460",
  "55070": "33460",
  "55071": "33460",
  "55073": "33460",
  "55074": "33460",
  "55075": "33460",
  "55076": "33460",
  "55077": "33460",
  "55079": "33460",
  "55080": "33460",
  "55082": "33460",
  "55084": "33460",
  "55085": "33460",
  "55087": "22060",
  "55088": "22060",
  "55089": "39860",
  "55090": "33460",
  "55092": "33460",
  "55101": "33460",
  "55102": "33460",
  "55103": "33460",
  "55104": "33460",
  "55105": "33460",
  "55106": "33460",
  "55107": "33460",
  "55108": "33460",
  "55109": "33460",
  "55110": "33460",
  "55112": "33460",
  "55113": "33460",
  "55114": "33460",
  "55115": "33460",
  "55116": "33460",
  "55117": "33460",
  "55118": "33460",
  "55119": "33460",
  "55120": "33460",
  "55121": "33460",
  "55122": "33460",
  "55123": "33460",
  "55124": "33460",
  "55125": "33460",
  "55126": "33460",
  "55127": "33460",
  "55128": "33460",
  "55129": "33460",
  "55130": "33460",
  "55301": "33460",
  "55302": "33460",
  "55303": "33460",
  "55304": "33460",
  "55305": "33460",
  "55306": "33460",
  "55308": "33460",
  "55309": "33460",
  "55311": "33460",
  "55312": "26780",
  "55313": "33460",
  "55315": "33460",
  "55316": "33460",
  "55317": "33460",
  "55318": "33460",
  "55319": "33460",
  "55320": "33460",
  "55321": "33460",
  "55322": "33460",
  "55327": "33460",
  "55328": "33460",
  "55330": "33460",
  "55331": "33460",
  "55336": "26780",
  "55337": "33460",
  "55339": "33460",
  "55340": "33460",
  "55341": "33460",
  "55343": "33460",
  "55344": "33460",
  "55345": "33460",
  "55346": "33460",
  "55347": "33460",
  "55349": "33460",
  "55350": "26780",
  "55352": "33460",
  "55353": "41060",
  "55354": "26780",
  "55356": "33460",
  "55357": "33460",
  "55358": "33460",
  "55359": "33460",
  "55360": "33460",
  "55362": "33460",
  "55363": "33460",
  "55364": "33460",
  "55367": "33460",
  "55368": "33460",
  "55369": "33460",
  "55370": "26780",
  "55371": "33460",
  "55372": "33460",
  "55373": "33460",
  "55374": "33460",
  "55375": "33460",
  "55376": "33460",
  "55378": "33460",
  "55379": "33460",
  "55381": "26780",
  "55382": "41060",
  "55384": "33460",
  "55385": "26780",
  "55386": "33460",
  "55387": "33460",
  "55388": "33460",
  "55390": "33460",
  "55391": "33460",
  "55395": "26780",
  "55397": "33460",
  "55398": "33460",
  "55401": "33460",
  "55403": "33460",
  "55404": "33460",
  "55405": "33460",
  "55406": "33460",
  "55407": "33460",
  "55408": "33460",
  "55409": "33460",
  "55410": "33460",
  "55411": "33460",
  "55412": "33460",
  "55413": "33460",
  "55414": "33460",
  "55415": "33460",
  "55416": "33460",
  "55417": "33460",
  "55418": "33460",
  "55419": "33460",
  "55420": "33460",
  "55421": "33460",
  "55422": "33460",
  "55423": "33460",
  "55424": "33460",
  "55425": "33460",
  "55426": "33460",
  "55427": "33460",
  "55428": "33460",
  "55429": "33460",
  "55430": "33460",
  "55431": "33460",
  "55432": "33460",
  "55433": "33460",
  "55434": "33460",
  "55435": "33460",
  "55436": "33460",
  "55437": "33460",
  "55438": "33460",
  "55439": "33460",
  "55441": "33460",
  "55442": "33460",
  "55443": "33460",
  "55444": "33460",
  "55445": "33460",
  "55446": "33460",
  "55447": "33460",
  "55448": "33460",
  "55449": "33460",
  "55454": "33460",
  "55601": "20260",
  "55602": "20260",
  "55603": "20260",
  "55607": "20260",
  "55609": "20260",
  "55614": "20260",
  "55616": "20260",
  "55702": "20260",
  "55703": "20260",
  "55705": "20260",
  "55706": "20260",
  "55707": "20260",
  "55708": "20260",
  "55709": "24330",
  "55710": "20260",
  "55711": "20260",
  "55713": "20260",
  "55717": "20260",
  "55718": "20260",
  "55719": "20260",
  "55720": "20260",
  "55721": "24330",
  "55722": "24330",
  "55723": "20260",
  "55724": "20260",
  "55725": "20260",
  "55726": "20260",
  "55731": "20260",
  "55732": "20260",
  "55733": "20260",
  "55734": "20260",
  "55736": "20260",
  "55738": "20260",
  "55741": "20260",
  "55744": "24330",
  "55746": "20260",
  "55748": "24330",
  "55749": "20260",
  "55750": "20260",
  "55751": "20260",
  "55757": "20260",
  "55758": "20260",
  "55763": "20260",
  "55764": "24330",
  "55765": "20260",
  "55767": "20260",
  "55768": "20260",
  "55769": "24330",
  "55771": "20260",
  "55775": "24330",
  "55779": "20260",
  "55781": "20260",
  "55782": "20260",
  "55790": "20260",
  "55792": "20260",
  "55793": "24330",
  "55797": "20260",
  "55798": "20260",
  "55802": "20260",
  "55803": "20260",
  "55804": "20260",
  "55805": "20260",
  "55806": "20260",
  "55807": "20260",
  "55808": "20260",
  "55810": "20260",
  "55811": "20260",
  "55812": "20260",
  "55901": "40340",
  "55902": "40340",
  "55904": "40340",
  "55906": "40340",
  "55909": "12380",
  "55910": "49100",
  "55912": "12380",
  "55917": "36940",
  "55918": "12380",
  "55919": "29100",
  "55920": "40340",
  "55921": "29100",
  "55922": "40340",
  "55923": "40340",
  "55924": "40340",
  "55925": "49100",
  "55926": "12380",
  "55927": "40340",
  "55929": "40340",
  "55932": "40340",
  "55934": "40340",
  "55935": "40340",
  "55936": "12380",
  "55939": "40340",
  "55940": "40340",
  "55941": "29100",
  "55943": "29100",
  "55944": "40340",
  "55945": "40340",
  "55946": "39860",
  "55947": "29100",
  "55949": "40340",
  "55951": "12380",
  "55952": "49100",
  "55953": "12380",
  "55954": "40340",
  "55955": "40340",
  "55956": "40340",
  "55957": "40340",
  "55959": "49100",
  "55960": "40340",
  "55961": "12380",
  "55962": "40340",
  "55963": "39860",
  "55964": "40340",
  "55965": "40340",
  "55967": "12380",
  "55969": "49100",
  "55970": "12380",
  "55971": "40340",
  "55972": "49100",
  "55973": "12380",
  "55974": "29100",
  "55975": "40340",
  "55976": "40340",
  "55979": "49100",
  "55981": "40340",
  "55982": "12380",
  "55983": "39860",
  "55985": "40340",
  "55987": "49100",
  "55990": "40340",
  "55991": "40340",
  "55992": "39860",
  "56001": "31860",
  "56003": "31860",
  "56007": "10660",
  "56009": "10660",
  "56010": "31860",
  "56011": "33460",
  "56016": "10660",
  "56017": "33460",
  "56019": "35580",
  "56021": "31860",
  "56024": "31860",
  "56026": "36940",
  "56028": "33460",
  "56029": "10660",
  "56031": "21860",
  "56032": "10660",
  "56034": "31860",
  "56035": "10660",
  "56036": "10660",
  "56037": "31860",
  "56039": "21860",
  "56041": "35580",
  "56042": "10660",
  "56043": "10660",
  "56045": "10660",
  "56050": "33460",
  "56052": "22060",
  "56054": "31860",
  "56055": "31860",
  "56057": "33460",
  "56058": "33460",
  "56063": "33460",
  "56065": "31860",
  "56069": "33460",
  "56071": "33460",
  "56073": "35580",
  "56074": "31860",
  "56080": "31860",
  "56082": "31860",
  "56085": "35580",
  "56087": "35580",
  "56088": "21860",
  "56090": "31860",
  "56096": "33460",
  "56110": "49380",
  "56115": "32140",
  "56119": "49380",
  "56121": "21860",
  "56127": "21860",
  "56132": "32140",
  "56157": "32140",
  "56160": "21860",
  "56162": "21860",
  "56171": "21860",
  "56175": "32140",
  "56176": "21860",
  "56181": "21860",
  "56187": "49380",
  "56201": "48820",
  "56209": "48820",
  "56229": "32140",
  "56251": "48820",
  "56253": "48820",
  "56258": "32140",
  "56264": "32140",
  "56273": "48820",
  "56279": "48820",
  "56281": "48820",
  "56282": "48820",
  "56288": "48820",
  "56289": "48820",
  "56291": "32140",
  "56301": "41060",
  "56303": "41060",
  "56304": "33460",
  "56307": "41060",
  "56308": "10820",
  "56310": "41060",
  "56312": "41060",
  "56315": "10820",
  "56316": "48820",
  "56319": "10820",
  "56320": "41060",
  "56324": "22260",
  "56326": "10820",
  "56329": "41060",
  "56330": "33460",
  "56331": "41060",
  "56332": "10820",
  "56335": "41060",
  "56340": "41060",
  "56343": "10820",
  "56352": "41060",
  "56353": "33460",
  "56354": "10820",
  "56356": "41060",
  "56357": "41060",
  "56359": "33460",
  "56361": "22260",
  "56362": "41060",
  "56367": "41060",
  "56368": "41060",
  "56369": "41060",
  "56374": "41060",
  "56375": "41060",
  "56376": "41060",
  "56377": "41060",
  "56378": "41060",
  "56379": "41060",
  "56386": "33460",
  "56387": "41060",
  "56401": "14660",
  "56425": "14660",
  "56435": "14660",
  "56441": "14660",
  "56442": "14660",
  "56444": "14660",
  "56447": "14660",
  "56448": "14660",
  "56449": "14660",
  "56450": "14660",
  "56452": "14660",
  "56455": "14660",
  "56465": "14660",
  "56466": "14660",
  "56468": "14660",
  "56472": "14660",
  "56473": "14660",
  "56474": "14660",
  "56484": "14660",
  "56514": "22020",
  "56515": "22260",
  "56517": "24220",
  "56518": "22260",
  "56520": "47420",
  "56523": "24220",
  "56524": "22260",
  "56527": "22260",
  "56528": "22260",
  "56529": "22020",
  "56533": "22260",
  "56534": "22260",
  "56535": "24220",
  "56537": "22260",
  "56540": "24220",
  "56542": "24220",
  "56547": "22020",
  "56549": "22020",
  "56551": "22260",
  "56556": "24220",
  "56560": "22020",
  "56567": "22260",
  "56571": "22260",
  "56572": "22260",
  "56573": "22260",
  "56576": "22260",
  "56579": "47420",
  "56580": "22020",
  "56585": "22020",
  "56586": "22260",
  "56587": "22260",
  "56588": "22260",
  "56592": "24220",
  "56601": "13420",
  "56628": "24330",
  "56630": "13420",
  "56633": "14660",
  "56636": "24330",
  "56639": "24330",
  "56641": "14660",
  "56646": "24220",
  "56647": "13420",
  "56650": "13420",
  "56655": "14660",
  "56657": "24330",
  "56661": "24330",
  "56662": "14660",
  "56667": "13420",
  "56672": "14660",
  "56676": "13420",
  "56678": "13420",
  "56683": "13420",
  "56684": "24220",
  "56685": "13420",
  "56716": "24220",
  "56721": "24220",
  "56722": "24220",
  "56723": "24220",
  "56727": "13420",
  "56736": "24220",
  "57001": "43580",
  "57002": "15100",
  "57003": "43620",
  "57004": "43620",
  "57005": "43620",
  "57006": "15100",
  "57010": "46820",
  "57012": "43620",
  "57013": "43620",
  "57014": "43620",
  "57015": "43620",
  "57018": "43620",
  "57020": "43620",
  "57021": "43620",
  "57022": "43620",
  "57025": "43580",
  "57026": "15100",
  "57030": "43620",
  "57032": "43620",
  "57033": "43620",
  "57035": "43620",
  "57036": "43620",
  "57037": "49460",
  "57038": "43580",
  "57039": "43620",
  "57043": "43620",
  "57047": "43620",
  "57048": "43620",
  "57049": "43580",
  "57053": "43620",
  "57055": "43620",
  "57058": "43620",
  "57064": "43620",
  "57068": "43620",
  "57069": "46820",
  "57070": "43620",
  "57071": "15100",
  "57072": "49460",
  "57073": "46820",
  "57077": "43620",
  "57078": "49460",
  "57103": "43620",
  "57104": "43620",
  "57105": "43620",
  "57106": "43620",
  "57107": "43620",
  "57108": "43620",
  "57110": "43620",
  "57201": "47980",
  "57220": "15100",
  "57221": "47980",
  "57223": "47980",
  "57234": "47980",
  "57235": "47980",
  "57241": "47980",
  "57242": "47980",
  "57243": "47980",
  "57248": "47980",
  "57263": "47980",
  "57272": "47980",
  "57276": "15100",
  "57301": "33580",
  "57312": "26700",
  "57319": "43620",
  "57334": "33580",
  "57350": "26700",
  "57363": "33580",
  "57374": "43620",
  "57382": "26700",
  "57401": "10100",
  "57433": "10100",
  "57445": "10100",
  "57451": "10100",
  "57479": "10100",
  "57501": "38180",
  "57522": "38180",
  "57532": "38180",
  "57536": "38180",
  "57626": "39660",
  "57701": "39660",
  "57702": "39660",
  "57703": "39660",
  "57718": "39660",
  "57719": "39660",
  "57732": "43940",
  "57745": "39660",
  "57751": "39660",
  "57754": "43940",
  "57759": "43940",
  "57761": "39660",
  "57769": "39660",
  "57775": "39660",
  "57783": "43940",
  "57785": "39660",
  "57790": "39660",
  "57793": "43940",
  "58005": "22020",
  "58006": "22020",
  "58011": "22020",
  "58012": "22020",
  "58015": "47420",
  "58018": "47420",
  "58021": "22020",
  "58027": "22020",
  "58030": "47420",
  "58036": "22020",
  "58041": "47420",
  "58042": "22020",
  "58047": "22020",
  "58048": "22020",
  "58051": "22020",
  "58052": "22020",
  "58053": "47420",
  "58059": "22020",
  "58061": "47420",
  "58064": "22020",
  "58075": "47420",
  "58077": "47420",
  "58078": "22020",
  "58079": "22020",
  "58081": "47420",
  "58102": "22020",
  "58103": "22020",
  "58104": "22020",
  "58201": "24220",
  "58203": "24220",
  "58228": "24220",
  "58251": "24220",
  "58256": "24220",
  "58267": "24220",
  "58275": "24220",
  "58278": "24220",
  "58401": "27420",
  "58472": "27420",
  "58483": "27420",
  "58494": "13900",
  "58501": "13900",
  "58503": "13900",
  "58504": "13900",
  "58521": "13900",
  "58530": "13900",
  "58532": "13900",
  "58554": "13900",
  "58558": "13900",
  "58563": "13900",
  "58572": "13900",
  "58601": "19860",
  "58630": "19860",
  "58631": "13900",
  "58652": "19860",
  "58655": "19860",
  "58656": "19860",
  "58701": "33500",
  "58703": "33500",
  "58704": "33500",
  "58710": "33500",
  "58718": "33500",
  "58722": "33500",
  "58731": "33500",
  "58736": "33500",
  "58740": "33500",
  "58741": "33500",
  "58744": "33500",
  "58746": "33500",
  "58759": "33500",
  "58761": "33500",
  "58768": "33500",
  "58785": "33500",
  "58788": "33500",
  "58789": "33500",
  "58790": "33500",
  "58792": "33500",
  "58801": "48780",
  "58830": "48780",
  "58843": "48780",
  "58849": "48780",
  "58852": "48780",
  "59001": "13740",
  "59006": "13740",
  "59008": "13740",
  "59014": "13740",
  "59015": "13740",
  "59019": "13740",
  "59028": "13740",
  "59029": "13740",
  "59037": "13740",
  "59041": "13740",
  "59044": "13740",
  "59057": "13740",
  "59061": "13740",
  "59063": "13740",
  "59068": "13740",
  "59070": "13740",
  "59071": "13740",
  "59079": "13740",
  "59088": "13740",
  "59101": "13740",
  "59102": "13740",
  "59105": "13740",
  "59106": "13740",
  "59401": "24500",
  "59404": "24500",
  "59405": "24500",
  "59410": "25740",
  "59412": "24500",
  "59414": "24500",
  "59421": "24500",
  "59443": "24500",
  "59463": "24500",
  "59465": "24500",
  "59472": "24500",
  "59477": "24500",
  "59480": "24500",
  "59483": "24500",
  "59485": "24500",
  "59487": "24500",
  "59601": "25740",
  "59602": "25740",
  "59631": "25740",
  "59632": "25740",
  "59633": "25740",
  "59634": "25740",
  "59635": "25740",
  "59638": "25740",
  "59639": "25740",
  "59648": "25740",
  "59701": "15580",
  "59714": "14580",
  "59715": "14580",
  "59718": "14580",
  "59730": "14580",
  "59741": "14580",
  "59750": "15580",
  "59752": "14580",
  "59758": "14580",
  "59759": "25740",
  "59760": "14580",
  "59801": "33540",
  "59802": "33540",
  "59803": "33540",
  "59804": "33540",
  "59808": "33540",
  "59823": "33540",
  "59825": "33540",
  "59826": "33540",
  "59834": "33540",
  "59845": "28060",
  "59846": "33540",
  "59847": "33540",
  "59868": "33540",
  "59901": "28060",
  "59912": "28060",
  "59913": "28060",
  "59916": "28060",
  "59919": "28060",
  "59920": "28060",
  "59922": "28060",
  "59925": "28060",
  "59926": "28060",
  "59927": "28060",
  "59928": "28060",
  "59932": "28060",
  "59936": "28060",
  "59937": "28060",
  "60002": "16980",
  "60004": "16980",
  "60005": "16980",
  "60007": "16980",
  "60008": "16980",
  "60010": "16980",
  "60012": "16980",
  "60013": "16980",
  "60014": "16980",
  "60015": "16980",
  "60016": "16980",
  "60018": "16980",
  "60020": "16980",
  "60021": "16980",
  "60022": "16980",
  "60025": "16980",
  "60026": "16980",
  "60029": "16980",
  "60030": "16980",
  "60031": "16980",
  "60033": "16980",
  "60034": "16980",
  "60035": "16980",
  "60040": "16980",
  "60041": "16980",
  "60042": "16980",
  "60043": "16980",
  "60044": "16980",
  "60045": "16980",
  "60046": "16980",
  "60047": "16980",
  "60048": "16980",
  "60050": "16980",
  "60051": "16980",
  "60053": "16980",
  "60056": "16980",
  "60060": "16980",
  "60061": "16980",
  "60062": "16980",
  "60064": "16980",
  "60067": "16980",
  "60068": "16980",
  "60069": "16980",
  "60070": "16980",
  "60071": "16980",
  "60072": "16980",
  "60073": "16980",
  "60074": "16980",
  "60076": "16980",
  "60077": "16980",
  "60081": "16980",
  "60083": "16980",
  "60084": "16980",
  "60085": "16980",
  "60087": "16980",
  "60089": "16980",
  "60090": "16980",
  "60091": "16980",
  "60093": "16980",
  "60096": "16980",
  "60097": "16980",
  "60098": "16980",
  "60099": "16980",
  "60101": "16980",
  "60102": "16980",
  "60103": "16980",
  "60104": "16980",
  "60106": "16980",
  "60107": "16980",
  "60108": "16980",
  "60109": "16980",
  "60110": "16980",
  "60111": "16980",
  "60112": "16980",
  "60113": "40300",
  "60115": "16980",
  "60118": "16980",
  "60119": "16980",
  "60120": "16980",
  "60123": "16980",
  "60124": "16980",
  "60126": "16980",
  "60130": "16980",
  "60131": "16980",
  "60133": "16980",
  "60134": "16980",
  "60135": "16980",
  "60136": "16980",
  "60137": "16980",
  "60139": "16980",
  "60140": "16980",
  "60142": "16980",
  "60143": "16980",
  "60145": "16980",
  "60146": "16980",
  "60148": "16980",
  "60150": "16980",
  "60151": "16980",
  "60152": "16980",
  "60153": "16980",
  "60154": "16980",
  "60155": "16980",
  "60156": "16980",
  "60157": "16980",
  "60160": "16980",
  "60162": "16980",
  "60163": "16980",
  "60164": "16980",
  "60165": "16980",
  "60169": "16980",
  "60171": "16980",
  "60172": "16980",
  "60173": "16980",
  "60174": "16980",
  "60175": "16980",
  "60176": "16980",
  "60177": "16980",
  "60178": "16980",
  "60180": "16980",
  "60181": "16980",
  "60184": "16980",
  "60185": "16980",
  "60187": "16980",
  "60188": "16980",
  "60189": "16980",
  "60190": "16980",
  "60191": "16980",
  "60192": "16980",
  "60193": "16980",
  "60194": "16980",
  "60195": "16980",
  "60201": "16980",
  "60202": "16980",
  "60203": "16980",
  "60301": "16980",
  "60302": "16980",
  "60304": "16980",
  "60305": "16980",
  "60401": "16980",
  "60402": "16980",
  "60403": "16980",
  "60404": "16980",
  "60406": "16980",
  "60407": "16980",
  "60408": "16980",
  "60409": "16980",
  "60410": "16980",
  "60411": "16980",
  "60415": "16980",
  "60416": "16980",
  "60417": "16980",
  "60418": "16980",
  "60419": "16980",
  "60420": "38700",
  "60421": "16980",
  "60422": "16980",
  "60423": "16980",
  "60424": "16980",
  "60425": "16980",
  "60426": "16980",
  "60428": "16980",
  "60429": "16980",
  "60430": "16980",
  "60431": "16980",
  "60432": "16980",
  "60433": "16980",
  "60435": "16980",
  "60436": "16980",
  "60438": "16980",
  "60439": "16980",
  "60440": "16980",
  "60441": "16980",
  "60442": "16980",
  "60443": "16980",
  "60444": "16980",
  "60445": "16980",
  "60446": "16980",
  "60447": "16980",
  "60448": "16980",
  "60449": "16980",
  "60450": "16980",
  "60451": "16980",
  "60452": "16980",
  "60453": "16980",
  "60455": "16980",
  "60456": "16980",
  "60457": "16980",
  "60458": "16980",
  "60459": "16980",
  "60460": "38700",
  "60461": "16980",
  "60462": "16980",
  "60463": "16980",
  "60464": "16980",
  "60465": "16980",
  "60466": "16980",
  "60467": "16980",
  "60468": "16980",
  "60469": "16980",
  "60470": "36837",
  "60471": "16980",
  "60472": "16980",
  "60473": "16980",
  "60474": "16980",
  "60475": "16980",
  "60476": "16980",
  "60477": "16980",
  "60478": "16980",
  "60479": "16980",
  "60480": "16980",
  "60481": "16980",
  "60482": "16980",
  "60484": "16980",
  "60487": "16980",
  "60490": "16980",
  "60491": "16980",
  "60501": "16980",
  "60502": "16980",
  "60503": "16980",
  "60504": "16980",
  "60505": "16980",
  "60506": "16980",
  "60510": "16980",
  "60511": "16980",
  "60512": "16980",
  "60513": "16980",
  "60514": "16980",
  "60515": "16980",
  "60516": "16980",
  "60517": "16980",
  "60518": "36837",
  "60520": "16980",
  "60521": "16980",
  "60523": "16980",
  "60525": "16980",
  "60526": "16980",
  "60527": "16980",
  "60530": "19940",
  "60531": "36837",
  "60532": "16980",
  "60534": "16980",
  "60537": "36837",
  "60538": "16980",
  "60540": "16980",
  "60541": "16980",
  "60542": "16980",
  "60543": "16980",
  "60544": "16980",
  "60545": "16980",
  "60546": "16980",
  "60548": "16980",
  "60549": "36837",
  "60550": "16980",
  "60551": "36837",
  "60552": "36837",
  "60553": "19940",
  "60554": "16980",
  "60555": "16980",
  "60556": "16980",
  "60558": "16980",
  "60559": "16980",
  "60560": "16980",
  "60561": "16980",
  "60563": "16980",
  "60564": "16980",
  "60565": "16980",
  "60585": "16980",
  "60586": "16980",
  "60601": "16980",
  "60602": "16980",
  "60603": "16980",
  "60604": "16980",
  "60605": "16980",
  "60606": "16980",
  "60607": "16980",
  "60608": "16980",
  "60609": "16980",
  "60610": "16980",
  "60611": "16980",
  "60612": "16980",
  "60613": "16980",
  "60614": "16980",
  "60615": "16980",
  "60616": "16980",
  "60617": "16980",
  "60618": "16980",
  "60619": "16980",
  "60620": "16980",
  "60621": "16980",
  "60622": "16980",
  "60623": "16980",
  "60624": "16980",
  "60625": "16980",
  "60626": "16980",
  "60628": "16980",
  "60629": "16980",
  "60630": "16980",
  "60631": "16980",
  "60632": "16980",
  "60633": "16980",
  "60634": "16980",
  "60636": "16980",
  "60637": "16980",
  "60638": "16980",
  "60639": "16980",
  "60640": "16980",
  "60641": "16980",
  "60642": "16980",
  "60643": "16980",
  "60644": "16980",
  "60645": "16980",
  "60646": "16980",
  "60647": "16980",
  "60649": "16980",
  "60651": "16980",
  "60652": "16980",
  "60653": "16980",
  "60654": "16980",
  "60655": "16980",
  "60656": "16980",
  "60657": "16980",
  "60659": "16980",
  "60660": "16980",
  "60661": "16980",
  "60706": "16980",
  "60707": "16980",
  "60712": "16980",
  "60714": "16980",
  "60803": "16980",
  "60804": "16980",
  "60805": "16980",
  "60827": "16980",
  "60901": "28100",
  "60910": "28100",
  "60913": "28100",
  "60914": "28100",
  "60915": "28100",
  "60917": "28100",
  "60921": "38700",
  "60929": "38700",
  "60934": "38700",
  "60935": "28100",
  "60940": "28100",
  "60941": "28100",
  "60942": "19180",
  "60950": "28100",
  "60954": "28100",
  "60958": "28100",
  "60960": "19180",
  "60961": "28100",
  "60963": "19180",
  "60964": "28100",
  "61006": "19940",
  "61007": "40300",
  "61008": "40420",
  "61010": "40300",
  "61011": "40420",
  "61012": "40420",
  "61015": "40300",
  "61016": "40420",
  "61019": "23300",
  "61020": "40300",
  "61021": "19940",
  "61024": "40420",
  "61030": "40300",
  "61031": "19940",
  "61032": "23300",
  "61038": "40420",
  "61039": "23300",
  "61042": "19940",
  "61047": "40300",
  "61048": "23300",
  "61049": "40300",
  "61052": "40300",
  "61054": "40300",
  "61060": "23300",
  "61061": "40300",
  "61062": "23300",
  "61063": "40420",
  "61064": "40300",
  "61065": "40420",
  "61068": "40300",
  "61070": "23300",
  "61071": "44580",
  "61072": "40420",
  "61073": "40420",
  "61080": "40420",
  "61081": "44580",
  "61084": "40300",
  "61088": "40420",
  "61089": "23300",
  "61101": "40420",
  "61102": "40420",
  "61103": "40420",
  "61104": "40420",
  "61107": "40420",
  "61108": "40420",
  "61109": "40420",
  "61111": "40420",
  "61114": "40420",
  "61115": "40420",
  "61201": "19340",
  "61230": "44580",
  "61231": "19340",
  "61232": "19340",
  "61234": "19340",
  "61235": "19340",
  "61238": "19340",
  "61239": "19340",
  "61240": "19340",
  "61241": "19340",
  "61242": "19340",
  "61244": "19340",
  "61250": "44580",
  "61251": "44580",
  "61252": "44580",
  "61254": "19340",
  "61256": "19340",
  "61257": "19340",
  "61259": "19340",
  "61260": "19340",
  "61261": "44580",
  "61262": "19340",
  "61263": "19340",
  "61264": "19340",
  "61265": "19340",
  "61270": "44580",
  "61272": "19340",
  "61273": "19340",
  "61275": "19340",
  "61276": "19340",
  "61277": "44580",
  "61279": "19340",
  "61281": "19340",
  "61282": "19340",
  "61283": "44580",
  "61284": "19340",
  "61301": "36837",
  "61310": "19940",
  "61311": "38700",
  "61313": "38700",
  "61318": "19940",
  "61319": "38700",
  "61320": "36837",
  "61325": "36837",
  "61326": "36837",
  "61327": "36837",
  "61329": "36837",
  "61330": "36837",
  "61333": "38700",
  "61334": "36837",
  "61341": "36837",
  "61342": "36837",
  "61348": "36837",
  "61349": "36837",
  "61350": "36837",
  "61353": "19940",
  "61354": "36837",
  "61356": "36837",
  "61360": "36837",
  "61361": "36837",
  "61362": "36837",
  "61364": "36837",
  "61367": "19940",
  "61368": "36837",
  "61369": "37900",
  "61370": "36837",
  "61373": "36837",
  "61375": "37900",
  "61376": "36837",
  "61377": "37900",
  "61378": "19940",
  "61379": "36837",
  "61401": "23660",
  "61410": "23660",
  "61411": "31380",
  "61412": "19340",
  "61413": "19340",
  "61414": "23660",
  "61415": "37900",
  "61416": "31380",
  "61420": "31380",
  "61421": "37900",
  "61422": "31380",
  "61427": "37900",
  "61428": "23660",
  "61434": "19340",
  "61437": "15460",
  "61438": "31380",
  "61440": "31380",
  "61442": "19340",
  "61443": "19340",
  "61448": "23660",
  "61449": "37900",
  "61450": "22800",
  "61451": "37900",
  "61455": "31380",
  "61465": "19340",
  "61466": "19340",
  "61467": "23660",
  "61469": "15460",
  "61470": "31380",
  "61475": "31380",
  "61476": "19340",
  "61483": "37900",
  "61486": "19340",
  "61488": "23660",
  "61489": "23660",
  "61490": "19340",
  "61491": "37900",
  "61501": "37900",
  "61516": "37900",
  "61517": "37900",
  "61520": "37900",
  "61523": "37900",
  "61525": "37900",
  "61526": "37900",
  "61528": "37900",
  "61529": "37900",
  "61530": "37900",
  "61531": "37900",
  "61533": "37900",
  "61534": "37900",
  "61535": "37900",
  "61536": "37900",
  "61537": "37900",
  "61540": "37900",
  "61542": "37900",
  "61544": "37900",
  "61545": "37900",
  "61546": "37900",
  "61547": "37900",
  "61548": "37900",
  "61550": "37900",
  "61552": "37900",
  "61554": "37900",
  "61559": "37900",
  "61560": "36837",
  "61561": "37900",
  "61563": "37900",
  "61564": "37900",
  "61565": "37900",
  "61568": "37900",
  "61569": "37900",
  "61570": "37900",
  "61571": "37900",
  "61572": "23660",
  "61602": "37900",
  "61603": "37900",
  "61604": "37900",
  "61605": "37900",
  "61606": "37900",
  "61607": "37900",
  "61610": "37900",
  "61611": "37900",
  "61614": "37900",
  "61615": "37900",
  "61616": "37900",
  "61701": "14010",
  "61704": "14010",
  "61705": "14010",
  "61721": "37900",
  "61722": "14010",
  "61723": "30660",
  "61724": "14010",
  "61725": "37900",
  "61726": "38700",
  "61728": "14010",
  "61729": "37900",
  "61730": "14010",
  "61732": "14010",
  "61733": "37900",
  "61734": "37900",
  "61736": "14010",
  "61737": "14010",
  "61738": "37900",
  "61739": "38700",
  "61740": "38700",
  "61741": "38700",
  "61742": "37900",
  "61743": "38700",
  "61744": "14010",
  "61745": "14010",
  "61747": "37900",
  "61748": "14010",
  "61752": "14010",
  "61753": "14010",
  "61754": "14010",
  "61755": "37900",
  "61756": "19500",
  "61759": "37900",
  "61760": "37900",
  "61761": "14010",
  "61764": "38700",
  "61769": "38700",
  "61770": "14010",
  "61771": "37900",
  "61772": "14010",
  "61774": "14010",
  "61775": "38700",
  "61776": "14010",
  "61801": "16580",
  "61802": "16580",
  "61810": "19180",
  "61811": "19180",
  "61812": "19180",
  "61813": "16580",
  "61814": "19180",
  "61817": "19180",
  "61818": "16580",
  "61820": "16580",
  "61821": "16580",
  "61822": "16580",
  "61830": "16580",
  "61832": "19180",
  "61833": "19180",
  "61834": "19180",
  "61839": "16580",
  "61840": "16580",
  "61841": "19180",
  "61843": "16580",
  "61844": "19180",
  "61846": "19180",
  "61847": "16580",
  "61848": "19180",
  "61849": "16580",
  "61850": "19180",
  "61853": "16580",
  "61854": "16580",
  "61856": "16580",
  "61858": "19180",
  "61859": "16580",
  "61863": "16580",
  "61864": "16580",
  "61865": "19180",
  "61866": "16580",
  "61870": "19180",
  "61872": "16580",
  "61873": "16580",
  "61874": "16580",
  "61875": "16580",
  "61876": "19180",
  "61877": "16580",
  "61878": "16580",
  "61880": "16580",
  "61883": "19180",
  "61884": "16580",
  "61912": "16660",
  "61920": "16660",
  "61925": "19500",
  "61929": "16580",
  "61931": "16660",
  "61936": "16580",
  "61938": "16660",
  "61943": "16660",
  "62001": "41180",
  "62002": "41180",
  "62006": "41180",
  "62009": "41180",
  "62010": "41180",
  "62012": "41180",
  "62013": "41180",
  "62014": "41180",
  "62018": "41180",
  "62019": "41180",
  "62021": "41180",
  "62022": "41180",
  "62024": "41180",
  "62025": "41180",
  "62028": "41180",
  "62031": "41180",
  "62033": "41180",
  "62034": "41180",
  "62035": "41180",
  "62036": "41180",
  "62037": "41180",
  "62040": "41180",
  "62045": "41180",
  "62046": "41180",
  "62047": "41180",
  "62048": "41180",
  "62052": "41180",
  "62053": "41180",
  "62058": "41180",
  "62060": "41180",
  "62061": "41180",
  "62062": "41180",
  "62063": "41180",
  "62067": "41180",
  "62069": "41180",
  "62074": "41180",
  "62084": "41180",
  "62086": "41180",
  "62087": "41180",
  "62088": "41180",
  "62090": "41180",
  "62093": "41180",
  "62095": "41180",
  "62097": "41180",
  "62201": "41180",
  "62203": "41180",
  "62204": "41180",
  "62205": "41180",
  "62206": "41180",
  "62207": "41180",
  "62208": "41180",
  "62215": "41180",
  "62216": "41180",
  "62218": "41180",
  "62219": "41180",
  "62220": "41180",
  "62221": "41180",
  "62223": "41180",
  "62226": "41180",
  "62230": "41180",
  "62231": "41180",
  "62232": "41180",
  "62234": "41180",
  "62236": "41180",
  "62239": "41180",
  "62240": "41180",
  "62243": "41180",
  "62244": "41180",
  "62245": "41180",
  "62246": "41180",
  "62248": "41180",
  "62249": "41180",
  "62250": "41180",
  "62253": "41180",
  "62254": "41180",
  "62255": "41180",
  "62257": "41180",
  "62258": "41180",
  "62260": "41180",
  "62262": "41180",
  "62264": "41180",
  "62265": "41180",
  "62269": "41180",
  "62275": "41180",
  "62281": "41180",
  "62284": "41180",
  "62285": "41180",
  "62293": "41180",
  "62294": "41180",
  "62295": "41180",
  "62298": "41180",
  "62301": "39500",
  "62305": "39500",
  "62311": "22800",
  "62313": "22800",
  "62316": "22800",
  "62320": "39500",
  "62321": "22800",
  "62324": "39500",
  "62326": "31380",
  "62330": "22800",
  "62334": "22800",
  "62338": "39500",
  "62341": "22800",
  "62347": "39500",
  "62351": "39500",
  "62354": "22800",
  "62355": "41180",
  "62358": "22800",
  "62360": "39500",
  "62367": "31380",
  "62373": "22800",
  "62374": "31380",
  "62376": "39500",
  "62379": "22800",
  "62380": "22800",
  "62401": "20820",
  "62411": "20820",
  "62424": "20820",
  "62428": "16660",
  "62436": "16660",
  "62440": "16660",
  "62443": "20820",
  "62447": "16660",
  "62461": "20820",
  "62462": "16660",
  "62467": "20820",
  "62468": "16660",
  "62469": "16660",
  "62473": "20820",
  "62501": "19500",
  "62513": "19500",
  "62514": "19500",
  "62517": "45380",
  "62520": "44100",
  "62521": "19500",
  "62522": "19500",
  "62526": "19500",
  "62530": "44100",
  "62531": "45380",
  "62535": "19500",
  "62536": "44100",
  "62538": "45380",
  "62539": "44100",
  "62540": "45380",
  "62544": "19500",
  "62545": "44100",
  "62546": "45380",
  "62547": "45380",
  "62548": "30660",
  "62549": "19500",
  "62551": "19500",
  "62554": "19500",
  "62555": "45380",
  "62556": "45380",
  "62557": "45380",
  "62558": "44100",
  "62561": "44100",
  "62563": "44100",
  "62567": "45380",
  "62568": "45380",
  "62570": "45380",
  "62572": "41180",
  "62573": "19500",
  "62601": "27300",
  "62613": "44100",
  "62615": "44100",
  "62625": "44100",
  "62626": "41180",
  "62628": "27300",
  "62629": "44100",
  "62631": "27300",
  "62634": "30660",
  "62635": "30660",
  "62638": "27300",
  "62640": "41180",
  "62642": "44100",
  "62650": "27300",
  "62656": "30660",
  "62661": "44100",
  "62665": "27300",
  "62668": "27300",
  "62670": "44100",
  "62673": "44100",
  "62674": "41180",
  "62675": "44100",
  "62677": "44100",
  "62684": "44100",
  "62685": "41180",
  "62688": "44100",
  "62690": "41180",
  "62692": "27300",
  "62693": "44100",
  "62694": "27300",
  "62695": "27300",
  "62702": "44100",
  "62703": "44100",
  "62704": "44100",
  "62707": "44100",
  "62711": "44100",
  "62712": "44100",
  "62801": "16460",
  "62807": "16460",
  "62849": "16460",
  "62853": "16460",
  "62854": "16460",
  "62864": "34500",
  "62870": "16460",
  "62875": "16460",
  "62881": "16460",
  "62882": "16460",
  "62883": "34500",
  "62892": "16460",
  "62893": "34500",
  "62901": "16060",
  "62902": "16060",
  "62903": "16060",
  "62907": "16060",
  "62908": "37140",
  "62910": "37140",
  "62914": "16020",
  "62918": "16060",
  "62922": "16060",
  "62924": "16060",
  "62927": "16060",
  "62932": "16060",
  "62933": "16060",
  "62939": "16060",
  "62940": "16060",
  "62942": "16060",
  "62943": "16060",
  "62948": "16060",
  "62950": "16060",
  "62951": "16060",
  "62953": "37140",
  "62957": "16020",
  "62958": "16060",
  "62959": "16060",
  "62960": "37140",
  "62966": "16060",
  "62972": "16060",
  "62974": "16060",
  "62975": "16060",
  "62985": "16060",
  "62988": "16020",
  "62990": "16020",
  "62994": "16060",
  "62995": "16060",
  "63005": "41180",
  "63010": "41180",
  "63011": "41180",
  "63012": "41180",
  "63013": "41180",
  "63014": "41180",
  "63015": "41180",
  "63016": "41180",
  "63017": "41180",
  "63019": "41180",
  "63020": "41180",
  "63021": "41180",
  "63023": "41180",
  "63025": "41180",
  "63026": "41180",
  "63028": "41180",
  "63031": "41180",
  "63033": "41180",
  "63034": "41180",
  "63036": "22100",
  "63037": "41180",
  "63038": "41180",
  "63039": "41180",
  "63040": "41180",
  "63041": "41180",
  "63042": "41180",
  "63043": "41180",
  "63044": "41180",
  "63048": "41180",
  "63049": "41180",
  "63050": "41180",
  "63051": "41180",
  "63052": "41180",
  "63055": "41180",
  "63056": "41180",
  "63060": "41180",
  "63061": "41180",
  "63068": "41180",
  "63069": "41180",
  "63070": "41180",
  "63072": "41180",
  "63073": "41180",
  "63074": "41180",
  "63077": "41180",
  "63080": "41180",
  "63084": "41180",
  "63087": "22100",
  "63088": "41180",
  "63089": "41180",
  "63090": "41180",
  "63101": "41180",
  "63102": "41180",
  "63103": "41180",
  "63104": "41180",
  "63105": "41180",
  "63106": "41180",
  "63107": "41180",
  "63108": "41180",
  "63109": "41180",
  "63110": "41180",
  "63111": "41180",
  "63112": "41180",
  "63113": "41180",
  "63114": "41180",
  "63115": "41180",
  "63116": "41180",
  "63117": "41180",
  "63118": "41180",
  "63119": "41180",
  "63120": "41180",
  "63121": "41180",
  "63122": "41180",
  "63123": "41180",
  "63124": "41180",
  "63125": "41180",
  "63126": "41180",
  "63127": "41180",
  "63128": "41180",
  "63129": "41180",
  "63130": "41180",
  "63131": "41180",
  "63132": "41180",
  "63133": "41180",
  "63134": "41180",
  "63135": "41180",
  "63136": "41180",
  "63137": "41180",
  "63138": "41180",
  "63139": "41180",
  "63141": "41180",
  "63143": "41180",
  "63144": "41180",
  "63146": "41180",
  "63147": "41180",
  "63301": "41180",
  "63303": "41180",
  "63304": "41180",
  "63332": "41180",
  "63341": "41180",
  "63343": "41180",
  "63347": "41180",
  "63348": "41180",
  "63349": "41180",
  "63352": "33020",
  "63357": "41180",
  "63362": "41180",
  "63366": "41180",
  "63367": "41180",
  "63368": "41180",
  "63369": "41180",
  "63373": "41180",
  "63376": "41180",
  "63377": "41180",
  "63379": "41180",
  "63380": "41180",
  "63381": "41180",
  "63382": "25300",
  "63383": "41180",
  "63385": "41180",
  "63386": "41180",
  "63388": "27620",
  "63389": "41180",
  "63390": "41180",
  "63401": "25300",
  "63435": "39500",
  "63436": "25300",
  "63440": "39500",
  "63445": "22800",
  "63448": "39500",
  "63454": "25300",
  "63456": "25300",
  "63459": "25300",
  "63461": "25300",
  "63462": "25300",
  "63463": "25300",
  "63471": "25300",
  "63501": "28860",
  "63533": "28860",
  "63544": "28860",
  "63546": "28860",
  "63559": "28860",
  "63601": "22100",
  "63624": "22100",
  "63628": "22100",
  "63637": "22100",
  "63640": "22100",
  "63653": "22100",
  "63701": "16020",
  "63703": "16020",
  "63730": "16020",
  "63736": "43460",
  "63739": "16020",
  "63740": "43460",
  "63747": "16020",
  "63751": "16020",
  "63755": "16020",
  "63764": "16020",
  "63766": "16020",
  "63769": "16020",
  "63770": "16020",
  "63771": "43460",
  "63780": "43460",
  "63785": "16020",
  "63801": "43460",
  "63821": "28380",
  "63829": "28380",
  "63837": "28380",
  "63852": "28380",
  "63855": "28380",
  "63857": "28380",
  "63863": "28380",
  "63876": "28380",
  "63901": "38740",
  "63933": "28380",
  "63935": "38740",
  "63939": "38740",
  "63945": "38740",
  "64001": "28140",
  "64011": "28140",
  "64012": "28140",
  "64014": "28140",
  "64015": "28140",
  "64016": "28140",
  "64017": "28140",
  "64018": "28140",
  "64019": "47660",
  "64020": "28140",
  "64021": "28140",
  "64022": "28140",
  "64024": "28140",
  "64029": "28140",
  "64030": "28140",
  "64034": "28140",
  "64035": "28140",
  "64036": "28140",
  "64037": "28140",
  "64040": "47660",
  "64048": "28140",
  "64050": "28140",
  "64052": "28140",
  "64053": "28140",
  "64054": "28140",
  "64055": "28140",
  "64056": "28140",
  "64057": "28140",
  "64058": "28140",
  "64060": "28140",
  "64061": "47660",
  "64062": "28140",
  "64063": "28140",
  "64064": "28140",
  "64067": "28140",
  "64068": "28140",
  "64070": "28140",
  "64071": "28140",
  "64074": "28140",
  "64075": "28140",
  "64076": "28140",
  "64077": "28140",
  "64078": "28140",
  "64079": "28140",
  "64080": "28140",
  "64081": "28140",
  "64082": "28140",
  "64083": "28140",
  "64084": "28140",
  "64085": "28140",
  "64086": "28140",
  "64088": "28140",
  "64089": "28140",
  "64093": "47660",
  "64096": "28140",
  "64097": "28140",
  "64098": "28140",
  "64105": "28140",
  "64106": "28140",
  "64108": "28140",
  "64109": "28140",
  "64110": "28140",
  "64111": "28140",
  "64112": "28140",
  "64113": "28140",
  "64114": "28140",
  "64116": "28140",
  "64117": "28140",
  "64118": "28140",
  "64119": "28140",
  "64120": "28140",
  "64123": "28140",
  "64124": "28140",
  "64125": "28140",
  "64126": "28140",
  "64127": "28140",
  "64128": "28140",
  "64129": "28140",
  "64130": "28140",
  "64131": "28140",
  "64132": "28140",
  "64133": "28140",
  "64134": "28140",
  "64136": "28140",
  "64137": "28140",
  "64138": "28140",
  "64139": "28140",
  "64145": "28140",
  "64146": "28140",
  "64149": "28140",
  "64150": "28140",
  "64151": "28140",
  "64152": "28140",
  "64153": "28140",
  "64154": "28140",
  "64155": "28140",
  "64156": "28140",
  "64157": "28140",
  "64158": "28140",
  "64161": "28140",
  "64163": "28140",
  "64165": "28140",
  "64166": "28140",
  "64167": "28140",
  "64401": "41140",
  "64421": "41140",
  "64427": "41140",
  "64429": "41140",
  "64430": "41140",
  "64436": "41140",
  "64439": "28140",
  "64440": "41140",
  "64443": "41140",
  "64444": "28140",
  "64448": "41140",
  "64449": "41140",
  "64454": "28140",
  "64457": "32340",
  "64459": "41140",
  "64465": "28140",
  "64468": "32340",
  "64469": "41140",
  "64474": "41140",
  "64475": "32340",
  "64477": "28140",
  "64480": "41140",
  "64483": "41140",
  "64484": "41140",
  "64485": "41140",
  "64490": "41140",
  "64492": "28140",
  "64493": "28140",
  "64494": "41140",
  "64497": "41140",
  "64501": "41140",
  "64503": "41140",
  "64504": "41140",
  "64505": "41140",
  "64506": "41140",
  "64507": "41140",
  "64625": "28140",
  "64637": "28140",
  "64644": "28140",
  "64649": "28140",
  "64650": "28140",
  "64671": "28140",
  "64701": "28140",
  "64720": "28140",
  "64725": "28140",
  "64730": "28140",
  "64733": "47660",
  "64734": "28140",
  "64739": "28140",
  "64742": "28140",
  "64743": "28140",
  "64746": "28140",
  "64747": "28140",
  "64752": "28140",
  "64755": "27900",
  "64761": "47660",
  "64779": "28140",
  "64780": "28140",
  "64801": "27900",
  "64804": "27900",
  "64832": "27900",
  "64834": "27900",
  "64836": "27900",
  "64840": "27900",
  "64842": "27900",
  "64844": "27900",
  "64850": "27900",
  "64855": "27900",
  "64859": "27900",
  "64862": "27900",
  "64865": "27900",
  "64866": "27900",
  "64867": "27900",
  "64870": "27900",
  "65010": "17860",
  "65016": "27620",
  "65018": "27620",
  "65023": "27620",
  "65024": "27620",
  "65025": "27620",
  "65032": "27620",
  "65035": "27620",
  "65039": "17860",
  "65040": "27620",
  "65043": "27620",
  "65048": "27620",
  "65051": "27620",
  "65053": "27620",
  "65054": "27620",
  "65059": "27620",
  "65063": "27620",
  "65067": "27620",
  "65068": "17860",
  "65074": "27620",
  "65076": "27620",
  "65080": "27620",
  "65081": "27620",
  "65085": "27620",
  "65101": "27620",
  "65109": "27620",
  "65201": "17860",
  "65202": "17860",
  "65203": "17860",
  "65231": "27620",
  "65233": "17860",
  "65237": "17860",
  "65239": "33620",
  "65240": "17860",
  "65243": "17860",
  "65244": "33620",
  "65248": "17860",
  "65251": "27620",
  "65254": "17860",
  "65255": "17860",
  "65256": "17860",
  "65257": "33620",
  "65259": "33620",
  "65260": "33620",
  "65262": "27620",
  "65264": "33020",
  "65265": "33020",
  "65270": "33620",
  "65274": "17860",
  "65276": "17860",
  "65279": "17860",
  "65284": "17860",
  "65287": "17860",
  "65301": "42740",
  "65321": "32180",
  "65322": "17860",
  "65330": "32180",
  "65332": "42740",
  "65333": "42740",
  "65334": "42740",
  "65336": "47660",
  "65337": "42740",
  "65340": "32180",
  "65347": "32180",
  "65348": "17860",
  "65349": "32180",
  "65350": "42740",
  "65351": "32180",
  "65401": "40620",
  "65452": "22780",
  "65459": "22780",
  "65462": "40620",
  "65470": "30060",
  "65534": "22780",
  "65536": "30060",
  "65548": "48460",
  "65550": "40620",
  "65559": "40620",
  "65583": "22780",
  "65584": "22780",
  "65590": "44180",
  "65601": "44180",
  "65604": "44180",
  "65610": "44180",
  "65612": "44180",
  "65613": "44180",
  "65614": "14700",
  "65616": "14700",
  "65617": "44180",
  "65619": "44180",
  "65622": "44180",
  "65626": "48460",
  "65627": "14700",
  "65631": "44180",
  "65632": "30060",
  "65640": "44180",
  "65644": "44180",
  "65648": "44180",
  "65649": "44180",
  "65652": "44180",
  "65653": "14700",
  "65663": "44180",
  "65669": "44180",
  "65672": "14700",
  "65674": "44180",
  "65679": "14700",
  "65680": "14700",
  "65685": "44180",
  "65692": "48460",
  "65706": "44180",
  "65710": "44180",
  "65713": "44180",
  "65714": "44180",
  "65721": "44180",
  "65722": "30060",
  "65725": "44180",
  "65733": "14700",
  "65738": "44180",
  "65739": "14700",
  "65740": "14700",
  "65742": "44180",
  "65746": "44180",
  "65753": "44180",
  "65757": "44180",
  "65759": "14700",
  "65764": "44180",
  "65770": "44180",
  "65771": "14700",
  "65775": "48460",
  "65781": "44180",
  "65789": "48460",
  "65790": "48460",
  "65793": "48460",
  "65802": "44180",
  "65803": "44180",
  "65804": "44180",
  "65806": "44180",
  "65807": "44180",
  "65809": "44180",
  "65810": "44180",
  "66002": "11860",
  "66006": "29940",
  "66007": "28140",
  "66008": "41140",
  "66010": "28140",
  "66012": "28140",
  "66013": "28140",
  "66014": "28140",
  "66016": "11860",
  "66018": "28140",
  "66020": "28140",
  "66021": "28140",
  "66023": "11860",
  "66024": "41140",
  "66025": "29940",
  "66026": "28140",
  "66030": "28140",
  "66035": "41140",
  "66040": "28140",
  "66041": "11860",
  "66042": "36840",
  "66043": "28140",
  "66044": "29940",
  "66046": "29940",
  "66047": "29940",
  "66048": "28140",
  "66049": "29940",
  "66050": "29940",
  "66052": "28140",
  "66053": "28140",
  "66054": "28140",
  "66056": "28140",
  "66058": "11860",
  "66060": "45820",
  "66061": "28140",
  "66062": "28140",
  "66064": "28140",
  "66066": "45820",
  "66067": "36840",
  "66070": "45820",
  "66071": "28140",
  "66072": "28140",
  "66073": "45820",
  "66075": "28140",
  "66076": "36840",
  "66078": "36840",
  "66079": "36840",
  "66080": "36840",
  "66083": "28140",
  "66085": "28140",
  "66086": "28140",
  "66087": "41140",
  "66088": "45820",
  "66090": "41140",
  "66092": "36840",
  "66095": "36840",
  "66097": "45820",
  "66101": "28140",
  "66102": "28140",
  "66103": "28140",
  "66104": "28140",
  "66105": "28140",
  "66106": "28140",
  "66109": "28140",
  "66111": "28140",
  "66112": "28140",
  "66202": "28140",
  "66203": "28140",
  "66204": "28140",
  "66205": "28140",
  "66206": "28140",
  "66207": "28140",
  "66208": "28140",
  "66209": "28140",
  "66210": "28140",
  "66211": "28140",
  "66212": "28140",
  "66213": "28140",
  "66214": "28140",
  "66215": "28140",
  "66216": "28140",
  "66217": "28140",
  "66218": "28140",
  "66219": "28140",
  "66220": "28140",
  "66221": "28140",
  "66223": "28140",
  "66224": "28140",
  "66226": "28140",
  "66227": "28140",
  "66401": "45820",
  "66402": "45820",
  "66407": "31740",
  "66409": "45820",
  "66413": "45820",
  "66414": "45820",
  "66416": "45820",
  "66418": "45820",
  "66419": "45820",
  "66422": "31740",
  "66423": "45820",
  "66429": "45820",
  "66431": "45820",
  "66432": "31740",
  "66436": "45820",
  "66440": "45820",
  "66441": "31740",
  "66449": "31740",
  "66451": "45820",
  "66502": "31740",
  "66503": "31740",
  "66507": "45820",
  "66509": "45820",
  "66510": "45820",
  "66512": "45820",
  "66514": "31740",
  "66516": "45820",
  "66517": "31740",
  "66520": "31740",
  "66521": "31740",
  "66523": "45820",
  "66524": "29940",
  "66526": "45820",
  "66528": "45820",
  "66531": "31740",
  "66533": "45820",
  "66535": "31740",
  "66536": "31740",
  "66537": "45820",
  "66539": "45820",
  "66540": "45820",
  "66542": "45820",
  "66543": "45820",
  "66546": "45820",
  "66547": "31740",
  "66549": "31740",
  "66552": "45820",
  "66554": "31740",
  "66603": "45820",
  "66604": "45820",
  "66605": "45820",
  "66606": "45820",
  "66607": "45820",
  "66608": "45820",
  "66609": "45820",
  "66610": "45820",
  "66611": "45820",
  "66612": "45820",
  "66614": "45820",
  "66615": "45820",
  "66616": "45820",
  "66617": "45820",
  "66618": "45820",
  "66619": "45820",
  "66711": "38260",
  "66712": "38260",
  "66724": "38260",
  "66734": "38260",
  "66735": "38260",
  "66743": "38260",
  "66753": "38260",
  "66756": "38260",
  "66762": "38260",
  "66763": "38260",
  "66767": "28140",
  "66780": "38260",
  "66801": "21380",
  "66830": "21380",
  "66833": "21380",
  "66834": "45820",
  "66835": "21380",
  "66840": "48620",
  "66842": "48620",
  "66843": "21380",
  "66845": "21380",
  "66864": "21380",
  "66865": "21380",
  "66868": "21380",
  "66869": "21380",
  "67001": "48620",
  "67002": "48620",
  "67004": "48620",
  "67005": "49060",
  "67008": "49060",
  "67010": "48620",
  "67013": "48620",
  "67016": "48620",
  "67017": "48620",
  "67019": "49060",
  "67020": "48620",
  "67022": "48620",
  "67025": "48620",
  "67026": "48620",
  "67030": "48620",
  "67031": "48620",
  "67037": "48620",
  "67038": "49060",
  "67039": "48620",
  "67042": "48620",
  "67050": "48620",
  "67051": "48620",
  "67052": "48620",
  "67056": "48620",
  "67060": "48620",
  "67062": "48620",
  "67067": "48620",
  "67074": "48620",
  "67101": "48620",
  "67103": "48620",
  "67106": "48620",
  "67107": "32700",
  "67108": "48620",
  "67110": "48620",
  "67114": "48620",
  "67117": "48620",
  "67119": "48620",
  "67120": "48620",
  "67123": "48620",
  "67133": "48620",
  "67135": "48620",
  "67140": "48620",
  "67144": "48620",
  "67146": "49060",
  "67147": "48620",
  "67149": "48620",
  "67151": "48620",
  "67152": "48620",
  "67154": "48620",
  "67156": "49060",
  "67202": "48620",
  "67203": "48620",
  "67204": "48620",
  "67205": "48620",
  "67206": "48620",
  "67207": "48620",
  "67208": "48620",
  "67209": "48620",
  "67210": "48620",
  "67211": "48620",
  "67212": "48620",
  "67213": "48620",
  "67214": "48620",
  "67215": "48620",
  "67216": "48620",
  "67217": "48620",
  "67218": "48620",
  "67219": "48620",
  "67220": "48620",
  "67223": "48620",
  "67226": "48620",
  "67227": "48620",
  "67228": "48620",
  "67230": "48620",
  "67232": "48620",
  "67235": "48620",
  "67301": "17700",
  "67330": "37660",
  "67332": "37660",
  "67333": "17700",
  "67335": "17700",
  "67336": "37660",
  "67337": "17700",
  "67340": "17700",
  "67341": "37660",
  "67342": "37660",
  "67344": "17700",
  "67347": "17700",
  "67351": "17700",
  "67354": "37660",
  "67356": "37660",
  "67357": "37660",
  "67401": "41460",
  "67416": "41460",
  "67422": "41460",
  "67428": "32700",
  "67436": "41460",
  "67442": "41460",
  "67443": "32700",
  "67448": "41460",
  "67456": "32700",
  "67460": "32700",
  "67464": "32700",
  "67467": "41460",
  "67470": "41460",
  "67484": "41460",
  "67491": "32700",
  "67501": "26740",
  "67502": "26740",
  "67505": "26740",
  "67510": "26740",
  "67514": "26740",
  "67522": "26740",
  "67525": "24460",
  "67526": "24460",
  "67530": "24460",
  "67543": "26740",
  "67544": "24460",
  "67546": "32700",
  "67561": "26740",
  "67564": "24460",
  "67566": "26740",
  "67567": "24460",
  "67568": "26740",
  "67570": "26740",
  "67581": "26740",
  "67583": "26740",
  "67601": "25700",
  "67637": "25700",
  "67640": "25700",
  "67671": "25700",
  "67801": "19980",
  "67834": "19980",
  "67838": "23780",
  "67842": "19980",
  "67846": "23780",
  "67851": "23780",
  "67859": "30580",
  "67860": "23780",
  "67876": "19980",
  "67882": "19980",
  "67901": "30580",
  "68002": "36540",
  "68003": "36540",
  "68005": "36540",
  "68007": "36540",
  "68008": "36540",
  "68015": "36540",
  "68016": "36540",
  "68017": "36540",
  "68018": "36540",
  "68022": "36540",
  "68023": "36540",
  "68025": "23340",
  "68028": "36540",
  "68029": "36540",
  "68030": "43580",
  "68031": "23340",
  "68033": "36540",
  "68034": "36540",
  "68037": "36540",
  "68040": "36540",
  "68041": "36540",
  "68044": "23340",
  "68046": "36540",
  "68048": "36540",
  "68050": "36540",
  "68057": "23340",
  "68058": "36540",
  "68059": "36540",
  "68063": "23340",
  "68064": "36540",
  "68065": "36540",
  "68066": "36540",
  "68069": "36540",
  "68070": "36540",
  "68073": "36540",
  "68102": "36540",
  "68104": "36540",
  "68105": "36540",
  "68106": "36540",
  "68107": "36540",
  "68108": "36540",
  "68110": "36540",
  "68111": "36540",
  "68112": "36540",
  "68114": "36540",
  "68116": "36540",
  "68117": "36540",
  "68118": "36540",
  "68122": "36540",
  "68123": "36540",
  "68124": "36540",
  "68127": "36540",
  "68128": "36540",
  "68130": "36540",
  "68131": "36540",
  "68132": "36540",
  "68133": "36540",
  "68134": "36540",
  "68135": "36540",
  "68136": "36540",
  "68137": "36540",
  "68138": "36540",
  "68142": "36540",
  "68144": "36540",
  "68147": "36540",
  "68152": "36540",
  "68154": "36540",
  "68157": "36540",
  "68164": "36540",
  "68301": "13100",
  "68304": "36540",
  "68307": "36540",
  "68310": "13100",
  "68313": "30700",
  "68314": "30700",
  "68317": "30700",
  "68318": "13100",
  "68328": "13100",
  "68330": "30700",
  "68331": "13100",
  "68336": "30700",
  "68339": "30700",
  "68347": "36540",
  "68349": "36540",
  "68357": "13100",
  "68358": "30700",
  "68360": "30700",
  "68364": "30700",
  "68366": "36540",
  "68368": "30700",
  "68372": "30700",
  "68381": "13100",
  "68402": "30700",
  "68404": "30700",
  "68405": "30700",
  "68407": "36540",
  "68409": "36540",
  "68413": "36540",
  "68415": "13100",
  "68419": "30700",
  "68422": "13100",
  "68423": "30700",
  "68428": "30700",
  "68430": "30700",
  "68434": "30700",
  "68439": "30700",
  "68455": "36540",
  "68456": "30700",
  "68461": "30700",
  "68462": "30700",
  "68463": "36540",
  "68466": "13100",
  "68502": "30700",
  "68503": "30700",
  "68504": "30700",
  "68505": "30700",
  "68506": "30700",
  "68507": "30700",
  "68508": "30700",
  "68510": "30700",
  "68512": "30700",
  "68516": "30700",
  "68517": "30700",
  "68520": "30700",
  "68521": "30700",
  "68522": "30700",
  "68523": "30700",
  "68524": "30700",
  "68526": "30700",
  "68527": "30700",
  "68528": "30700",
  "68532": "30700",
  "68601": "18100",
  "68621": "23340",
  "68628": "24260",
  "68631": "18100",
  "68633": "23340",
  "68634": "18100",
  "68642": "18100",
  "68643": "18100",
  "68644": "18100",
  "68647": "18100",
  "68648": "36540",
  "68649": "23340",
  "68653": "18100",
  "68663": "24260",
  "68664": "23340",
  "68701": "35740",
  "68710": "43580",
  "68715": "35740",
  "68728": "43580",
  "68731": "43580",
  "68732": "43580",
  "68733": "43580",
  "68741": "43580",
  "68743": "43580",
  "68747": "35740",
  "68748": "35740",
  "68752": "35740",
  "68757": "43580",
  "68758": "35740",
  "68765": "35740",
  "68767": "35740",
  "68769": "35740",
  "68770": "43580",
  "68776": "43580",
  "68779": "35740",
  "68781": "35740",
  "68784": "43580",
  "68785": "43580",
  "68801": "24260",
  "68803": "24260",
  "68810": "24260",
  "68812": "28260",
  "68816": "24260",
  "68820": "24260",
  "68824": "24260",
  "68826": "24260",
  "68827": "24260",
  "68831": "24260",
  "68832": "24260",
  "68834": "30420",
  "68835": "24260",
  "68836": "28260",
  "68838": "24260",
  "68840": "28260",
  "68845": "28260",
  "68847": "28260",
  "68850": "30420",
  "68858": "28260",
  "68863": "30420",
  "68864": "24260",
  "68866": "28260",
  "68869": "28260",
  "68870": "28260",
  "68872": "24260",
  "68873": "24260",
  "68876": "28260",
  "68878": "30420",
  "68883": "24260",
  "68901": "25580",
  "68924": "28260",
  "68925": "25580",
  "68937": "30420",
  "68941": "25580",
  "68945": "28260",
  "68950": "25580",
  "68955": "25580",
  "68956": "25580",
  "68959": "28260",
  "68973": "25580",
  "69029": "35820",
  "69101": "35820",
  "69123": "35820",
  "69130": "30420",
  "69138": "30420",
  "69143": "35820",
  "69151": "35820",
  "69163": "35820",
  "69165": "35820",
  "69169": "35820",
  "69341": "42420",
  "69345": "42420",
  "69346": "42420",
  "69352": "42420",
  "69356": "42420",
  "69357": "42420",
  "69358": "42420",
  "69361": "42420",
  "70001": "35380",
  "70002": "35380",
  "70003": "35380",
  "70005": "35380",
  "70006": "35380",
  "70030": "35380",
  "70031": "35380",
  "70032": "35380",
  "70036": "35380",
  "70037": "35380",
  "70039": "35380",
  "70040": "35380",
  "70041": "35380",
  "70043": "35380",
  "70047": "35380",
  "70052": "35380",
  "70053": "35380",
  "70056": "35380",
  "70057": "35380",
  "70058": "35380",
  "70062": "35380",
  "70065": "35380",
  "70067": "35380",
  "70068": "35380",
  "70070": "35380",
  "70071": "35380",
  "70072": "35380",
  "70075": "35380",
  "70079": "35380",
  "70080": "35380",
  "70083": "35380",
  "70084": "35380",
  "70085": "35380",
  "70086": "35380",
  "70087": "35380",
  "70090": "35380",
  "70092": "35380",
  "70094": "35380",
  "70112": "35380",
  "70113": "35380",
  "70114": "35380",
  "70115": "35380",
  "70116": "35380",
  "70117": "35380",
  "70118": "35380",
  "70119": "35380",
  "70121": "35380",
  "70122": "35380",
  "70123": "35380",
  "70124": "35380",
  "70125": "35380",
  "70126": "35380",
  "70127": "35380",
  "70128": "35380",
  "70129": "35380",
  "70130": "35380",
  "70131": "35380",
  "70301": "26380",
  "70339": "12940",
  "70342": "34020",
  "70343": "26380",
  "70344": "26380",
  "70345": "26380",
  "70346": "12940",
  "70354": "26380",
  "70355": "26380",
  "70357": "26380",
  "70358": "35380",
  "70359": "26380",
  "70360": "26380",
  "70363": "26380",
  "70364": "26380",
  "70372": "26380",
  "70373": "26380",
  "70374": "26380",
  "70377": "26380",
  "70380": "34020",
  "70390": "12940",
  "70392": "34020",
  "70394": "26380",
  "70395": "26380",
  "70397": "26380",
  "70401": "25220",
  "70403": "25220",
  "70420": "35380",
  "70422": "25220",
  "70426": "14220",
  "70427": "14220",
  "70431": "35380",
  "70433": "35380",
  "70435": "35380",
  "70437": "35380",
  "70438": "14220",
  "70442": "25220",
  "70443": "25220",
  "70444": "25220",
  "70445": "35380",
  "70446": "25220",
  "70447": "35380",
  "70448": "35380",
  "70449": "12940",
  "70450": "14220",
  "70452": "35380",
  "70454": "25220",
  "70455": "25220",
  "70456": "25220",
  "70458": "35380",
  "70460": "35380",
  "70461": "35380",
  "70462": "12940",
  "70466": "25220",
  "70471": "35380",
  "70501": "29180",
  "70503": "29180",
  "70506": "29180",
  "70507": "29180",
  "70508": "29180",
  "70510": "29180",
  "70512": "36660",
  "70515": "29180",
  "70517": "29180",
  "70518": "29180",
  "70520": "29180",
  "70525": "29180",
  "70526": "29180",
  "70528": "29180",
  "70529": "29180",
  "70532": "27660",
  "70533": "29180",
  "70535": "36660",
  "70538": "34020",
  "70542": "29180",
  "70543": "29180",
  "70544": "29180",
  "70546": "27660",
  "70548": "29180",
  "70549": "27660",
  "70555": "29180",
  "70560": "29180",
  "70563": "29180",
  "70570": "36660",
  "70577": "36660",
  "70578": "29180",
  "70581": "27660",
  "70582": "29180",
  "70583": "29180",
  "70584": "36660",
  "70589": "36660",
  "70591": "27660",
  "70592": "29180",
  "70601": "29340",
  "70605": "29340",
  "70607": "29340",
  "70611": "29340",
  "70615": "29340",
  "70630": "29340",
  "70631": "29340",
  "70632": "29340",
  "70633": "29340",
  "70634": "19760",
  "70643": "29340",
  "70645": "29340",
  "70647": "29340",
  "70652": "19760",
  "70653": "19760",
  "70656": "22860",
  "70657": "19760",
  "70661": "29340",
  "70663": "29340",
  "70665": "29340",
  "70668": "29340",
  "70669": "29340",
  "70706": "12940",
  "70710": "12940",
  "70711": "12940",
  "70714": "12940",
  "70719": "12940",
  "70722": "12940",
  "70723": "35380",
  "70725": "12940",
  "70726": "12940",
  "70730": "12940",
  "70733": "12940",
  "70734": "12940",
  "70737": "12940",
  "70739": "12940",
  "70744": "12940",
  "70748": "12940",
  "70749": "12940",
  "70750": "36660",
  "70754": "12940",
  "70760": "12940",
  "70762": "12940",
  "70763": "35380",
  "70764": "12940",
  "70767": "12940",
  "70769": "12940",
  "70770": "12940",
  "70774": "12940",
  "70775": "12940",
  "70776": "12940",
  "70777": "12940",
  "70778": "12940",
  "70783": "12940",
  "70785": "12940",
  "70788": "12940",
  "70791": "12940",
  "70802": "12940",
  "70805": "12940",
  "70806": "12940",
  "70807": "12940",
  "70808": "12940",
  "70809": "12940",
  "70810": "12940",
  "70811": "12940",
  "70812": "12940",
  "70814": "12940",
  "70815": "12940",
  "70816": "12940",
  "70817": "12940",
  "70818": "12940",
  "70819": "12940",
  "70820": "12940",
  "71004": "43340",
  "71006": "43340",
  "71007": "43340",
  "71023": "33380",
  "71033": "43340",
  "71037": "43340",
  "71039": "33380",
  "71043": "43340",
  "71044": "43340",
  "71047": "43340",
  "71049": "43340",
  "71051": "43340",
  "71052": "43340",
  "71055": "33380",
  "71060": "43340",
  "71061": "43340",
  "71064": "43340",
  "71067": "43340",
  "71069": "43340",
  "71070": "35060",
  "71075": "33380",
  "71078": "43340",
  "71082": "43340",
  "71101": "43340",
  "71103": "43340",
  "71104": "43340",
  "71105": "43340",
  "71106": "43340",
  "71107": "43340",
  "71108": "43340",
  "71109": "43340",
  "71110": "43340",
  "71111": "43340",
  "71112": "43340",
  "71115": "43340",
  "71118": "43340",
  "71119": "43340",
  "71129": "43340",
  "71201": "33740",
  "71202": "33740",
  "71203": "33740",
  "71220": "33740",
  "71222": "33740",
  "71223": "33740",
  "71225": "33740",
  "71227": "40820",
  "71229": "33740",
  "71234": "33740",
  "71235": "40820",
  "71238": "33740",
  "71241": "33740",
  "71245": "40820",
  "71256": "33740",
  "71260": "33740",
  "71261": "33740",
  "71270": "40820",
  "71275": "40820",
  "71277": "33740",
  "71280": "33740",
  "71291": "33740",
  "71292": "33740",
  "71301": "10780",
  "71302": "10780",
  "71303": "10780",
  "71325": "10780",
  "71326": "35020",
  "71328": "10780",
  "71334": "35020",
  "71346": "10780",
  "71353": "36660",
  "71356": "36660",
  "71358": "36660",
  "71360": "10780",
  "71373": "35020",
  "71403": "22860",
  "71405": "10780",
  "71409": "10780",
  "71411": "35060",
  "71417": "10780",
  "71423": "10780",
  "71424": "10780",
  "71430": "10780",
  "71433": "10780",
  "71438": "10780",
  "71439": "22860",
  "71446": "22860",
  "71447": "10780",
  "71454": "10780",
  "71457": "35060",
  "71461": "22860",
  "71466": "10780",
  "71467": "10780",
  "71472": "10780",
  "71485": "10780",
  "71601": "38220",
  "71602": "38220",
  "71603": "38220",
  "71652": "38220",
  "71660": "38220",
  "71665": "38220",
  "71667": "38220",
  "71701": "15780",
  "71720": "15780",
  "71730": "20980",
  "71743": "11660",
  "71744": "15780",
  "71753": "31620",
  "71762": "20980",
  "71764": "15780",
  "71801": "26260",
  "71822": "45500",
  "71837": "45500",
  "71854": "45500",
  "71859": "26260",
  "71901": "26300",
  "71909": "30780",
  "71913": "26300",
  "71921": "11660",
  "71923": "11660",
  "71929": "31680",
  "71933": "26300",
  "71949": "26300",
  "71956": "26300",
  "71964": "26300",
  "71968": "26300",
  "72002": "30780",
  "72007": "30780",
  "72010": "42620",
  "72011": "30780",
  "72012": "42620",
  "72015": "30780",
  "72016": "30780",
  "72019": "30780",
  "72022": "30780",
  "72023": "30780",
  "72024": "30780",
  "72025": "30780",
  "72032": "30780",
  "72034": "30780",
  "72039": "30780",
  "72045": "42620",
  "72046": "30780",
  "72058": "30780",
  "72065": "30780",
  "72070": "30780",
  "72076": "30780",
  "72081": "42620",
  "72084": "30780",
  "72086": "30780",
  "72087": "30780",
  "72102": "42620",
  "72103": "30780",
  "72104": "31680",
  "72106": "30780",
  "72111": "30780",
  "72113": "30780",
  "72114": "30780",
  "72116": "30780",
  "72117": "30780",
  "72118": "30780",
  "72120": "30780",
  "72121": "42620",
  "72122": "30780",
  "72125": "30780",
  "72126": "30780",
  "72132": "38220",
  "72135": "30780",
  "72136": "42620",
  "72137": "42620",
  "72142": "30780",
  "72143": "42620",
  "72150": "30780",
  "72167": "30780",
  "72173": "30780",
  "72176": "30780",
  "72201": "30780",
  "72202": "30780",
  "72204": "30780",
  "72205": "30780",
  "72206": "30780",
  "72207": "30780",
  "72209": "30780",
  "72210": "30780",
  "72211": "30780",
  "72212": "30780",
  "72223": "30780",
  "72227": "30780",
  "72301": "32820",
  "72315": "14180",
  "72331": "32820",
  "72333": "25760",
  "72335": "22620",
  "72342": "25760",
  "72348": "22620",
  "72354": "27860",
  "72355": "25760",
  "72364": "32820",
  "72365": "27860",
  "72366": "25760",
  "72372": "22620",
  "72374": "25760",
  "72386": "27860",
  "72390": "25760",
  "72401": "27860",
  "72404": "27860",
  "72411": "27860",
  "72416": "27860",
  "72417": "27860",
  "72419": "27860",
  "72432": "27860",
  "72437": "27860",
  "72442": "14180",
  "72443": "37500",
  "72447": "27860",
  "72450": "37500",
  "72472": "27860",
  "72479": "27860",
  "72482": "12900",
  "72501": "12900",
  "72521": "12900",
  "72529": "12900",
  "72531": "34260",
  "72537": "34260",
  "72542": "12900",
  "72544": "34260",
  "72562": "12900",
  "72568": "12900",
  "72579": "12900",
  "72601": "25460",
  "72633": "25460",
  "72635": "34260",
  "72641": "25460",
  "72642": "34260",
  "72644": "25460",
  "72651": "34260",
  "72653": "34260",
  "72658": "34260",
  "72662": "25460",
  "72685": "25460",
  "72701": "22220",
  "72703": "22220",
  "72704": "22220",
  "72712": "22220",
  "72713": "22220",
  "72714": "22220",
  "72715": "22220",
  "72718": "22220",
  "72719": "22220",
  "72722": "22220",
  "72727": "22220",
  "72730": "22220",
  "72732": "22220",
  "72734": "22220",
  "72736": "22220",
  "72738": "22220",
  "72740": "22220",
  "72744": "22220",
  "72745": "22220",
  "72751": "22220",
  "72753": "22220",
  "72756": "22220",
  "72758": "22220",
  "72761": "22220",
  "72762": "22220",
  "72764": "22220",
  "72768": "22220",
  "72773": "22220",
  "72774": "22220",
  "72801": "40780",
  "72802": "40780",
  "72823": "40780",
  "72833": "40780",
  "72834": "40780",
  "72837": "40780",
  "72842": "40780",
  "72843": "40780",
  "72847": "40780",
  "72858": "40780",
  "72901": "22900",
  "72903": "22900",
  "72904": "22900",
  "72908": "22900",
  "72916": "22900",
  "72921": "22900",
  "72923": "22900",
  "72932": "22900",
  "72933": "22900",
  "72936": "22900",
  "72937": "22900",
  "72940": "22900",
  "72941": "22900",
  "72944": "22900",
  "72946": "22900",
  "72947": "22900",
  "72949": "22900",
  "72952": "22900",
  "72956": "22900",
  "72959": "22220",
  "73002": "36420",
  "73003": "36420",
  "73004": "36420",
  "73007": "36420",
  "73008": "36420",
  "73010": "36420",
  "73011": "36420",
  "73012": "36420",
  "73013": "36420",
  "73014": "36420",
  "73018": "36420",
  "73020": "36420",
  "73025": "36420",
  "73026": "36420",
  "73027": "36420",
  "73028": "36420",
  "73034": "36420",
  "73036": "36420",
  "73044": "36420",
  "73045": "36420",
  "73049": "36420",
  "73051": "36420",
  "73054": "36420",
  "73055": "20340",
  "73059": "36420",
  "73063": "36420",
  "73064": "36420",
  "73065": "36420",
  "73067": "36420",
  "73068": "36420",
  "73069": "36420",
  "73071": "36420",
  "73072": "36420",
  "73073": "44660",
  "73078": "36420",
  "73079": "36420",
  "73080": "36420",
  "73082": "36420",
  "73084": "36420",
  "73089": "36420",
  "73090": "36420",
  "73092": "36420",
  "73093": "36420",
  "73095": "36420",
  "73096": "48220",
  "73099": "36420",
  "73102": "36420",
  "73103": "36420",
  "73104": "36420",
  "73105": "36420",
  "73106": "36420",
  "73107": "36420",
  "73108": "36420",
  "73109": "36420",
  "73110": "36420",
  "73111": "36420",
  "73112": "36420",
  "73114": "36420",
  "73115": "36420",
  "73116": "36420",
  "73117": "36420",
  "73118": "36420",
  "73119": "36420",
  "73120": "36420",
  "73121": "36420",
  "73122": "36420",
  "73127": "36420",
  "73128": "36420",
  "73129": "36420",
  "73130": "36420",
  "73131": "36420",
  "73132": "36420",
  "73134": "36420",
  "73135": "36420",
  "73139": "36420",
  "73141": "36420",
  "73142": "36420",
  "73149": "36420",
  "73150": "36420",
  "73151": "36420",
  "73159": "36420",
  "73160": "36420",
  "73162": "36420",
  "73165": "36420",
  "73169": "36420",
  "73170": "36420",
  "73173": "36420",
  "73179": "36420",
  "73401": "11620",
  "73430": "11620",
  "73434": "20340",
  "73438": "11620",
  "73441": "11620",
  "73442": "20340",
  "73443": "11620",
  "73448": "11620",
  "73449": "20460",
  "73453": "11620",
  "73458": "11620",
  "73459": "11620",
  "73463": "11620",
  "73481": "11620",
  "73491": "20340",
  "73501": "30020",
  "73505": "30020",
  "73507": "30020",
  "73521": "11060",
  "73526": "11060",
  "73527": "30020",
  "73529": "20340",
  "73531": "30020",
  "73532": "11060",
  "73533": "20340",
  "73537": "11060",
  "73538": "30020",
  "73540": "30020",
  "73541": "30020",
  "73543": "30020",
  "73549": "11060",
  "73552": "30020",
  "73557": "30020",
  "73560": "11060",
  "73562": "30020",
  "73567": "30020",
  "73568": "30020",
  "73572": "30020",
  "73601": "48220",
  "73620": "48220",
  "73625": "48220",
  "73639": "48220",
  "73644": "21120",
  "73645": "21120",
  "73662": "21120",
  "73669": "48220",
  "73701": "21420",
  "73703": "21420",
  "73720": "21420",
  "73727": "21420",
  "73730": "21420",
  "73735": "21420",
  "73736": "21420",
  "73738": "21420",
  "73753": "21420",
  "73754": "21420",
  "73762": "36420",
  "73773": "21420",
  "73801": "49260",
  "73832": "49260",
  "73840": "49260",
  "73841": "49260",
  "73843": "49260",
  "73852": "49260",
  "73853": "49260",
  "73857": "49260",
  "73858": "49260",
  "73939": "25100",
  "73942": "25100",
  "73945": "25100",
  "73949": "25100",
  "73951": "25100",
  "74002": "46140",
  "74003": "12780",
  "74006": "12780",
  "74008": "46140",
  "74010": "46140",
  "74011": "46140",
  "74012": "46140",
  "74014": "46140",
  "74015": "46140",
  "74016": "46140",
  "74017": "46140",
  "74019": "46140",
  "74020": "46140",
  "74021": "46140",
  "74022": "12780",
  "74023": "44660",
  "74026": "36420",
  "74028": "46140",
  "74029": "12780",
  "74030": "46140",
  "74032": "44660",
  "74033": "46140",
  "74035": "46140",
  "74036": "46140",
  "74037": "46140",
  "74038": "46140",
  "74039": "46140",
  "74041": "46140",
  "74044": "46140",
  "74045": "46140",
  "74047": "46140",
  "74051": "12780",
  "74052": "46140",
  "74053": "46140",
  "74054": "46140",
  "74055": "46140",
  "74056": "46140",
  "74058": "46140",
  "74059": "44660",
  "74061": "12780",
  "74062": "44660",
  "74063": "46140",
  "74066": "46140",
  "74070": "46140",
  "74073": "46140",
  "74074": "44660",
  "74075": "44660",
  "74079": "36420",
  "74080": "46140",
  "74081": "46140",
  "74085": "44660",
  "74104": "46140",
  "74105": "46140",
  "74106": "46140",
  "74107": "46140",
  "74108": "46140",
  "74110": "46140",
  "74112": "46140",
  "74114": "46140",
  "74115": "46140",
  "74116": "46140",
  "74119": "46140",
  "74120": "46140",
  "74126": "46140",
  "74127": "46140",
  "74128": "46140",
  "74129": "46140",
  "74130": "46140",
  "74131": "46140",
  "74132": "46140",
  "74133": "46140",
  "74134": "46140",
  "74135": "46140",
  "74136": "46140",
  "74137": "46140",
  "74145": "46140",
  "74146": "46140",
  "74339": "33060",
  "74343": "33060",
  "74354": "33060",
  "74358": "33060",
  "74363": "33060",
  "74370": "33060",
  "74401": "34780",
  "74403": "34780",
  "74421": "46140",
  "74422": "34780",
  "74425": "32540",
  "74427": "45140",
  "74428": "34780",
  "74429": "46140",
  "74430": "32540",
  "74434": "34780",
  "74435": "22900",
  "74436": "34780",
  "74437": "46140",
  "74441": "45140",
  "74442": "32540",
  "74445": "46140",
  "74447": "46140",
  "74450": "34780",
  "74451": "45140",
  "74452": "45140",
  "74454": "46140",
  "74455": "34780",
  "74457": "45140",
  "74463": "34780",
  "74464": "45140",
  "74467": "46140",
  "74469": "34780",
  "74470": "34780",
  "74471": "45140",
  "74501": "32540",
  "74546": "32540",
  "74547": "32540",
  "74554": "32540",
  "74560": "32540",
  "74561": "32540",
  "74570": "32540",
  "74601": "38620",
  "74604": "38620",
  "74631": "38620",
  "74632": "38620",
  "74633": "46140",
  "74637": "46140",
  "74640": "21420",
  "74641": "38620",
  "74647": "38620",
  "74650": "46140",
  "74652": "46140",
  "74653": "38620",
  "74701": "20460",
  "74723": "20460",
  "74726": "20460",
  "74729": "20460",
  "74730": "20460",
  "74731": "20460",
  "74733": "20460",
  "74741": "20460",
  "74801": "43060",
  "74804": "43060",
  "74820": "10220",
  "74824": "36420",
  "74825": "10220",
  "74826": "43060",
  "74831": "36420",
  "74832": "36420",
  "74834": "36420",
  "74851": "43060",
  "74852": "43060",
  "74855": "36420",
  "74857": "36420",
  "74864": "36420",
  "74865": "10220",
  "74869": "36420",
  "74871": "10220",
  "74872": "10220",
  "74873": "43060",
  "74875": "36420",
  "74878": "43060",
  "74881": "36420",
  "74936": "22900",
  "74948": "22900",
  "74954": "22900",
  "74955": "22900",
  "74962": "22900",
  "75001": "19100",
  "75002": "19100",
  "75006": "19100",
  "75007": "19100",
  "75009": "19100",
  "75010": "19100",
  "75013": "19100",
  "75019": "19100",
  "75020": "43300",
  "75021": "43300",
  "75022": "19100",
  "75023": "19100",
  "75024": "19100",
  "75025": "19100",
  "75028": "19100",
  "75032": "19100",
  "75033": "19100",
  "75034": "19100",
  "75035": "19100",
  "75036": "19100",
  "75038": "19100",
  "75039": "19100",
  "75040": "19100",
  "75041": "19100",
  "75042": "19100",
  "75043": "19100",
  "75044": "19100",
  "75048": "19100",
  "75050": "19100",
  "75051": "19100",
  "75052": "19100",
  "75054": "19100",
  "75056": "19100",
  "75057": "19100",
  "75058": "43300",
  "75060": "19100",
  "75061": "19100",
  "75062": "19100",
  "75063": "19100",
  "75065": "19100",
  "75067": "19100",
  "75068": "19100",
  "75069": "19100",
  "75070": "19100",
  "75071": "19100",
  "75072": "19100",
  "75074": "19100",
  "75075": "19100",
  "75076": "43300",
  "75077": "19100",
  "75078": "19100",
  "75080": "19100",
  "75081": "19100",
  "75082": "19100",
  "75087": "19100",
  "75088": "19100",
  "75089": "19100",
  "75090": "43300",
  "75092": "43300",
  "75093": "19100",
  "75094": "19100",
  "75098": "19100",
  "75102": "18620",
  "75104": "19100",
  "75105": "18620",
  "75109": "18620",
  "75110": "18620",
  "75114": "19100",
  "75115": "19100",
  "75116": "19100",
  "75119": "19100",
  "75125": "19100",
  "75126": "19100",
  "75134": "19100",
  "75135": "19100",
  "75137": "19100",
  "75141": "19100",
  "75142": "19100",
  "75143": "11980",
  "75144": "18620",
  "75146": "19100",
  "75148": "11980",
  "75149": "19100",
  "75150": "19100",
  "75152": "19100",
  "75153": "18620",
  "75154": "19100",
  "75155": "18620",
  "75156": "11980",
  "75158": "19100",
  "75159": "19100",
  "75160": "19100",
  "75161": "19100",
  "75163": "11980",
  "75165": "19100",
  "75166": "19100",
  "75167": "19100",
  "75172": "19100",
  "75173": "19100",
  "75180": "19100",
  "75181": "19100",
  "75182": "19100",
  "75189": "19100",
  "75201": "19100",
  "75202": "19100",
  "75203": "19100",
  "75204": "19100",
  "75205": "19100",
  "75206": "19100",
  "75208": "19100",
  "75209": "19100",
  "75210": "19100",
  "75211": "19100",
  "75212": "19100",
  "75214": "19100",
  "75215": "19100",
  "75216": "19100",
  "75217": "19100",
  "75218": "19100",
  "75219": "19100",
  "75220": "19100",
  "75223": "19100",
  "75224": "19100",
  "75225": "19100",
  "75226": "19100",
  "75227": "19100",
  "75228": "19100",
  "75229": "19100",
  "75230": "19100",
  "75231": "19100",
  "75232": "19100",
  "75233": "19100",
  "75234": "19100",
  "75235": "19100",
  "75236": "19100",
  "75237": "19100",
  "75238": "19100",
  "75240": "19100",
  "75241": "19100",
  "75243": "19100",
  "75244": "19100",
  "75246": "19100",
  "75248": "19100",
  "75249": "19100",
  "75252": "19100",
  "75253": "19100",
  "75254": "19100",
  "75287": "19100",
  "75401": "19100",
  "75402": "19100",
  "75407": "19100",
  "75409": "19100",
  "75411": "37580",
  "75413": "14300",
  "75414": "43300",
  "75416": "37580",
  "75418": "14300",
  "75420": "44860",
  "75421": "37580",
  "75422": "19100",
  "75423": "19100",
  "75424": "19100",
  "75428": "19100",
  "75431": "44860",
  "75433": "44860",
  "75435": "37580",
  "75437": "44860",
  "75438": "14300",
  "75439": "14300",
  "75442": "19100",
  "75446": "14300",
  "75447": "14300",
  "75449": "19100",
  "75451": "34420",
  "75452": "14300",
  "75453": "19100",
  "75454": "19100",
  "75455": "34420",
  "75459": "43300",
  "75460": "37580",
  "75462": "37580",
  "75468": "37580",
  "75471": "44860",
  "75473": "37580",
  "75474": "19100",
  "75475": "14300",
  "75476": "14300",
  "75477": "37580",
  "75478": "44860",
  "75479": "14300",
  "75481": "44860",
  "75482": "44860",
  "75486": "37580",
  "75488": "14300",
  "75490": "14300",
  "75491": "43300",
  "75492": "14300",
  "75495": "43300",
  "75496": "14300",
  "75501": "45500",
  "75503": "45500",
  "75558": "34420",
  "75559": "45500",
  "75561": "45500",
  "75567": "45500",
  "75569": "45500",
  "75570": "45500",
  "75573": "45500",
  "75574": "45500",
  "75601": "30980",
  "75602": "30980",
  "75603": "30980",
  "75604": "30980",
  "75605": "30980",
  "75640": "30980",
  "75642": "30980",
  "75644": "30980",
  "75645": "30980",
  "75647": "30980",
  "75650": "30980",
  "75651": "30980",
  "75652": "30980",
  "75654": "30980",
  "75661": "30980",
  "75662": "30980",
  "75667": "30980",
  "75670": "30980",
  "75672": "30980",
  "75681": "30980",
  "75683": "30980",
  "75684": "30980",
  "75686": "34420",
  "75687": "30980",
  "75691": "30980",
  "75692": "30980",
  "75693": "30980",
  "75701": "46340",
  "75702": "46340",
  "75703": "46340",
  "75704": "46340",
  "75705": "46340",
  "75706": "46340",
  "75707": "46340",
  "75708": "46340",
  "75709": "46340",
  "75750": "46340",
  "75751": "11980",
  "75752": "11980",
  "75755": "30980",
  "75756": "11980",
  "75757": "46340",
  "75758": "11980",
  "75760": "34860",
  "75762": "46340",
  "75763": "11980",
  "75766": "27380",
  "75770": "11980",
  "75771": "46340",
  "75778": "11980",
  "75784": "30980",
  "75785": "27380",
  "75789": "27380",
  "75791": "46340",
  "75792": "46340",
  "75801": "37300",
  "75803": "37300",
  "75839": "37300",
  "75853": "37300",
  "75861": "37300",
  "75901": "31260",
  "75904": "31260",
  "75925": "27380",
  "75937": "34860",
  "75941": "31260",
  "75943": "34860",
  "75944": "34860",
  "75946": "34860",
  "75949": "31260",
  "75961": "34860",
  "75964": "34860",
  "75965": "34860",
  "75969": "31260",
  "75976": "27380",
  "75980": "31260",
  "76001": "19100",
  "76002": "19100",
  "76005": "19100",
  "76006": "19100",
  "76008": "19100",
  "76009": "19100",
  "76010": "19100",
  "76011": "19100",
  "76012": "19100",
  "76013": "19100",
  "76014": "19100",
  "76015": "19100",
  "76016": "19100",
  "76017": "19100",
  "76018": "19100",
  "76020": "19100",
  "76021": "19100",
  "76022": "19100",
  "76023": "19100",
  "76028": "19100",
  "76031": "19100",
  "76033": "19100",
  "76034": "19100",
  "76035": "24180",
  "76036": "19100",
  "76039": "19100",
  "76040": "19100",
  "76044": "19100",
  "76048": "24180",
  "76049": "24180",
  "76050": "19100",
  "76051": "19100",
  "76052": "19100",
  "76053": "19100",
  "76054": "19100",
  "76058": "19100",
  "76059": "19100",
  "76060": "19100",
  "76063": "19100",
  "76064": "19100",
  "76065": "19100",
  "76066": "19100",
  "76067": "33420",
  "76071": "19100",
  "76073": "19100",
  "76078": "19100",
  "76082": "19100",
  "76084": "19100",
  "76085": "19100",
  "76086": "19100",
  "76087": "19100",
  "76088": "19100",
  "76092": "19100",
  "76093": "19100",
  "76102": "19100",
  "76103": "19100",
  "76104": "19100",
  "76105": "19100",
  "76106": "19100",
  "76107": "19100",
  "76108": "19100",
  "76109": "19100",
  "76110": "19100",
  "76111": "19100",
  "76112": "19100",
  "76114": "19100",
  "76115": "19100",
  "76116": "19100",
  "76117": "19100",
  "76118": "19100",
  "76119": "19100",
  "76120": "19100",
  "76123": "19100",
  "76126": "19100",
  "76131": "19100",
  "76132": "19100",
  "76133": "19100",
  "76134": "19100",
  "76135": "19100",
  "76137": "19100",
  "76140": "19100",
  "76148": "19100",
  "76164": "19100",
  "76177": "19100",
  "76179": "19100",
  "76180": "19100",
  "76182": "19100",
  "76201": "19100",
  "76205": "19100",
  "76207": "19100",
  "76208": "19100",
  "76209": "19100",
  "76210": "19100",
  "76225": "19100",
  "76226": "19100",
  "76227": "19100",
  "76228": "48660",
  "76233": "23620",
  "76234": "19100",
  "76240": "23620",
  "76244": "19100",
  "76245": "43300",
  "76247": "19100",
  "76248": "19100",
  "76249": "19100",
  "76250": "23620",
  "76252": "23620",
  "76258": "19100",
  "76259": "19100",
  "76262": "19100",
  "76266": "19100",
  "76271": "43300",
  "76272": "23620",
  "76273": "43300",
  "76301": "48660",
  "76302": "48660",
  "76305": "48660",
  "76306": "48660",
  "76308": "48660",
  "76309": "48660",
  "76310": "48660",
  "76351": "48660",
  "76354": "48660",
  "76360": "48660",
  "76365": "48660",
  "76366": "48660",
  "76367": "48660",
  "76370": "48660",
  "76379": "48660",
  "76384": "46900",
  "76389": "48660",
  "76401": "44500",
  "76426": "19100",
  "76431": "19100",
  "76432": "15220",
  "76433": "44500",
  "76443": "10180",
  "76446": "44500",
  "76449": "33420",
  "76453": "44500",
  "76462": "24180",
  "76463": "44500",
  "76472": "33420",
  "76475": "33420",
  "76476": "24180",
  "76484": "33420",
  "76487": "19100",
  "76501": "28660",
  "76502": "28660",
  "76504": "28660",
  "76511": "28660",
  "76513": "28660",
  "76519": "28660",
  "76522": "28660",
  "76524": "47380",
  "76527": "12420",
  "76528": "28660",
  "76530": "12420",
  "76534": "28660",
  "76537": "12420",
  "76539": "28660",
  "76541": "28660",
  "76542": "28660",
  "76543": "28660",
  "76548": "28660",
  "76549": "28660",
  "76550": "28660",
  "76554": "28660",
  "76557": "47380",
  "76559": "28660",
  "76561": "28660",
  "76566": "28660",
  "76569": "28660",
  "76570": "47380",
  "76571": "28660",
  "76574": "12420",
  "76578": "12420",
  "76579": "28660",
  "76624": "47380",
  "76626": "18620",
  "76629": "17780",
  "76630": "47380",
  "76633": "47380",
  "76638": "47380",
  "76639": "18620",
  "76640": "47380",
  "76641": "18620",
  "76643": "47380",
  "76651": "19100",
  "76655": "47380",
  "76656": "47380",
  "76657": "47380",
  "76661": "47380",
  "76664": "47380",
  "76670": "19100",
  "76679": "18620",
  "76681": "18620",
  "76682": "47380",
  "76689": "47380",
  "76691": "47380",
  "76701": "47380",
  "76704": "47380",
  "76705": "47380",
  "76706": "47380",
  "76707": "47380",
  "76708": "47380",
  "76710": "47380",
  "76711": "47380",
  "76712": "47380",
  "76801": "15220",
  "76802": "15220",
  "76823": "15220",
  "76827": "15220",
  "76853": "28660",
  "76857": "15220",
  "76890": "15220",
  "76901": "41660",
  "76903": "41660",
  "76904": "41660",
  "76905": "41660",
  "76935": "41660",
  "76941": "41660",
  "76951": "41660",
  "77002": "26420",
  "77003": "26420",
  "77004": "26420",
  "77005": "26420",
  "77006": "26420",
  "77007": "26420",
  "77008": "26420",
  "77009": "26420",
  "77011": "26420",
  "77012": "26420",
  "77013": "26420",
  "77014": "26420",
  "77015": "26420",
  "77016": "26420",
  "77017": "26420",
  "77018": "26420",
  "77019": "26420",
  "77020": "26420",
  "77021": "26420",
  "77022": "26420",
  "77023": "26420",
  "77024": "26420",
  "77025": "26420",
  "77026": "26420",
  "77027": "26420",
  "77028": "26420",
  "77029": "26420",
  "77030": "26420",
  "77031": "26420",
  "77032": "26420",
  "77033": "26420",
  "77034": "26420",
  "77035": "26420",
  "77036": "26420",
  "77037": "26420",
  "77038": "26420",
  "77039": "26420",
  "77040": "26420",
  "77041": "26420",
  "77042": "26420",
  "77043": "26420",
  "77044": "26420",
  "77045": "26420",
  "77046": "26420",
  "77047": "26420",
  "77048": "26420",
  "77049": "26420",
  "77050": "26420",
  "77051": "26420",
  "77053": "26420",
  "77054": "26420",
  "77055": "26420",
  "77056": "26420",
  "77057": "26420",
  "77058": "26420",
  "77059": "26420",
  "77060": "26420",
  "77061": "26420",
  "77062": "26420",
  "77063": "26420",
  "77064": "26420",
  "77065": "26420",
  "77066": "26420",
  "77067": "26420",
  "77068": "26420",
  "77069": "26420",
  "77070": "26420",
  "77071": "26420",
  "77072": "26420",
  "77073": "26420",
  "77074": "26420",
  "77075": "26420",
  "77076": "26420",
  "77077": "26420",
  "77078": "26420",
  "77079": "26420",
  "77080": "26420",
  "77081": "26420",
  "77082": "26420",
  "77083": "26420",
  "77084": "26420",
  "77085": "26420",
  "77086": "26420",
  "77087": "26420",
  "77088": "26420",
  "77089": "26420",
  "77090": "26420",
  "77091": "26420",
  "77092": "26420",
  "77093": "26420",
  "77094": "26420",
  "77095": "26420",
  "77096": "26420",
  "77098": "26420",
  "77099": "26420",
  "77301": "26420",
  "77302": "26420",
  "77303": "26420",
  "77304": "26420",
  "77306": "26420",
  "77316": "26420",
  "77318": "26420",
  "77320": "26660",
  "77327": "26420",
  "77336": "26420",
  "77338": "26420",
  "77339": "26420",
  "77340": "26660",
  "77345": "26420",
  "77346": "26420",
  "77354": "26420",
  "77355": "26420",
  "77356": "26420",
  "77357": "26420",
  "77358": "26660",
  "77362": "26420",
  "77365": "26420",
  "77372": "26420",
  "77373": "26420",
  "77374": "13140",
  "77375": "26420",
  "77376": "13140",
  "77377": "26420",
  "77378": "26420",
  "77379": "26420",
  "77380": "26420",
  "77381": "26420",
  "77382": "26420",
  "77384": "26420",
  "77385": "26420",
  "77386": "26420",
  "77388": "26420",
  "77389": "26420",
  "77396": "26420",
  "77401": "26420",
  "77406": "26420",
  "77407": "26420",
  "77414": "13060",
  "77415": "13060",
  "77417": "26420",
  "77418": "26420",
  "77419": "13060",
  "77420": "20900",
  "77422": "26420",
  "77423": "26420",
  "77426": "14780",
  "77429": "26420",
  "77430": "26420",
  "77433": "26420",
  "77435": "20900",
  "77437": "20900",
  "77441": "26420",
  "77443": "20900",
  "77444": "26420",
  "77445": "26420",
  "77447": "26420",
  "77448": "20900",
  "77449": "26420",
  "77450": "26420",
  "77451": "26420",
  "77453": "20900",
  "77455": "20900",
  "77456": "13060",
  "77457": "13060",
  "77459": "26420",
  "77461": "26420",
  "77464": "26420",
  "77465": "13060",
  "77468": "13060",
  "77469": "26420",
  "77471": "26420",
  "77474": "26420",
  "77476": "26420",
  "77477": "26420",
  "77478": "26420",
  "77479": "26420",
  "77480": "26420",
  "77482": "13060",
  "77483": "13060",
  "77484": "26420",
  "77485": "26420",
  "77486": "26420",
  "77488": "20900",
  "77489": "26420",
  "77493": "26420",
  "77494": "26420",
  "77498": "26420",
  "77502": "26420",
  "77503": "26420",
  "77504": "26420",
  "77505": "26420",
  "77506": "26420",
  "77510": "26420",
  "77511": "26420",
  "77514": "26420",
  "77515": "26420",
  "77517": "26420",
  "77518": "26420",
  "77519": "13140",
  "77520": "26420",
  "77521": "26420",
  "77523": "26420",
  "77530": "26420",
  "77531": "26420",
  "77532": "26420",
  "77534": "26420",
  "77535": "26420",
  "77536": "26420",
  "77539": "26420",
  "77541": "26420",
  "77545": "26420",
  "77546": "26420",
  "77547": "26420",
  "77550": "26420",
  "77551": "26420",
  "77554": "26420",
  "77560": "26420",
  "77562": "26420",
  "77563": "26420",
  "77564": "26420",
  "77565": "26420",
  "77566": "26420",
  "77568": "26420",
  "77571": "26420",
  "77573": "26420",
  "77575": "26420",
  "77577": "26420",
  "77578": "26420",
  "77581": "26420",
  "77583": "26420",
  "77584": "26420",
  "77585": "13140",
  "77586": "26420",
  "77587": "26420",
  "77590": "26420",
  "77591": "26420",
  "77597": "26420",
  "77598": "26420",
  "77611": "13140",
  "77619": "13140",
  "77622": "13140",
  "77625": "13140",
  "77627": "13140",
  "77630": "13140",
  "77632": "13140",
  "77640": "13140",
  "77642": "13140",
  "77650": "26420",
  "77651": "13140",
  "77656": "13140",
  "77657": "13140",
  "77659": "13140",
  "77661": "26420",
  "77662": "13140",
  "77663": "13140",
  "77665": "26420",
  "77701": "13140",
  "77702": "13140",
  "77703": "13140",
  "77705": "13140",
  "77706": "13140",
  "77707": "13140",
  "77708": "13140",
  "77713": "13140",
  "77801": "17780",
  "77802": "17780",
  "77803": "17780",
  "77807": "17780",
  "77808": "17780",
  "77833": "14780",
  "77835": "14780",
  "77836": "17780",
  "77837": "17780",
  "77840": "17780",
  "77845": "17780",
  "77856": "17780",
  "77859": "17780",
  "77863": "17780",
  "77873": "26660",
  "77878": "17780",
  "77879": "17780",
  "77880": "14780",
  "77901": "47020",
  "77904": "47020",
  "77905": "47020",
  "77951": "47020",
  "77963": "47020",
  "77968": "47020",
  "77978": "38920",
  "77979": "38920",
  "77982": "38920",
  "77983": "38920",
  "78002": "41700",
  "78003": "41700",
  "78004": "41700",
  "78005": "37770",
  "78006": "41700",
  "78009": "41700",
  "78010": "28500",
  "78013": "28500",
  "78015": "41700",
  "78016": "41700",
  "78017": "37770",
  "78019": "29700",
  "78023": "41700",
  "78024": "28500",
  "78025": "28500",
  "78026": "41700",
  "78028": "28500",
  "78039": "41700",
  "78040": "29700",
  "78041": "29700",
  "78043": "29700",
  "78045": "29700",
  "78046": "29700",
  "78052": "41700",
  "78055": "41700",
  "78056": "41700",
  "78057": "37770",
  "78058": "28500",
  "78059": "41700",
  "78061": "37770",
  "78063": "41700",
  "78064": "41700",
  "78065": "41700",
  "78066": "41700",
  "78069": "41700",
  "78070": "41700",
  "78073": "41700",
  "78076": "49820",
  "78101": "41700",
  "78102": "13300",
  "78108": "41700",
  "78109": "41700",
  "78112": "41700",
  "78114": "41700",
  "78121": "41700",
  "78123": "41700",
  "78124": "41700",
  "78130": "41700",
  "78132": "41700",
  "78133": "41700",
  "78147": "41700",
  "78148": "41700",
  "78152": "41700",
  "78154": "41700",
  "78155": "41700",
  "78160": "41700",
  "78161": "41700",
  "78163": "41700",
  "78201": "41700",
  "78202": "41700",
  "78203": "41700",
  "78204": "41700",
  "78205": "41700",
  "78207": "41700",
  "78208": "41700",
  "78209": "41700",
  "78210": "41700",
  "78211": "41700",
  "78212": "41700",
  "78213": "41700",
  "78214": "41700",
  "78215": "41700",
  "78216": "41700",
  "78217": "41700",
  "78218": "41700",
  "78219": "41700",
  "78220": "41700",
  "78221": "41700",
  "78222": "41700",
  "78223": "41700",
  "78224": "41700",
  "78225": "41700",
  "78226": "41700",
  "78227": "41700",
  "78228": "41700",
  "78229": "41700",
  "78230": "41700",
  "78231": "41700",
  "78232": "41700",
  "78233": "41700",
  "78237": "41700",
  "78238": "41700",
  "78239": "41700",
  "78240": "41700",
  "78242": "41700",
  "78244": "41700",
  "78245": "41700",
  "78247": "41700",
  "78248": "41700",
  "78249": "41700",
  "78250": "41700",
  "78251": "41700",
  "78252": "41700",
  "78253": "41700",
  "78254": "41700",
  "78255": "41700",
  "78256": "41700",
  "78257": "41700",
  "78258": "41700",
  "78259": "41700",
  "78260": "41700",
  "78261": "41700",
  "78263": "41700",
  "78264": "41700",
  "78266": "41700",
  "78332": "10860",
  "78336": "18580",
  "78341": "10860",
  "78343": "18580",
  "78344": "10860",
  "78349": "10860",
  "78357": "10860",
  "78358": "40530",
  "78362": "18580",
  "78363": "28780",
  "78368": "18580",
  "78369": "29700",
  "78370": "18580",
  "78372": "10860",
  "78373": "18580",
  "78374": "18580",
  "78379": "28780",
  "78380": "18580",
  "78382": "40530",
  "78383": "10860",
  "78384": "10860",
  "78385": "28780",
  "78387": "18580",
  "78389": "13300",
  "78390": "18580",
  "78401": "18580",
  "78402": "18580",
  "78404": "18580",
  "78405": "18580",
  "78407": "18580",
  "78408": "18580",
  "78409": "18580",
  "78410": "18580",
  "78411": "18580",
  "78412": "18580",
  "78413": "18580",
  "78414": "18580",
  "78415": "18580",
  "78416": "18580",
  "78417": "18580",
  "78418": "18580",
  "78501": "32580",
  "78503": "32580",
  "78504": "32580",
  "78516": "32580",
  "78520": "15180",
  "78521": "15180",
  "78526": "15180",
  "78537": "32580",
  "78538": "32580",
  "78539": "32580",
  "78541": "32580",
  "78542": "32580",
  "78543": "32580",
  "78549": "32580",
  "78550": "15180",
  "78552": "15180",
  "78557": "32580",
  "78558": "32580",
  "78559": "15180",
  "78560": "32580",
  "78561": "39700",
  "78562": "32580",
  "78566": "15180",
  "78569": "39700",
  "78570": "32580",
  "78572": "32580",
  "78573": "32580",
  "78574": "32580",
  "78575": "15180",
  "78576": "32580",
  "78577": "32580",
  "78578": "15180",
  "78579": "32580",
  "78580": "39700",
  "78582": "40100",
  "78583": "15180",
  "78586": "15180",
  "78589": "32580",
  "78593": "15180",
  "78594": "39700",
  "78595": "32580",
  "78596": "32580",
  "78597": "15180",
  "78598": "39700",
  "78599": "32580",
  "78602": "12420",
  "78610": "12420",
  "78612": "12420",
  "78613": "12420",
  "78615": "12420",
  "78616": "12420",
  "78617": "12420",
  "78619": "12420",
  "78620": "12420",
  "78621": "12420",
  "78622": "12420",
  "78623": "41700",
  "78624": "23240",
  "78626": "12420",
  "78628": "12420",
  "78631": "23240",
  "78632": "12420",
  "78633": "12420",
  "78634": "12420",
  "78638": "41700",
  "78640": "12420",
  "78641": "12420",
  "78642": "12420",
  "78644": "12420",
  "78645": "12420",
  "78648": "12420",
  "78650": "12420",
  "78652": "12420",
  "78653": "12420",
  "78655": "12420",
  "78656": "12420",
  "78659": "12420",
  "78660": "12420",
  "78661": "12420",
  "78662": "12420",
  "78664": "12420",
  "78665": "12420",
  "78666": "12420",
  "78669": "12420",
  "78671": "23240",
  "78676": "12420",
  "78681": "12420",
  "78701": "12420",
  "78702": "12420",
  "78703": "12420",
  "78704": "12420",
  "78705": "12420",
  "78717": "12420",
  "78719": "12420",
  "78721": "12420",
  "78722": "12420",
  "78723": "12420",
  "78724": "12420",
  "78725": "12420",
  "78726": "12420",
  "78727": "12420",
  "78728": "12420",
  "78729": "12420",
  "78730": "12420",
  "78731": "12420",
  "78732": "12420",
  "78733": "12420",
  "78734": "12420",
  "78735": "12420",
  "78736": "12420",
  "78737": "12420",
  "78738": "12420",
  "78739": "12420",
  "78741": "12420",
  "78744": "12420",
  "78745": "12420",
  "78746": "12420",
  "78747": "12420",
  "78748": "12420",
  "78749": "12420",
  "78750": "12420",
  "78751": "12420",
  "78752": "12420",
  "78753": "12420",
  "78754": "12420",
  "78756": "12420",
  "78757": "12420",
  "78758": "12420",
  "78759": "12420",
  "78801": "46620",
  "78838": "46620",
  "78840": "19620",
  "78850": "41700",
  "78852": "20580",
  "78860": "20580",
  "78861": "41700",
  "78877": "20580",
  "78881": "46620",
  "78886": "41700",
  "78932": "14780",
  "78950": "26420",
  "78953": "12420",
  "78957": "12420",
  "79007": "14420",
  "79013": "20300",
  "79015": "11100",
  "79019": "11100",
  "79029": "20300",
  "79036": "14420",
  "79039": "11100",
  "79041": "38380",
  "79045": "25820",
  "79059": "37420",
  "79065": "37420",
  "79068": "11100",
  "79072": "38380",
  "79078": "14420",
  "79080": "11100",
  "79083": "14420",
  "79086": "20300",
  "79097": "11100",
  "79098": "25820",
  "79101": "11100",
  "79102": "11100",
  "79103": "11100",
  "79104": "11100",
  "79106": "11100",
  "79107": "11100",
  "79108": "11100",
  "79109": "11100",
  "79110": "11100",
  "79118": "11100",
  "79119": "11100",
  "79121": "11100",
  "79124": "11100",
  "79250": "38380",
  "79311": "38380",
  "79313": "30220",
  "79322": "31180",
  "79329": "31180",
  "79331": "29500",
  "79336": "30220",
  "79343": "31180",
  "79350": "31180",
  "79357": "31180",
  "79358": "30220",
  "79363": "31180",
  "79364": "31180",
  "79366": "31180",
  "79367": "30220",
  "79372": "30220",
  "79373": "31180",
  "79381": "31180",
  "79382": "31180",
  "79401": "31180",
  "79403": "31180",
  "79404": "31180",
  "79407": "31180",
  "79410": "31180",
  "79411": "31180",
  "79412": "31180",
  "79413": "31180",
  "79414": "31180",
  "79415": "31180",
  "79416": "31180",
  "79423": "31180",
  "79424": "31180",
  "79501": "10180",
  "79504": "10180",
  "79506": "45020",
  "79508": "10180",
  "79510": "10180",
  "79511": "13700",
  "79520": "10180",
  "79525": "10180",
  "79526": "43660",
  "79527": "43660",
  "79530": "10180",
  "79536": "10180",
  "79541": "10180",
  "79545": "45020",
  "79549": "43660",
  "79553": "10180",
  "79556": "45020",
  "79562": "10180",
  "79563": "10180",
  "79601": "10180",
  "79602": "10180",
  "79603": "10180",
  "79605": "10180",
  "79606": "10180",
  "79701": "33260",
  "79703": "33260",
  "79705": "33260",
  "79706": "33260",
  "79707": "33260",
  "79713": "33260",
  "79714": "11380",
  "79720": "13700",
  "79758": "36220",
  "79761": "36220",
  "79762": "36220",
  "79763": "36220",
  "79764": "36220",
  "79765": "36220",
  "79766": "36220",
  "79772": "37780",
  "79782": "33260",
  "79821": "21340",
  "79835": "21340",
  "79836": "21340",
  "79838": "21340",
  "79849": "21340",
  "79853": "21340",
  "79901": "21340",
  "79902": "21340",
  "79903": "21340",
  "79904": "21340",
  "79905": "21340",
  "79907": "21340",
  "79911": "21340",
  "79912": "21340",
  "79915": "21340",
  "79922": "21340",
  "79924": "21340",
  "79925": "21340",
  "79927": "21340",
  "79928": "21340",
  "79930": "21340",
  "79932": "21340",
  "79934": "21340",
  "79935": "21340",
  "79936": "21340",
  "79938": "21340",
  "80002": "19740",
  "80003": "19740",
  "80004": "19740",
  "80005": "19740",
  "80007": "19740",
  "80010": "19740",
  "80011": "19740",
  "80012": "19740",
  "80013": "19740",
  "80014": "19740",
  "80015": "19740",
  "80016": "19740",
  "80017": "19740",
  "80018": "19740",
  "80019": "19740",
  "80020": "19740",
  "80021": "19740",
  "80022": "19740",
  "80023": "19740",
  "80026": "14500",
  "80027": "14500",
  "80030": "19740",
  "80031": "19740",
  "80033": "19740",
  "80101": "19740",
  "80102": "19740",
  "80103": "19740",
  "80104": "19740",
  "80105": "19740",
  "80106": "19740",
  "80107": "19740",
  "80108": "19740",
  "80109": "19740",
  "80110": "19740",
  "80111": "19740",
  "80112": "19740",
  "80113": "19740",
  "80116": "19740",
  "80117": "19740",
  "80118": "19740",
  "80120": "19740",
  "80121": "19740",
  "80122": "19740",
  "80123": "19740",
  "80124": "19740",
  "80125": "19740",
  "80126": "19740",
  "80127": "19740",
  "80128": "19740",
  "80129": "19740",
  "80130": "19740",
  "80131": "19740",
  "80132": "17820",
  "80133": "17820",
  "80134": "19740",
  "80135": "19740",
  "80136": "19740",
  "80137": "19740",
  "80138": "19740",
  "80202": "19740",
  "80203": "19740",
  "80204": "19740",
  "80205": "19740",
  "80206": "19740",
  "80207": "19740",
  "80209": "19740",
  "80210": "19740",
  "80211": "19740",
  "80212": "19740",
  "80214": "19740",
  "80215": "19740",
  "80216": "19740",
  "80218": "19740",
  "80219": "19740",
  "80220": "19740",
  "80221": "19740",
  "80222": "19740",
  "80223": "19740",
  "80224": "19740",
  "80226": "19740",
  "80227": "19740",
  "80228": "19740",
  "80229": "19740",
  "80230": "19740",
  "80231": "19740",
  "80232": "19740",
  "80233": "19740",
  "80234": "19740",
  "80235": "19740",
  "80236": "19740",
  "80237": "19740",
  "80238": "19740",
  "80239": "19740",
  "80241": "19740",
  "80246": "19740",
  "80247": "19740",
  "80249": "19740",
  "80260": "19740",
  "80301": "14500",
  "80302": "14500",
  "80303": "14500",
  "80304": "14500",
  "80305": "14500",
  "80401": "19740",
  "80403": "19740",
  "80420": "19740",
  "80421": "19740",
  "80422": "19740",
  "80424": "14720",
  "80427": "19740",
  "80428": "44460",
  "80432": "19740",
  "80433": "19740",
  "80435": "14720",
  "80436": "19740",
  "80438": "19740",
  "80439": "19740",
  "80440": "19740",
  "80443": "14720",
  "80444": "19740",
  "80448": "19740",
  "80449": "19740",
  "80452": "19740",
  "80453": "19740",
  "80454": "19740",
  "80455": "14500",
  "80456": "19740",
  "80457": "19740",
  "80465": "19740",
  "80466": "14500",
  "80467": "44460",
  "80469": "44460",
  "80470": "19740",
  "80474": "19740",
  "80475": "19740",
  "80476": "19740",
  "80481": "14500",
  "80483": "44460",
  "80487": "44460",
  "80498": "14720",
  "80501": "14500",
  "80503": "14500",
  "80504": "24540",
  "80510": "14500",
  "80512": "22660",
  "80513": "22660",
  "80514": "24540",
  "80515": "22660",
  "80516": "24540",
  "80517": "22660",
  "80520": "24540",
  "80521": "22660",
  "80524": "22660",
  "80525": "22660",
  "80526": "22660",
  "80528": "22660",
  "80530": "24540",
  "80532": "22660",
  "80534": "24540",
  "80535": "22660",
  "80536": "22660",
  "80537": "22660",
  "80538": "22660",
  "80540": "14500",
  "80542": "24540",
  "80543": "24540",
  "80545": "22660",
  "80547": "22660",
  "80549": "22660",
  "80550": "24540",
  "80601": "19740",
  "80602": "19740",
  "80603": "24540",
  "80610": "24540",
  "80611": "24540",
  "80612": "24540",
  "80615": "24540",
  "80620": "24540",
  "80621": "24540",
  "80623": "24540",
  "80624": "24540",
  "80631": "24540",
  "80634": "24540",
  "80640": "19740",
  "80642": "24540",
  "80643": "24540",
  "80644": "24540",
  "80645": "24540",
  "80648": "24540",
  "80649": "24540",
  "80650": "24540",
  "80651": "24540",
  "80652": "24540",
  "80653": "22820",
  "80654": "22820",
  "80701": "22820",
  "80705": "22820",
  "80722": "44540",
  "80723": "22820",
  "80726": "44540",
  "80728": "44540",
  "80729": "24540",
  "80733": "22820",
  "80736": "44540",
  "80741": "44540",
  "80747": "44540",
  "80751": "44540",
  "80808": "17820",
  "80809": "17820",
  "80813": "17820",
  "80814": "17820",
  "80816": "17820",
  "80817": "17820",
  "80819": "17820",
  "80820": "19740",
  "80827": "19740",
  "80829": "17820",
  "80830": "19740",
  "80831": "17820",
  "80832": "17820",
  "80835": "19740",
  "80860": "17820",
  "80863": "17820",
  "80864": "17820",
  "80903": "17820",
  "80904": "17820",
  "80905": "17820",
  "80906": "17820",
  "80907": "17820",
  "80908": "17820",
  "80909": "17820",
  "80910": "17820",
  "80911": "17820",
  "80915": "17820",
  "80916": "17820",
  "80917": "17820",
  "80918": "17820",
  "80919": "17820",
  "80920": "17820",
  "80921": "17820",
  "80922": "17820",
  "80923": "17820",
  "80924": "17820",
  "80925": "17820",
  "80926": "17820",
  "80927": "17820",
  "80928": "17820",
  "80951": "17820",
  "81001": "39380",
  "81003": "39380",
  "81004": "39380",
  "81005": "39380",
  "81006": "39380",
  "81007": "39380",
  "81008": "39380",
  "81019": "39380",
  "81022": "39380",
  "81023": "39380",
  "81025": "39380",
  "81122": "20420",
  "81137": "20420",
  "81212": "15860",
  "81223": "15860",
  "81226": "15860",
  "81233": "15860",
  "81240": "15860",
  "81301": "20420",
  "81303": "20420",
  "81326": "20420",
  "81401": "33940",
  "81403": "33940",
  "81415": "33940",
  "81422": "33940",
  "81423": "33940",
  "81424": "33940",
  "81425": "33940",
  "81427": "33940",
  "81432": "33940",
  "81501": "24300",
  "81503": "24300",
  "81504": "24300",
  "81505": "24300",
  "81506": "24300",
  "81507": "24300",
  "81520": "24300",
  "81521": "24300",
  "81523": "24300",
  "81524": "24300",
  "81525": "24300",
  "81526": "24300",
  "81527": "24300",
  "81601": "24060",
  "81611": "24060",
  "81615": "24060",
  "81620": "20780",
  "81621": "24060",
  "81623": "24060",
  "81624": "24300",
  "81625": "18780",
  "81630": "24060",
  "81631": "20780",
  "81632": "20780",
  "81635": "24060",
  "81637": "20780",
  "81639": "44460",
  "81642": "24060",
  "81643": "24300",
  "81647": "24060",
  "81650": "24060",
  "81652": "24060",
  "81654": "24060",
  "81656": "24060",
  "81657": "20780",
  "82001": "16940",
  "82007": "16940",
  "82009": "16940",
  "82053": "16940",
  "82054": "16940",
  "82055": "29660",
  "82070": "29660",
  "82072": "29660",
  "82082": "16940",
  "82501": "40180",
  "82513": "40180",
  "82515": "40180",
  "82516": "40180",
  "82520": "40180",
  "82523": "40180",
  "82601": "16220",
  "82604": "16220",
  "82609": "16220",
  "82636": "16220",
  "82643": "16220",
  "82649": "40180",
  "82701": "23940",
  "82710": "23940",
  "82712": "23940",
  "82716": "23940",
  "82718": "23940",
  "82720": "23940",
  "82721": "23940",
  "82727": "23940",
  "82729": "23940",
  "82730": "23940",
  "82732": "23940",
  "82801": "43260",
  "82832": "43260",
  "82833": "43260",
  "82835": "43260",
  "82836": "43260",
  "82838": "43260",
  "82839": "43260",
  "82842": "43260",
  "82901": "40540",
  "82930": "21740",
  "82932": "40540",
  "82933": "21740",
  "82935": "40540",
  "82937": "21740",
  "82939": "21740",
  "82943": "40540",
  "82945": "40540",
  "83001": "27220",
  "83013": "27220",
  "83014": "27220",
  "83025": "27220",
  "83201": "38540",
  "83202": "38540",
  "83204": "38540",
  "83210": "13940",
  "83211": "38540",
  "83214": "38540",
  "83221": "13940",
  "83228": "30860",
  "83234": "38540",
  "83236": "13940",
  "83237": "30860",
  "83245": "38540",
  "83246": "38540",
  "83250": "38540",
  "83262": "13940",
  "83263": "30860",
  "83271": "38540",
  "83274": "13940",
  "83301": "46300",
  "83311": "15420",
  "83313": "25200",
  "83316": "46300",
  "83318": "15420",
  "83320": "25200",
  "83323": "15420",
  "83325": "46300",
  "83327": "25200",
  "83328": "46300",
  "83333": "25200",
  "83334": "46300",
  "83335": "46300",
  "83336": "15420",
  "83338": "46300",
  "83340": "25200",
  "83341": "46300",
  "83342": "15420",
  "83344": "46300",
  "83346": "15420",
  "83347": "15420",
  "83350": "15420",
  "83353": "25200",
  "83401": "26820",
  "83402": "26820",
  "83404": "26820",
  "83406": "26820",
  "83414": "27220",
  "83420": "39940",
  "83422": "27220",
  "83424": "27220",
  "83427": "26820",
  "83428": "26820",
  "83429": "39940",
  "83431": "26820",
  "83434": "26820",
  "83436": "39940",
  "83440": "39940",
  "83442": "26820",
  "83443": "26820",
  "83444": "26820",
  "83445": "39940",
  "83448": "39940",
  "83449": "26820",
  "83450": "26820",
  "83451": "39940",
  "83452": "27220",
  "83454": "26820",
  "83455": "27220",
  "83501": "30300",
  "83524": "30300",
  "83535": "34140",
  "83537": "34140",
  "83540": "30300",
  "83541": "30300",
  "83545": "30300",
  "83604": "14260",
  "83605": "14260",
  "83607": "14260",
  "83616": "14260",
  "83617": "14260",
  "83619": "36620",
  "83622": "14260",
  "83623": "34300",
  "83624": "14260",
  "83626": "14260",
  "83627": "14260",
  "83628": "14260",
  "83629": "14260",
  "83631": "14260",
  "83633": "34300",
  "83634": "14260",
  "83637": "14260",
  "83639": "14260",
  "83641": "14260",
  "83642": "14260",
  "83644": "14260",
  "83646": "14260",
  "83647": "34300",
  "83650": "14260",
  "83651": "14260",
  "83655": "36620",
  "83656": "14260",
  "83660": "14260",
  "83661": "36620",
  "83669": "14260",
  "83670": "14260",
  "83676": "14260",
  "83686": "14260",
  "83687": "14260",
  "83702": "14260",
  "83703": "14260",
  "83704": "14260",
  "83705": "14260",
  "83706": "14260",
  "83709": "14260",
  "83712": "14260",
  "83713": "14260",
  "83714": "14260",
  "83716": "14260",
  "83801": "17660",
  "83803": "41760",
  "83804": "41760",
  "83806": "34140",
  "83809": "41760",
  "83810": "17660",
  "83811": "41760",
  "83813": "41760",
  "83814": "17660",
  "83815": "17660",
  "83821": "41760",
  "83822": "41760",
  "83823": "34140",
  "83825": "41760",
  "83832": "30300",
  "83833": "17660",
  "83835": "17660",
  "83836": "41760",
  "83840": "41760",
  "83841": "41760",
  "83843": "34140",
  "83848": "41760",
  "83852": "41760",
  "83854": "17660",
  "83855": "34140",
  "83856": "41760",
  "83857": "34140",
  "83858": "17660",
  "83860": "41760",
  "83864": "41760",
  "83869": "17660",
  "83871": "34140",
  "83872": "34140",
  "83876": "17660",
  "84003": "39340",
  "84004": "39340",
  "84005": "39340",
  "84006": "41620",
  "84009": "41620",
  "84010": "36260",
  "84013": "39340",
  "84014": "36260",
  "84015": "36260",
  "84017": "25720",
  "84020": "41620",
  "84025": "36260",
  "84029": "41620",
  "84032": "25720",
  "84033": "25720",
  "84036": "25720",
  "84037": "36260",
  "84040": "36260",
  "84041": "36260",
  "84042": "39340",
  "84043": "39340",
  "84044": "41620",
  "84045": "39340",
  "84047": "41620",
  "84049": "25720",
  "84050": "36260",
  "84054": "36260",
  "84055": "25720",
  "84057": "39340",
  "84058": "39340",
  "84059": "39340",
  "84060": "25720",
  "84061": "25720",
  "84062": "39340",
  "84065": "41620",
  "84067": "36260",
  "84070": "41620",
  "84071": "41620",
  "84074": "41620",
  "84075": "36260",
  "84078": "46860",
  "84081": "41620",
  "84082": "25720",
  "84084": "41620",
  "84087": "36260",
  "84088": "41620",
  "84092": "41620",
  "84093": "41620",
  "84094": "41620",
  "84095": "41620",
  "84096": "41620",
  "84097": "39340",
  "84098": "25720",
  "84101": "41620",
  "84102": "41620",
  "84103": "41620",
  "84104": "41620",
  "84105": "41620",
  "84106": "41620",
  "84107": "41620",
  "84108": "41620",
  "84109": "41620",
  "84111": "41620",
  "84115": "41620",
  "84116": "41620",
  "84117": "41620",
  "84118": "41620",
  "84119": "41620",
  "84120": "41620",
  "84121": "41620",
  "84123": "41620",
  "84124": "41620",
  "84128": "41620",
  "84129": "41620",
  "84301": "36260",
  "84302": "36260",
  "84305": "30860",
  "84306": "36260",
  "84307": "36260",
  "84309": "36260",
  "84310": "36260",
  "84311": "36260",
  "84312": "36260",
  "84314": "36260",
  "84315": "36260",
  "84317": "36260",
  "84318": "30860",
  "84319": "30860",
  "84320": "30860",
  "84321": "30860",
  "84324": "36260",
  "84325": "30860",
  "84326": "30860",
  "84327": "30860",
  "84328": "30860",
  "84330": "36260",
  "84332": "30860",
  "84333": "30860",
  "84335": "30860",
  "84337": "36260",
  "84338": "30860",
  "84339": "30860",
  "84340": "36260",
  "84341": "30860",
  "84401": "36260",
  "84403": "36260",
  "84404": "36260",
  "84405": "36260",
  "84414": "36260",
  "84501": "39220",
  "84526": "39220",
  "84601": "39340",
  "84604": "39340",
  "84606": "39340",
  "84633": "39340",
  "84645": "39340",
  "84648": "39340",
  "84651": "39340",
  "84653": "39340",
  "84655": "39340",
  "84660": "39340",
  "84663": "39340",
  "84664": "39340",
  "84719": "16260",
  "84720": "16260",
  "84721": "16260",
  "84722": "41100",
  "84725": "41100",
  "84737": "41100",
  "84738": "41100",
  "84745": "41100",
  "84746": "41100",
  "84757": "41100",
  "84761": "16260",
  "84763": "41100",
  "84765": "41100",
  "84767": "41100",
  "84770": "41100",
  "84774": "41100",
  "84779": "41100",
  "84780": "41100",
  "84781": "41100",
  "84782": "41100",
  "84783": "41100",
  "84790": "41100",
  "85003": "38060",
  "85004": "38060",
  "85006": "38060",
  "85007": "38060",
  "85008": "38060",
  "85009": "38060",
  "85012": "38060",
  "85013": "38060",
  "85014": "38060",
  "85015": "38060",
  "85016": "38060",
  "85017": "38060",
  "85018": "38060",
  "85019": "38060",
  "85020": "38060",
  "85021": "38060",
  "85022": "38060",
  "85023": "38060",
  "85024": "38060",
  "85027": "38060",
  "85028": "38060",
  "85029": "38060",
  "85031": "38060",
  "85032": "38060",
  "85033": "38060",
  "85034": "38060",
  "85035": "38060",
  "85037": "38060",
  "85040": "38060",
  "85041": "38060",
  "85042": "38060",
  "85043": "38060",
  "85044": "38060",
  "85045": "38060",
  "85048": "38060",
  "85050": "38060",
  "85051": "38060",
  "85053": "38060",
  "85054": "38060",
  "85083": "38060",
  "85085": "38060",
  "85086": "38060",
  "85087": "38060",
  "85118": "38060",
  "85119": "38060",
  "85120": "38060",
  "85122": "38060",
  "85123": "38060",
  "85128": "38060",
  "85131": "38060",
  "85132": "38060",
  "85135": "37740",
  "85137": "38060",
  "85138": "38060",
  "85139": "38060",
  "85140": "38060",
  "85142": "38060",
  "85143": "38060",
  "85145": "38060",
  "85173": "38060",
  "85192": "38060",
  "85193": "38060",
  "85194": "38060",
  "85201": "38060",
  "85202": "38060",
  "85203": "38060",
  "85204": "38060",
  "85205": "38060",
  "85206": "38060",
  "85207": "38060",
  "85208": "38060",
  "85209": "38060",
  "85210": "38060",
  "85212": "38060",
  "85213": "38060",
  "85215": "38060",
  "85224": "38060",
  "85225": "38060",
  "85226": "38060",
  "85233": "38060",
  "85234": "38060",
  "85248": "38060",
  "85249": "38060",
  "85250": "38060",
  "85251": "38060",
  "85253": "38060",
  "85254": "38060",
  "85255": "38060",
  "85257": "38060",
  "85258": "38060",
  "85259": "38060",
  "85260": "38060",
  "85262": "38060",
  "85263": "38060",
  "85264": "38060",
  "85266": "38060",
  "85268": "38060",
  "85281": "38060",
  "85282": "38060",
  "85283": "38060",
  "85284": "38060",
  "85286": "38060",
  "85295": "38060",
  "85296": "38060",
  "85297": "38060",
  "85298": "38060",
  "85301": "38060",
  "85302": "38060",
  "85303": "38060",
  "85304": "38060",
  "85305": "38060",
  "85306": "38060",
  "85307": "38060",
  "85308": "38060",
  "85310": "38060",
  "85320": "38060",
  "85321": "46060",
  "85322": "38060",
  "85323": "38060",
  "85324": "39150",
  "85326": "38060",
  "85331": "38060",
  "85332": "39150",
  "85335": "38060",
  "85336": "49740",
  "85337": "38060",
  "85338": "38060",
  "85339": "38060",
  "85340": "38060",
  "85342": "38060",
  "85345": "38060",
  "85349": "49740",
  "85350": "49740",
  "85351": "38060",
  "85353": "38060",
  "85354": "38060",
  "85355": "38060",
  "85356": "49740",
  "85361": "38060",
  "85362": "39150",
  "85363": "38060",
  "85364": "49740",
  "85365": "49740",
  "85367": "49740",
  "85373": "38060",
  "85374": "38060",
  "85375": "38060",
  "85377": "38060",
  "85378": "38060",
  "85379": "38060",
  "85381": "38060",
  "85382": "38060",
  "85383": "38060",
  "85387": "38060",
  "85388": "38060",
  "85390": "38060",
  "85392": "38060",
  "85395": "38060",
  "85396": "38060",
  "85501": "37740",
  "85532": "37740",
  "85539": "37740",
  "85541": "37740",
  "85543": "40940",
  "85544": "37740",
  "85545": "38060",
  "85546": "40940",
  "85552": "40940",
  "85553": "37740",
  "85554": "37740",
  "85602": "43420",
  "85603": "43420",
  "85605": "43420",
  "85606": "43420",
  "85607": "43420",
  "85610": "43420",
  "85611": "46060",
  "85614": "46060",
  "85615": "43420",
  "85616": "43420",
  "85617": "43420",
  "85619": "46060",
  "85621": "35700",
  "85622": "46060",
  "85623": "38060",
  "85624": "35700",
  "85625": "43420",
  "85629": "46060",
  "85630": "43420",
  "85631": "38060",
  "85632": "43420",
  "85635": "43420",
  "85637": "35700",
  "85638": "43420",
  "85640": "35700",
  "85641": "46060",
  "85643": "43420",
  "85645": "46060",
  "85646": "35700",
  "85648": "35700",
  "85650": "43420",
  "85653": "46060",
  "85658": "46060",
  "85701": "46060",
  "85704": "46060",
  "85705": "46060",
  "85706": "46060",
  "85710": "46060",
  "85711": "46060",
  "85712": "46060",
  "85713": "46060",
  "85714": "46060",
  "85715": "46060",
  "85716": "46060",
  "85718": "46060",
  "85719": "46060",
  "85730": "46060",
  "85735": "46060",
  "85736": "46060",
  "85737": "46060",
  "85739": "46060",
  "85741": "46060",
  "85742": "46060",
  "85743": "46060",
  "85745": "46060",
  "85746": "46060",
  "85747": "46060",
  "85748": "46060",
  "85749": "46060",
  "85750": "46060",
  "85755": "46060",
  "85756": "46060",
  "85757": "46060",
  "85901": "43320",
  "85928": "43320",
  "85929": "43320",
  "85931": "22380",
  "85933": "43320",
  "85935": "43320",
  "85937": "43320",
  "85939": "43320",
  "86001": "22380",
  "86004": "22380",
  "86005": "22380",
  "86015": "22380",
  "86017": "22380",
  "86018": "22380",
  "86021": "29420",
  "86022": "29420",
  "86024": "22380",
  "86025": "43320",
  "86038": "22380",
  "86040": "22380",
  "86046": "22380",
  "86047": "43320",
  "86301": "39150",
  "86303": "39150",
  "86305": "39150",
  "86314": "39150",
  "86315": "39150",
  "86320": "39150",
  "86322": "39150",
  "86323": "39150",
  "86324": "39150",
  "86325": "39150",
  "86326": "39150",
  "86327": "39150",
  "86332": "39150",
  "86333": "39150",
  "86334": "39150",
  "86335": "39150",
  "86336": "39150",
  "86337": "39150",
  "86343": "39150",
  "86351": "39150",
  "86401": "29420",
  "86403": "29420",
  "86404": "29420",
  "86406": "29420",
  "86409": "29420",
  "86413": "29420",
  "86426": "29420",
  "86429": "29420",
  "86432": "29420",
  "86436": "29420",
  "86438": "29420",
  "86440": "29420",
  "86441": "29420",
  "86442": "29420",
  "86444": "29420",
  "87001": "10740",
  "87002": "10740",
  "87004": "10740",
  "87008": "10740",
  "87010": "42140",
  "87013": "10740",
  "87015": "42140",
  "87016": "10740",
  "87020": "24380",
  "87024": "10740",
  "87025": "10740",
  "87031": "10740",
  "87035": "10740",
  "87036": "10740",
  "87041": "10740",
  "87042": "10740",
  "87043": "10740",
  "87044": "10740",
  "87046": "21580",
  "87047": "10740",
  "87048": "10740",
  "87056": "42140",
  "87059": "10740",
  "87068": "10740",
  "87083": "10740",
  "87102": "10740",
  "87104": "10740",
  "87105": "10740",
  "87106": "10740",
  "87107": "10740",
  "87108": "10740",
  "87109": "10740",
  "87110": "10740",
  "87111": "10740",
  "87112": "10740",
  "87113": "10740",
  "87114": "10740",
  "87120": "10740",
  "87121": "10740",
  "87122": "10740",
  "87123": "10740",
  "87124": "10740",
  "87144": "10740",
  "87301": "23700",
  "87317": "23700",
  "87401": "22140",
  "87402": "22140",
  "87410": "22140",
  "87412": "21580",
  "87413": "22140",
  "87415": "22140",
  "87416": "22140",
  "87417": "22140",
  "87418": "22140",
  "87419": "22140",
  "87421": "22140",
  "87501": "42140",
  "87505": "42140",
  "87506": "42140",
  "87507": "42140",
  "87508": "42140",
  "87510": "21580",
  "87520": "21580",
  "87522": "42140",
  "87529": "45340",
  "87532": "21580",
  "87535": "42140",
  "87538": "29780",
  "87540": "42140",
  "87544": "31060",
  "87547": "31060",
  "87552": "29780",
  "87556": "45340",
  "87557": "45340",
  "87558": "45340",
  "87560": "29780",
  "87562": "29780",
  "87567": "42140",
  "87571": "45340",
  "87579": "45340",
  "87580": "45340",
  "87701": "29780",
  "87742": "29780",
  "87745": "29780",
  "87936": "29740",
  "87937": "29740",
  "87940": "29740",
  "87941": "29740",
  "88001": "29740",
  "88005": "29740",
  "88007": "29740",
  "88008": "29740",
  "88011": "29740",
  "88012": "29740",
  "88021": "29740",
  "88022": "43500",
  "88023": "43500",
  "88024": "29740",
  "88026": "43500",
  "88028": "43500",
  "88030": "19700",
  "88032": "29740",
  "88038": "43500",
  "88041": "43500",
  "88043": "43500",
  "88044": "29740",
  "88046": "29740",
  "88047": "29740",
  "88048": "29740",
  "88049": "43500",
  "88053": "43500",
  "88061": "43500",
  "88063": "29740",
  "88065": "43500",
  "88072": "29740",
  "88081": "29740",
  "88101": "17580",
  "88130": "38780",
  "88201": "40740",
  "88203": "40740",
  "88210": "16100",
  "88220": "16100",
  "88230": "40740",
  "88231": "26020",
  "88240": "26020",
  "88242": "26020",
  "88252": "26020",
  "88260": "26020",
  "88267": "26020",
  "88301": "40760",
  "88310": "10460",
  "88312": "40760",
  "88316": "40760",
  "88317": "10460",
  "88318": "40760",
  "88325": "10460",
  "88337": "10460",
  "88339": "10460",
  "88341": "40760",
  "88345": "40760",
  "88346": "40760",
  "88347": "10460",
  "88348": "40760",
  "88352": "10460",
  "88416": "29780",
  "89002": "29820",
  "89004": "29820",
  "89005": "29820",
  "89007": "29820",
  "89011": "29820",
  "89012": "29820",
  "89014": "29820",
  "89015": "29820",
  "89018": "29820",
  "89019": "29820",
  "89021": "29820",
  "89025": "29820",
  "89027": "29820",
  "89029": "29820",
  "89030": "29820",
  "89031": "29820",
  "89032": "29820",
  "89040": "29820",
  "89044": "29820",
  "89046": "29820",
  "89048": "37220",
  "89049": "37220",
  "89052": "29820",
  "89060": "37220",
  "89061": "37220",
  "89074": "29820",
  "89081": "29820",
  "89084": "29820",
  "89085": "29820",
  "89086": "29820",
  "89101": "29820",
  "89102": "29820",
  "89103": "29820",
  "89104": "29820",
  "89106": "29820",
  "89107": "29820",
  "89108": "29820",
  "89109": "29820",
  "89110": "29820",
  "89113": "29820",
  "89115": "29820",
  "89117": "29820",
  "89118": "29820",
  "89119": "29820",
  "89120": "29820",
  "89121": "29820",
  "89122": "29820",
  "89123": "29820",
  "89124": "29820",
  "89128": "29820",
  "89129": "29820",
  "89130": "29820",
  "89131": "29820",
  "89134": "29820",
  "89135": "29820",
  "89138": "29820",
  "89139": "29820",
  "89141": "29820",
  "89142": "29820",
  "89143": "29820",
  "89144": "29820",
  "89145": "29820",
  "89146": "29820",
  "89147": "29820",
  "89148": "29820",
  "89149": "29820",
  "89155": "29820",
  "89156": "29820",
  "89158": "29820",
  "89161": "29820",
  "89166": "29820",
  "89169": "29820",
  "89178": "29820",
  "89179": "29820",
  "89183": "29820",
  "89316": "21220",
  "89402": "39900",
  "89403": "22280",
  "89406": "21980",
  "89408": "22280",
  "89410": "23820",
  "89411": "23820",
  "89413": "23820",
  "89423": "23820",
  "89428": "22280",
  "89429": "22280",
  "89430": "22280",
  "89431": "39900",
  "89433": "39900",
  "89434": "39900",
  "89436": "39900",
  "89439": "39900",
  "89440": "39900",
  "89441": "39900",
  "89442": "39900",
  "89444": "22280",
  "89445": "49080",
  "89447": "22280",
  "89448": "23820",
  "89449": "23820",
  "89451": "39900",
  "89460": "23820",
  "89501": "39900",
  "89502": "39900",
  "89503": "39900",
  "89506": "39900",
  "89508": "39900",
  "89509": "39900",
  "89510": "39900",
  "89511": "39900",
  "89512": "39900",
  "89519": "39900",
  "89521": "39900",
  "89523": "39900",
  "89701": "16180",
  "89703": "16180",
  "89704": "39900",
  "89705": "23820",
  "89706": "16180",
  "89801": "21220",
  "89815": "21220",
  "89822": "21220",
  "89835": "21220",
  "89883": "21220",
  "90001": "31080",
  "90002": "31080",
  "90003": "31080",
  "90004": "31080",
  "90005": "31080",
  "90006": "31080",
  "90007": "31080",
  "90008": "31080",
  "90010": "31080",
  "90011": "31080",
  "90012": "31080",
  "90013": "31080",
  "90014": "31080",
  "90015": "31080",
  "90016": "31080",
  "90017": "31080",
  "90018": "31080",
  "90019": "31080",
  "90020": "31080",
  "90021": "31080",
  "90022": "31080",
  "90023": "31080",
  "90024": "31080",
  "90025": "31080",
  "90026": "31080",
  "90027": "31080",
  "90028": "31080",
  "90029": "31080",
  "90031": "31080",
  "90032": "31080",
  "90033": "31080",
  "90034": "31080",
  "90035": "31080",
  "90036": "31080",
  "90037": "31080",
  "90038": "31080",
  "90039": "31080",
  "90040": "31080",
  "90041": "31080",
  "90042": "31080",
  "90043": "31080",
  "90044": "31080",
  "90045": "31080",
  "90046": "31080",
  "90047": "31080",
  "90048": "31080",
  "90049": "31080",
  "90056": "31080",
  "90057": "31080",
  "90058": "31080",
  "90059": "31080",
  "90061": "31080",
  "90062": "31080",
  "90063": "31080",
  "90064": "31080",
  "90065": "31080",
  "90066": "31080",
  "90067": "31080",
  "90068": "31080",
  "90069": "31080",
  "90077": "31080",
  "90094": "31080",
  "90201": "31080",
  "90210": "31080",
  "90211": "31080",
  "90212": "31080",
  "90220": "31080",
  "90221": "31080",
  "90222": "31080",
  "90230": "31080",
  "90232": "31080",
  "90240": "31080",
  "90241": "31080",
  "90242": "31080",
  "90245": "31080",
  "90247": "31080",
  "90248": "31080",
  "90249": "31080",
  "90250": "31080",
  "90254": "31080",
  "90255": "31080",
  "90260": "31080",
  "90262": "31080",
  "90265": "31080",
  "90266": "31080",
  "90270": "31080",
  "90272": "31080",
  "90274": "31080",
  "90275": "31080",
  "90277": "31080",
  "90278": "31080",
  "90280": "31080",
  "90290": "31080",
  "90291": "31080",
  "90292": "31080",
  "90293": "31080",
  "90301": "31080",
  "90302": "31080",
  "90303": "31080",
  "90304": "31080",
  "90305": "31080",
  "90401": "31080",
  "90402": "31080",
  "90403": "31080",
  "90404": "31080",
  "90405": "31080",
  "90501": "31080",
  "90502": "31080",
  "90503": "31080",
  "90504": "31080",
  "90505": "31080",
  "90601": "31080",
  "90602": "31080",
  "90603": "31080",
  "90604": "31080",
  "90605": "31080",
  "90606": "31080",
  "90620": "31080",
  "90621": "31080",
  "90623": "31080",
  "90630": "31080",
  "90631": "31080",
  "90638": "31080",
  "90640": "31080",
  "90650": "31080",
  "90660": "31080",
  "90670": "31080",
  "90680": "31080",
  "90701": "31080",
  "90703": "31080",
  "90704": "31080",
  "90706": "31080",
  "90710": "31080",
  "90712": "31080",
  "90713": "31080",
  "90715": "31080",
  "90716": "31080",
  "90717": "31080",
  "90720": "31080",
  "90723": "31080",
  "90731": "31080",
  "90732": "31080",
  "90740": "31080",
  "90742": "31080",
  "90744": "31080",
  "90745": "31080",
  "90746": "31080",
  "90755": "31080",
  "90802": "31080",
  "90803": "31080",
  "90804": "31080",
  "90805": "31080",
  "90806": "31080",
  "90807": "31080",
  "90808": "31080",
  "90810": "31080",
  "90813": "31080",
  "90814": "31080",
  "90815": "31080",
  "91001": "31080",
  "91006": "31080",
  "91007": "31080",
  "91008": "31080",
  "91010": "31080",
  "91011": "31080",
  "91016": "31080",
  "91020": "31080",
  "91024": "31080",
  "91030": "31080",
  "91040": "31080",
  "91042": "31080",
  "91101": "31080",
  "91103": "31080",
  "91104": "31080",
  "91105": "31080",
  "91106": "31080",
  "91107": "31080",
  "91108": "31080",
  "91201": "31080",
  "91202": "31080",
  "91203": "31080",
  "91204": "31080",
  "91205": "31080",
  "91206": "31080",
  "91207": "31080",
  "91208": "31080",
  "91210": "31080",
  "91214": "31080",
  "91301": "31080",
  "91302": "31080",
  "91303": "31080",
  "91304": "31080",
  "91306": "31080",
  "91307": "31080",
  "91311": "31080",
  "91316": "31080",
  "91320": "37100",
  "91321": "31080",
  "91324": "31080",
  "91325": "31080",
  "91326": "31080",
  "91331": "31080",
  "91335": "31080",
  "91340": "31080",
  "91342": "31080",
  "91343": "31080",
  "91344": "31080",
  "91345": "31080",
  "91350": "31080",
  "91351": "31080",
  "91352": "31080",
  "91354": "31080",
  "91355": "31080",
  "91356": "31080",
  "91360": "37100",
  "91361": "37100",
  "91362": "37100",
  "91364": "31080",
  "91367": "31080",
  "91377": "37100",
  "91381": "31080",
  "91384": "31080",
  "91387": "31080",
  "91390": "31080",
  "91401": "31080",
  "91402": "31080",
  "91403": "31080",
  "91405": "31080",
  "91406": "31080",
  "91411": "31080",
  "91423": "31080",
  "91436": "31080",
  "91501": "31080",
  "91502": "31080",
  "91504": "31080",
  "91505": "31080",
  "91506": "31080",
  "91601": "31080",
  "91602": "31080",
  "91604": "31080",
  "91605": "31080",
  "91606": "31080",
  "91607": "31080",
  "91701": "40140",
  "91702": "31080",
  "91706": "31080",
  "91708": "40140",
  "91709": "40140",
  "91710": "40140",
  "91711": "31080",
  "91722": "31080",
  "91723": "31080",
  "91724": "31080",
  "91730": "40140",
  "91731": "31080",
  "91732": "31080",
  "91733": "31080",
  "91737": "40140",
  "91739": "40140",
  "91740": "31080",
  "91741": "31080",
  "91744": "31080",
  "91745": "31080",
  "91746": "31080",
  "91748": "31080",
  "91750": "31080",
  "91752": "40140",
  "91754": "31080",
  "91755": "31080",
  "91759": "40140",
  "91761": "40140",
  "91762": "40140",
  "91763": "40140",
  "91764": "40140",
  "91765": "31080",
  "91766": "31080",
  "91767": "31080",
  "91768": "31080",
  "91770": "31080",
  "91773": "31080",
  "91775": "31080",
  "91776": "31080",
  "91780": "31080",
  "91784": "40140",
  "91786": "40140",
  "91789": "31080",
  "91790": "31080",
  "91791": "31080",
  "91792": "31080",
  "91801": "31080",
  "91803": "31080",
  "91901": "41740",
  "91902": "41740",
  "91905": "41740",
  "91906": "41740",
  "91910": "41740",
  "91911": "41740",
  "91913": "41740",
  "91914": "41740",
  "91915": "41740",
  "91916": "41740",
  "91917": "41740",
  "91932": "41740",
  "91934": "41740",
  "91935": "41740",
  "91941": "41740",
  "91942": "41740",
  "91945": "41740",
  "91948": "41740",
  "91950": "41740",
  "91962": "41740",
  "91963": "41740",
  "91977": "41740",
  "91978": "41740",
  "92003": "41740",
  "92004": "20940",
  "92007": "41740",
  "92008": "41740",
  "92009": "41740",
  "92010": "41740",
  "92011": "41740",
  "92014": "41740",
  "92019": "41740",
  "92020": "41740",
  "92021": "41740",
  "92024": "41740",
  "92025": "41740",
  "92026": "41740",
  "92027": "41740",
  "92028": "41740",
  "92029": "41740",
  "92036": "41740",
  "92037": "41740",
  "92040": "41740",
  "92054": "41740",
  "92056": "41740",
  "92057": "41740",
  "92058": "41740",
  "92059": "41740",
  "92060": "41740",
  "92061": "41740",
  "92064": "41740",
  "92065": "41740",
  "92066": "41740",
  "92067": "41740",
  "92069": "41740",
  "92070": "41740",
  "92071": "41740",
  "92075": "41740",
  "92078": "41740",
  "92081": "41740",
  "92082": "41740",
  "92083": "41740",
  "92084": "41740",
  "92086": "41740",
  "92091": "41740",
  "92101": "41740",
  "92102": "41740",
  "92103": "41740",
  "92104": "41740",
  "92105": "41740",
  "92106": "41740",
  "92107": "41740",
  "92108": "41740",
  "92109": "41740",
  "92110": "41740",
  "92111": "41740",
  "92113": "41740",
  "92114": "41740",
  "92115": "41740",
  "92116": "41740",
  "92117": "41740",
  "92118": "41740",
  "92119": "41740",
  "92120": "41740",
  "92121": "41740",
  "92122": "41740",
  "92123": "41740",
  "92124": "41740",
  "92126": "41740",
  "92127": "41740",
  "92128": "41740",
  "92129": "41740",
  "92130": "41740",
  "92131": "41740",
  "92139": "41740",
  "92154": "41740",
  "92173": "41740",
  "92201": "40140",
  "92203": "40140",
  "92210": "40140",
  "92211": "40140",
  "92220": "40140",
  "92223": "40140",
  "92225": "40140",
  "92227": "20940",
  "92230": "40140",
  "92231": "20940",
  "92233": "20940",
  "92234": "40140",
  "92236": "40140",
  "92239": "40140",
  "92240": "40140",
  "92241": "40140",
  "92242": "40140",
  "92243": "20940",
  "92249": "20940",
  "92250": "20940",
  "92251": "20940",
  "92252": "40140",
  "92253": "40140",
  "92254": "40140",
  "92256": "40140",
  "92258": "40140",
  "92260": "40140",
  "92262": "40140",
  "92264": "40140",
  "92268": "40140",
  "92270": "40140",
  "92274": "40140",
  "92276": "40140",
  "92277": "40140",
  "92282": "40140",
  "92284": "40140",
  "92285": "40140",
  "92301": "40140",
  "92305": "40140",
  "92307": "40140",
  "92308": "40140",
  "92311": "40140",
  "92313": "40140",
  "92314": "40140",
  "92315": "40140",
  "92316": "40140",
  "92317": "40140",
  "92320": "40140",
  "92321": "40140",
  "92322": "40140",
  "92324": "40140",
  "92325": "40140",
  "92327": "40140",
  "92333": "40140",
  "92335": "40140",
  "92336": "40140",
  "92337": "40140",
  "92339": "40140",
  "92341": "40140",
  "92342": "40140",
  "92344": "40140",
  "92345": "40140",
  "92346": "40140",
  "92347": "40140",
  "92352": "40140",
  "92354": "40140",
  "92356": "40140",
  "92358": "40140",
  "92359": "40140",
  "92363": "40140",
  "92365": "40140",
  "92368": "40140",
  "92371": "40140",
  "92372": "40140",
  "92373": "40140",
  "92374": "40140",
  "92376": "40140",
  "92377": "40140",
  "92378": "40140",
  "92382": "40140",
  "92385": "40140",
  "92386": "40140",
  "92391": "40140",
  "92392": "40140",
  "92394": "40140",
  "92395": "40140",
  "92397": "40140",
  "92398": "40140",
  "92399": "40140",
  "92401": "40140",
  "92404": "40140",
  "92405": "40140",
  "92407": "40140",
  "92408": "40140",
  "92410": "40140",
  "92411": "40140",
  "92501": "40140",
  "92503": "40140",
  "92504": "40140",
  "92505": "40140",
  "92506": "40140",
  "92507": "40140",
  "92508": "40140",
  "92509": "40140",
  "92530": "40140",
  "92532": "40140",
  "92536": "40140",
  "92539": "40140",
  "92543": "40140",
  "92544": "40140",
  "92545": "40140",
  "92548": "40140",
  "92549": "40140",
  "92551": "40140",
  "92553": "40140",
  "92555": "40140",
  "92557": "40140",
  "92561": "40140",
  "92562": "40140",
  "92563": "40140",
  "92567": "40140",
  "92570": "40140",
  "92571": "40140",
  "92582": "40140",
  "92583": "40140",
  "92584": "40140",
  "92585": "40140",
  "92586": "40140",
  "92587": "40140",
  "92590": "40140",
  "92591": "40140",
  "92592": "40140",
  "92595": "40140",
  "92596": "40140",
  "92602": "31080",
  "92603": "31080",
  "92604": "31080",
  "92606": "31080",
  "92610": "31080",
  "92612": "31080",
  "92614": "31080",
  "92617": "31080",
  "92618": "31080",
  "92620": "31080",
  "92624": "31080",
  "92625": "31080",
  "92626": "31080",
  "92627": "31080",
  "92629": "31080",
  "92630": "31080",
  "92637": "31080",
  "92646": "31080",
  "92647": "31080",
  "92648": "31080",
  "92649": "31080",
  "92651": "31080",
  "92653": "31080",
  "92655": "31080",
  "92656": "31080",
  "92657": "31080",
  "92660": "31080",
  "92661": "31080",
  "92662": "31080",
  "92663": "31080",
  "92672": "31080",
  "92673": "31080",
  "92675": "31080",
  "92676": "31080",
  "92677": "31080",
  "92679": "31080",
  "92683": "31080",
  "92688": "31080",
  "92691": "31080",
  "92692": "31080",
  "92694": "31080",
  "92701": "31080",
  "92703": "31080",
  "92704": "31080",
  "92705": "31080",
  "92706": "31080",
  "92707": "31080",
  "92708": "31080",
  "92780": "31080",
  "92782": "31080",
  "92801": "31080",
  "92802": "31080",
  "92804": "31080",
  "92805": "31080",
  "92806": "31080",
  "92807": "31080",
  "92808": "31080",
  "92821": "31080",
  "92823": "31080",
  "92831": "31080",
  "92832": "31080",
  "92833": "31080",
  "92835": "31080",
  "92840": "31080",
  "92841": "31080",
  "92843": "31080",
  "92844": "31080",
  "92845": "31080",
  "92860": "40140",
  "92861": "31080",
  "92865": "31080",
  "92866": "31080",
  "92867": "31080",
  "92868": "31080",
  "92869": "31080",
  "92870": "31080",
  "92879": "40140",
  "92880": "40140",
  "92881": "40140",
  "92882": "40140",
  "92883": "40140",
  "92886": "31080",
  "92887": "31080",
  "93001": "37100",
  "93003": "37100",
  "93004": "37100",
  "93010": "37100",
  "93012": "37100",
  "93013": "42200",
  "93015": "37100",
  "93021": "37100",
  "93022": "37100",
  "93023": "37100",
  "93030": "37100",
  "93033": "37100",
  "93035": "37100",
  "93036": "37100",
  "93040": "37100",
  "93041": "37100",
  "93060": "37100",
  "93063": "37100",
  "93065": "37100",
  "93066": "37100",
  "93067": "42200",
  "93101": "42200",
  "93103": "42200",
  "93105": "42200",
  "93108": "42200",
  "93109": "42200",
  "93110": "42200",
  "93111": "42200",
  "93117": "42200",
  "93201": "47300",
  "93202": "25260",
  "93203": "12540",
  "93204": "25260",
  "93205": "12540",
  "93206": "12540",
  "93210": "23420",
  "93212": "25260",
  "93215": "12540",
  "93218": "47300",
  "93219": "47300",
  "93221": "47300",
  "93222": "12540",
  "93223": "47300",
  "93224": "12540",
  "93225": "12540",
  "93226": "12540",
  "93227": "47300",
  "93230": "25260",
  "93234": "23420",
  "93235": "47300",
  "93238": "12540",
  "93239": "25260",
  "93240": "12540",
  "93241": "12540",
  "93242": "23420",
  "93243": "12540",
  "93244": "47300",
  "93245": "25260",
  "93247": "47300",
  "93249": "12540",
  "93250": "12540",
  "93252": "12540",
  "93254": "42020",
  "93255": "12540",
  "93256": "47300",
  "93257": "47300",
  "93261": "47300",
  "93263": "12540",
  "93265": "47300",
  "93266": "25260",
  "93267": "47300",
  "93268": "12540",
  "93270": "47300",
  "93271": "47300",
  "93272": "47300",
  "93274": "47300",
  "93277": "47300",
  "93280": "12540",
  "93283": "12540",
  "93285": "12540",
  "93286": "47300",
  "93291": "47300",
  "93292": "47300",
  "93301": "12540",
  "93304": "12540",
  "93305": "12540",
  "93306": "12540",
  "93307": "12540",
  "93308": "12540",
  "93309": "12540",
  "93311": "12540",
  "93312": "12540",
  "93313": "12540",
  "93314": "12540",
  "93401": "42020",
  "93402": "42020",
  "93405": "42020",
  "93420": "42020",
  "93422": "42020",
  "93424": "42020",
  "93426": "42020",
  "93427": "42200",
  "93428": "42020",
  "93430": "42020",
  "93432": "42020",
  "93433": "42020",
  "93434": "42200",
  "93436": "42200",
  "93440": "42200",
  "93441": "42200",
  "93442": "42020",
  "93444": "42020",
  "93445": "42020",
  "93446": "42020",
  "93449": "42020",
  "93450": "41500",
  "93451": "41500",
  "93452": "42020",
  "93453": "42020",
  "93454": "42200",
  "93455": "42200",
  "93458": "42200",
  "93460": "42200",
  "93461": "42020",
  "93463": "42200",
  "93465": "42020",
  "93501": "12540",
  "93505": "12540",
  "93510": "31080",
  "93516": "12540",
  "93518": "12540",
  "93523": "12540",
  "93527": "12540",
  "93531": "12540",
  "93532": "31080",
  "93534": "31080",
  "93535": "31080",
  "93536": "31080",
  "93543": "31080",
  "93544": "31080",
  "93550": "31080",
  "93551": "31080",
  "93552": "31080",
  "93553": "31080",
  "93555": "12540",
  "93560": "12540",
  "93561": "12540",
  "93562": "40140",
  "93591": "31080",
  "93601": "31460",
  "93602": "23420",
  "93604": "31460",
  "93608": "23420",
  "93609": "23420",
  "93610": "31460",
  "93611": "23420",
  "93612": "23420",
  "93614": "31460",
  "93615": "47300",
  "93616": "23420",
  "93618": "47300",
  "93619": "23420",
  "93620": "32900",
  "93621": "23420",
  "93622": "23420",
  "93625": "23420",
  "93626": "23420",
  "93628": "23420",
  "93630": "23420",
  "93631": "23420",
  "93634": "23420",
  "93635": "32900",
  "93636": "31460",
  "93637": "31460",
  "93638": "31460",
  "93640": "23420",
  "93641": "23420",
  "93643": "31460",
  "93644": "31460",
  "93645": "31460",
  "93646": "23420",
  "93647": "47300",
  "93648": "23420",
  "93650": "23420",
  "93651": "23420",
  "93653": "31460",
  "93654": "23420",
  "93656": "23420",
  "93657": "23420",
  "93660": "23420",
  "93662": "23420",
  "93664": "23420",
  "93665": "32900",
  "93667": "23420",
  "93668": "23420",
  "93669": "31460",
  "93675": "23420",
  "93701": "23420",
  "93702": "23420",
  "93703": "23420",
  "93704": "23420",
  "93705": "23420",
  "93706": "23420",
  "93710": "23420",
  "93711": "23420",
  "93720": "23420",
  "93721": "23420",
  "93722": "23420",
  "93723": "23420",
  "93725": "23420",
  "93726": "23420",
  "93727": "23420",
  "93728": "23420",
  "93730": "23420",
  "93737": "23420",
  "93901": "41500",
  "93905": "41500",
  "93906": "41500",
  "93907": "41500",
  "93908": "41500",
  "93920": "41500",
  "93921": "41500",
  "93923": "41500",
  "93924": "41500",
  "93925": "41500",
  "93926": "41500",
  "93927": "41500",
  "93930": "41500",
  "93932": "41500",
  "93933": "41500",
  "93940": "41500",
  "93950": "41500",
  "93953": "41500",
  "93955": "41500",
  "93960": "41500",
  "93962": "41500",
  "94002": "41860",
  "94005": "41860",
  "94010": "41860",
  "94014": "41860",
  "94015": "41860",
  "94018": "41860",
  "94019": "41860",
  "94020": "41860",
  "94022": "41940",
  "94024": "41940",
  "94025": "41860",
  "94027": "41860",
  "94028": "41860",
  "94030": "41860",
  "94037": "41860",
  "94038": "41860",
  "94040": "41940",
  "94041": "41940",
  "94043": "41940",
  "94044": "41860",
  "94060": "41860",
  "94061": "41860",
  "94062": "41860",
  "94063": "41860",
  "94065": "41860",
  "94066": "41860",
  "94070": "41860",
  "94080": "41860",
  "94085": "41940",
  "94086": "41940",
  "94087": "41940",
  "94089": "41940",
  "94102": "41860",
  "94103": "41860",
  "94104": "41860",
  "94105": "41860",
  "94107": "41860",
  "94108": "41860",
  "94109": "41860",
  "94110": "41860",
  "94111": "41860",
  "94112": "41860",
  "94114": "41860",
  "94115": "41860",
  "94116": "41860",
  "94117": "41860",
  "94118": "41860",
  "94121": "41860",
  "94122": "41860",
  "94123": "41860",
  "94124": "41860",
  "94127": "41860",
  "94129": "41860",
  "94131": "41860",
  "94132": "41860",
  "94133": "41860",
  "94134": "41860",
  "94158": "41860",
  "94301": "41940",
  "94303": "41860",
  "94304": "41940",
  "94305": "41940",
  "94306": "41940",
  "94401": "41860",
  "94402": "41860",
  "94403": "41860",
  "94404": "41860",
  "94501": "41860",
  "94502": "41860",
  "94503": "34900",
  "94505": "41860",
  "94506": "41860",
  "94507": "41860",
  "94508": "34900",
  "94509": "41860",
  "94510": "46700",
  "94511": "41860",
  "94513": "41860",
  "94514": "41860",
  "94515": "34900",
  "94517": "41860",
  "94518": "41860",
  "94519": "41860",
  "94520": "41860",
  "94521": "41860",
  "94523": "41860",
  "94525": "41860",
  "94526": "41860",
  "94528": "41860",
  "94530": "41860",
  "94531": "41860",
  "94533": "46700",
  "94534": "46700",
  "94536": "41860",
  "94538": "41860",
  "94539": "41860",
  "94541": "41860",
  "94542": "41860",
  "94544": "41860",
  "94545": "41860",
  "94546": "41860",
  "94547": "41860",
  "94549": "41860",
  "94550": "41860",
  "94551": "41860",
  "94552": "41860",
  "94553": "41860",
  "94555": "41860",
  "94556": "41860",
  "94558": "34900",
  "94559": "34900",
  "94560": "41860",
  "94561": "41860",
  "94563": "41860",
  "94564": "41860",
  "94565": "41860",
  "94566": "41860",
  "94567": "34900",
  "94568": "41860",
  "94569": "41860",
  "94571": "46700",
  "94572": "41860",
  "94574": "34900",
  "94577": "41860",
  "94578": "41860",
  "94579": "41860",
  "94580": "41860",
  "94582": "41860",
  "94583": "41860",
  "94585": "46700",
  "94586": "41860",
  "94587": "41860",
  "94588": "41860",
  "94589": "46700",
  "94590": "46700",
  "94591": "46700",
  "94592": "46700",
  "94595": "41860",
  "94596": "41860",
  "94597": "41860",
  "94598": "41860",
  "94599": "34900",
  "94601": "41860",
  "94602": "41860",
  "94603": "41860",
  "94605": "41860",
  "94606": "41860",
  "94607": "41860",
  "94608": "41860",
  "94609": "41860",
  "94610": "41860",
  "94611": "41860",
  "94612": "41860",
  "94618": "41860",
  "94619": "41860",
  "94621": "41860",
  "94702": "41860",
  "94703": "41860",
  "94704": "41860",
  "94705": "41860",
  "94706": "41860",
  "94707": "41860",
  "94708": "41860",
  "94709": "41860",
  "94710": "41860",
  "94801": "41860",
  "94803": "41860",
  "94804": "41860",
  "94805": "41860",
  "94806": "41860",
  "94901": "41860",
  "94903": "41860",
  "94904": "41860",
  "94920": "41860",
  "94923": "42220",
  "94924": "41860",
  "94925": "41860",
  "94928": "42220",
  "94929": "41860",
  "94930": "41860",
  "94931": "42220",
  "94933": "41860",
  "94937": "41860",
  "94938": "41860",
  "94939": "41860",
  "94940": "41860",
  "94941": "41860",
  "94945": "41860",
  "94946": "41860",
  "94947": "41860",
  "94949": "41860",
  "94951": "42220",
  "94952": "42220",
  "94954": "42220",
  "94956": "41860",
  "94957": "41860",
  "94960": "41860",
  "94963": "41860",
  "94965": "41860",
  "94970": "41860",
  "94971": "41860",
  "94973": "41860",
  "95002": "41940",
  "95003": "42100",
  "95004": "41940",
  "95005": "42100",
  "95006": "42100",
  "95007": "42100",
  "95008": "41940",
  "95010": "42100",
  "95012": "41500",
  "95014": "41940",
  "95017": "42100",
  "95018": "42100",
  "95019": "42100",
  "95020": "41940",
  "95023": "41940",
  "95030": "41940",
  "95032": "41940",
  "95033": "41940",
  "95035": "41940",
  "95037": "41940",
  "95039": "41500",
  "95041": "42100",
  "95045": "41940",
  "95046": "41940",
  "95050": "41940",
  "95051": "41940",
  "95054": "41940",
  "95060": "42100",
  "95062": "42100",
  "95064": "42100",
  "95065": "42100",
  "95066": "42100",
  "95070": "41940",
  "95073": "42100",
  "95076": "42100",
  "95110": "41940",
  "95111": "41940",
  "95112": "41940",
  "95113": "41940",
  "95116": "41940",
  "95117": "41940",
  "95118": "41940",
  "95119": "41940",
  "95120": "41940",
  "95121": "41940",
  "95122": "41940",
  "95123": "41940",
  "95124": "41940",
  "95125": "41940",
  "95126": "41940",
  "95127": "41940",
  "95128": "41940",
  "95129": "41940",
  "95130": "41940",
  "95131": "41940",
  "95132": "41940",
  "95133": "41940",
  "95134": "41940",
  "95135": "41940",
  "95136": "41940",
  "95138": "41940",
  "95139": "41940",
  "95140": "41940",
  "95148": "41940",
  "95202": "44700",
  "95203": "44700",
  "95204": "44700",
  "95205": "44700",
  "95206": "44700",
  "95207": "44700",
  "95209": "44700",
  "95210": "44700",
  "95212": "44700",
  "95215": "44700",
  "95219": "44700",
  "95220": "44700",
  "95227": "44700",
  "95230": "33700",
  "95231": "44700",
  "95236": "44700",
  "95237": "44700",
  "95240": "44700",
  "95242": "44700",
  "95258": "44700",
  "95301": "32900",
  "95303": "32900",
  "95304": "44700",
  "95307": "33700",
  "95310": "43760",
  "95313": "33700",
  "95315": "32900",
  "95316": "33700",
  "95319": "33700",
  "95320": "44700",
  "95321": "43760",
  "95322": "32900",
  "95323": "33700",
  "95324": "32900",
  "95326": "33700",
  "95327": "43760",
  "95328": "33700",
  "95329": "33700",
  "95330": "44700",
  "95333": "32900",
  "95334": "32900",
  "95335": "43760",
  "95336": "44700",
  "95337": "44700",
  "95340": "32900",
  "95341": "32900",
  "95346": "43760",
  "95348": "32900",
  "95350": "33700",
  "95351": "33700",
  "95354": "33700",
  "95355": "33700",
  "95356": "33700",
  "95357": "33700",
  "95358": "33700",
  "95360": "33700",
  "95361": "33700",
  "95363": "33700",
  "95365": "32900",
  "95366": "44700",
  "95367": "33700",
  "95368": "33700",
  "95369": "32900",
  "95370": "43760",
  "95372": "43760",
  "95374": "32900",
  "95375": "43760",
  "95376": "44700",
  "95377": "44700",
  "95379": "43760",
  "95380": "33700",
  "95382": "33700",
  "95383": "43760",
  "95386": "33700",
  "95388": "32900",
  "95391": "41860",
  "95401": "42220",
  "95403": "42220",
  "95404": "42220",
  "95405": "42220",
  "95407": "42220",
  "95409": "42220",
  "95410": "46380",
  "95412": "42220",
  "95419": "42220",
  "95421": "42220",
  "95422": "17340",
  "95423": "17340",
  "95424": "17340",
  "95425": "42220",
  "95426": "17340",
  "95428": "46380",
  "95433": "42220",
  "95436": "42220",
  "95437": "46380",
  "95439": "42220",
  "95441": "42220",
  "95442": "42220",
  "95443": "17340",
  "95444": "42220",
  "95445": "46380",
  "95446": "42220",
  "95448": "42220",
  "95449": "46380",
  "95450": "42220",
  "95451": "17340",
  "95452": "42220",
  "95453": "17340",
  "95454": "46380",
  "95456": "46380",
  "95457": "17340",
  "95458": "17340",
  "95459": "46380",
  "95460": "46380",
  "95461": "17340",
  "95462": "42220",
  "95464": "17340",
  "95465": "42220",
  "95467": "17340",
  "95469": "46380",
  "95470": "46380",
  "95472": "42220",
  "95476": "42220",
  "95482": "46380",
  "95485": "17340",
  "95490": "46380",
  "95492": "42220",
  "95497": "42220",
  "95501": "21700",
  "95503": "21700",
  "95519": "21700",
  "95521": "21700",
  "95524": "21700",
  "95525": "21700",
  "95526": "21700",
  "95527": "21700",
  "95528": "21700",
  "95531": "18860",
  "95536": "21700",
  "95540": "21700",
  "95542": "21700",
  "95543": "18860",
  "95547": "21700",
  "95549": "21700",
  "95551": "21700",
  "95553": "21700",
  "95560": "21700",
  "95562": "21700",
  "95567": "18860",
  "95570": "21700",
  "95573": "21700",
  "95589": "21700",
  "95602": "40900",
  "95603": "40900",
  "95605": "40900",
  "95607": "40900",
  "95608": "40900",
  "95610": "40900",
  "95612": "40900",
  "95614": "40900",
  "95615": "40900",
  "95616": "40900",
  "95618": "40900",
  "95619": "40900",
  "95620": "46700",
  "95621": "40900",
  "95623": "40900",
  "95624": "40900",
  "95626": "40900",
  "95627": "40900",
  "95628": "40900",
  "95630": "40900",
  "95631": "40900",
  "95632": "40900",
  "95633": "40900",
  "95634": "40900",
  "95635": "40900",
  "95636": "40900",
  "95637": "40900",
  "95638": "40900",
  "95641": "40900",
  "95645": "40900",
  "95648": "40900",
  "95650": "40900",
  "95651": "40900",
  "95653": "40900",
  "95655": "40900",
  "95658": "40900",
  "95659": "49700",
  "95660": "40900",
  "95661": "40900",
  "95662": "40900",
  "95663": "40900",
  "95664": "40900",
  "95667": "40900",
  "95668": "40900",
  "95670": "40900",
  "95672": "40900",
  "95673": "40900",
  "95674": "49700",
  "95677": "40900",
  "95678": "40900",
  "95681": "40900",
  "95682": "40900",
  "95683": "40900",
  "95684": "40900",
  "95686": "44700",
  "95687": "46700",
  "95688": "46700",
  "95690": "40900",
  "95691": "40900",
  "95692": "49700",
  "95693": "40900",
  "95694": "40900",
  "95695": "40900",
  "95697": "40900",
  "95701": "40900",
  "95703": "40900",
  "95709": "40900",
  "95713": "40900",
  "95714": "40900",
  "95715": "40900",
  "95720": "40900",
  "95721": "40900",
  "95722": "40900",
  "95724": "46020",
  "95726": "40900",
  "95728": "46020",
  "95735": "40900",
  "95742": "40900",
  "95746": "40900",
  "95747": "40900",
  "95757": "40900",
  "95758": "40900",
  "95762": "40900",
  "95765": "40900",
  "95776": "40900",
  "95811": "40900",
  "95814": "40900",
  "95815": "40900",
  "95816": "40900",
  "95817": "40900",
  "95818": "40900",
  "95819": "40900",
  "95820": "40900",
  "95821": "40900",
  "95822": "40900",
  "95823": "40900",
  "95824": "40900",
  "95825": "40900",
  "95826": "40900",
  "95827": "40900",
  "95828": "40900",
  "95829": "40900",
  "95830": "40900",
  "95831": "40900",
  "95832": "40900",
  "95833": "40900",
  "95834": "40900",
  "95835": "40900",
  "95837": "40900",
  "95838": "40900",
  "95841": "40900",
  "95842": "40900",
  "95843": "40900",
  "95864": "40900",
  "95901": "49700",
  "95914": "17020",
  "95916": "17020",
  "95917": "17020",
  "95918": "49700",
  "95919": "49700",
  "95922": "49700",
  "95925": "49700",
  "95926": "17020",
  "95928": "17020",
  "95935": "49700",
  "95937": "40900",
  "95938": "17020",
  "95941": "17020",
  "95942": "17020",
  "95945": "46020",
  "95946": "46020",
  "95948": "17020",
  "95949": "46020",
  "95953": "49700",
  "95954": "17020",
  "95957": "49700",
  "95959": "46020",
  "95960": "46020",
  "95961": "49700",
  "95962": "49700",
  "95965": "17020",
  "95966": "17020",
  "95968": "17020",
  "95969": "17020",
  "95973": "17020",
  "95975": "46020",
  "95977": "46020",
  "95978": "17020",
  "95982": "49700",
  "95991": "49700",
  "95993": "49700",
  "96001": "39820",
  "96002": "39820",
  "96003": "39820",
  "96006": "45000",
  "96007": "39820",
  "96008": "39820",
  "96009": "45000",
  "96013": "39820",
  "96016": "39820",
  "96019": "39820",
  "96021": "39780",
  "96022": "39780",
  "96028": "39820",
  "96033": "39820",
  "96035": "39780",
  "96047": "39820",
  "96051": "39820",
  "96055": "39780",
  "96056": "45000",
  "96059": "39780",
  "96062": "39820",
  "96065": "39820",
  "96069": "39820",
  "96071": "39820",
  "96073": "39820",
  "96075": "39780",
  "96080": "39780",
  "96084": "39820",
  "96087": "39820",
  "96088": "39820",
  "96096": "39820",
  "96105": "45000",
  "96109": "45000",
  "96113": "45000",
  "96114": "45000",
  "96117": "45000",
  "96121": "45000",
  "96128": "45000",
  "96130": "45000",
  "96140": "40900",
  "96141": "40900",
  "96142": "40900",
  "96143": "40900",
  "96145": "40900",
  "96146": "40900",
  "96148": "40900",
  "96150": "40900",
  "96161": "46020",
  "96701": "46520",
  "96703": "28180",
  "96704": "25900",
  "96705": "28180",
  "96706": "46520",
  "96707": "46520",
  "96708": "27980",
  "96710": "25900",
  "96712": "46520",
  "96713": "27980",
  "96714": "28180",
  "96716": "28180",
  "96717": "46520",
  "96719": "25900",
  "96720": "25900",
  "96722": "28180",
  "96725": "25900",
  "96727": "25900",
  "96728": "25900",
  "96729": "27980",
  "96730": "46520",
  "96731": "46520",
  "96732": "27980",
  "96734": "46520",
  "96738": "25900",
  "96740": "25900",
  "96741": "28180",
  "96743": "25900",
  "96744": "46520",
  "96746": "28180",
  "96748": "27980",
  "96749": "25900",
  "96750": "25900",
  "96752": "28180",
  "96753": "27980",
  "96754": "28180",
  "96755": "25900",
  "96756": "28180",
  "96757": "27980",
  "96760": "25900",
  "96761": "27980",
  "96762": "46520",
  "96763": "27980",
  "96764": "25900",
  "96765": "28180",
  "96766": "28180",
  "96768": "27980",
  "96770": "27980",
  "96771": "25900",
  "96772": "25900",
  "96776": "25900",
  "96777": "25900",
  "96778": "25900",
  "96779": "27980",
  "96780": "25900",
  "96781": "25900",
  "96782": "46520",
  "96783": "25900",
  "96785": "25900",
  "96786": "46520",
  "96789": "46520",
  "96790": "27980",
  "96791": "46520",
  "96792": "46520",
  "96793": "27980",
  "96795": "46520",
  "96796": "28180",
  "96797": "46520",
  "96813": "46520",
  "96814": "46520",
  "96815": "46520",
  "96816": "46520",
  "96817": "46520",
  "96818": "46520",
  "96819": "46520",
  "96821": "46520",
  "96822": "46520",
  "96825": "46520",
  "96826": "46520",
  "97002": "41420",
  "97003": "38900",
  "97004": "38900",
  "97005": "38900",
  "97006": "38900",
  "97007": "38900",
  "97008": "38900",
  "97009": "38900",
  "97011": "38900",
  "97013": "38900",
  "97014": "26220",
  "97015": "38900",
  "97016": "38900",
  "97017": "38900",
  "97018": "38900",
  "97019": "38900",
  "97020": "41420",
  "97021": "45520",
  "97022": "38900",
  "97023": "38900",
  "97024": "38900",
  "97026": "41420",
  "97027": "38900",
  "97028": "38900",
  "97030": "38900",
  "97031": "26220",
  "97032": "41420",
  "97034": "38900",
  "97035": "38900",
  "97037": "45520",
  "97038": "38900",
  "97040": "45520",
  "97041": "26220",
  "97042": "38900",
  "97045": "38900",
  "97048": "38900",
  "97049": "38900",
  "97051": "38900",
  "97053": "38900",
  "97054": "38900",
  "97055": "38900",
  "97056": "38900",
  "97058": "45520",
  "97060": "38900",
  "97062": "38900",
  "97063": "45520",
  "97064": "38900",
  "97067": "38900",
  "97068": "38900",
  "97070": "38900",
  "97071": "41420",
  "97078": "38900",
  "97080": "38900",
  "97086": "38900",
  "97089": "38900",
  "97101": "38900",
  "97102": "11820",
  "97103": "11820",
  "97106": "38900",
  "97110": "11820",
  "97111": "38900",
  "97113": "38900",
  "97114": "38900",
  "97115": "38900",
  "97116": "38900",
  "97117": "38900",
  "97119": "38900",
  "97121": "11820",
  "97123": "38900",
  "97124": "38900",
  "97127": "38900",
  "97128": "38900",
  "97132": "38900",
  "97133": "38900",
  "97137": "41420",
  "97138": "11820",
  "97140": "38900",
  "97146": "11820",
  "97148": "38900",
  "97201": "38900",
  "97202": "38900",
  "97203": "38900",
  "97205": "38900",
  "97206": "38900",
  "97209": "38900",
  "97210": "38900",
  "97211": "38900",
  "97212": "38900",
  "97213": "38900",
  "97214": "38900",
  "97215": "38900",
  "97216": "38900",
  "97217": "38900",
  "97218": "38900",
  "97219": "38900",
  "97220": "38900",
  "97221": "38900",
  "97222": "38900",
  "97223": "38900",
  "97224": "38900",
  "97225": "38900",
  "97227": "38900",
  "97229": "38900",
  "97230": "38900",
  "97231": "38900",
  "97232": "38900",
  "97233": "38900",
  "97236": "38900",
  "97239": "38900",
  "97266": "38900",
  "97267": "38900",
  "97301": "41420",
  "97302": "41420",
  "97303": "41420",
  "97304": "41420",
  "97305": "41420",
  "97306": "41420",
  "97317": "41420",
  "97321": "18700",
  "97322": "10540",
  "97324": "18700",
  "97325": "41420",
  "97326": "35440",
  "97327": "10540",
  "97330": "18700",
  "97333": "18700",
  "97338": "41420",
  "97341": "35440",
  "97342": "10540",
  "97343": "35440",
  "97344": "41420",
  "97345": "10540",
  "97346": "10540",
  "97347": "38900",
  "97348": "10540",
  "97351": "41420",
  "97352": "41420",
  "97355": "10540",
  "97357": "35440",
  "97358": "41420",
  "97360": "10540",
  "97361": "41420",
  "97362": "41420",
  "97364": "35440",
  "97365": "35440",
  "97366": "35440",
  "97367": "35440",
  "97368": "35440",
  "97369": "35440",
  "97370": "18700",
  "97371": "41420",
  "97374": "10540",
  "97375": "38900",
  "97376": "35440",
  "97377": "10540",
  "97378": "38900",
  "97380": "35440",
  "97381": "41420",
  "97383": "41420",
  "97385": "41420",
  "97386": "10540",
  "97388": "35440",
  "97389": "10540",
  "97390": "35440",
  "97391": "35440",
  "97392": "41420",
  "97394": "35440",
  "97396": "38900",
  "97401": "21660",
  "97402": "21660",
  "97403": "21660",
  "97404": "21660",
  "97405": "21660",
  "97408": "21660",
  "97411": "18300",
  "97412": "21660",
  "97413": "21660",
  "97415": "15060",
  "97417": "40700",
  "97419": "21660",
  "97420": "18300",
  "97423": "18300",
  "97424": "21660",
  "97426": "21660",
  "97431": "21660",
  "97432": "40700",
  "97434": "21660",
  "97435": "40700",
  "97436": "40700",
  "97437": "21660",
  "97438": "21660",
  "97439": "21660",
  "97441": "40700",
  "97442": "40700",
  "97443": "40700",
  "97444": "15060",
  "97446": "10540",
  "97448": "21660",
  "97449": "18300",
  "97450": "15060",
  "97452": "21660",
  "97453": "21660",
  "97454": "21660",
  "97455": "21660",
  "97456": "18700",
  "97457": "40700",
  "97458": "18300",
  "97459": "18300",
  "97461": "21660",
  "97462": "40700",
  "97463": "21660",
  "97465": "15060",
  "97466": "18300",
  "97467": "40700",
  "97469": "40700",
  "97470": "40700",
  "97471": "40700",
  "97476": "15060",
  "97477": "21660",
  "97478": "21660",
  "97479": "40700",
  "97480": "21660",
  "97487": "21660",
  "97488": "21660",
  "97489": "21660",
  "97492": "21660",
  "97493": "21660",
  "97495": "40700",
  "97496": "40700",
  "97497": "24420",
  "97498": "35440",
  "97499": "40700",
  "97501": "32780",
  "97502": "32780",
  "97503": "32780",
  "97504": "32780",
  "97520": "32780",
  "97522": "32780",
  "97523": "24420",
  "97524": "32780",
  "97525": "32780",
  "97526": "24420",
  "97527": "24420",
  "97530": "32780",
  "97532": "24420",
  "97535": "32780",
  "97536": "32780",
  "97537": "32780",
  "97538": "24420",
  "97539": "32780",
  "97540": "32780",
  "97541": "32780",
  "97543": "24420",
  "97544": "24420",
  "97601": "28900",
  "97603": "28900",
  "97621": "28900",
  "97622": "28900",
  "97623": "28900",
  "97624": "28900",
  "97627": "28900",
  "97632": "28900",
  "97633": "28900",
  "97634": "28900",
  "97639": "28900",
  "97701": "13460",
  "97702": "13460",
  "97703": "13460",
  "97707": "13460",
  "97733": "28900",
  "97737": "28900",
  "97739": "13460",
  "97753": "39260",
  "97754": "39260",
  "97756": "13460",
  "97759": "13460",
  "97760": "13460",
  "97801": "25840",
  "97810": "25840",
  "97813": "25840",
  "97818": "25840",
  "97824": "29260",
  "97826": "25840",
  "97827": "29260",
  "97836": "25840",
  "97838": "25840",
  "97844": "25840",
  "97850": "29260",
  "97862": "25840",
  "97867": "29260",
  "97868": "25840",
  "97875": "25840",
  "97876": "29260",
  "97882": "25840",
  "97883": "29260",
  "97886": "25840",
  "97901": "36620",
  "97913": "36620",
  "97914": "36620",
  "97918": "36620",
  "98001": "42660",
  "98002": "42660",
  "98003": "42660",
  "98004": "42660",
  "98005": "42660",
  "98006": "42660",
  "98007": "42660",
  "98008": "42660",
  "98010": "42660",
  "98011": "42660",
  "98012": "42660",
  "98014": "42660",
  "98019": "42660",
  "98020": "42660",
  "98021": "42660",
  "98022": "42660",
  "98023": "42660",
  "98024": "42660",
  "98026": "42660",
  "98027": "42660",
  "98028": "42660",
  "98029": "42660",
  "98030": "42660",
  "98031": "42660",
  "98032": "42660",
  "98033": "42660",
  "98034": "42660",
  "98036": "42660",
  "98037": "42660",
  "98038": "42660",
  "98039": "42660",
  "98040": "42660",
  "98042": "42660",
  "98043": "42660",
  "98045": "42660",
  "98047": "42660",
  "98050": "42660",
  "98051": "42660",
  "98052": "42660",
  "98053": "42660",
  "98055": "42660",
  "98056": "42660",
  "98057": "42660",
  "98058": "42660",
  "98059": "42660",
  "98065": "42660",
  "98068": "42660",
  "98070": "42660",
  "98072": "42660",
  "98074": "42660",
  "98075": "42660",
  "98077": "42660",
  "98087": "42660",
  "98092": "42660",
  "98101": "42660",
  "98102": "42660",
  "98103": "42660",
  "98104": "42660",
  "98105": "42660",
  "98106": "42660",
  "98107": "42660",
  "98108": "42660",
  "98109": "42660",
  "98110": "14740",
  "98112": "42660",
  "98115": "42660",
  "98116": "42660",
  "98117": "42660",
  "98118": "42660",
  "98119": "42660",
  "98121": "42660",
  "98122": "42660",
  "98125": "42660",
  "98126": "42660",
  "98133": "42660",
  "98134": "42660",
  "98136": "42660",
  "98144": "42660",
  "98146": "42660",
  "98148": "42660",
  "98155": "42660",
  "98164": "42660",
  "98166": "42660",
  "98168": "42660",
  "98177": "42660",
  "98178": "42660",
  "98188": "42660",
  "98198": "42660",
  "98199": "42660",
  "98201": "42660",
  "98203": "42660",
  "98204": "42660",
  "98206": "42660",
  "98208": "42660",
  "98220": "13380",
  "98221": "34580",
  "98223": "42660",
  "98224": "42660",
  "98225": "13380",
  "98226": "13380",
  "98229": "13380",
  "98230": "13380",
  "98232": "34580",
  "98233": "34580",
  "98236": "36020",
  "98237": "34580",
  "98239": "36020",
  "98240": "13380",
  "98241": "42660",
  "98244": "13380",
  "98247": "13380",
  "98248": "13380",
  "98249": "36020",
  "98251": "42660",
  "98252": "42660",
  "98253": "36020",
  "98256": "42660",
  "98257": "34580",
  "98258": "42660",
  "98260": "36020",
  "98262": "13380",
  "98264": "13380",
  "98266": "13380",
  "98267": "34580",
  "98270": "42660",
  "98271": "42660",
  "98272": "42660",
  "98273": "34580",
  "98274": "34580",
  "98275": "42660",
  "98276": "13380",
  "98277": "36020",
  "98281": "13380",
  "98282": "36020",
  "98283": "13380",
  "98284": "34580",
  "98288": "42660",
  "98290": "42660",
  "98292": "42660",
  "98294": "42660",
  "98295": "13380",
  "98296": "42660",
  "98303": "42660",
  "98304": "16500",
  "98310": "14740",
  "98311": "14740",
  "98312": "14740",
  "98321": "42660",
  "98323": "42660",
  "98326": "38820",
  "98327": "42660",
  "98328": "42660",
  "98329": "42660",
  "98331": "38820",
  "98332": "42660",
  "98333": "42660",
  "98335": "42660",
  "98337": "14740",
  "98338": "42660",
  "98340": "14740",
  "98342": "14740",
  "98345": "14740",
  "98346": "14740",
  "98349": "42660",
  "98351": "42660",
  "98354": "42660",
  "98356": "16500",
  "98359": "14740",
  "98360": "42660",
  "98361": "16500",
  "98362": "38820",
  "98363": "38820",
  "98366": "14740",
  "98367": "14740",
  "98370": "14740",
  "98371": "42660",
  "98372": "42660",
  "98373": "42660",
  "98374": "42660",
  "98375": "42660",
  "98377": "16500",
  "98380": "14740",
  "98382": "38820",
  "98383": "14740",
  "98385": "42660",
  "98387": "42660",
  "98388": "42660",
  "98390": "42660",
  "98391": "42660",
  "98392": "14740",
  "98394": "42660",
  "98396": "42660",
  "98402": "42660",
  "98403": "42660",
  "98404": "42660",
  "98405": "42660",
  "98406": "42660",
  "98407": "42660",
  "98408": "42660",
  "98409": "42660",
  "98418": "42660",
  "98422": "42660",
  "98424": "42660",
  "98443": "42660",
  "98444": "42660",
  "98445": "42660",
  "98446": "42660",
  "98465": "42660",
  "98466": "42660",
  "98467": "42660",
  "98498": "42660",
  "98499": "42660",
  "98501": "36500",
  "98502": "36500",
  "98503": "36500",
  "98506": "36500",
  "98512": "36500",
  "98513": "36500",
  "98516": "36500",
  "98520": "10140",
  "98524": "43220",
  "98528": "43220",
  "98530": "36500",
  "98531": "16500",
  "98532": "16500",
  "98535": "10140",
  "98537": "10140",
  "98541": "10140",
  "98546": "43220",
  "98548": "43220",
  "98550": "10140",
  "98555": "43220",
  "98557": "10140",
  "98563": "10140",
  "98564": "16500",
  "98568": "10140",
  "98569": "10140",
  "98570": "16500",
  "98571": "10140",
  "98572": "16500",
  "98576": "36500",
  "98579": "36500",
  "98580": "42660",
  "98581": "31020",
  "98584": "43220",
  "98585": "16500",
  "98588": "43220",
  "98589": "36500",
  "98591": "16500",
  "98592": "43220",
  "98593": "16500",
  "98595": "10140",
  "98596": "16500",
  "98597": "36500",
  "98601": "38900",
  "98603": "31020",
  "98604": "38900",
  "98605": "38900",
  "98606": "38900",
  "98607": "38900",
  "98610": "38900",
  "98611": "31020",
  "98616": "31020",
  "98625": "31020",
  "98626": "31020",
  "98629": "38900",
  "98632": "31020",
  "98639": "38900",
  "98642": "38900",
  "98645": "31020",
  "98648": "38900",
  "98649": "31020",
  "98651": "38900",
  "98660": "38900",
  "98661": "38900",
  "98662": "38900",
  "98663": "38900",
  "98664": "38900",
  "98665": "38900",
  "98671": "38900",
  "98674": "31020",
  "98675": "38900",
  "98682": "38900",
  "98683": "38900",
  "98684": "38900",
  "98685": "38900",
  "98686": "38900",
  "98801": "48300",
  "98802": "48300",
  "98813": "48300",
  "98815": "48300",
  "98816": "48300",
  "98822": "48300",
  "98823": "34180",
  "98826": "48300",
  "98828": "48300",
  "98830": "48300",
  "98831": "48300",
  "98837": "34180",
  "98843": "48300",
  "98847": "48300",
  "98848": "34180",
  "98850": "48300",
  "98851": "34180",
  "98857": "34180",
  "98858": "48300",
  "98901": "49420",
  "98902": "49420",
  "98903": "49420",
  "98908": "49420",
  "98922": "21260",
  "98923": "49420",
  "98925": "21260",
  "98926": "21260",
  "98930": "49420",
  "98932": "49420",
  "98933": "49420",
  "98934": "21260",
  "98935": "49420",
  "98936": "49420",
  "98937": "49420",
  "98938": "49420",
  "98940": "21260",
  "98941": "21260",
  "98942": "49420",
  "98943": "21260",
  "98944": "49420",
  "98946": "21260",
  "98947": "49420",
  "98948": "49420",
  "98951": "49420",
  "98952": "49420",
  "98953": "49420",
  "99001": "44060",
  "99003": "44060",
  "99004": "44060",
  "99005": "44060",
  "99006": "44060",
  "99009": "44060",
  "99012": "44060",
  "99013": "44060",
  "99016": "44060",
  "99018": "44060",
  "99019": "44060",
  "99021": "44060",
  "99022": "44060",
  "99023": "44060",
  "99025": "44060",
  "99026": "44060",
  "99027": "44060",
  "99029": "44060",
  "99030": "44060",
  "99031": "44060",
  "99033": "39420",
  "99036": "44060",
  "99037": "44060",
  "99101": "44060",
  "99109": "44060",
  "99110": "44060",
  "99111": "39420",
  "99113": "39420",
  "99114": "44060",
  "99115": "34180",
  "99123": "34180",
  "99125": "39420",
  "99126": "44060",
  "99128": "39420",
  "99130": "39420",
  "99133": "34180",
  "99135": "34180",
  "99143": "39420",
  "99148": "44060",
  "99149": "39420",
  "99157": "44060",
  "99158": "39420",
  "99161": "39420",
  "99163": "39420",
  "99167": "44060",
  "99169": "36830",
  "99170": "39420",
  "99171": "39420",
  "99173": "44060",
  "99179": "39420",
  "99181": "44060",
  "99201": "44060",
  "99202": "44060",
  "99203": "44060",
  "99204": "44060",
  "99205": "44060",
  "99206": "44060",
  "99207": "44060",
  "99208": "44060",
  "99212": "44060",
  "99216": "44060",
  "99217": "44060",
  "99218": "44060",
  "99223": "44060",
  "99224": "44060",
  "99301": "28420",
  "99320": "28420",
  "99323": "47460",
  "99324": "47460",
  "99326": "28420",
  "99330": "28420",
  "99336": "28420",
  "99337": "28420",
  "99338": "28420",
  "99341": "36830",
  "99343": "28420",
  "99344": "36830",
  "99348": "47460",
  "99349": "34180",
  "99350": "28420",
  "99352": "28420",
  "99353": "28420",
  "99354": "28420",
  "99357": "34180",
  "99360": "47460",
  "99361": "47460",
  "99362": "47460",
  "99371": "36830",
  "99402": "30300",
  "99403": "30300",
  "99501": "11260",
  "99502": "11260",
  "99503": "11260",
  "99504": "11260",
  "99507": "11260",
  "99508": "11260",
  "99515": "11260",
  "99516": "11260",
  "99517": "11260",
  "99518": "11260",
  "99540": "11260",
  "99567": "11260",
  "99577": "11260",
  "99587": "11260",
  "99623": "11260",
  "99645": "11260",
  "99654": "11260",
  "99674": "11260",
  "99676": "11260",
  "99688": "11260",
  "99701": "21820",
  "99705": "21820",
  "99709": "21820",
  "99712": "21820",
  "99714": "21820",
  "99801": "27940",
  "99824": "27940",
  "99901": "28540",
};
