import { FeedbackModal } from "components/Feedback/FeedbackModal";
import { createContext, useContext, useState } from "react";

interface FeedbackPopUpProps {
  showFeedbackPopup(arg?: {
    title?: string;
    placeholder?: string;
    subject?: string;
  }): void;
}

const FeedbackPopUpContext = createContext<FeedbackPopUpProps | null>(null);

export const FeedbackPopUpProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [texts, setTexts] = useState({
    title: "Send us your feedback",
    placeholder:
      "Please leave feedback related to your experience on Reventure App",
    subject: "Help",
  });

  const showFeedbackPopup = (param: {
    title?: string;
    placeholder?: string;
    subject?: string;
  }) => {
    setTexts({
      title: param?.title || "Send us your feedback",
      placeholder:
        param?.placeholder ||
        "Please leave feedback related to your experience on Reventure App",
      subject: param?.subject || "Help",
    });

    setShow(true);
  };

  return (
    <FeedbackPopUpContext.Provider value={{ showFeedbackPopup }}>
      {children}
      <FeedbackModal setShow={setShow} show={show} texts={texts} />
    </FeedbackPopUpContext.Provider>
  );
};

const useFeedbackPopUp = () => {
  const values = useContext(FeedbackPopUpContext);

  if (!values) {
    throw new Error("Cannot use FeedbackPopUp outside of provider");
  }

  return values;
};

export default useFeedbackPopUp;
