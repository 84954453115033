import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import { ResponsiveContext } from "lib/context";
import { getRelativePathname } from "lib/getRelativePathname";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext } from "react";

export const DowngradeSuccessfulModal = () => {
  const router = useRouter();
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const handleContinue = () => {
    router.replace(`${getRelativePathname(router)}?modalClosed=true`);
  };

  return (
    <Modal
      contentStyle={{
        maxWidth: "100vw",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
      isOpen={!!router.query.showDowngradeModal}
    >
      <div
        css={{
          background: "white",
          padding: 32,
          width: 357,
        }}
      >
        <Flex align="center" direction="column">
          <Image src="/success-icon.svg" height={59} alt="success" width={59} />
          <Text
            css={{ marginTop: 17 }}
            fontSize={isTabletOrMobile ? "larger" : "heading2"}
            bolder
            align="center"
          >
            {!!router.query.planCanceled
              ? "Plan Canceled"
              : "Downgrade Successful"}
          </Text>
          <Text
            css={{
              lineHeight: "22px",
              textAlign: "center",
              marginTop: 8,
              color: "rgba(0, 0, 0, 0.72)",
              maxWidth: 200,
            }}
          >
            {!!router.query.planCanceled
              ? "Your plan has been canceled. You will not be charged on next billing cycles"
              : "This plan will be reflected in the next billing cycle"}
          </Text>
          <Button
            variant="primary"
            css={{ marginTop: 25, width: "100%" }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};
