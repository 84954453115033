import { useTheme } from "@emotion/react";
import { Plan, PlanType } from "backend/utils/plan";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import React, { useContext, useEffect, useMemo } from "react";
import { PlanCard } from "./PlanCard";
import { usePlans } from "hooks/usePlans";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { ResponsiveContext } from "lib/context";
import { Price } from "backend/types/stripe.type";
import { ArrowLeftOutlined } from "@ant-design/icons";
import useGeoExploratory from "hooks/useGeoExploratory";
import { DatapointCategory } from "./DatapointCategory";
import { useUserData } from "hooks/useUser";
import { useRouter } from "next/router";
import { getRelativePathname } from "lib/getRelativePathname";
import CrossIcon from "components/icons/CrossIcon";

const DatapointsModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): JSX.Element => {
  const theme = useTheme();
  const plans = usePlans();
  const user = useUserData();
  const { groupedExploratories } = useGeoExploratory();
  const router = useRouter();

  const { setShowPlanModal, setPlans, setPlanPopupTrigger } =
    useContext(PlanModalContext);

  useEffect(() => {
    setPlans(plans);
  }, [plans]);

  const filteredAndSortedPlans = useMemo<Price[]>(() => {
    return plans
      .filter((plan) => plan.name !== Plan.Free)
      .sort((a, b) => a.amount - b.amount);
  }, [plans]);

  const [homePriceAndAfforability, marketTrends, investorMetrics, demographic] =
    useMemo(() => {
      return groupedExploratories.map((category) => {
        return {
          ...category,
          options: category.options.filter((opt) => opt.premium),
        };
      });
    }, [groupedExploratories]);

  return plans.length > 0 ? (
    <Modal
      overlayStyle={{ overflow: "auto" }}
      contentStyle={{
        padding: 0,
        background: "white",
        maxWidth: 555,
        width: "100%",
      }}
      isOpen={open}
    >
      <div css={{ padding: "11px 18px" }}>
        <Flex align="center" css={{ overflowX: "hidden" }}>
          <ArrowLeftOutlined
            style={{ fontSize: 20 }}
            onClick={() => {
              onClose();
              setPlanPopupTrigger("From Datapoint modal");
              setShowPlanModal(true);
            }}
          />
          <Text
            tagName="h1"
            align="center"
            css={{ flex: 1 }}
            fontSize="large"
            bold
          >
            Premium Data Points
          </Text>
          <Flex
            onClick={onClose}
            align="center"
            justify="center"
            css={{
              cursor: "pointer",
              background: "#EFEFEF",
              borderRadius: 4,
              height: 37,
              width: 37,
            }}
          >
            <CrossIcon />
          </Flex>
        </Flex>
      </div>
      <div css={{ borderTop: `1px solid ${theme.colors.radioBorder}` }} />
      <Flex align="stretch">
        {filteredAndSortedPlans.map((plan, index) => (
          <React.Fragment key={plan.id}>
            <PlanCard onSelect={onClose} plan={plan} />
            {index !== filteredAndSortedPlans.length - 1 && (
              <div
                css={{
                  alignSelf: "stretch",
                  borderRight: `1px solid ${theme.colors.radioBorder}`,
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Flex>
      <div css={{ borderTop: `1px solid ${theme.colors.radioBorder}` }} />
      <Flex justify="center">
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            maxWidth: 455,
            padding: "14px",
            gap: 63,
          }}
        >
          <Flex direction="column" gap={15}>
            <DatapointCategory category={homePriceAndAfforability} />
            <DatapointCategory category={marketTrends} />
          </Flex>
          <Flex direction="column" gap={15}>
            <DatapointCategory category={investorMetrics} />
            <DatapointCategory category={demographic} />
          </Flex>
        </div>
      </Flex>
      <Flex
        css={{ background: theme.colors.blue, padding: 10 }}
        justify="center"
      >
        <Text fontSize="large" css={{ color: theme.colors.white }}>
          Or signup as a{" "}
          <span
            onClick={() => {
              if (!user.uid && !user.isLoading) {
                router.push(`${getRelativePathname(router)}?type=signup`);
                onClose();
              } else if (user?.plan === PlanType.Free) {
                onClose();
              }
            }}
            css={{
              fontWeight: theme.fontWeights.bold,
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
          >
            Free User
          </span>{" "}
          with access to 8 data points
        </Text>
      </Flex>
    </Modal>
  ) : (
    <></>
  );
};

export default DatapointsModal;
