import styled from "@emotion/styled";

export const Flex = styled.div<{
  inline?: boolean;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  column?: string;
  reverse?: boolean;
  justify?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "start"
    | "end"
    | "left"
    | "right";
  align?:
    | "stretch"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "start"
    | "end"
    | "self-start"
    | "self-end";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  grow?: boolean;
  shrink?: boolean;
  cursor?: string;
  flex?: string | number;
  gap?: number;
}>`
  display: ${(props) => (props.inline ? "inline-flex" : "flex")};
  flex-direction: ${(props) =>
    props.direction ??
    (props.column
      ? props.reverse
        ? "column-reverse"
        : "column"
      : props.reverse
        ? "row-reverse"
        : "row")};
  justify-content: ${(props) => (props.justify ? props.justify : "start")};
  align-items: ${(props) => (props.align ? props.align : "flex-start")};
  flex-wrap: ${(props) => props.wrap};
  flex-grow: ${(props) => (props.grow === true ? 1 : 0)};
  flex-shrink: ${(props) => (props.shrink === true ? 1 : 0)};
  cursor: ${(props) => (props.cursor ? props.cursor : "inherit")};
  flex: ${(props) => (props.flex ? props.flex : "inherit")};
  gap: ${(props) => (props.gap ? `${props.gap}px` : "inherit")};
`;
