import { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Flex } from "components/Flex";
import axios, { AxiosResponse } from "axios";
import { Input } from "components/Input";
import { useTheme } from "@emotion/react";
import { Text } from "components/Text";
import { Divider } from "antd";
import { useUserData } from "hooks/useUser";
import { currencyFormatter } from "lib/currencyFormatter";
import { Button } from "components/Button";
import Icon from "@mdi/react";
import { mdiLock } from "@mdi/js";
import { LoadingOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { splitPlanName } from "lib/splitPlanName";
import { Plan } from "backend/utils/plan";
import { StripeElements } from "@stripe/stripe-js";
import { useDiscountCode } from "hooks/useDiscount";
import { DiscountCodeField } from "components/DiscountCodeField";
import Stripe from "stripe";
import { StripeCheckoutProps } from "./StripeElementsNewDesign";
import { AuthModalContext, ResponsiveContext } from "lib/context";
import Image from "next/image";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  authEvent,
  createUser,
  getAuthError,
  getUser,
  identifyUser,
} from "lib/auth";
import { auth } from "lib/firebase";
import useWelcomePopUp from "hooks/useWelcomePopUp";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { updateUser } from "lib/helpers/userHelpers";
import PlanService from "services/PlanService";
import { toast } from "react-toastify";
import posthog from "posthog-js";
import moment from "moment";
import { getRelativePathname } from "lib/getRelativePathname";

type ReqType = {
  priceId: string;
  userId: string;
  plan: string;
  email: string;
  planCycle: string;
  checkingOut: boolean | undefined;
  setCheckingOut: any;
};

const CheckoutFormStripe = ({
  amount,
  priceId,
  selectedPlan,
  productId,
  checkingOut,
  setCheckingOut,
}: StripeCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const user = useUserData();
  const [name, setName] = useState(user?.name);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const router = useRouter();
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const [error, setError] = useState("");
  const { setShowWelcomePopup } = useWelcomePopUp();
  const { setShowAuthModal, setAuthType } = useContext(AuthModalContext);
  const { setShowPlanModal } = useContext(PlanModalContext);
  const [cardStatus, setCardStatus] = useState<any>();
  const {
    discount,
    discountCode,
    handleDiscountCodeChange,
    isValidDiscount,
    loading: checkingDiscountValidity,
  } = useDiscountCode({ productId });

  const handleSubscriptionCreation = async (
    planType: string,
    planCycle: string,
    promotionCode: string,
    setupIntent: any,
    redirect: string,
    userId: string,
    storedPriceId: string,
  ) => {
    const res = await axios.post<
      ReqType,
      AxiosResponse<{
        data: {
          paymentIntent: Stripe.PaymentIntent;
        };
      }>
    >(`${process.env.NEXT_PUBLIC_APP_URL}/api/stripe/create-subscription`, {
      priceId: storedPriceId,
      userId: userId,
      plan: planType,
      planCycle: planCycle || "Monthly",
      promotionCode,
      paymentMethodId: JSON.parse(setupIntent)?.payment_method,
    });
    const paymentIntent =
      res?.data?.data?.paymentIntent || (res["paymentIntent"] as any);
    setShowWelcomePopup(false);
    router.push(`/dashboard?paymentAmount=${paymentIntent?.amount || 0}`);
  };
  useEffect(() => {
    const query = router?.query;
    if (query?.redirect_status === "succeeded" && user?.uid && stripe) {
      if (query?.setup_intent) {
        setLoading(true);
        const promotionCode = query.promotionCode;
        if (promotionCode) {
          handleDiscountCodeChange(promotionCode);
        }
        const { planCycle, planType } = splitPlanName(query.plan! as Plan);
        const clientSecret = query.setup_intent_client_secret as string;
        stripe
          ?.retrieveSetupIntent(clientSecret)
          .then((res) => {
            if (res.error) {
              handleError(res.error);
              return;
            }

            handleSubscriptionCreation(
              planType,
              planCycle,
              promotionCode as string,
              res.setupIntent,
              query.redirect as string,
              query.uid as string,
              query.priceId as string,
            )
              .catch((e) => {
                handleError(
                  e?.response?.data.message ? e?.response?.data.message : e,
                );
              })
              .finally(() => {
                setLoading(false);
              });
          })
          .catch((e) => {
            setLoading(false);
            handleError(e);
          });
      }
    }
  }, [router?.query?.redirect_status, user?.uid, stripe]);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      let full_name = "";
      setName((n) => {
        full_name = n as string;
        return n;
      });
      if (user) {
        const userInDb = await getUser(user);
        if (!userInDb) {
          createUser(user, user.displayName || full_name);
          identifyUser({ ...user, name: user.displayName || full_name });
          authEvent({
            provider:
              user.providerData[0].providerId === "password"
                ? "email"
                : "google",
            user: user,
            authType: "Sign Up",
          });
        } else {
          if (!userInDb.name && (!!user.displayName || full_name)) {
            updateUser(user.uid, { name: user.displayName || full_name });
          }
        }
      }
    });

    return () => {
      unsub();
    };
  }, []);
  function validateEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  const signUpWithEmailAndPassword = async () => {
    const data = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    ).catch((error) => {
      setError(getAuthError(error));
      return error;
    });
    return data;
  };
  const handleSubmit = async (event) => {
    setCheckingOut(true);
    event.preventDefault(); // Prevent default form submission

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Disable form submission until Stripe.js has loaded.
      setCheckingOut(false);
      return;
    }

    if (!name || name.startsWith(" ")) {
      setNameError("Your name is incomplete.");
      setCheckingOut(false);
      return;
    }
    setLoading(true);

    // Trigger form validation and wallet collection
    elements!.submit().then(async ({ error: submitError }) => {
      if (submitError) {
        handleError(submitError);
        setLoading(false);
        setCheckingOut(false);
        return;
      }

      const { planCycle, planType } = splitPlanName(selectedPlan);
      let signedInUser = user;

      if (!user.uid) {
        if (!email || !password || !confirmPassword) {
          setError("Please enter required fields");
          setCheckingOut(false);
          setLoading(false);
          return;
        }
        if (!validateEmail(email)) {
          setError("Enter a valid email");
          setCheckingOut(false);
          setLoading(false);
          return;
        }
        if (password !== confirmPassword) {
          setError("Confirm password does not match");
          setLoading(false);
          setCheckingOut(false);
          return;
        }
        await signUpWithEmailAndPassword()
          .then((newUser) => {
            signedInUser = { uid: newUser?.user?.uid };
          })
          .catch(() => {
            return;
          });
      }

      if (user.planId) {
        const prevPlan = user.plan;
        try {
          setLoading(true);
          const data = await PlanService.changePlan(
            user.id!,
            priceId!,
            discountCode,
          );
          const latestInvoice = data.data;
          posthog.capture("Plan upgraded", {
            nextBillingCycleDate: moment
              .unix(latestInvoice.lines.data.at(-1)?.period.end!)
              .format("MM/DD/YYYY"),
            newPlanName: selectedPlan,
            changedPlanFrom: prevPlan,
            cadence: planCycle,
            amount: (latestInvoice.amount_due / 100).toFixed(2),
          });
          router.replace(
            `${getRelativePathname(router)}?upgradeStatus=success&amountPaid=${
              latestInvoice.amount_due
            }`,
          );
          return null;
        } catch (error: any) {
          toast(error.message, {
            type: "error",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } finally {
          setLoading(false);
          setCheckingOut(false);
          return null;
        }
      }
      return axios
        .post<ReqType, AxiosResponse<{ data: { clientSecret: string } }>>(
          `${process.env.NEXT_PUBLIC_APP_URL}/api/stripe/create-payment-method`,
          { userId: signedInUser?.uid },
        )
        .then(({ data }) => {
          const { clientSecret } = data.data;
          const returnUrl = `${process.env.NEXT_PUBLIC_APP_URL}/dashboard?uid=${
            signedInUser.uid
          }&priceId=${priceId}&plan=${selectedPlan}${
            discountCode ? `&promotionCode=${discountCode}` : ""
          }&redirect=${router.query.redirect || "/"}`;

          if (!clientSecret || planType === "Free") {
            setLoading(false);
            setCheckingOut(false);
            return;
          }
          return stripe
            .confirmSetup({
              elements: elements as StripeElements,
              clientSecret,
              redirect: "if_required",
              confirmParams: {
                return_url: returnUrl,
                payment_method_data: { billing_details: { name } },
              },
            })
            .then(async (res) => {
              if (res?.setupIntent) {
                const intent = res.setupIntent;
                await handleSubscriptionCreation(
                  planType,
                  planCycle,
                  discountCode,
                  JSON.stringify(intent),
                  (router.query.redirect as string) || "/",
                  signedInUser.uid as string,
                  priceId,
                ).then((res) => {});
              }
            })
            .catch((error) => {
              handleError(error);
            });
        })
        .catch((error) => {
          handleError(error?.response?.data || error);
        })
        .finally(() => {
          setLoading(false);
          setCheckingOut(false);
        });
    });
  };

  const subtotal = amount / 100;
  const discountedTotal =
    discount && isValidDiscount
      ? subtotal * (discount?.coupon.percent_off! / 100)
      : 0;
  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      css={{ width: "100%", marginTop: isTabletOrMobile ? 16 : 0 }}
    >
      {!user.uid && error.includes("already") && (
        <Flex
          css={{
            width: "100%",
            background: "#FA48650F",
            color: "#FA4865",
            padding: "7px 12px",
          }}
          justify="space-between"
          align="center"
        >
          <Text>The email already belongs to a member</Text>
          <Button
            onClick={() => {
              setShowPlanModal(false);
              setAuthType("login");
              setShowAuthModal(true);
            }}
            style={{
              padding: "1px 8px",
              lineHeight: "10px",
              height: "25px",
              textAlign: "center",
              background: "transparent",
              border: "1px solid  #FA4865",
            }}
          >
            Login
          </Button>
        </Flex>
      )}
      <Flex
        flex="1"
        direction="column"
        css={{ width: "100%", padding: isTabletOrMobile ? "0" : "15px" }}
      >
        <Flex gap={11} direction="column" css={{ width: "100%" }}>
          <Text css={{ fontSize: 16, lineHeight: "16px" }} bolder>
            Full Name
          </Text>
          <Flex direction="column" gap={1} css={{ width: "100%" }}>
            <Input
              onChange={(e) => {
                setError("");
                const val = e.target.value;
                setName(val.startsWith(" ") ? val?.trim() : val);
                setErrorMessage(undefined);
                setNameError("");
              }}
              autoComplete="name"
              required
              value={name}
              placeholder="Full name"
              style={{}}
              width="100%"
              name="name"
              css={{
                padding: "8px 11px",
                maxHeight: "34px",
                borderRadius: "3px",
                ...(nameError
                  ? { border: `2px solid ${theme.colors.venetianRed}` }
                  : {}),
              }}
            />
            {nameError && (
              <Text
                css={{
                  marginTop: "0.25rem",
                  color: theme.colors.venetianRed,
                  fontSize: 16,
                }}
              >
                {nameError}
              </Text>
            )}
          </Flex>
        </Flex>
        {!user.uid && !user.isLoading && (
          <>
            <Flex
              gap={11}
              direction="column"
              css={{ width: "100%", marginTop: 10 }}
            >
              <Text css={{ fontSize: 16, lineHeight: "16px" }} bolder>
                Email
              </Text>
              <Flex direction="column" css={{ width: "100%" }} gap={1}>
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setNameError("");
                    setError("");
                    setErrorMessage(undefined);
                  }}
                  value={email}
                  autoComplete="email"
                  required
                  placeholder="Email"
                  width="100%"
                  type="email"
                  name="email"
                  css={{
                    height: "34px",
                    padding: "9px 11px",
                    borderRadius: "3px",
                    ...(nameError ||
                    error.includes("already") ||
                    error.includes("valid email")
                      ? { border: "2px solid #df1b41" }
                      : {}),
                  }}
                />
                {error.includes("valid email") && (
                  <Text css={{ color: theme.colors.red }}>{error}</Text>
                )}
              </Flex>
            </Flex>
            <Flex
              gap={11}
              direction="column"
              css={{ width: "100%", marginTop: 10 }}
            >
              <Text css={{ fontSize: 16, lineHeight: "16px" }} bolder>
                Password
              </Text>
              <div
                css={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Input
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                    setNameError("");
                    setErrorMessage(undefined);
                  }}
                  value={password}
                  placeholder="Password"
                  width="100%"
                  required
                  type={!showPassword ? "password" : "text"}
                  autoComplete="new-password"
                  name="password"
                  css={{
                    height: "34px",
                    padding: "9px 11px",
                    borderRadius: "3px",
                    ...(nameError || error?.includes("does not match")
                      ? { border: "2px solid #df1b41" }
                      : {}),
                  }}
                />
                <span
                  css={{
                    position: "absolute",
                    right: 15,
                    top: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <Image
                    alt="Show/hide"
                    src="/eye.svg"
                    width={23}
                    height={23}
                  />
                </span>
              </div>
            </Flex>
            <Flex
              gap={11}
              direction="column"
              css={{ width: "100%", marginTop: 10 }}
            >
              <Text css={{ fontSize: 16, lineHeight: "16px" }} bolder>
                Confirm Password
              </Text>
              <Flex
                direction="column"
                css={{ width: "100%", margin: 0 }}
                gap={1}
              >
                <div
                  css={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Input
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setNameError("");
                      setError("");
                      setErrorMessage(undefined);
                    }}
                    value={confirmPassword}
                    placeholder="Password"
                    autoComplete="new-password"
                    width="100%"
                    required
                    type={!showConfirmPassword ? "password" : "text"}
                    name="confirm password"
                    css={{
                      height: "34px",
                      padding: "9px 11px",
                      borderRadius: "3px",
                      ...(nameError || error?.includes("does not match")
                        ? { border: "2px solid #df1b41" }
                        : {}),
                    }}
                  />
                  <span
                    css={{
                      position: "absolute",
                      right: 15,
                      top: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  >
                    <Image
                      alt="Show/hide"
                      src="/eye.svg"
                      width={23}
                      height={23}
                    />
                  </span>
                </div>
                {error && error.includes("does not match") && (
                  <Text
                    css={{
                      marginTop: "0.25rem",
                      color: theme.colors.venetianRed,
                      fontSize: 16,
                    }}
                  >
                    {error}
                  </Text>
                )}
              </Flex>
            </Flex>
          </>
        )}
        <Flex direction="column" css={{ width: "100%", marginTop: 10 }}>
          <PaymentElement
            options={{ terms: { card: "never" } }}
            css={{
              fontSize: theme.fontSizes.default,
              width: "100%",
              label: { margin: "2px" },
            }}
            onChange={(event) => {
              setCardStatus(event);
              setErrorMessage(undefined);
            }}
          />
        </Flex>
        <Divider
          orientation="center"
          css={{
            height: 1,
            background: "#E2E2E2",
            width: "100%",
            marginTop: 10,
            marginBottom: 8,
          }}
        />
        {discount && isValidDiscount && (
          <Flex direction="column" gap={10} css={{ width: "100%" }}>
            <Flex css={{ width: "100%" }} justify="space-between">
              <Text>Subtotal</Text>
              <Text>{currencyFormatter(subtotal)}</Text>
            </Flex>
            <Flex css={{ width: "100%" }} justify="space-between">
              <Text>Discount</Text>
              <Text>-{currencyFormatter(discountedTotal)}</Text>
            </Flex>
            <Divider
              orientation="center"
              style={{
                height: 1,
                background: "#E2E2E2",
                width: "100%",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Flex>
        )}
        <Flex
          css={{ fontSize: 16, fontWeight: 500, width: "100%" }}
          justify="space-between"
          align="center"
        >
          <Flex css={{ width: "100%" }} gap={4} direction="column">
            <Text>Total</Text>
            <Text css={{ fontSize: 14, color: "#ACACAC" }}>
              Taxes are included
            </Text>
          </Flex>
          <Text css={{ fontSize: 18 }}>
            {currencyFormatter(
              discountedTotal ? subtotal - discountedTotal : subtotal,
            )}
          </Text>
        </Flex>
        <DiscountCodeField
          labelGap={16}
          discountCode={discountCode}
          discount={discount}
          isValidDiscount={isValidDiscount}
          loading={checkingDiscountValidity}
          origin={"signupCheckout"}
          onDiscountChange={(e) => {
            setNameError("");
            setErrorMessage(undefined);
            handleDiscountCodeChange(e.target.value);
          }}
        />
        {errorMessage && (
          <Text
            css={{
              color: theme.colors.venetianRed,
              textAlign: "center",
              width: "100%",
              marginTop: 20,
            }}
            fontSize="medium"
          >
            {errorMessage}
          </Text>
        )}
        <Button
          css={{
            background: "#3EB770",
            padding: 10,
            color: "white",
            width: "100%",
            marginTop: 18,
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 8,
            borderRadius: 3,
          }}
          disabled={
            ((!email || !password || !confirmPassword) && !user?.uid) ||
            cardStatus?.empty ||
            !cardStatus?.complete ||
            loading ||
            !!(!isValidDiscount && discountCode) ||
            checkingDiscountValidity ||
            errorMessage ||
            !!error
          }
        >
          <Icon path={mdiLock} size="16px" />
          Pay USD
          {currencyFormatter(
            discountedTotal ? subtotal - discountedTotal : subtotal,
          ).replace("$", "")}
          {loading && <LoadingOutlined style={{ marginLeft: 8 }} />}
        </Button>
        {isTabletOrMobile && (
          <div
            css={{
              maxWidth: !isTabletOrMobile ? 503 : "100%",
              marginTop: isTabletOrMobile ? 24 : 35,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Text
              css={{
                padding: isTabletOrMobile ? "5px 10px" : "18px 24px",
                background: theme.colors.brightGray,
                borderRadius: 4,
                width: "fit-content",
                fontSize: "13px",
              }}
            >
              By clicking the &apos;Pay&apos; button you agree to be{" "}
              <b>
                billed{" "}
                {currencyFormatter(
                  discountedTotal ? subtotal - discountedTotal : subtotal || 0,
                  {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  },
                )}{" "}
                {selectedPlan === Plan.Premium_Monthly ? "monthly" : "annually"}{" "}
              </b>{" "}
              for this subscription.
            </Text>
          </div>
        )}
        <Text css={{ color: "#ACACAC", lineHeight: "22px", paddingBottom: 20 }}>
          Your personal data will be used to process your order, support your
          experience throughout this website, and for other purposes described
          in our privacy policy.
        </Text>
      </Flex>
    </form>
  );
};

export default CheckoutFormStripe;
