import axios from "axios";
import { Price } from "backend/types/stripe.type";
import Stripe from "stripe";

async function getPlans() {
  const {
    data: { data: plans },
  } = await axios.get<{ data: Price[]; message: string }>(
    `${process.env.NEXT_PUBLIC_APP_URL}/api/stripe/get-plans`,
  );
  return { data: plans };
}

async function getInvoices(userId: string) {
  const {
    data: { data: invoices },
  } = await axios.get<{ data: Stripe.Invoice[]; message: string }>(
    `${process.env.NEXT_PUBLIC_APP_URL}/api/stripe/get-invoices?userId=${userId}`,
  );
  return { data: invoices };
}

async function changePlan(
  userId: string,
  newPriceId: string,
  promotionCode?: string,
) {
  const { data } = await axios.post("/api/stripe/change-plan", {
    userId: userId,
    newPriceId,
    promotionCode,
  });
  return data;
}

async function reactivatePlan(userId: string) {
  const { data } = await axios.post("/api/stripe/reactivate-plan", {
    userId: userId,
  });
  return data;
}

async function getDiscount(
  promotionCode: string,
): Promise<Stripe.PromotionCode> {
  const { data } = await axios.get(
    `/api/stripe/get-discount?promotionCode=${promotionCode}`,
  );
  return data.data;
}

async function savePaymentMethod(userId: string, paymentMethodId: string) {
  await axios.post("api/stripe/save-payment-method", {
    userId,
    paymentMethodId,
  });
}

async function Addcoupon(userId: string, couponCode: string) {
  const { data } = await axios.post("/api/stripe/add-coupon", {
    userId: userId,
    couponCode,
  });
  return data;
}
async function renewEarly(
  stripeSubscriptionId: string,
  couponCode: string,
  userId: string,
) {
  const { data } = await axios.post("/api/stripe/early-renew", {
    stripeSubscriptionId: stripeSubscriptionId,
    couponCode,
    userId,
  });
  return data;
}
async function getUpcomingInvoice(customerId: string) {
  const { data } = await axios.post("/api/stripe/upcomming-invoice", {
    customerId: customerId,
  });
  return data;
}

export default {
  getPlans,
  getInvoices,
  changePlan,
  reactivatePlan,
  getDiscount,
  savePaymentMethod,
  getUpcomingInvoice,
  Addcoupon,
  renewEarly,
};
