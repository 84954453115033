import { PlanType } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { ResponsiveContext } from "lib/context";
import { currencyFormatter } from "lib/currencyFormatter";
import { useContext } from "react";
import { UserData } from "types/user";

type Props = {
  amount: number;
  user: Partial<UserData>;
  handleContinue: () => void;
};

export const PaymentSuccess = ({ amount, user, handleContinue }: Props) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  return (
    <Flex align="center" direction="column">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="61"
        viewBox="0 0 61 61"
        fill="none"
      >
        <path
          d="M30.4999 1.00014C46.7808 1.00021 59.9997 14.2192 59.9996 30.5001C59.9995 46.7811 46.7806 59.9999 30.4996 59.9999C14.2186 59.9998 0.999787 46.7808 0.999863 30.4999C0.999939 14.2189 14.2189 1.00006 30.4999 1.00014Z"
          fill="#33C023"
        />
        <path
          d="M30.4999 1.00014C46.7808 1.00021 59.9997 14.2192 59.9996 30.5001C59.9995 46.7811 46.7806 59.9999 30.4996 59.9999C14.2186 59.9998 0.999787 46.7808 0.999863 30.4999C0.999939 14.2189 14.2189 1.00006 30.4999 1.00014Z"
          stroke="#33C023"
          strokeWidth="1.19827"
        />
        <path
          d="M19.4102 33.2295L26.5828 40.4022L41.5953 25.3896"
          fill="#33C023"
        />
        <path
          d="M19.4102 33.2295L26.5828 40.4022L41.5953 25.3896"
          stroke="white"
          strokeWidth="5.33778"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Text
        css={{ marginTop: 17 }}
        fontSize={isTabletOrMobile ? "larger" : "heading1"}
        bolder
      >
        Purchase Successful
      </Text>
      <Text
        css={{
          lineHeight: "22px",
          textAlign: "center",
          marginTop: 8,
          color: "rgba(0, 0, 0, 0.72)",
        }}
      >
        Thanks for your purchase and get ready to Unleash the Power of Real
        Estate Data
      </Text>
      <Flex css={{ marginTop: 17, width: "100%" }} direction="column" gap={10}>
        <Flex css={{ width: "100%" }} justify="space-between" align="center">
          <Text css={{ color: "rgba(0, 0, 0, 0.65)" }}>Plan</Text>
          <Text>{user.plan}</Text>
        </Flex>
        {user.plan !== PlanType.Free && (
          <Flex justify="space-between" css={{ width: "100%" }} align="center">
            <Text css={{ color: "rgba(0, 0, 0, 0.65)" }}>Type</Text>
            <Text>{user.planCycle}</Text>
          </Flex>
        )}
        <Flex justify="space-between" align="center" css={{ width: "100%" }}>
          <Text bolder>Amount Paid</Text>
          <Text bolder>{currencyFormatter(amount / 100)}</Text>
        </Flex>
      </Flex>
      <Button
        variant="primary"
        css={{ marginTop: 25, width: "100%" }}
        onClick={() => {
          handleContinue();
        }}
      >
        Continue
      </Button>
    </Flex>
  );
};
