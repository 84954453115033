import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface WelcomePopUpProps {
  showWelcomePopup: boolean;
  setShowWelcomePopup: Dispatch<SetStateAction<boolean>>;
  toggleWelcomePopup(): void;
}

const welcomePopUpContext = createContext<WelcomePopUpProps | null>(null);

export const WelcomePopUpProvider = ({ children }) => {
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const toggleWelcomePopup = () => setShowWelcomePopup((prev) => !prev);

  return (
    <welcomePopUpContext.Provider
      value={{
        showWelcomePopup,
        setShowWelcomePopup,
        toggleWelcomePopup,
      }}
    >
      {children}
    </welcomePopUpContext.Provider>
  );
};

const useWelcomePopUp = () => {
  const values = useContext(welcomePopUpContext);

  if (!values) {
    throw new Error("Cannot use WelcomePopUp outside of provider");
  }

  return values;
};

export default useWelcomePopUp;
