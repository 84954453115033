import { Theme } from "@emotion/react";
import styled, { Interpolation } from "@emotion/styled";
import { ComponentProps, ReactNode } from "react";

const StyledInput = styled("input")(({ theme, disabled }) => ({
  fontFamily: "inherit",
  padding: "12px 16px",
  borderRadius: theme.radii.small,
  fontSize: theme.fontSizes.default,
  outline: "none",
  border: `1px solid ${
    disabled ? theme.colors.inputBorder : theme.colors.disabled
  }`,
  background: theme.colors.white,
  width: "100%",
  color: disabled ? theme.colors.disabled : "black",
}));

type Props = ComponentProps<typeof StyledInput> & {
  label?: ReactNode;
  containerCss?: Interpolation<Theme>;
};

export const Input = ({ label, disabled, containerCss, ...rest }: Props) => {
  return label ? (
    <div css={containerCss} style={{ position: "relative", width: "100%" }}>
      {label && (
        <span
          css={{
            position: "absolute",
            top: -7,
            background: "white",
            left: 12,
            padding: "0px 4px",
            lineHeight: "12px",
          }}
        >
          <span
            css={(theme) => ({
              opacity: disabled ? 0.25 : 0.65,
              fontSize: theme.fontSizes.small,
            })}
          >
            {label}
          </span>
        </span>
      )}
      <StyledInput disabled={disabled} {...rest} />
    </div>
  ) : (
    <StyledInput disabled={disabled} css={{}} {...rest} />
  );
};
