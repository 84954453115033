import { Plan, PlanCycle, PlanType } from "backend/utils/plan";

export const splitPlanName = (name: Plan) => {
  const splitPlan = name.split(" ");
  let planType = splitPlan[0] as PlanType;
  splitPlan.forEach((split, index) => {
    if (index !== 0 && index !== splitPlan.length - 1) {
      planType = `${planType} ${split}` as PlanType;
    }
  });
  const planCycle = splitPlan[splitPlan.length - 1] as PlanCycle;
  return { planType, planCycle };
};
