/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import theme from "lib/theme";

const RadioInput = styled.div<{
  hideBorder?: boolean;
  variant?: "primary";
}>`
  text-align: left;
  border:  ${(props) => (props.hideBorder ? `none` : `2px solid #9eacaf`)};
  padding: 0px;
  border-radius: 6px;
  cursor: pointer;
  input[type=radio] {
    position: absolute;
    opacity: 0;
  }
  input[type=radio] + .radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid ${theme.colors.radioBorder};
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
 input[type=radio]:checked + .radio-label:before {
    background-color: ${(props) =>
      props.variant === "primary" ? theme.colors.primary : `#00212c`};
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border:  ${(props) =>
      props.variant === "primary"
        ? `1px solid ${theme.colors.primary}`
        : `1px solid ${theme.colors.radioBorder}`};
  }
   input[type=radio]:focus + .radio-label:before {
    outline: none;
    border-color: ${(props) =>
      props.variant === "primary"
        ? theme.colors.primary
        : theme.colors.radioBorder};
  }
  input[type=radio]:disabled + .radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
  }
  input[type=radio] + .radio-label:empty:before {
    margin-right: 0;
  }
  }
`;

const Radio = (props) => {
  const {
    hideBorder = false,
    variant = "default",
    hideText,
    className = "",
    checked,
    onClick,
    text,
    disabled,
    id,
  } = props;

  return (
    <RadioInput variant={variant} hideBorder={hideBorder}>
      <input
        id={id}
        name={id}
        type="radio"
        value={text}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onClick(e)}
      />
      {!hideText && (
        <label
          css={() => ({ display: "flex", cursor: "pointer" })}
          htmlFor={id}
          className="radio-label"
        >
          {text}
        </label>
      )}
    </RadioInput>
  );
};

export default Radio;
