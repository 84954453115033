import { createContext, useState } from "react";

type TPaymentModalContext = {
  showPaymentModal: boolean;
  setShowPaymentModal: (value: boolean) => void;
};

export const PaymentModalContext = createContext<TPaymentModalContext>({
  setShowPaymentModal: (_v) => {},
  showPaymentModal: false,
});

export const PaymentModalProvider = ({ children }) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  return (
    <PaymentModalContext.Provider
      value={{ showPaymentModal, setShowPaymentModal }}
    >
      {children}
    </PaymentModalContext.Provider>
  );
};
