import { Modal } from "components/Modal";
import { PaymentSuccess } from "components/plan/PaymentSuccess";
import { getRelativePathname } from "lib/getRelativePathname";
import { useUserData } from "hooks/useUser";
import { useRouter } from "next/router";

export const UpgradeSuccessfulModal = () => {
  const user = useUserData();
  const router = useRouter();

  const handleContinue = () => {
    router.replace(`${getRelativePathname(router)}`);
  };

  return (
    <Modal
      contentStyle={{
        maxWidth: "100vw",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
      isOpen={router.query.upgradeStatus === "success"}
    >
      <div
        css={{
          background: "white",
          padding: 32,
          width: 357,
        }}
      >
        <PaymentSuccess
          handleContinue={handleContinue}
          user={user}
          amount={parseInt((router.query.amountPaid as string) || "0")}
        />
      </div>
    </Modal>
  );
};
