import { NextRouter } from "next/router";

export const getRelativePathname = (router: NextRouter) => {
  let relativeURL;
  const slug = router.query.slug;
  if (slug) {
    relativeURL = router.pathname.replace("[slug]", slug as string);
  } else {
    relativeURL = router.pathname;
  }
  return relativeURL;
};
