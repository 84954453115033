/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { Input } from "./Input";
import { Text } from "./Text";
import { GoogleButton } from "./GoogleButton";
import { Button } from "./Button";
import { Line } from "./Line";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { CheckboxInput } from "./common/CheckboxInput";
import Image from "next/image";

export const AuthForm = ({
  authType,
  setAuthType = undefined,
  title,
  error,
  setError,
  loading,
  email,
  password,
  setEmail,
  setFullname,
  fullname,
  setPassword,
  onFormSubmit,
  onGoogleSubmit,
  setAcceptTerms,
  acceptTerms,
  showTermsError,
  setShowTermsError,
  confirmPassword,
  setConfirmPassword,
}: any) => {
  const [isReset, setIsReset] = useState(false);
  const [resetMsg, setResetMsg] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const authTypeMap = {
    login: {
      title: <span>Sign in</span>,
      googleButtonLabel: <span>Continue with Google</span>,
    },
    signUp: {
      title: <span>Sign up for a Free Account</span>,
      googleButtonLabel: <span>Continue with Google</span>,
    },
    reset: {
      title: <span>Reset Password</span>,
      googleButtonLabel: null,
    },
  };

  const authVerbiage = isReset ? authTypeMap["reset"] : authTypeMap[authType];

  const resetPassword = () => {
    const auth = getAuth();
    const actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: `${process.env.NEXT_PUBLIC_APP_URL}/dashboard?passwordReset=true`,
      handleCodeInApp: false,
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setResetMsg(
          "An email with password reset link has been sent to your email account. Please go through that link to reset your password.",
        );
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        let errorMessage = error.message;

        if (errorMessage.includes("invalid-email")) {
          errorMessage = "Please enter a valid Email";
          setResetMsg(errorMessage);
        } else {
          setResetMsg("Error sending password reset email");
        }
        // ..
      });
  };

  return (
    <main
      css={(theme) => ({
        width: "90%",
        maxWidth: "399px",
        borderRadius: theme.radii.small,
        margin: "50px auto",
        boxShadow: theme.shadow,
        backgroundColor: theme.colors.white,
        padding: "20px 20px 1px 20px",
      })}
    >
      <>
        <h1
          css={(theme) => ({
            paddingTop: "0px",
            paddingRight: theme.padding.medium,
            fontSize: 30,
            margin: 0,
          })}
        >
          <span>{authVerbiage.title}</span>
        </h1>
        <div
          css={() => ({
            textAlign: "center",
            margin: "0 auto",
          })}
        >
          {authType === "signUp" ? (
            <Text
              onClick={() => {
                setIsReset(false);
                setResetMsg("");
                setAuthType("login");
              }}
            >
              Already have an account?{" "}
              <a
                css={(theme) => ({
                  color: theme.colors.primary,
                  textDecoration: "none",
                  ":hover": {
                    color: theme.colors.primary,
                  },
                })}
                href="#"
              >
                Sign in
              </a>
            </Text>
          ) : (
            <Text
              onClick={() => {
                setIsReset(false);
                setResetMsg("");
                setAuthType("signUp");
              }}
            >
              Don&apos;t have an account?{" "}
              <a
                css={(theme) => ({
                  color: theme.colors.primary,
                  textDecoration: "none",
                  ":hover": {
                    color: theme.colors.primary,
                  },
                })}
                href="#"
              >
                Sign up
              </a>
            </Text>
          )}
        </div>
        {!isReset && (
          <GoogleButton
            onClick={() => onGoogleSubmit()}
            label={authVerbiage.googleButtonLabel}
          />
        )}
        <Line>
          <span
            css={(theme) => ({
              backgroundColor: theme.colors.white,
              padding: "0px 10px",
              color: theme.colors.gray100,
              fontSize: theme.fontSizes.small,
            })}
          >
            OR
          </span>
        </Line>
        <div
          css={() => ({
            marginBottom: "15px",
          })}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (isReset) {
                resetPassword();
              } else {
                onFormSubmit();
              }
            }}
          >
            {authType === "signUp" && (
              <div css={(theme) => ({ marginBottom: theme.margin.large })}>
                <Input
                  value={fullname}
                  onChange={(e) => {
                    if (!!error) {
                      setError("");
                    }
                    setFullname(e.target.value);
                  }}
                  required
                  placeholder={"Full Name"}
                  name={"fullname"}
                  type={"name"}
                />
              </div>
            )}
            <div css={(theme) => ({ marginBottom: theme.margin.large })}>
              <Input
                value={email}
                onChange={(e) => {
                  const email = e.target.value.trim();
                  setEmail(email);
                  if (!!error) {
                    setError("");
                  }
                }}
                required
                placeholder={"Email address"}
                name={"email"}
                type={"email"}
              />
            </div>
            {!isReset && (
              <div>
                <div
                  css={(theme) => ({
                    marginBottom: theme.margin.large,
                    position: "relative",
                  })}
                >
                  <Input
                    value={password}
                    onChange={(e) => {
                      const password = e.target.value.trim();
                      setPassword(password);
                      if (!!error) {
                        setError("");
                      }
                    }}
                    required
                    placeholder={"Password"}
                    name={"password"}
                    type={hidePassword ? "password" : "text"}
                    css={() => ({
                      paddingRight: 40,
                    })}
                  />
                  <span
                    css={() => ({
                      position: "absolute",
                      right: 20,
                      top: 12,
                      cursor: "pointer",
                    })}
                    onClick={() => {
                      setHidePassword(!hidePassword);
                    }}
                  >
                    <Image alt="Eye" src="/eye.svg" width={23} height={23} />
                  </span>
                </div>
                {authType === "signUp" && (
                  <div
                    css={(theme) => ({
                      marginBottom: theme.margin.large,
                      position: "relative",
                    })}
                  >
                    <Input
                      value={confirmPassword}
                      onChange={(e) => {
                        const cp = e.target.value.trim();
                        setConfirmPassword(cp);
                        if (!!error) {
                          setError("");
                        }
                      }}
                      required
                      placeholder={"Confirm password"}
                      name={"confirm_password"}
                      type={hideConfirmPassword ? "password" : "text"}
                      css={() => ({
                        paddingRight: 40,
                      })}
                    />
                    <span
                      css={() => ({
                        position: "absolute",
                        right: 20,
                        top: 12,
                        cursor: "pointer",
                      })}
                      onClick={() => {
                        setHideConfirmPassword(!hideConfirmPassword);
                      }}
                    >
                      <Image alt="Eye" src="/eye.svg" width={23} height={23} />
                    </span>
                  </div>
                )}
              </div>
            )}

            {resetMsg && (
              <Text
                css={(theme) => ({
                  color: theme.colors.red,
                  textAlign: "center",
                })}
              >
                {resetMsg}
              </Text>
            )}
            {error && (
              <Text
                css={(theme) => ({
                  color: theme.colors.red,
                  textAlign: "center",
                })}
              >
                {error}
              </Text>
            )}

            {authType === "signUp" && !isReset && (
              <>
                <div
                  css={() => ({
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "flex-start",
                  })}
                >
                  <span>
                    <CheckboxInput
                      type="checkbox"
                      onClick={() => {
                        setAcceptTerms(!acceptTerms);
                        setShowTermsError(acceptTerms);
                      }}
                      css={(theme) => ({
                        borderColor: theme.colors.primary,
                      })}
                    />
                  </span>
                  <span
                    onClick={() => {
                      setIsReset(false);
                      setResetMsg("");
                      setAuthType("signUp");
                    }}
                    css={(theme) => ({
                      fontSize: theme.fontSizes.small,
                      color: theme.colors.gray300,
                      paddingLeft: 10,
                    })}
                  >
                    By signing up, you agree to our{" "}
                    <a
                      css={(theme) => ({
                        color: theme.colors.black,
                        textDecoration: "none",
                        fontWeight: theme.fontWeights.heading,
                      })}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`//${process.env.NEXT_PUBLIC_VERCEL_URL}/privacy-policy.pdf`}
                    >
                      Privacy Policy,{" "}
                    </a>
                    <a
                      css={(theme) => ({
                        color: theme.colors.black,
                        textDecoration: "none",
                        fontWeight: theme.fontWeights.heading,
                      })}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`//${process.env.NEXT_PUBLIC_VERCEL_URL}/reventure-app-terms-and-conditions.pdf`}
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      css={(theme) => ({
                        color: theme.colors.black,
                        textDecoration: "none",
                        fontWeight: theme.fontWeights.heading,
                      })}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`//${process.env.NEXT_PUBLIC_VERCEL_URL}/reventure-app-terms-and-conditions.pdf`}
                    >
                      Notification Settings
                    </a>
                  </span>
                </div>
                {showTermsError && (
                  <Text
                    css={(theme) => ({
                      color: theme.colors.red,
                      textAlign: "center",
                    })}
                  >
                    Please accept terms and conditions to continue.
                  </Text>
                )}
              </>
            )}
            <Button
              disabled={loading}
              css={() => ({
                marginTop: "26px",
                marginBottom: 10,
                width: "100%",
              })}
              variant="primary"
            >
              {loading
                ? "Loading..."
                : authType === "signUp"
                ? "Create Account"
                : "Submit"}
            </Button>
          </form>
          {!isReset && authType !== "signUp" && (
            <Text
              onClick={() => {
                setIsReset(true);
                setError("");
                // resetPassword();
              }}
            >
              <a
                css={(theme) => ({
                  textDecoration: "none",
                  color: theme.colors.primary,
                  ":hover": {
                    color: theme.colors.primary,
                  },
                })}
                href="#"
              >
                Forgot Your Password?
              </a>
            </Text>
          )}
        </div>
      </>
    </main>
  );
};
