import { excludedFilters } from "lib/options/filters";
import { maxBy, minBy } from "lodash";
import { Geo } from "types/MapContext";
import { FeatureState } from "types/Mapbox";
import { CurrentDataPoints } from "types/cube";
import { IFilter } from "types/options";

export const isFilterExcludedFromGeo = (filter: IFilter, geo: Geo | string) => {
  return !excludedFilters[geo]?.some(
    (excluded) => filter.label === excluded.label,
  );
};

export const filterOutliers = (
  datapointValues: (CurrentDataPoints | FeatureState)[],
  exploratory: string,
  percentile: number[],
) => {
  const minPercentile = percentile[0];
  const maxPercentile = percentile[1];

  let values: (CurrentDataPoints | FeatureState)[],
    q1: number,
    q3: number,
    iqr: number,
    maxValue: number,
    minValue: number;

  values = datapointValues
    .slice() // copy
    .sort((a, b) => a[exploratory] - b[exploratory])
    .filter((geoArea) => !!geoArea[exploratory]);

  const quarterLength = values.length / 4;
  const threeQuarterLength = values.length * (3 / 4);

  if (
    values.length < 4 ||
    values.length <= Math.floor(quarterLength + 1) ||
    values.length <= Math.ceil(threeQuarterLength + 1)
  ) {
    return filterByPercentile(datapointValues, exploratory, percentile);
  }

  if (quarterLength % 1 === 0) {
    q1 =
      (1 / 2) *
      (values[quarterLength][exploratory] +
        values[quarterLength + 1][exploratory]);
    q3 =
      (1 / 2) *
      (values[threeQuarterLength][exploratory] +
        values[threeQuarterLength + 1][exploratory]);
  } else {
    q1 = values[Math.floor(quarterLength + 1)][exploratory];
    q3 = values[Math.ceil(threeQuarterLength + 1)][exploratory];
  }

  iqr = q3 - q1;
  maxValue = q3 + iqr * 1.5;
  minValue = q1 - iqr * 1.5;

  const diff = maxValue - minValue;

  const min =
    minPercentile > 0
      ? (minPercentile / 100) * diff + minValue
      : minBy(datapointValues, (value) => value[exploratory])?.[exploratory] ||
        0;

  const max =
    maxPercentile < 100
      ? (maxPercentile / 100) * diff + minValue
      : maxBy(datapointValues, (value) => value[exploratory])?.[exploratory] ||
        0;

  return [min, max];
};

export const filterByPercentile = (
  datapointValues: (CurrentDataPoints | FeatureState)[],
  exploratory: string,
  percentile: number[],
) => {
  const minValue =
    minBy(datapointValues, (value) => value[exploratory])?.[exploratory] || 0;
  const maxValue =
    maxBy(datapointValues, (value) => value[exploratory])?.[exploratory] || 0;

  const diff = maxValue - minValue;

  return [
    (percentile[0] / 100) * diff + minValue,
    (percentile[1] / 100) * diff + minValue,
  ];
};
