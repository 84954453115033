import { Flex } from "./Flex";
import { Text } from "./Text";
import Icon from "@mdi/react";
import { mdiCheckCircle, mdiCloseCircle } from "@mdi/js";
import Spinner from "./Spinner";
import { Interpolation, Theme, useTheme } from "@emotion/react";
import { ChangeEventHandler } from "react";
import { Input } from "./Input";

type Props = {
  onDiscountChange: ChangeEventHandler<HTMLInputElement>;
  loading: boolean;
  discountCode: string;
  isValidDiscount: boolean;
  labelGap: number;
  labelCss?: Interpolation<Theme>;
  isPlanModal?: boolean;
  discount: any;
  origin?: any;
};

export const DiscountCodeField = ({
  onDiscountChange,
  loading,
  discountCode,
  isValidDiscount,
  labelGap,
  labelCss,
  isPlanModal,
  discount,
  origin,
}: Props) => {
  const theme = useTheme();

  return (
    <Flex
      gap={labelGap}
      direction="column"
      css={{
        width: "100%",
        marginTop: isPlanModal || origin === "signupCheckout" ? 0 : 24,
      }}
    >
      {!isPlanModal ||
        (origin !== "signupCheckout" && (
          <Text css={labelCss} bolder>
            Discount code
          </Text>
        ))}
      <div
        css={{
          position: "relative",
          width: "100%",
        }}
      >
        <Input
          onChange={onDiscountChange}
          placeholder={isPlanModal ? "Enter discount code" : "Discount code"}
          width="100%"
          height={
            isPlanModal ? "30px" : origin === "signupCheckout" ? "34px" : "auto"
          }
          name="discount"
          value={discountCode}
          css={{
            height: isPlanModal ? "35px" : "auto",
            padding: isPlanModal
              ? "12px 35px 12px 16px"
              : origin === "signupCheckout"
              ? "5px  40px 5px 11px"
              : "12px 40px 12px 16px",
            ...(origin === "signupCheckout" ? { borderRadius: "3px" } : {}),
          }}
        />
        <div
          css={{
            position: "absolute",
            display: "flex",
            right: isPlanModal ? 10 : 12,
            top: isPlanModal ? 7 : origin === "signupCheckout" ? 6 : 12,
            cursor: "pointer",
          }}
        >
          {loading ? (
            <div
              css={{
                svg: {
                  height: isPlanModal ? 20 : 22,
                  width: isPlanModal ? 20 : 22,
                },
                div: { position: "static", transform: "none" },
              }}
            >
              <Spinner />
            </div>
          ) : isValidDiscount && discountCode && discount ? (
            <Icon
              path={mdiCheckCircle}
              size="22px"
              color={theme.colors.brightGreen}
            />
          ) : (!isValidDiscount && discountCode) ||
            (discountCode && !discount) ? (
            <Icon path={mdiCloseCircle} size="22px" color={theme.colors.red} />
          ) : (
            ""
          )}
        </div>
      </div>
    </Flex>
  );
};
