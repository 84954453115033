import { useTheme } from "@emotion/react";
import { Tooltip } from "antd";
import { Price } from "backend/types/stripe.type";
import { Plan, PlanCycle, PlanType } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { useUserData } from "hooks/useUser";
import { ResponsiveContext } from "lib/context";
import { currencyFormatter } from "lib/currencyFormatter";
import { useRouter } from "next/router";
import { useContext } from "react";

type Props = {
  plan: Price;
  onSelect?: () => void;
};

export const PlanCard = ({ plan, onSelect }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const user = useUserData();
  const { setShowPlanModal } = useContext(PlanModalContext);

  const planNameSplit = plan.name.split(" ");
  const planCycle = planNameSplit[planNameSplit.length - 1];

  const handleGetStarted = async () => {
    if (user?.uid)
      await router.push(
        `${process.env.NEXT_PUBLIC_APP_URL}/checkout?plan=${plan.name}`,
      );
    else
      await router.push(
        `/checkout?plan=${plan.name}&redirect=${process.env.NEXT_PUBLIC_APP_URL}/${router.asPath}`,
      );
    setShowPlanModal(false);
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <Flex
      direction="column"
      gap={12}
      justify="space-between"
      css={{ padding: 21, flex: 1 }}
    >
      <div>
        <Flex
          align={isTabletOrMobile ? "start" : "center"}
          gap={14}
          direction={isTabletOrMobile ? "column-reverse" : "row"}
        >
          <Text
            bolder
            css={{
              fontSize: theme.fontSizes.large,
              lineHeight: "22px",
              marginTop: plan.name === Plan.Premium_Yearly ? 0 : 5,
            }}
          >
            {planCycle === PlanCycle.Monthly ? PlanCycle.Monthly : "Annual"}{" "}
            Pass
          </Text>
          {plan.name === Plan.Premium_Yearly && (
            <Tooltip
              overlayStyle={{ zIndex: 1100 }}
              overlayInnerStyle={{ color: "black" }}
              trigger="hover"
              placement={isTabletOrMobile ? "bottomLeft" : "top"}
              color="white"
              title="Average monthly cost of $33/month compared to $39/month"
            >
              <div
                css={{
                  fontSize: theme.fontSizes.default,
                  background: theme.colors.seaGreen,
                  color: "#FFF",
                  borderRadius: theme.radii.large,
                  padding: "4px 12px",
                }}
              >
                Save 15%
              </div>
            </Tooltip>
          )}
        </Flex>
        <Flex gap={1} direction="column" justify="center">
          <Flex wrap="wrap" justify="space-between" align="baseline">
            <Flex align="center" gap={4}>
              <Text
                fontSize={isTabletOrMobile ? "larger" : "heading4"}
                bolder
                css={{ lineHeight: "45px" }}
              >
                {currencyFormatter(
                  (plan?.name === Plan.Premium_Yearly
                    ? plan.amount / 12
                    : plan.amount) / 100,
                  {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  },
                )}
              </Text>
              <Text
                fontSize={isTabletOrMobile ? "medium" : "small"}
                css={{ opacity: 0.75 }}
              >
                / {"   "}
                {PlanCycle.Monthly.replace("ly", "").toLowerCase()}
              </Text>
            </Flex>
          </Flex>
          {plan?.name === Plan.Premium_Yearly && (
            <Text
              fontSize={isTabletOrMobile ? "small" : "medium"}
              css={{
                opacity: 0.65,
                marginTop: isTabletOrMobile ? "-10px" : "-4px",
              }}
            >
              {currencyFormatter(plan.amount / 100, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}{" "}
              {planCycle.toLowerCase()}
            </Text>
          )}
        </Flex>
      </div>
      <Button
        variant="primary"
        css={{
          fontWeight: theme.fontWeights.heading,
          padding: 11,
          fontSize: theme.fontSizes.small,
          borderRadius: 3,
          width: "100%",
          marginTop: 6,
          alignSelf: "baseline",
        }}
        onClick={handleGetStarted}
      >
        Get Started
      </Button>
    </Flex>
  );
};
