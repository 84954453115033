import { GeocodeQuery } from "../types/geocode";

const FETCH_TTL = 604800;

interface PlacesQueryParams {
  country?: string;
  limit?: number;
  types?: string;
}

const createQuery: (query: string, params?: PlacesQueryParams) => string = (
  search,
  params = {},
) => {
  let queryParams = "";

  for (let key in params) {
    queryParams += `&${key}=${params[key]}`;
  }

  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
    search,
  )}.json?access_token=${process.env.NEXT_PUBLIC_MAPBOX_API_KEY}${queryParams}`;
};

export async function getLatLngByPlace(
  place: string,
): Promise<{ lng: number; lat: number } | undefined> {
  try {
    const response = await fetch(createQuery(encodeURIComponent(place)), {
      next: { revalidate: FETCH_TTL },
    });

    const data = await response.json();

    if (data?.features?.length && data?.features[0]?.geometry?.coordinates) {
      const [lng, lat] = data?.features[0]?.geometry?.coordinates;
      return { lng, lat };
    }
  } catch (error) {
    console.error(error);
  }
}

export const getDataByLatLng: (
  lat: string | number,
  lng: string | number,
  params?: PlacesQueryParams,
) => Promise<GeocodeQuery | undefined> = async (lat, lng, params = {}) => {
  try {
    const response = await fetch(createQuery(`${lng},${lat}`, params), {
      next: { revalidate: FETCH_TTL },
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getDataByPlace: (
  query: string,
  params?: PlacesQueryParams,
) => Promise<GeocodeQuery | undefined> = async (place, params = {}) => {
  try {
    const response = await fetch(createQuery(place, params), {
      next: { revalidate: FETCH_TTL },
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
};
