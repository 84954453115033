// Dependencies
import { Flex } from "components/Flex";
import useFeedbackPopUp from "hooks/useFeedbackPopUp";
import { ResponsiveContext } from "lib/context";
import Image from "next/image";
import { useRouter } from "next/router";
import ChatIcon from "public/chat-icon.svg";
import { useContext } from "react";

export default function ProfileFeedbackButton(): JSX.Element {
  const router = useRouter();
  const { showFeedbackPopup } = useFeedbackPopUp();
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  return router.asPath.startsWith("/profile") ? (
    <>
      <Flex
        css={(theme) => ({
          position: "fixed",
          bottom: isTabletOrMobile ? 15 : 30,
          right: 10,
          height: 50,
          width: 115,
          borderRadius: theme.radii.small,
          backgroundColor: theme.colors.white,
          zIndex: 100,
          cursor: "pointer",
          transition: "width 0.3s ease",
          overflow: "hidden",
          whiteSpace: "nowrap",
          boxShadow: "0px 4px 51px 0px rgba(0, 0, 0, 0.16)",
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
          },
        })}
        onClick={() => showFeedbackPopup()}
      >
        <Flex
          align="center"
          wrap="nowrap"
          gap={6}
          css={(theme) => ({
            height: "100%",
            width: "100%",
            paddingLeft: 6,
            display: "flex",

            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            },
          })}
        >
          <Image
            src={ChatIcon}
            alt="Feedback"
            style={{ width: 30, height: 30 }}
          />
          <div
            className="feedback-text"
            css={{
              transition: "opacity 0.3s ease",
              display: "inline",
              opacity: 1,
            }}
          >
            Feedback
          </div>
        </Flex>
      </Flex>
    </>
  ) : (
    <></>
  );
}
