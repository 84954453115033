import { Datapoints } from "types/cube";
import { IFilterPreset } from "types/options";

export const filterPresets: IFilterPreset[] = [
  {
    label: "Cheapest",
    filters: [
      {
        code: Datapoints.HOME_VALUE,
        label: "Home Value",
        val: [],
        percentile: [0, 35],
      },
    ],
  },
  {
    label: "Most Expensive",
    filters: [
      {
        code: Datapoints.HOME_VALUE,
        label: "Home Value",
        val: [],
        percentile: [65, 100],
      },
    ],
  },
  {
    label: "Affordable",
    filters: [
      {
        code: Datapoints.HOME_VALUE_TO_EARNINGS_RATIO,
        label: "Value / Income Ratio",
        val: [],
        percentile: [0, 35],
      },
    ],
  },
  {
    label: "High Income",
    filters: [
      {
        code: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
        label: "Median Household Income",
        val: [],
        percentile: [65, 100],
      },
    ],
  },
  {
    label: "Most Overvalued",
    filters: [
      {
        code: Datapoints.OVER_UNDER_VALUED_PERCENTAGE,
        label: "Overvalued %",
        val: [],
        percentile: [65, 100],
      },
    ],
  },
  {
    label: "Least Overvalued",
    filters: [
      {
        code: Datapoints.OVER_UNDER_VALUED_PERCENTAGE,
        label: "Overvalued %",
        val: [],
        percentile: [0, 35],
      },
    ],
  },
  {
    label: "High Population",
    filters: [
      {
        code: Datapoints.POPULATION,
        label: "Population",
        val: [],
        percentile: [70, 100],
      },
    ],
  },
];

export const presetFilterLabels = [
  { label: "Home Value" },
  { label: "Value / Income Ratio" },
  { label: "Median Household Income" },
  { label: "Overvalued %" },
];
