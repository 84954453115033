import { createContext } from "react";
import { IMapContext } from "types/MapContext";
import { UserData } from "types/user";

type UpdateCard = {
  cardUpdating: string;
  setCardUpdating: (state: string) => void;
};

export const UserContext = createContext<Partial<UserData>>({});

export const AuthFormContext = createContext<any>({});

export const ResponsiveContext = createContext<any>({});

export const MapContext = createContext<IMapContext>({} as IMapContext);

export const AuthModalContext = createContext({} as any);

export const CardUpdateContext = createContext({} as UpdateCard);
