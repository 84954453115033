import styled from "@emotion/styled";
import { ButtonHTMLAttributes } from "react";
import { variant } from "styled-system";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "alternate";
}

export const Button = styled("button")<Props>(
  ({ theme }) => ({
    fontFamily: "inherit",
    padding: "10px 25px",
    borderRadius: theme.radii.smaller,
    fontSize: theme.fontSizes.default,
    cursor: "pointer",
    outline: "none",
    border: "none",
    ":disabled": {
      background: theme.colors.lightBg,
      color: theme.colors.disabled,
      cursor: "not-allowed",
    },
  }),
  ({ theme, disabled }) =>
    variant({
      variants: {
        primary: {
          color: "white",
          bg: "#000",
          ...(!disabled
            ? {
                "&:hover": {
                  transition: "all 0.4s ease",
                  opacity: 0.75,
                },
              }
            : {}),
        },
        secondary: {
          color: "navy",
          bg: "white",
        },
        alternate: {
          color: "white",
          bg: theme.colors.primary,
          transition: "all 0.4s ease",
          textDecoration: "none",
          maxHeight: "50px",
          ...(!disabled
            ? {
                "&:hover": {
                  transition: "all 0.4s ease",
                  opacity: 0.85,
                },
              }
            : {}),
        },
      },
    }),
);
