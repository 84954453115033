import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Select } from "antd";
import { Plan, planConfig } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import Radio from "components/Radio";
import { Text } from "components/Text";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { doc, updateDoc } from "firebase/firestore";
import useUser, { useUserData } from "hooks/useUser";
import { currencyFormatter } from "lib/currencyFormatter";
import { db } from "lib/firebase";
import { getRelativePathname } from "lib/getRelativePathname";
import { stateList } from "lib/options/stateList";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { StateOption } from "types/options";
import posthog from "posthog-js";

export const CheaperSubscription = () => {
  const user = useUserData();
  const { setChangedPlanFrom } = useUser();
  const theme = useTheme();
  const router = useRouter();
  const { plans } = useContext(PlanModalContext);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(-1);
  const [premiumStates, setPremiumStates] = useState<StateOption[]>([]);
  const [errors, setErrors] = useState({
    state: "",
    plan: "",
  });

  const tailoredPlans = useMemo(() => {
    return plans.filter((plan) =>
      [
        Plan.State_Level_Access_Monthly,
        Plan.State_Level_Access_Yearly,
      ].includes(plan.name),
    );
  }, [plans]);

  useEffect(() => {
    tailoredPlans.forEach((plan, index) => {
      if (plan.name === `${user.plan} ${user.planCycle}`) {
        setSelectedPlanIndex(index);
      }
    });
  }, [user.plan, tailoredPlans, router.query.showCancelSubscription]);

  const handleClose = () => {
    router.replace(getRelativePathname(router));
  };

  const handleCancel = () => {
    router.replace(
      `${getRelativePathname(router)}?showCancelSubscriptionFeedback=true`,
    );
  };

  const handlePurchase = async () => {
    const plan = tailoredPlans[selectedPlanIndex];

    if (!plan) {
      setErrors((prev) => ({
        ...prev,
        plan: "Please select a Plan",
      }));
      return;
    }

    if (!premiumStates.length) {
      setErrors((prev) => ({
        ...prev,
        state: "Please select a Premium State",
      }));
      return;
    }

    try {
      await updateDoc(doc(db, "users", user.id as string), {
        premiumStates,
        stateUpdatedAt: new Date().toISOString(),
      });

      const currentPlan = `${user.plan} ${user.planCycle}`;
      if (plan.name !== currentPlan) {
        setChangedPlanFrom(currentPlan);
        const prevAmount = planConfig[currentPlan].amount;
        const nextAmount = planConfig[plan.name].amount;
        if (
          prevAmount < nextAmount &&
          !(
            currentPlan === Plan.Premium_Monthly &&
            plan.name === Plan.State_Level_Access_Yearly
          )
        ) {
          router.push(
            `${getRelativePathname(router)}?selectedPlanId=${
              plan.id
            }&openUpgradeModal=true&previousModal=CheaperSubscription`,
          );
        } else {
          router.replace(
            `${getRelativePathname(router)}?selectedPlanId=${
              plan.id
            }&downgradingModal=true&previousModal=CheaperSubscription`,
          );
        }
      }
    } catch (error) {
      console.error(`Failed to purchase plan`, error);
    }
  };

  return (
    <Modal
      isOpen={!!router.query.showCheaperSubscription}
      overlayStyle={{ overflow: "auto", padding: "34px 12px" }}
      contentStyle={{
        maxWidth: 360,
        background: "white",
        width: "100%",
        padding: "15px 24px",
        border: `1px solid ${theme.colors.radioBorder}`,
        overflow: "unset",
      }}
    >
      <Flex
        justify="center"
        align="center"
        css={{
          borderRadius: theme.radii.smaller,
          background: theme.colors.lightBg,
          height: 30,
          position: "absolute",
          width: 30,
          right: 15,
          top: 15,
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
      </Flex>
      <div
        css={{
          marginTop: -45,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            border: "10px solid white",
            borderRadius: theme.radii.full,
          }}
        >
          <Image
            alt="headshot"
            src="/nick-headshot.png"
            css={{ borderRadius: theme.radii.full }}
            width={96}
            height={96}
          />
        </div>
      </div>
      <Flex direction="column" align="center">
        <h1
          css={{
            fontSize: theme.fontSizes.larger,
            fontWeight: theme.fontWeights.heading,
            margin: 0,
            textAlign: "center",
          }}
        >
          Save 50%
        </h1>
        <Text css={{ opacity: 0.5, width: "92%" }} align="center">
          Maintain access to premium data in 1 State for half the price of the
          regular plan.
        </Text>
      </Flex>
      <Flex gap={18} css={{ marginTop: 20 }} direction="column">
        {tailoredPlans.map((plan, index) => (
          <Flex
            align="center"
            css={{
              borderRadius: theme.radii.smaller,
              border: `1px solid ${theme.colors.radioBorder}`,
              padding: "10px 20px",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => {
              setErrors((prev) => ({ ...prev, plan: "" }));
              setSelectedPlanIndex(index);
            }}
            key={plan.id}
            justify="space-between"
          >
            <Flex align="center" gap={21}>
              <div
                css={{
                  "input[type=radio] + .radio-label:before": {
                    width: "28px !important",
                    height: "28px !important",
                  },
                  padding: "6px 0px",
                }}
              >
                <Radio
                  checked={index === selectedPlanIndex}
                  variant="primary"
                  hideBorder
                />
              </div>
              <div
                css={{
                  borderRight: `1px solid ${theme.colors.radioBorder}`,
                  alignSelf: "stretch",
                }}
              />
              <Text bold large>
                {plan.name === Plan.State_Level_Access_Monthly
                  ? "Monthly"
                  : "Yearly"}
                :{" "}
                {currencyFormatter(plan.amount / 100, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Text>
            </Flex>
            {plan.name === `${user.plan} ${user.planCycle}` && (
              <Text
                css={{
                  background: theme.colors.cultured,
                  color: theme.colors.bangladeshGreen,
                  padding: "2px 5px",
                  borderRadius: theme.radii.large,
                }}
                fontSize="small"
              >
                Current
              </Text>
            )}
          </Flex>
        ))}
        <Text
          css={{
            color: theme.colors.primary,
            marginBottom: -theme.margin.medium,
            fontSize: theme.fontSizes.small,
            alignSelf: "center",
          }}
        >
          {errors.plan}
        </Text>
        <Text
          css={{
            marginBottom: -theme.margin.medium,
            fontSize: theme.fontSizes.small,
          }}
        >
          Select 1 Premium State
        </Text>
        <Select
          showSearch
          placeholder="Select a premium state"
          options={stateList}
          filterOption={(value, option) =>
            !!option?.label.toLowerCase().startsWith(value.toLowerCase())
          }
          value={premiumStates[0]?.value}
          onSelect={(_, option) => {
            setErrors((prev) => ({ ...prev, state: "" }));
            setPremiumStates([option]);
          }}
          css={{ width: "100%" }}
          dropdownStyle={{ zIndex: 1100 }}
        />
      </Flex>

      <Text
        css={{
          color: theme.colors.primary,
          marginBottom: -theme.margin.medium,
          fontSize: theme.fontSizes.small,
          textAlign: "center",
        }}
      >
        {errors.state}
      </Text>
      <Button
        onClick={handlePurchase}
        css={{ width: "100%", marginTop: 20 }}
        variant="alternate"
      >
        Downgrade to cheaper plan
      </Button>
      <Button
        css={{
          width: "100%",
          marginTop: 8,
          border: `1px solid ${theme.colors.primary}`,
          color: theme.colors.primary,
        }}
        variant="secondary"
        onClick={handleCancel}
      >
        No, Cancel anyways
      </Button>
    </Modal>
  );
};
