/** @jsxImportSource @emotion/react */

import React from "react";

export const Center = ({ children }) => {
  return (
    <div
      css={() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      })}
    >
      {children}
    </div>
  );
};
