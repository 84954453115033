import { values } from "lodash";
import { Cube } from "types/cube";
import configs from "./configs";

type cubesType =
  | "current"
  | "time-series"
  | "score-current"
  | "score-time-series";

export function getQueryList(cube: cubesType, skipPremium?: boolean) {
  const list: string[] = [];

  const sets = {
    current: {
      option: "hasCurrent",
      cube: Cube.CURRENT_DATA_SET,
    },
    "time-series": {
      option: "hasTimeSeries",
      cube: Cube.TIME_SERIES_DATA_SET,
    },
    "score-current": {
      option: "hasScoreCurrent",
      cube: Cube.CURRENT_SCORE,
    },
    "score-time-series": {
      option: "hasScoreTimeSeries",
      cube: Cube.TIME_SERIES_SCORE,
    },
  };

  Object.values(configs).forEach((config) => {
    if (!config[sets[cube].option]) return;
    if (skipPremium && config.premium) return;
    list.push(`${sets[cube].cube}.${config.code}`);

    if (!!config.secondaryDatapoints?.length) {
      config.secondaryDatapoints.forEach((extra) => {
        const parentCode = config.code;

        if (extra.interval) {
          list.push(`${parentCode}_tooltip_${extra.code}.latest_value`);
          list.push(`${parentCode}_tooltip_${extra.code}.latest_date`);
          list.push(`${parentCode}_tooltip_${extra.code}.prev_value`);
          list.push(`${parentCode}_tooltip_${extra.code}.prev_date`);
        } else if (!!extra.years?.length) {
          extra.years.forEach((year) =>
            list.push(`${parentCode}_tooltip_years.${extra.code}_${year}`),
          );
        } else if (extra.isYearlyValue) {
          list.push(`${parentCode}_tooltip_${extra.code}.value`);
        } else if (extra.dates) {
          extra.dates.forEach((date) => {
            const [month, year] = date.split("/");
            const columnName = `${extra.code}_${month}_${year}`;
            list.push(`${parentCode}_tooltip_dates.${columnName}`);
          });
        } else if (extra.latestValue) {
          list.push(`${parentCode}_latest_tooltip.${extra.code}`);
        } else {
          list.push(`${parentCode}_tooltip.${extra.code}`);
        }
      });
    }
  });

  return list;
}

export function getQueryCodeMap(cube: cubesType) {
  return getQueryList(cube).reduce((acc, cur) => {
    if (cur.includes("tooltip")) {
      if (cur.endsWith(".value")) {
        acc[cur] = cur.replace(".value", "");
      } else {
        acc[cur] = cur.replace(".", "_");
      }
    } else {
      const [_, code] = cur.split(".");
      acc[cur] = code;
    }
    return acc;
  }, {});
}

export function getScoreQueryCodeMap() {
  return values(configs).reduce((acc, config) => {
    if (config.hasScoreCurrent) {
      const code = `${Cube.CURRENT_SCORE}.${config.code}`;

      acc[code] = config.raw ? `raw_${config.code}` : config.code;
    }
    return acc;
  }, {});
}
