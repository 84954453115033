import { useTheme } from "@emotion/react";
import { Plan, PlanCycle, PlanType } from "backend/utils/plan";
import { Price } from "backend/types/stripe.type";
import { Flex } from "components/Flex";
import Radio from "components/Radio";
import { Text } from "components/Text";
import { useMemo } from "react";
import { splitPlanName } from "lib/splitPlanName";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { Tooltip } from "antd";
import { currencyFormatter } from "lib/currencyFormatter";

type Props = {
  handleChangePlan: (plan: Plan) => void;
  selectedPlan: Plan;
  plan: Price;
  isWhatsInsideOpen: boolean;
  onWhatsInsideClick: () => void;
  isTabletOrMobile: boolean;
  appliedCode?: string;
};

export const CheckoutPlanItem = ({
  handleChangePlan,
  selectedPlan,
  plan,
  isWhatsInsideOpen,
  onWhatsInsideClick,
  isTabletOrMobile,
  appliedCode,
}: Props) => {
  const theme = useTheme();
  const { planType: planName, planCycle } = useMemo(() => {
    return splitPlanName(plan.name);
  }, [plan.name]);

  const monthlyAmount =
    planCycle === PlanCycle.Monthly ? plan.amount : plan.amount / 12;

  return (
    <Flex
      onClick={() => {
        if (planName !== PlanType.Free) {
          handleChangePlan(plan.name);
        }
      }}
      css={{
        width: "100%",
        borderRadius: 4,
        padding: isTabletOrMobile ? "5px 8px" : "4px 12px 4px 18px",
        border: `1px solid ${
          plan.name === selectedPlan ? theme.colors.primary : "#E0E0E0"
        }`,
        cursor: "pointer",
        position: "relative",
      }}
      role="button"
    >
      {plan.name === Plan.Premium_Yearly && !isTabletOrMobile && (
        <Tooltip
          overlayStyle={{ zIndex: 1100 }}
          overlayInnerStyle={{ color: "black" }}
          trigger="hover"
          placement={isTabletOrMobile ? "bottomLeft" : "top"}
          color="white"
          title="Average monthly cost of $33/month compared to $39/month"
        >
          <div
            css={{
              fontSize: isTabletOrMobile
                ? theme.fontSizes.smaller + 1
                : theme.fontSizes.small,
              background: theme.colors.seaGreen,
              color: "#FFF",
              borderRadius: theme.radii.smaller,
              padding: isTabletOrMobile ? "0px 3px" : "2px 4px",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            Save 15%
          </div>
        </Tooltip>
      )}
      <Flex
        flex="1"
        justify="space-between"
        align="center"
        css={{ height: "57px" }}
      >
        <Flex
          gap={isTabletOrMobile ? 10 : 20}
          flex="none"
          align="center"
          css={{ width: "fit-content" }}
        >
          <div css={{ width: 20 }}>
            {planName !== PlanType.Free && (
              <Radio
                hideBorder
                checked={selectedPlan === plan.name}
                variant="primary"
              />
            )}
          </div>
          <div>
            <Text
              fontSize={isTabletOrMobile ? "default" : "large"}
              bold
              css={{ marginTop: isTabletOrMobile ? 0 : 3 }}
            >
              {planName}{" "}
              {planCycle === PlanCycle.Monthly ? PlanCycle.Monthly : "Annually"}
            </Text>
            {isTabletOrMobile && (
              <Text
                css={{
                  color:
                    plan.name === selectedPlan
                      ? theme.colors.primary
                      : "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
                fontSize="small"
                onClick={onWhatsInsideClick}
              >
                Whats inisde?{" "}
                <Icon
                  path={isWhatsInsideOpen ? mdiChevronUp : mdiChevronDown}
                  size="14px"
                />
              </Text>
            )}
          </div>
        </Flex>
        <Flex flex="none" gap={1} align="end">
          <Flex gap={1} direction="column" justify="center">
            <Flex wrap="wrap" justify="space-between" align="baseline">
              <Flex align="center" gap={4}>
                <Text
                  bolder
                  css={{
                    lineHeight: "45px",
                    fontSize: isTabletOrMobile ? "23px" : "26px",
                  }}
                >
                  {currencyFormatter(monthlyAmount / 100, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                </Text>
                <Text
                  fontSize={isTabletOrMobile ? "medium" : "small"}
                  css={{
                    opacity: 0.75,
                    marginTop: isTabletOrMobile ? 0 : "25px",
                  }}
                >
                  / {"   "}
                  {PlanCycle.Monthly.replace("ly", "").toLowerCase()}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
