import { isBoolean, isEmpty, isNull, isNumber, omitBy } from "lodash";

export function cloneObject<T = any>(obj) {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export function checkNumberValidity(val: any): boolean {
  return isNumber(val) && !isNaN(val) && isFinite(val);
}

export function isValidValue(v: any) {
  return (
    isNull(v) ||
    isBoolean(v) ||
    (isNumber(v) && !isNaN(v) && isFinite(v)) ||
    !isEmpty(v)
  );
}

export function removeInvalidValues(obj: { [key: string]: any }) {
  return omitBy(obj, (v) => !isValidValue(v));
}
