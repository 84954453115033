export const getIconForCardType = (type: string) => {
  const suffix = "-card.svg";
  switch (type) {
    case "visa":
      return `/visa${suffix}`;
    case "amex":
      return `/amex${suffix}`;
    case "discover":
      return `/discover${suffix}`;
    case "jcb":
      return `/jcb${suffix}`;
    case "unionpay":
      return `/unionpay${suffix}`;
    default:
      return `/other${suffix}`;
  }
};
