import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { DrawerTypes } from "./drawerTypes";

interface DrawerProps {
  drawerType: DrawerTypes | null;
  setShow: Dispatch<SetStateAction<DrawerTypes | null>>;
  showDrawer(val: DrawerTypes | null): void;
  hideDrawer(): void;
}

const DrawerContext = createContext<DrawerProps | null>(null);

export const DrawerProvider = ({ children }) => {
  const [drawerType, setShow] = useState<DrawerTypes | null>(null);

  function showDrawer(val: DrawerTypes | null) {
    setShow(val);
  }
  function hideDrawer() {
    setShow(null);
  }

  return (
    <DrawerContext.Provider
      value={{ drawerType, setShow, showDrawer, hideDrawer }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawer = () => {
  const values = useContext(DrawerContext);
  if (!values) throw new Error("Cannot use Drawer outside of Provider");
  return values;
};

export default useDrawer;
