import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const CrossIcon: FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "21"}
      height={height || "21"}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M19.437 19.4375L10.2182 10.2187L19.437 0.99995"
        stroke={color || "#5B5B5B"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M1 19.4375L10.2188 10.2187L1 0.99995"
        stroke={color || "#5B5B5B"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CrossIcon;
