import { useEffect, useState } from "react";

type ITimerStatus = "idle" | "running" | "paused";

export const useStopWatch = (timerName: string) => {
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout>();
  const [timer, setTimer] = useState(0);
  const [timerStatus, setTimerStatus] = useState<ITimerStatus>("idle");

  const startWatchTimer = () => {
    if (timerStatus !== "running") {
      const savedTime = parseInt(localStorage.getItem(timerName) || "0") || 0;
      setTimer(savedTime);
      const startTime = Date.now() - savedTime;
      const timerInterval = setInterval(() => {
        const newTime = Date.now() - startTime;

        setTimer(newTime);
        localStorage.setItem(timerName, newTime.toString());
      }, 1000);
      setTimerStatus("running");
      setTimerInterval(timerInterval);
    }
  };

  const stopWatchTimer = () => {
    resetTimer();
  };

  const pauseWatchTimer = () => {
    clearInterval(timerInterval);
    setTimerStatus("paused");
  };

  const resetTimer = () => {
    localStorage.removeItem(timerName);
    clearInterval(timerInterval);
    setTimer(0);
    setTimerStatus("idle");
  };

  return {
    timer,
    resetTimer,
    stopWatchTimer,
    startWatchTimer,
    pauseWatchTimer,
  };
};
