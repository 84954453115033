import { Dispatch, SetStateAction, createContext, useState } from "react";
import { Step } from "react-joyride";

export const TourContext = createContext<{
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  steps: Step[];
  setSteps: Dispatch<SetStateAction<Step[]>>;
  isTourPaused: boolean;
  setIsTourPaused: Dispatch<SetStateAction<boolean>>;
}>({
  currentStep: 0,
  setCurrentStep: () => {},
  steps: [],
  setSteps: () => {},
  isTourPaused: false,
  setIsTourPaused: () => {},
});

export const TourProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<Step[]>([]);
  const [isTourPaused, setIsTourPaused] = useState<boolean>(false);

  return (
    <TourContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        setSteps,
        steps,
        isTourPaused,
        setIsTourPaused,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};
