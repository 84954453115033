import { User } from "backend/types/user.type";
import { PlanCycle, PlanType } from "backend/utils/plan";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { countyMetroMap } from "lib/options/countyMetroMap";
import { freeExploratories } from "lib/options/exploratory";
import { metroList } from "lib/options/metroList";
import zipStateMap from "lib/options/zipStateMap";
import Stripe from "stripe";
import { Geo } from "types/MapContext";
import { CombinedDatapoints } from "types/cube";
import { EngageUserObject, UserData } from "types/user";
import { removeInvalidValues } from "./common";
import parseJwt from "./parseJWT";

export const isAlreadyAuthorizedOnThisDevice = (
  authTimes: number[],
  currentAuthTime: number,
) => {
  return authTimes.some((time) => time === currentAuthTime);
};

export const isMaxAuthDevicesLimitReached = (authTimes: number[]) => {
  const maxAuthsAllowed = parseInt(
    process.env.NEXT_PUBLIC_MAX_AUTHS_ALLOWED as string,
  );
  return authTimes.length >= maxAuthsAllowed;
};

export const isUnAuthorizedOnThisDevice = (
  authTimes: number[],
  currentAuthTime: number,
) => {
  return authTimes[0] < currentAuthTime;
};

export const updateUser = async (id: string, userData: Partial<User>) => {
  return await updateDoc(doc(db, "users", id), userData);
};

export const removeAuthTime = async (user) => {
  const { auth_time } = parseJwt(user.accessToken);
  const authTime = parseInt(auth_time);
  const authTimes = user?.authTimes?.filter((time) => time !== authTime) || [];
  await updateUser(user?.id!, { authTimes });
};

export const hasAccessToPremiumArea = (
  user: Partial<UserData>,
  row: any,
  exploratory: CombinedDatapoints | false,
  geo: Geo,
) => {
  const isFreeExploratory = !exploratory
    ? false
    : freeExploratories.includes(exploratory);
  if (user.isFreeUser && !isFreeExploratory) return false;
  let isAreaFromPremiumState = true;

  if (user.canAccessBasicOnly && !isFreeExploratory) {
    if (geo === Geo.STATE) {
      isAreaFromPremiumState = !!user.premiumStates?.some(
        (state) => state.value === row.geo_code || state.value === row.GEOID,
      );
    }
    if (geo === Geo.METRO) {
      isAreaFromPremiumState = !!user.premiumStates?.some(
        (state) =>
          isMetroWithinState(row.geo_code, state.value) ||
          isMetroWithinState(row.GEOID, state.value),
      );
    }
    if (geo === Geo.COUNTY) {
      isAreaFromPremiumState = !!user.premiumStates?.some(
        (state) => state.value === row.state_code,
      );
    }
    if (geo === Geo.ZIP) {
      isAreaFromPremiumState = !!user.premiumStates?.some(
        (state) => state.value === zipStateMap[Number(row.geo_code).toString()],
      );
    }
  }

  return isAreaFromPremiumState;
};

export function createEngageUserObject({
  user,
  stripeObject,
  overwrite,
}: {
  user: User;
  stripeObject?: Stripe.InvoiceLineItem;
  overwrite?: Partial<EngageUserObject> & { [key: string]: any };
}) {
  const countyStateMetroMap = countyMetroMap.find(
    (item) => item.cbsacode === user?.defaultMetro?.value,
  );
  const state = countyStateMetroMap?.statename;

  const nextBillingInterval = stripeObject
    ? stripeObject?.plan?.interval === "month"
      ? PlanCycle.Monthly
      : PlanCycle.Yearly
    : user.nextBillingInterval;

  const isPremium =
    user.plan === PlanType.Premium &&
    user.stripeSubscriptionStatus === "active";

  const isBasic =
    user.plan === PlanType.State_Level_Access &&
    user.stripeSubscriptionStatus === "active";

  const userAttributes: EngageUserObject & { [key: string]: any } = {
    plan: user.plan || PlanType.Free,
    planId: user.planId || PlanType.Free,
    role: user.whoAreYou,
    metro: user.defaultMetro?.label,
    name: user.name,
    email: user.email,
    amount: (stripeObject?.amount || 0) / 100 || user.nextBillableAmount / 100,
    cadence: nextBillingInterval,
    isBasic,
    isPremium,
    state,

    ...overwrite,
  };

  return removeInvalidValues(userAttributes);
}

function isMetroWithinState(metroId: string, stateId: string) {
  const metro = metroList.find(
    (metro) =>
      `${metro.value}` === metroId && metro["FIPS State Code"] === stateId,
  );

  return !!metro;
}
