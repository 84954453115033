interface Config {
  [key: string]: {
    code: string;
    column: string;
    hasCurrent?: boolean;
    hasTimeSeries?: boolean;
    hasScoreCurrent?: boolean;
    hasScoreTimeSeries?: boolean;
    premium?: boolean;
    raw?: boolean;
    secondaryDatapoints: {
      code: string;
      column: string;
      parent?: string;
      interval?: number;
      yearly?: boolean;
      years?: number[];
      isYearlyValue?: boolean;
      dates?: string[];
      latestValue?: boolean;
    }[];
  };
}

const configs: Config = {
  home_value: {
    code: "home_value",
    column: "home_value",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [
      {
        code: "fair_home_value",
        column: "fair_home_value",
      }
    ],
  },
  home_value_growth_rate: {
    code: "home_value_growth_rate",
    column: "home_value_growth_yoy",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
        interval: 1,
        yearly: true,
      },
      {
        code: "cpi_yoy_percentage",
        column: "cpi_yoy_percentage",
      }
    ],
  },
  home_value_five_year_growth_rate: {
    code: "home_value_five_year_growth_rate",
    column: "home_value_growth_five_year",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
        interval: 5,
        yearly: true,
      },
    ],
  },
  yoy_inventory_change_percentage: {
    code: "yoy_inventory_change_percentage",
    column: "sale_inventory_growth_yoy",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [
      {
        code: "total_active_inventory",
        column: "for_sale_inventory",
        interval: 1,
        yearly: true,
      },
    ],
  },
  population: {
    code: "population",
    column: "population",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  median_household_income: {
    code: "median_household_income",
    column: "median_household_income",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  rent_for_apartments: {
    code: "rent_for_apartments",
    column: "rental_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  rent_for_houses: {
    code: "rent_for_houses",
    column: "rent_for_houses",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  home_ownership_rate: {
    code: "home_ownership_rate",
    column: "home_ownership_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "ownership_hhs",
        column: "ownership_hhs",
      },
      {
        code: "households",
        column: "households",
      },
    ],
  },
  households: {
    code: "households",
    column: "households",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  median_age: {
    code: "median_age",
    column: "median_age",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  days_on_market: {
    code: "days_on_market",
    column: "days_on_market",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  yoy_days_on_market: {
    code: "yoy_days_on_market",
    column: "days_on_market_growth_yoy",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "days_on_market",
        column: "days_on_market",
        interval: 1,
        yearly: true,
      },
    ],
  },
  mom_active_inventory_change_percentage: {
    code: "mom_active_inventory_change_percentage",
    column: "sale_inventory_growth_mom",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "total_active_inventory",
        column: "for_sale_inventory",
        interval: 1,
        yearly: false,
      },
    ],
  },
  inventory_percentage_of_houses: {
    code: "inventory_percentage_of_houses",
    column: "inventory_as_percentage_of_houses",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "total_active_inventory",
        column: "for_sale_inventory",
      },
      {
        code: "absentee_owned_units",
        column: "absentee_owned_houses",
        isYearlyValue: true,
      },
      {
        code: "ownership_hhs",
        column: "ownership_hhs",
        isYearlyValue: true,
      },
    ],
  },
  property_tax_rate: {
    code: "property_tax_rate",
    column: "property_tax_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "property_tax",
        column: "property_tax",
      },
      {
        code: "home_value",
        column: "home_value",
      },
    ],
  },
  poverty_percentage: {
    code: "poverty_percentage",
    column: "poverty_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "population_living_in_poverty",
        column: "population_living_in_poverty",
      },
      {
        code: "population",
        column: "population",
      },
    ],
  },
  mortgaged_home_percentage: {
    code: "mortgaged_home_percentage",
    column: "mortgaged_home_percentage",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "housing_units_with_a_mortgage",
        column: "housing_units_with_a_mortgage",
      },
      {
        code: "ownership_hhs",
        column: "ownership_hhs",
      },
    ],
  },
  rent_percentage_of_income: {
    code: "rent_percentage_of_income",
    column: "rent_as_percentage_of_income",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "yearly_apartment_rent",
        column: "yearly_apartment_rent",
      },
      {
        code: "median_household_income",
        column: "median_household_income",
        isYearlyValue: true,
      },
    ],
  },
  population_growth_rate: {
    code: "population_growth_rate",
    column: "population_growth",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "population",
        column: "population",
        interval: 5,
        yearly: true,
      },
    ],
  },
  median_income_growth_rate: {
    code: "median_income_growth_rate",
    column: "income_growth",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "median_household_income",
        column: "median_household_income",
        interval: 5,
        yearly: true,
      },
    ],
  },
  housing_unit_growth: {
    code: "housing_unit_growth",
    column: "housing_unit_growth_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "housing_units",
        column: "housing_units",
        interval: 5,
        yearly: true,
      },
    ],
  },
  housing_units: {
    code: "housing_units",
    column: "housing_units",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  home_value_to_earnings_ratio: {
    code: "home_value_to_earnings_ratio",
    column: "value_income_ratio",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
      },
      {
        code: "median_household_income",
        column: "median_household_income",
        isYearlyValue: true,
      },
    ],
  },
  mom_home_value_growth: {
    code: "mom_home_value_growth",
    column: "home_value_growth_mom",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
        interval: 1,
        yearly: false,
      },
    ],
  },
  share_of_listings_with_price_cut: {
    code: "share_of_listings_with_price_cut",
    column: "price_cut_percentage",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "listings_with_price_cut",
        column: "listings_with_price_cut",
      },
      {
        code: "total_inventory",
        column: "total_inventory",
      },
    ],
  },
  total_migration: {
    code: "total_migration",
    column: "migration_total",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  migration_percentage: {
    code: "migration_percentage",
    column: "migration_percent_of_population",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "total_migration",
        column: "migration_total",
      },
      {
        code: "population",
        column: "population",
      },
    ],
  },
  cap_rate: {
    code: "cap_rate",
    column: "cap_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "gross_rent_income",
        column: "gross_rent_income",
      },
      {
        code: "expenses",
        column: "expenses",
      },
      {
        code: "net_rent_income",
        column: "net_rent_income",
      },
      {
        code: "home_value",
        column: "home_value",
      },
      {
        code: "monthly_treasury_avg_yield",
        column: "monthly_treasury_avg_yield",
      }
    ],
  },
  absentee_owner_percentage: {
    code: "absentee_owner_percentage",
    column: "shadow_inventory_percentage",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "absentee_owned_units",
        column: "absentee_owned_houses",
      },
      {
        code: "ownership_hhs",
        column: "ownership_hhs",
      },
    ],
  },
  over_under_valued_percentage: {
    code: "over_under_valued_percentage",
    column: "over_valued_percent",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
      },
      {
        code: "fair_home_value",
        column: "fair_home_value",
      },
    ],
  },
  fair_home_value: {
    code: "fair_home_value",
    column: "fair_home_value",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  typical_house_payment: {
    code: "typical_house_payment",
    column: "monthly_house_payment",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "principle_interest",
        column: "principle_interest",
      },
      {
        code: "insurance_taxes_misc_costs",
        column: "insurance_taxes_misc_costs",
      },
      {
        code: "mortgage_rate",
        column: "mortgage_rate",
      },
    ],
  },
  salary_to_afford_house: {
    code: "salary_to_afford_house",
    column: "salary_to_afford_house",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "typical_yearly_house_payment",
        column: "typical_yearly_house_payment"
      },
      {
        code: "typical_house_payment",
        column: "monthly_house_payment"
      },
      {
        code: "median_household_income",
        column: "median_household_income"
      }
    ],
  },
  house_payment_rate_of_med_income: {
    code: "house_payment_rate_of_med_income",
    column: "house_payment_as_percentage_of_income",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "typical_yearly_house_payment",
        column: "typical_yearly_house_payment",
      },
      {
        code: "median_household_income",
        column: "median_household_income",
        isYearlyValue: true,
      },
      {
        code: "mortgage_rate",
        column: "mortgage_rate",
      },
    ],
  },
  buy_versus_rent_percentage: {
    code: "buy_versus_rent_percentage",
    column: "buy_v_rent_differential",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "typical_yearly_house_payment",
        column: "typical_yearly_house_payment",
      },
      {
        code: "yearly_apartment_rent",
        column: "yearly_apartment_rent",
      },
    ],
  },
  typical_yearly_house_payment: {
    code: "typical_yearly_house_payment",
    column: "typical_yearly_house_payment",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  yearly_apartment_rent: {
    code: "yearly_apartment_rent",
    column: "yearly_apartment_rent",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  expenses: {
    code: "expenses",
    column: "expenses",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  gross_rent_income: {
    code: "gross_rent_income",
    column: "gross_rent_income",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  total_inventory: {
    code: "total_inventory",
    column: "total_inventory",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  listings_with_price_cut: {
    code: "listings_with_price_cut",
    column: "listings_with_price_cut",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  mortgage_rate: {
    code: "mortgage_rate",
    column: "mortgage_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  insurance_taxes_misc_costs: {
    code: "insurance_taxes_misc_costs",
    column: "insurance_taxes_misc_costs",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  principle_interest: {
    code: "principle_interest",
    column: "principle_interest",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  population_living_in_poverty: {
    code: "population_living_in_poverty",
    column: "population_living_in_poverty",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  ownership_hhs: {
    code: "ownership_hhs",
    column: "ownership_hhs",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  total_active_inventory: {
    code: "total_active_inventory",
    column: "for_sale_inventory",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  housing_units_with_a_mortgage: {
    code: "housing_units_with_a_mortgage",
    column: "housing_units_with_a_mortgage",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  absentee_owned_units: {
    code: "absentee_owned_units",
    column: "absentee_owned_houses",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  active_listing_count_yy: {
    code: "active_listing_count_yy",
    column: "active_listing_count_yy",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  median_taxes: {
    code: "median_taxes",
    column: "median_taxes",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  percent_crash_great_recession: {
    code: "percent_crash_great_recession",
    column: "percentage_crash",
    hasCurrent: true,
    hasTimeSeries: false,
    premium: true,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
        years: [2007, 2012],
      },
    ],
  },
  property_tax: {
    code: "property_tax",
    column: "property_tax",
    hasCurrent: true,
    hasTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  net_rent_income: {
    code: "net_rent_income",
    column: "net_rent_income",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  migration_population: {
    code: "migration_population",
    column: "migration_population",
    hasCurrent: false,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  rent_growth_yoy: {
    code: "rent_growth_yoy",
    column: "rent_growth_yoy",
    hasCurrent: true,
    hasTimeSeries: false,
    premium: true,
    secondaryDatapoints: [
        {
          code: "rent_for_apartments",
          column: "rental_rate",
          interval: 1,
          yearly: true,
        },
    ],
  },
  per_change_home_value_june: {
    code: "per_change_home_value_june",
    column: "per_change_home_value_june",
    hasCurrent: true,
    hasTimeSeries: false,
    premium: true,
    secondaryDatapoints: [
      {
        parent: "home_value",
        code: "home_value",
        column: "home_value",
      },
      {
        code: "home_value",
        column: "home_value",
        dates: ["6/2022"],
      },
    ],
  },
  median_listing_price: {
    code: "median_listing_price",
    column: "median_listing_price",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  median_listing_price_yoy: {
    code: "median_listing_price_yoy",
    column: "median_listing_price_yoy",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "median_listing_price",
        column: "median_listing_price",
        interval: 1,
        yearly: true,
      },
    ],
  },
  new_listing_count: {
    code: "new_listing_count",
    column: "new_listing_count",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  new_listing_count_yoy: {
    code: "new_listing_count_yoy",
    column: "new_listing_count_yoy",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [
      {
        code: "new_listing_count",
        column: "new_listing_count",
        interval: 1,
        yearly: true,
      },
    ],
  },
  for_sale_inventory_v_avg: {
    code: "for_sale_inventory_v_avg",
    column: "for_sale_inventory_v_avg",
    hasCurrent: true,
    hasTimeSeries: false,
    premium: true,
    secondaryDatapoints: [
      {
        code: "total_active_inventory",
        column: "for_sale_inventory",
      },
      {
        code: "long_term_fsi_avg",
        column: "long_term_fsi_avg",
      },
    ],
  },
  long_term_fsi_avg: {
    code: "long_term_fsi_avg",
    column: "long_term_fsi_avg",
    hasCurrent: false,
    hasTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  employment_level: {
    code: "employment_level",
    column: "employment_level",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  unemployment_rate: {
    code: "unemployment_rate",
    column: "unemployment_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  unemployment_level: {
    code: "unemployment_level",
    column: "unemployment_level",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  total_nonfarm_payroll: {
    code: "total_nonfarm_payroll",
    column: "total_nonfarm_payroll",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  total_private_weekly_hours: {
    code: "total_private_weekly_hours",
    column: "total_private_weekly_hours",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  total_private_hourly_earnings: {
    code: "total_private_hourly_earnings",
    column: "total_private_hourly_earnings",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  trailing_twelve_months_total_private_weekly_hours: {
    code: "trailing_twelve_months_total_private_weekly_hours",
    column: "trailing_twelve_months_total_private_weekly_hours",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  trailing_twelve_months_total_private_hourly_earnings: {
    code: "trailing_twelve_months_total_private_hourly_earnings",
    column: "trailing_twelve_months_total_private_hourly_earnings",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  trailing_weekly_earnings: {
    code: "trailing_weekly_earnings",
    column: "trailing_weekly_earnings",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  trailing_twelve_months_building_permits: {
    code: "trailing_twelve_months_building_permits",
    column: "trailing_twelve_months_building_permits",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  trailing_twelve_months_single_permits: {
    code: "trailing_twelve_months_single_permits",
    column: "trailing_twelve_months_single_permits",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  trailing_twelve_months_multi_permits: {
    code: "trailing_twelve_months_multi_permits",
    column: "trailing_twelve_months_multi_permits",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: true,
    secondaryDatapoints: [],
  },
  building_permit_percentage: {
    code: "building_permit_percentage",
    column: "building_permit_percentage",
    hasCurrent: true,
    hasTimeSeries: true,
    premium: false,
    secondaryDatapoints: [
      {
        code: "building_permits",
        column: "trailing_twelve_months_building_permits",
      },
      {
        code: "migration_population",
        column: "migration_population",
        latestValue: true,
      },
    ],
  },

  home_price_momentum_score: {
    code: "home_price_momentum_score",
    column: "home_price_momentum_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [
      {
        code: "home_value",
        column: "home_value",
        interval: 1,
        yearly: true,
      },
    ],
  },
  recent_appreciation_score: {
    code: "recent_appreciation_score",
    column: "recent_appreciaton_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  days_on_market_score: {
    code: "days_on_market_score",
    column: "days_on_market_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  mortgage_rates_score: {
    code: "mortgage_rates_score",
    column: "mortgage_rates_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [
      {
        code: "mortgage_rate",
        column: "mortgage_rate",
        interval: 1,
        yearly: true,
      },
    ],
  },
  inventory_levels_score: {
    code: "inventory_levels_score",
    column: "inventory_levels_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  price_cuts_score: {
    code: "price_cuts_score",
    column: "price_cuts_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },

  recent_appreciation_percentage: {
    code: "recent_appreciation_percentage",
    column: "recent_appreciation_percentage",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: true,
    secondaryDatapoints: [],
  },
  days_on_market_percentage: {
    code: "days_on_market_percentage",
    column: "days_on_market_percentage",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: true,
    secondaryDatapoints: [],
  },
  mortgage_rates_yoy_percentage: {
    code: "mortgage_rates_yoy_percentage",
    column: "mortgage_rates_yoy_percentage",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: true,
    secondaryDatapoints: [],
  },
  mortgage_rates_five_year_percentage: {
    code: "mortgage_rates_five_year_percentage",
    column: "mortgage_rates_five_year_percentage",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: true,
    secondaryDatapoints: [],
  },
  inventory_levels_percentage: {
    code: "inventory_levels_percentage",
    column: "inventory_levels_percentage",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: true,
    secondaryDatapoints: [],
  },
  price_cuts_percentage: {
    code: "price_cuts_percentage",
    column: "price_cuts_percentage",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: true,
    secondaryDatapoints: [],
  },

  home_buyer_score: {
    code: "home_buyer_score",
    column: "home_buyer_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  long_term_appreciation_percentile: {
    code: "long_term_appreciation_percentile",
    column: "long_term_appreciation_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  poverty_rate_percentile: {
    code: "poverty_rate_percentile",
    column: "poverty_rate_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: false,
    secondaryDatapoints: [],
  },
  median_household_income_percentile: {
    code: "median_household_income_percentile",
    column: "median_household_income_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: false,
    secondaryDatapoints: [],
  },
  demographic_growth_percentile: {
    code: "demographic_growth_percentile",
    column: "demographic_growth_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  overvaluation_percentile: {
    code: "overvaluation_percentile",
    column: "overvaluation_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: false,
    secondaryDatapoints: [],
  },
  value_income_ratio_percentile: {
    code: "value_income_ratio_percentile",
    column: "value_income_ratio_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: false,
    secondaryDatapoints: [],
  },
  wealth_income_percentile: {
    code: "wealth_income_percentile",
    column: "wealth_income_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  cap_rate_percentile: {
    code: "cap_rate_percentile",
    column: "cap_rate_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },
  rent_percentile: {
    code: "rent_percentile",
    column: "rent_percentile",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    secondaryDatapoints: [],
  },

  investor_score: {
    code: "investor_score",
    column: "investor_score",
    hasCurrent: false,
    hasTimeSeries: false,
    hasScoreCurrent: true,
    hasScoreTimeSeries: true,
    premium: false,
    raw: false,
    secondaryDatapoints: [],
  },
  vacancy_rate: {
    code: "vacancy_rate",
    column: "vacancy_rate",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [
      {
        code: "vacant_house",
        column: "vacant_house",
      },
      {
        code: "housing_units",
        column: "housing_units",
      },
    ],
  },
  vacant_house: {
    code: "vacant_house",
    column: "vacant_house",
    hasCurrent: true,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: true,
    secondaryDatapoints: [],
  },
  cpi_yoy_percentage: {
    code: "cpi_yoy_percentage",
    column: "cpi_yoy_percentage",
    hasCurrent: false,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: false,
    secondaryDatapoints: [],
  },
  monthly_treasury_avg_yield: {
    code: "monthly_treasury_avg_yield",
    column: "monthly_treasury_avg_yield",
    hasCurrent: false,
    hasTimeSeries: true,
    hasScoreCurrent: false,
    hasScoreTimeSeries: false,
    premium: false,
    secondaryDatapoints: [],
  },
};

export default configs;
