import styles from "../styles/Spinner.module.css";
import { Center } from "./Center";
import { Flex } from "./Flex";

const Spinner = ({ label = "", isAbsolute = true }) => {
  const Spin = () => {
    return (
      <svg className={styles.spinner} viewBox="0 0 50 50">
        <circle
          className={styles.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="6"
        ></circle>
      </svg>
    );
  };
  return (
    <>
      {isAbsolute ? (
        <Center>
          <Spin />
          {/* {label} */}
        </Center>
      ) : (
        <Flex css={{ alignSelf: "center" }}>
          <Spin />
        </Flex>
      )}
    </>
  );
};

export default Spinner;
