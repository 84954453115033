import { Price } from "backend/types/stripe.type";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PlanService from "services/PlanService";

export const usePlans = () => {
  const [plans, setPlans] = useState<Price[]>([]);

  useEffect(() => {
    PlanService.getPlans()
      .then(({ data }) => {
        setPlans(data.sort((a, b) => a.amount - b.amount));
      })
      .catch((e) => {
        toast(e.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }, []);

  return plans;
};
