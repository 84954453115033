import { loadStripe } from "@stripe/stripe-js";

export const getStripePromise = async (retries = 3) => {
  let retry = 0;
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  while (retry < retries) {
    try {
      const stripe = await loadStripe(
        process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
      );
      return stripe;
    } catch (error) {
      retry++;
      if (retry >= retries) {
        return null;
      }
      await delay(400);
    }
  }

  return null;
};
