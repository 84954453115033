/** @jsxImportSource @emotion/react */

import { Theme, useTheme } from "@emotion/react";
import React, { ElementType, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLElement> {
  tagName?: string;
  align?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  padding?: string;
  margin?: string;
  bold?: boolean;
  large?: boolean;
  heading?: boolean;
  medium?: boolean;
  small?: boolean;
  heading1?: boolean;
  bolder?: boolean;
  fontSize?: keyof Theme["fontSizes"];
  fontWeight?: keyof Theme["fontWeights"];
  transform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width"
    | "full-size-kana";
}

export const Text = ({
  tagName,
  align,
  padding,
  margin,
  bold,
  large,
  heading,
  medium,
  small,
  transform,
  heading1,
  bolder,
  fontSize,
  fontWeight,
  ...props
}: Props) => {
  const theme = useTheme();
  const paddingMap = {
    small: theme.padding.small,
    default: theme.padding.default,
    medium: theme.padding.default,
    large: theme.padding.large,
  };

  const marginMap = {
    small: theme.padding.small,
    default: theme.padding.default,
    medium: theme.padding.default,
    large: theme.padding.large,
  };

  const Tag = tagName || ("p" as ElementType);

  return (
    <Tag
      css={{
        textAlign: align ? align : "left",
        padding: (padding && paddingMap[padding]) || 0,
        margin: (margin && marginMap[margin]) || 0,
        fontWeight: bolder
          ? theme.fontWeights.heading
          : bold
            ? theme.fontWeights.bold
            : fontWeight
              ? theme.fontWeights[fontWeight]
              : theme.fontWeights.body,
        fontSize: heading1
          ? theme.fontSizes.heading1
          : large
            ? theme.fontSizes.large
            : heading
              ? theme.fontSizes.heading
              : small
                ? theme.fontSizes.small
                : fontSize
                  ? theme.fontSizes[fontSize]
                  : theme.fontSizes.default,
        textTransform: transform || "inherit",
      }}
      {...props}
    />
  );
};
