/** @jsxImportSource @emotion/react */

import React from "react";
import { Flex } from "./Flex";
import { Button } from "./Button";
import { Text } from "./Text";
import Image from "next/image";

export const GoogleButton = ({ onClick, label }) => {
  return (
    <Button
      css={(theme) => ({
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        border: "1px solid #ccc",
        marginTop: 32,
        width: "100%",
        paddingLeft: 16,
      })}
      onClick={onClick}
    >
      <Flex align="center">
        <Image width={16} height={16} alt="google" src="/google.svg" />{" "}
        <Text css={() => ({ marginLeft: 52 })}>{label}</Text>
      </Flex>
    </Button>
  );
};
