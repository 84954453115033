import cube from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import createCache from "@emotion/cache";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { ConfigProvider } from "antd";
import { DrawerProvider } from "components/Drawer/useDrawer";
import { useAuthModal } from "hooks/useAuthModal";
import { FeedbackPopUpProvider } from "hooks/useFeedbackPopUp";
import { FiltersProvider } from "hooks/useFilters";
import { GeoSearchProvider } from "hooks/useGeoSearch";
import { MapDataProvider } from "hooks/useMapData";
import { ProgressQueryProvider } from "hooks/useProgressQuery";
import useUser from "hooks/useUser";
import { WelcomePopUpProvider } from "hooks/useWelcomePopUp";
import {
  AuthModalContext,
  ResponsiveContext,
  UserContext,
  CardUpdateContext,
} from "lib/context";
import HttpTransport from "lib/cube/HttpTransport";
import theme from "lib/theme";
import { ReactNode, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PaymentModalProvider } from "./PaymentModalProvider";
import { PlanModalProvider } from "./PlanModalProvider";
import { TourProvider } from "./TourProvider";
import jwt from "jsonwebtoken";

const cache = createCache({ key: "next" });

ConfigProvider.config({
  theme: {
    primaryColor: theme.colors.primary,
  },
});

const jwtToken = jwt.sign(
  {},
  process.env.NEXT_PUBLIC_CUBE_API_SECRET as string,
);
const cubejsApi = cube("", {
  apiUrl: process.env.NEXT_PUBLIC_CUBE_API_URL as string,
  transport: new HttpTransport({
    apiUrl: process.env.NEXT_PUBLIC_CUBE_API_URL as string,
    authorization: jwtToken as string,
  }),
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 975px)" });
  const isTiny = useMediaQuery({ query: "(max-width: 424px)" });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 976px) and (max-width: 1439px)",
  });
  const userData = useUser();
  const authValues = useAuthModal();

  const [isTabletOrMobile, setTabletOrMobile] = useState<boolean>(false);
  const [cardUpdating, setCardUpdating] = useState<string>("");

  useEffect(() => {
    setTabletOrMobile(isSmallScreen);
  }, [isSmallScreen]);

  return (
    <CacheProvider value={cache}>
      <CardUpdateContext.Provider value={{ cardUpdating, setCardUpdating }}>
        <ThemeProvider theme={theme}>
          <ResponsiveContext.Provider
            value={{ isTabletOrMobile, isMediumScreen, isTiny }}
          >
            <CubeProvider cubejsApi={cubejsApi}>
              <AuthModalContext.Provider value={authValues}>
                <ProgressQueryProvider>
                  <UserContext.Provider value={userData}>
                    <TourProvider>
                      <PlanModalProvider>
                        <WelcomePopUpProvider>
                          <FeedbackPopUpProvider>
                            <PaymentModalProvider>
                              <GeoSearchProvider>
                                <FiltersProvider>
                                  <MapDataProvider>
                                    <DrawerProvider>{children}</DrawerProvider>
                                  </MapDataProvider>
                                </FiltersProvider>
                              </GeoSearchProvider>
                            </PaymentModalProvider>
                          </FeedbackPopUpProvider>
                        </WelcomePopUpProvider>
                      </PlanModalProvider>
                    </TourProvider>
                  </UserContext.Provider>
                </ProgressQueryProvider>
              </AuthModalContext.Provider>
            </CubeProvider>
          </ResponsiveContext.Provider>
        </ThemeProvider>
      </CardUpdateContext.Provider>
    </CacheProvider>
  );
};
