export interface CountyMetroMap {
  cbsacode: number | string;
  metrodivisioncode: number | string;
  csacode: number | string;
  cbsatitle: string;
  metropolitandivisiontitle: string;
  csatitle: string;
  countycountyequivalent: string;
  statename: string;
  fipsstatecode: number | string;
  fipscountycode: number | string;
  centraloutlyingcounty: string;
}

export const countyMetroMap: CountyMetroMap[] = [
  {
    cbsacode: "",
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "",
    statename: "",
    fipsstatecode: "",
    fipscountycode: "",
    centraloutlyingcounty: "",
  },
  {
    cbsacode: 33860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Montgomery, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Autauga County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19300,
    metrodivisioncode: "",
    csacode: 380,
    cbsatitle: "Daphne-Fairhope-Foley, AL",
    metropolitandivisiontitle: "",
    csatitle: "Mobile-Daphne-Fairhope, AL",
    countycountyequivalent: "Baldwin County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Bibb County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Blount County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Anniston-Oxford-Jacksonville, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Calhoun County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46740,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Valley, AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Chambers County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Chilton County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21460,
    metrodivisioncode: "",
    csacode: 222,
    cbsatitle: "Enterprise, AL",
    metropolitandivisiontitle: "",
    csatitle: "Dothan-Enterprise-Ozark, AL",
    countycountyequivalent: "Coffee County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22520,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Florence-Muscle Shoals, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Colbert County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45180,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Talladega-Sylacauga, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Coosa County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18980,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Cullman, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Cullman County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37120,
    metrodivisioncode: "",
    csacode: 222,
    cbsatitle: "Ozark, AL",
    metropolitandivisiontitle: "",
    csatitle: "Dothan-Enterprise-Ozark, AL",
    countycountyequivalent: "Dale County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Selma, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dallas County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Montgomery, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Elmore County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Gadsden, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Etowah County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20020,
    metrodivisioncode: "",
    csacode: 222,
    cbsatitle: "Aberdeen, SD",
    metropolitandivisiontitle: "",
    csatitle: "Dothan-Enterprise-Ozark, AL",
    countycountyequivalent: "Geneva County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tuscaloosa, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hale County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 65,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20020,
    metrodivisioncode: "",
    csacode: 222,
    cbsatitle: "Dothan, AL",
    metropolitandivisiontitle: "",
    csatitle: "Dothan-Enterprise-Ozark, AL",
    countycountyequivalent: "Henry County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 67,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20020,
    metrodivisioncode: "",
    csacode: 222,
    cbsatitle: "Dothan, AL",
    metropolitandivisiontitle: "",
    csatitle: "Dothan-Enterprise-Ozark, AL",
    countycountyequivalent: "Houston County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42460,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Scottsboro, AL",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Jackson County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Jefferson County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22520,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Florence-Muscle Shoals, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lauderdale County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19460,
    metrodivisioncode: "",
    csacode: 290,
    cbsatitle: "Decatur, AL",
    metropolitandivisiontitle: "",
    csatitle: "Huntsville-Decatur-Albertville, AL",
    countycountyequivalent: "Lawrence County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12220,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Auburn-Opelika, AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Lee County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26620,
    metrodivisioncode: "",
    csacode: 290,
    cbsatitle: "Huntsville, AL",
    metropolitandivisiontitle: "",
    csatitle: "Huntsville-Decatur-Albertville, AL",
    countycountyequivalent: "Limestone County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 83,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Montgomery, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lowndes County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 85,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26620,
    metrodivisioncode: "",
    csacode: 290,
    cbsatitle: "Huntsville, AL",
    metropolitandivisiontitle: "",
    csatitle: "Huntsville-Decatur-Albertville, AL",
    countycountyequivalent: "Madison County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10700,
    metrodivisioncode: "",
    csacode: 290,
    cbsatitle: "Albertville, AL",
    metropolitandivisiontitle: "",
    csatitle: "Huntsville-Decatur-Albertville, AL",
    countycountyequivalent: "Marshall County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33660,
    metrodivisioncode: "",
    csacode: 380,
    cbsatitle: "Mobile, AL",
    metropolitandivisiontitle: "",
    csatitle: "Mobile-Daphne-Fairhope, AL",
    countycountyequivalent: "Mobile County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Montgomery, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Montgomery County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19460,
    metrodivisioncode: "",
    csacode: 290,
    cbsatitle: "Decatur, AL",
    metropolitandivisiontitle: "",
    csatitle: "Huntsville-Decatur-Albertville, AL",
    countycountyequivalent: "Morgan County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tuscaloosa, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pickens County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 107,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Troy, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pike County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17980,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Columbus, GA-AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Russell County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "St. Clair County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 115,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Shelby County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45180,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Talladega-Sylacauga, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Talladega County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tuscaloosa, AL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Tuscaloosa County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13820,
    metrodivisioncode: "",
    csacode: 142,
    cbsatitle: "Birmingham-Hoover, AL",
    metropolitandivisiontitle: "",
    csatitle: "Birmingham-Hoover-Talladega, AL",
    countycountyequivalent: "Walker County",
    statename: "Alabama",
    fipsstatecode: 1,
    fipscountycode: 127,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Anchorage, AK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Anchorage Municipality",
    statename: "Alaska",
    fipsstatecode: 2,
    fipscountycode: 20,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fairbanks, AK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Fairbanks North Star Borough",
    statename: "Alaska",
    fipsstatecode: 2,
    fipscountycode: 90,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Juneau, AK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Juneau City and Borough",
    statename: "Alaska",
    fipsstatecode: 2,
    fipscountycode: 110,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ketchikan, AK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ketchikan Gateway Borough",
    statename: "Alaska",
    fipsstatecode: 2,
    fipscountycode: 130,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Anchorage, AK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Matanuska-Susitna Borough",
    statename: "Alaska",
    fipsstatecode: 2,
    fipscountycode: 170,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sierra Vista-Douglas, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cochise County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Flagstaff, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coconino County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Payson, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gila County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Safford, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Graham County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Phoenix-Mesa-Scottsdale, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Maricopa County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29420,
    metrodivisioncode: "",
    csacode: 332,
    cbsatitle: "Lake Havasu City-Kingman, AZ",
    metropolitandivisiontitle: "",
    csatitle: "Las Vegas-Henderson, NV-AZ",
    countycountyequivalent: "Mohave County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43320,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Show Low, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Navajo County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46060,
    metrodivisioncode: "",
    csacode: 536,
    cbsatitle: "Tucson, AZ",
    metropolitandivisiontitle: "",
    csatitle: "Tucson-Nogales, AZ",
    countycountyequivalent: "Pima County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Phoenix-Mesa-Scottsdale, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pinal County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35700,
    metrodivisioncode: "",
    csacode: 536,
    cbsatitle: "Nogales, AZ",
    metropolitandivisiontitle: "",
    csatitle: "Tucson-Nogales, AZ",
    countycountyequivalent: "Santa Cruz County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Prescott, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Yavapai County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Yuma, AZ",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Yuma County",
    statename: "Arizona",
    fipsstatecode: 4,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mountain Home, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Baxter County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fayetteville-Springdale-Rogers, AR-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Benton County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Harrison, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Boone County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Camden, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Calhoun County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Arkadelphia, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clark County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38220,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Pine Bluff, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Cleveland County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Magnolia, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Columbia County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27860,
    metrodivisioncode: "",
    csacode: 308,
    cbsatitle: "Jonesboro, AR",
    metropolitandivisiontitle: "",
    csatitle: "Jonesboro-Paragould, AR",
    countycountyequivalent: "Craighead County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Smith, AR-OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Crawford County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Crittenden County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30780,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Little Rock-North Little Rock-Conway, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Faulkner County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26300,
    metrodivisioncode: "",
    csacode: 284,
    cbsatitle: "Hot Springs, AR",
    metropolitandivisiontitle: "",
    csatitle: "Hot Springs-Malvern, AR",
    countycountyequivalent: "Garland County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30780,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Little Rock-North Little Rock-Conway, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Grant County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37500,
    metrodivisioncode: "",
    csacode: 308,
    cbsatitle: "Paragould, AR",
    metropolitandivisiontitle: "",
    csatitle: "Jonesboro-Paragould, AR",
    countycountyequivalent: "Greene County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31680,
    metrodivisioncode: "",
    csacode: 284,
    cbsatitle: "Malvern, AR",
    metropolitandivisiontitle: "",
    csatitle: "Hot Springs-Malvern, AR",
    countycountyequivalent: "Hot Spring County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Batesville, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Independence County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38220,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Pine Bluff, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Jefferson County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38220,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Pine Bluff, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Lincoln County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Texarkana, TX-AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Little River County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30780,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Little Rock-North Little Rock-Conway, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Lonoke County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fayetteville-Springdale-Rogers, AR-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Madison County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Texarkana, TX-AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Miller County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Blytheville, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mississippi County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Harrison, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Newton County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 101,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Camden, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ouachita County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30780,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Little Rock-North Little Rock-Conway, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Perry County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 105,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25760,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Helena-West Helena, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Phillips County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27860,
    metrodivisioncode: "",
    csacode: 308,
    cbsatitle: "Jonesboro, AR",
    metropolitandivisiontitle: "",
    csatitle: "Jonesboro-Paragould, AR",
    countycountyequivalent: "Poinsett County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Russellville, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pope County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30780,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Little Rock-North Little Rock-Conway, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Pulaski County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22620,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Forrest City, AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "St. Francis County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30780,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Little Rock-North Little Rock-Conway, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "Saline County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Smith, AR-OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sebastian County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 131,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "El Dorado, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Union County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fayetteville-Springdale-Rogers, AR-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Washington County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42620,
    metrodivisioncode: "",
    csacode: 340,
    cbsatitle: "Searcy, AR",
    metropolitandivisiontitle: "",
    csatitle: "Little Rock-North Little Rock, AR",
    countycountyequivalent: "White County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Russellville, AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Yell County",
    statename: "Arkansas",
    fipsstatecode: 5,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41860,
    metrodivisioncode: 36084,
    csacode: 488,
    cbsatitle: "San Francisco-Oakland-Hayward, CA",
    metropolitandivisiontitle: "Oakland-Hayward-Berkeley, CA",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Alameda County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Chico, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Butte County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41860,
    metrodivisioncode: 36084,
    csacode: 488,
    cbsatitle: "San Francisco-Oakland-Hayward, CA",
    metropolitandivisiontitle: "Oakland-Hayward-Berkeley, CA",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Contra Costa County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Crescent City, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Del Norte County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40900,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Sacramento--Roseville--Arden-Arcade, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "El Dorado County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23420,
    metrodivisioncode: "",
    csacode: 260,
    cbsatitle: "Fresno, CA",
    metropolitandivisiontitle: "",
    csatitle: "Fresno-Madera, CA",
    countycountyequivalent: "Fresno County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Eureka-Arcata-Fortuna, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Humboldt County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "El Centro, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Imperial County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bakersfield, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kern County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25260,
    metrodivisioncode: "",
    csacode: 546,
    cbsatitle: "Hanford-Corcoran, CA",
    metropolitandivisiontitle: "",
    csatitle: "Visalia-Porterville-Hanford, CA",
    countycountyequivalent: "Kings County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clearlake, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lake County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45000,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Susanville, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lassen County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31080,
    metrodivisioncode: 31084,
    csacode: 348,
    cbsatitle: "Los Angeles-Long Beach-Anaheim, CA",
    metropolitandivisiontitle: "Los Angeles-Long Beach-Glendale, CA",
    csatitle: "Los Angeles-Long Beach, CA",
    countycountyequivalent: "Los Angeles County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31460,
    metrodivisioncode: "",
    csacode: 260,
    cbsatitle: "Madera, CA",
    metropolitandivisiontitle: "",
    csatitle: "Fresno-Madera, CA",
    countycountyequivalent: "Madera County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41860,
    metrodivisioncode: 42034,
    csacode: 488,
    cbsatitle: "San Francisco-Oakland-Hayward, CA",
    metropolitandivisiontitle: "San Rafael, CA",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Marin County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ukiah, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mendocino County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32900,
    metrodivisioncode: "",
    csacode: 382,
    cbsatitle: "Merced, CA",
    metropolitandivisiontitle: "",
    csatitle: "Modesto-Merced, CA",
    countycountyequivalent: "Merced County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salinas, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Monterey County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34900,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "Napa, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Napa County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46020,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Truckee-Grass Valley, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "Nevada County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31080,
    metrodivisioncode: 11244,
    csacode: 348,
    cbsatitle: "Los Angeles-Long Beach-Anaheim, CA",
    metropolitandivisiontitle: "Anaheim-Santa Ana-Irvine, CA",
    csatitle: "Los Angeles-Long Beach, CA",
    countycountyequivalent: "Orange County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40900,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Sacramento--Roseville--Arden-Arcade, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "Placer County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40140,
    metrodivisioncode: "",
    csacode: 348,
    cbsatitle: "Riverside-San Bernardino-Ontario, CA",
    metropolitandivisiontitle: "",
    csatitle: "Los Angeles-Long Beach, CA",
    countycountyequivalent: "Riverside County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40900,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Sacramento--Roseville--Arden-Arcade, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "Sacramento County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41940,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "San Jose-Sunnyvale-Santa Clara, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "San Benito County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 69,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40140,
    metrodivisioncode: "",
    csacode: 348,
    cbsatitle: "Riverside-San Bernardino-Ontario, CA",
    metropolitandivisiontitle: "",
    csatitle: "Los Angeles-Long Beach, CA",
    countycountyequivalent: "San Bernardino County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Diego-Carlsbad, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "San Diego County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41860,
    metrodivisioncode: 41884,
    csacode: 488,
    cbsatitle: "San Francisco-Oakland-Hayward, CA",
    metropolitandivisiontitle:
      "San Francisco-Redwood City-South San Francisco, CA",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "San Francisco County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44700,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "Stockton-Lodi, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "San Joaquin County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Luis Obispo-Paso Robles-Arroyo Grande, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "San Luis Obispo County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41860,
    metrodivisioncode: 41884,
    csacode: 488,
    cbsatitle: "San Francisco-Oakland-Hayward, CA",
    metropolitandivisiontitle:
      "San Francisco-Redwood City-South San Francisco, CA",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "San Mateo County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Santa Maria-Santa Barbara, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Santa Barbara County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41940,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "San Jose-Sunnyvale-Santa Clara, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Santa Clara County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42100,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "Santa Cruz-Watsonville, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Santa Cruz County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39820,
    metrodivisioncode: "",
    csacode: 454,
    cbsatitle: "Redding, CA",
    metropolitandivisiontitle: "",
    csatitle: "Redding-Red Bluff, CA",
    countycountyequivalent: "Shasta County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46700,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "Vallejo-Fairfield, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Solano County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42220,
    metrodivisioncode: "",
    csacode: 488,
    cbsatitle: "Santa Rosa, CA",
    metropolitandivisiontitle: "",
    csatitle: "San Jose-San Francisco-Oakland, CA",
    countycountyequivalent: "Sonoma County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33700,
    metrodivisioncode: "",
    csacode: 382,
    cbsatitle: "Modesto, CA",
    metropolitandivisiontitle: "",
    csatitle: "Modesto-Merced, CA",
    countycountyequivalent: "Stanislaus County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49700,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Yuba City, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "Sutter County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39780,
    metrodivisioncode: "",
    csacode: 454,
    cbsatitle: "Red Bluff, CA",
    metropolitandivisiontitle: "",
    csatitle: "Redding-Red Bluff, CA",
    countycountyequivalent: "Tehama County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47300,
    metrodivisioncode: "",
    csacode: 546,
    cbsatitle: "Visalia-Porterville, CA",
    metropolitandivisiontitle: "",
    csatitle: "Visalia-Porterville-Hanford, CA",
    countycountyequivalent: "Tulare County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43760,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sonora, CA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Tuolumne County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37100,
    metrodivisioncode: "",
    csacode: 348,
    cbsatitle: "Oxnard-Thousand Oaks-Ventura, CA",
    metropolitandivisiontitle: "",
    csatitle: "Los Angeles-Long Beach, CA",
    countycountyequivalent: "Ventura County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40900,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Sacramento--Roseville--Arden-Arcade, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "Yolo County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 113,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49700,
    metrodivisioncode: "",
    csacode: 472,
    cbsatitle: "Yuba City, CA",
    metropolitandivisiontitle: "",
    csatitle: "Sacramento-Roseville, CA",
    countycountyequivalent: "Yuba County",
    statename: "California",
    fipsstatecode: 6,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Adams County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Arapahoe County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14500,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Boulder, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Boulder County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Broomfield County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 14,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Clear Creek County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 19,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Denver County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Douglas County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20780,
    metrodivisioncode: "",
    csacode: 233,
    cbsatitle: "Edwards, CO",
    metropolitandivisiontitle: "",
    csatitle: "Edwards-Glenwood Springs, CO",
    countycountyequivalent: "Eagle County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Elbert County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 39,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Colorado Springs, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "El Paso County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15860,
    metrodivisioncode: "",
    csacode: 444,
    cbsatitle: "Cañon City, CO",
    metropolitandivisiontitle: "",
    csatitle: "Pueblo-Cañon City, CO",
    countycountyequivalent: "Fremont County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24060,
    metrodivisioncode: "",
    csacode: 233,
    cbsatitle: "Glenwood Springs, CO",
    metropolitandivisiontitle: "",
    csatitle: "Edwards-Glenwood Springs, CO",
    countycountyequivalent: "Garfield County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Gilpin County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 47,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Jefferson County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Durango, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "La Plata County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Collins, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Larimer County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sterling, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Logan County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Junction, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mesa County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18780,
    metrodivisioncode: "",
    csacode: 525,
    cbsatitle: "Craig, CO",
    metropolitandivisiontitle: "",
    csatitle: "Steamboat Springs-Craig, CO",
    countycountyequivalent: "Moffat County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Montrose, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Montrose County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Morgan, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Morgan County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19740,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Denver-Aurora-Lakewood, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Park County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24060,
    metrodivisioncode: "",
    csacode: 233,
    cbsatitle: "Glenwood Springs, CO",
    metropolitandivisiontitle: "",
    csatitle: "Edwards-Glenwood Springs, CO",
    countycountyequivalent: "Pitkin County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 97,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39380,
    metrodivisioncode: "",
    csacode: 444,
    cbsatitle: "Pueblo, CO",
    metropolitandivisiontitle: "",
    csatitle: "Pueblo-Cañon City, CO",
    countycountyequivalent: "Pueblo County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44460,
    metrodivisioncode: "",
    csacode: 525,
    cbsatitle: "Steamboat Springs, CO",
    metropolitandivisiontitle: "",
    csatitle: "Steamboat Springs-Craig, CO",
    countycountyequivalent: "Routt County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14720,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Breckenridge, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Summit County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Colorado Springs, CO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Teller County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 119,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24540,
    metrodivisioncode: "",
    csacode: 216,
    cbsatitle: "Greeley, CO",
    metropolitandivisiontitle: "",
    csatitle: "Denver-Aurora, CO",
    countycountyequivalent: "Weld County",
    statename: "Colorado",
    fipsstatecode: 8,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14860,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Bridgeport-Stamford-Norwalk, CT",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Fairfield County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25540,
    metrodivisioncode: "",
    csacode: 278,
    cbsatitle: "Hartford-West Hartford-East Hartford, CT",
    metropolitandivisiontitle: "",
    csatitle: "Hartford-West Hartford, CT",
    countycountyequivalent: "Hartford County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45860,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Torrington, CT",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Litchfield County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25540,
    metrodivisioncode: "",
    csacode: 278,
    cbsatitle: "Hartford-West Hartford-East Hartford, CT",
    metropolitandivisiontitle: "",
    csatitle: "Hartford-West Hartford, CT",
    countycountyequivalent: "Middlesex County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35300,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "New Haven-Milford, CT",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "New Haven County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35980,
    metrodivisioncode: "",
    csacode: 278,
    cbsatitle: "Norwich-New London, CT",
    metropolitandivisiontitle: "",
    csatitle: "Hartford-West Hartford, CT",
    countycountyequivalent: "New London County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25540,
    metrodivisioncode: "",
    csacode: 278,
    cbsatitle: "Hartford-West Hartford-East Hartford, CT",
    metropolitandivisiontitle: "",
    csatitle: "Hartford-West Hartford, CT",
    countycountyequivalent: "Tolland County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49340,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Worcester, MA-CT",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Windham County",
    statename: "Connecticut",
    fipsstatecode: 9,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20100,
    metrodivisioncode: "",
    csacode: 428,
    cbsatitle: "Dover, DE",
    metropolitandivisiontitle: "",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Kent County",
    statename: "Delaware",
    fipsstatecode: 10,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 48864,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Wilmington, DE-MD-NJ",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "New Castle County",
    statename: "Delaware",
    fipsstatecode: 10,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salisbury, MD-DE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sussex County",
    statename: "Delaware",
    fipsstatecode: 10,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "District of Columbia",
    statename: "District of Columbia",
    fipsstatecode: 11,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23540,
    metrodivisioncode: "",
    csacode: 264,
    cbsatitle: "Gainesville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Gainesville-Lake City, FL",
    countycountyequivalent: "Alachua County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27260,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "Jacksonville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "Baker County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 3,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Panama City, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bay County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Palm Bay-Melbourne-Titusville, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brevard County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33100,
    metrodivisioncode: 22744,
    csacode: 370,
    cbsatitle: "Miami-Fort Lauderdale-West Palm Beach, FL",
    metropolitandivisiontitle:
      "Fort Lauderdale-Pompano Beach-Deerfield Beach, FL",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "Broward County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39460,
    metrodivisioncode: "",
    csacode: 412,
    cbsatitle: "Punta Gorda, FL",
    metropolitandivisiontitle: "",
    csatitle: "North Port-Sarasota, FL",
    countycountyequivalent: "Charlotte County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Homosassa Springs, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Citrus County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27260,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "Jacksonville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "Clay County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34940,
    metrodivisioncode: "",
    csacode: 162,
    cbsatitle: "Naples-Immokalee-Marco Island, FL",
    metropolitandivisiontitle: "",
    csatitle: "Cape Coral-Fort Myers-Naples, FL",
    countycountyequivalent: "Collier County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29380,
    metrodivisioncode: "",
    csacode: 264,
    cbsatitle: "Lake City, FL",
    metropolitandivisiontitle: "",
    csatitle: "Gainesville-Lake City, FL",
    countycountyequivalent: "Columbia County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11580,
    metrodivisioncode: "",
    csacode: 412,
    cbsatitle: "Arcadia, FL",
    metropolitandivisiontitle: "",
    csatitle: "North Port-Sarasota, FL",
    countycountyequivalent: "DeSoto County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27260,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "Jacksonville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "Duval County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pensacola-Ferry Pass-Brent, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Escambia County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19660,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "Deltona-Daytona Beach-Ormond Beach, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Flagler County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45220,
    metrodivisioncode: "",
    csacode: 533,
    cbsatitle: "Tallahassee, FL",
    metropolitandivisiontitle: "",
    csatitle: "Tallahassee-Bainbridge, FL-GA",
    countycountyequivalent: "Gadsden County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 39,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23540,
    metrodivisioncode: "",
    csacode: 264,
    cbsatitle: "Gainesville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Gainesville-Lake City, FL",
    countycountyequivalent: "Gilchrist County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 41,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Panama City, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gulf County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wauchula, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hardee County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clewiston, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hendry County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tampa-St. Petersburg-Clearwater, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hernando County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sebring, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Highlands County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tampa-St. Petersburg-Clearwater, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hillsborough County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42680,
    metrodivisioncode: "",
    csacode: 370,
    cbsatitle: "Sebastian-Vero Beach, FL",
    metropolitandivisiontitle: "",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "Indian River County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45220,
    metrodivisioncode: "",
    csacode: 533,
    cbsatitle: "Tallahassee, FL",
    metropolitandivisiontitle: "",
    csatitle: "Tallahassee-Bainbridge, FL-GA",
    countycountyequivalent: "Jefferson County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 65,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36740,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "Orlando-Kissimmee-Sanford, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Lake County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 69,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15980,
    metrodivisioncode: "",
    csacode: 162,
    cbsatitle: "Cape Coral-Fort Myers, FL",
    metropolitandivisiontitle: "",
    csatitle: "Cape Coral-Fort Myers-Naples, FL",
    countycountyequivalent: "Lee County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45220,
    metrodivisioncode: "",
    csacode: 533,
    cbsatitle: "Tallahassee, FL",
    metropolitandivisiontitle: "",
    csatitle: "Tallahassee-Bainbridge, FL-GA",
    countycountyequivalent: "Leon County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35840,
    metrodivisioncode: "",
    csacode: 412,
    cbsatitle: "North Port-Sarasota-Bradenton, FL",
    metropolitandivisiontitle: "",
    csatitle: "North Port-Sarasota, FL",
    countycountyequivalent: "Manatee County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ocala, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Marion County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38940,
    metrodivisioncode: "",
    csacode: 370,
    cbsatitle: "Port St. Lucie, FL",
    metropolitandivisiontitle: "",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "Martin County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33100,
    metrodivisioncode: 33124,
    csacode: 370,
    cbsatitle: "Miami-Fort Lauderdale-West Palm Beach, FL",
    metropolitandivisiontitle: "Miami-Miami Beach-Kendall, FL",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "Miami-Dade County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 86,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Key West, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Monroe County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27260,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "Jacksonville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "Nassau County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 89,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18880,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Crestview-Fort Walton Beach-Destin, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Okaloosa County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36380,
    metrodivisioncode: "",
    csacode: 370,
    cbsatitle: "Okeechobee, FL",
    metropolitandivisiontitle: "",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "Okeechobee County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36740,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "Orlando-Kissimmee-Sanford, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Orange County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36740,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "Orlando-Kissimmee-Sanford, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Osceola County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 97,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33100,
    metrodivisioncode: 48424,
    csacode: 370,
    cbsatitle: "Miami-Fort Lauderdale-West Palm Beach, FL",
    metropolitandivisiontitle: "West Palm Beach-Boca Raton-Delray Beach, FL",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "Palm Beach County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tampa-St. Petersburg-Clearwater, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pasco County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tampa-St. Petersburg-Clearwater, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pinellas County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lakeland-Winter Haven, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Polk County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37260,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "Palatka, FL",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "Putnam County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27260,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "Jacksonville, FL",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "St. Johns County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 109,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38940,
    metrodivisioncode: "",
    csacode: 370,
    cbsatitle: "Port St. Lucie, FL",
    metropolitandivisiontitle: "",
    csatitle: "Miami-Fort Lauderdale-Port St. Lucie, FL",
    countycountyequivalent: "St. Lucie County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pensacola-Ferry Pass-Brent, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Santa Rosa County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35840,
    metrodivisioncode: "",
    csacode: 412,
    cbsatitle: "North Port-Sarasota-Bradenton, FL",
    metropolitandivisiontitle: "",
    csatitle: "North Port-Sarasota, FL",
    countycountyequivalent: "Sarasota County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36740,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "Orlando-Kissimmee-Sanford, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Seminole County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45540,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "The Villages, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Sumter County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19660,
    metrodivisioncode: "",
    csacode: 422,
    cbsatitle: "Deltona-Daytona Beach-Ormond Beach, FL",
    metropolitandivisiontitle: "",
    csatitle: "Orlando-Deltona-Daytona Beach, FL",
    countycountyequivalent: "Volusia County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45220,
    metrodivisioncode: "",
    csacode: 533,
    cbsatitle: "Tallahassee, FL",
    metropolitandivisiontitle: "",
    csatitle: "Tallahassee-Bainbridge, FL-GA",
    countycountyequivalent: "Wakulla County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 129,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18880,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Crestview-Fort Walton Beach-Destin, FL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Walton County",
    statename: "Florida",
    fipsstatecode: 12,
    fipscountycode: 131,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Albany, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Baker County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Milledgeville, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Baldwin County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Barrow County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Bartow County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fitzgerald, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ben Hill County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31420,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Macon, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Bibb County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brunswick, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brantley County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Valdosta, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brooks County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 27,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42340,
    metrodivisioncode: "",
    csacode: 496,
    cbsatitle: "Savannah, GA",
    metropolitandivisiontitle: "",
    csatitle: "Savannah-Hinesville-Statesboro, GA",
    countycountyequivalent: "Bryan County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44340,
    metrodivisioncode: "",
    csacode: 496,
    cbsatitle: "Statesboro, GA",
    metropolitandivisiontitle: "",
    csatitle: "Savannah-Hinesville-Statesboro, GA",
    countycountyequivalent: "Bulloch County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Burke County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 33,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Butts County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 35,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41220,
    metrodivisioncode: "",
    csacode: 300,
    cbsatitle: "St. Marys, GA",
    metropolitandivisiontitle: "",
    csatitle: "Jacksonville-St. Marys-Palatka, FL-GA",
    countycountyequivalent: "Camden County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Carroll County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16860,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Chattanooga, TN-GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Catoosa County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42340,
    metrodivisioncode: "",
    csacode: 496,
    cbsatitle: "Savannah, GA",
    metropolitandivisiontitle: "",
    csatitle: "Savannah-Hinesville-Statesboro, GA",
    countycountyequivalent: "Chatham County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17980,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Columbus, GA-AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Chattahoochee County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44900,
    metrodivisioncode: "",
    csacode: 470,
    cbsatitle: "Summerville, GA",
    metropolitandivisiontitle: "",
    csatitle: "Rome-Summerville, GA",
    countycountyequivalent: "Chattooga County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Cherokee County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12020,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Athens-Clarke County, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Clarke County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Clayton County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Cobb County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Douglas, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coffee County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Moultrie, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Colquitt County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Columbia County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Coweta County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31420,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Macon, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Crawford County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cordele, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Crisp County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16860,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Chattanooga, TN-GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Dade County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 83,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Dawson County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 85,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12460,
    metrodivisioncode: "",
    csacode: 533,
    cbsatitle: "Bainbridge, GA",
    metropolitandivisiontitle: "",
    csatitle: "Tallahassee-Bainbridge, FL-GA",
    countycountyequivalent: "Decatur County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "DeKalb County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Albany, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dougherty County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Douglas County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Valdosta, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Echols County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 101,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42340,
    metrodivisioncode: "",
    csacode: 496,
    cbsatitle: "Savannah, GA",
    metropolitandivisiontitle: "",
    csatitle: "Savannah-Hinesville-Statesboro, GA",
    countycountyequivalent: "Effingham County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Fayette County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40660,
    metrodivisioncode: "",
    csacode: 470,
    cbsatitle: "Rome, GA",
    metropolitandivisiontitle: "",
    csatitle: "Rome-Summerville, GA",
    countycountyequivalent: "Floyd County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Forsyth County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Fulton County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brunswick, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Glynn County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15660,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Calhoun, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Gordon County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 129,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Gwinnett County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 135,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cornelia, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Habersham County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 137,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23580,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Gainesville, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Hall County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Milledgeville, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hancock County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 141,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Haralson County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 143,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17980,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Columbus, GA-AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Harris County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Heard County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Henry County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 151,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47580,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Warner Robins, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Houston County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27600,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Jefferson, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Jackson County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 157,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Jasper County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 159,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dublin, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Johnson County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 167,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31420,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Macon, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Jones County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 169,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Lamar County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 171,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Valdosta, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lanier County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 173,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dublin, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Laurens County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 175,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Albany, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lee County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 177,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25980,
    metrodivisioncode: "",
    csacode: 496,
    cbsatitle: "Hinesville, GA",
    metropolitandivisiontitle: "",
    csatitle: "Savannah-Hinesville-Statesboro, GA",
    countycountyequivalent: "Liberty County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lincoln County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 181,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25980,
    metrodivisioncode: "",
    csacode: 496,
    cbsatitle: "Hinesville, GA",
    metropolitandivisiontitle: "",
    csatitle: "Savannah-Hinesville-Statesboro, GA",
    countycountyequivalent: "Long County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 183,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Valdosta, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lowndes County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 185,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McDuffie County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 189,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brunswick, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McIntosh County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 191,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12020,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Athens-Clarke County, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Madison County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 195,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17980,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Columbus, GA-AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Marion County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 197,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Meriwether County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 199,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31420,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Macon, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Monroe County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 207,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47080,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Vidalia, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Montgomery County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 209,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Morgan County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 211,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19140,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Dalton, GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Murray County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 213,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17980,
    metrodivisioncode: "",
    csacode: 194,
    cbsatitle: "Columbus, GA-AL",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Auburn-Opelika, GA-AL",
    countycountyequivalent: "Muscogee County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 215,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Newton County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 217,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12020,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Athens-Clarke County, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Oconee County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 219,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12020,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Athens-Clarke County, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Oglethorpe County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 221,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Paulding County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 223,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47580,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Warner Robins, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Peach County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 225,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Pickens County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 227,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waycross, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pierce County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 229,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Pike County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 231,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16340,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Cedartown, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Polk County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 233,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47580,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Warner Robins, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Pulaski County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 235,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Richmond County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 245,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Rockdale County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 247,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Americus, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Schley County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 249,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Spalding County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 255,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Toccoa, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Stephens County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 257,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Americus, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sumter County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 261,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Albany, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Terrell County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 273,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Thomasville, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Thomas County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 275,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tifton, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Tift County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 277,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47080,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Vidalia, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Toombs County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 279,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29300,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "LaGrange, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Troup County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 285,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31420,
    metrodivisioncode: "",
    csacode: 356,
    cbsatitle: "Macon, GA",
    metropolitandivisiontitle: "",
    csatitle: "Macon-Warner Robins, GA",
    countycountyequivalent: "Twiggs County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 289,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45580,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Thomaston, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Upson County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 293,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16860,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Chattanooga, TN-GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Walker County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 295,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12060,
    metrodivisioncode: "",
    csacode: 122,
    cbsatitle: "Atlanta-Sandy Springs-Roswell, GA",
    metropolitandivisiontitle: "",
    csatitle: "Atlanta--Athens-Clarke County--Sandy Springs, GA",
    countycountyequivalent: "Walton County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 297,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waycross, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ware County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 299,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jesup, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wayne County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 305,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19140,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Dalton, GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Whitfield County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 313,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Albany, GA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Worth County",
    statename: "Georgia",
    fipsstatecode: 13,
    fipscountycode: 321,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hilo, HI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hawaii County",
    statename: "Hawaii",
    fipsstatecode: 15,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46520,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Urban Honolulu, HI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Honolulu County",
    statename: "Hawaii",
    fipsstatecode: 15,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kahului-Wailuku-Lahaina, HI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kalawao County",
    statename: "Hawaii",
    fipsstatecode: 15,
    fipscountycode: 5,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kapaa, HI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kauai County",
    statename: "Hawaii",
    fipsstatecode: 15,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kahului-Wailuku-Lahaina, HI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Maui County",
    statename: "Hawaii",
    fipsstatecode: 15,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14260,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Boise City, ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Ada County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pocatello, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bannock County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13940,
    metrodivisioncode: "",
    csacode: 292,
    cbsatitle: "Blackfoot, ID",
    metropolitandivisiontitle: "",
    csatitle: "Idaho Falls-Rexburg-Blackfoot, ID",
    countycountyequivalent: "Bingham County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hailey, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Blaine County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14260,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Boise City, ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Boise County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41760,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sandpoint, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bonner County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26820,
    metrodivisioncode: "",
    csacode: 292,
    cbsatitle: "Idaho Falls, ID",
    metropolitandivisiontitle: "",
    csatitle: "Idaho Falls-Rexburg-Blackfoot, ID",
    countycountyequivalent: "Bonneville County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26820,
    metrodivisioncode: "",
    csacode: 292,
    cbsatitle: "Idaho Falls, ID",
    metropolitandivisiontitle: "",
    csatitle: "Idaho Falls-Rexburg-Blackfoot, ID",
    countycountyequivalent: "Butte County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hailey, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Camas County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14260,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Boise City, ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Canyon County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 27,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burley, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cassia County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34300,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Mountain Home, ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Elmore County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Logan, UT-ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Franklin County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 41,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39940,
    metrodivisioncode: "",
    csacode: 292,
    cbsatitle: "Rexburg, ID",
    metropolitandivisiontitle: "",
    csatitle: "Idaho Falls-Rexburg-Blackfoot, ID",
    countycountyequivalent: "Fremont County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14260,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Boise City, ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Gem County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26820,
    metrodivisioncode: "",
    csacode: 292,
    cbsatitle: "Idaho Falls, ID",
    metropolitandivisiontitle: "",
    csatitle: "Idaho Falls-Rexburg-Blackfoot, ID",
    countycountyequivalent: "Jefferson County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Twin Falls, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jerome County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17660,
    metrodivisioncode: "",
    csacode: 518,
    cbsatitle: "Coeur d'Alene, ID",
    metropolitandivisiontitle: "",
    csatitle: "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    countycountyequivalent: "Kootenai County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34140,
    metrodivisioncode: "",
    csacode: 446,
    cbsatitle: "Moscow, ID",
    metropolitandivisiontitle: "",
    csatitle: "Pullman-Moscow, WA-ID",
    countycountyequivalent: "Latah County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hailey, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lincoln County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 63,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39940,
    metrodivisioncode: "",
    csacode: 292,
    cbsatitle: "Rexburg, ID",
    metropolitandivisiontitle: "",
    csatitle: "Idaho Falls-Rexburg-Blackfoot, ID",
    countycountyequivalent: "Madison County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burley, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Minidoka County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 67,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lewiston, ID-WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Nez Perce County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14260,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Boise City, ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Owyhee County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36620,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Ontario, OR-ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Payette County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, WY-ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Teton County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Twin Falls, ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Twin Falls County",
    statename: "Idaho",
    fipsstatecode: 16,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39500,
    metrodivisioncode: "",
    csacode: 448,
    cbsatitle: "Quincy, IL-MO",
    metropolitandivisiontitle: "",
    csatitle: "Quincy-Hannibal, IL-MO",
    countycountyequivalent: "Adams County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16020,
    metrodivisioncode: "",
    csacode: 164,
    cbsatitle: "Cape Girardeau, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "Cape Girardeau-Sikeston, MO-IL",
    countycountyequivalent: "Alexander County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 3,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Bond County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 5,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40420,
    metrodivisioncode: "",
    csacode: 466,
    cbsatitle: "Rockford, IL",
    metropolitandivisiontitle: "",
    csatitle: "Rockford-Freeport-Rochelle, IL",
    countycountyequivalent: "Boone County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36860,
    metrodivisioncode: "",
    csacode: 176,
    cbsatitle: "Ottawa-Peru, IL",
    metropolitandivisiontitle: "",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Bureau County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Calhoun County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Champaign-Urbana, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Champaign County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45380,
    metrodivisioncode: "",
    csacode: 522,
    cbsatitle: "Taylorville, IL",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Jacksonville-Lincoln, IL",
    countycountyequivalent: "Christian County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Clinton County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 27,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charleston-Mattoon, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coles County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 16974,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Chicago-Naperville-Arlington Heights, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Cook County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charleston-Mattoon, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cumberland County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 35,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 20994,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Elgin, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "DeKalb County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14010,
    metrodivisioncode: "",
    csacode: 145,
    cbsatitle: "Bloomington, IL",
    metropolitandivisiontitle: "",
    csatitle: "Bloomington-Pontiac, IL",
    countycountyequivalent: "De Witt County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 39,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 16974,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Chicago-Naperville-Arlington Heights, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "DuPage County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Effingham, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Effingham County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Champaign-Urbana, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ford County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15900,
    metrodivisioncode: "",
    csacode: 426,
    cbsatitle: "Canton, IL",
    metropolitandivisiontitle: "",
    csatitle: "Peoria-Canton, IL",
    countycountyequivalent: "Fulton County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 16974,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Chicago-Naperville-Arlington Heights, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Grundy County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 63,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22800,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Madison-Keokuk, IA-IL-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hancock County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 67,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burlington, IA-IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Henderson County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 71,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19340,
    metrodivisioncode: "",
    csacode: 209,
    cbsatitle: "Davenport-Moline-Rock Island, IA-IL",
    metropolitandivisiontitle: "",
    csatitle: "Davenport-Moline, IA-IL",
    countycountyequivalent: "Henry County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Carbondale-Marion, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mount Vernon, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jefferson County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Jersey County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 83,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 20994,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Elgin, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Kane County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28100,
    metrodivisioncode: "",
    csacode: 176,
    cbsatitle: "Kankakee, IL",
    metropolitandivisiontitle: "",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Kankakee County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 16974,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Chicago-Naperville-Arlington Heights, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Kendall County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Galesburg, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Knox County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 29404,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Lake County-Kenosha County, IL-WI",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Lake County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36860,
    metrodivisioncode: "",
    csacode: 176,
    cbsatitle: "Ottawa-Peru, IL",
    metropolitandivisiontitle: "",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "LaSalle County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19940,
    metrodivisioncode: "",
    csacode: 221,
    cbsatitle: "Dixon, IL",
    metropolitandivisiontitle: "",
    csatitle: "Dixon-Sterling, IL",
    countycountyequivalent: "Lee County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38700,
    metrodivisioncode: "",
    csacode: 145,
    cbsatitle: "Pontiac, IL",
    metropolitandivisiontitle: "",
    csatitle: "Bloomington-Pontiac, IL",
    countycountyequivalent: "Livingston County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30660,
    metrodivisioncode: "",
    csacode: 522,
    cbsatitle: "Lincoln, IL",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Jacksonville-Lincoln, IL",
    countycountyequivalent: "Logan County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Macomb, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McDonough County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 16974,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Chicago-Naperville-Arlington Heights, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "McHenry County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14010,
    metrodivisioncode: "",
    csacode: 145,
    cbsatitle: "Bloomington, IL",
    metropolitandivisiontitle: "",
    csatitle: "Bloomington-Pontiac, IL",
    countycountyequivalent: "McLean County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Decatur, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Macon County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Macoupin County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 117,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Madison County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16460,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "Centralia, IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Marion County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37900,
    metrodivisioncode: "",
    csacode: 426,
    cbsatitle: "Peoria, IL",
    metropolitandivisiontitle: "",
    csatitle: "Peoria-Canton, IL",
    countycountyequivalent: "Marshall County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 123,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37140,
    metrodivisioncode: "",
    csacode: 424,
    cbsatitle: "Paducah, KY-IL",
    metropolitandivisiontitle: "",
    csatitle: "Paducah-Mayfield, KY-IL",
    countycountyequivalent: "Massac County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 127,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44100,
    metrodivisioncode: "",
    csacode: 522,
    cbsatitle: "Springfield, IL",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Jacksonville-Lincoln, IL",
    countycountyequivalent: "Menard County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 129,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19340,
    metrodivisioncode: "",
    csacode: 209,
    cbsatitle: "Davenport-Moline-Rock Island, IA-IL",
    metropolitandivisiontitle: "",
    csatitle: "Davenport-Moline, IA-IL",
    countycountyequivalent: "Mercer County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 131,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Monroe County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27300,
    metrodivisioncode: "",
    csacode: 522,
    cbsatitle: "Jacksonville, IL",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Jacksonville-Lincoln, IL",
    countycountyequivalent: "Morgan County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 137,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40300,
    metrodivisioncode: "",
    csacode: 466,
    cbsatitle: "Rochelle, IL",
    metropolitandivisiontitle: "",
    csatitle: "Rockford-Freeport-Rochelle, IL",
    countycountyequivalent: "Ogle County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37900,
    metrodivisioncode: "",
    csacode: 426,
    cbsatitle: "Peoria, IL",
    metropolitandivisiontitle: "",
    csatitle: "Peoria-Canton, IL",
    countycountyequivalent: "Peoria County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Champaign-Urbana, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Piatt County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 147,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36860,
    metrodivisioncode: "",
    csacode: 176,
    cbsatitle: "Ottawa-Peru, IL",
    metropolitandivisiontitle: "",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Putnam County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 155,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19340,
    metrodivisioncode: "",
    csacode: 209,
    cbsatitle: "Davenport-Moline-Rock Island, IA-IL",
    metropolitandivisiontitle: "",
    csatitle: "Davenport-Moline, IA-IL",
    countycountyequivalent: "Rock Island County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "St. Clair County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 163,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44100,
    metrodivisioncode: "",
    csacode: 522,
    cbsatitle: "Springfield, IL",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Jacksonville-Lincoln, IL",
    countycountyequivalent: "Sangamon County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 167,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27300,
    metrodivisioncode: "",
    csacode: 522,
    cbsatitle: "Jacksonville, IL",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Jacksonville-Lincoln, IL",
    countycountyequivalent: "Scott County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 171,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37900,
    metrodivisioncode: "",
    csacode: 426,
    cbsatitle: "Peoria, IL",
    metropolitandivisiontitle: "",
    csatitle: "Peoria-Canton, IL",
    countycountyequivalent: "Stark County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 175,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23300,
    metrodivisioncode: "",
    csacode: 466,
    cbsatitle: "Freeport, IL",
    metropolitandivisiontitle: "",
    csatitle: "Rockford-Freeport-Rochelle, IL",
    countycountyequivalent: "Stephenson County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 177,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37900,
    metrodivisioncode: "",
    csacode: 426,
    cbsatitle: "Peoria, IL",
    metropolitandivisiontitle: "",
    csatitle: "Peoria-Canton, IL",
    countycountyequivalent: "Tazewell County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Danville, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Vermilion County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 183,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44580,
    metrodivisioncode: "",
    csacode: 221,
    cbsatitle: "Sterling, IL",
    metropolitandivisiontitle: "",
    csatitle: "Dixon-Sterling, IL",
    countycountyequivalent: "Whiteside County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 195,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 16974,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Chicago-Naperville-Arlington Heights, IL",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Will County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 197,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Carbondale-Marion, IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Williamson County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 199,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40420,
    metrodivisioncode: "",
    csacode: 466,
    cbsatitle: "Rockford, IL",
    metropolitandivisiontitle: "",
    csatitle: "Rockford-Freeport-Rochelle, IL",
    countycountyequivalent: "Winnebago County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 201,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37900,
    metrodivisioncode: "",
    csacode: 426,
    cbsatitle: "Peoria, IL",
    metropolitandivisiontitle: "",
    csatitle: "Peoria-Canton, IL",
    countycountyequivalent: "Woodford County",
    statename: "Illinois",
    fipsstatecode: 17,
    fipscountycode: 203,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19540,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Decatur, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Adams County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23060,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Fort Wayne, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Allen County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18020,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Columbus, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Bartholomew County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29200,
    metrodivisioncode: "",
    csacode: 320,
    cbsatitle: "Lafayette-West Lafayette, IN",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-West Lafayette-Frankfort, IN",
    countycountyequivalent: "Benton County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Boone County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Brown County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29200,
    metrodivisioncode: "",
    csacode: 320,
    cbsatitle: "Lafayette-West Lafayette, IN",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-West Lafayette-Frankfort, IN",
    countycountyequivalent: "Carroll County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Logansport, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cass County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Clark County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Terre Haute, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clay County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23140,
    metrodivisioncode: "",
    csacode: 320,
    cbsatitle: "Frankfort, IN",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-West Lafayette-Frankfort, IN",
    countycountyequivalent: "Clinton County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Washington, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Daviess County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Dearborn County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 29,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24700,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Greensburg, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Decatur County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12140,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Auburn, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "DeKalb County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34620,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Muncie, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Delaware County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jasper, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dubois County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21140,
    metrodivisioncode: "",
    csacode: 515,
    cbsatitle: "Elkhart-Goshen, IN",
    metropolitandivisiontitle: "",
    csatitle: "South Bend-Elkhart-Mishawaka, IN-MI",
    countycountyequivalent: "Elkhart County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18220,
    metrodivisioncode: "",
    csacode: 458,
    cbsatitle: "Connersville, IN",
    metropolitandivisiontitle: "",
    csatitle: "Richmond-Connersville, IN",
    countycountyequivalent: "Fayette County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Floyd County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marion, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grant County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Hamilton County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Hancock County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Harrison County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Hendricks County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35220,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "New Castle, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Henry County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29020,
    metrodivisioncode: "",
    csacode: 316,
    cbsatitle: "Kokomo, IN",
    metropolitandivisiontitle: "",
    csatitle: "Kokomo-Peru, IN",
    countycountyequivalent: "Howard County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26540,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Huntington, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Huntington County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42980,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Seymour, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Jackson County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 23844,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Gary, IN",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Jasper County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31500,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Madison, IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Jefferson County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35860,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "North Vernon, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Jennings County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Johnson County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Vincennes, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Knox County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Warsaw, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kosciusko County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 23844,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Gary, IN",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Lake County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33140,
    metrodivisioncode: "",
    csacode: 176,
    cbsatitle: "Michigan City-La Porte, IN",
    metropolitandivisiontitle: "",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "LaPorte County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13260,
    metrodivisioncode: "",
    csacode: 144,
    cbsatitle: "Bedford, IN",
    metropolitandivisiontitle: "",
    csatitle: "Bloomington-Bedford, IN",
    countycountyequivalent: "Lawrence County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Madison County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 95,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Marion County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38500,
    metrodivisioncode: "",
    csacode: 515,
    cbsatitle: "Plymouth, IN",
    metropolitandivisiontitle: "",
    csatitle: "South Bend-Elkhart-Mishawaka, IN-MI",
    countycountyequivalent: "Marshall County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37940,
    metrodivisioncode: "",
    csacode: 316,
    cbsatitle: "Peru, IN",
    metropolitandivisiontitle: "",
    csatitle: "Kokomo-Peru, IN",
    countycountyequivalent: "Miami County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14020,
    metrodivisioncode: "",
    csacode: 144,
    cbsatitle: "Bloomington, IN",
    metropolitandivisiontitle: "",
    csatitle: "Bloomington-Bedford, IN",
    countycountyequivalent: "Monroe County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18820,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Crawfordsville, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Montgomery County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Morgan County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 23844,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Gary, IN",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Newton County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28340,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Kendallville, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Noble County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Ohio County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 115,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14020,
    metrodivisioncode: "",
    csacode: 144,
    cbsatitle: "Bloomington, IN",
    metropolitandivisiontitle: "",
    csatitle: "Bloomington-Bedford, IN",
    countycountyequivalent: "Owen County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 119,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jasper, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pike County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 125,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 23844,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Gary, IN",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Porter County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Evansville, IN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Posey County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 129,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Putnam County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 133,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43780,
    metrodivisioncode: "",
    csacode: 515,
    cbsatitle: "South Bend-Mishawaka, IN-MI",
    metropolitandivisiontitle: "",
    csatitle: "South Bend-Elkhart-Mishawaka, IN-MI",
    countycountyequivalent: "St. Joseph County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Scott County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 143,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26900,
    metrodivisioncode: "",
    csacode: 294,
    cbsatitle: "Indianapolis-Carmel-Anderson, IN",
    metropolitandivisiontitle: "",
    csatitle: "Indianapolis-Carmel-Muncie, IN",
    countycountyequivalent: "Shelby County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11420,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Angola, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Steuben County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 151,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Terre Haute, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sullivan County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 153,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29200,
    metrodivisioncode: "",
    csacode: 320,
    cbsatitle: "Lafayette-West Lafayette, IN",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-West Lafayette-Frankfort, IN",
    countycountyequivalent: "Tippecanoe County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 157,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Union County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 161,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Evansville, IN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Vanderburgh County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 163,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Terre Haute, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Vermillion County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 165,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Terre Haute, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Vigo County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 167,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wabash, IN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wabash County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Evansville, IN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Warrick County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 173,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Washington County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 175,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39980,
    metrodivisioncode: "",
    csacode: 458,
    cbsatitle: "Richmond, IN",
    metropolitandivisiontitle: "",
    csatitle: "Richmond-Connersville, IN",
    countycountyequivalent: "Wayne County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 177,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23060,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Fort Wayne, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Wells County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 179,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23060,
    metrodivisioncode: "",
    csacode: 258,
    cbsatitle: "Fort Wayne, IN",
    metropolitandivisiontitle: "",
    csatitle: "Fort Wayne-Huntington-Auburn, IN",
    countycountyequivalent: "Whitley County",
    statename: "Indiana",
    fipsstatecode: 18,
    fipscountycode: 183,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16300,
    metrodivisioncode: "",
    csacode: 168,
    cbsatitle: "Cedar Rapids, IA",
    metropolitandivisiontitle: "",
    csatitle: "Cedar Rapids-Iowa City, IA",
    countycountyequivalent: "Benton County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 11,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waterloo-Cedar Falls, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Black Hawk County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14340,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Boone, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Boone County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waterloo-Cedar Falls, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bremer County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Storm Lake, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Buena Vista County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mason City, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cerro Gordo County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Spencer, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clay County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17540,
    metrodivisioncode: "",
    csacode: 209,
    cbsatitle: "Clinton, IA",
    metropolitandivisiontitle: "",
    csatitle: "Davenport-Moline, IA-IL",
    countycountyequivalent: "Clinton County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19780,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Des Moines-West Des Moines, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Dallas County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ottumwa, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Davis County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burlington, IA-IL",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Des Moines County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Spirit Lake, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dickinson County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dubuque, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dubuque County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waterloo-Cedar Falls, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grundy County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 75,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19780,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Des Moines-West Des Moines, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Guthrie County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 77,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Harrison County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 85,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35500,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Newton, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Jasper County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21840,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fairfield, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jefferson County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26980,
    metrodivisioncode: "",
    csacode: 168,
    cbsatitle: "Iowa City, IA",
    metropolitandivisiontitle: "",
    csatitle: "Cedar Rapids-Iowa City, IA",
    countycountyequivalent: "Johnson County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16300,
    metrodivisioncode: "",
    csacode: 168,
    cbsatitle: "Cedar Rapids, IA",
    metropolitandivisiontitle: "",
    csatitle: "Cedar Rapids-Iowa City, IA",
    countycountyequivalent: "Jones County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 105,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22800,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Madison-Keokuk, IA-IL-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lee County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16300,
    metrodivisioncode: "",
    csacode: 168,
    cbsatitle: "Cedar Rapids, IA",
    metropolitandivisiontitle: "",
    csatitle: "Cedar Rapids-Iowa City, IA",
    countycountyequivalent: "Linn County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19780,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Des Moines-West Des Moines, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Madison County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 121,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Oskaloosa, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mahaska County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marshalltown, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Marshall County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Mills County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 129,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34700,
    metrodivisioncode: "",
    csacode: 209,
    cbsatitle: "Muscatine, IA",
    metropolitandivisiontitle: "",
    csatitle: "Davenport-Moline, IA-IL",
    countycountyequivalent: "Muscatine County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43580,
    metrodivisioncode: "",
    csacode: 512,
    cbsatitle: "Sioux City, IA-NE-SD",
    metropolitandivisiontitle: "",
    csatitle: "Sioux City-Vermillion, IA-SD-NE",
    countycountyequivalent: "Plymouth County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19780,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Des Moines-West Des Moines, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Polk County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Pottawattamie County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 155,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19340,
    metrodivisioncode: "",
    csacode: 209,
    cbsatitle: "Davenport-Moline-Rock Island, IA-IL",
    metropolitandivisiontitle: "",
    csatitle: "Davenport-Moline, IA-IL",
    countycountyequivalent: "Scott County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 163,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11180,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Ames, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Story County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ottumwa, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wapello County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19780,
    metrodivisioncode: "",
    csacode: 218,
    cbsatitle: "Des Moines-West Des Moines, IA",
    metropolitandivisiontitle: "",
    csatitle: "Des Moines-Ames-West Des Moines, IA",
    countycountyequivalent: "Warren County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 181,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26980,
    metrodivisioncode: "",
    csacode: 168,
    cbsatitle: "Iowa City, IA",
    metropolitandivisiontitle: "",
    csatitle: "Cedar Rapids-Iowa City, IA",
    countycountyequivalent: "Washington County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 183,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Dodge, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Webster County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 187,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43580,
    metrodivisioncode: "",
    csacode: 512,
    cbsatitle: "Sioux City, IA-NE-SD",
    metropolitandivisiontitle: "",
    csatitle: "Sioux City-Vermillion, IA-SD-NE",
    countycountyequivalent: "Woodbury County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 193,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mason City, IA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Worth County",
    statename: "Iowa",
    fipsstatecode: 19,
    fipscountycode: 195,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11860,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Atchison, KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Atchison County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Great Bend, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Barton County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48620,
    metrodivisioncode: "",
    csacode: 556,
    cbsatitle: "Wichita, KS",
    metropolitandivisiontitle: "",
    csatitle: "Wichita-Arkansas City-Winfield, KS",
    countycountyequivalent: "Butler County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11680,
    metrodivisioncode: "",
    csacode: 556,
    cbsatitle: "Arkansas City-Winfield, KS",
    metropolitandivisiontitle: "",
    csatitle: "Wichita-Arkansas City-Winfield, KS",
    countycountyequivalent: "Cowley County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pittsburg, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Crawford County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "St. Joseph, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Doniphan County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29940,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Lawrence, KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Douglas County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hays, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ellis County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Garden City, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Finney County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dodge City, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ford County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36840,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Ottawa, KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Franklin County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27920,
    metrodivisioncode: "",
    csacode: 358,
    cbsatitle: "Junction City, KS",
    metropolitandivisiontitle: "",
    csatitle: "Manhattan-Junction City, KS",
    countycountyequivalent: "Geary County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48620,
    metrodivisioncode: "",
    csacode: 556,
    cbsatitle: "Wichita, KS",
    metropolitandivisiontitle: "",
    csatitle: "Wichita-Arkansas City-Winfield, KS",
    countycountyequivalent: "Harvey County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Topeka, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 85,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Topeka, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jefferson County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Johnson County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Garden City, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kearny County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48620,
    metrodivisioncode: "",
    csacode: 556,
    cbsatitle: "Wichita, KS",
    metropolitandivisiontitle: "",
    csatitle: "Wichita-Arkansas City-Winfield, KS",
    countycountyequivalent: "Kingman County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 95,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Parsons, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Labette County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Leavenworth County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Linn County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 107,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Emporia, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lyon County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "McPherson, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McPherson County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Miami County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 121,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Coffeyville, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Montgomery County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Topeka, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Osage County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 139,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salina, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ottawa County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 143,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31740,
    metrodivisioncode: "",
    csacode: 358,
    cbsatitle: "Manhattan, KS",
    metropolitandivisiontitle: "",
    csatitle: "Manhattan-Junction City, KS",
    countycountyequivalent: "Pottawatomie County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hutchinson, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Reno County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 155,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31740,
    metrodivisioncode: "",
    csacode: 358,
    cbsatitle: "Manhattan, KS",
    metropolitandivisiontitle: "",
    csatitle: "Manhattan-Junction City, KS",
    countycountyequivalent: "Riley County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salina, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Saline County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48620,
    metrodivisioncode: "",
    csacode: 556,
    cbsatitle: "Wichita, KS",
    metropolitandivisiontitle: "",
    csatitle: "Wichita-Arkansas City-Winfield, KS",
    countycountyequivalent: "Sedgwick County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 173,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Liberal, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Seward County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 175,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Topeka, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Shawnee County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 177,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48620,
    metrodivisioncode: "",
    csacode: 556,
    cbsatitle: "Wichita, KS",
    metropolitandivisiontitle: "",
    csatitle: "Wichita-Arkansas City-Winfield, KS",
    countycountyequivalent: "Sumner County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 191,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Topeka, KS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wabaunsee County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 197,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Wyandotte County",
    statename: "Kansas",
    fipsstatecode: 20,
    fipscountycode: 209,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14540,
    metrodivisioncode: "",
    csacode: 150,
    cbsatitle: "Bowling Green, KY",
    metropolitandivisiontitle: "",
    csatitle: "Bowling Green-Glasgow, KY",
    countycountyequivalent: "Allen County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 3,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23180,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Frankfort, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Anderson County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 5,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37140,
    metrodivisioncode: "",
    csacode: 424,
    cbsatitle: "Paducah, KY-IL",
    metropolitandivisiontitle: "",
    csatitle: "Paducah-Mayfield, KY-IL",
    countycountyequivalent: "Ballard County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23980,
    metrodivisioncode: "",
    csacode: 150,
    cbsatitle: "Glasgow, KY",
    metropolitandivisiontitle: "",
    csatitle: "Bowling Green-Glasgow, KY",
    countycountyequivalent: "Barren County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Mount Sterling, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Bath County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 11,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Middlesborough, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bell County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Boone County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Lexington-Fayette, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Bourbon County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Boyd County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Danville, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Boyle County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Bracken County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Bullitt County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14540,
    metrodivisioncode: "",
    csacode: 150,
    cbsatitle: "Bowling Green, KY",
    metropolitandivisiontitle: "",
    csatitle: "Bowling Green-Glasgow, KY",
    countycountyequivalent: "Butler County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 31,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Murray, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Calloway County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Campbell County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksville, TN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Christian County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 47,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Lexington-Fayette, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Clark County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 49,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Owensboro, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Daviess County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14540,
    metrodivisioncode: "",
    csacode: 150,
    cbsatitle: "Bowling Green, KY",
    metropolitandivisiontitle: "",
    csatitle: "Bowling Green-Glasgow, KY",
    countycountyequivalent: "Edmonson County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Lexington-Fayette, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Fayette County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23180,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Frankfort, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Franklin County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46460,
    metrodivisioncode: "",
    csacode: 362,
    cbsatitle: "Union City, TN-KY",
    metropolitandivisiontitle: "",
    csatitle: "Martin-Union City, TN-KY",
    countycountyequivalent: "Fulton County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 75,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Gallatin County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 77,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Grant County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32460,
    metrodivisioncode: "",
    csacode: 424,
    cbsatitle: "Mayfield, KY",
    metropolitandivisiontitle: "",
    csatitle: "Paducah-Mayfield, KY-IL",
    countycountyequivalent: "Graves County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Greenup County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Owensboro, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hancock County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 91,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21060,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Elizabethtown-Fort Knox, KY",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Hardin County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Evansville, IN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Henderson County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Henry County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Madisonville, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hopkins County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Jefferson County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Lexington-Fayette, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Jessamine County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 113,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Kenton County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "London, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Knox County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21060,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Elizabethtown-Fort Knox, KY",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Larue County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 123,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "London, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Laurel County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Danville, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lincoln County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 137,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37140,
    metrodivisioncode: "",
    csacode: 424,
    cbsatitle: "Paducah, KY-IL",
    metropolitandivisiontitle: "",
    csatitle: "Paducah-Mayfield, KY-IL",
    countycountyequivalent: "Livingston County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 139,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37140,
    metrodivisioncode: "",
    csacode: 424,
    cbsatitle: "Paducah, KY-IL",
    metropolitandivisiontitle: "",
    csatitle: "Paducah-Mayfield, KY-IL",
    countycountyequivalent: "McCracken County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Owensboro, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McLean County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40080,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Richmond-Berea, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Madison County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 151,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32500,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Maysville, KY",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Mason County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21060,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Elizabethtown-Fort Knox, KY",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Meade County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 163,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Mount Sterling, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Menifee County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 165,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23980,
    metrodivisioncode: "",
    csacode: 150,
    cbsatitle: "Glasgow, KY",
    metropolitandivisiontitle: "",
    csatitle: "Bowling Green-Glasgow, KY",
    countycountyequivalent: "Metcalfe County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 169,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Mount Sterling, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Montgomery County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 173,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12680,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Bardstown, KY",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Nelson County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Oldham County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 185,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Pendleton County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 191,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Somerset, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pulaski County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 199,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40080,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Richmond-Berea, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Rockcastle County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 203,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Lexington-Fayette, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Scott County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 209,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Shelby County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 211,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Spencer County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 215,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Campbellsville, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Taylor County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 217,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksville, TN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Trigg County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 221,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31140,
    metrodivisioncode: "",
    csacode: 350,
    cbsatitle: "Louisville/Jefferson County, KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Louisville/Jefferson County--Elizabethtown--Madison, KY-IN",
    countycountyequivalent: "Trimble County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 223,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14540,
    metrodivisioncode: "",
    csacode: 150,
    cbsatitle: "Bowling Green, KY",
    metropolitandivisiontitle: "",
    csatitle: "Bowling Green-Glasgow, KY",
    countycountyequivalent: "Warren County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 227,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "London, KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Whitley County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 235,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30460,
    metrodivisioncode: "",
    csacode: 336,
    cbsatitle: "Lexington-Fayette, KY",
    metropolitandivisiontitle: "",
    csatitle: "Lexington-Fayette--Richmond--Frankfort, KY",
    countycountyequivalent: "Woodford County",
    statename: "Kentucky",
    fipsstatecode: 21,
    fipscountycode: 239,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29180,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Lafayette, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "Acadia Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 1,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ascension Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19760,
    metrodivisioncode: "",
    csacode: 217,
    cbsatitle: "DeRidder, LA",
    metropolitandivisiontitle: "",
    csatitle: "DeRidder-Fort Polk South, LA",
    countycountyequivalent: "Beauregard Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Shreveport-Bossier City, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bossier Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Shreveport-Bossier City, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Caddo Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lake Charles, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Calcasieu Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lake Charles, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cameron Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Natchez, MS-LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Concordia Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Shreveport-Bossier City, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "De Soto Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 31,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "East Baton Rouge Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "East Feliciana Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Alexandria, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grant Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29180,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Lafayette, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "Iberia Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Iberville Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "Jefferson Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29180,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Lafayette, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "Lafayette Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Houma-Thibodaux, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lafourche Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40820,
    metrodivisioncode: "",
    csacode: 384,
    cbsatitle: "Ruston, LA",
    metropolitandivisiontitle: "",
    csatitle: "Monroe-Ruston-Bastrop, LA",
    countycountyequivalent: "Lincoln Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Livingston Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12820,
    metrodivisioncode: "",
    csacode: 384,
    cbsatitle: "Bastrop, LA",
    metropolitandivisiontitle: "",
    csatitle: "Monroe-Ruston-Bastrop, LA",
    countycountyequivalent: "Morehouse Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Natchitoches, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Natchitoches Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "Orleans Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33740,
    metrodivisioncode: "",
    csacode: 384,
    cbsatitle: "Monroe, LA",
    metropolitandivisiontitle: "",
    csatitle: "Monroe-Ruston-Bastrop, LA",
    countycountyequivalent: "Ouachita Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "Plaquemines Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pointe Coupee Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 77,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Alexandria, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Rapides Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "St. Bernard Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "St. Charles Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "St. Helena Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 91,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "St. James Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "St. John the Baptist Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36660,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Opelousas, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "St. Landry Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29180,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Lafayette, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "St. Martin Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 99,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34020,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Morgan City, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "St. Mary Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35380,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "New Orleans-Metairie, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "St. Tammany Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25220,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "Hammond, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "Tangipahoa Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Houma-Thibodaux, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Terrebonne Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33740,
    metrodivisioncode: "",
    csacode: 384,
    cbsatitle: "Monroe, LA",
    metropolitandivisiontitle: "",
    csatitle: "Monroe-Ruston-Bastrop, LA",
    countycountyequivalent: "Union Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29180,
    metrodivisioncode: "",
    csacode: 318,
    cbsatitle: "Lafayette, LA",
    metropolitandivisiontitle: "",
    csatitle: "Lafayette-Opelousas-Morgan City, LA",
    countycountyequivalent: "Vermilion Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 113,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22860,
    metrodivisioncode: "",
    csacode: 217,
    cbsatitle: "Fort Polk South, LA",
    metropolitandivisiontitle: "",
    csatitle: "DeRidder-Fort Polk South, LA",
    countycountyequivalent: "Vernon Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14220,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "Bogalusa, LA",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "Washington Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Shreveport-Bossier City, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Webster Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 119,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "West Baton Rouge Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Baton Rouge, LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "West Feliciana Parish",
    statename: "Louisiana",
    fipsstatecode: 22,
    fipscountycode: 125,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30340,
    metrodivisioncode: "",
    csacode: 438,
    cbsatitle: "Lewiston-Auburn, ME",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Lewiston-South Portland, ME",
    countycountyequivalent: "Androscoggin County",
    statename: "Maine",
    fipsstatecode: 23,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38860,
    metrodivisioncode: "",
    csacode: 438,
    cbsatitle: "Portland-South Portland, ME",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Lewiston-South Portland, ME",
    countycountyequivalent: "Cumberland County",
    statename: "Maine",
    fipsstatecode: 23,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Waterville, ME",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kennebec County",
    statename: "Maine",
    fipsstatecode: 23,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bangor, ME",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Penobscot County",
    statename: "Maine",
    fipsstatecode: 23,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38860,
    metrodivisioncode: "",
    csacode: 438,
    cbsatitle: "Portland-South Portland, ME",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Lewiston-South Portland, ME",
    countycountyequivalent: "Sagadahoc County",
    statename: "Maine",
    fipsstatecode: 23,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38860,
    metrodivisioncode: "",
    csacode: 438,
    cbsatitle: "Portland-South Portland, ME",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Lewiston-South Portland, ME",
    countycountyequivalent: "York County",
    statename: "Maine",
    fipsstatecode: 23,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cumberland, MD-WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Allegany County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Anne Arundel County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Baltimore County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Calvert County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Carroll County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 48864,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Wilmington, DE-MD-NJ",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Cecil County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Charles County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15700,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Cambridge, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Dorchester County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 43524,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Silver Spring-Frederick-Rockville, MD",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Frederick County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Harford County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Howard County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 43524,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Silver Spring-Frederick-Rockville, MD",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Montgomery County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Prince George's County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Queen Anne's County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15680,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "California-Lexington Park, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "St. Mary's County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salisbury, MD-DE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Somerset County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 39,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20660,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Easton, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Talbot County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25180,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Hagerstown-Martinsburg, MD-WV",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Washington County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salisbury, MD-DE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wicomico County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Salisbury, MD-DE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Worcester County",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 47,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12580,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Baltimore-Columbia-Towson, MD",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Baltimore city",
    statename: "Maryland",
    fipsstatecode: 24,
    fipscountycode: 510,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12700,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Barnstable Town, MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Barnstable County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pittsfield, MA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Berkshire County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39300,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Providence-Warwick, RI-MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Bristol County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47240,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Vineyard Haven, MA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dukes County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 15764,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Cambridge-Newton-Framingham, MA",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Essex County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24640,
    metrodivisioncode: "",
    csacode: 521,
    cbsatitle: "Greenfield Town, MA",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Greenfield Town, MA",
    countycountyequivalent: "Franklin County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44140,
    metrodivisioncode: "",
    csacode: 521,
    cbsatitle: "Springfield, MA",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Greenfield Town, MA",
    countycountyequivalent: "Hampden County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44140,
    metrodivisioncode: "",
    csacode: 521,
    cbsatitle: "Springfield, MA",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Greenfield Town, MA",
    countycountyequivalent: "Hampshire County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 15764,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Cambridge-Newton-Framingham, MA",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Middlesex County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 14454,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Boston, MA",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Norfolk County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 14454,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Boston, MA",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Plymouth County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 14454,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Boston, MA",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Suffolk County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49340,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Worcester, MA-CT",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Worcester County",
    statename: "Massachusetts",
    fipsstatecode: 25,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26090,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Holland, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Allegan County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Alpena, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Alpena County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24340,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Grand Rapids-Wyoming, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Barry County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13020,
    metrodivisioncode: "",
    csacode: 474,
    cbsatitle: "Bay City, MI",
    metropolitandivisiontitle: "",
    csatitle: "Saginaw-Midland-Bay City, MI",
    countycountyequivalent: "Bay County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Traverse City, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Benzie County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 19,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35660,
    metrodivisioncode: "",
    csacode: 515,
    cbsatitle: "Niles-Benton Harbor, MI",
    metropolitandivisiontitle: "",
    csatitle: "South Bend-Elkhart-Mishawaka, IN-MI",
    countycountyequivalent: "Berrien County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Coldwater, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Branch County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12980,
    metrodivisioncode: "",
    csacode: 310,
    cbsatitle: "Battle Creek, MI",
    metropolitandivisiontitle: "",
    csatitle: "Kalamazoo-Battle Creek-Portage, MI",
    countycountyequivalent: "Calhoun County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43780,
    metrodivisioncode: "",
    csacode: 515,
    cbsatitle: "South Bend-Mishawaka, IN-MI",
    metropolitandivisiontitle: "",
    csatitle: "South Bend-Elkhart-Mishawaka, IN-MI",
    countycountyequivalent: "Cass County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sault Ste. Marie, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chippewa County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29620,
    metrodivisioncode: "",
    csacode: 330,
    cbsatitle: "Lansing-East Lansing, MI",
    metropolitandivisiontitle: "",
    csatitle: "Lansing-East Lansing-Owosso, MI",
    countycountyequivalent: "Clinton County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Escanaba, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Delta County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Iron Mountain, MI-WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dickinson County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29620,
    metrodivisioncode: "",
    csacode: 330,
    cbsatitle: "Lansing-East Lansing, MI",
    metropolitandivisiontitle: "",
    csatitle: "Lansing-East Lansing-Owosso, MI",
    countycountyequivalent: "Eaton County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22420,
    metrodivisioncode: "",
    csacode: 220,
    cbsatitle: "Flint, MI",
    metropolitandivisiontitle: "",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Genesee County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Traverse City, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grand Traverse County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10940,
    metrodivisioncode: "",
    csacode: 394,
    cbsatitle: "Alma, MI",
    metropolitandivisiontitle: "",
    csatitle: "Mount Pleasant-Alma, MI",
    countycountyequivalent: "Gratiot County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25880,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hillsdale, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hillsdale County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Houghton, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Houghton County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29620,
    metrodivisioncode: "",
    csacode: 330,
    cbsatitle: "Lansing-East Lansing, MI",
    metropolitandivisiontitle: "",
    csatitle: "Lansing-East Lansing-Owosso, MI",
    countycountyequivalent: "Ingham County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26960,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Ionia, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Ionia County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34380,
    metrodivisioncode: "",
    csacode: 394,
    cbsatitle: "Mount Pleasant, MI",
    metropolitandivisiontitle: "",
    csatitle: "Mount Pleasant-Alma, MI",
    countycountyequivalent: "Isabella County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28020,
    metrodivisioncode: "",
    csacode: 310,
    cbsatitle: "Kalamazoo-Portage, MI",
    metropolitandivisiontitle: "",
    csatitle: "Kalamazoo-Battle Creek-Portage, MI",
    countycountyequivalent: "Kalamazoo County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Traverse City, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kalkaska County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24340,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Grand Rapids-Wyoming, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Kent County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Houghton, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Keweenaw County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 83,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19820,
    metrodivisioncode: 47664,
    csacode: 220,
    cbsatitle: "Detroit-Warren-Dearborn, MI",
    metropolitandivisiontitle: "Warren-Troy-Farmington Hills, MI",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Lapeer County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Traverse City, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Leelanau County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 89,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10300,
    metrodivisioncode: "",
    csacode: 220,
    cbsatitle: "Adrian, MI",
    metropolitandivisiontitle: "",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Lenawee County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19820,
    metrodivisioncode: 47664,
    csacode: 220,
    cbsatitle: "Detroit-Warren-Dearborn, MI",
    metropolitandivisiontitle: "Warren-Troy-Farmington Hills, MI",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Livingston County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19820,
    metrodivisioncode: 47664,
    csacode: 220,
    cbsatitle: "Detroit-Warren-Dearborn, MI",
    metropolitandivisiontitle: "Warren-Troy-Farmington Hills, MI",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Macomb County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marquette, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Marquette County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ludington, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mason County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13660,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Big Rapids, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Mecosta County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marinette, WI-MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Menominee County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33220,
    metrodivisioncode: "",
    csacode: 474,
    cbsatitle: "Midland, MI",
    metropolitandivisiontitle: "",
    csatitle: "Saginaw-Midland-Bay City, MI",
    countycountyequivalent: "Midland County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cadillac, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Missaukee County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 113,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33780,
    metrodivisioncode: "",
    csacode: 220,
    cbsatitle: "Monroe, MI",
    metropolitandivisiontitle: "",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Monroe County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24340,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Grand Rapids-Wyoming, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Montcalm County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 117,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34740,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Muskegon, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Muskegon County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19820,
    metrodivisioncode: 47664,
    csacode: 220,
    cbsatitle: "Detroit-Warren-Dearborn, MI",
    metropolitandivisiontitle: "Warren-Troy-Farmington Hills, MI",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Oakland County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24340,
    metrodivisioncode: "",
    csacode: 266,
    cbsatitle: "Grand Rapids-Wyoming, MI",
    metropolitandivisiontitle: "",
    csatitle: "Grand Rapids-Wyoming-Muskegon, MI",
    countycountyequivalent: "Ottawa County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 139,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40980,
    metrodivisioncode: "",
    csacode: 474,
    cbsatitle: "Saginaw, MI",
    metropolitandivisiontitle: "",
    csatitle: "Saginaw-Midland-Bay City, MI",
    countycountyequivalent: "Saginaw County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19820,
    metrodivisioncode: 47664,
    csacode: 220,
    cbsatitle: "Detroit-Warren-Dearborn, MI",
    metropolitandivisiontitle: "Warren-Troy-Farmington Hills, MI",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "St. Clair County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 147,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44780,
    metrodivisioncode: "",
    csacode: 310,
    cbsatitle: "Sturgis, MI",
    metropolitandivisiontitle: "",
    csatitle: "Kalamazoo-Battle Creek-Portage, MI",
    countycountyequivalent: "St. Joseph County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 149,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37020,
    metrodivisioncode: "",
    csacode: 330,
    cbsatitle: "Owosso, MI",
    metropolitandivisiontitle: "",
    csatitle: "Lansing-East Lansing-Owosso, MI",
    countycountyequivalent: "Shiawassee County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 155,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28020,
    metrodivisioncode: "",
    csacode: 310,
    cbsatitle: "Kalamazoo-Portage, MI",
    metropolitandivisiontitle: "",
    csatitle: "Kalamazoo-Battle Creek-Portage, MI",
    countycountyequivalent: "Van Buren County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 159,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11460,
    metrodivisioncode: "",
    csacode: 220,
    cbsatitle: "Ann Arbor, MI",
    metropolitandivisiontitle: "",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Washtenaw County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19820,
    metrodivisioncode: 19804,
    csacode: 220,
    cbsatitle: "Detroit-Warren-Dearborn, MI",
    metropolitandivisiontitle: "Detroit-Dearborn-Livonia, MI",
    csatitle: "Detroit-Warren-Ann Arbor, MI",
    countycountyequivalent: "Wayne County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 163,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cadillac, MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wexford County",
    statename: "Michigan",
    fipsstatecode: 26,
    fipscountycode: 165,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Anoka County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bemidji, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Beltrami County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41060,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "St. Cloud, MN",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Benton County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31860,
    metrodivisioncode: "",
    csacode: 359,
    cbsatitle: "Mankato-North Mankato, MN",
    metropolitandivisiontitle: "",
    csatitle: "Mankato-New Ulm-North Mankato, MN",
    countycountyequivalent: "Blue Earth County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35580,
    metrodivisioncode: "",
    csacode: 359,
    cbsatitle: "New Ulm, MN",
    metropolitandivisiontitle: "",
    csatitle: "Mankato-New Ulm-North Mankato, MN",
    countycountyequivalent: "Brown County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Duluth, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Carlton County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Carver County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brainerd, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cass County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Chisago County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22020,
    metrodivisioncode: "",
    csacode: 244,
    cbsatitle: "Fargo, ND-MN",
    metropolitandivisiontitle: "",
    csatitle: "Fargo-Wahpeton, ND-MN",
    countycountyequivalent: "Clay County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brainerd, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Crow Wing County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Dakota County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40340,
    metrodivisioncode: "",
    csacode: 462,
    cbsatitle: "Rochester, MN",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Austin, MN",
    countycountyequivalent: "Dodge County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 39,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Alexandria, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Douglas County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40340,
    metrodivisioncode: "",
    csacode: 462,
    cbsatitle: "Rochester, MN",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Austin, MN",
    countycountyequivalent: "Fillmore County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Albert Lea, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Freeborn County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39860,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Red Wing, MN",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Goodhue County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Hennepin County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "La Crosse-Onalaska, WI-MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Houston County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Isanti County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Willmar, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kandiyohi County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Le Sueur County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marshall, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lyon County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26780,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Hutchinson, MN",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "McLeod County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Mille Lacs County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 95,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12380,
    metrodivisioncode: "",
    csacode: 462,
    cbsatitle: "Austin, MN",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Austin, MN",
    countycountyequivalent: "Mower County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31860,
    metrodivisioncode: "",
    csacode: 359,
    cbsatitle: "Mankato-North Mankato, MN",
    metropolitandivisiontitle: "",
    csatitle: "Mankato-New Ulm-North Mankato, MN",
    countycountyequivalent: "Nicollet County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Worthington, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Nobles County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40340,
    metrodivisioncode: "",
    csacode: 462,
    cbsatitle: "Rochester, MN",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Austin, MN",
    countycountyequivalent: "Olmsted County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fergus Falls, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Otter Tail County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Forks, ND-MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Polk County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Ramsey County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22060,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Faribault-Northfield, MN",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Rice County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 131,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Duluth, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "St. Louis County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 137,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Scott County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Sherburne County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Sibley County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 143,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41060,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "St. Cloud, MN",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Stearns County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Owatonna, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Steele County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 147,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40340,
    metrodivisioncode: "",
    csacode: 462,
    cbsatitle: "Rochester, MN",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Austin, MN",
    countycountyequivalent: "Wabasha County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 157,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Washington County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 163,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47420,
    metrodivisioncode: "",
    csacode: 244,
    cbsatitle: "Wahpeton, ND-MN",
    metropolitandivisiontitle: "",
    csatitle: "Fargo-Wahpeton, ND-MN",
    countycountyequivalent: "Wilkin County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 167,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Winona, MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Winona County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Wright County",
    statename: "Minnesota",
    fipsstatecode: 27,
    fipscountycode: 171,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Natchez, MS-LA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Adams County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Corinth, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Alcorn County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "McComb, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Amite County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 5,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Benton County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17380,
    metrodivisioncode: "",
    csacode: 185,
    cbsatitle: "Cleveland, MS",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Indianola, MS",
    countycountyequivalent: "Bolivar County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Greenwood, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Carroll County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46980,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Vicksburg, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Claiborne County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Meridian, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clarke County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksdale, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coahoma County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27140,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Jackson, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Copiah County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 29,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "DeSoto County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hattiesburg, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Forrest County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grenada, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grenada County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Gulfport-Biloxi-Pascagoula, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hancock County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Gulfport-Biloxi-Pascagoula, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Harrison County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27140,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Jackson, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Hinds County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tupelo, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Itawamba County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 57,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Gulfport-Biloxi-Pascagoula, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Laurel, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jasper County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Laurel, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jones County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Meridian, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kemper County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 69,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Oxford, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lafayette County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hattiesburg, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lamar County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Meridian, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lauderdale County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tupelo, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lee County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Greenwood, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Leflore County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15020,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Brookhaven, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Lincoln County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Columbus, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lowndes County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27140,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Jackson, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Madison County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Marshall County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Starkville, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Oktibbeha County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38100,
    metrodivisioncode: "",
    csacode: 406,
    cbsatitle: "Picayune, MS",
    metropolitandivisiontitle: "",
    csatitle: "New Orleans-Metairie-Hammond, LA-MS",
    countycountyequivalent: "Pearl River County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hattiesburg, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Perry County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "McComb, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pike County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tupelo, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pontotoc County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 115,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27140,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Jackson, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Rankin County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27140,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Jackson, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Simpson County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 127,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26940,
    metrodivisioncode: "",
    csacode: 185,
    cbsatitle: "Indianola, MS",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Indianola, MS",
    countycountyequivalent: "Sunflower County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Tate County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 137,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Tunica County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 143,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46980,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Vicksburg, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Warren County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 149,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Greenville, MS",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Washington County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 151,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27140,
    metrodivisioncode: "",
    csacode: 298,
    cbsatitle: "Jackson, MS",
    metropolitandivisiontitle: "",
    csatitle: "Jackson-Vicksburg-Brookhaven, MS",
    countycountyequivalent: "Yazoo County",
    statename: "Mississippi",
    fipsstatecode: 28,
    fipscountycode: 163,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kirksville, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Adair County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "St. Joseph, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Andrew County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 3,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33020,
    metrodivisioncode: "",
    csacode: 190,
    cbsatitle: "Mexico, MO",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Moberly-Mexico, MO",
    countycountyequivalent: "Audrain County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Bates County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16020,
    metrodivisioncode: "",
    csacode: 164,
    cbsatitle: "Cape Girardeau, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "Cape Girardeau-Sikeston, MO-IL",
    countycountyequivalent: "Bollinger County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17860,
    metrodivisioncode: "",
    csacode: 190,
    cbsatitle: "Columbia, MO",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Moberly-Mexico, MO",
    countycountyequivalent: "Boone County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "St. Joseph, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Buchanan County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Poplar Bluff, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Butler County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Caldwell County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jefferson City, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Callaway County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 27,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16020,
    metrodivisioncode: "",
    csacode: 164,
    cbsatitle: "Cape Girardeau, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "Cape Girardeau-Sikeston, MO-IL",
    countycountyequivalent: "Cape Girardeau County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Cass County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44180,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Springfield, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Christian County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22800,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Madison-Keokuk, IA-IL-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clark County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Clay County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Clinton County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 49,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jefferson City, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cole County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44180,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Springfield, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Dallas County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "St. Joseph, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "DeKalb County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 63,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kennett, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dunklin County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Franklin County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 71,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44180,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Springfield, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Greene County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "West Plains, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Howell County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Jackson County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27900,
    metrodivisioncode: "",
    csacode: 309,
    cbsatitle: "Joplin, MO",
    metropolitandivisiontitle: "",
    csatitle: "Joplin-Miami, MO-OK",
    countycountyequivalent: "Jasper County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Jefferson County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47660,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Warrensburg, MO",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Johnson County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lebanon, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Laclede County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Lafayette County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 107,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39500,
    metrodivisioncode: "",
    csacode: 448,
    cbsatitle: "Quincy, IL-MO",
    metropolitandivisiontitle: "",
    csatitle: "Quincy-Hannibal, IL-MO",
    countycountyequivalent: "Lewis County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Lincoln County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 113,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fayetteville-Springdale-Rogers, AR-MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McDonald County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 119,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25300,
    metrodivisioncode: "",
    csacode: 448,
    cbsatitle: "Hannibal, MO",
    metropolitandivisiontitle: "",
    csatitle: "Quincy-Hannibal, IL-MO",
    countycountyequivalent: "Marion County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jefferson City, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Moniteau County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 135,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27900,
    metrodivisioncode: "",
    csacode: 309,
    cbsatitle: "Joplin, MO",
    metropolitandivisiontitle: "",
    csatitle: "Joplin-Miami, MO-OK",
    countycountyequivalent: "Newton County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Maryville, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Nodaway County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 147,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jefferson City, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Osage County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 151,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sedalia, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pettis County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 159,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Rolla, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Phelps County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Platte County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 165,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44180,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Springfield, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Polk County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 167,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Leonard Wood, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pulaski County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25300,
    metrodivisioncode: "",
    csacode: 448,
    cbsatitle: "Hannibal, MO",
    metropolitandivisiontitle: "",
    csatitle: "Quincy-Hannibal, IL-MO",
    countycountyequivalent: "Ralls County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 173,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33620,
    metrodivisioncode: "",
    csacode: 190,
    cbsatitle: "Moberly, MO",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Moberly-Mexico, MO",
    countycountyequivalent: "Randolph County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 175,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28140,
    metrodivisioncode: "",
    csacode: 312,
    cbsatitle: "Kansas City, MO-KS",
    metropolitandivisiontitle: "",
    csatitle: "Kansas City-Overland Park-Kansas City, MO-KS",
    countycountyequivalent: "Ray County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 177,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "St. Charles County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 183,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22100,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "Farmington, MO",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "St. Francois County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 187,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "St. Louis County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 189,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marshall, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Saline County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 195,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kirksville, MO",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Schuyler County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 197,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43460,
    metrodivisioncode: "",
    csacode: 164,
    cbsatitle: "Sikeston, MO",
    metropolitandivisiontitle: "",
    csatitle: "Cape Girardeau-Sikeston, MO-IL",
    countycountyequivalent: "Scott County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 201,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14700,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Branson, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Stone County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 209,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14700,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Branson, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Taney County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 213,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "Warren County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 219,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44180,
    metrodivisioncode: "",
    csacode: 520,
    cbsatitle: "Springfield, MO",
    metropolitandivisiontitle: "",
    csatitle: "Springfield-Branson, MO",
    countycountyequivalent: "Webster County",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 225,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41180,
    metrodivisioncode: "",
    csacode: 476,
    cbsatitle: "St. Louis, MO-IL",
    metropolitandivisiontitle: "",
    csatitle: "St. Louis-St. Charles-Farmington, MO-IL",
    countycountyequivalent: "St. Louis city",
    statename: "Missouri",
    fipsstatecode: 29,
    fipscountycode: 510,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Billings, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Carbon County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Great Falls, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cascade County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kalispell, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Flathead County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bozeman, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gallatin County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Billings, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Golden Valley County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Helena, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jefferson County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Helena, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lewis and Clark County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Missoula, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Missoula County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Butte-Silver Bow, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Silver Bow County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Billings, MT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Yellowstone County",
    statename: "Montana",
    fipsstatecode: 30,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hastings, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Adams County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Scottsbluff, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Banner County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kearney, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Buffalo County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Cass County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43580,
    metrodivisioncode: "",
    csacode: 512,
    cbsatitle: "Sioux City, IA-NE-SD",
    metropolitandivisiontitle: "",
    csatitle: "Sioux City-Vermillion, IA-SD-NE",
    countycountyequivalent: "Dakota County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lexington, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dawson County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43580,
    metrodivisioncode: "",
    csacode: 512,
    cbsatitle: "Sioux City, IA-NE-SD",
    metropolitandivisiontitle: "",
    csatitle: "Sioux City-Vermillion, IA-SD-NE",
    countycountyequivalent: "Dixon County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23340,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Fremont, NE",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Dodge County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Douglas County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13100,
    metrodivisioncode: "",
    csacode: 339,
    cbsatitle: "Beatrice, NE",
    metropolitandivisiontitle: "",
    csatitle: "Lincoln-Beatrice, NE",
    countycountyequivalent: "Gage County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lexington, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gosper County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Island, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hall County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Island, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hamilton County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Island, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Howard County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kearney, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kearney County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 99,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30700,
    metrodivisioncode: "",
    csacode: 339,
    cbsatitle: "Lincoln, NE",
    metropolitandivisiontitle: "",
    csatitle: "Lincoln-Beatrice, NE",
    countycountyequivalent: "Lancaster County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "North Platte, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lincoln County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "North Platte, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Logan County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 113,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "North Platte, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McPherson County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 117,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Norfolk, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Madison County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Island, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Merrick County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 121,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Norfolk, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pierce County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 139,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Columbus, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Platte County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Sarpy County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Saunders County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 155,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Scottsbluff, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Scotts Bluff County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 157,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30700,
    metrodivisioncode: "",
    csacode: 339,
    cbsatitle: "Lincoln, NE",
    metropolitandivisiontitle: "",
    csatitle: "Lincoln-Beatrice, NE",
    countycountyequivalent: "Seward County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 159,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Scottsbluff, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sioux County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 165,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Norfolk, NE",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Stanton County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 167,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36540,
    metrodivisioncode: "",
    csacode: 420,
    cbsatitle: "Omaha-Council Bluffs, NE-IA",
    metropolitandivisiontitle: "",
    csatitle: "Omaha-Council Bluffs-Fremont, NE-IA",
    countycountyequivalent: "Washington County",
    statename: "Nebraska",
    fipsstatecode: 31,
    fipscountycode: 177,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fallon, NV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Churchill County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29820,
    metrodivisioncode: "",
    csacode: 332,
    cbsatitle: "Las Vegas-Henderson-Paradise, NV",
    metropolitandivisiontitle: "",
    csatitle: "Las Vegas-Henderson, NV-AZ",
    countycountyequivalent: "Clark County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23820,
    metrodivisioncode: "",
    csacode: 456,
    cbsatitle: "Gardnerville Ranchos, NV",
    metropolitandivisiontitle: "",
    csatitle: "Reno-Carson City-Fernley, NV",
    countycountyequivalent: "Douglas County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Elko, NV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Elko County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Elko, NV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Eureka County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 11,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49080,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Winnemucca, NV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Humboldt County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22280,
    metrodivisioncode: "",
    csacode: 456,
    cbsatitle: "Fernley, NV",
    metropolitandivisiontitle: "",
    csatitle: "Reno-Carson City-Fernley, NV",
    countycountyequivalent: "Lyon County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37220,
    metrodivisioncode: "",
    csacode: 332,
    cbsatitle: "Pahrump, NV",
    metropolitandivisiontitle: "",
    csatitle: "Las Vegas-Henderson, NV-AZ",
    countycountyequivalent: "Nye County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39900,
    metrodivisioncode: "",
    csacode: 456,
    cbsatitle: "Reno, NV",
    metropolitandivisiontitle: "",
    csatitle: "Reno-Carson City-Fernley, NV",
    countycountyequivalent: "Storey County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 29,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39900,
    metrodivisioncode: "",
    csacode: 456,
    cbsatitle: "Reno, NV",
    metropolitandivisiontitle: "",
    csatitle: "Reno-Carson City-Fernley, NV",
    countycountyequivalent: "Washoe County",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16180,
    metrodivisioncode: "",
    csacode: 456,
    cbsatitle: "Carson City, NV",
    metropolitandivisiontitle: "",
    csatitle: "Reno-Carson City-Fernley, NV",
    countycountyequivalent: "Carson City",
    statename: "Nevada",
    fipsstatecode: 32,
    fipscountycode: 510,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29060,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Laconia, NH",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Belknap County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Keene, NH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cheshire County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Berlin, NH-VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coos County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Claremont-Lebanon, NH-VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grafton County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31700,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Manchester-Nashua, NH",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Hillsborough County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18180,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Concord, NH",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Merrimack County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 40484,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Rockingham County-Strafford County, NH",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Rockingham County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14460,
    metrodivisioncode: 40484,
    csacode: 148,
    cbsatitle: "Boston-Cambridge-Newton, MA-NH",
    metropolitandivisiontitle: "Rockingham County-Strafford County, NH",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Strafford County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Claremont-Lebanon, NH-VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sullivan County",
    statename: "New Hampshire",
    fipsstatecode: 33,
    fipscountycode: 19,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12100,
    metrodivisioncode: "",
    csacode: 428,
    cbsatitle: "Atlantic City-Hammonton, NJ",
    metropolitandivisiontitle: "",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Atlantic County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Bergen County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Sullivan County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 105,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 15804,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Camden, NJ",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Burlington County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 15804,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Camden, NJ",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Camden County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36140,
    metrodivisioncode: "",
    csacode: 428,
    cbsatitle: "Ocean City, NJ",
    metropolitandivisiontitle: "",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Cape May County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47220,
    metrodivisioncode: "",
    csacode: 428,
    cbsatitle: "Vineland-Bridgeton, NJ",
    metropolitandivisiontitle: "",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Cumberland County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Essex County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 15804,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Camden, NJ",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Gloucester County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Hudson County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Hunterdon County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45940,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Trenton, NJ",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Mercer County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Middlesex County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Monmouth County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Morris County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Ocean County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Passaic County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 48864,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Wilmington, DE-MD-NJ",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Salem County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Somerset County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Sussex County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Union County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10900,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Allentown-Bethlehem-Easton, PA-NJ",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Warren County",
    statename: "New Jersey",
    fipsstatecode: 34,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10740,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Albuquerque, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Bernalillo County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roswell, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chaves County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24380,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Grants, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Cibola County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 6,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17580,
    metrodivisioncode: "",
    csacode: 188,
    cbsatitle: "Clovis, NM",
    metropolitandivisiontitle: "",
    csatitle: "Clovis-Portales, NM",
    countycountyequivalent: "Curry County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29740,
    metrodivisioncode: "",
    csacode: 238,
    cbsatitle: "Las Cruces, NM",
    metropolitandivisiontitle: "",
    csatitle: "El Paso-Las Cruces, TX-NM",
    countycountyequivalent: "Doña Ana County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Carlsbad-Artesia, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Eddy County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Silver City, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grant County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hobbs, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lea County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31060,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Los Alamos, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Los Alamos County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 28,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Deming, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Luna County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Gallup, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McKinley County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Alamogordo, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Otero County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21580,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Española, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Rio Arriba County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38780,
    metrodivisioncode: "",
    csacode: 188,
    cbsatitle: "Portales, NM",
    metropolitandivisiontitle: "",
    csatitle: "Clovis-Portales, NM",
    countycountyequivalent: "Roosevelt County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10740,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Albuquerque, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Sandoval County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Farmington, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "San Juan County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29780,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Las Vegas, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "San Miguel County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42140,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Santa Fe, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Santa Fe County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Taos, NM",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Taos County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10740,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Albuquerque, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Torrance County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 57,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10740,
    metrodivisioncode: "",
    csacode: 106,
    cbsatitle: "Albuquerque, NM",
    metropolitandivisiontitle: "",
    csatitle: "Albuquerque-Santa Fe-Las Vegas, NM",
    countycountyequivalent: "Valencia County",
    statename: "New Mexico",
    fipsstatecode: 35,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10580,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Albany-Schenectady-Troy, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Albany County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Bronx County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Binghamton, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Broome County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36460,
    metrodivisioncode: "",
    csacode: 160,
    cbsatitle: "Olean, NY",
    metropolitandivisiontitle: "",
    csatitle: "Buffalo-Cheektowaga, NY",
    countycountyequivalent: "Cattaraugus County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12180,
    metrodivisioncode: "",
    csacode: 532,
    cbsatitle: "Auburn, NY",
    metropolitandivisiontitle: "",
    csatitle: "Syracuse-Auburn, NY",
    countycountyequivalent: "Cayuga County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jamestown-Dunkirk-Fredonia, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chautauqua County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21300,
    metrodivisioncode: "",
    csacode: 236,
    cbsatitle: "Elmira, NY",
    metropolitandivisiontitle: "",
    csatitle: "Elmira-Corning, NY",
    countycountyequivalent: "Chemung County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Plattsburgh, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clinton County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26460,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Hudson, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Columbia County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18660,
    metrodivisioncode: "",
    csacode: 296,
    cbsatitle: "Cortland, NY",
    metropolitandivisiontitle: "",
    csatitle: "Ithaca-Cortland, NY",
    countycountyequivalent: "Cortland County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 20524,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Dutchess County-Putnam County, NY",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Dutchess County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 27,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15380,
    metrodivisioncode: "",
    csacode: 160,
    cbsatitle: "Buffalo-Cheektowaga-Niagara Falls, NY",
    metropolitandivisiontitle: "",
    csatitle: "Buffalo-Cheektowaga, NY",
    countycountyequivalent: "Erie County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Malone, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Franklin County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24100,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Gloversville, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Fulton County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12860,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Batavia, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Genesee County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Utica-Rome, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Herkimer County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Watertown-Fort Drum, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jefferson County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Kings County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40380,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Rochester, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Livingston County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45060,
    metrodivisioncode: "",
    csacode: 532,
    cbsatitle: "Syracuse, NY",
    metropolitandivisiontitle: "",
    csatitle: "Syracuse-Auburn, NY",
    countycountyequivalent: "Madison County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40380,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Rochester, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Monroe County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11220,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Amsterdam, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Montgomery County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35004,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Nassau County-Suffolk County, NY",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Nassau County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "New York County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15380,
    metrodivisioncode: "",
    csacode: 160,
    cbsatitle: "Buffalo-Cheektowaga-Niagara Falls, NY",
    metropolitandivisiontitle: "",
    csatitle: "Buffalo-Cheektowaga, NY",
    countycountyequivalent: "Niagara County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Utica-Rome, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Oneida County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45060,
    metrodivisioncode: "",
    csacode: 532,
    cbsatitle: "Syracuse, NY",
    metropolitandivisiontitle: "",
    csatitle: "Syracuse-Auburn, NY",
    countycountyequivalent: "Onondaga County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40380,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Rochester, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Ontario County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Orange County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 71,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40380,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Rochester, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Orleans County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45060,
    metrodivisioncode: "",
    csacode: 532,
    cbsatitle: "Syracuse, NY",
    metropolitandivisiontitle: "",
    csatitle: "Syracuse-Auburn, NY",
    countycountyequivalent: "Oswego County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 75,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Oneonta, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Otsego County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 20524,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Dutchess County-Putnam County, NY",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Putnam County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Queens County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10580,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Albany-Schenectady-Troy, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Rensselaer County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Richmond County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Rockland County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ogdensburg-Massena, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "St. Lawrence County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10580,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Albany-Schenectady-Troy, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Saratoga County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10580,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Albany-Schenectady-Troy, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Schenectady County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10580,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Albany-Schenectady-Troy, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Schoharie County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 95,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42900,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Seneca Falls, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Seneca County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18500,
    metrodivisioncode: "",
    csacode: 236,
    cbsatitle: "Corning, NY",
    metropolitandivisiontitle: "",
    csatitle: "Elmira-Corning, NY",
    countycountyequivalent: "Steuben County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35004,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Nassau County-Suffolk County, NY",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Suffolk County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Binghamton, NY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Tioga County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27060,
    metrodivisioncode: "",
    csacode: 296,
    cbsatitle: "Ithaca, NY",
    metropolitandivisiontitle: "",
    csatitle: "Ithaca-Cortland, NY",
    countycountyequivalent: "Tompkins County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28740,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Kingston, NY",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Ulster County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24020,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Glens Falls, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Warren County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24020,
    metrodivisioncode: "",
    csacode: 104,
    cbsatitle: "Glens Falls, NY",
    metropolitandivisiontitle: "",
    csatitle: "Albany-Schenectady, NY",
    countycountyequivalent: "Washington County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40380,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Rochester, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Wayne County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 117,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35614,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "New York-Jersey City-White Plains, NY-NJ",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Westchester County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40380,
    metrodivisioncode: "",
    csacode: 464,
    cbsatitle: "Rochester, NY",
    metropolitandivisiontitle: "",
    csatitle: "Rochester-Batavia-Seneca Falls, NY",
    countycountyequivalent: "Yates County",
    statename: "New York",
    fipsstatecode: 36,
    fipscountycode: 123,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15500,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Burlington, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Alamance County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25860,
    metrodivisioncode: "",
    csacode: 280,
    cbsatitle: "Hickory-Lenoir-Morganton, NC",
    metropolitandivisiontitle: "",
    csatitle: "Hickory-Lenoir, NC",
    countycountyequivalent: "Alexander County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 3,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47820,
    metrodivisioncode: "",
    csacode: 274,
    cbsatitle: "Washington, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Washington, NC",
    countycountyequivalent: "Beaufort County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34820,
    metrodivisioncode: "",
    csacode: 396,
    cbsatitle: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC",
    metropolitandivisiontitle: "",
    csatitle: "Myrtle Beach-Conway, SC-NC",
    countycountyequivalent: "Brunswick County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11700,
    metrodivisioncode: "",
    csacode: 120,
    cbsatitle: "Asheville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Asheville-Brevard, NC",
    countycountyequivalent: "Buncombe County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25860,
    metrodivisioncode: "",
    csacode: 280,
    cbsatitle: "Hickory-Lenoir-Morganton, NC",
    metropolitandivisiontitle: "",
    csatitle: "Hickory-Lenoir, NC",
    countycountyequivalent: "Burke County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Cabarrus County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 25,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25860,
    metrodivisioncode: "",
    csacode: 280,
    cbsatitle: "Hickory-Lenoir-Morganton, NC",
    metropolitandivisiontitle: "",
    csatitle: "Hickory-Lenoir, NC",
    countycountyequivalent: "Caldwell County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21020,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Elizabeth City, NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Camden County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 29,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33980,
    metrodivisioncode: "",
    csacode: 404,
    cbsatitle: "Morehead City, NC",
    metropolitandivisiontitle: "",
    csatitle: "New Bern-Morehead City, NC",
    countycountyequivalent: "Carteret County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25860,
    metrodivisioncode: "",
    csacode: 280,
    cbsatitle: "Hickory-Lenoir-Morganton, NC",
    metropolitandivisiontitle: "",
    csatitle: "Hickory-Lenoir, NC",
    countycountyequivalent: "Catawba County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20500,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Durham-Chapel Hill, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Chatham County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43140,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Shelby, NC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Cleveland County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35100,
    metrodivisioncode: "",
    csacode: 404,
    cbsatitle: "New Bern, NC",
    metropolitandivisiontitle: "",
    csatitle: "New Bern-Morehead City, NC",
    countycountyequivalent: "Craven County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22180,
    metrodivisioncode: "",
    csacode: 246,
    cbsatitle: "Fayetteville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Fayetteville-Lumberton-Laurinburg, NC",
    countycountyequivalent: "Cumberland County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Currituck County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28620,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Kill Devil Hills, NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Dare County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49180,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Winston-Salem, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Davidson County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49180,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Winston-Salem, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Davie County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20500,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Durham-Chapel Hill, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Durham County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40580,
    metrodivisioncode: "",
    csacode: 468,
    cbsatitle: "Rocky Mount, NC",
    metropolitandivisiontitle: "",
    csatitle: "Rocky Mount-Wilson-Roanoke Rapids, NC",
    countycountyequivalent: "Edgecombe County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49180,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Winston-Salem, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Forsyth County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39580,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Raleigh, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Franklin County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 69,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Gaston County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 71,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Gates County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37080,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Oxford, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Granville County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24660,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Greensboro-High Point, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Guilford County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40260,
    metrodivisioncode: "",
    csacode: 468,
    cbsatitle: "Roanoke Rapids, NC",
    metropolitandivisiontitle: "",
    csatitle: "Rocky Mount-Wilson-Roanoke Rapids, NC",
    countycountyequivalent: "Halifax County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20380,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Dunn, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Harnett County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11700,
    metrodivisioncode: "",
    csacode: 120,
    cbsatitle: "Asheville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Asheville-Brevard, NC",
    countycountyequivalent: "Haywood County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11700,
    metrodivisioncode: "",
    csacode: 120,
    cbsatitle: "Asheville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Asheville-Brevard, NC",
    countycountyequivalent: "Henderson County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22180,
    metrodivisioncode: "",
    csacode: 246,
    cbsatitle: "Fayetteville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Fayetteville-Lumberton-Laurinburg, NC",
    countycountyequivalent: "Hoke County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Iredell County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19000,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cullowhee, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39580,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Raleigh, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Johnston County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35100,
    metrodivisioncode: "",
    csacode: 404,
    cbsatitle: "New Bern, NC",
    metropolitandivisiontitle: "",
    csatitle: "New Bern-Morehead City, NC",
    countycountyequivalent: "Jones County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41820,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Sanford, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Lee County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kinston, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lenoir County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Lincoln County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 109,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32000,
    metrodivisioncode: "",
    csacode: 280,
    cbsatitle: "Marion, NC",
    metropolitandivisiontitle: "",
    csatitle: "Hickory-Lenoir, NC",
    countycountyequivalent: "McDowell County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11700,
    metrodivisioncode: "",
    csacode: 120,
    cbsatitle: "Asheville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Asheville-Brevard, NC",
    countycountyequivalent: "Madison County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 115,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Mecklenburg County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38240,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pinehurst-Southern Pines, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Moore County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40580,
    metrodivisioncode: "",
    csacode: 468,
    cbsatitle: "Rocky Mount, NC",
    metropolitandivisiontitle: "",
    csatitle: "Rocky Mount-Wilson-Roanoke Rapids, NC",
    countycountyequivalent: "Nash County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wilmington, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "New Hanover County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 129,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40260,
    metrodivisioncode: "",
    csacode: 468,
    cbsatitle: "Roanoke Rapids, NC",
    metropolitandivisiontitle: "",
    csatitle: "Rocky Mount-Wilson-Roanoke Rapids, NC",
    countycountyequivalent: "Northampton County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 131,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jacksonville, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Onslow County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20500,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Durham-Chapel Hill, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Orange County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 135,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35100,
    metrodivisioncode: "",
    csacode: 404,
    cbsatitle: "New Bern, NC",
    metropolitandivisiontitle: "",
    csatitle: "New Bern-Morehead City, NC",
    countycountyequivalent: "Pamlico County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 137,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21020,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Elizabeth City, NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Pasquotank County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wilmington, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pender County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 141,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 21020,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Elizabeth City, NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Perquimans County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 143,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20500,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Durham-Chapel Hill, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Person County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24780,
    metrodivisioncode: "",
    csacode: 274,
    cbsatitle: "Greenville, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Washington, NC",
    countycountyequivalent: "Pitt County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 147,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24660,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Greensboro-High Point, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Randolph County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 151,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Rockingham, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Richmond County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31300,
    metrodivisioncode: "",
    csacode: 246,
    cbsatitle: "Lumberton, NC",
    metropolitandivisiontitle: "",
    csatitle: "Fayetteville-Lumberton-Laurinburg, NC",
    countycountyequivalent: "Robeson County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 155,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24660,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Greensboro-High Point, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Rockingham County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 157,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Rowan County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 159,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Forest City, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Rutherford County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29900,
    metrodivisioncode: "",
    csacode: 246,
    cbsatitle: "Laurinburg, NC",
    metropolitandivisiontitle: "",
    csatitle: "Fayetteville-Lumberton-Laurinburg, NC",
    countycountyequivalent: "Scotland County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 165,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10620,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Albemarle, NC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Stanly County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 167,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49180,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Winston-Salem, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Stokes County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34340,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Mount Airy, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Surry County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 171,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14820,
    metrodivisioncode: "",
    csacode: 120,
    cbsatitle: "Brevard, NC",
    metropolitandivisiontitle: "",
    csatitle: "Asheville-Brevard, NC",
    countycountyequivalent: "Transylvania County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 175,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28620,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Kill Devil Hills, NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Tyrrell County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 177,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Union County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25780,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Henderson, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Vance County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 181,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39580,
    metrodivisioncode: "",
    csacode: 450,
    cbsatitle: "Raleigh, NC",
    metropolitandivisiontitle: "",
    csatitle: "Raleigh-Durham-Chapel Hill, NC",
    countycountyequivalent: "Wake County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 183,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Boone, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Watauga County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 189,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Goldsboro, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wayne County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 191,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "North Wilkesboro, NC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wilkes County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 193,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48980,
    metrodivisioncode: "",
    csacode: 468,
    cbsatitle: "Wilson, NC",
    metropolitandivisiontitle: "",
    csatitle: "Rocky Mount-Wilson-Roanoke Rapids, NC",
    countycountyequivalent: "Wilson County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 195,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49180,
    metrodivisioncode: "",
    csacode: 268,
    cbsatitle: "Winston-Salem, NC",
    metropolitandivisiontitle: "",
    csatitle: "Greensboro--Winston-Salem--High Point, NC",
    countycountyequivalent: "Yadkin County",
    statename: "North Carolina",
    fipsstatecode: 37,
    fipscountycode: 197,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bismarck, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Burleigh County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22020,
    metrodivisioncode: "",
    csacode: 244,
    cbsatitle: "Fargo, ND-MN",
    metropolitandivisiontitle: "",
    csatitle: "Fargo-Wahpeton, ND-MN",
    countycountyequivalent: "Cass County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Grand Forks, ND-MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grand Forks County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Minot, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McHenry County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 49,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bismarck, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Morton County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bismarck, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Oliver County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 65,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Minot, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Renville County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 75,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47420,
    metrodivisioncode: "",
    csacode: 244,
    cbsatitle: "Wahpeton, ND-MN",
    metropolitandivisiontitle: "",
    csatitle: "Fargo-Wahpeton, ND-MN",
    countycountyequivalent: "Richland County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bismarck, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sioux County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 85,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dickinson, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Stark County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jamestown, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Stutsman County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Minot, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ward County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Williston, ND",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Williams County",
    statename: "North Dakota",
    fipsstatecode: 38,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30620,
    metrodivisioncode: "",
    csacode: 338,
    cbsatitle: "Lima, OH",
    metropolitandivisiontitle: "",
    csatitle: "Lima-Van Wert-Celina, OH",
    countycountyequivalent: "Allen County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11740,
    metrodivisioncode: "",
    csacode: 360,
    cbsatitle: "Ashland, OH",
    metropolitandivisiontitle: "",
    csatitle: "Mansfield-Ashland-Bucyrus, OH",
    countycountyequivalent: "Ashland County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11780,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Ashtabula, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Ashtabula County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Athens, OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Athens County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47540,
    metrodivisioncode: "",
    csacode: 338,
    cbsatitle: "Wapakoneta, OH",
    metropolitandivisiontitle: "",
    csatitle: "Lima-Van Wert-Celina, OH",
    countycountyequivalent: "Auglaize County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wheeling, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Belmont County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Brown County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Butler County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15940,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Canton-Massillon, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Carroll County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 19,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46500,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Urbana, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Champaign County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44220,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Springfield, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Clark County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Clermont County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48940,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Wilmington, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Clinton County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41400,
    metrodivisioncode: "",
    csacode: 566,
    cbsatitle: "Salem, OH",
    metropolitandivisiontitle: "",
    csatitle: "Youngstown-Warren, OH-PA",
    countycountyequivalent: "Columbiana County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Coshocton, OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coshocton County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15340,
    metrodivisioncode: "",
    csacode: 360,
    cbsatitle: "Bucyrus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Mansfield-Ashland-Bucyrus, OH",
    countycountyequivalent: "Crawford County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17460,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Cleveland-Elyria, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Cuyahoga County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24820,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Greenville, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Darke County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Defiance, OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Defiance County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Delaware County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41780,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Sandusky, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Erie County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Fairfield County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47920,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Washington Court House, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Fayette County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Franklin County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45780,
    metrodivisioncode: "",
    csacode: 534,
    cbsatitle: "Toledo, OH",
    metropolitandivisiontitle: "",
    csatitle: "Toledo-Port Clinton, OH",
    countycountyequivalent: "Fulton County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Point Pleasant, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gallia County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17460,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Cleveland-Elyria, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Geauga County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19380,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Dayton, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Greene County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15740,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Cambridge, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Guernsey County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Hamilton County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22300,
    metrodivisioncode: "",
    csacode: 248,
    cbsatitle: "Findlay, OH",
    metropolitandivisiontitle: "",
    csatitle: "Findlay-Tiffin, OH",
    countycountyequivalent: "Hancock County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Hocking County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 73,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35940,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Norwalk, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Huron County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27160,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48260,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Weirton-Steubenville, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Jefferson County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34540,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Mount Vernon, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Knox County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17460,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Cleveland-Elyria, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Lake County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Lawrence County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Licking County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 89,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13340,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Bellefontaine, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Logan County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17460,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Cleveland-Elyria, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Lorain County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45780,
    metrodivisioncode: "",
    csacode: 534,
    cbsatitle: "Toledo, OH",
    metropolitandivisiontitle: "",
    csatitle: "Toledo-Port Clinton, OH",
    countycountyequivalent: "Lucas County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Madison County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 97,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49660,
    metrodivisioncode: "",
    csacode: 566,
    cbsatitle: "Youngstown-Warren-Boardman, OH-PA",
    metropolitandivisiontitle: "",
    csatitle: "Youngstown-Warren, OH-PA",
    countycountyequivalent: "Mahoning County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32020,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Marion, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Marion County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17460,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Cleveland-Elyria, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Medina County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16380,
    metrodivisioncode: "",
    csacode: 338,
    cbsatitle: "Celina, OH",
    metropolitandivisiontitle: "",
    csatitle: "Lima-Van Wert-Celina, OH",
    countycountyequivalent: "Mercer County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19380,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Dayton, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Miami County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19380,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Dayton, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Montgomery County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Morrow County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 117,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49780,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Zanesville, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Muskingum County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38840,
    metrodivisioncode: "",
    csacode: 534,
    cbsatitle: "Port Clinton, OH",
    metropolitandivisiontitle: "",
    csatitle: "Toledo-Port Clinton, OH",
    countycountyequivalent: "Ottawa County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Perry County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 127,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Pickaway County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 129,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 10420,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Akron, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Portage County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31900,
    metrodivisioncode: "",
    csacode: 360,
    cbsatitle: "Mansfield, OH",
    metropolitandivisiontitle: "",
    csatitle: "Mansfield-Ashland-Bucyrus, OH",
    countycountyequivalent: "Richland County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17060,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Chillicothe, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Ross County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fremont, OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sandusky County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39020,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Portsmouth, OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Scioto County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45660,
    metrodivisioncode: "",
    csacode: 248,
    cbsatitle: "Tiffin, OH",
    metropolitandivisiontitle: "",
    csatitle: "Findlay-Tiffin, OH",
    countycountyequivalent: "Seneca County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 147,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43380,
    metrodivisioncode: "",
    csacode: 212,
    cbsatitle: "Sidney, OH",
    metropolitandivisiontitle: "",
    csatitle: "Dayton-Springfield-Sidney, OH",
    countycountyequivalent: "Shelby County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 149,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15940,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Canton-Massillon, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Stark County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 151,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10420,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "Akron, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Summit County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49660,
    metrodivisioncode: "",
    csacode: 566,
    cbsatitle: "Youngstown-Warren-Boardman, OH-PA",
    metropolitandivisiontitle: "",
    csatitle: "Youngstown-Warren, OH-PA",
    countycountyequivalent: "Trumbull County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 155,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35420,
    metrodivisioncode: "",
    csacode: 184,
    cbsatitle: "New Philadelphia-Dover, OH",
    metropolitandivisiontitle: "",
    csatitle: "Cleveland-Akron-Canton, OH",
    countycountyequivalent: "Tuscarawas County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 157,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18140,
    metrodivisioncode: "",
    csacode: 198,
    cbsatitle: "Columbus, OH",
    metropolitandivisiontitle: "",
    csatitle: "Columbus-Marion-Zanesville, OH",
    countycountyequivalent: "Union County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 159,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46780,
    metrodivisioncode: "",
    csacode: 338,
    cbsatitle: "Van Wert, OH",
    metropolitandivisiontitle: "",
    csatitle: "Lima-Van Wert-Celina, OH",
    countycountyequivalent: "Van Wert County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17140,
    metrodivisioncode: "",
    csacode: 178,
    cbsatitle: "Cincinnati, OH-KY-IN",
    metropolitandivisiontitle: "",
    csatitle: "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    countycountyequivalent: "Warren County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 165,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31930,
    metrodivisioncode: "",
    csacode: 425,
    cbsatitle: "Marietta, OH",
    metropolitandivisiontitle: "",
    csatitle: "Parkersburg-Marietta-Vienna, WV-OH",
    countycountyequivalent: "Washington County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 167,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wooster, OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wayne County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 169,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45780,
    metrodivisioncode: "",
    csacode: 534,
    cbsatitle: "Toledo, OH",
    metropolitandivisiontitle: "",
    csatitle: "Toledo-Port Clinton, OH",
    countycountyequivalent: "Wood County",
    statename: "Ohio",
    fipsstatecode: 39,
    fipscountycode: 173,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21120,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Elk City, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Beckham County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20460,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Durant, OK",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Bryan County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Canadian County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ardmore, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Carter County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tahlequah, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Cherokee County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Cleveland County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lawton, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Comanche County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lawton, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cotton County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 33,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Creek County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Weatherford, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Custer County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Enid, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Garfield County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Grady County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Altus, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ponca City, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kay County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 22900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Smith, AR-OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Le Flore County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Lincoln County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Logan County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "McClain County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34780,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Muskogee, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Muskogee County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36420,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Oklahoma City, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Oklahoma County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Okmulgee County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Osage County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33060,
    metrodivisioncode: "",
    csacode: 309,
    cbsatitle: "Miami, OK",
    metropolitandivisiontitle: "",
    csatitle: "Joplin-Miami, MO-OK",
    countycountyequivalent: "Ottawa County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Pawnee County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 117,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Stillwater, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Payne County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "McAlester, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pittsburg County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ada, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pontotoc County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43060,
    metrodivisioncode: "",
    csacode: 416,
    cbsatitle: "Shawnee, OK",
    metropolitandivisiontitle: "",
    csatitle: "Oklahoma City-Shawnee, OK",
    countycountyequivalent: "Pottawatomie County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Rogers County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 131,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fort Smith, AR-OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sequoyah County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 135,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Duncan, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Stephens County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 137,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Guymon, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Texas County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Tulsa County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46140,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Tulsa, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Wagoner County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12780,
    metrodivisioncode: "",
    csacode: 538,
    cbsatitle: "Bartlesville, OK",
    metropolitandivisiontitle: "",
    csatitle: "Tulsa-Muskogee-Bartlesville, OK",
    countycountyequivalent: "Washington County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 147,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Woodward, OK",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Woodward County",
    statename: "Oklahoma",
    fipsstatecode: 40,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18700,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Corvallis, OR",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Benton County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Clackamas County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Astoria, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clatsop County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Columbia County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Coos Bay, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coos County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39260,
    metrodivisioncode: "",
    csacode: 140,
    cbsatitle: "Prineville, OR",
    metropolitandivisiontitle: "",
    csatitle: "Bend-Redmond-Prineville, OR",
    countycountyequivalent: "Crook County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brookings, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Curry County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13460,
    metrodivisioncode: "",
    csacode: 140,
    cbsatitle: "Bend-Redmond, OR",
    metropolitandivisiontitle: "",
    csatitle: "Bend-Redmond-Prineville, OR",
    countycountyequivalent: "Deschutes County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roseburg, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Douglas County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hood River, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hood River County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32780,
    metrodivisioncode: "",
    csacode: 366,
    cbsatitle: "Medford, OR",
    metropolitandivisiontitle: "",
    csatitle: "Medford-Grants Pass, OR",
    countycountyequivalent: "Jackson County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24420,
    metrodivisioncode: "",
    csacode: 366,
    cbsatitle: "Grants Pass, OR",
    metropolitandivisiontitle: "",
    csatitle: "Medford-Grants Pass, OR",
    countycountyequivalent: "Josephine County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Klamath Falls, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Klamath County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Eugene, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lane County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35440,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Newport, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lincoln County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10540,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Albany, OR",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Linn County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36620,
    metrodivisioncode: "",
    csacode: 147,
    cbsatitle: "Ontario, OR-ID",
    metropolitandivisiontitle: "",
    csatitle: "Boise City-Mountain Home-Ontario, ID-OR",
    countycountyequivalent: "Malheur County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41420,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Salem, OR",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Marion County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25840,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hermiston-Pendleton, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Morrow County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 49,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Multnomah County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41420,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Salem, OR",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Polk County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25840,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hermiston-Pendleton, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Umatilla County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "La Grande, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Union County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45520,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "The Dalles, OR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wasco County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Washington County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Yamhill County",
    statename: "Oregon",
    fipsstatecode: 41,
    fipscountycode: 71,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 23900,
    metrodivisioncode: "",
    csacode: 276,
    cbsatitle: "Gettysburg, PA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisburg-York-Lebanon, PA",
    countycountyequivalent: "Adams County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Allegheny County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Armstrong County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 5,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Beaver County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39740,
    metrodivisioncode: "",
    csacode: 428,
    cbsatitle: "Reading, PA",
    metropolitandivisiontitle: "",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Berks County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Altoona, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Blair County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sayre, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bradford County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 33874,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle:
      "Montgomery County-Bucks County-Chester County, PA",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Bucks County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Butler County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27780,
    metrodivisioncode: "",
    csacode: 306,
    cbsatitle: "Johnstown, PA",
    metropolitandivisiontitle: "",
    csatitle: "Johnstown-Somerset, PA",
    countycountyequivalent: "Cambria County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10900,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Allentown-Bethlehem-Easton, PA-NJ",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Carbon County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44300,
    metrodivisioncode: "",
    csacode: 524,
    cbsatitle: "State College, PA",
    metropolitandivisiontitle: "",
    csatitle: "State College-DuBois, PA",
    countycountyequivalent: "Centre County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 33874,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle:
      "Montgomery County-Bucks County-Chester County, PA",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Chester County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20180,
    metrodivisioncode: "",
    csacode: 524,
    cbsatitle: "DuBois, PA",
    metropolitandivisiontitle: "",
    csatitle: "State College-DuBois, PA",
    countycountyequivalent: "Clearfield County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30820,
    metrodivisioncode: "",
    csacode: 558,
    cbsatitle: "Lock Haven, PA",
    metropolitandivisiontitle: "",
    csatitle: "Williamsport-Lock Haven, PA",
    countycountyequivalent: "Clinton County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14100,
    metrodivisioncode: "",
    csacode: 146,
    cbsatitle: "Bloomsburg-Berwick, PA",
    metropolitandivisiontitle: "",
    csatitle: "Bloomsburg-Berwick-Sunbury, PA",
    countycountyequivalent: "Columbia County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32740,
    metrodivisioncode: "",
    csacode: 240,
    cbsatitle: "Meadville, PA",
    metropolitandivisiontitle: "",
    csatitle: "Erie-Meadville, PA",
    countycountyequivalent: "Crawford County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25420,
    metrodivisioncode: "",
    csacode: 276,
    cbsatitle: "Harrisburg-Carlisle, PA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisburg-York-Lebanon, PA",
    countycountyequivalent: "Cumberland County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25420,
    metrodivisioncode: "",
    csacode: 276,
    cbsatitle: "Harrisburg-Carlisle, PA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisburg-York-Lebanon, PA",
    countycountyequivalent: "Dauphin County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 37964,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Philadelphia, PA",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Delaware County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21500,
    metrodivisioncode: "",
    csacode: 240,
    cbsatitle: "Erie, PA",
    metropolitandivisiontitle: "",
    csatitle: "Erie-Meadville, PA",
    countycountyequivalent: "Erie County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Fayette County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16540,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Chambersburg-Waynesboro, PA",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Franklin County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Huntingdon, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Huntingdon County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26860,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Indiana, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Indiana County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Scranton--Wilkes-Barre--Hazleton, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lackawanna County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lancaster, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lancaster County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35260,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "New Castle, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Lawrence County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30140,
    metrodivisioncode: "",
    csacode: 276,
    cbsatitle: "Lebanon, PA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisburg-York-Lebanon, PA",
    countycountyequivalent: "Lebanon County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10900,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Allentown-Bethlehem-Easton, PA-NJ",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Lehigh County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Scranton--Wilkes-Barre--Hazleton, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Luzerne County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48700,
    metrodivisioncode: "",
    csacode: 558,
    cbsatitle: "Williamsport, PA",
    metropolitandivisiontitle: "",
    csatitle: "Williamsport-Lock Haven, PA",
    countycountyequivalent: "Lycoming County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bradford, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McKean County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49660,
    metrodivisioncode: "",
    csacode: 566,
    cbsatitle: "Youngstown-Warren-Boardman, OH-PA",
    metropolitandivisiontitle: "",
    csatitle: "Youngstown-Warren, OH-PA",
    countycountyequivalent: "Mercer County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lewistown, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mifflin County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20700,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "East Stroudsburg, PA",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Monroe County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 33874,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle:
      "Montgomery County-Bucks County-Chester County, PA",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Montgomery County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14100,
    metrodivisioncode: "",
    csacode: 146,
    cbsatitle: "Bloomsburg-Berwick, PA",
    metropolitandivisiontitle: "",
    csatitle: "Bloomsburg-Berwick-Sunbury, PA",
    countycountyequivalent: "Montour County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10900,
    metrodivisioncode: "",
    csacode: 408,
    cbsatitle: "Allentown-Bethlehem-Easton, PA-NJ",
    metropolitandivisiontitle: "",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Northampton County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 95,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44980,
    metrodivisioncode: "",
    csacode: 146,
    cbsatitle: "Sunbury, PA",
    metropolitandivisiontitle: "",
    csatitle: "Bloomsburg-Berwick-Sunbury, PA",
    countycountyequivalent: "Northumberland County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25420,
    metrodivisioncode: "",
    csacode: 276,
    cbsatitle: "Harrisburg-Carlisle, PA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisburg-York-Lebanon, PA",
    countycountyequivalent: "Perry County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 99,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37980,
    metrodivisioncode: 37964,
    csacode: 428,
    cbsatitle: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    metropolitandivisiontitle: "Philadelphia, PA",
    csatitle: "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    countycountyequivalent: "Philadelphia County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35620,
    metrodivisioncode: 35084,
    csacode: 408,
    cbsatitle: "New York-Newark-Jersey City, NY-NJ-PA",
    metropolitandivisiontitle: "Newark, NJ-PA",
    csatitle: "New York-Newark, NY-NJ-CT-PA",
    countycountyequivalent: "Pike County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pottsville, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Schuylkill County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42780,
    metrodivisioncode: "",
    csacode: 146,
    cbsatitle: "Selinsgrove, PA",
    metropolitandivisiontitle: "",
    csatitle: "Bloomsburg-Berwick-Sunbury, PA",
    countycountyequivalent: "Snyder County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43740,
    metrodivisioncode: "",
    csacode: 306,
    cbsatitle: "Somerset, PA",
    metropolitandivisiontitle: "",
    csatitle: "Johnstown-Somerset, PA",
    countycountyequivalent: "Somerset County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30260,
    metrodivisioncode: "",
    csacode: 146,
    cbsatitle: "Lewisburg, PA",
    metropolitandivisiontitle: "",
    csatitle: "Bloomsburg-Berwick-Sunbury, PA",
    countycountyequivalent: "Union County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Oil City, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Venango County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Warren, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Warren County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Washington County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38300,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Pittsburgh, PA",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Westmoreland County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 129,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Scranton--Wilkes-Barre--Hazleton, PA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wyoming County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 131,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49620,
    metrodivisioncode: "",
    csacode: 276,
    cbsatitle: "York-Hanover, PA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisburg-York-Lebanon, PA",
    countycountyequivalent: "York County",
    statename: "Pennsylvania",
    fipsstatecode: 42,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39300,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Providence-Warwick, RI-MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Bristol County",
    statename: "Rhode Island",
    fipsstatecode: 44,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39300,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Providence-Warwick, RI-MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Kent County",
    statename: "Rhode Island",
    fipsstatecode: 44,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39300,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Providence-Warwick, RI-MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Newport County",
    statename: "Rhode Island",
    fipsstatecode: 44,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39300,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Providence-Warwick, RI-MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Providence County",
    statename: "Rhode Island",
    fipsstatecode: 44,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39300,
    metrodivisioncode: "",
    csacode: 148,
    cbsatitle: "Providence-Warwick, RI-MA",
    metropolitandivisiontitle: "",
    csatitle: "Boston-Worcester-Providence, MA-RI-NH-CT",
    countycountyequivalent: "Washington County",
    statename: "Rhode Island",
    fipsstatecode: 44,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24940,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Greenwood, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Abbeville County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 1,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Aiken County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24860,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Greenville-Anderson-Mauldin, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Anderson County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hilton Head Island-Bluffton-Beaufort, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Beaufort County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charleston-North Charleston, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Berkeley County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17900,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Columbia, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Calhoun County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charleston-North Charleston, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Charleston County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23500,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Gaffney, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Cherokee County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Chester County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Florence, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Darlington County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 31,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charleston-North Charleston, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dorchester County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Augusta-Richmond County, GA-SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Edgefield County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17900,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Columbia, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Fairfield County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 39,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Florence, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Florence County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23860,
    metrodivisioncode: "",
    csacode: 396,
    cbsatitle: "Georgetown, SC",
    metropolitandivisiontitle: "",
    csatitle: "Myrtle Beach-Conway, SC-NC",
    countycountyequivalent: "Georgetown County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24860,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Greenville-Anderson-Mauldin, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Greenville County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24940,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Greenwood, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Greenwood County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34820,
    metrodivisioncode: "",
    csacode: 396,
    cbsatitle: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC",
    metropolitandivisiontitle: "",
    csatitle: "Myrtle Beach-Conway, SC-NC",
    countycountyequivalent: "Horry County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hilton Head Island-Bluffton-Beaufort, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jasper County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17900,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Columbia, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Kershaw County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 55,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "Lancaster County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 57,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24860,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Greenville-Anderson-Mauldin, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Laurens County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17900,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Columbia, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Lexington County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bennettsville, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Marlboro County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 35140,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Newberry, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Newberry County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42860,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Seneca, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Oconee County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36700,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Orangeburg, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Orangeburg County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24860,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Greenville-Anderson-Mauldin, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Pickens County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17900,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Columbia, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Richland County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17900,
    metrodivisioncode: "",
    csacode: 192,
    cbsatitle: "Columbia, SC",
    metropolitandivisiontitle: "",
    csatitle: "Columbia-Orangeburg-Newberry, SC",
    countycountyequivalent: "Saluda County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43900,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Spartanburg, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Spartanburg County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sumter, SC",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sumter County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43900,
    metrodivisioncode: "",
    csacode: 273,
    cbsatitle: "Spartanburg, SC",
    metropolitandivisiontitle: "",
    csatitle: "Greenville-Spartanburg-Anderson, SC",
    countycountyequivalent: "Union County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16740,
    metrodivisioncode: "",
    csacode: 172,
    cbsatitle: "Charlotte-Concord-Gastonia, NC-SC",
    metropolitandivisiontitle: "",
    csatitle: "Charlotte-Concord, NC-SC",
    countycountyequivalent: "York County",
    statename: "South Carolina",
    fipsstatecode: 45,
    fipscountycode: 91,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Huron, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Beadle County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brookings, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brookings County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aberdeen, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brown County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46820,
    metrodivisioncode: "",
    csacode: 512,
    cbsatitle: "Vermillion, SD",
    metropolitandivisiontitle: "",
    csatitle: "Sioux City-Vermillion, IA-SD-NE",
    countycountyequivalent: "Clay County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Watertown, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Codington County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39660,
    metrodivisioncode: "",
    csacode: 452,
    cbsatitle: "Rapid City, SD",
    metropolitandivisiontitle: "",
    csatitle: "Rapid City-Spearfish, SD",
    countycountyequivalent: "Custer County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 33,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mitchell, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Davison County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aberdeen, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Edmunds County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mitchell, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hanson County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pierre, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hughes County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43940,
    metrodivisioncode: "",
    csacode: 452,
    cbsatitle: "Spearfish, SD",
    metropolitandivisiontitle: "",
    csatitle: "Rapid City-Spearfish, SD",
    countycountyequivalent: "Lawrence County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sioux Falls, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lincoln County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sioux Falls, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McCook County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 39660,
    metrodivisioncode: "",
    csacode: 452,
    cbsatitle: "Rapid City, SD",
    metropolitandivisiontitle: "",
    csatitle: "Rapid City-Spearfish, SD",
    countycountyequivalent: "Meade County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sioux Falls, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Minnehaha County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39660,
    metrodivisioncode: "",
    csacode: 452,
    cbsatitle: "Rapid City, SD",
    metropolitandivisiontitle: "",
    csatitle: "Rapid City-Spearfish, SD",
    countycountyequivalent: "Pennington County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 103,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pierre, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Stanley County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pierre, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sully County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 119,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sioux Falls, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Turner County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 125,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 43580,
    metrodivisioncode: "",
    csacode: 512,
    cbsatitle: "Sioux City, IA-NE-SD",
    metropolitandivisiontitle: "",
    csatitle: "Sioux City-Vermillion, IA-SD-NE",
    countycountyequivalent: "Union County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Yankton, SD",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Yankton County",
    statename: "South Dakota",
    fipsstatecode: 46,
    fipscountycode: 135,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Anderson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43180,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Shelbyville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Bedford County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Blount County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17420,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Cleveland, TN",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Bradley County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Campbell County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Cannon County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27740,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Johnson City, TN",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Carter County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Cheatham County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chester County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 35460,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Newport, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Cocke County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tullahoma-Manchester, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coffee County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Crockett County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 33,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Crossville, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cumberland County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Davidson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Dickson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dyersburg, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dyer County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Fayette County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 47,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tullahoma-Manchester, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Franklin County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Grainger County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 57,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Greeneville, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Greene County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34100,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Morristown, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Hamblen County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16860,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Chattanooga, TN-GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Hamilton County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28700,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Kingsport-Bristol-Bristol, TN-VA",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Hawkins County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Paris, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Henry County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Hickman County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 81,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cookeville, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jackson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 87,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34100,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Morristown, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Jefferson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Knox County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 93,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Lawrenceburg, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Lawrence County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Loudon County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11940,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Athens, TN",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "McMinn County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Macon County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Madison County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16860,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Chattanooga, TN-GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Marion County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 115,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30280,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Lewisburg, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Marshall County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Maury County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 119,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksville, TN-KY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Montgomery County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Tullahoma-Manchester, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Moore County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 127,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Morgan County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 129,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46460,
    metrodivisioncode: "",
    csacode: 362,
    cbsatitle: "Union City, TN-KY",
    metropolitandivisiontitle: "",
    csatitle: "Martin-Union City, TN-KY",
    countycountyequivalent: "Obion County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 131,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cookeville, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Overton County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 133,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17420,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Cleveland, TN",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Polk County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 139,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cookeville, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Putnam County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19420,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Dayton, TN",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Rhea County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Roane County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Robertson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 147,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Rutherford County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16860,
    metrodivisioncode: "",
    csacode: 174,
    cbsatitle: "Chattanooga, TN-GA",
    metropolitandivisiontitle: "",
    csatitle: "Chattanooga-Cleveland-Dalton, TN-GA-AL",
    countycountyequivalent: "Sequatchie County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 153,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Sevierville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Sevier County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 155,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Shelby County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 157,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Smith County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 159,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28700,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Kingsport-Bristol-Bristol, TN-VA",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Sullivan County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 163,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Sumner County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 165,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32820,
    metrodivisioncode: "",
    csacode: 368,
    cbsatitle: "Memphis, TN-MS-AR",
    metropolitandivisiontitle: "",
    csatitle: "Memphis-Forrest City, TN-MS-AR",
    countycountyequivalent: "Tipton County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 167,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Trousdale County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 169,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 27740,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Johnson City, TN",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Unicoi County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 171,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28940,
    metrodivisioncode: "",
    csacode: 314,
    cbsatitle: "Knoxville, TN",
    metropolitandivisiontitle: "",
    csatitle: "Knoxville-Morristown-Sevierville, TN",
    countycountyequivalent: "Union County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 173,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "McMinnville, TN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Warren County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 177,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27740,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Johnson City, TN",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Washington County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32280,
    metrodivisioncode: "",
    csacode: 362,
    cbsatitle: "Martin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Martin-Union City, TN-KY",
    countycountyequivalent: "Weakley County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 183,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Williamson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 187,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34980,
    metrodivisioncode: "",
    csacode: 400,
    cbsatitle: "Nashville-Davidson--Murfreesboro--Franklin, TN",
    metropolitandivisiontitle: "",
    csatitle: "Nashville-Davidson--Murfreesboro, TN",
    countycountyequivalent: "Wilson County",
    statename: "Tennessee",
    fipsstatecode: 47,
    fipscountycode: 189,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Palestine, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Anderson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Andrews, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Andrews County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lufkin, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Angelina County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18580,
    metrodivisioncode: "",
    csacode: 204,
    cbsatitle: "Corpus Christi, TX",
    metropolitandivisiontitle: "",
    csatitle: "Corpus Christi-Kingsville-Alice, TX",
    countycountyequivalent: "Aransas County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wichita Falls, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Archer County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11100,
    metrodivisioncode: "",
    csacode: 108,
    cbsatitle: "Amarillo, TX",
    metropolitandivisiontitle: "",
    csatitle: "Amarillo-Borger, TX",
    countycountyequivalent: "Armstrong County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 11,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Atascosa County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Austin County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bandera County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 19,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Austin-Round Rock, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bastrop County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beeville, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bee County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Killeen-Temple, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bell County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bexar County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 45500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Texarkana, TX-AR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bowie County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Brazoria County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "College Station-Bryan, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brazos County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Brownwood, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Brown County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "College Station-Bryan, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Burleson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Austin-Round Rock, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Caldwell County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 55,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38920,
    metrodivisioncode: "",
    csacode: 544,
    cbsatitle: "Port Lavaca, TX",
    metropolitandivisiontitle: "",
    csatitle: "Victoria-Port Lavaca, TX",
    countycountyequivalent: "Calhoun County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Abilene, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Callahan County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15180,
    metrodivisioncode: "",
    csacode: 154,
    cbsatitle: "Brownsville-Harlingen, TX",
    metropolitandivisiontitle: "",
    csatitle: "Brownsville-Harlingen-Raymondville, TX",
    countycountyequivalent: "Cameron County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11100,
    metrodivisioncode: "",
    csacode: 108,
    cbsatitle: "Amarillo, TX",
    metropolitandivisiontitle: "",
    csatitle: "Amarillo-Borger, TX",
    countycountyequivalent: "Carson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 65,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Chambers County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27380,
    metrodivisioncode: "",
    csacode: 540,
    cbsatitle: "Jacksonville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Tyler-Jacksonville, TX",
    countycountyequivalent: "Cherokee County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wichita Falls, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clay County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 77,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Collin County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Comal County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23620,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Gainesville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Cooke County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Killeen-Temple, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Coryell County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31180,
    metrodivisioncode: "",
    csacode: 352,
    cbsatitle: "Lubbock, TX",
    metropolitandivisiontitle: "",
    csatitle: "Lubbock-Levelland, TX",
    countycountyequivalent: "Crosby County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 107,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Dallas County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lamesa, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dawson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Hereford, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Deaf Smith County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Denton County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 121,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36220,
    metrodivisioncode: "",
    csacode: 372,
    cbsatitle: "Odessa, TX",
    metropolitandivisiontitle: "",
    csatitle: "Midland-Odessa, TX",
    countycountyequivalent: "Ector County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 135,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Ellis County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21340,
    metrodivisioncode: "",
    csacode: 238,
    cbsatitle: "El Paso, TX",
    metropolitandivisiontitle: "",
    csatitle: "El Paso-Las Cruces, TX-NM",
    countycountyequivalent: "El Paso County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Stephenville, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Erath County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waco, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Falls County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Fort Bend County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 157,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Galveston County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 167,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23240,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fredericksburg, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gillespie County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 171,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Big Spring, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Glasscock County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 173,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47020,
    metrodivisioncode: "",
    csacode: 544,
    cbsatitle: "Victoria, TX",
    metropolitandivisiontitle: "",
    csatitle: "Victoria-Port Lavaca, TX",
    countycountyequivalent: "Goliad County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 175,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pampa, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Gray County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43300,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Sherman-Denison, TX",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Grayson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 181,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30980,
    metrodivisioncode: "",
    csacode: 346,
    cbsatitle: "Longview, TX",
    metropolitandivisiontitle: "",
    csatitle: "Longview-Marshall, TX",
    countycountyequivalent: "Gregg County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 183,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Guadalupe County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 187,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Plainview, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hale County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 189,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beaumont-Port Arthur, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hardin County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 199,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Harris County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 201,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32220,
    metrodivisioncode: "",
    csacode: 346,
    cbsatitle: "Marshall, TX",
    metropolitandivisiontitle: "",
    csatitle: "Longview-Marshall, TX",
    countycountyequivalent: "Harrison County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 203,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Austin-Round Rock, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hays County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 209,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11980,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Athens, TX",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Henderson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 213,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32580,
    metrodivisioncode: "",
    csacode: 365,
    cbsatitle: "McAllen-Edinburg-Mission, TX",
    metropolitandivisiontitle: "",
    csatitle: "McAllen-Edinburg, TX",
    countycountyequivalent: "Hidalgo County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 215,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30220,
    metrodivisioncode: "",
    csacode: 352,
    cbsatitle: "Levelland, TX",
    metropolitandivisiontitle: "",
    csatitle: "Lubbock-Levelland, TX",
    countycountyequivalent: "Hockley County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 219,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 23104,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Fort Worth-Arlington, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Hood County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 221,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44860,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Sulphur Springs, TX",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Hopkins County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 223,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Big Spring, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Howard County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 227,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21340,
    metrodivisioncode: "",
    csacode: 238,
    cbsatitle: "El Paso, TX",
    metropolitandivisiontitle: "",
    csatitle: "El Paso-Las Cruces, TX-NM",
    countycountyequivalent: "Hudspeth County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 229,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Hunt County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 231,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14420,
    metrodivisioncode: "",
    csacode: 108,
    cbsatitle: "Borger, TX",
    metropolitandivisiontitle: "",
    csatitle: "Amarillo-Borger, TX",
    countycountyequivalent: "Hutchinson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 233,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Angelo, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Irion County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 235,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beaumont-Port Arthur, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jefferson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 245,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10860,
    metrodivisioncode: "",
    csacode: 204,
    cbsatitle: "Alice, TX",
    metropolitandivisiontitle: "",
    csatitle: "Corpus Christi-Kingsville-Alice, TX",
    countycountyequivalent: "Jim Wells County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 249,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 23104,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Fort Worth-Arlington, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Johnson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 251,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Abilene, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jones County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 253,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Kaufman County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 257,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kendall County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 259,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28780,
    metrodivisioncode: "",
    csacode: 204,
    cbsatitle: "Kingsville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Corpus Christi-Kingsville-Alice, TX",
    countycountyequivalent: "Kenedy County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 261,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28500,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kerrville, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kerr County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 265,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28780,
    metrodivisioncode: "",
    csacode: 204,
    cbsatitle: "Kingsville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Corpus Christi-Kingsville-Alice, TX",
    countycountyequivalent: "Kleberg County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 273,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Paris, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lamar County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 277,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Killeen-Temple, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lampasas County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 281,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Liberty County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 291,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31180,
    metrodivisioncode: "",
    csacode: 352,
    cbsatitle: "Lubbock, TX",
    metropolitandivisiontitle: "",
    csatitle: "Lubbock-Levelland, TX",
    countycountyequivalent: "Lubbock County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 303,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31180,
    metrodivisioncode: "",
    csacode: 352,
    cbsatitle: "Lubbock, TX",
    metropolitandivisiontitle: "",
    csatitle: "Lubbock-Levelland, TX",
    countycountyequivalent: "Lynn County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 305,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Waco, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "McLennan County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 309,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33260,
    metrodivisioncode: "",
    csacode: 372,
    cbsatitle: "Midland, TX",
    metropolitandivisiontitle: "",
    csatitle: "Midland-Odessa, TX",
    countycountyequivalent: "Martin County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 317,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13060,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Bay City, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Matagorda County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 321,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Eagle Pass, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Maverick County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 323,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Medina County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 325,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33260,
    metrodivisioncode: "",
    csacode: 372,
    cbsatitle: "Midland, TX",
    metropolitandivisiontitle: "",
    csatitle: "Midland-Odessa, TX",
    countycountyequivalent: "Midland County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 329,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Montgomery County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 339,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 20300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Dumas, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Moore County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 341,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Nacogdoches, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Nacogdoches County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 347,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18620,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Corsicana, TX",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Navarro County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 349,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beaumont-Port Arthur, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Newton County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 351,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 45020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sweetwater, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Nolan County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 353,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 18580,
    metrodivisioncode: "",
    csacode: 204,
    cbsatitle: "Corpus Christi, TX",
    metropolitandivisiontitle: "",
    csatitle: "Corpus Christi-Kingsville-Alice, TX",
    countycountyequivalent: "Nueces County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 355,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11100,
    metrodivisioncode: "",
    csacode: 108,
    cbsatitle: "Amarillo, TX",
    metropolitandivisiontitle: "",
    csatitle: "Amarillo-Borger, TX",
    countycountyequivalent: "Oldham County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 359,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beaumont-Port Arthur, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Orange County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 361,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33420,
    metrodivisioncode: "",
    csacode: 206,
    cbsatitle: "Mineral Wells, TX",
    metropolitandivisiontitle: "",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Palo Pinto County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 363,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 23104,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Fort Worth-Arlington, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Parker County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 367,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11100,
    metrodivisioncode: "",
    csacode: 108,
    cbsatitle: "Amarillo, TX",
    metropolitandivisiontitle: "",
    csatitle: "Amarillo-Borger, TX",
    countycountyequivalent: "Potter County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 375,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11100,
    metrodivisioncode: "",
    csacode: 108,
    cbsatitle: "Amarillo, TX",
    metropolitandivisiontitle: "",
    csatitle: "Amarillo-Borger, TX",
    countycountyequivalent: "Randall County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 381,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Pecos, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Reeves County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 389,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17780,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "College Station-Bryan, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Robertson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 395,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 19124,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Dallas-Plano-Irving, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Rockwall County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 397,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30980,
    metrodivisioncode: "",
    csacode: 346,
    cbsatitle: "Longview, TX",
    metropolitandivisiontitle: "",
    csatitle: "Longview-Marshall, TX",
    countycountyequivalent: "Rusk County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 401,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 18580,
    metrodivisioncode: "",
    csacode: 204,
    cbsatitle: "Corpus Christi, TX",
    metropolitandivisiontitle: "",
    csatitle: "Corpus Christi-Kingsville-Alice, TX",
    countycountyequivalent: "San Patricio County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 409,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Snyder, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Scurry County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 415,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46340,
    metrodivisioncode: "",
    csacode: 540,
    cbsatitle: "Tyler, TX",
    metropolitandivisiontitle: "",
    csatitle: "Tyler-Jacksonville, TX",
    countycountyequivalent: "Smith County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 423,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 23104,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Fort Worth-Arlington, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Somervell County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 425,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40100,
    metrodivisioncode: "",
    csacode: 365,
    cbsatitle: "Rio Grande City, TX",
    metropolitandivisiontitle: "",
    csatitle: "McAllen-Edinburg, TX",
    countycountyequivalent: "Starr County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 427,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 23104,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Fort Worth-Arlington, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Tarrant County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 439,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Abilene, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Taylor County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 441,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Mount Pleasant, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Titus County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 449,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Angelo, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Tom Green County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 451,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Austin-Round Rock, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Travis County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 453,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26660,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Huntsville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Trinity County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 455,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30980,
    metrodivisioncode: "",
    csacode: 346,
    cbsatitle: "Longview, TX",
    metropolitandivisiontitle: "",
    csatitle: "Longview-Marshall, TX",
    countycountyequivalent: "Upshur County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 459,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Uvalde, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Uvalde County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 463,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Del Rio, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Val Verde County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 465,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47020,
    metrodivisioncode: "",
    csacode: 544,
    cbsatitle: "Victoria, TX",
    metropolitandivisiontitle: "",
    csatitle: "Victoria-Port Lavaca, TX",
    countycountyequivalent: "Victoria County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 469,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26660,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Huntsville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Walker County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 471,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26420,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Houston-The Woodlands-Sugar Land, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Waller County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 473,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14780,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "Brenham, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Washington County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 477,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Laredo, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Webb County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 479,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20900,
    metrodivisioncode: "",
    csacode: 288,
    cbsatitle: "El Campo, TX",
    metropolitandivisiontitle: "",
    csatitle: "Houston-The Woodlands, TX",
    countycountyequivalent: "Wharton County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 481,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wichita Falls, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wichita County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 485,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 46900,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Vernon, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wilbarger County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 487,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39700,
    metrodivisioncode: "",
    csacode: 154,
    cbsatitle: "Raymondville, TX",
    metropolitandivisiontitle: "",
    csatitle: "Brownsville-Harlingen-Raymondville, TX",
    countycountyequivalent: "Willacy County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 489,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Austin-Round Rock, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Williamson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 491,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41700,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "San Antonio-New Braunfels, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wilson County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 493,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19100,
    metrodivisioncode: 23104,
    csacode: 206,
    cbsatitle: "Dallas-Fort Worth-Arlington, TX",
    metropolitandivisiontitle: "Fort Worth-Arlington, TX",
    csatitle: "Dallas-Fort Worth, TX-OK",
    countycountyequivalent: "Wise County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 497,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Zapata, TX",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Zapata County",
    statename: "Texas",
    fipsstatecode: 48,
    fipscountycode: 505,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36260,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Ogden-Clearfield, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Box Elder County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Logan, UT-ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Cache County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Price, UT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Carbon County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36260,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Ogden-Clearfield, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Davis County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cedar City, UT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Iron County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39340,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Provo-Orem, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Juab County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 23,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36260,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Ogden-Clearfield, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Morgan County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 29,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41620,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Salt Lake City, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Salt Lake County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44920,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Summit Park, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Summit County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41620,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Salt Lake City, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Tooele County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 46860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Vernal, UT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Uintah County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39340,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Provo-Orem, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Utah County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25720,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Heber, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Wasatch County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "St. George, UT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Washington County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36260,
    metrodivisioncode: "",
    csacode: 482,
    cbsatitle: "Ogden-Clearfield, UT",
    metropolitandivisiontitle: "",
    csatitle: "Salt Lake City-Provo-Orem, UT",
    countycountyequivalent: "Weber County",
    statename: "Utah",
    fipsstatecode: 49,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bennington, VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bennington County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 15540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burlington-South Burlington, VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chittenden County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13620,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Berlin, NH-VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Essex County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 9,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burlington-South Burlington, VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Franklin County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 11,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 15540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Burlington-South Burlington, VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grand Isle County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Claremont-Lebanon, NH-VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Orange County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40860,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Rutland, VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Rutland County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 12740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Barre, VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Washington County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17200,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Claremont-Lebanon, NH-VT",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Windsor County",
    statename: "Vermont",
    fipsstatecode: 50,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charlottesville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Albemarle County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Amelia County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 7,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lynchburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Amherst County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lynchburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Appomattox County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 11,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Arlington County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44420,
    metrodivisioncode: "",
    csacode: 277,
    cbsatitle: "Staunton-Waynesboro, VA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisonburg-Staunton-Waynesboro, VA",
    countycountyequivalent: "Augusta County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lynchburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bedford County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roanoke, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Botetourt County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charlottesville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Buckingham County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 29,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lynchburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Campbell County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Caroline County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 33,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Charles City County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 36,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chesterfield County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Clarke County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roanoke, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Craig County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Culpeper County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 47,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13720,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Big Stone Gap, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dickenson County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Dinwiddie County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Fairfax County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 59,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Fauquier County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Blacksburg-Christiansburg-Radford, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Floyd County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 63,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charlottesville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Fluvanna County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 65,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roanoke, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Franklin County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 67,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49020,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Winchester, VA-WV",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Frederick County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Blacksburg-Christiansburg-Radford, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Giles County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 71,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Gloucester County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Goochland County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 75,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charlottesville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Greene County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 79,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hanover County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Henrico County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Martinsville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Henry County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Isle of Wight County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "James City County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 95,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "King William County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 101,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Loudoun County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Mathews County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 115,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Blacksburg-Christiansburg-Radford, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Montgomery County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charlottesville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Nelson County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 125,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "New Kent County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 127,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 19260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Danville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pittsylvania County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Powhatan County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 145,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Prince George County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 149,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Prince William County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 153,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Blacksburg-Christiansburg-Radford, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Pulaski County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 155,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Rappahannock County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 157,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 40220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roanoke, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Roanoke County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 161,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25500,
    metrodivisioncode: "",
    csacode: 277,
    cbsatitle: "Harrisonburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisonburg-Staunton-Waynesboro, VA",
    countycountyequivalent: "Rockingham County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 165,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28700,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Kingsport-Bristol-Bristol, TN-VA",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Scott County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 169,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Spotsylvania County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 177,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Stafford County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 179,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sussex County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 183,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 14140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bluefield, WV-VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Tazewell County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 185,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Warren County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 187,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 28700,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Kingsport-Bristol-Bristol, TN-VA",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Washington County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 191,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13720,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Big Stone Gap, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Wise County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 195,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "York County",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 199,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Alexandria city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 510,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lynchburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Bedford city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 515,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28700,
    metrodivisioncode: "",
    csacode: 304,
    cbsatitle: "Kingsport-Bristol-Bristol, TN-VA",
    metropolitandivisiontitle: "",
    csatitle: "Johnson City-Kingsport-Bristol, TN-VA",
    countycountyequivalent: "Bristol city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 520,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Charlottesville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Charlottesville city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 540,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Chesapeake city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 550,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Colonial Heights city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 570,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Danville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Danville city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 590,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Fairfax city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 600,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Falls Church city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 610,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Fredericksburg city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 630,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Hampton city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 650,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25500,
    metrodivisioncode: "",
    csacode: 277,
    cbsatitle: "Harrisonburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisonburg-Staunton-Waynesboro, VA",
    countycountyequivalent: "Harrisonburg city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 660,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Hopewell city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 670,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31340,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lynchburg, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lynchburg city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 680,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Manassas city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 683,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Manassas Park city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 685,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Martinsville, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Martinsville city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 690,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Newport News city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 700,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Norfolk city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 710,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13720,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Big Stone Gap, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Norton city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 720,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Petersburg city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 730,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Poquoson city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 735,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Portsmouth city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 740,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13980,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Blacksburg-Christiansburg-Radford, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Radford city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 750,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Richmond, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Richmond city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 760,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roanoke, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Roanoke city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 770,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Roanoke, VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Salem city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 775,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44420,
    metrodivisioncode: "",
    csacode: 277,
    cbsatitle: "Staunton-Waynesboro, VA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisonburg-Staunton-Waynesboro, VA",
    countycountyequivalent: "Staunton city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 790,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Suffolk city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 800,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Virginia Beach city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 810,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44420,
    metrodivisioncode: "",
    csacode: 277,
    cbsatitle: "Staunton-Waynesboro, VA",
    metropolitandivisiontitle: "",
    csatitle: "Harrisonburg-Staunton-Waynesboro, VA",
    countycountyequivalent: "Waynesboro city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 820,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47260,
    metrodivisioncode: "",
    csacode: 545,
    cbsatitle: "Virginia Beach-Norfolk-Newport News, VA-NC",
    metropolitandivisiontitle: "",
    csatitle: "Virginia Beach-Norfolk, VA-NC",
    countycountyequivalent: "Williamsburg city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 830,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 49020,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Winchester, VA-WV",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Winchester city",
    statename: "Virginia",
    fipsstatecode: 51,
    fipscountycode: 840,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36830,
    metrodivisioncode: "",
    csacode: 393,
    cbsatitle: "Othello, WA",
    metropolitandivisiontitle: "",
    csatitle: "Moses Lake-Othello, WA",
    countycountyequivalent: "Adams County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 30300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Lewiston, ID-WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Asotin County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kennewick-Richland, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Benton County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wenatchee, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Chelan County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Port Angeles, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Clallam County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Clark County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Walla Walla, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Columbia County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 13,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31020,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Longview, WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Cowlitz County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48300,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wenatchee, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Douglas County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 28420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Kennewick-Richland, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Franklin County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34180,
    metrodivisioncode: "",
    csacode: 393,
    cbsatitle: "Moses Lake, WA",
    metropolitandivisiontitle: "",
    csatitle: "Moses Lake-Othello, WA",
    countycountyequivalent: "Grant County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aberdeen, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grays Harbor County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36020,
    metrodivisioncode: "",
    csacode: 500,
    cbsatitle: "Oak Harbor, WA",
    metropolitandivisiontitle: "",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Island County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42660,
    metrodivisioncode: 42644,
    csacode: 500,
    cbsatitle: "Seattle-Tacoma-Bellevue, WA",
    metropolitandivisiontitle: "Seattle-Bellevue-Everett, WA",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "King County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14740,
    metrodivisioncode: "",
    csacode: 500,
    cbsatitle: "Bremerton-Silverdale, WA",
    metropolitandivisiontitle: "",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Kitsap County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Ellensburg, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Kittitas County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16500,
    metrodivisioncode: "",
    csacode: 500,
    cbsatitle: "Centralia, WA",
    metropolitandivisiontitle: "",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Lewis County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43220,
    metrodivisioncode: "",
    csacode: 500,
    cbsatitle: "Shelton, WA",
    metropolitandivisiontitle: "",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Mason County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44060,
    metrodivisioncode: "",
    csacode: 518,
    cbsatitle: "Spokane-Spokane Valley, WA",
    metropolitandivisiontitle: "",
    csatitle: "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    countycountyequivalent: "Pend Oreille County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 51,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42660,
    metrodivisioncode: 45104,
    csacode: 500,
    cbsatitle: "Seattle-Tacoma-Bellevue, WA",
    metropolitandivisiontitle: "Tacoma-Lakewood, WA",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Pierce County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34580,
    metrodivisioncode: "",
    csacode: 500,
    cbsatitle: "Mount Vernon-Anacortes, WA",
    metropolitandivisiontitle: "",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Skagit County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38900,
    metrodivisioncode: "",
    csacode: 440,
    cbsatitle: "Portland-Vancouver-Hillsboro, OR-WA",
    metropolitandivisiontitle: "",
    csatitle: "Portland-Vancouver-Salem, OR-WA",
    countycountyequivalent: "Skamania County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 42660,
    metrodivisioncode: 42644,
    csacode: 500,
    cbsatitle: "Seattle-Tacoma-Bellevue, WA",
    metropolitandivisiontitle: "Seattle-Bellevue-Everett, WA",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Snohomish County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44060,
    metrodivisioncode: "",
    csacode: 518,
    cbsatitle: "Spokane-Spokane Valley, WA",
    metropolitandivisiontitle: "",
    csatitle: "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    countycountyequivalent: "Spokane County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 44060,
    metrodivisioncode: "",
    csacode: 518,
    cbsatitle: "Spokane-Spokane Valley, WA",
    metropolitandivisiontitle: "",
    csatitle: "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    countycountyequivalent: "Stevens County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 65,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 36500,
    metrodivisioncode: "",
    csacode: 500,
    cbsatitle: "Olympia-Tumwater, WA",
    metropolitandivisiontitle: "",
    csatitle: "Seattle-Tacoma, WA",
    countycountyequivalent: "Thurston County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47460,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Walla Walla, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Walla Walla County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bellingham, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Whatcom County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39420,
    metrodivisioncode: "",
    csacode: 446,
    cbsatitle: "Pullman, WA",
    metropolitandivisiontitle: "",
    csatitle: "Pullman-Moscow, WA-ID",
    countycountyequivalent: "Whitman County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Yakima, WA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Yakima County",
    statename: "Washington",
    fipsstatecode: 53,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25180,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Hagerstown-Martinsburg, MD-WV",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Berkeley County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16620,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Charleston, WV",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Boone County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 5,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48260,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Weirton-Steubenville, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Brooke County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Cabell County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16620,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Charleston, WV",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Clay County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 15,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 17220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksburg, WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Doddridge County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 13220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beckley, WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Fayette County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49020,
    metrodivisioncode: "",
    csacode: 548,
    cbsatitle: "Winchester, VA-WV",
    metropolitandivisiontitle: "",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Hampshire County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 27,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48260,
    metrodivisioncode: "",
    csacode: 430,
    cbsatitle: "Weirton-Steubenville, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    countycountyequivalent: "Hancock County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksburg, WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Harrison County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 47900,
    metrodivisioncode: 47894,
    csacode: 548,
    cbsatitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    metropolitandivisiontitle: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    csatitle: "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    countycountyequivalent: "Jefferson County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 16620,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Charleston, WV",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Kanawha County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Lincoln County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 43,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 30880,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Logan, WV",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Logan County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21900,
    metrodivisioncode: "",
    csacode: 390,
    cbsatitle: "Fairmont, WV",
    metropolitandivisiontitle: "",
    csatitle: "Morgantown-Fairmont, WV",
    countycountyequivalent: "Marion County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 49,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wheeling, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Marshall County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Point Pleasant, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mason County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 53,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 14140,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Bluefield, WV-VA",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mercer County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 19060,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cumberland, MD-WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Mineral County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 57,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 34060,
    metrodivisioncode: "",
    csacode: 390,
    cbsatitle: "Morgantown, WV",
    metropolitandivisiontitle: "",
    csatitle: "Morgantown-Fairmont, WV",
    countycountyequivalent: "Monongalia County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Wheeling, WV-OH",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Ohio County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 34060,
    metrodivisioncode: "",
    csacode: 390,
    cbsatitle: "Morgantown, WV",
    metropolitandivisiontitle: "",
    csatitle: "Morgantown-Fairmont, WV",
    countycountyequivalent: "Preston County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 77,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Putnam County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Beckley, WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Raleigh County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Elkins, WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Randolph County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 83,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Clarksburg, WV",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Taylor County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 91,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 26580,
    metrodivisioncode: "",
    csacode: 170,
    cbsatitle: "Huntington-Ashland, WV-KY-OH",
    metropolitandivisiontitle: "",
    csatitle: "Charleston-Huntington-Ashland, WV-OH-KY",
    countycountyequivalent: "Wayne County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 37620,
    metrodivisioncode: "",
    csacode: 425,
    cbsatitle: "Parkersburg-Vienna, WV",
    metropolitandivisiontitle: "",
    csatitle: "Parkersburg-Marietta-Vienna, WV-OH",
    countycountyequivalent: "Wirt County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 105,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 37620,
    metrodivisioncode: "",
    csacode: 425,
    cbsatitle: "Parkersburg-Vienna, WV",
    metropolitandivisiontitle: "",
    csatitle: "Parkersburg-Marietta-Vienna, WV-OH",
    countycountyequivalent: "Wood County",
    statename: "West Virginia",
    fipsstatecode: 54,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24580,
    metrodivisioncode: "",
    csacode: 267,
    cbsatitle: "Green Bay, WI",
    metropolitandivisiontitle: "",
    csatitle: "Green Bay-Shawano, WI",
    countycountyequivalent: "Brown County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11540,
    metrodivisioncode: "",
    csacode: 118,
    cbsatitle: "Appleton, WI",
    metropolitandivisiontitle: "",
    csatitle: "Appleton-Oshkosh-Neenah, WI",
    countycountyequivalent: "Calumet County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20740,
    metrodivisioncode: "",
    csacode: 232,
    cbsatitle: "Eau Claire, WI",
    metropolitandivisiontitle: "",
    csatitle: "Eau Claire-Menomonie, WI",
    countycountyequivalent: "Chippewa County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 17,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31540,
    metrodivisioncode: "",
    csacode: 357,
    cbsatitle: "Madison, WI",
    metropolitandivisiontitle: "",
    csatitle: "Madison-Janesville-Beloit, WI",
    countycountyequivalent: "Columbia County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 21,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31540,
    metrodivisioncode: "",
    csacode: 357,
    cbsatitle: "Madison, WI",
    metropolitandivisiontitle: "",
    csatitle: "Madison-Janesville-Beloit, WI",
    countycountyequivalent: "Dane County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 13180,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Beaver Dam, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Dodge County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Duluth, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Douglas County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32860,
    metrodivisioncode: "",
    csacode: 232,
    cbsatitle: "Menomonie, WI",
    metropolitandivisiontitle: "",
    csatitle: "Eau Claire-Menomonie, WI",
    countycountyequivalent: "Dunn County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 20740,
    metrodivisioncode: "",
    csacode: 232,
    cbsatitle: "Eau Claire, WI",
    metropolitandivisiontitle: "",
    csatitle: "Eau Claire-Menomonie, WI",
    countycountyequivalent: "Eau Claire County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27020,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Iron Mountain, MI-WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Florence County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 22540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Fond du Lac, WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Fond du Lac County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38420,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Platteville, WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Grant County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31540,
    metrodivisioncode: "",
    csacode: 357,
    cbsatitle: "Madison, WI",
    metropolitandivisiontitle: "",
    csatitle: "Madison-Janesville-Beloit, WI",
    countycountyequivalent: "Green County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 45,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 31540,
    metrodivisioncode: "",
    csacode: 357,
    cbsatitle: "Madison, WI",
    metropolitandivisiontitle: "",
    csatitle: "Madison-Janesville-Beloit, WI",
    countycountyequivalent: "Iowa County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 49,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 48020,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Watertown-Fort Atkinson, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Jefferson County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 55,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16980,
    metrodivisioncode: 29404,
    csacode: 176,
    cbsatitle: "Chicago-Naperville-Elgin, IL-IN-WI",
    metropolitandivisiontitle: "Lake County-Kenosha County, IL-WI",
    csatitle: "Chicago-Naperville, IL-IN-WI",
    countycountyequivalent: "Kenosha County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 24580,
    metrodivisioncode: "",
    csacode: 267,
    cbsatitle: "Green Bay, WI",
    metropolitandivisiontitle: "",
    csatitle: "Green Bay-Shawano, WI",
    countycountyequivalent: "Kewaunee County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 61,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 29100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "La Crosse-Onalaska, WI-MN",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "La Crosse County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32980,
    metrodivisioncode: "",
    csacode: 554,
    cbsatitle: "Merrill, WI",
    metropolitandivisiontitle: "",
    csatitle: "Wausau-Stevens Point-Wisconsin Rapids, WI",
    countycountyequivalent: "Lincoln County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31820,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Manitowoc, WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Manitowoc County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48140,
    metrodivisioncode: "",
    csacode: 554,
    cbsatitle: "Wausau, WI",
    metropolitandivisiontitle: "",
    csatitle: "Wausau-Stevens Point-Wisconsin Rapids, WI",
    countycountyequivalent: "Marathon County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 31940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Marinette, WI-MI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Marinette County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 75,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43020,
    metrodivisioncode: "",
    csacode: 267,
    cbsatitle: "Shawano, WI",
    metropolitandivisiontitle: "",
    csatitle: "Green Bay-Shawano, WI",
    countycountyequivalent: "Menominee County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 78,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33340,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Milwaukee-Waukesha-West Allis, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Milwaukee County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 24580,
    metrodivisioncode: "",
    csacode: 267,
    cbsatitle: "Green Bay, WI",
    metropolitandivisiontitle: "",
    csatitle: "Green Bay-Shawano, WI",
    countycountyequivalent: "Oconto County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 83,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 11540,
    metrodivisioncode: "",
    csacode: 118,
    cbsatitle: "Appleton, WI",
    metropolitandivisiontitle: "",
    csatitle: "Appleton-Oshkosh-Neenah, WI",
    countycountyequivalent: "Outagamie County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33340,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Milwaukee-Waukesha-West Allis, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Ozaukee County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 89,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "Pierce County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 93,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 44620,
    metrodivisioncode: "",
    csacode: 554,
    cbsatitle: "Stevens Point, WI",
    metropolitandivisiontitle: "",
    csatitle: "Wausau-Stevens Point-Wisconsin Rapids, WI",
    countycountyequivalent: "Portage County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 39540,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Racine, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Racine County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27500,
    metrodivisioncode: "",
    csacode: 357,
    cbsatitle: "Janesville-Beloit, WI",
    metropolitandivisiontitle: "",
    csatitle: "Madison-Janesville-Beloit, WI",
    countycountyequivalent: "Rock County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33460,
    metrodivisioncode: "",
    csacode: 378,
    cbsatitle: "Minneapolis-St. Paul-Bloomington, MN-WI",
    metropolitandivisiontitle: "",
    csatitle: "Minneapolis-St. Paul, MN-WI",
    countycountyequivalent: "St. Croix County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 109,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 12660,
    metrodivisioncode: "",
    csacode: 357,
    cbsatitle: "Baraboo, WI",
    metropolitandivisiontitle: "",
    csatitle: "Madison-Janesville-Beloit, WI",
    countycountyequivalent: "Sauk County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 111,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43020,
    metrodivisioncode: "",
    csacode: 267,
    cbsatitle: "Shawano, WI",
    metropolitandivisiontitle: "",
    csatitle: "Green Bay-Shawano, WI",
    countycountyequivalent: "Shawano County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43100,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sheboygan, WI",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sheboygan County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 48580,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Whitewater-Elkhorn, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Walworth County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 33340,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Milwaukee-Waukesha-West Allis, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Washington County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 131,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 33340,
    metrodivisioncode: "",
    csacode: 376,
    cbsatitle: "Milwaukee-Waukesha-West Allis, WI",
    metropolitandivisiontitle: "",
    csatitle: "Milwaukee-Racine-Waukesha, WI",
    countycountyequivalent: "Waukesha County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 36780,
    metrodivisioncode: "",
    csacode: 118,
    cbsatitle: "Oshkosh-Neenah, WI",
    metropolitandivisiontitle: "",
    csatitle: "Appleton-Oshkosh-Neenah, WI",
    countycountyequivalent: "Winnebago County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 49220,
    metrodivisioncode: "",
    csacode: 554,
    cbsatitle: "Wisconsin Rapids-Marshfield, WI",
    metropolitandivisiontitle: "",
    csatitle: "Wausau-Stevens Point-Wisconsin Rapids, WI",
    countycountyequivalent: "Wood County",
    statename: "Wisconsin",
    fipsstatecode: 55,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 29660,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Laramie, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Albany County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 23940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Gillette, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Campbell County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40180,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Riverton, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Fremont County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16940,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Cheyenne, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Laramie County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 16220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Casper, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Natrona County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 43260,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Sheridan, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sheridan County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 40540,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Rock Springs, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Sweetwater County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 37,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27220,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jackson, WY-ID",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Teton County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 21740,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Evanston, WY",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Uinta County",
    statename: "Wyoming",
    fipsstatecode: 56,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10260,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Adjuntas, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Adjuntas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 1,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Aguada Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 3,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Aguadilla Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 5,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Aguas Buenas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 7,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Aibonito Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 9,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Añasco Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 11,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11640,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Arecibo, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Arecibo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 13,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25020,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Guayama, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Arroyo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 15,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Barceloneta Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 17,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Barranquitas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 19,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Bayamón Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 21,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41900,
    metrodivisioncode: "",
    csacode: 364,
    cbsatitle: "San Germán, PR",
    metropolitandivisiontitle: "",
    csatitle: "Mayagüez-San Germán, PR",
    countycountyequivalent: "Cabo Rojo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 23,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Caguas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 25,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11640,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Arecibo, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Camuy Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 27,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Canóvanas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 29,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Carolina Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 31,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Cataño Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 33,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Cayey Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 35,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Ceiba Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 37,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Ciales Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 39,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Cidra Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 41,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17620,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Coamo, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Coamo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 43,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Comerío Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 45,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Corozal Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 47,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Dorado Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 51,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Fajardo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 53,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Florida Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 54,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Guánica Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 55,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 25020,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Guayama, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Guayama Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 57,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Guayanilla Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 59,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Guaynabo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 61,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Gurabo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 63,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11640,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Arecibo, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Hatillo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 65,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 32420,
    metrodivisioncode: "",
    csacode: 364,
    cbsatitle: "Mayagüez, PR",
    metropolitandivisiontitle: "",
    csatitle: "Mayagüez-San Germán, PR",
    countycountyequivalent: "Hormigueros Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 67,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Humacao Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 69,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Isabela Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 71,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 27580,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Jayuya, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Jayuya Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 73,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Juana Díaz Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 75,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Juncos Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 77,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41900,
    metrodivisioncode: "",
    csacode: 364,
    cbsatitle: "San Germán, PR",
    metropolitandivisiontitle: "",
    csatitle: "Mayagüez-San Germán, PR",
    countycountyequivalent: "Lajas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 79,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Lares Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 81,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Las Piedras Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 85,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Loíza Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 87,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Luquillo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 89,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Manatí Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 91,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Maunabo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 95,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 32420,
    metrodivisioncode: "",
    csacode: 364,
    cbsatitle: "Mayagüez, PR",
    metropolitandivisiontitle: "",
    csatitle: "Mayagüez-San Germán, PR",
    countycountyequivalent: "Mayagüez Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 97,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Moca Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 99,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Morovis Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 101,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Naguabo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 103,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Naranjito Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 105,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Orocovis Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 107,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 25020,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Guayama, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Patillas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 109,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Peñuelas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 111,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Ponce Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 113,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 11640,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Arecibo, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Quebradillas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 115,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Rincón Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 117,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Río Grande Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 119,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41900,
    metrodivisioncode: "",
    csacode: 364,
    cbsatitle: "San Germán, PR",
    metropolitandivisiontitle: "",
    csatitle: "Mayagüez-San Germán, PR",
    countycountyequivalent: "Sabana Grande Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 121,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 17640,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "Coco, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Salinas Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 123,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41900,
    metrodivisioncode: "",
    csacode: 364,
    cbsatitle: "San Germán, PR",
    metropolitandivisiontitle: "",
    csatitle: "Mayagüez-San Germán, PR",
    countycountyequivalent: "San Germán Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 125,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "San Juan Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 127,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "San Lorenzo Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 129,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "San Sebastián Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 131,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 42180,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Santa Isabel, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Santa Isabel Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 133,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Toa Alta Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 135,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Toa Baja Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 137,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Trujillo Alto Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 139,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 10380,
    metrodivisioncode: "",
    csacode: "",
    cbsatitle: "Aguadilla-Isabela, PR",
    metropolitandivisiontitle: "",
    csatitle: "",
    countycountyequivalent: "Utuado Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 141,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Vega Alta Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 143,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Vega Baja Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 145,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Villalba Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 149,
    centraloutlyingcounty: "Outlying",
  },
  {
    cbsacode: 41980,
    metrodivisioncode: "",
    csacode: 490,
    cbsatitle: "San Juan-Carolina-Caguas, PR",
    metropolitandivisiontitle: "",
    csatitle: "San Juan-Carolina, PR",
    countycountyequivalent: "Yabucoa Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 151,
    centraloutlyingcounty: "Central",
  },
  {
    cbsacode: 38660,
    metrodivisioncode: "",
    csacode: 434,
    cbsatitle: "Ponce, PR",
    metropolitandivisiontitle: "",
    csatitle: "Ponce-Coamo-Santa Isabel, PR",
    countycountyequivalent: "Yauco Municipio",
    statename: "Puerto Rico",
    fipsstatecode: 72,
    fipscountycode: 153,
    centraloutlyingcounty: "Outlying",
  },
];
