import { MetroOption } from "types/options";

export const metroList: MetroOption[] = [
  {
    "FIPS State Code": "46",
    "FIPS County Code": "013",
    value: 10100,
    label: "Aberdeen, SD",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "027",
    value: 10140,
    label: "Aberdeen, WA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "059",
    value: 10180,
    label: "Abilene, TX",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "123",
    value: 10220,
    label: "Ada, OK",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "091",
    value: 10300,
    label: "Adrian, MI",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "133",
    value: 10420,
    label: "Akron, OH",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "035",
    value: 10460,
    label: "Alamogordo, NM",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "095",
    value: 10500,
    label: "Albany, GA",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "043",
    value: 10540,
    label: "Albany-Lebanon, OR",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "001",
    value: 10580,
    label: "Albany-Schenectady-Troy, NY",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "167",
    value: 10620,
    label: "Albemarle, NC",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "047",
    value: 10660,
    label: "Albert Lea, MN",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "095",
    value: 10700,
    label: "Albertville, AL",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "001",
    value: 10740,
    label: "Albuquerque, NM",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "037",
    value: 10760,
    label: "Alexander City, AL",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "043",
    value: 10780,
    label: "Alexandria, LA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "041",
    value: 10820,
    label: "Alexandria, MN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "131",
    value: 10860,
    label: "Alice, TX",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "041",
    value: 10900,
    label: "Allentown-Bethlehem-Easton, PA-NJ",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "057",
    value: 10940,
    label: "Alma, MI",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "007",
    value: 10980,
    label: "Alpena, MI",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "013",
    value: 11020,
    label: "Altoona, PA",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "065",
    value: 11060,
    label: "Altus, OK",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "011",
    value: 11100,
    label: "Amarillo, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "249",
    value: 11140,
    label: "Americus, GA",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "015",
    value: 11180,
    label: "Ames, IA",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "057",
    value: 11220,
    label: "Amsterdam, NY",
  },
  {
    "FIPS State Code": "02",
    "FIPS County Code": "020",
    value: 11260,
    label: "Anchorage, AK",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "003",
    value: 11380,
    label: "Andrews, TX",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "151",
    value: 11420,
    label: "Angola, IN",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "161",
    value: 11460,
    label: "Ann Arbor, MI",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "015",
    value: 11500,
    label: "Anniston-Oxford, AL",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "015",
    value: 11540,
    label: "Appleton, WI",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "027",
    value: 11580,
    label: "Arcadia, FL",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "019",
    value: 11620,
    label: "Ardmore, OK",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "019",
    value: 11660,
    label: "Arkadelphia, AR",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "021",
    value: 11700,
    label: "Asheville, NC",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "005",
    value: 11740,
    label: "Ashland, OH",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "007",
    value: 11780,
    label: "Ashtabula, OH",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "007",
    value: 11820,
    label: "Astoria, OR",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "005",
    value: 11860,
    label: "Atchison, KS",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "009",
    value: 11900,
    label: "Athens, OH",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "107",
    value: 11940,
    label: "Athens, TN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "213",
    value: 11980,
    label: "Athens, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "059",
    value: 12020,
    label: "Athens-Clarke County, GA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "013",
    value: 12060,
    label: "Atlanta-Sandy Springs-Alpharetta, GA",
  },
  {
    "FIPS State Code": "34",
    "FIPS County Code": "001",
    value: 12100,
    label: "Atlantic City-Hammonton, NJ",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "053",
    value: 12120,
    label: "Atmore, AL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "033",
    value: 12140,
    label: "Auburn, IN",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "011",
    value: 12180,
    label: "Auburn, NY",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "081",
    value: 12220,
    label: "Auburn-Opelika, AL",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "033",
    value: 12260,
    label: "Augusta-Richmond County, GA-SC",
  },
  {
    "FIPS State Code": "23",
    "FIPS County Code": "011",
    value: 12300,
    label: "Augusta-Waterville, ME",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "099",
    value: 12380,
    label: "Austin, MN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "021",
    value: 12420,
    label: "Austin-Round Rock-Georgetown, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "087",
    value: 12460,
    label: "Bainbridge, GA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "029",
    value: 12540,
    label: "Bakersfield, CA",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "003",
    value: 12580,
    label: "Baltimore-Columbia-Towson, MD",
  },
  {
    "FIPS State Code": "23",
    "FIPS County Code": "019",
    value: 12620,
    label: "Bangor, ME",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "111",
    value: 12660,
    label: "Baraboo, WI",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "179",
    value: 12680,
    label: "Bardstown, KY",
  },
  {
    "FIPS State Code": "25",
    "FIPS County Code": "001",
    value: 12700,
    label: "Barnstable Town, MA",
  },
  {
    "FIPS State Code": "50",
    "FIPS County Code": "023",
    value: 12740,
    label: "Barre, VT",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "147",
    value: 12780,
    label: "Bartlesville, OK",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "037",
    value: 12860,
    label: "Batavia, NY",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "063",
    value: 12900,
    label: "Batesville, AR",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "005",
    value: 12940,
    label: "Baton Rouge, LA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "025",
    value: 12980,
    label: "Battle Creek, MI",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "017",
    value: 13020,
    label: "Bay City, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "321",
    value: 13060,
    label: "Bay City, TX",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "067",
    value: 13100,
    label: "Beatrice, NE",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "199",
    value: 13140,
    label: "Beaumont-Port Arthur, TX",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "027",
    value: 13180,
    label: "Beaver Dam, WI",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "019",
    value: 13220,
    label: "Beckley, WV",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "093",
    value: 13260,
    label: "Bedford, IN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "025",
    value: 13300,
    label: "Beeville, TX",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "091",
    value: 13340,
    label: "Bellefontaine, OH",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "073",
    value: 13380,
    label: "Bellingham, WA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "007",
    value: 13420,
    label: "Bemidji, MN",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "017",
    value: 13460,
    label: "Bend, OR",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "069",
    value: 13500,
    label: "Bennettsville, SC",
  },
  {
    "FIPS State Code": "50",
    "FIPS County Code": "003",
    value: 13540,
    label: "Bennington, VT",
  },
  {
    "FIPS State Code": "33",
    "FIPS County Code": "007",
    value: 13620,
    label: "Berlin, NH",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "107",
    value: 13660,
    label: "Big Rapids, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "227",
    value: 13700,
    label: "Big Spring, TX",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "195",
    value: 13720,
    label: "Big Stone Gap, VA",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "009",
    value: 13740,
    label: "Billings, MT",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "007",
    value: 13780,
    label: "Binghamton, NY",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "007",
    value: 13820,
    label: "Birmingham-Hoover, AL",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "015",
    value: 13900,
    label: "Bismarck, ND",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "011",
    value: 13940,
    label: "Blackfoot, ID",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "071",
    value: 13980,
    label: "Blacksburg-Christiansburg, VA",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "113",
    value: 14010,
    label: "Bloomington, IL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "105",
    value: 14020,
    label: "Bloomington, IN",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "037",
    value: 14100,
    label: "Bloomsburg-Berwick, PA",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "021",
    value: 14140,
    label: "Bluefield, WV-VA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "179",
    value: 14160,
    label: "Bluffton, IN",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "093",
    value: 14180,
    label: "Blytheville, AR",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "117",
    value: 14220,
    label: "Bogalusa, LA",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "001",
    value: 14260,
    label: "Boise City, ID",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "147",
    value: 14300,
    label: "Bonham, TX",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "189",
    value: 14380,
    label: "Boone, NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "233",
    value: 14420,
    label: "Borger, TX",
  },
  {
    "FIPS State Code": "25",
    "FIPS County Code": "021",
    value: 14460,
    label: "Boston-Cambridge-Newton, MA-NH",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "013",
    value: 14500,
    label: "Boulder, CO",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "003",
    value: 14540,
    label: "Bowling Green, KY",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "031",
    value: 14580,
    label: "Bozeman, MT",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "083",
    value: 14620,
    label: "Bradford, PA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "021",
    value: 14660,
    label: "Brainerd, MN",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "213",
    value: 14700,
    label: "Branson, MO",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "117",
    value: 14720,
    label: "Breckenridge, CO",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "035",
    value: 14740,
    label: "Bremerton-Silverdale-Port Orchard, WA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "477",
    value: 14780,
    label: "Brenham, TX",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "175",
    value: 14820,
    label: "Brevard, NC",
  },
  {
    "FIPS State Code": "09",
    "FIPS County Code": "001",
    value: 14860,
    label: "Bridgeport-Stamford-Norwalk, CT",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "085",
    value: 15020,
    label: "Brookhaven, MS",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "015",
    value: 15060,
    label: "Brookings, OR",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "011",
    value: 15100,
    label: "Brookings, SD",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "075",
    value: 15140,
    label: "Brownsville, TN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "061",
    value: 15180,
    label: "Brownsville-Harlingen, TX",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "049",
    value: 15220,
    label: "Brownwood, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "025",
    value: 15260,
    label: "Brunswick, GA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "033",
    value: 15340,
    label: "Bucyrus-Galion, OH",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "029",
    value: 15380,
    label: "Buffalo-Cheektowaga, NY",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "031",
    value: 15420,
    label: "Burley, ID",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "071",
    value: 15460,
    label: "Burlington, IA-IL",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "001",
    value: 15500,
    label: "Burlington, NC",
  },
  {
    "FIPS State Code": "50",
    "FIPS County Code": "007",
    value: 15540,
    label: "Burlington-South Burlington, VT",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "093",
    value: 15580,
    label: "Butte-Silver Bow, MT",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "113",
    value: 15620,
    label: "Cadillac, MI",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "129",
    value: 15660,
    label: "Calhoun, GA",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "037",
    value: 15680,
    label: "California-Lexington Park, MD",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "019",
    value: 15700,
    label: "Cambridge, MD",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "059",
    value: 15740,
    label: "Cambridge, OH",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "013",
    value: 15780,
    label: "Camden, AR",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "087",
    value: 15820,
    label: "Campbellsville, KY",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "043",
    value: 15860,
    label: "Cañon City, CO",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "019",
    value: 15940,
    label: "Canton-Massillon, OH",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "071",
    value: 15980,
    label: "Cape Coral-Fort Myers, FL",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "003",
    value: 16020,
    label: "Cape Girardeau, MO-IL",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "077",
    value: 16060,
    label: "Carbondale-Marion, IL",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "015",
    value: 16100,
    label: "Carlsbad-Artesia, NM",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "027",
    value: 16140,
    label: "Carroll, IA",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "510",
    value: 16180,
    label: "Carson City, NV",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "025",
    value: 16220,
    label: "Casper, WY",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "021",
    value: 16260,
    label: "Cedar City, UT",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "011",
    value: 16300,
    label: "Cedar Rapids, IA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "233",
    value: 16340,
    label: "Cedartown, GA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "107",
    value: 16380,
    label: "Celina, OH",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "177",
    value: 16420,
    label: "Central City, KY",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "121",
    value: 16460,
    label: "Centralia, IL",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "041",
    value: 16500,
    label: "Centralia, WA",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "055",
    value: 16540,
    label: "Chambersburg-Waynesboro, PA",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "019",
    value: 16580,
    label: "Champaign-Urbana, IL",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "005",
    value: 16620,
    label: "Charleston, WV",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "029",
    value: 16660,
    label: "Charleston-Mattoon, IL",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "015",
    value: 16700,
    label: "Charleston-North Charleston, SC",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "007",
    value: 16740,
    label: "Charlotte-Concord-Gastonia, NC-SC",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "003",
    value: 16820,
    label: "Charlottesville, VA",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "047",
    value: 16860,
    label: "Chattanooga, TN-GA",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "021",
    value: 16940,
    label: "Cheyenne, WY",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "031",
    value: 16980,
    label: "Chicago-Naperville-Elgin, IL-IN-WI",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "007",
    value: 17020,
    label: "Chico, CA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "141",
    value: 17060,
    label: "Chillicothe, OH",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "029",
    value: 17140,
    label: "Cincinnati, OH-KY-IN",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "017",
    value: 17220,
    label: "Clarksburg, WV",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "027",
    value: 17260,
    label: "Clarksdale, MS",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "047",
    value: 17300,
    label: "Clarksville, TN-KY",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "033",
    value: 17340,
    label: "Clearlake, CA",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "011",
    value: 17380,
    label: "Cleveland, MS",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "011",
    value: 17420,
    label: "Cleveland, TN",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "035",
    value: 17460,
    label: "Cleveland-Elyria, OH",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "051",
    value: 17500,
    label: "Clewiston, FL",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "045",
    value: 17540,
    label: "Clinton, IA",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "009",
    value: 17580,
    label: "Clovis, NM",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "055",
    value: 17660,
    label: "Coeur d'Alene, ID",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "125",
    value: 17700,
    label: "Coffeyville, KS",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "023",
    value: 17740,
    label: "Coldwater, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "041",
    value: 17780,
    label: "College Station-Bryan, TX",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "041",
    value: 17820,
    label: "Colorado Springs, CO",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "019",
    value: 17860,
    label: "Columbia, MO",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "017",
    value: 17900,
    label: "Columbia, SC",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "113",
    value: 17980,
    label: "Columbus, GA-AL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "005",
    value: 18020,
    label: "Columbus, IN",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "087",
    value: 18060,
    label: "Columbus, MS",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "141",
    value: 18100,
    label: "Columbus, NE",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "041",
    value: 18140,
    label: "Columbus, OH",
  },
  {
    "FIPS State Code": "33",
    "FIPS County Code": "013",
    value: 18180,
    label: "Concord, NH",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "041",
    value: 18220,
    label: "Connersville, IN",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "087",
    value: 18260,
    label: "Cookeville, TN",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "011",
    value: 18300,
    label: "Coos Bay, OR",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "081",
    value: 18380,
    label: "Cordele, GA",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "003",
    value: 18420,
    label: "Corinth, MS",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "137",
    value: 18460,
    label: "Cornelia, GA",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "101",
    value: 18500,
    label: "Corning, NY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "355",
    value: 18580,
    label: "Corpus Christi, TX",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "349",
    value: 18620,
    label: "Corsicana, TX",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "023",
    value: 18660,
    label: "Cortland, NY",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "003",
    value: 18700,
    label: "Corvallis, OR",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "031",
    value: 18740,
    label: "Coshocton, OH",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "081",
    value: 18780,
    label: "Craig, CO",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "107",
    value: 18820,
    label: "Crawfordsville, IN",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "015",
    value: 18860,
    label: "Crescent City, CA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "091",
    value: 18880,
    label: "Crestview-Fort Walton Beach-Destin, FL",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "035",
    value: 18900,
    label: "Crossville, TN",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "043",
    value: 18980,
    label: "Cullman, AL",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "099",
    value: 19000,
    label: "Cullowhee, NC",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "001",
    value: 19060,
    label: "Cumberland, MD-WV",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "085",
    value: 19100,
    label: "Dallas-Fort Worth-Arlington, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "213",
    value: 19140,
    label: "Dalton, GA",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "183",
    value: 19180,
    label: "Danville, IL",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "021",
    value: 19220,
    label: "Danville, KY",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "143",
    value: 19260,
    label: "Danville, VA",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "003",
    value: 19300,
    label: "Daphne-Fairhope-Foley, AL",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "073",
    value: 19340,
    label: "Davenport-Moline-Rock Island, IA-IL",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "143",
    value: 19420,
    label: "Dayton, TN",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "057",
    value: 19430,
    label: "Dayton-Kettering, OH",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "079",
    value: 19460,
    label: "Decatur, AL",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "115",
    value: 19500,
    label: "Decatur, IL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "001",
    value: 19540,
    label: "Decatur, IN",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "039",
    value: 19580,
    label: "Defiance, OH",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "465",
    value: 19620,
    label: "Del Rio, TX",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "035",
    value: 19660,
    label: "Deltona-Daytona Beach-Ormond Beach, FL",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "029",
    value: 19700,
    label: "Deming, NM",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "001",
    value: 19740,
    label: "Denver-Aurora-Lakewood, CO",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "011",
    value: 19760,
    label: "DeRidder, LA",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "049",
    value: 19780,
    label: "Des Moines-West Des Moines, IA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "163",
    value: 19820,
    label: "Detroit-Warren-Dearborn, MI",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "007",
    value: 19860,
    label: "Dickinson, ND",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "103",
    value: 19940,
    label: "Dixon, IL",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "057",
    value: 19980,
    label: "Dodge City, KS",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "061",
    value: 20020,
    label: "Dothan, AL",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "003",
    value: 20060,
    label: "Douglas, GA",
  },
  {
    "FIPS State Code": "10",
    "FIPS County Code": "001",
    value: 20100,
    label: "Dover, DE",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "167",
    value: 20140,
    label: "Dublin, GA",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "033",
    value: 20180,
    label: "DuBois, PA",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "061",
    value: 20220,
    label: "Dubuque, IA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "017",
    value: 20260,
    label: "Duluth, MN-WI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "341",
    value: 20300,
    label: "Dumas, TX",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "137",
    value: 20340,
    label: "Duncan, OK",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "067",
    value: 20420,
    label: "Durango, CO",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "013",
    value: 20460,
    label: "Durant, OK",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "037",
    value: 20500,
    label: "Durham-Chapel Hill, NC",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "045",
    value: 20540,
    label: "Dyersburg, TN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "323",
    value: 20580,
    label: "Eagle Pass, TX",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "041",
    value: 20660,
    label: "Easton, MD",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "089",
    value: 20700,
    label: "East Stroudsburg, PA",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "017",
    value: 20740,
    label: "Eau Claire, WI",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "037",
    value: 20780,
    label: "Edwards, CO",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "049",
    value: 20820,
    label: "Effingham, IL",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "481",
    value: 20900,
    label: "El Campo, TX",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "025",
    value: 20940,
    label: "El Centro, CA",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "139",
    value: 20980,
    label: "El Dorado, AR",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "139",
    value: 21020,
    label: "Elizabeth City, NC",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "093",
    value: 21060,
    label: "Elizabethtown-Fort Knox, KY",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "009",
    value: 21120,
    label: "Elk City, OK",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "039",
    value: 21140,
    label: "Elkhart-Goshen, IN",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "083",
    value: 21180,
    label: "Elkins, WV",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "007",
    value: 21220,
    label: "Elko, NV",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "037",
    value: 21260,
    label: "Ellensburg, WA",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "015",
    value: 21300,
    label: "Elmira, NY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "141",
    value: 21340,
    label: "El Paso, TX",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "017",
    value: 21380,
    label: "Emporia, KS",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "047",
    value: 21420,
    label: "Enid, OK",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "031",
    value: 21460,
    label: "Enterprise, AL",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "049",
    value: 21500,
    label: "Erie, PA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "041",
    value: 21540,
    label: "Escanaba, MI",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "039",
    value: 21580,
    label: "Española, NM",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "005",
    value: 21640,
    label: "Eufaula, AL-GA",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "039",
    value: 21660,
    label: "Eugene-Springfield, OR",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "023",
    value: 21700,
    label: "Eureka-Arcata, CA",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "041",
    value: 21740,
    label: "Evanston, WY",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "129",
    value: 21780,
    label: "Evansville, IN-KY",
  },
  {
    "FIPS State Code": "02",
    "FIPS County Code": "090",
    value: 21820,
    label: "Fairbanks, AK",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "101",
    value: 21840,
    label: "Fairfield, IA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "091",
    value: 21860,
    label: "Fairmont, MN",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "049",
    value: 21900,
    label: "Fairmont, WV",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "001",
    value: 21980,
    label: "Fallon, NV",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "027",
    value: 22020,
    label: "Fargo, ND-MN",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "131",
    value: 22060,
    label: "Faribault-Northfield, MN",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "187",
    value: 22100,
    label: "Farmington, MO",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "045",
    value: 22140,
    label: "Farmington, NM",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "051",
    value: 22180,
    label: "Fayetteville, NC",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "007",
    value: 22220,
    label: "Fayetteville-Springdale-Rogers, AR",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "111",
    value: 22260,
    label: "Fergus Falls, MN",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "019",
    value: 22280,
    label: "Fernley, NV",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "063",
    value: 22300,
    label: "Findlay, OH",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "017",
    value: 22340,
    label: "Fitzgerald, GA",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "005",
    value: 22380,
    label: "Flagstaff, AZ",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "049",
    value: 22420,
    label: "Flint, MI",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "031",
    value: 22500,
    label: "Florence, SC",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "033",
    value: 22520,
    label: "Florence-Muscle Shoals, AL",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "039",
    value: 22540,
    label: "Fond du Lac, WI",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "161",
    value: 22580,
    label: "Forest City, NC",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "123",
    value: 22620,
    label: "Forrest City, AR",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "069",
    value: 22660,
    label: "Fort Collins, CO",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "187",
    value: 22700,
    label: "Fort Dodge, IA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "169",
    value: 22780,
    label: "Fort Leonard Wood, MO",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "067",
    value: 22800,
    label: "Fort Madison-Keokuk, IA-IL-MO",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "087",
    value: 22820,
    label: "Fort Morgan, CO",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "049",
    value: 22840,
    label: "Fort Payne, AL",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "115",
    value: 22860,
    label: "Fort Polk South, LA",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "033",
    value: 22900,
    label: "Fort Smith, AR-OK",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "003",
    value: 23060,
    label: "Fort Wayne, IN",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "023",
    value: 23140,
    label: "Frankfort, IN",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "005",
    value: 23180,
    label: "Frankfort, KY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "171",
    value: 23240,
    label: "Fredericksburg, TX",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "177",
    value: 23300,
    label: "Freeport, IL",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "053",
    value: 23340,
    label: "Fremont, NE",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "143",
    value: 23380,
    label: "Fremont, OH",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "019",
    value: 23420,
    label: "Fresno, CA",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "055",
    value: 23460,
    label: "Gadsden, AL",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "021",
    value: 23500,
    label: "Gaffney, SC",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "001",
    value: 23540,
    label: "Gainesville, FL",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "139",
    value: 23580,
    label: "Gainesville, GA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "097",
    value: 23620,
    label: "Gainesville, TX",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "095",
    value: 23660,
    label: "Galesburg, IL",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "031",
    value: 23700,
    label: "Gallup, NM",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "055",
    value: 23780,
    label: "Garden City, KS",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "005",
    value: 23820,
    label: "Gardnerville Ranchos, NV",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "043",
    value: 23860,
    label: "Georgetown, SC",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "001",
    value: 23900,
    label: "Gettysburg, PA",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "005",
    value: 23940,
    label: "Gillette, WY",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "009",
    value: 23980,
    label: "Glasgow, KY",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "113",
    value: 24020,
    label: "Glens Falls, NY",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "045",
    value: 24060,
    label: "Glenwood Springs, CO",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "035",
    value: 24100,
    label: "Gloversville, NY",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "191",
    value: 24140,
    label: "Goldsboro, NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "221",
    value: 24180,
    label: "Granbury, TX",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "119",
    value: 24220,
    label: "Grand Forks, ND-MN",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "079",
    value: 24260,
    label: "Grand Island, NE",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "077",
    value: 24300,
    label: "Grand Junction, CO",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "061",
    value: 24330,
    label: "Grand Rapids, MN",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "067",
    value: 24340,
    label: "Grand Rapids-Kentwood, MI",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "006",
    value: 24380,
    label: "Grants, NM",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "033",
    value: 24420,
    label: "Grants Pass, OR",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "009",
    value: 24460,
    label: "Great Bend, KS",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "013",
    value: 24500,
    label: "Great Falls, MT",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "123",
    value: 24540,
    label: "Greeley, CO",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "009",
    value: 24580,
    label: "Green Bay, WI",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "059",
    value: 24620,
    label: "Greeneville, TN",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "081",
    value: 24660,
    label: "Greensboro-High Point, NC",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "031",
    value: 24700,
    label: "Greensburg, IN",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "151",
    value: 24740,
    label: "Greenville, MS",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "147",
    value: 24780,
    label: "Greenville, NC",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "037",
    value: 24820,
    label: "Greenville, OH",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "007",
    value: 24860,
    label: "Greenville-Anderson, SC",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "015",
    value: 24900,
    label: "Greenwood, MS",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "047",
    value: 24940,
    label: "Greenwood, SC",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "043",
    value: 24980,
    label: "Grenada, MS",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "045",
    value: 25060,
    label: "Gulfport-Biloxi, MS",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "139",
    value: 25100,
    label: "Guymon, OK",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "043",
    value: 25180,
    label: "Hagerstown-Martinsburg, MD-WV",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "013",
    value: 25200,
    label: "Hailey, ID",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "105",
    value: 25220,
    label: "Hammond, LA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "031",
    value: 25260,
    label: "Hanford-Corcoran, CA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "127",
    value: 25300,
    label: "Hannibal, MO",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "041",
    value: 25420,
    label: "Harrisburg-Carlisle, PA",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "009",
    value: 25460,
    label: "Harrison, AR",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "165",
    value: 25500,
    label: "Harrisonburg, VA",
  },
  {
    "FIPS State Code": "09",
    "FIPS County Code": "003",
    value: 25540,
    label: "Hartford-East Hartford-Middletown, CT",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "001",
    value: 25580,
    label: "Hastings, NE",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "031",
    value: 25620,
    label: "Hattiesburg, MS",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "051",
    value: 25700,
    label: "Hays, KS",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "043",
    value: 25720,
    label: "Heber, UT",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "043",
    value: 25740,
    label: "Helena, MT",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "107",
    value: 25760,
    label: "Helena-West Helena, AR",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "181",
    value: 25780,
    label: "Henderson, NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "117",
    value: 25820,
    label: "Hereford, TX",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "049",
    value: 25840,
    label: "Hermiston-Pendleton, OR",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "003",
    value: 25860,
    label: "Hickory-Lenoir-Morganton, NC",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "059",
    value: 25880,
    label: "Hillsdale, MI",
  },
  {
    "FIPS State Code": "15",
    "FIPS County Code": "001",
    value: 25900,
    label: "Hilo, HI",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "013",
    value: 25940,
    label: "Hilton Head Island-Bluffton, SC",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "179",
    value: 25980,
    label: "Hinesville, GA",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "025",
    value: 26020,
    label: "Hobbs, NM",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "005",
    value: 26090,
    label: "Holland, MI",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "017",
    value: 26140,
    label: "Homosassa Springs, FL",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "027",
    value: 26220,
    label: "Hood River, OR",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "057",
    value: 26260,
    label: "Hope, AR",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "051",
    value: 26300,
    label: "Hot Springs, AR",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "061",
    value: 26340,
    label: "Houghton, MI",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "057",
    value: 26380,
    label: "Houma-Thibodaux, LA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "015",
    value: 26420,
    label: "Houston-The Woodlands-Sugar Land, TX",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "021",
    value: 26460,
    label: "Hudson, NY",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "061",
    value: 26500,
    label: "Huntingdon, PA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "069",
    value: 26540,
    label: "Huntington, IN",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "019",
    value: 26580,
    label: "Huntington-Ashland, WV-KY-OH",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "083",
    value: 26620,
    label: "Huntsville, AL",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "471",
    value: 26660,
    label: "Huntsville, TX",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "005",
    value: 26700,
    label: "Huron, SD",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "155",
    value: 26740,
    label: "Hutchinson, KS",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "085",
    value: 26780,
    label: "Hutchinson, MN",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "019",
    value: 26820,
    label: "Idaho Falls, ID",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "063",
    value: 26860,
    label: "Indiana, PA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "011",
    value: 26900,
    label: "Indianapolis-Carmel-Anderson, IN",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "133",
    value: 26940,
    label: "Indianola, MS",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "103",
    value: 26980,
    label: "Iowa City, IA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "043",
    value: 27020,
    label: "Iron Mountain, MI-WI",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "109",
    value: 27060,
    label: "Ithaca, NY",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "075",
    value: 27100,
    label: "Jackson, MI",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "029",
    value: 27140,
    label: "Jackson, MS",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "079",
    value: 27160,
    label: "Jackson, OH",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "023",
    value: 27180,
    label: "Jackson, TN",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "081",
    value: 27220,
    label: "Jackson, WY-ID",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "003",
    value: 27260,
    label: "Jacksonville, FL",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "137",
    value: 27300,
    label: "Jacksonville, IL",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "133",
    value: 27340,
    label: "Jacksonville, NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "073",
    value: 27380,
    label: "Jacksonville, TX",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "093",
    value: 27420,
    label: "Jamestown, ND",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "013",
    value: 27460,
    label: "Jamestown-Dunkirk-Fredonia, NY",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "105",
    value: 27500,
    label: "Janesville-Beloit, WI",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "127",
    value: 27530,
    label: "Jasper, AL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "037",
    value: 27540,
    label: "Jasper, IN",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "157",
    value: 27600,
    label: "Jefferson, GA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "027",
    value: 27620,
    label: "Jefferson City, MO",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "053",
    value: 27660,
    label: "Jennings, LA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "305",
    value: 27700,
    label: "Jesup, GA",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "019",
    value: 27740,
    label: "Johnson City, TN",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "021",
    value: 27780,
    label: "Johnstown, PA",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "031",
    value: 27860,
    label: "Jonesboro, AR",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "097",
    value: 27900,
    label: "Joplin, MO",
  },
  {
    "FIPS State Code": "02",
    "FIPS County Code": "110",
    value: 27940,
    label: "Juneau, AK",
  },
  {
    "FIPS State Code": "15",
    "FIPS County Code": "009",
    value: 27980,
    label: "Kahului-Wailuku-Lahaina, HI",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "077",
    value: 28020,
    label: "Kalamazoo-Portage, MI",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "029",
    value: 28060,
    label: "Kalispell, MT",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "091",
    value: 28100,
    label: "Kankakee, IL",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "091",
    value: 28140,
    label: "Kansas City, MO-KS",
  },
  {
    "FIPS State Code": "15",
    "FIPS County Code": "007",
    value: 28180,
    label: "Kapaa, HI",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "019",
    value: 28260,
    label: "Kearney, NE",
  },
  {
    "FIPS State Code": "33",
    "FIPS County Code": "005",
    value: 28300,
    label: "Keene, NH",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "113",
    value: 28340,
    label: "Kendallville, IN",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "069",
    value: 28380,
    label: "Kennett, MO",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "005",
    value: 28420,
    label: "Kennewick-Richland, WA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "265",
    value: 28500,
    label: "Kerrville, TX",
  },
  {
    "FIPS State Code": "02",
    "FIPS County Code": "130",
    value: 28540,
    label: "Ketchikan, AK",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "087",
    value: 28580,
    label: "Key West, FL",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "055",
    value: 28620,
    label: "Kill Devil Hills, NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "027",
    value: 28660,
    label: "Killeen-Temple, TX",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "073",
    value: 28700,
    label: "Kingsport-Bristol, TN-VA",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "111",
    value: 28740,
    label: "Kingston, NY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "261",
    value: 28780,
    label: "Kingsville, TX",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "107",
    value: 28820,
    label: "Kinston, NC",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "001",
    value: 28860,
    label: "Kirksville, MO",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "035",
    value: 28900,
    label: "Klamath Falls, OR",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "001",
    value: 28940,
    label: "Knoxville, TN",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "067",
    value: 29020,
    label: "Kokomo, IN",
  },
  {
    "FIPS State Code": "33",
    "FIPS County Code": "001",
    value: 29060,
    label: "Laconia, NH",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "055",
    value: 29100,
    label: "La Crosse-Onalaska, WI-MN",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "001",
    value: 29180,
    label: "Lafayette, LA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "007",
    value: 29200,
    label: "Lafayette-West Lafayette, IN",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "061",
    value: 29260,
    label: "La Grande, OR",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "017",
    value: 29300,
    label: "LaGrange, GA-AL",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "019",
    value: 29340,
    label: "Lake Charles, LA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "023",
    value: 29380,
    label: "Lake City, FL",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "015",
    value: 29420,
    label: "Lake Havasu City-Kingman, AZ",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "105",
    value: 29460,
    label: "Lakeland-Winter Haven, FL",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "115",
    value: 29500,
    label: "Lamesa, TX",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "071",
    value: 29540,
    label: "Lancaster, PA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "037",
    value: 29620,
    label: "Lansing-East Lansing, MI",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "001",
    value: 29660,
    label: "Laramie, WY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "479",
    value: 29700,
    label: "Laredo, TX",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "013",
    value: 29740,
    label: "Las Cruces, NM",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "033",
    value: 29780,
    label: "Las Vegas, NM",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "003",
    value: 29820,
    label: "Las Vegas-Henderson-Paradise, NV",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "061",
    value: 29860,
    label: "Laurel, MS",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "165",
    value: 29900,
    label: "Laurinburg, NC",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "045",
    value: 29940,
    label: "Lawrence, KS",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "099",
    value: 29980,
    label: "Lawrenceburg, TN",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "031",
    value: 30020,
    label: "Lawton, OK",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "105",
    value: 30060,
    label: "Lebanon, MO",
  },
  {
    "FIPS State Code": "33",
    "FIPS County Code": "009",
    value: 30100,
    label: "Lebanon, NH-VT",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "075",
    value: 30140,
    label: "Lebanon, PA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "219",
    value: 30220,
    label: "Levelland, TX",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "119",
    value: 30260,
    label: "Lewisburg, PA",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "117",
    value: 30280,
    label: "Lewisburg, TN",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "069",
    value: 30300,
    label: "Lewiston, ID-WA",
  },
  {
    "FIPS State Code": "23",
    "FIPS County Code": "001",
    value: 30340,
    label: "Lewiston-Auburn, ME",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "087",
    value: 30380,
    label: "Lewistown, PA",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "047",
    value: 30420,
    label: "Lexington, NE",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "017",
    value: 30460,
    label: "Lexington-Fayette, KY",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "175",
    value: 30580,
    label: "Liberal, KS",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "003",
    value: 30620,
    label: "Lima, OH",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "107",
    value: 30660,
    label: "Lincoln, IL",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "109",
    value: 30700,
    label: "Lincoln, NE",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "045",
    value: 30780,
    label: "Little Rock-North Little Rock-Conway, AR",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "035",
    value: 30820,
    label: "Lock Haven, PA",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "041",
    value: 30860,
    label: "Logan, UT-ID",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "017",
    value: 30900,
    label: "Logansport, IN",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "051",
    value: 30940,
    label: "London, KY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "183",
    value: 30980,
    label: "Longview, TX",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "015",
    value: 31020,
    label: "Longview, WA",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "028",
    value: 31060,
    label: "Los Alamos, NM",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "059",
    value: 31080,
    label: "Los Angeles-Long Beach-Anaheim, CA",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "019",
    value: 31140,
    label: "Louisville/Jefferson County, KY-IN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "107",
    value: 31180,
    label: "Lubbock, TX",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "105",
    value: 31220,
    label: "Ludington, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "005",
    value: 31260,
    label: "Lufkin, TX",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "155",
    value: 31300,
    label: "Lumberton, NC",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "009",
    value: 31340,
    label: "Lynchburg, VA",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "109",
    value: 31380,
    label: "Macomb, IL",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "021",
    value: 31420,
    label: "Macon-Bibb County, GA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "039",
    value: 31460,
    label: "Madera, CA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "077",
    value: 31500,
    label: "Madison, IN",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "021",
    value: 31540,
    label: "Madison, WI",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "107",
    value: 31580,
    label: "Madisonville, KY",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "027",
    value: 31620,
    label: "Magnolia, AR",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "033",
    value: 31660,
    label: "Malone, NY",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "059",
    value: 31680,
    label: "Malvern, AR",
  },
  {
    "FIPS State Code": "33",
    "FIPS County Code": "011",
    value: 31700,
    label: "Manchester-Nashua, NH",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "061",
    value: 31740,
    label: "Manhattan, KS",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "071",
    value: 31820,
    label: "Manitowoc, WI",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "013",
    value: 31860,
    label: "Mankato, MN",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "139",
    value: 31900,
    label: "Mansfield, OH",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "167",
    value: 31930,
    label: "Marietta, OH",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "109",
    value: 31940,
    label: "Marinette, WI-MI",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "053",
    value: 31980,
    label: "Marion, IN",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "111",
    value: 32000,
    label: "Marion, NC",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "101",
    value: 32020,
    label: "Marion, OH",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "103",
    value: 32100,
    label: "Marquette, MI",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "083",
    value: 32140,
    label: "Marshall, MN",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "195",
    value: 32180,
    label: "Marshall, MO",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "127",
    value: 32260,
    label: "Marshalltown, IA",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "183",
    value: 32280,
    label: "Martin, TN",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "089",
    value: 32300,
    label: "Martinsville, VA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "147",
    value: 32340,
    label: "Maryville, MO",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "033",
    value: 32380,
    label: "Mason City, IA",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "083",
    value: 32460,
    label: "Mayfield, KY",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "161",
    value: 32500,
    label: "Maysville, KY",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "121",
    value: 32540,
    label: "McAlester, OK",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "215",
    value: 32580,
    label: "McAllen-Edinburg-Mission, TX",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "113",
    value: 32620,
    label: "McComb, MS",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "177",
    value: 32660,
    label: "McMinnville, TN",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "113",
    value: 32700,
    label: "McPherson, KS",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "039",
    value: 32740,
    label: "Meadville, PA",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "029",
    value: 32780,
    label: "Medford, OR",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "035",
    value: 32820,
    label: "Memphis, TN-MS-AR",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "033",
    value: 32860,
    label: "Menomonie, WI",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "047",
    value: 32900,
    label: "Merced, CA",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "023",
    value: 32940,
    label: "Meridian, MS",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "007",
    value: 33020,
    label: "Mexico, MO",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "115",
    value: 33060,
    label: "Miami, OK",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "011",
    value: 33100,
    label: "Miami-Fort Lauderdale-Pompano Beach, FL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "091",
    value: 33140,
    label: "Michigan City-La Porte, IN",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "013",
    value: 33180,
    label: "Middlesborough, KY",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "111",
    value: 33220,
    label: "Midland, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "317",
    value: 33260,
    label: "Midland, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "009",
    value: 33300,
    label: "Milledgeville, GA",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "079",
    value: 33340,
    label: "Milwaukee-Waukesha, WI",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "119",
    value: 33380,
    label: "Minden, LA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "363",
    value: 33420,
    label: "Mineral Wells, TX",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "003",
    value: 33460,
    label: "Minneapolis-St. Paul-Bloomington, MN-WI",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "049",
    value: 33500,
    label: "Minot, ND",
  },
  {
    "FIPS State Code": "30",
    "FIPS County Code": "063",
    value: 33540,
    label: "Missoula, MT",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "035",
    value: 33580,
    label: "Mitchell, SD",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "175",
    value: 33620,
    label: "Moberly, MO",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "097",
    value: 33660,
    label: "Mobile, AL",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "099",
    value: 33700,
    label: "Modesto, CA",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "067",
    value: 33740,
    label: "Monroe, LA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "115",
    value: 33780,
    label: "Monroe, MI",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "001",
    value: 33860,
    label: "Montgomery, AL",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "085",
    value: 33940,
    label: "Montrose, CO",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "031",
    value: 33980,
    label: "Morehead City, NC",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "101",
    value: 34020,
    label: "Morgan City, LA",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "061",
    value: 34060,
    label: "Morgantown, WV",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "057",
    value: 34100,
    label: "Morristown, TN",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "057",
    value: 34140,
    label: "Moscow, ID",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "025",
    value: 34180,
    label: "Moses Lake, WA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "071",
    value: 34220,
    label: "Moultrie, GA",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "005",
    value: 34260,
    label: "Mountain Home, AR",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "039",
    value: 34300,
    label: "Mountain Home, ID",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "171",
    value: 34340,
    label: "Mount Airy, NC",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "045",
    value: 34350,
    label: "Mount Gay-Shamrock, WV",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "073",
    value: 34380,
    label: "Mount Pleasant, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "063",
    value: 34420,
    label: "Mount Pleasant, TX",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "011",
    value: 34460,
    label: "Mount Sterling, KY",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "081",
    value: 34500,
    label: "Mount Vernon, IL",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "083",
    value: 34540,
    label: "Mount Vernon, OH",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "057",
    value: 34580,
    label: "Mount Vernon-Anacortes, WA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "035",
    value: 34620,
    label: "Muncie, IN",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "035",
    value: 34660,
    label: "Murray, KY",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "139",
    value: 34700,
    label: "Muscatine, IA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "121",
    value: 34740,
    label: "Muskegon, MI",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "101",
    value: 34780,
    label: "Muskogee, OK",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "019",
    value: 34820,
    label: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "347",
    value: 34860,
    label: "Nacogdoches, TX",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "055",
    value: 34900,
    label: "Napa, CA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "021",
    value: 34940,
    label: "Naples-Marco Island, FL",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "015",
    value: 34980,
    label: "Nashville-Davidson--Murfreesboro--Franklin, TN",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "029",
    value: 35020,
    label: "Natchez, MS-LA",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "069",
    value: 35060,
    label: "Natchitoches, LA",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "049",
    value: 35100,
    label: "New Bern, NC",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "071",
    value: 35140,
    label: "Newberry, SC",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "065",
    value: 35220,
    label: "New Castle, IN",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "073",
    value: 35260,
    label: "New Castle, PA",
  },
  {
    "FIPS State Code": "09",
    "FIPS County Code": "009",
    value: 35300,
    label: "New Haven-Milford, CT",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "051",
    value: 35380,
    label: "New Orleans-Metairie, LA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "157",
    value: 35420,
    label: "New Philadelphia-Dover, OH",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "041",
    value: 35440,
    label: "Newport, OR",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "029",
    value: 35460,
    label: "Newport, TN",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "015",
    value: 35580,
    label: "New Ulm, MN",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "059",
    value: 35620,
    label: "New York-Newark-Jersey City, NY-NJ-PA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "021",
    value: 35660,
    label: "Niles, MI",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "023",
    value: 35700,
    label: "Nogales, AZ",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "119",
    value: 35740,
    label: "Norfolk, NE",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "111",
    value: 35820,
    label: "North Platte, NE",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "081",
    value: 35840,
    label: "North Port-Sarasota-Bradenton, FL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "079",
    value: 35860,
    label: "North Vernon, IN",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "193",
    value: 35900,
    label: "North Wilkesboro, NC",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "077",
    value: 35940,
    label: "Norwalk, OH",
  },
  {
    "FIPS State Code": "09",
    "FIPS County Code": "011",
    value: 35980,
    label: "Norwich-New London, CT",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "029",
    value: 36020,
    label: "Oak Harbor, WA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "083",
    value: 36100,
    label: "Ocala, FL",
  },
  {
    "FIPS State Code": "34",
    "FIPS County Code": "009",
    value: 36140,
    label: "Ocean City, NJ",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "135",
    value: 36220,
    label: "Odessa, TX",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "003",
    value: 36260,
    label: "Ogden-Clearfield, UT",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "089",
    value: 36300,
    label: "Ogdensburg-Massena, NY",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "121",
    value: 36340,
    label: "Oil City, PA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "093",
    value: 36380,
    label: "Okeechobee, FL",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "017",
    value: 36420,
    label: "Oklahoma City, OK",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "009",
    value: 36460,
    label: "Olean, NY",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "067",
    value: 36500,
    label: "Olympia-Lacey-Tumwater, WA",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "085",
    value: 36540,
    label: "Omaha-Council Bluffs, NE-IA",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "077",
    value: 36580,
    label: "Oneonta, NY",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "075",
    value: 36620,
    label: "Ontario, OR-ID",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "097",
    value: 36660,
    label: "Opelousas, LA",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "075",
    value: 36700,
    label: "Orangeburg, SC",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "069",
    value: 36740,
    label: "Orlando-Kissimmee-Sanford, FL",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "139",
    value: 36780,
    label: "Oshkosh-Neenah, WI",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "123",
    value: 36820,
    label: "Oskaloosa, IA",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "001",
    value: 36830,
    label: "Othello, WA",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "011",
    value: 36837,
    label: "Ottawa, IL",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "059",
    value: 36840,
    label: "Ottawa, KS",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "179",
    value: 36900,
    label: "Ottumwa, IA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "147",
    value: 36940,
    label: "Owatonna, MN",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "059",
    value: 36980,
    label: "Owensboro, KY",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "071",
    value: 37060,
    label: "Oxford, MS",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "111",
    value: 37100,
    label: "Oxnard-Thousand Oaks-Ventura, CA",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "045",
    value: 37120,
    label: "Ozark, AL",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "127",
    value: 37140,
    label: "Paducah, KY-IL",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "023",
    value: 37220,
    label: "Pahrump, NV",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "107",
    value: 37260,
    label: "Palatka, FL",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "001",
    value: 37300,
    label: "Palestine, TX",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "009",
    value: 37340,
    label: "Palm Bay-Melbourne-Titusville, FL",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "179",
    value: 37420,
    label: "Pampa, TX",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "005",
    value: 37460,
    label: "Panama City, FL",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "055",
    value: 37500,
    label: "Paragould, AR",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "079",
    value: 37540,
    label: "Paris, TN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "277",
    value: 37580,
    label: "Paris, TX",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "105",
    value: 37620,
    label: "Parkersburg-Vienna, WV",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "099",
    value: 37660,
    label: "Parsons, KS",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "007",
    value: 37740,
    label: "Payson, AZ",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "163",
    value: 37770,
    label: "Pearsall, TX",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "301",
    value: 37780,
    label: "Pecos, TX",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "125",
    value: 37800,
    label: "Pella, IA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "033",
    value: 37860,
    label: "Pensacola-Ferry Pass-Brent, FL",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "057",
    value: 37900,
    label: "Peoria, IL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "103",
    value: 37940,
    label: "Peru, IN",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "005",
    value: 37980,
    label: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "013",
    value: 38060,
    label: "Phoenix-Mesa-Chandler, AZ",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "109",
    value: 38100,
    label: "Picayune, MS",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "065",
    value: 38180,
    label: "Pierre, SD",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "025",
    value: 38220,
    label: "Pine Bluff, AR",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "125",
    value: 38240,
    label: "Pinehurst-Southern Pines, NC",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "037",
    value: 38260,
    label: "Pittsburg, KS",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "003",
    value: 38300,
    label: "Pittsburgh, PA",
  },
  {
    "FIPS State Code": "25",
    "FIPS County Code": "003",
    value: 38340,
    label: "Pittsfield, MA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "189",
    value: 38380,
    label: "Plainview, TX",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "043",
    value: 38420,
    label: "Platteville, WI",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "019",
    value: 38460,
    label: "Plattsburgh, NY",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "099",
    value: 38500,
    label: "Plymouth, IN",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "005",
    value: 38540,
    label: "Pocatello, ID",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "053",
    value: 38580,
    label: "Point Pleasant, WV-OH",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "071",
    value: 38620,
    label: "Ponca City, OK",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "105",
    value: 38700,
    label: "Pontiac, IL",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "023",
    value: 38740,
    label: "Poplar Bluff, MO",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "041",
    value: 38780,
    label: "Portales, NM",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "009",
    value: 38820,
    label: "Port Angeles, WA",
  },
  {
    "FIPS State Code": "23",
    "FIPS County Code": "005",
    value: 38860,
    label: "Portland-South Portland, ME",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "005",
    value: 38900,
    label: "Portland-Vancouver-Hillsboro, OR-WA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "057",
    value: 38920,
    label: "Port Lavaca, TX",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "085",
    value: 38940,
    label: "Port St. Lucie, FL",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "145",
    value: 39020,
    label: "Portsmouth, OH",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "107",
    value: 39060,
    label: "Pottsville, PA",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "027",
    value: 39100,
    label: "Poughkeepsie-Newburgh-Middletown, NY",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "025",
    value: 39150,
    label: "Prescott Valley-Prescott, AZ",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "007",
    value: 39220,
    label: "Price, UT",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "013",
    value: 39260,
    label: "Prineville, OR",
  },
  {
    "FIPS State Code": "44",
    "FIPS County Code": "005",
    value: 39300,
    label: "Providence-Warwick, RI-MA",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "023",
    value: 39340,
    label: "Provo-Orem, UT",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "101",
    value: 39380,
    label: "Pueblo, CO",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "075",
    value: 39420,
    label: "Pullman, WA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "015",
    value: 39460,
    label: "Punta Gorda, FL",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "001",
    value: 39500,
    label: "Quincy, IL-MO",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "101",
    value: 39540,
    label: "Racine, WI",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "069",
    value: 39580,
    label: "Raleigh-Cary, NC",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "093",
    value: 39660,
    label: "Rapid City, SD",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "489",
    value: 39700,
    label: "Raymondville, TX",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "011",
    value: 39740,
    label: "Reading, PA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "103",
    value: 39780,
    label: "Red Bluff, CA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "089",
    value: 39820,
    label: "Redding, CA",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "049",
    value: 39860,
    label: "Red Wing, MN",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "029",
    value: 39900,
    label: "Reno, NV",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "043",
    value: 39940,
    label: "Rexburg, ID",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "177",
    value: 39980,
    label: "Richmond, IN",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "007",
    value: 40060,
    label: "Richmond, VA",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "065",
    value: 40080,
    label: "Richmond-Berea, KY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "427",
    value: 40100,
    label: "Rio Grande City-Roma, TX",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "065",
    value: 40140,
    label: "Riverside-San Bernardino-Ontario, CA",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "013",
    value: 40180,
    label: "Riverton, WY",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "023",
    value: 40220,
    label: "Roanoke, VA",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "083",
    value: 40260,
    label: "Roanoke Rapids, NC",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "141",
    value: 40300,
    label: "Rochelle, IL",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "039",
    value: 40340,
    label: "Rochester, MN",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "051",
    value: 40380,
    label: "Rochester, NY",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "007",
    value: 40420,
    label: "Rockford, IL",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "153",
    value: 40460,
    label: "Rockingham, NC",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "007",
    value: 40530,
    label: "Rockport, TX",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "037",
    value: 40540,
    label: "Rock Springs, WY",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "065",
    value: 40580,
    label: "Rocky Mount, NC",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "161",
    value: 40620,
    label: "Rolla, MO",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "115",
    value: 40660,
    label: "Rome, GA",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "019",
    value: 40700,
    label: "Roseburg, OR",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "005",
    value: 40740,
    label: "Roswell, NM",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "027",
    value: 40760,
    label: "Ruidoso, NM",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "115",
    value: 40780,
    label: "Russellville, AR",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "061",
    value: 40820,
    label: "Ruston, LA",
  },
  {
    "FIPS State Code": "50",
    "FIPS County Code": "021",
    value: 40860,
    label: "Rutland, VT",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "017",
    value: 40900,
    label: "Sacramento-Roseville-Folsom, CA",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "009",
    value: 40940,
    label: "Safford, AZ",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "145",
    value: 40980,
    label: "Saginaw, MI",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "009",
    value: 41060,
    label: "St. Cloud, MN",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "053",
    value: 41100,
    label: "St. George, UT",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "043",
    value: 41140,
    label: "St. Joseph, MO-KS",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "005",
    value: 41180,
    label: "St. Louis, MO-IL",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "039",
    value: 41220,
    label: "St. Marys, GA",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "047",
    value: 41260,
    label: "St. Marys, PA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "029",
    value: 41400,
    label: "Salem, OH",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "047",
    value: 41420,
    label: "Salem, OR",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "143",
    value: 41460,
    label: "Salina, KS",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "053",
    value: 41500,
    label: "Salinas, CA",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "005",
    value: 41540,
    label: "Salisbury, MD-DE",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "035",
    value: 41620,
    label: "Salt Lake City, UT",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "235",
    value: 41660,
    label: "San Angelo, TX",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "013",
    value: 41700,
    label: "San Antonio-New Braunfels, TX",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "073",
    value: 41740,
    label: "San Diego-Chula Vista-Carlsbad, CA",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "017",
    value: 41760,
    label: "Sandpoint, ID",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "043",
    value: 41780,
    label: "Sandusky, OH",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "105",
    value: 41820,
    label: "Sanford, NC",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "001",
    value: 41860,
    label: "San Francisco-Oakland-Berkeley, CA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "069",
    value: 41940,
    label: "San Jose-Sunnyvale-Santa Clara, CA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "079",
    value: 42020,
    label: "San Luis Obispo-Paso Robles, CA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "087",
    value: 42100,
    label: "Santa Cruz-Watsonville, CA",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "049",
    value: 42140,
    label: "Santa Fe, NM",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "083",
    value: 42200,
    label: "Santa Maria-Santa Barbara, CA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "097",
    value: 42220,
    label: "Santa Rosa-Petaluma, CA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "033",
    value: 42300,
    label: "Sault Ste. Marie, MI",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "029",
    value: 42340,
    label: "Savannah, GA",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "015",
    value: 42380,
    label: "Sayre, PA",
  },
  {
    "FIPS State Code": "31",
    "FIPS County Code": "007",
    value: 42420,
    label: "Scottsbluff, NE",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "071",
    value: 42460,
    label: "Scottsboro, AL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "143",
    value: 42500,
    label: "Scottsburg, IN",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "069",
    value: 42540,
    label: "Scranton--Wilkes-Barre, PA",
  },
  {
    "FIPS State Code": "05",
    "FIPS County Code": "145",
    value: 42620,
    label: "Searcy, AR",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "033",
    value: 42660,
    label: "Seattle-Tacoma-Bellevue, WA",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "061",
    value: 42680,
    label: "Sebastian-Vero Beach, FL",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "055",
    value: 42700,
    label: "Sebring-Avon Park, FL",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "159",
    value: 42740,
    label: "Sedalia, MO",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "109",
    value: 42780,
    label: "Selinsgrove, PA",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "047",
    value: 42820,
    label: "Selma, AL",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "073",
    value: 42860,
    label: "Seneca, SC",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "099",
    value: 42900,
    label: "Seneca Falls, NY",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "155",
    value: 42940,
    label: "Sevierville, TN",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "071",
    value: 42980,
    label: "Seymour, IN",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "078",
    value: 43020,
    label: "Shawano, WI",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "125",
    value: 43060,
    label: "Shawnee, OK",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "117",
    value: 43100,
    label: "Sheboygan, WI",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "045",
    value: 43140,
    label: "Shelby, NC",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "003",
    value: 43180,
    label: "Shelbyville, TN",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "045",
    value: 43220,
    label: "Shelton, WA",
  },
  {
    "FIPS State Code": "56",
    "FIPS County Code": "033",
    value: 43260,
    label: "Sheridan, WY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "181",
    value: 43300,
    label: "Sherman-Denison, TX",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "017",
    value: 43320,
    label: "Show Low, AZ",
  },
  {
    "FIPS State Code": "22",
    "FIPS County Code": "015",
    value: 43340,
    label: "Shreveport-Bossier City, LA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "149",
    value: 43380,
    label: "Sidney, OH",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "003",
    value: 43420,
    label: "Sierra Vista-Douglas, AZ",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "201",
    value: 43460,
    label: "Sikeston, MO",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "017",
    value: 43500,
    label: "Silver City, NM",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "193",
    value: 43580,
    label: "Sioux City, IA-NE-SD",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "083",
    value: 43620,
    label: "Sioux Falls, SD",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "415",
    value: 43660,
    label: "Snyder, TX",
  },
  {
    "FIPS State Code": "21",
    "FIPS County Code": "199",
    value: 43700,
    label: "Somerset, KY",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "111",
    value: 43740,
    label: "Somerset, PA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "109",
    value: 43760,
    label: "Sonora, CA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "141",
    value: 43780,
    label: "South Bend-Mishawaka, IN-MI",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "083",
    value: 43900,
    label: "Spartanburg, SC",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "081",
    value: 43940,
    label: "Spearfish, SD",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "041",
    value: 43980,
    label: "Spencer, IA",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "059",
    value: 44020,
    label: "Spirit Lake, IA",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "063",
    value: 44060,
    label: "Spokane-Spokane Valley, WA",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "129",
    value: 44100,
    label: "Springfield, IL",
  },
  {
    "FIPS State Code": "25",
    "FIPS County Code": "011",
    value: 44140,
    label: "Springfield, MA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "043",
    value: 44180,
    label: "Springfield, MO",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "023",
    value: 44220,
    label: "Springfield, OH",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "105",
    value: 44260,
    label: "Starkville, MS",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "027",
    value: 44300,
    label: "State College, PA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "031",
    value: 44340,
    label: "Statesboro, GA",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "015",
    value: 44420,
    label: "Staunton, VA",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "107",
    value: 44460,
    label: "Steamboat Springs, CO",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "143",
    value: 44500,
    label: "Stephenville, TX",
  },
  {
    "FIPS State Code": "08",
    "FIPS County Code": "075",
    value: 44540,
    label: "Sterling, CO",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "195",
    value: 44580,
    label: "Sterling, IL",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "097",
    value: 44620,
    label: "Stevens Point, WI",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "119",
    value: 44660,
    label: "Stillwater, OK",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "077",
    value: 44700,
    label: "Stockton, CA",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "021",
    value: 44740,
    label: "Storm Lake, IA",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "149",
    value: 44780,
    label: "Sturgis, MI",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "223",
    value: 44860,
    label: "Sulphur Springs, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "055",
    value: 44900,
    label: "Summerville, GA",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "027",
    value: 44940,
    label: "Sumter, SC",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "097",
    value: 44980,
    label: "Sunbury, PA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "035",
    value: 45000,
    label: "Susanville, CA",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "353",
    value: 45020,
    label: "Sweetwater, TX",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "053",
    value: 45060,
    label: "Syracuse, NY",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "021",
    value: 45140,
    label: "Tahlequah, OK",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "121",
    value: 45180,
    label: "Talladega-Sylacauga, AL",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "039",
    value: 45220,
    label: "Tallahassee, FL",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "053",
    value: 45300,
    label: "Tampa-St. Petersburg-Clearwater, FL",
  },
  {
    "FIPS State Code": "35",
    "FIPS County Code": "055",
    value: 45340,
    label: "Taos, NM",
  },
  {
    "FIPS State Code": "17",
    "FIPS County Code": "021",
    value: 45380,
    label: "Taylorville, IL",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "021",
    value: 45460,
    label: "Terre Haute, IN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "081",
    value: 45500,
    label: "Texarkana, TX-AR",
  },
  {
    "FIPS State Code": "41",
    "FIPS County Code": "065",
    value: 45520,
    label: "The Dalles, OR",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "119",
    value: 45540,
    label: "The Villages, FL",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "293",
    value: 45580,
    label: "Thomaston, GA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "275",
    value: 45620,
    label: "Thomasville, GA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "147",
    value: 45660,
    label: "Tiffin, OH",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "277",
    value: 45700,
    label: "Tifton, GA",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "257",
    value: 45740,
    label: "Toccoa, GA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "051",
    value: 45780,
    label: "Toledo, OH",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "085",
    value: 45820,
    label: "Topeka, KS",
  },
  {
    "FIPS State Code": "09",
    "FIPS County Code": "005",
    value: 45860,
    label: "Torrington, CT",
  },
  {
    "FIPS State Code": "26",
    "FIPS County Code": "019",
    value: 45900,
    label: "Traverse City, MI",
  },
  {
    "FIPS State Code": "34",
    "FIPS County Code": "021",
    value: 45940,
    label: "Trenton-Princeton, NJ",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "109",
    value: 45980,
    label: "Troy, AL",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "057",
    value: 46020,
    label: "Truckee-Grass Valley, CA",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "019",
    value: 46060,
    label: "Tucson, AZ",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "031",
    value: 46100,
    label: "Tullahoma-Manchester, TN",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "037",
    value: 46140,
    label: "Tulsa, OK",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "057",
    value: 46180,
    label: "Tupelo, MS",
  },
  {
    "FIPS State Code": "01",
    "FIPS County Code": "063",
    value: 46220,
    label: "Tuscaloosa, AL",
  },
  {
    "FIPS State Code": "16",
    "FIPS County Code": "053",
    value: 46300,
    label: "Twin Falls, ID",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "423",
    value: 46340,
    label: "Tyler, TX",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "045",
    value: 46380,
    label: "Ukiah, CA",
  },
  {
    "FIPS State Code": "45",
    "FIPS County Code": "087",
    value: 46420,
    label: "Union, SC",
  },
  {
    "FIPS State Code": "47",
    "FIPS County Code": "131",
    value: 46460,
    label: "Union City, TN",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "021",
    value: 46500,
    label: "Urbana, OH",
  },
  {
    "FIPS State Code": "15",
    "FIPS County Code": "003",
    value: 46520,
    label: "Urban Honolulu, HI",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "043",
    value: 46540,
    label: "Utica-Rome, NY",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "463",
    value: 46620,
    label: "Uvalde, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "027",
    value: 46660,
    label: "Valdosta, GA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "095",
    value: 46700,
    label: "Vallejo, CA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "161",
    value: 46780,
    label: "Van Wert, OH",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "027",
    value: 46820,
    label: "Vermillion, SD",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "047",
    value: 46860,
    label: "Vernal, UT",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "487",
    value: 46900,
    label: "Vernon, TX",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "149",
    value: 46980,
    label: "Vicksburg, MS",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "175",
    value: 47020,
    label: "Victoria, TX",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "209",
    value: 47080,
    label: "Vidalia, GA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "083",
    value: 47180,
    label: "Vincennes, IN",
  },
  {
    "FIPS State Code": "34",
    "FIPS County Code": "011",
    value: 47220,
    label: "Vineland-Bridgeton, NJ",
  },
  {
    "FIPS State Code": "25",
    "FIPS County Code": "007",
    value: 47240,
    label: "Vineyard Haven, MA",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "029",
    value: 47260,
    label: "Virginia Beach-Norfolk-Newport News, VA-NC",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "107",
    value: 47300,
    label: "Visalia, CA",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "169",
    value: 47340,
    label: "Wabash, IN",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "145",
    value: 47380,
    label: "Waco, TX",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "167",
    value: 47420,
    label: "Wahpeton, ND-MN",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "071",
    value: 47460,
    label: "Walla Walla, WA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "011",
    value: 47540,
    label: "Wapakoneta, OH",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "153",
    value: 47580,
    label: "Warner Robins, GA",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "123",
    value: 47620,
    label: "Warren, PA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "101",
    value: 47660,
    label: "Warrensburg, MO",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "085",
    value: 47700,
    label: "Warsaw, IN",
  },
  {
    "FIPS State Code": "18",
    "FIPS County Code": "027",
    value: 47780,
    label: "Washington, IN",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "013",
    value: 47820,
    label: "Washington, NC",
  },
  {
    "FIPS State Code": "24",
    "FIPS County Code": "021",
    value: 47900,
    label: "Washington-Arlington-Alexandria, DC-VA-MD-WV",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "047",
    value: 47920,
    label: "Washington Court House, OH",
  },
  {
    "FIPS State Code": "19",
    "FIPS County Code": "013",
    value: 47940,
    label: "Waterloo-Cedar Falls, IA",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "029",
    value: 47980,
    label: "Watertown, SD",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "055",
    value: 48020,
    label: "Watertown-Fort Atkinson, WI",
  },
  {
    "FIPS State Code": "36",
    "FIPS County Code": "045",
    value: 48060,
    label: "Watertown-Fort Drum, NY",
  },
  {
    "FIPS State Code": "12",
    "FIPS County Code": "049",
    value: 48100,
    label: "Wauchula, FL",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "069",
    value: 48140,
    label: "Wausau-Weston, WI",
  },
  {
    "FIPS State Code": "13",
    "FIPS County Code": "229",
    value: 48180,
    label: "Waycross, GA",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "039",
    value: 48220,
    label: "Weatherford, OK",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "081",
    value: 48260,
    label: "Weirton-Steubenville, WV-OH",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "007",
    value: 48300,
    label: "Wenatchee, WA",
  },
  {
    "FIPS State Code": "29",
    "FIPS County Code": "091",
    value: 48460,
    label: "West Plains, MO",
  },
  {
    "FIPS State Code": "28",
    "FIPS County Code": "025",
    value: 48500,
    label: "West Point, MS",
  },
  {
    "FIPS State Code": "54",
    "FIPS County Code": "013",
    value: 48540,
    label: "Wheeling, WV-OH",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "127",
    value: 48580,
    label: "Whitewater, WI",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "015",
    value: 48620,
    label: "Wichita, KS",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "009",
    value: 48660,
    label: "Wichita Falls, TX",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "081",
    value: 48700,
    label: "Williamsport, PA",
  },
  {
    "FIPS State Code": "38",
    "FIPS County Code": "105",
    value: 48780,
    label: "Williston, ND",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "067",
    value: 48820,
    label: "Willmar, MN",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "129",
    value: 48900,
    label: "Wilmington, NC",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "027",
    value: 48940,
    label: "Wilmington, OH",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "195",
    value: 48980,
    label: "Wilson, NC",
  },
  {
    "FIPS State Code": "51",
    "FIPS County Code": "069",
    value: 49020,
    label: "Winchester, VA-WV",
  },
  {
    "FIPS State Code": "20",
    "FIPS County Code": "035",
    value: 49060,
    label: "Winfield, KS",
  },
  {
    "FIPS State Code": "32",
    "FIPS County Code": "013",
    value: 49080,
    label: "Winnemucca, NV",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "169",
    value: 49100,
    label: "Winona, MN",
  },
  {
    "FIPS State Code": "37",
    "FIPS County Code": "057",
    value: 49180,
    label: "Winston-Salem, NC",
  },
  {
    "FIPS State Code": "55",
    "FIPS County Code": "141",
    value: 49220,
    label: "Wisconsin Rapids-Marshfield, WI",
  },
  {
    "FIPS State Code": "40",
    "FIPS County Code": "045",
    value: 49260,
    label: "Woodward, OK",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "169",
    value: 49300,
    label: "Wooster, OH",
  },
  {
    "FIPS State Code": "25",
    "FIPS County Code": "015",
    value: 49340,
    label: "Worcester, MA-CT",
  },
  {
    "FIPS State Code": "27",
    "FIPS County Code": "105",
    value: 49380,
    label: "Worthington, MN",
  },
  {
    "FIPS State Code": "53",
    "FIPS County Code": "077",
    value: 49420,
    label: "Yakima, WA",
  },
  {
    "FIPS State Code": "46",
    "FIPS County Code": "135",
    value: 49460,
    label: "Yankton, SD",
  },
  {
    "FIPS State Code": "42",
    "FIPS County Code": "133",
    value: 49620,
    label: "York-Hanover, PA",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "099",
    value: 49660,
    label: "Youngstown-Warren-Boardman, OH-PA",
  },
  {
    "FIPS State Code": "06",
    "FIPS County Code": "101",
    value: 49700,
    label: "Yuba City, CA",
  },
  {
    "FIPS State Code": "04",
    "FIPS County Code": "027",
    value: 49740,
    label: "Yuma, AZ",
  },
  {
    "FIPS State Code": "39",
    "FIPS County Code": "119",
    value: 49780,
    label: "Zanesville, OH",
  },
  {
    "FIPS State Code": "48",
    "FIPS County Code": "505",
    value: 49820,
    label: "Zapata, TX",
  },
  {
    "FIPS State Code": "49",
    "FIPS County Code": "43",
    value: 44920,
    label: "Summit Park, UT",
  },
];
