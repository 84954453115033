import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Plan, PlanCycle } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { useUserData } from "hooks/useUser";
import { currencyFormatter } from "lib/currencyFormatter";
import { getIconForCardType } from "lib/getIconForCardType";
import { getRelativePathname } from "lib/getRelativePathname";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import PlanService from "services/PlanService";
import Stripe from "stripe";
import { EditCard } from "./EditCard";
import { UpgradeSuccessfulModal } from "./UpgradeSuccessfulModal";
import { useDiscountCode } from "hooks/useDiscount";
import { DiscountCodeField } from "components/DiscountCodeField";
import { CardUpdateContext } from "lib/context";

export const RenewPlanModal = () => {
  const router = useRouter();
  const theme = useTheme();
  const user = useUserData();
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { plans } = useContext(PlanModalContext);
  const [upcommingInvoice, setUpcommingInvoice] =
    useState<Stripe.UpcomingInvoice | null>(null);
  const [previousDiscount, setPreviousDiscount] = useState(0);
  const { cardUpdating, setCardUpdating } = useContext(CardUpdateContext);

  const fetchUpcoming = async () => {
    if (user?.stripeCustomerId) {
      try {
        const invoice = await PlanService.getUpcomingInvoice(
          user?.stripeCustomerId,
        );
        setUpcommingInvoice(invoice.data);
        setPreviousDiscount(
          invoice ? invoice.data.subtotal - invoice.data.total : 0,
        );
        if (invoice.data.subtotal == 0) {
          toast.warning("No Upcoming Invoice");
          handleGoBack();
        }
      } catch (error) {
        handleGoBack();
        setUpcommingInvoice(null);
        setPreviousDiscount(0);
      }
    }
  };

  useEffect(() => {
    if (router.query.openRenewModal) {
      fetchUpcoming();
    }
  }, [router.query.openRenewModal, user?.stripeCustomerId]);

  const plan = useMemo(() => {
    return plans.find((pln) => pln.id === router.query.selectedPlanId);
  }, [router.query.selectedPlanId, plans]);

  const {
    discount,
    discountCode,
    handleDiscountCodeChange,
    isValidDiscount,
    loading: checkingDiscountValidity,
  } = useDiscountCode({ productId: plan?.productId || "" });

  const renew = async () => {
    setLoading(true);
    if (!isValidDiscount) {
      handleDiscountCodeChange("");
    }
    if (user?.uid && user?.stripeSubscriptionId) {
      try {
        const res = await PlanService.renewEarly(
          user?.stripeSubscriptionId,
          isValidDiscount ? discountCode : "",
          user?.uid,
        );
        router.replace(
          `${getRelativePathname(
            router,
          )}?upgradeStatus=success&amountPaid=${-res.data.amount}`,
        );
        toast.success("Subscription renewed");
      } catch (error) {
        toast.error((error as any)?.response?.data?.data);
      }
      setLoading(false);
    }
  };

  const handleGoBack = async () => {
    setUpcommingInvoice(null);
    router.replace(`${getRelativePathname(router)}`);
  };

  const discountAmount = discount?.coupon.percent_off
    ? ((plan?.amount || 0) * (discount?.coupon.percent_off! || 0)) / 100
    : upcommingInvoice && previousDiscount > 0
    ? previousDiscount
    : 0;

  return (
    <>
      <UpgradeSuccessfulModal />
      <Modal
        isOpen={!!router.query.openRenewModal}
        onAfterClose={() => handleDiscountCodeChange("")}
        contentStyle={{
          maxWidth: 330,
          background: "white",
          width: "100%",
          padding: 0,
        }}
      >
        <EditCard
          onClose={() => {
            setShowEditModal(false);
          }}
          setCardUpdating={setCardUpdating}
          showEditModal={showEditModal}
          user={user}
        />
        <Flex
          justify="space-between"
          css={{ padding: "14px 22px" }}
          align="center"
        >
          <Flex align="center" gap={10}>
            <Text tagName="h1" fontSize="large" bolder>
              Payment Details
            </Text>
          </Flex>
          <Flex
            justify="center"
            align="center"
            css={{
              borderRadius: theme.radii.smaller,
              background: theme.colors.lightBg,
              height: 30,
              width: 30,
              cursor: "pointer",
            }}
            onClick={() => {
              let relativeURL;
              const slug = router.query.slug;
              if (slug) {
                relativeURL = router.pathname.replace("[slug]", slug as string);
              } else {
                relativeURL = router.pathname;
              }
              router.replace(`${relativeURL}`);
            }}
          >
            <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
          </Flex>
        </Flex>
        <div
          css={{
            borderTop: `1px solid ${theme.colors.radioBorder}`,
            width: "100%",
          }}
        />
        <div css={{ padding: "14px 22px" }}>
          <div
            css={{
              border: `1px solid ${theme.colors.radioBorder}`,
              width: "100%",
              padding: "9px 11px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: theme.radii.smaller,
            }}
          >
            {cardUpdating !== user?.paymentMethod?.last4 ? (
              <>
                <Flex gap={18} align="center">
                  <Image
                    alt="visa"
                    src={getIconForCardType(user.paymentMethod?.brand || "")}
                    width={40}
                    height={40}
                  />
                  <Flex gap={1} direction="column">
                    <Text>**** **** **** {user?.paymentMethod?.last4}</Text>
                    <Text>
                      {user.paymentMethod?.brand?.replace(
                        user.paymentMethod?.brand?.[0],
                        user.paymentMethod?.brand?.[0]?.toUpperCase(),
                      )}
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  css={{
                    border: `1px solid ${theme.colors.radioBorder}`,
                    padding: "6px 12px",
                    background: "white",
                    borderRadius: theme.radii.smaller,
                    fontSize: theme.fontSizes.default,
                  }}
                  onClick={() => setShowEditModal(true)}
                >
                  Edit
                </Button>
              </>
            ) : (
              <Text style={{ fontStyle: "italic", color: theme.colors.text3 }}>
                Updating card info...
              </Text>
            )}
          </div>
          <DiscountCodeField
            labelGap={8}
            discount={discount}
            discountCode={discountCode}
            isValidDiscount={isValidDiscount}
            loading={checkingDiscountValidity}
            onDiscountChange={(e) => handleDiscountCodeChange(e.target.value)}
          />
          <div css={{ marginTop: 12 }}>
            <Flex justify="space-between">
              <Text css={{ opacity: 0.65 }}>Plan</Text>
              <Text>
                {plan?.name === Plan.Premium_Monthly
                  ? PlanCycle.Monthly
                  : plan?.name === Plan.Premium_Yearly
                  ? PlanCycle.Yearly
                  : plan?.name}
              </Text>
            </Flex>
            <Flex justify="space-between" css={{ marginTop: 5 }}>
              <Text css={{ opacity: 0.65 }}>Amount</Text>
              <Text>{currencyFormatter((plan?.amount || 0) / 100)}</Text>
            </Flex>
            {((discount && isValidDiscount) || previousDiscount > 0) && (
              <Flex justify="space-between" css={{ marginTop: 5 }}>
                <Text css={{ opacity: 0.65 }}>Discount</Text>
                <Text>{currencyFormatter(discountAmount / 100)}</Text>
              </Flex>
            )}
            <div
              css={{
                borderTop: `1px solid ${theme.colors.radioBorder}`,
                width: "100%",
                marginTop: 12,
              }}
            />
            <Flex justify="space-between" css={{ marginTop: 5 }}>
              <Text bolder>Total:</Text>
              <Text bolder>
                {currencyFormatter(
                  ((plan?.amount || 0) - discountAmount) / 100,
                )}
              </Text>
            </Flex>
          </div>
          <Button
            onClick={renew}
            disabled={!upcommingInvoice}
            css={{ width: "100%", marginTop: 14 }}
            variant="alternate"
          >
            {loading ? "Loading..." : "Pay Now"}
          </Button>
        </div>
      </Modal>
    </>
  );
};
