import { NextResponse } from "next/server";
import type { NextRequest } from "next/server";

export function middleware(request: NextRequest) {
  if (
    request.nextUrl.host === "blog.reventure.app" &&
    /^[^-/.]*-.*$/g.test(request.nextUrl.pathname.substring(1))
  ) {
    request.nextUrl.pathname = `/content${request.nextUrl.pathname}`;
    return NextResponse.redirect(request.nextUrl);
  }
}

export function shouldEventBeCapturedonSentry(event) {
  const exceptions = [
    "Style is not done loading",
    "undefined is not an object (evaluating 'ec.style.listImages')",
  ];
  if (event?.exception && event?.exception?.values) {
    const message = event.exception.values[0].value;
    const containsExceptionText = exceptions.some((ex) => message.includes(ex));
    return !containsExceptionText;
  }
}
