import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import PlanService from "services/PlanService";
import Stripe from "stripe";

export const useDiscountCode = ({ productId }: { productId: string }) => {
  const [discountCode, setDiscountCode] = useState("");
  const [isValidDiscount, setIsValidDiscount] = useState(false);
  const [discount, setDiscount] = useState<Stripe.PromotionCode | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsValidDiscount(
      !!discount?.coupon?.applies_to?.products?.includes(productId) ||
        !discount?.coupon?.applies_to,
    );
  }, [productId, discount]);

  const getDiscountCode = useCallback(
    debounce(async (value) => {
      try {
        const data = await PlanService.getDiscount(value);
        setDiscount(data);
      } catch (error: any) {
        setDiscount(null);
        setIsValidDiscount(false);
      } finally {
        setLoading(false);
      }
    }, 500),
    [],
  );

  const handleDiscountCodeChange = (value) => {
    setIsValidDiscount(false);
    setLoading(true);
    setDiscountCode(value);
    getDiscountCode(value);
  };

  return {
    discountCode,
    discount,
    isValidDiscount,
    loading,
    handleDiscountCodeChange,
  };
};
