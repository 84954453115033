import { CountyOptions } from "types/options";

export const countyList: CountyOptions[] = [
  {
    value: "01001",
    label: "Autauga County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01003",
    label: "Baldwin County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01005",
    label: "Barbour County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01007",
    label: "Bibb County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01009",
    label: "Blount County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01011",
    label: "Bullock County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01013",
    label: "Butler County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01015",
    label: "Calhoun County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01017",
    label: "Chambers County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01019",
    label: "Cherokee County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01021",
    label: "Chilton County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01023",
    label: "Choctaw County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01025",
    label: "Clarke County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01027",
    label: "Clay County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01029",
    label: "Cleburne County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01031",
    label: "Coffee County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01033",
    label: "Colbert County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01035",
    label: "Conecuh County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01037",
    label: "Coosa County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01039",
    label: "Covington County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01041",
    label: "Crenshaw County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01043",
    label: "Cullman County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01045",
    label: "Dale County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01047",
    label: "Dallas County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01049",
    label: "DeKalb County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01051",
    label: "Elmore County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01053",
    label: "Escambia County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01055",
    label: "Etowah County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01057",
    label: "Fayette County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01059",
    label: "Franklin County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01061",
    label: "Geneva County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01063",
    label: "Greene County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01065",
    label: "Hale County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01067",
    label: "Henry County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01069",
    label: "Houston County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01071",
    label: "Jackson County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01073",
    label: "Jefferson County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01075",
    label: "Lamar County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01077",
    label: "Lauderdale County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01079",
    label: "Lawrence County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01081",
    label: "Lee County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01083",
    label: "Limestone County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01085",
    label: "Lowndes County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01087",
    label: "Macon County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01089",
    label: "Madison County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01091",
    label: "Marengo County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01093",
    label: "Marion County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01095",
    label: "Marshall County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01097",
    label: "Mobile County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01099",
    label: "Monroe County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01101",
    label: "Montgomery County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01103",
    label: "Morgan County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01105",
    label: "Perry County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01107",
    label: "Pickens County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01109",
    label: "Pike County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01111",
    label: "Randolph County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01113",
    label: "Russell County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01115",
    label: "St. Clair County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01117",
    label: "Shelby County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01119",
    label: "Sumter County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01121",
    label: "Talladega County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01123",
    label: "Tallapoosa County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01125",
    label: "Tuscaloosa County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01127",
    label: "Walker County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01129",
    label: "Washington County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01131",
    label: "Wilcox County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "01133",
    label: "Winston County, Alabama",
    stateCode: "01",
    stateAbbr: "AL",
  },
  {
    value: "02013",
    label: "Aleutians East Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02016",
    label: "Aleutians West Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02020",
    label: "Anchorage Municipality, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02050",
    label: "Bethel Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02060",
    label: "Bristol Bay Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02063",
    label: "Chugach Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02066",
    label: "Copper River Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02068",
    label: "Denali Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02070",
    label: "Dillingham Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02090",
    label: "Fairbanks North Star Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02100",
    label: "Haines Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02105",
    label: "Hoonah-Angoon Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02110",
    label: "Juneau City and Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02122",
    label: "Kenai Peninsula Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02130",
    label: "Ketchikan Gateway Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02150",
    label: "Kodiak Island Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02158",
    label: "Kusilvak Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02164",
    label: "Lake and Peninsula Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02170",
    label: "Matanuska-Susitna Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02180",
    label: "Nome Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02185",
    label: "North Slope Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02188",
    label: "Northwest Arctic Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02195",
    label: "Petersburg Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02198",
    label: "Prince of Wales-Hyder Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02220",
    label: "Sitka City and Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02230",
    label: "Skagway Municipality, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02240",
    label: "Southeast Fairbanks Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02275",
    label: "Wrangell City and Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02282",
    label: "Yakutat City and Borough, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "02290",
    label: "Yukon-Koyukuk Census Area, Alaska",
    stateCode: "02",
    stateAbbr: "AK",
  },
  {
    value: "04001",
    label: "Apache County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04003",
    label: "Cochise County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04005",
    label: "Coconino County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04007",
    label: "Gila County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04009",
    label: "Graham County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04011",
    label: "Greenlee County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04012",
    label: "La Paz County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04013",
    label: "Maricopa County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04015",
    label: "Mohave County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04017",
    label: "Navajo County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04019",
    label: "Pima County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04021",
    label: "Pinal County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04023",
    label: "Santa Cruz County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04025",
    label: "Yavapai County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "04027",
    label: "Yuma County, Arizona",
    stateCode: "04",
    stateAbbr: "AZ",
  },
  {
    value: "05001",
    label: "Arkansas County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05003",
    label: "Ashley County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05005",
    label: "Baxter County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05007",
    label: "Benton County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05009",
    label: "Boone County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05011",
    label: "Bradley County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05013",
    label: "Calhoun County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05015",
    label: "Carroll County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05017",
    label: "Chicot County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05019",
    label: "Clark County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05021",
    label: "Clay County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05023",
    label: "Cleburne County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05025",
    label: "Cleveland County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05027",
    label: "Columbia County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05029",
    label: "Conway County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05031",
    label: "Craighead County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05033",
    label: "Crawford County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05035",
    label: "Crittenden County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05037",
    label: "Cross County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05039",
    label: "Dallas County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05041",
    label: "Desha County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05043",
    label: "Drew County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05045",
    label: "Faulkner County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05047",
    label: "Franklin County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05049",
    label: "Fulton County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05051",
    label: "Garland County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05053",
    label: "Grant County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05055",
    label: "Greene County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05057",
    label: "Hempstead County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05059",
    label: "Hot Spring County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05061",
    label: "Howard County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05063",
    label: "Independence County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05065",
    label: "Izard County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05067",
    label: "Jackson County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05069",
    label: "Jefferson County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05071",
    label: "Johnson County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05073",
    label: "Lafayette County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05075",
    label: "Lawrence County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05077",
    label: "Lee County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05079",
    label: "Lincoln County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05081",
    label: "Little River County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05083",
    label: "Logan County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05085",
    label: "Lonoke County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05087",
    label: "Madison County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05089",
    label: "Marion County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05091",
    label: "Miller County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05093",
    label: "Mississippi County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05095",
    label: "Monroe County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05097",
    label: "Montgomery County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05099",
    label: "Nevada County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05101",
    label: "Newton County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05103",
    label: "Ouachita County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05105",
    label: "Perry County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05107",
    label: "Phillips County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05109",
    label: "Pike County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05111",
    label: "Poinsett County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05113",
    label: "Polk County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05115",
    label: "Pope County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05117",
    label: "Prairie County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05119",
    label: "Pulaski County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05121",
    label: "Randolph County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05123",
    label: "St. Francis County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05125",
    label: "Saline County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05127",
    label: "Scott County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05129",
    label: "Searcy County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05131",
    label: "Sebastian County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05133",
    label: "Sevier County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05135",
    label: "Sharp County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05137",
    label: "Stone County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05139",
    label: "Union County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05141",
    label: "Van Buren County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05143",
    label: "Washington County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05145",
    label: "White County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05147",
    label: "Woodruff County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "05149",
    label: "Yell County, Arkansas",
    stateCode: "05",
    stateAbbr: "AR",
  },
  {
    value: "06001",
    label: "Alameda County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06003",
    label: "Alpine County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06005",
    label: "Amador County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06007",
    label: "Butte County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06009",
    label: "Calaveras County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06011",
    label: "Colusa County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06013",
    label: "Contra Costa County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06015",
    label: "Del Norte County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06017",
    label: "El Dorado County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06019",
    label: "Fresno County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06021",
    label: "Glenn County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06023",
    label: "Humboldt County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06025",
    label: "Imperial County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06027",
    label: "Inyo County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06029",
    label: "Kern County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06031",
    label: "Kings County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06033",
    label: "Lake County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06035",
    label: "Lassen County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06037",
    label: "Los Angeles County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06039",
    label: "Madera County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06041",
    label: "Marin County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06043",
    label: "Mariposa County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06045",
    label: "Mendocino County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06047",
    label: "Merced County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06049",
    label: "Modoc County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06051",
    label: "Mono County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06053",
    label: "Monterey County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06055",
    label: "Napa County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06057",
    label: "Nevada County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06059",
    label: "Orange County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06061",
    label: "Placer County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06063",
    label: "Plumas County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06065",
    label: "Riverside County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06067",
    label: "Sacramento County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06069",
    label: "San Benito County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06071",
    label: "San Bernardino County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06073",
    label: "San Diego County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06075",
    label: "San Francisco County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06077",
    label: "San Joaquin County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06079",
    label: "San Luis Obispo County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06081",
    label: "San Mateo County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06083",
    label: "Santa Barbara County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06085",
    label: "Santa Clara County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06087",
    label: "Santa Cruz County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06089",
    label: "Shasta County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06091",
    label: "Sierra County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06093",
    label: "Siskiyou County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06095",
    label: "Solano County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06097",
    label: "Sonoma County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06099",
    label: "Stanislaus County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06101",
    label: "Sutter County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06103",
    label: "Tehama County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06105",
    label: "Trinity County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06107",
    label: "Tulare County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06109",
    label: "Tuolumne County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06111",
    label: "Ventura County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06113",
    label: "Yolo County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "06115",
    label: "Yuba County, California",
    stateCode: "06",
    stateAbbr: "CA",
  },
  {
    value: "08001",
    label: "Adams County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08003",
    label: "Alamosa County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08005",
    label: "Arapahoe County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08007",
    label: "Archuleta County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08009",
    label: "Baca County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08011",
    label: "Bent County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08013",
    label: "Boulder County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08014",
    label: "Broomfield County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08015",
    label: "Chaffee County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08017",
    label: "Cheyenne County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08019",
    label: "Clear Creek County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08021",
    label: "Conejos County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08023",
    label: "Costilla County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08025",
    label: "Crowley County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08027",
    label: "Custer County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08029",
    label: "Delta County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08031",
    label: "Denver County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08033",
    label: "Dolores County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08035",
    label: "Douglas County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08037",
    label: "Eagle County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08039",
    label: "Elbert County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08041",
    label: "El Paso County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08043",
    label: "Fremont County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08045",
    label: "Garfield County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08047",
    label: "Gilpin County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08049",
    label: "Grand County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08051",
    label: "Gunnison County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08053",
    label: "Hinsdale County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08055",
    label: "Huerfano County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08057",
    label: "Jackson County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08059",
    label: "Jefferson County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08061",
    label: "Kiowa County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08063",
    label: "Kit Carson County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08065",
    label: "Lake County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08067",
    label: "La Plata County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08069",
    label: "Larimer County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08071",
    label: "Las Animas County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08073",
    label: "Lincoln County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08075",
    label: "Logan County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08077",
    label: "Mesa County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08079",
    label: "Mineral County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08081",
    label: "Moffat County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08083",
    label: "Montezuma County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08085",
    label: "Montrose County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08087",
    label: "Morgan County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08089",
    label: "Otero County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08091",
    label: "Ouray County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08093",
    label: "Park County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08095",
    label: "Phillips County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08097",
    label: "Pitkin County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08099",
    label: "Prowers County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08101",
    label: "Pueblo County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08103",
    label: "Rio Blanco County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08105",
    label: "Rio Grande County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08107",
    label: "Routt County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08109",
    label: "Saguache County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08111",
    label: "San Juan County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08113",
    label: "San Miguel County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08115",
    label: "Sedgwick County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08117",
    label: "Summit County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08119",
    label: "Teller County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08121",
    label: "Washington County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08123",
    label: "Weld County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "08125",
    label: "Yuma County, Colorado",
    stateCode: "08",
    stateAbbr: "CO",
  },
  {
    value: "09001",
    label: "Fairfield County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09003",
    label: "Hartford County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09005",
    label: "Litchfield County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09007",
    label: "Middlesex County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09009",
    label: "New Haven County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09011",
    label: "New London County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09013",
    label: "Tolland County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "09015",
    label: "Windham County, Connecticut",
    stateCode: "09",
    stateAbbr: "CT",
  },
  {
    value: "10001",
    label: "Kent County, Delaware",
    stateCode: "10",
    stateAbbr: "DE",
  },
  {
    value: "10003",
    label: "New Castle County, Delaware",
    stateCode: "10",
    stateAbbr: "DE",
  },
  {
    value: "10005",
    label: "Sussex County, Delaware",
    stateCode: "10",
    stateAbbr: "DE",
  },
  {
    value: "11001",
    label: "District of Columbia, District of Columbia",
    stateCode: "11",
    stateAbbr: "DC",
  },
  {
    value: "12001",
    label: "Alachua County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12003",
    label: "Baker County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12005",
    label: "Bay County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12007",
    label: "Bradford County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12009",
    label: "Brevard County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12011",
    label: "Broward County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12013",
    label: "Calhoun County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12015",
    label: "Charlotte County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12017",
    label: "Citrus County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12019",
    label: "Clay County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12021",
    label: "Collier County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12023",
    label: "Columbia County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12027",
    label: "DeSoto County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12029",
    label: "Dixie County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12031",
    label: "Duval County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12033",
    label: "Escambia County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12035",
    label: "Flagler County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12037",
    label: "Franklin County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12039",
    label: "Gadsden County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12041",
    label: "Gilchrist County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12043",
    label: "Glades County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12045",
    label: "Gulf County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12047",
    label: "Hamilton County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12049",
    label: "Hardee County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12051",
    label: "Hendry County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12053",
    label: "Hernando County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12055",
    label: "Highlands County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12057",
    label: "Hillsborough County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12059",
    label: "Holmes County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12061",
    label: "Indian River County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12063",
    label: "Jackson County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12065",
    label: "Jefferson County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12067",
    label: "Lafayette County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12069",
    label: "Lake County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12071",
    label: "Lee County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12073",
    label: "Leon County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12075",
    label: "Levy County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12077",
    label: "Liberty County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12079",
    label: "Madison County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12081",
    label: "Manatee County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12083",
    label: "Marion County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12085",
    label: "Martin County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12086",
    label: "Miami-Dade County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12087",
    label: "Monroe County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12089",
    label: "Nassau County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12091",
    label: "Okaloosa County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12093",
    label: "Okeechobee County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12095",
    label: "Orange County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12097",
    label: "Osceola County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12099",
    label: "Palm Beach County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12101",
    label: "Pasco County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12103",
    label: "Pinellas County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12105",
    label: "Polk County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12107",
    label: "Putnam County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12109",
    label: "St. Johns County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12111",
    label: "St. Lucie County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12113",
    label: "Santa Rosa County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12115",
    label: "Sarasota County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12117",
    label: "Seminole County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12119",
    label: "Sumter County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12121",
    label: "Suwannee County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12123",
    label: "Taylor County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12125",
    label: "Union County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12127",
    label: "Volusia County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12129",
    label: "Wakulla County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12131",
    label: "Walton County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "12133",
    label: "Washington County, Florida",
    stateCode: "12",
    stateAbbr: "FL",
  },
  {
    value: "13001",
    label: "Appling County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13003",
    label: "Atkinson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13005",
    label: "Bacon County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13007",
    label: "Baker County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13009",
    label: "Baldwin County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13011",
    label: "Banks County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13013",
    label: "Barrow County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13015",
    label: "Bartow County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13017",
    label: "Ben Hill County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13019",
    label: "Berrien County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13021",
    label: "Bibb County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13023",
    label: "Bleckley County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13025",
    label: "Brantley County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13027",
    label: "Brooks County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13029",
    label: "Bryan County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13031",
    label: "Bulloch County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13033",
    label: "Burke County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13035",
    label: "Butts County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13037",
    label: "Calhoun County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13039",
    label: "Camden County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13043",
    label: "Candler County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13045",
    label: "Carroll County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13047",
    label: "Catoosa County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13049",
    label: "Charlton County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13051",
    label: "Chatham County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13053",
    label: "Chattahoochee County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13055",
    label: "Chattooga County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13057",
    label: "Cherokee County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13059",
    label: "Clarke County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13061",
    label: "Clay County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13063",
    label: "Clayton County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13065",
    label: "Clinch County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13067",
    label: "Cobb County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13069",
    label: "Coffee County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13071",
    label: "Colquitt County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13073",
    label: "Columbia County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13075",
    label: "Cook County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13077",
    label: "Coweta County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13079",
    label: "Crawford County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13081",
    label: "Crisp County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13083",
    label: "Dade County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13085",
    label: "Dawson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13087",
    label: "Decatur County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13089",
    label: "DeKalb County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13091",
    label: "Dodge County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13093",
    label: "Dooly County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13095",
    label: "Dougherty County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13097",
    label: "Douglas County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13099",
    label: "Early County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13101",
    label: "Echols County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13103",
    label: "Effingham County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13105",
    label: "Elbert County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13107",
    label: "Emanuel County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13109",
    label: "Evans County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13111",
    label: "Fannin County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13113",
    label: "Fayette County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13115",
    label: "Floyd County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13117",
    label: "Forsyth County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13119",
    label: "Franklin County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13121",
    label: "Fulton County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13123",
    label: "Gilmer County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13125",
    label: "Glascock County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13127",
    label: "Glynn County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13129",
    label: "Gordon County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13131",
    label: "Grady County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13133",
    label: "Greene County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13135",
    label: "Gwinnett County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13137",
    label: "Habersham County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13139",
    label: "Hall County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13141",
    label: "Hancock County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13143",
    label: "Haralson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13145",
    label: "Harris County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13147",
    label: "Hart County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13149",
    label: "Heard County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13151",
    label: "Henry County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13153",
    label: "Houston County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13155",
    label: "Irwin County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13157",
    label: "Jackson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13159",
    label: "Jasper County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13161",
    label: "Jeff Davis County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13163",
    label: "Jefferson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13165",
    label: "Jenkins County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13167",
    label: "Johnson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13169",
    label: "Jones County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13171",
    label: "Lamar County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13173",
    label: "Lanier County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13175",
    label: "Laurens County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13177",
    label: "Lee County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13179",
    label: "Liberty County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13181",
    label: "Lincoln County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13183",
    label: "Long County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13185",
    label: "Lowndes County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13187",
    label: "Lumpkin County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13189",
    label: "McDuffie County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13191",
    label: "McIntosh County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13193",
    label: "Macon County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13195",
    label: "Madison County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13197",
    label: "Marion County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13199",
    label: "Meriwether County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13201",
    label: "Miller County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13205",
    label: "Mitchell County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13207",
    label: "Monroe County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13209",
    label: "Montgomery County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13211",
    label: "Morgan County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13213",
    label: "Murray County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13215",
    label: "Muscogee County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13217",
    label: "Newton County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13219",
    label: "Oconee County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13221",
    label: "Oglethorpe County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13223",
    label: "Paulding County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13225",
    label: "Peach County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13227",
    label: "Pickens County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13229",
    label: "Pierce County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13231",
    label: "Pike County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13233",
    label: "Polk County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13235",
    label: "Pulaski County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13237",
    label: "Putnam County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13239",
    label: "Quitman County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13241",
    label: "Rabun County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13243",
    label: "Randolph County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13245",
    label: "Richmond County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13247",
    label: "Rockdale County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13249",
    label: "Schley County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13251",
    label: "Screven County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13253",
    label: "Seminole County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13255",
    label: "Spalding County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13257",
    label: "Stephens County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13259",
    label: "Stewart County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13261",
    label: "Sumter County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13263",
    label: "Talbot County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13265",
    label: "Taliaferro County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13267",
    label: "Tattnall County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13269",
    label: "Taylor County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13271",
    label: "Telfair County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13273",
    label: "Terrell County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13275",
    label: "Thomas County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13277",
    label: "Tift County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13279",
    label: "Toombs County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13281",
    label: "Towns County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13283",
    label: "Treutlen County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13285",
    label: "Troup County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13287",
    label: "Turner County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13289",
    label: "Twiggs County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13291",
    label: "Union County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13293",
    label: "Upson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13295",
    label: "Walker County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13297",
    label: "Walton County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13299",
    label: "Ware County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13301",
    label: "Warren County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13303",
    label: "Washington County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13305",
    label: "Wayne County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13307",
    label: "Webster County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13309",
    label: "Wheeler County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13311",
    label: "White County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13313",
    label: "Whitfield County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13315",
    label: "Wilcox County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13317",
    label: "Wilkes County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13319",
    label: "Wilkinson County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "13321",
    label: "Worth County, Georgia",
    stateCode: "13",
    stateAbbr: "GA",
  },
  {
    value: "15001",
    label: "Hawaii County, Hawaii",
    stateCode: "15",
    stateAbbr: "HI",
  },
  {
    value: "15003",
    label: "Honolulu County, Hawaii",
    stateCode: "15",
    stateAbbr: "HI",
  },
  {
    value: "15005",
    label: "Kalawao County, Hawaii",
    stateCode: "15",
    stateAbbr: "HI",
  },
  {
    value: "15007",
    label: "Kauai County, Hawaii",
    stateCode: "15",
    stateAbbr: "HI",
  },
  {
    value: "15009",
    label: "Maui County, Hawaii",
    stateCode: "15",
    stateAbbr: "HI",
  },
  {
    value: "16001",
    label: "Ada County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16003",
    label: "Adams County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16005",
    label: "Bannock County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16007",
    label: "Bear Lake County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16009",
    label: "Benewah County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16011",
    label: "Bingham County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16013",
    label: "Blaine County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16015",
    label: "Boise County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16017",
    label: "Bonner County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16019",
    label: "Bonneville County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16021",
    label: "Boundary County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16023",
    label: "Butte County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16025",
    label: "Camas County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16027",
    label: "Canyon County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16029",
    label: "Caribou County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16031",
    label: "Cassia County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16033",
    label: "Clark County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16035",
    label: "Clearwater County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16037",
    label: "Custer County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16039",
    label: "Elmore County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16041",
    label: "Franklin County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16043",
    label: "Fremont County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16045",
    label: "Gem County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16047",
    label: "Gooding County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16049",
    label: "Idaho County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16051",
    label: "Jefferson County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16053",
    label: "Jerome County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16055",
    label: "Kootenai County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16057",
    label: "Latah County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16059",
    label: "Lemhi County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16061",
    label: "Lewis County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16063",
    label: "Lincoln County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16065",
    label: "Madison County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16067",
    label: "Minidoka County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16069",
    label: "Nez Perce County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16071",
    label: "Oneida County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16073",
    label: "Owyhee County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16075",
    label: "Payette County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16077",
    label: "Power County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16079",
    label: "Shoshone County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16081",
    label: "Teton County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16083",
    label: "Twin Falls County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16085",
    label: "Valley County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "16087",
    label: "Washington County, Idaho",
    stateCode: "16",
    stateAbbr: "ID",
  },
  {
    value: "17001",
    label: "Adams County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17003",
    label: "Alexander County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17005",
    label: "Bond County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17007",
    label: "Boone County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17009",
    label: "Brown County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17011",
    label: "Bureau County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17013",
    label: "Calhoun County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17015",
    label: "Carroll County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17017",
    label: "Cass County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17019",
    label: "Champaign County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17021",
    label: "Christian County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17023",
    label: "Clark County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17025",
    label: "Clay County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17027",
    label: "Clinton County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17029",
    label: "Coles County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17031",
    label: "Cook County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17033",
    label: "Crawford County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17035",
    label: "Cumberland County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17037",
    label: "DeKalb County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17039",
    label: "De Witt County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17041",
    label: "Douglas County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17043",
    label: "DuPage County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17045",
    label: "Edgar County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17047",
    label: "Edwards County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17049",
    label: "Effingham County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17051",
    label: "Fayette County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17053",
    label: "Ford County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17055",
    label: "Franklin County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17057",
    label: "Fulton County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17059",
    label: "Gallatin County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17061",
    label: "Greene County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17063",
    label: "Grundy County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17065",
    label: "Hamilton County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17067",
    label: "Hancock County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17069",
    label: "Hardin County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17071",
    label: "Henderson County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17073",
    label: "Henry County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17075",
    label: "Iroquois County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17077",
    label: "Jackson County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17079",
    label: "Jasper County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17081",
    label: "Jefferson County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17083",
    label: "Jersey County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17085",
    label: "Jo Daviess County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17087",
    label: "Johnson County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17089",
    label: "Kane County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17091",
    label: "Kankakee County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17093",
    label: "Kendall County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17095",
    label: "Knox County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17097",
    label: "Lake County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17099",
    label: "LaSalle County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17101",
    label: "Lawrence County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17103",
    label: "Lee County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17105",
    label: "Livingston County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17107",
    label: "Logan County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17109",
    label: "McDonough County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17111",
    label: "McHenry County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17113",
    label: "McLean County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17115",
    label: "Macon County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17117",
    label: "Macoupin County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17119",
    label: "Madison County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17121",
    label: "Marion County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17123",
    label: "Marshall County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17125",
    label: "Mason County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17127",
    label: "Massac County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17129",
    label: "Menard County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17131",
    label: "Mercer County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17133",
    label: "Monroe County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17135",
    label: "Montgomery County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17137",
    label: "Morgan County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17139",
    label: "Moultrie County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17141",
    label: "Ogle County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17143",
    label: "Peoria County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17145",
    label: "Perry County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17147",
    label: "Piatt County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17149",
    label: "Pike County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17151",
    label: "Pope County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17153",
    label: "Pulaski County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17155",
    label: "Putnam County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17157",
    label: "Randolph County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17159",
    label: "Richland County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17161",
    label: "Rock Island County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17163",
    label: "St. Clair County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17165",
    label: "Saline County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17167",
    label: "Sangamon County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17169",
    label: "Schuyler County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17171",
    label: "Scott County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17173",
    label: "Shelby County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17175",
    label: "Stark County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17177",
    label: "Stephenson County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17179",
    label: "Tazewell County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17181",
    label: "Union County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17183",
    label: "Vermilion County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17185",
    label: "Wabash County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17187",
    label: "Warren County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17189",
    label: "Washington County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17191",
    label: "Wayne County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17193",
    label: "White County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17195",
    label: "Whiteside County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17197",
    label: "Will County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17199",
    label: "Williamson County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17201",
    label: "Winnebago County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "17203",
    label: "Woodford County, Illinois",
    stateCode: "17",
    stateAbbr: "IL",
  },
  {
    value: "18001",
    label: "Adams County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18003",
    label: "Allen County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18005",
    label: "Bartholomew County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18007",
    label: "Benton County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18009",
    label: "Blackford County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18011",
    label: "Boone County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18013",
    label: "Brown County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18015",
    label: "Carroll County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18017",
    label: "Cass County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18019",
    label: "Clark County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18021",
    label: "Clay County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18023",
    label: "Clinton County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18025",
    label: "Crawford County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18027",
    label: "Daviess County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18029",
    label: "Dearborn County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18031",
    label: "Decatur County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18033",
    label: "DeKalb County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18035",
    label: "Delaware County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18037",
    label: "Dubois County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18039",
    label: "Elkhart County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18041",
    label: "Fayette County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18043",
    label: "Floyd County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18045",
    label: "Fountain County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18047",
    label: "Franklin County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18049",
    label: "Fulton County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18051",
    label: "Gibson County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18053",
    label: "Grant County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18055",
    label: "Greene County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18057",
    label: "Hamilton County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18059",
    label: "Hancock County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18061",
    label: "Harrison County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18063",
    label: "Hendricks County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18065",
    label: "Henry County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18067",
    label: "Howard County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18069",
    label: "Huntington County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18071",
    label: "Jackson County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18073",
    label: "Jasper County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18075",
    label: "Jay County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18077",
    label: "Jefferson County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18079",
    label: "Jennings County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18081",
    label: "Johnson County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18083",
    label: "Knox County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18085",
    label: "Kosciusko County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18087",
    label: "LaGrange County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18089",
    label: "Lake County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18091",
    label: "LaPorte County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18093",
    label: "Lawrence County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18095",
    label: "Madison County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18097",
    label: "Marion County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18099",
    label: "Marshall County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18101",
    label: "Martin County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18103",
    label: "Miami County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18105",
    label: "Monroe County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18107",
    label: "Montgomery County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18109",
    label: "Morgan County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18111",
    label: "Newton County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18113",
    label: "Noble County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18115",
    label: "Ohio County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18117",
    label: "Orange County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18119",
    label: "Owen County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18121",
    label: "Parke County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18123",
    label: "Perry County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18125",
    label: "Pike County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18127",
    label: "Porter County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18129",
    label: "Posey County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18131",
    label: "Pulaski County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18133",
    label: "Putnam County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18135",
    label: "Randolph County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18137",
    label: "Ripley County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18139",
    label: "Rush County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18141",
    label: "St. Joseph County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18143",
    label: "Scott County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18145",
    label: "Shelby County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18147",
    label: "Spencer County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18149",
    label: "Starke County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18151",
    label: "Steuben County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18153",
    label: "Sullivan County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18155",
    label: "Switzerland County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18157",
    label: "Tippecanoe County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18159",
    label: "Tipton County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18161",
    label: "Union County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18163",
    label: "Vanderburgh County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18165",
    label: "Vermillion County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18167",
    label: "Vigo County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18169",
    label: "Wabash County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18171",
    label: "Warren County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18173",
    label: "Warrick County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18175",
    label: "Washington County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18177",
    label: "Wayne County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18179",
    label: "Wells County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18181",
    label: "White County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "18183",
    label: "Whitley County, Indiana",
    stateCode: "18",
    stateAbbr: "IN",
  },
  {
    value: "19001",
    label: "Adair County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19003",
    label: "Adams County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19005",
    label: "Allamakee County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19007",
    label: "Appanoose County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19009",
    label: "Audubon County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19011",
    label: "Benton County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19013",
    label: "Black Hawk County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19015",
    label: "Boone County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19017",
    label: "Bremer County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19019",
    label: "Buchanan County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19021",
    label: "Buena Vista County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19023",
    label: "Butler County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19025",
    label: "Calhoun County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19027",
    label: "Carroll County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19029",
    label: "Cass County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19031",
    label: "Cedar County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19033",
    label: "Cerro Gordo County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19035",
    label: "Cherokee County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19037",
    label: "Chickasaw County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19039",
    label: "Clarke County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19041",
    label: "Clay County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19043",
    label: "Clayton County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19045",
    label: "Clinton County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19047",
    label: "Crawford County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19049",
    label: "Dallas County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19051",
    label: "Davis County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19053",
    label: "Decatur County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19055",
    label: "Delaware County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19057",
    label: "Des Moines County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19059",
    label: "Dickinson County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19061",
    label: "Dubuque County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19063",
    label: "Emmet County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19065",
    label: "Fayette County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19067",
    label: "Floyd County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19069",
    label: "Franklin County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19071",
    label: "Fremont County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19073",
    label: "Greene County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19075",
    label: "Grundy County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19077",
    label: "Guthrie County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19079",
    label: "Hamilton County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19081",
    label: "Hancock County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19083",
    label: "Hardin County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19085",
    label: "Harrison County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19087",
    label: "Henry County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19089",
    label: "Howard County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19091",
    label: "Humboldt County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19093",
    label: "Ida County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19095",
    label: "Iowa County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19097",
    label: "Jackson County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19099",
    label: "Jasper County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19101",
    label: "Jefferson County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19103",
    label: "Johnson County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19105",
    label: "Jones County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19107",
    label: "Keokuk County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19109",
    label: "Kossuth County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19111",
    label: "Lee County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19113",
    label: "Linn County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19115",
    label: "Louisa County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19117",
    label: "Lucas County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19119",
    label: "Lyon County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19121",
    label: "Madison County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19123",
    label: "Mahaska County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19125",
    label: "Marion County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19127",
    label: "Marshall County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19129",
    label: "Mills County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19131",
    label: "Mitchell County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19133",
    label: "Monona County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19135",
    label: "Monroe County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19137",
    label: "Montgomery County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19139",
    label: "Muscatine County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19141",
    label: "O'Brien County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19143",
    label: "Osceola County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19145",
    label: "Page County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19147",
    label: "Palo Alto County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19149",
    label: "Plymouth County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19151",
    label: "Pocahontas County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19153",
    label: "Polk County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19155",
    label: "Pottawattamie County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19157",
    label: "Poweshiek County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19159",
    label: "Ringgold County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19161",
    label: "Sac County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19163",
    label: "Scott County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19165",
    label: "Shelby County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19167",
    label: "Sioux County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19169",
    label: "Story County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19171",
    label: "Tama County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19173",
    label: "Taylor County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19175",
    label: "Union County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19177",
    label: "Van Buren County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19179",
    label: "Wapello County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19181",
    label: "Warren County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19183",
    label: "Washington County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19185",
    label: "Wayne County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19187",
    label: "Webster County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19189",
    label: "Winnebago County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19191",
    label: "Winneshiek County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19193",
    label: "Woodbury County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19195",
    label: "Worth County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "19197",
    label: "Wright County, Iowa",
    stateCode: "19",
    stateAbbr: "IA",
  },
  {
    value: "20001",
    label: "Allen County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20003",
    label: "Anderson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20005",
    label: "Atchison County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20007",
    label: "Barber County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20009",
    label: "Barton County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20011",
    label: "Bourbon County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20013",
    label: "Brown County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20015",
    label: "Butler County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20017",
    label: "Chase County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20019",
    label: "Chautauqua County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20021",
    label: "Cherokee County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20023",
    label: "Cheyenne County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20025",
    label: "Clark County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20027",
    label: "Clay County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20029",
    label: "Cloud County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20031",
    label: "Coffey County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20033",
    label: "Comanche County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20035",
    label: "Cowley County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20037",
    label: "Crawford County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20039",
    label: "Decatur County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20041",
    label: "Dickinson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20043",
    label: "Doniphan County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20045",
    label: "Douglas County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20047",
    label: "Edwards County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20049",
    label: "Elk County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20051",
    label: "Ellis County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20053",
    label: "Ellsworth County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20055",
    label: "Finney County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20057",
    label: "Ford County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20059",
    label: "Franklin County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20061",
    label: "Geary County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20063",
    label: "Gove County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20065",
    label: "Graham County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20067",
    label: "Grant County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20069",
    label: "Gray County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20071",
    label: "Greeley County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20073",
    label: "Greenwood County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20075",
    label: "Hamilton County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20077",
    label: "Harper County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20079",
    label: "Harvey County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20081",
    label: "Haskell County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20083",
    label: "Hodgeman County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20085",
    label: "Jackson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20087",
    label: "Jefferson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20089",
    label: "Jewell County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20091",
    label: "Johnson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20093",
    label: "Kearny County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20095",
    label: "Kingman County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20097",
    label: "Kiowa County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20099",
    label: "Labette County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20101",
    label: "Lane County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20103",
    label: "Leavenworth County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20105",
    label: "Lincoln County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20107",
    label: "Linn County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20109",
    label: "Logan County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20111",
    label: "Lyon County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20113",
    label: "McPherson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20115",
    label: "Marion County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20117",
    label: "Marshall County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20119",
    label: "Meade County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20121",
    label: "Miami County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20123",
    label: "Mitchell County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20125",
    label: "Montgomery County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20127",
    label: "Morris County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20129",
    label: "Morton County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20131",
    label: "Nemaha County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20133",
    label: "Neosho County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20135",
    label: "Ness County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20137",
    label: "Norton County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20139",
    label: "Osage County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20141",
    label: "Osborne County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20143",
    label: "Ottawa County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20145",
    label: "Pawnee County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20147",
    label: "Phillips County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20149",
    label: "Pottawatomie County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20151",
    label: "Pratt County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20153",
    label: "Rawlins County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20155",
    label: "Reno County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20157",
    label: "Republic County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20159",
    label: "Rice County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20161",
    label: "Riley County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20163",
    label: "Rooks County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20165",
    label: "Rush County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20167",
    label: "Russell County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20169",
    label: "Saline County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20171",
    label: "Scott County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20173",
    label: "Sedgwick County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20175",
    label: "Seward County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20177",
    label: "Shawnee County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20179",
    label: "Sheridan County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20181",
    label: "Sherman County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20183",
    label: "Smith County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20185",
    label: "Stafford County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20187",
    label: "Stanton County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20189",
    label: "Stevens County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20191",
    label: "Sumner County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20193",
    label: "Thomas County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20195",
    label: "Trego County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20197",
    label: "Wabaunsee County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20199",
    label: "Wallace County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20201",
    label: "Washington County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20203",
    label: "Wichita County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20205",
    label: "Wilson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20207",
    label: "Woodson County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "20209",
    label: "Wyandotte County, Kansas",
    stateCode: "20",
    stateAbbr: "KS",
  },
  {
    value: "21001",
    label: "Adair County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21003",
    label: "Allen County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21005",
    label: "Anderson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21007",
    label: "Ballard County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21009",
    label: "Barren County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21011",
    label: "Bath County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21013",
    label: "Bell County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21015",
    label: "Boone County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21017",
    label: "Bourbon County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21019",
    label: "Boyd County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21021",
    label: "Boyle County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21023",
    label: "Bracken County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21025",
    label: "Breathitt County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21027",
    label: "Breckinridge County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21029",
    label: "Bullitt County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21031",
    label: "Butler County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21033",
    label: "Caldwell County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21035",
    label: "Calloway County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21037",
    label: "Campbell County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21039",
    label: "Carlisle County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21041",
    label: "Carroll County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21043",
    label: "Carter County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21045",
    label: "Casey County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21047",
    label: "Christian County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21049",
    label: "Clark County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21051",
    label: "Clay County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21053",
    label: "Clinton County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21055",
    label: "Crittenden County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21057",
    label: "Cumberland County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21059",
    label: "Daviess County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21061",
    label: "Edmonson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21063",
    label: "Elliott County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21065",
    label: "Estill County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21067",
    label: "Fayette County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21069",
    label: "Fleming County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21071",
    label: "Floyd County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21073",
    label: "Franklin County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21075",
    label: "Fulton County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21077",
    label: "Gallatin County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21079",
    label: "Garrard County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21081",
    label: "Grant County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21083",
    label: "Graves County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21085",
    label: "Grayson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21087",
    label: "Green County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21089",
    label: "Greenup County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21091",
    label: "Hancock County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21093",
    label: "Hardin County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21095",
    label: "Harlan County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21097",
    label: "Harrison County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21099",
    label: "Hart County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21101",
    label: "Henderson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21103",
    label: "Henry County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21105",
    label: "Hickman County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21107",
    label: "Hopkins County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21109",
    label: "Jackson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21111",
    label: "Jefferson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21113",
    label: "Jessamine County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21115",
    label: "Johnson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21117",
    label: "Kenton County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21119",
    label: "Knott County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21121",
    label: "Knox County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21123",
    label: "Larue County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21125",
    label: "Laurel County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21127",
    label: "Lawrence County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21129",
    label: "Lee County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21131",
    label: "Leslie County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21133",
    label: "Letcher County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21135",
    label: "Lewis County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21137",
    label: "Lincoln County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21139",
    label: "Livingston County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21141",
    label: "Logan County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21143",
    label: "Lyon County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21145",
    label: "McCracken County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21147",
    label: "McCreary County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21149",
    label: "McLean County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21151",
    label: "Madison County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21153",
    label: "Magoffin County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21155",
    label: "Marion County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21157",
    label: "Marshall County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21159",
    label: "Martin County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21161",
    label: "Mason County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21163",
    label: "Meade County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21165",
    label: "Menifee County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21167",
    label: "Mercer County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21169",
    label: "Metcalfe County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21171",
    label: "Monroe County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21173",
    label: "Montgomery County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21175",
    label: "Morgan County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21177",
    label: "Muhlenberg County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21179",
    label: "Nelson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21181",
    label: "Nicholas County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21183",
    label: "Ohio County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21185",
    label: "Oldham County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21187",
    label: "Owen County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21189",
    label: "Owsley County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21191",
    label: "Pendleton County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21193",
    label: "Perry County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21195",
    label: "Pike County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21197",
    label: "Powell County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21199",
    label: "Pulaski County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21201",
    label: "Robertson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21203",
    label: "Rockcastle County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21205",
    label: "Rowan County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21207",
    label: "Russell County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21209",
    label: "Scott County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21211",
    label: "Shelby County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21213",
    label: "Simpson County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21215",
    label: "Spencer County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21217",
    label: "Taylor County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21219",
    label: "Todd County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21221",
    label: "Trigg County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21223",
    label: "Trimble County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21225",
    label: "Union County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21227",
    label: "Warren County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21229",
    label: "Washington County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21231",
    label: "Wayne County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21233",
    label: "Webster County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21235",
    label: "Whitley County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21237",
    label: "Wolfe County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "21239",
    label: "Woodford County, Kentucky",
    stateCode: "21",
    stateAbbr: "KY",
  },
  {
    value: "22001",
    label: "Acadia Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22003",
    label: "Allen Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22005",
    label: "Ascension Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22007",
    label: "Assumption Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22009",
    label: "Avoyelles Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22011",
    label: "Beauregard Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22013",
    label: "Bienville Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22015",
    label: "Bossier Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22017",
    label: "Caddo Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22019",
    label: "Calcasieu Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22021",
    label: "Caldwell Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22023",
    label: "Cameron Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22025",
    label: "Catahoula Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22027",
    label: "Claiborne Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22029",
    label: "Concordia Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22031",
    label: "De Soto Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22033",
    label: "East Baton Rouge Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22035",
    label: "East Carroll Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22037",
    label: "East Feliciana Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22039",
    label: "Evangeline Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22041",
    label: "Franklin Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22043",
    label: "Grant Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22045",
    label: "Iberia Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22047",
    label: "Iberville Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22049",
    label: "Jackson Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22051",
    label: "Jefferson Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22053",
    label: "Jefferson Davis Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22055",
    label: "Lafayette Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22057",
    label: "Lafourche Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22059",
    label: "LaSalle Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22061",
    label: "Lincoln Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22063",
    label: "Livingston Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22065",
    label: "Madison Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22067",
    label: "Morehouse Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22069",
    label: "Natchitoches Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22071",
    label: "Orleans Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22073",
    label: "Ouachita Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22075",
    label: "Plaquemines Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22077",
    label: "Pointe Coupee Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22079",
    label: "Rapides Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22081",
    label: "Red River Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22083",
    label: "Richland Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22085",
    label: "Sabine Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22087",
    label: "St. Bernard Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22089",
    label: "St. Charles Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22091",
    label: "St. Helena Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22093",
    label: "St. James Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22095",
    label: "St. John the Baptist Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22097",
    label: "St. Landry Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22099",
    label: "St. Martin Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22101",
    label: "St. Mary Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22103",
    label: "St. Tammany Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22105",
    label: "Tangipahoa Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22107",
    label: "Tensas Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22109",
    label: "Terrebonne Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22111",
    label: "Union Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22113",
    label: "Vermilion Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22115",
    label: "Vernon Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22117",
    label: "Washington Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22119",
    label: "Webster Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22121",
    label: "West Baton Rouge Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22123",
    label: "West Carroll Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22125",
    label: "West Feliciana Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "22127",
    label: "Winn Parish, Louisiana",
    stateCode: "22",
    stateAbbr: "LA",
  },
  {
    value: "23001",
    label: "Androscoggin County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23003",
    label: "Aroostook County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23005",
    label: "Cumberland County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23007",
    label: "Franklin County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23009",
    label: "Hancock County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23011",
    label: "Kennebec County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23013",
    label: "Knox County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23015",
    label: "Lincoln County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23017",
    label: "Oxford County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23019",
    label: "Penobscot County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23021",
    label: "Piscataquis County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23023",
    label: "Sagadahoc County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23025",
    label: "Somerset County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23027",
    label: "Waldo County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23029",
    label: "Washington County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "23031",
    label: "York County, Maine",
    stateCode: "23",
    stateAbbr: "ME",
  },
  {
    value: "24001",
    label: "Allegany County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24003",
    label: "Anne Arundel County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24005",
    label: "Baltimore County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24009",
    label: "Calvert County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24011",
    label: "Caroline County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24013",
    label: "Carroll County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24015",
    label: "Cecil County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24017",
    label: "Charles County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24019",
    label: "Dorchester County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24021",
    label: "Frederick County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24023",
    label: "Garrett County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24025",
    label: "Harford County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24027",
    label: "Howard County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24029",
    label: "Kent County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24031",
    label: "Montgomery County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24033",
    label: "Prince George's County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24035",
    label: "Queen Anne's County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24037",
    label: "St. Mary's County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24039",
    label: "Somerset County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24041",
    label: "Talbot County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24043",
    label: "Washington County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24045",
    label: "Wicomico County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24047",
    label: "Worcester County, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "24510",
    label: "Baltimore city, Maryland",
    stateCode: "24",
    stateAbbr: "MD",
  },
  {
    value: "25001",
    label: "Barnstable County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25003",
    label: "Berkshire County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25005",
    label: "Bristol County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25007",
    label: "Dukes County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25009",
    label: "Essex County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25011",
    label: "Franklin County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25013",
    label: "Hampden County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25015",
    label: "Hampshire County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25017",
    label: "Middlesex County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25019",
    label: "Nantucket County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25021",
    label: "Norfolk County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25023",
    label: "Plymouth County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25025",
    label: "Suffolk County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "25027",
    label: "Worcester County, Massachusetts",
    stateCode: "25",
    stateAbbr: "MA",
  },
  {
    value: "26001",
    label: "Alcona County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26003",
    label: "Alger County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26005",
    label: "Allegan County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26007",
    label: "Alpena County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26009",
    label: "Antrim County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26011",
    label: "Arenac County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26013",
    label: "Baraga County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26015",
    label: "Barry County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26017",
    label: "Bay County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26019",
    label: "Benzie County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26021",
    label: "Berrien County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26023",
    label: "Branch County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26025",
    label: "Calhoun County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26027",
    label: "Cass County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26029",
    label: "Charlevoix County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26031",
    label: "Cheboygan County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26033",
    label: "Chippewa County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26035",
    label: "Clare County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26037",
    label: "Clinton County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26039",
    label: "Crawford County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26041",
    label: "Delta County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26043",
    label: "Dickinson County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26045",
    label: "Eaton County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26047",
    label: "Emmet County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26049",
    label: "Genesee County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26051",
    label: "Gladwin County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26053",
    label: "Gogebic County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26055",
    label: "Grand Traverse County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26057",
    label: "Gratiot County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26059",
    label: "Hillsdale County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26061",
    label: "Houghton County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26063",
    label: "Huron County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26065",
    label: "Ingham County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26067",
    label: "Ionia County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26069",
    label: "Iosco County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26071",
    label: "Iron County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26073",
    label: "Isabella County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26075",
    label: "Jackson County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26077",
    label: "Kalamazoo County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26079",
    label: "Kalkaska County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26081",
    label: "Kent County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26083",
    label: "Keweenaw County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26085",
    label: "Lake County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26087",
    label: "Lapeer County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26089",
    label: "Leelanau County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26091",
    label: "Lenawee County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26093",
    label: "Livingston County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26095",
    label: "Luce County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26097",
    label: "Mackinac County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26099",
    label: "Macomb County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26101",
    label: "Manistee County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26103",
    label: "Marquette County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26105",
    label: "Mason County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26107",
    label: "Mecosta County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26109",
    label: "Menominee County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26111",
    label: "Midland County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26113",
    label: "Missaukee County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26115",
    label: "Monroe County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26117",
    label: "Montcalm County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26119",
    label: "Montmorency County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26121",
    label: "Muskegon County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26123",
    label: "Newaygo County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26125",
    label: "Oakland County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26127",
    label: "Oceana County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26129",
    label: "Ogemaw County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26131",
    label: "Ontonagon County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26133",
    label: "Osceola County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26135",
    label: "Oscoda County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26137",
    label: "Otsego County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26139",
    label: "Ottawa County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26141",
    label: "Presque Isle County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26143",
    label: "Roscommon County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26145",
    label: "Saginaw County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26147",
    label: "St. Clair County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26149",
    label: "St. Joseph County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26151",
    label: "Sanilac County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26153",
    label: "Schoolcraft County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26155",
    label: "Shiawassee County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26157",
    label: "Tuscola County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26159",
    label: "Van Buren County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26161",
    label: "Washtenaw County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26163",
    label: "Wayne County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "26165",
    label: "Wexford County, Michigan",
    stateCode: "26",
    stateAbbr: "MI",
  },
  {
    value: "27001",
    label: "Aitkin County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27003",
    label: "Anoka County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27005",
    label: "Becker County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27007",
    label: "Beltrami County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27009",
    label: "Benton County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27011",
    label: "Big Stone County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27013",
    label: "Blue Earth County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27015",
    label: "Brown County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27017",
    label: "Carlton County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27019",
    label: "Carver County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27021",
    label: "Cass County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27023",
    label: "Chippewa County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27025",
    label: "Chisago County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27027",
    label: "Clay County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27029",
    label: "Clearwater County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27031",
    label: "Cook County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27033",
    label: "Cottonwood County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27035",
    label: "Crow Wing County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27037",
    label: "Dakota County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27039",
    label: "Dodge County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27041",
    label: "Douglas County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27043",
    label: "Faribault County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27045",
    label: "Fillmore County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27047",
    label: "Freeborn County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27049",
    label: "Goodhue County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27051",
    label: "Grant County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27053",
    label: "Hennepin County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27055",
    label: "Houston County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27057",
    label: "Hubbard County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27059",
    label: "Isanti County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27061",
    label: "Itasca County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27063",
    label: "Jackson County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27065",
    label: "Kanabec County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27067",
    label: "Kandiyohi County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27069",
    label: "Kittson County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27071",
    label: "Koochiching County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27073",
    label: "Lac qui Parle County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27075",
    label: "Lake County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27077",
    label: "Lake of the Woods County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27079",
    label: "Le Sueur County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27081",
    label: "Lincoln County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27083",
    label: "Lyon County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27085",
    label: "McLeod County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27087",
    label: "Mahnomen County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27089",
    label: "Marshall County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27091",
    label: "Martin County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27093",
    label: "Meeker County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27095",
    label: "Mille Lacs County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27097",
    label: "Morrison County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27099",
    label: "Mower County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27101",
    label: "Murray County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27103",
    label: "Nicollet County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27105",
    label: "Nobles County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27107",
    label: "Norman County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27109",
    label: "Olmsted County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27111",
    label: "Otter Tail County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27113",
    label: "Pennington County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27115",
    label: "Pine County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27117",
    label: "Pipestone County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27119",
    label: "Polk County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27121",
    label: "Pope County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27123",
    label: "Ramsey County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27125",
    label: "Red Lake County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27127",
    label: "Redwood County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27129",
    label: "Renville County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27131",
    label: "Rice County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27133",
    label: "Rock County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27135",
    label: "Roseau County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27137",
    label: "St. Louis County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27139",
    label: "Scott County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27141",
    label: "Sherburne County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27143",
    label: "Sibley County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27145",
    label: "Stearns County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27147",
    label: "Steele County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27149",
    label: "Stevens County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27151",
    label: "Swift County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27153",
    label: "Todd County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27155",
    label: "Traverse County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27157",
    label: "Wabasha County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27159",
    label: "Wadena County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27161",
    label: "Waseca County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27163",
    label: "Washington County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27165",
    label: "Watonwan County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27167",
    label: "Wilkin County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27169",
    label: "Winona County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27171",
    label: "Wright County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "27173",
    label: "Yellow Medicine County, Minnesota",
    stateCode: "27",
    stateAbbr: "MN",
  },
  {
    value: "28001",
    label: "Adams County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28003",
    label: "Alcorn County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28005",
    label: "Amite County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28007",
    label: "Attala County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28009",
    label: "Benton County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28011",
    label: "Bolivar County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28013",
    label: "Calhoun County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28015",
    label: "Carroll County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28017",
    label: "Chickasaw County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28019",
    label: "Choctaw County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28021",
    label: "Claiborne County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28023",
    label: "Clarke County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28025",
    label: "Clay County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28027",
    label: "Coahoma County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28029",
    label: "Copiah County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28031",
    label: "Covington County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28033",
    label: "DeSoto County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28035",
    label: "Forrest County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28037",
    label: "Franklin County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28039",
    label: "George County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28041",
    label: "Greene County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28043",
    label: "Grenada County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28045",
    label: "Hancock County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28047",
    label: "Harrison County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28049",
    label: "Hinds County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28051",
    label: "Holmes County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28053",
    label: "Humphreys County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28055",
    label: "Issaquena County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28057",
    label: "Itawamba County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28059",
    label: "Jackson County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28061",
    label: "Jasper County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28063",
    label: "Jefferson County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28065",
    label: "Jefferson Davis County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28067",
    label: "Jones County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28069",
    label: "Kemper County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28071",
    label: "Lafayette County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28073",
    label: "Lamar County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28075",
    label: "Lauderdale County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28077",
    label: "Lawrence County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28079",
    label: "Leake County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28081",
    label: "Lee County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28083",
    label: "Leflore County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28085",
    label: "Lincoln County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28087",
    label: "Lowndes County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28089",
    label: "Madison County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28091",
    label: "Marion County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28093",
    label: "Marshall County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28095",
    label: "Monroe County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28097",
    label: "Montgomery County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28099",
    label: "Neshoba County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28101",
    label: "Newton County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28103",
    label: "Noxubee County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28105",
    label: "Oktibbeha County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28107",
    label: "Panola County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28109",
    label: "Pearl River County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28111",
    label: "Perry County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28113",
    label: "Pike County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28115",
    label: "Pontotoc County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28117",
    label: "Prentiss County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28119",
    label: "Quitman County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28121",
    label: "Rankin County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28123",
    label: "Scott County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28125",
    label: "Sharkey County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28127",
    label: "Simpson County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28129",
    label: "Smith County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28131",
    label: "Stone County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28133",
    label: "Sunflower County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28135",
    label: "Tallahatchie County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28137",
    label: "Tate County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28139",
    label: "Tippah County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28141",
    label: "Tishomingo County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28143",
    label: "Tunica County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28145",
    label: "Union County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28147",
    label: "Walthall County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28149",
    label: "Warren County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28151",
    label: "Washington County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28153",
    label: "Wayne County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28155",
    label: "Webster County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28157",
    label: "Wilkinson County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28159",
    label: "Winston County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28161",
    label: "Yalobusha County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "28163",
    label: "Yazoo County, Mississippi",
    stateCode: "28",
    stateAbbr: "MS",
  },
  {
    value: "29001",
    label: "Adair County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29003",
    label: "Andrew County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29005",
    label: "Atchison County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29007",
    label: "Audrain County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29009",
    label: "Barry County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29011",
    label: "Barton County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29013",
    label: "Bates County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29015",
    label: "Benton County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29017",
    label: "Bollinger County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29019",
    label: "Boone County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29021",
    label: "Buchanan County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29023",
    label: "Butler County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29025",
    label: "Caldwell County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29027",
    label: "Callaway County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29029",
    label: "Camden County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29031",
    label: "Cape Girardeau County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29033",
    label: "Carroll County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29035",
    label: "Carter County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29037",
    label: "Cass County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29039",
    label: "Cedar County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29041",
    label: "Chariton County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29043",
    label: "Christian County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29045",
    label: "Clark County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29047",
    label: "Clay County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29049",
    label: "Clinton County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29051",
    label: "Cole County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29053",
    label: "Cooper County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29055",
    label: "Crawford County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29057",
    label: "Dade County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29059",
    label: "Dallas County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29061",
    label: "Daviess County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29063",
    label: "DeKalb County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29065",
    label: "Dent County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29067",
    label: "Douglas County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29069",
    label: "Dunklin County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29071",
    label: "Franklin County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29073",
    label: "Gasconade County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29075",
    label: "Gentry County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29077",
    label: "Greene County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29079",
    label: "Grundy County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29081",
    label: "Harrison County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29083",
    label: "Henry County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29085",
    label: "Hickory County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29087",
    label: "Holt County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29089",
    label: "Howard County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29091",
    label: "Howell County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29093",
    label: "Iron County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29095",
    label: "Jackson County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29097",
    label: "Jasper County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29099",
    label: "Jefferson County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29101",
    label: "Johnson County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29103",
    label: "Knox County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29105",
    label: "Laclede County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29107",
    label: "Lafayette County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29109",
    label: "Lawrence County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29111",
    label: "Lewis County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29113",
    label: "Lincoln County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29115",
    label: "Linn County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29117",
    label: "Livingston County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29119",
    label: "McDonald County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29121",
    label: "Macon County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29123",
    label: "Madison County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29125",
    label: "Maries County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29127",
    label: "Marion County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29129",
    label: "Mercer County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29131",
    label: "Miller County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29133",
    label: "Mississippi County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29135",
    label: "Moniteau County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29137",
    label: "Monroe County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29139",
    label: "Montgomery County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29141",
    label: "Morgan County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29143",
    label: "New Madrid County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29145",
    label: "Newton County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29147",
    label: "Nodaway County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29149",
    label: "Oregon County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29151",
    label: "Osage County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29153",
    label: "Ozark County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29155",
    label: "Pemiscot County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29157",
    label: "Perry County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29159",
    label: "Pettis County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29161",
    label: "Phelps County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29163",
    label: "Pike County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29165",
    label: "Platte County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29167",
    label: "Polk County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29169",
    label: "Pulaski County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29171",
    label: "Putnam County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29173",
    label: "Ralls County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29175",
    label: "Randolph County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29177",
    label: "Ray County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29179",
    label: "Reynolds County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29181",
    label: "Ripley County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29183",
    label: "St. Charles County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29185",
    label: "St. Clair County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29186",
    label: "Ste. Genevieve County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29187",
    label: "St. Francois County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29189",
    label: "St. Louis County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29195",
    label: "Saline County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29197",
    label: "Schuyler County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29199",
    label: "Scotland County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29201",
    label: "Scott County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29203",
    label: "Shannon County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29205",
    label: "Shelby County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29207",
    label: "Stoddard County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29209",
    label: "Stone County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29211",
    label: "Sullivan County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29213",
    label: "Taney County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29215",
    label: "Texas County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29217",
    label: "Vernon County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29219",
    label: "Warren County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29221",
    label: "Washington County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29223",
    label: "Wayne County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29225",
    label: "Webster County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29227",
    label: "Worth County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29229",
    label: "Wright County, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "29510",
    label: "St. Louis city, Missouri",
    stateCode: "29",
    stateAbbr: "MO",
  },
  {
    value: "30001",
    label: "Beaverhead County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30003",
    label: "Big Horn County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30005",
    label: "Blaine County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30007",
    label: "Broadwater County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30009",
    label: "Carbon County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30011",
    label: "Carter County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30013",
    label: "Cascade County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30015",
    label: "Chouteau County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30017",
    label: "Custer County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30019",
    label: "Daniels County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30021",
    label: "Dawson County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30023",
    label: "Deer Lodge County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30025",
    label: "Fallon County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30027",
    label: "Fergus County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30029",
    label: "Flathead County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30031",
    label: "Gallatin County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30033",
    label: "Garfield County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30035",
    label: "Glacier County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30037",
    label: "Golden Valley County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30039",
    label: "Granite County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30041",
    label: "Hill County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30043",
    label: "Jefferson County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30045",
    label: "Judith Basin County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30047",
    label: "Lake County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30049",
    label: "Lewis and Clark County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30051",
    label: "Liberty County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30053",
    label: "Lincoln County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30055",
    label: "McCone County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30057",
    label: "Madison County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30059",
    label: "Meagher County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30061",
    label: "Mineral County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30063",
    label: "Missoula County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30065",
    label: "Musselshell County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30067",
    label: "Park County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30069",
    label: "Petroleum County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30071",
    label: "Phillips County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30073",
    label: "Pondera County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30075",
    label: "Powder River County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30077",
    label: "Powell County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30079",
    label: "Prairie County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30081",
    label: "Ravalli County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30083",
    label: "Richland County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30085",
    label: "Roosevelt County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30087",
    label: "Rosebud County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30089",
    label: "Sanders County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30091",
    label: "Sheridan County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30093",
    label: "Silver Bow County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30095",
    label: "Stillwater County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30097",
    label: "Sweet Grass County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30099",
    label: "Teton County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30101",
    label: "Toole County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30103",
    label: "Treasure County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30105",
    label: "Valley County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30107",
    label: "Wheatland County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30109",
    label: "Wibaux County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "30111",
    label: "Yellowstone County, Montana",
    stateCode: "30",
    stateAbbr: "MT",
  },
  {
    value: "31001",
    label: "Adams County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31003",
    label: "Antelope County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31005",
    label: "Arthur County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31007",
    label: "Banner County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31009",
    label: "Blaine County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31011",
    label: "Boone County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31013",
    label: "Box Butte County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31015",
    label: "Boyd County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31017",
    label: "Brown County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31019",
    label: "Buffalo County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31021",
    label: "Burt County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31023",
    label: "Butler County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31025",
    label: "Cass County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31027",
    label: "Cedar County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31029",
    label: "Chase County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31031",
    label: "Cherry County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31033",
    label: "Cheyenne County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31035",
    label: "Clay County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31037",
    label: "Colfax County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31039",
    label: "Cuming County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31041",
    label: "Custer County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31043",
    label: "Dakota County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31045",
    label: "Dawes County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31047",
    label: "Dawson County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31049",
    label: "Deuel County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31051",
    label: "Dixon County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31053",
    label: "Dodge County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31055",
    label: "Douglas County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31057",
    label: "Dundy County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31059",
    label: "Fillmore County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31061",
    label: "Franklin County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31063",
    label: "Frontier County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31065",
    label: "Furnas County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31067",
    label: "Gage County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31069",
    label: "Garden County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31071",
    label: "Garfield County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31073",
    label: "Gosper County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31075",
    label: "Grant County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31077",
    label: "Greeley County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31079",
    label: "Hall County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31081",
    label: "Hamilton County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31083",
    label: "Harlan County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31085",
    label: "Hayes County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31087",
    label: "Hitchcock County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31089",
    label: "Holt County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31091",
    label: "Hooker County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31093",
    label: "Howard County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31095",
    label: "Jefferson County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31097",
    label: "Johnson County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31099",
    label: "Kearney County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31101",
    label: "Keith County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31103",
    label: "Keya Paha County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31105",
    label: "Kimball County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31107",
    label: "Knox County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31109",
    label: "Lancaster County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31111",
    label: "Lincoln County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31113",
    label: "Logan County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31115",
    label: "Loup County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31117",
    label: "McPherson County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31119",
    label: "Madison County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31121",
    label: "Merrick County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31123",
    label: "Morrill County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31125",
    label: "Nance County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31127",
    label: "Nemaha County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31129",
    label: "Nuckolls County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31131",
    label: "Otoe County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31133",
    label: "Pawnee County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31135",
    label: "Perkins County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31137",
    label: "Phelps County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31139",
    label: "Pierce County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31141",
    label: "Platte County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31143",
    label: "Polk County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31145",
    label: "Red Willow County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31147",
    label: "Richardson County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31149",
    label: "Rock County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31151",
    label: "Saline County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31153",
    label: "Sarpy County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31155",
    label: "Saunders County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31157",
    label: "Scotts Bluff County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31159",
    label: "Seward County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31161",
    label: "Sheridan County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31163",
    label: "Sherman County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31165",
    label: "Sioux County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31167",
    label: "Stanton County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31169",
    label: "Thayer County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31171",
    label: "Thomas County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31173",
    label: "Thurston County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31175",
    label: "Valley County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31177",
    label: "Washington County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31179",
    label: "Wayne County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31181",
    label: "Webster County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31183",
    label: "Wheeler County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "31185",
    label: "York County, Nebraska",
    stateCode: "31",
    stateAbbr: "NE",
  },
  {
    value: "32001",
    label: "Churchill County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32003",
    label: "Clark County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32005",
    label: "Douglas County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32007",
    label: "Elko County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32009",
    label: "Esmeralda County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32011",
    label: "Eureka County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32013",
    label: "Humboldt County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32015",
    label: "Lander County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32017",
    label: "Lincoln County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32019",
    label: "Lyon County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32021",
    label: "Mineral County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32023",
    label: "Nye County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32027",
    label: "Pershing County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32029",
    label: "Storey County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32031",
    label: "Washoe County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32033",
    label: "White Pine County, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "32510",
    label: "Carson City, Nevada",
    stateCode: "32",
    stateAbbr: "NV",
  },
  {
    value: "33001",
    label: "Belknap County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33003",
    label: "Carroll County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33005",
    label: "Cheshire County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33007",
    label: "Coos County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33009",
    label: "Grafton County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33011",
    label: "Hillsborough County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33013",
    label: "Merrimack County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33015",
    label: "Rockingham County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33017",
    label: "Strafford County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "33019",
    label: "Sullivan County, New Hampshire",
    stateCode: "33",
    stateAbbr: "NH",
  },
  {
    value: "34001",
    label: "Atlantic County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34003",
    label: "Bergen County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34005",
    label: "Burlington County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34007",
    label: "Camden County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34009",
    label: "Cape May County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34011",
    label: "Cumberland County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34013",
    label: "Essex County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34015",
    label: "Gloucester County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34017",
    label: "Hudson County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34019",
    label: "Hunterdon County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34021",
    label: "Mercer County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34023",
    label: "Middlesex County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34025",
    label: "Monmouth County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34027",
    label: "Morris County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34029",
    label: "Ocean County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34031",
    label: "Passaic County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34033",
    label: "Salem County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34035",
    label: "Somerset County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34037",
    label: "Sussex County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34039",
    label: "Union County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "34041",
    label: "Warren County, New Jersey",
    stateCode: "34",
    stateAbbr: "NJ",
  },
  {
    value: "35001",
    label: "Bernalillo County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35003",
    label: "Catron County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35005",
    label: "Chaves County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35006",
    label: "Cibola County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35007",
    label: "Colfax County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35009",
    label: "Curry County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35011",
    label: "De Baca County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35013",
    label: "Doña Ana County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35015",
    label: "Eddy County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35017",
    label: "Grant County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35019",
    label: "Guadalupe County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35021",
    label: "Harding County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35023",
    label: "Hidalgo County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35025",
    label: "Lea County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35027",
    label: "Lincoln County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35028",
    label: "Los Alamos County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35029",
    label: "Luna County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35031",
    label: "McKinley County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35033",
    label: "Mora County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35035",
    label: "Otero County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35037",
    label: "Quay County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35039",
    label: "Rio Arriba County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35041",
    label: "Roosevelt County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35043",
    label: "Sandoval County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35045",
    label: "San Juan County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35047",
    label: "San Miguel County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35049",
    label: "Santa Fe County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35051",
    label: "Sierra County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35053",
    label: "Socorro County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35055",
    label: "Taos County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35057",
    label: "Torrance County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35059",
    label: "Union County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "35061",
    label: "Valencia County, New Mexico",
    stateCode: "35",
    stateAbbr: "NM",
  },
  {
    value: "36001",
    label: "Albany County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36003",
    label: "Allegany County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36005",
    label: "Bronx County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36007",
    label: "Broome County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36009",
    label: "Cattaraugus County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36011",
    label: "Cayuga County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36013",
    label: "Chautauqua County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36015",
    label: "Chemung County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36017",
    label: "Chenango County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36019",
    label: "Clinton County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36021",
    label: "Columbia County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36023",
    label: "Cortland County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36025",
    label: "Delaware County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36027",
    label: "Dutchess County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36029",
    label: "Erie County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36031",
    label: "Essex County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36033",
    label: "Franklin County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36035",
    label: "Fulton County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36037",
    label: "Genesee County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36039",
    label: "Greene County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36041",
    label: "Hamilton County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36043",
    label: "Herkimer County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36045",
    label: "Jefferson County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36047",
    label: "Kings County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36049",
    label: "Lewis County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36051",
    label: "Livingston County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36053",
    label: "Madison County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36055",
    label: "Monroe County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36057",
    label: "Montgomery County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36059",
    label: "Nassau County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36061",
    label: "New York County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36063",
    label: "Niagara County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36065",
    label: "Oneida County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36067",
    label: "Onondaga County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36069",
    label: "Ontario County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36071",
    label: "Orange County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36073",
    label: "Orleans County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36075",
    label: "Oswego County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36077",
    label: "Otsego County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36079",
    label: "Putnam County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36081",
    label: "Queens County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36083",
    label: "Rensselaer County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36085",
    label: "Richmond County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36087",
    label: "Rockland County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36089",
    label: "St. Lawrence County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36091",
    label: "Saratoga County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36093",
    label: "Schenectady County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36095",
    label: "Schoharie County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36097",
    label: "Schuyler County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36099",
    label: "Seneca County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36101",
    label: "Steuben County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36103",
    label: "Suffolk County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36105",
    label: "Sullivan County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36107",
    label: "Tioga County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36109",
    label: "Tompkins County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36111",
    label: "Ulster County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36113",
    label: "Warren County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36115",
    label: "Washington County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36117",
    label: "Wayne County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36119",
    label: "Westchester County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36121",
    label: "Wyoming County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "36123",
    label: "Yates County, New York",
    stateCode: "36",
    stateAbbr: "NY",
  },
  {
    value: "37001",
    label: "Alamance County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37003",
    label: "Alexander County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37005",
    label: "Alleghany County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37007",
    label: "Anson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37009",
    label: "Ashe County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37011",
    label: "Avery County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37013",
    label: "Beaufort County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37015",
    label: "Bertie County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37017",
    label: "Bladen County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37019",
    label: "Brunswick County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37021",
    label: "Buncombe County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37023",
    label: "Burke County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37025",
    label: "Cabarrus County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37027",
    label: "Caldwell County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37029",
    label: "Camden County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37031",
    label: "Carteret County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37033",
    label: "Caswell County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37035",
    label: "Catawba County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37037",
    label: "Chatham County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37039",
    label: "Cherokee County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37041",
    label: "Chowan County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37043",
    label: "Clay County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37045",
    label: "Cleveland County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37047",
    label: "Columbus County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37049",
    label: "Craven County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37051",
    label: "Cumberland County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37053",
    label: "Currituck County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37055",
    label: "Dare County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37057",
    label: "Davidson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37059",
    label: "Davie County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37061",
    label: "Duplin County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37063",
    label: "Durham County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37065",
    label: "Edgecombe County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37067",
    label: "Forsyth County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37069",
    label: "Franklin County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37071",
    label: "Gaston County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37073",
    label: "Gates County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37075",
    label: "Graham County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37077",
    label: "Granville County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37079",
    label: "Greene County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37081",
    label: "Guilford County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37083",
    label: "Halifax County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37085",
    label: "Harnett County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37087",
    label: "Haywood County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37089",
    label: "Henderson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37091",
    label: "Hertford County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37093",
    label: "Hoke County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37095",
    label: "Hyde County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37097",
    label: "Iredell County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37099",
    label: "Jackson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37101",
    label: "Johnston County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37103",
    label: "Jones County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37105",
    label: "Lee County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37107",
    label: "Lenoir County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37109",
    label: "Lincoln County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37111",
    label: "McDowell County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37113",
    label: "Macon County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37115",
    label: "Madison County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37117",
    label: "Martin County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37119",
    label: "Mecklenburg County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37121",
    label: "Mitchell County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37123",
    label: "Montgomery County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37125",
    label: "Moore County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37127",
    label: "Nash County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37129",
    label: "New Hanover County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37131",
    label: "Northampton County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37133",
    label: "Onslow County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37135",
    label: "Orange County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37137",
    label: "Pamlico County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37139",
    label: "Pasquotank County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37141",
    label: "Pender County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37143",
    label: "Perquimans County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37145",
    label: "Person County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37147",
    label: "Pitt County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37149",
    label: "Polk County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37151",
    label: "Randolph County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37153",
    label: "Richmond County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37155",
    label: "Robeson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37157",
    label: "Rockingham County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37159",
    label: "Rowan County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37161",
    label: "Rutherford County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37163",
    label: "Sampson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37165",
    label: "Scotland County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37167",
    label: "Stanly County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37169",
    label: "Stokes County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37171",
    label: "Surry County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37173",
    label: "Swain County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37175",
    label: "Transylvania County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37177",
    label: "Tyrrell County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37179",
    label: "Union County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37181",
    label: "Vance County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37183",
    label: "Wake County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37185",
    label: "Warren County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37187",
    label: "Washington County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37189",
    label: "Watauga County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37191",
    label: "Wayne County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37193",
    label: "Wilkes County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37195",
    label: "Wilson County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37197",
    label: "Yadkin County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "37199",
    label: "Yancey County, North Carolina",
    stateCode: "37",
    stateAbbr: "NC",
  },
  {
    value: "38001",
    label: "Adams County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38003",
    label: "Barnes County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38005",
    label: "Benson County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38007",
    label: "Billings County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38009",
    label: "Bottineau County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38011",
    label: "Bowman County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38013",
    label: "Burke County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38015",
    label: "Burleigh County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38017",
    label: "Cass County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38019",
    label: "Cavalier County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38021",
    label: "Dickey County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38023",
    label: "Divide County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38025",
    label: "Dunn County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38027",
    label: "Eddy County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38029",
    label: "Emmons County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38031",
    label: "Foster County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38033",
    label: "Golden Valley County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38035",
    label: "Grand Forks County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38037",
    label: "Grant County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38039",
    label: "Griggs County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38041",
    label: "Hettinger County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38043",
    label: "Kidder County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38045",
    label: "LaMoure County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38047",
    label: "Logan County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38049",
    label: "McHenry County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38051",
    label: "McIntosh County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38053",
    label: "McKenzie County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38055",
    label: "McLean County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38057",
    label: "Mercer County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38059",
    label: "Morton County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38061",
    label: "Mountrail County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38063",
    label: "Nelson County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38065",
    label: "Oliver County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38067",
    label: "Pembina County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38069",
    label: "Pierce County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38071",
    label: "Ramsey County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38073",
    label: "Ransom County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38075",
    label: "Renville County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38077",
    label: "Richland County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38079",
    label: "Rolette County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38081",
    label: "Sargent County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38083",
    label: "Sheridan County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38085",
    label: "Sioux County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38087",
    label: "Slope County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38089",
    label: "Stark County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38091",
    label: "Steele County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38093",
    label: "Stutsman County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38095",
    label: "Towner County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38097",
    label: "Traill County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38099",
    label: "Walsh County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38101",
    label: "Ward County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38103",
    label: "Wells County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "38105",
    label: "Williams County, North Dakota",
    stateCode: "38",
    stateAbbr: "ND",
  },
  {
    value: "39001",
    label: "Adams County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39003",
    label: "Allen County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39005",
    label: "Ashland County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39007",
    label: "Ashtabula County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39009",
    label: "Athens County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39011",
    label: "Auglaize County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39013",
    label: "Belmont County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39015",
    label: "Brown County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39017",
    label: "Butler County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39019",
    label: "Carroll County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39021",
    label: "Champaign County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39023",
    label: "Clark County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39025",
    label: "Clermont County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39027",
    label: "Clinton County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39029",
    label: "Columbiana County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39031",
    label: "Coshocton County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39033",
    label: "Crawford County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39035",
    label: "Cuyahoga County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39037",
    label: "Darke County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39039",
    label: "Defiance County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39041",
    label: "Delaware County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39043",
    label: "Erie County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39045",
    label: "Fairfield County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39047",
    label: "Fayette County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39049",
    label: "Franklin County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39051",
    label: "Fulton County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39053",
    label: "Gallia County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39055",
    label: "Geauga County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39057",
    label: "Greene County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39059",
    label: "Guernsey County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39061",
    label: "Hamilton County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39063",
    label: "Hancock County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39065",
    label: "Hardin County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39067",
    label: "Harrison County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39069",
    label: "Henry County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39071",
    label: "Highland County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39073",
    label: "Hocking County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39075",
    label: "Holmes County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39077",
    label: "Huron County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39079",
    label: "Jackson County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39081",
    label: "Jefferson County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39083",
    label: "Knox County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39085",
    label: "Lake County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39087",
    label: "Lawrence County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39089",
    label: "Licking County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39091",
    label: "Logan County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39093",
    label: "Lorain County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39095",
    label: "Lucas County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39097",
    label: "Madison County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39099",
    label: "Mahoning County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39101",
    label: "Marion County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39103",
    label: "Medina County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39105",
    label: "Meigs County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39107",
    label: "Mercer County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39109",
    label: "Miami County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39111",
    label: "Monroe County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39113",
    label: "Montgomery County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39115",
    label: "Morgan County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39117",
    label: "Morrow County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39119",
    label: "Muskingum County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39121",
    label: "Noble County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39123",
    label: "Ottawa County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39125",
    label: "Paulding County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39127",
    label: "Perry County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39129",
    label: "Pickaway County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39131",
    label: "Pike County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39133",
    label: "Portage County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39135",
    label: "Preble County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39137",
    label: "Putnam County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39139",
    label: "Richland County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39141",
    label: "Ross County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39143",
    label: "Sandusky County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39145",
    label: "Scioto County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39147",
    label: "Seneca County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39149",
    label: "Shelby County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39151",
    label: "Stark County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39153",
    label: "Summit County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39155",
    label: "Trumbull County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39157",
    label: "Tuscarawas County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39159",
    label: "Union County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39161",
    label: "Van Wert County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39163",
    label: "Vinton County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39165",
    label: "Warren County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39167",
    label: "Washington County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39169",
    label: "Wayne County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39171",
    label: "Williams County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39173",
    label: "Wood County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "39175",
    label: "Wyandot County, Ohio",
    stateCode: "39",
    stateAbbr: "OH",
  },
  {
    value: "40001",
    label: "Adair County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40003",
    label: "Alfalfa County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40005",
    label: "Atoka County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40007",
    label: "Beaver County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40009",
    label: "Beckham County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40011",
    label: "Blaine County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40013",
    label: "Bryan County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40015",
    label: "Caddo County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40017",
    label: "Canadian County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40019",
    label: "Carter County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40021",
    label: "Cherokee County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40023",
    label: "Choctaw County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40025",
    label: "Cimarron County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40027",
    label: "Cleveland County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40029",
    label: "Coal County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40031",
    label: "Comanche County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40033",
    label: "Cotton County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40035",
    label: "Craig County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40037",
    label: "Creek County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40039",
    label: "Custer County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40041",
    label: "Delaware County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40043",
    label: "Dewey County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40045",
    label: "Ellis County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40047",
    label: "Garfield County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40049",
    label: "Garvin County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40051",
    label: "Grady County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40053",
    label: "Grant County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40055",
    label: "Greer County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40057",
    label: "Harmon County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40059",
    label: "Harper County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40061",
    label: "Haskell County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40063",
    label: "Hughes County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40065",
    label: "Jackson County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40067",
    label: "Jefferson County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40069",
    label: "Johnston County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40071",
    label: "Kay County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40073",
    label: "Kingfisher County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40075",
    label: "Kiowa County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40077",
    label: "Latimer County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40079",
    label: "Le Flore County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40081",
    label: "Lincoln County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40083",
    label: "Logan County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40085",
    label: "Love County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40087",
    label: "McClain County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40089",
    label: "McCurtain County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40091",
    label: "McIntosh County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40093",
    label: "Major County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40095",
    label: "Marshall County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40097",
    label: "Mayes County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40099",
    label: "Murray County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40101",
    label: "Muskogee County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40103",
    label: "Noble County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40105",
    label: "Nowata County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40107",
    label: "Okfuskee County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40109",
    label: "Oklahoma County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40111",
    label: "Okmulgee County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40113",
    label: "Osage County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40115",
    label: "Ottawa County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40117",
    label: "Pawnee County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40119",
    label: "Payne County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40121",
    label: "Pittsburg County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40123",
    label: "Pontotoc County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40125",
    label: "Pottawatomie County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40127",
    label: "Pushmataha County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40129",
    label: "Roger Mills County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40131",
    label: "Rogers County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40133",
    label: "Seminole County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40135",
    label: "Sequoyah County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40137",
    label: "Stephens County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40139",
    label: "Texas County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40141",
    label: "Tillman County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40143",
    label: "Tulsa County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40145",
    label: "Wagoner County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40147",
    label: "Washington County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40149",
    label: "Washita County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40151",
    label: "Woods County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "40153",
    label: "Woodward County, Oklahoma",
    stateCode: "40",
    stateAbbr: "OK",
  },
  {
    value: "41001",
    label: "Baker County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41003",
    label: "Benton County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41005",
    label: "Clackamas County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41007",
    label: "Clatsop County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41009",
    label: "Columbia County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41011",
    label: "Coos County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41013",
    label: "Crook County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41015",
    label: "Curry County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41017",
    label: "Deschutes County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41019",
    label: "Douglas County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41021",
    label: "Gilliam County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41023",
    label: "Grant County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41025",
    label: "Harney County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41027",
    label: "Hood River County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41029",
    label: "Jackson County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41031",
    label: "Jefferson County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41033",
    label: "Josephine County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41035",
    label: "Klamath County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41037",
    label: "Lake County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41039",
    label: "Lane County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41041",
    label: "Lincoln County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41043",
    label: "Linn County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41045",
    label: "Malheur County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41047",
    label: "Marion County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41049",
    label: "Morrow County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41051",
    label: "Multnomah County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41053",
    label: "Polk County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41055",
    label: "Sherman County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41057",
    label: "Tillamook County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41059",
    label: "Umatilla County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41061",
    label: "Union County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41063",
    label: "Wallowa County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41065",
    label: "Wasco County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41067",
    label: "Washington County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41069",
    label: "Wheeler County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "41071",
    label: "Yamhill County, Oregon",
    stateCode: "41",
    stateAbbr: "OR",
  },
  {
    value: "42001",
    label: "Adams County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42003",
    label: "Allegheny County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42005",
    label: "Armstrong County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42007",
    label: "Beaver County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42009",
    label: "Bedford County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42011",
    label: "Berks County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42013",
    label: "Blair County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42015",
    label: "Bradford County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42017",
    label: "Bucks County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42019",
    label: "Butler County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42021",
    label: "Cambria County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42023",
    label: "Cameron County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42025",
    label: "Carbon County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42027",
    label: "Centre County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42029",
    label: "Chester County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42031",
    label: "Clarion County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42033",
    label: "Clearfield County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42035",
    label: "Clinton County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42037",
    label: "Columbia County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42039",
    label: "Crawford County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42041",
    label: "Cumberland County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42043",
    label: "Dauphin County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42045",
    label: "Delaware County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42047",
    label: "Elk County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42049",
    label: "Erie County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42051",
    label: "Fayette County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42053",
    label: "Forest County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42055",
    label: "Franklin County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42057",
    label: "Fulton County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42059",
    label: "Greene County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42061",
    label: "Huntingdon County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42063",
    label: "Indiana County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42065",
    label: "Jefferson County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42067",
    label: "Juniata County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42069",
    label: "Lackawanna County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42071",
    label: "Lancaster County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42073",
    label: "Lawrence County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42075",
    label: "Lebanon County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42077",
    label: "Lehigh County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42079",
    label: "Luzerne County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42081",
    label: "Lycoming County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42083",
    label: "McKean County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42085",
    label: "Mercer County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42087",
    label: "Mifflin County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42089",
    label: "Monroe County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42091",
    label: "Montgomery County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42093",
    label: "Montour County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42095",
    label: "Northampton County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42097",
    label: "Northumberland County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42099",
    label: "Perry County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42101",
    label: "Philadelphia County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42103",
    label: "Pike County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42105",
    label: "Potter County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42107",
    label: "Schuylkill County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42109",
    label: "Snyder County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42111",
    label: "Somerset County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42113",
    label: "Sullivan County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42115",
    label: "Susquehanna County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42117",
    label: "Tioga County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42119",
    label: "Union County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42121",
    label: "Venango County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42123",
    label: "Warren County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42125",
    label: "Washington County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42127",
    label: "Wayne County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42129",
    label: "Westmoreland County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42131",
    label: "Wyoming County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "42133",
    label: "York County, Pennsylvania",
    stateCode: "42",
    stateAbbr: "PA",
  },
  {
    value: "44001",
    label: "Bristol County, Rhode Island",
    stateCode: "44",
    stateAbbr: "RI",
  },
  {
    value: "44003",
    label: "Kent County, Rhode Island",
    stateCode: "44",
    stateAbbr: "RI",
  },
  {
    value: "44005",
    label: "Newport County, Rhode Island",
    stateCode: "44",
    stateAbbr: "RI",
  },
  {
    value: "44007",
    label: "Providence County, Rhode Island",
    stateCode: "44",
    stateAbbr: "RI",
  },
  {
    value: "44009",
    label: "Washington County, Rhode Island",
    stateCode: "44",
    stateAbbr: "RI",
  },
  {
    value: "45001",
    label: "Abbeville County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45003",
    label: "Aiken County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45005",
    label: "Allendale County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45007",
    label: "Anderson County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45009",
    label: "Bamberg County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45011",
    label: "Barnwell County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45013",
    label: "Beaufort County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45015",
    label: "Berkeley County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45017",
    label: "Calhoun County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45019",
    label: "Charleston County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45021",
    label: "Cherokee County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45023",
    label: "Chester County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45025",
    label: "Chesterfield County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45027",
    label: "Clarendon County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45029",
    label: "Colleton County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45031",
    label: "Darlington County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45033",
    label: "Dillon County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45035",
    label: "Dorchester County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45037",
    label: "Edgefield County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45039",
    label: "Fairfield County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45041",
    label: "Florence County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45043",
    label: "Georgetown County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45045",
    label: "Greenville County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45047",
    label: "Greenwood County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45049",
    label: "Hampton County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45051",
    label: "Horry County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45053",
    label: "Jasper County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45055",
    label: "Kershaw County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45057",
    label: "Lancaster County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45059",
    label: "Laurens County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45061",
    label: "Lee County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45063",
    label: "Lexington County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45065",
    label: "McCormick County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45067",
    label: "Marion County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45069",
    label: "Marlboro County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45071",
    label: "Newberry County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45073",
    label: "Oconee County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45075",
    label: "Orangeburg County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45077",
    label: "Pickens County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45079",
    label: "Richland County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45081",
    label: "Saluda County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45083",
    label: "Spartanburg County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45085",
    label: "Sumter County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45087",
    label: "Union County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45089",
    label: "Williamsburg County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "45091",
    label: "York County, South Carolina",
    stateCode: "45",
    stateAbbr: "SC",
  },
  {
    value: "46003",
    label: "Aurora County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46005",
    label: "Beadle County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46007",
    label: "Bennett County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46009",
    label: "Bon Homme County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46011",
    label: "Brookings County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46013",
    label: "Brown County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46015",
    label: "Brule County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46017",
    label: "Buffalo County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46019",
    label: "Butte County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46021",
    label: "Campbell County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46023",
    label: "Charles Mix County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46025",
    label: "Clark County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46027",
    label: "Clay County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46029",
    label: "Codington County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46031",
    label: "Corson County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46033",
    label: "Custer County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46035",
    label: "Davison County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46037",
    label: "Day County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46039",
    label: "Deuel County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46041",
    label: "Dewey County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46043",
    label: "Douglas County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46045",
    label: "Edmunds County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46047",
    label: "Fall River County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46049",
    label: "Faulk County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46051",
    label: "Grant County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46053",
    label: "Gregory County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46055",
    label: "Haakon County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46057",
    label: "Hamlin County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46059",
    label: "Hand County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46061",
    label: "Hanson County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46063",
    label: "Harding County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46065",
    label: "Hughes County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46067",
    label: "Hutchinson County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46069",
    label: "Hyde County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46071",
    label: "Jackson County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46073",
    label: "Jerauld County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46075",
    label: "Jones County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46077",
    label: "Kingsbury County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46079",
    label: "Lake County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46081",
    label: "Lawrence County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46083",
    label: "Lincoln County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46085",
    label: "Lyman County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46087",
    label: "McCook County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46089",
    label: "McPherson County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46091",
    label: "Marshall County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46093",
    label: "Meade County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46095",
    label: "Mellette County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46097",
    label: "Miner County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46099",
    label: "Minnehaha County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46101",
    label: "Moody County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46102",
    label: "Oglala Lakota County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46103",
    label: "Pennington County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46105",
    label: "Perkins County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46107",
    label: "Potter County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46109",
    label: "Roberts County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46111",
    label: "Sanborn County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46115",
    label: "Spink County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46117",
    label: "Stanley County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46119",
    label: "Sully County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46121",
    label: "Todd County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46123",
    label: "Tripp County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46125",
    label: "Turner County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46127",
    label: "Union County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46129",
    label: "Walworth County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46135",
    label: "Yankton County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "46137",
    label: "Ziebach County, South Dakota",
    stateCode: "46",
    stateAbbr: "SD",
  },
  {
    value: "47001",
    label: "Anderson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47003",
    label: "Bedford County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47005",
    label: "Benton County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47007",
    label: "Bledsoe County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47009",
    label: "Blount County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47011",
    label: "Bradley County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47013",
    label: "Campbell County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47015",
    label: "Cannon County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47017",
    label: "Carroll County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47019",
    label: "Carter County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47021",
    label: "Cheatham County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47023",
    label: "Chester County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47025",
    label: "Claiborne County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47027",
    label: "Clay County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47029",
    label: "Cocke County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47031",
    label: "Coffee County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47033",
    label: "Crockett County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47035",
    label: "Cumberland County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47037",
    label: "Davidson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47039",
    label: "Decatur County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47041",
    label: "DeKalb County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47043",
    label: "Dickson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47045",
    label: "Dyer County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47047",
    label: "Fayette County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47049",
    label: "Fentress County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47051",
    label: "Franklin County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47053",
    label: "Gibson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47055",
    label: "Giles County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47057",
    label: "Grainger County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47059",
    label: "Greene County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47061",
    label: "Grundy County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47063",
    label: "Hamblen County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47065",
    label: "Hamilton County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47067",
    label: "Hancock County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47069",
    label: "Hardeman County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47071",
    label: "Hardin County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47073",
    label: "Hawkins County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47075",
    label: "Haywood County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47077",
    label: "Henderson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47079",
    label: "Henry County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47081",
    label: "Hickman County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47083",
    label: "Houston County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47085",
    label: "Humphreys County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47087",
    label: "Jackson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47089",
    label: "Jefferson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47091",
    label: "Johnson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47093",
    label: "Knox County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47095",
    label: "Lake County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47097",
    label: "Lauderdale County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47099",
    label: "Lawrence County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47101",
    label: "Lewis County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47103",
    label: "Lincoln County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47105",
    label: "Loudon County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47107",
    label: "McMinn County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47109",
    label: "McNairy County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47111",
    label: "Macon County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47113",
    label: "Madison County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47115",
    label: "Marion County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47117",
    label: "Marshall County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47119",
    label: "Maury County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47121",
    label: "Meigs County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47123",
    label: "Monroe County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47125",
    label: "Montgomery County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47127",
    label: "Moore County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47129",
    label: "Morgan County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47131",
    label: "Obion County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47133",
    label: "Overton County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47135",
    label: "Perry County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47137",
    label: "Pickett County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47139",
    label: "Polk County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47141",
    label: "Putnam County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47143",
    label: "Rhea County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47145",
    label: "Roane County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47147",
    label: "Robertson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47149",
    label: "Rutherford County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47151",
    label: "Scott County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47153",
    label: "Sequatchie County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47155",
    label: "Sevier County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47157",
    label: "Shelby County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47159",
    label: "Smith County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47161",
    label: "Stewart County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47163",
    label: "Sullivan County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47165",
    label: "Sumner County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47167",
    label: "Tipton County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47169",
    label: "Trousdale County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47171",
    label: "Unicoi County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47173",
    label: "Union County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47175",
    label: "Van Buren County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47177",
    label: "Warren County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47179",
    label: "Washington County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47181",
    label: "Wayne County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47183",
    label: "Weakley County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47185",
    label: "White County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47187",
    label: "Williamson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "47189",
    label: "Wilson County, Tennessee",
    stateCode: "47",
    stateAbbr: "TN",
  },
  {
    value: "48001",
    label: "Anderson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48003",
    label: "Andrews County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48005",
    label: "Angelina County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48007",
    label: "Aransas County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48009",
    label: "Archer County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48011",
    label: "Armstrong County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48013",
    label: "Atascosa County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48015",
    label: "Austin County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48017",
    label: "Bailey County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48019",
    label: "Bandera County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48021",
    label: "Bastrop County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48023",
    label: "Baylor County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48025",
    label: "Bee County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48027",
    label: "Bell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48029",
    label: "Bexar County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48031",
    label: "Blanco County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48033",
    label: "Borden County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48035",
    label: "Bosque County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48037",
    label: "Bowie County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48039",
    label: "Brazoria County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48041",
    label: "Brazos County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48043",
    label: "Brewster County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48045",
    label: "Briscoe County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48047",
    label: "Brooks County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48049",
    label: "Brown County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48051",
    label: "Burleson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48053",
    label: "Burnet County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48055",
    label: "Caldwell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48057",
    label: "Calhoun County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48059",
    label: "Callahan County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48061",
    label: "Cameron County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48063",
    label: "Camp County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48065",
    label: "Carson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48067",
    label: "Cass County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48069",
    label: "Castro County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48071",
    label: "Chambers County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48073",
    label: "Cherokee County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48075",
    label: "Childress County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48077",
    label: "Clay County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48079",
    label: "Cochran County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48081",
    label: "Coke County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48083",
    label: "Coleman County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48085",
    label: "Collin County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48087",
    label: "Collingsworth County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48089",
    label: "Colorado County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48091",
    label: "Comal County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48093",
    label: "Comanche County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48095",
    label: "Concho County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48097",
    label: "Cooke County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48099",
    label: "Coryell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48101",
    label: "Cottle County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48103",
    label: "Crane County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48105",
    label: "Crockett County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48107",
    label: "Crosby County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48109",
    label: "Culberson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48111",
    label: "Dallam County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48113",
    label: "Dallas County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48115",
    label: "Dawson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48117",
    label: "Deaf Smith County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48119",
    label: "Delta County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48121",
    label: "Denton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48123",
    label: "DeWitt County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48125",
    label: "Dickens County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48127",
    label: "Dimmit County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48129",
    label: "Donley County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48131",
    label: "Duval County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48133",
    label: "Eastland County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48135",
    label: "Ector County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48137",
    label: "Edwards County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48139",
    label: "Ellis County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48141",
    label: "El Paso County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48143",
    label: "Erath County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48145",
    label: "Falls County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48147",
    label: "Fannin County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48149",
    label: "Fayette County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48151",
    label: "Fisher County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48153",
    label: "Floyd County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48155",
    label: "Foard County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48157",
    label: "Fort Bend County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48159",
    label: "Franklin County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48161",
    label: "Freestone County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48163",
    label: "Frio County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48165",
    label: "Gaines County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48167",
    label: "Galveston County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48169",
    label: "Garza County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48171",
    label: "Gillespie County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48173",
    label: "Glasscock County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48175",
    label: "Goliad County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48177",
    label: "Gonzales County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48179",
    label: "Gray County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48181",
    label: "Grayson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48183",
    label: "Gregg County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48185",
    label: "Grimes County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48187",
    label: "Guadalupe County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48189",
    label: "Hale County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48191",
    label: "Hall County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48193",
    label: "Hamilton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48195",
    label: "Hansford County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48197",
    label: "Hardeman County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48199",
    label: "Hardin County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48201",
    label: "Harris County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48203",
    label: "Harrison County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48205",
    label: "Hartley County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48207",
    label: "Haskell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48209",
    label: "Hays County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48211",
    label: "Hemphill County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48213",
    label: "Henderson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48215",
    label: "Hidalgo County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48217",
    label: "Hill County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48219",
    label: "Hockley County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48221",
    label: "Hood County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48223",
    label: "Hopkins County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48225",
    label: "Houston County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48227",
    label: "Howard County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48229",
    label: "Hudspeth County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48231",
    label: "Hunt County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48233",
    label: "Hutchinson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48235",
    label: "Irion County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48237",
    label: "Jack County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48239",
    label: "Jackson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48241",
    label: "Jasper County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48243",
    label: "Jeff Davis County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48245",
    label: "Jefferson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48247",
    label: "Jim Hogg County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48249",
    label: "Jim Wells County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48251",
    label: "Johnson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48253",
    label: "Jones County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48255",
    label: "Karnes County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48257",
    label: "Kaufman County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48259",
    label: "Kendall County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48261",
    label: "Kenedy County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48263",
    label: "Kent County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48265",
    label: "Kerr County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48267",
    label: "Kimble County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48269",
    label: "King County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48271",
    label: "Kinney County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48273",
    label: "Kleberg County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48275",
    label: "Knox County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48277",
    label: "Lamar County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48279",
    label: "Lamb County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48281",
    label: "Lampasas County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48283",
    label: "La Salle County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48285",
    label: "Lavaca County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48287",
    label: "Lee County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48289",
    label: "Leon County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48291",
    label: "Liberty County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48293",
    label: "Limestone County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48295",
    label: "Lipscomb County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48297",
    label: "Live Oak County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48299",
    label: "Llano County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48301",
    label: "Loving County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48303",
    label: "Lubbock County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48305",
    label: "Lynn County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48307",
    label: "McCulloch County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48309",
    label: "McLennan County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48311",
    label: "McMullen County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48313",
    label: "Madison County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48315",
    label: "Marion County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48317",
    label: "Martin County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48319",
    label: "Mason County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48321",
    label: "Matagorda County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48323",
    label: "Maverick County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48325",
    label: "Medina County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48327",
    label: "Menard County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48329",
    label: "Midland County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48331",
    label: "Milam County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48333",
    label: "Mills County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48335",
    label: "Mitchell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48337",
    label: "Montague County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48339",
    label: "Montgomery County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48341",
    label: "Moore County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48343",
    label: "Morris County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48345",
    label: "Motley County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48347",
    label: "Nacogdoches County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48349",
    label: "Navarro County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48351",
    label: "Newton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48353",
    label: "Nolan County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48355",
    label: "Nueces County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48357",
    label: "Ochiltree County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48359",
    label: "Oldham County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48361",
    label: "Orange County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48363",
    label: "Palo Pinto County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48365",
    label: "Panola County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48367",
    label: "Parker County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48369",
    label: "Parmer County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48371",
    label: "Pecos County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48373",
    label: "Polk County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48375",
    label: "Potter County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48377",
    label: "Presidio County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48379",
    label: "Rains County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48381",
    label: "Randall County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48383",
    label: "Reagan County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48385",
    label: "Real County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48387",
    label: "Red River County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48389",
    label: "Reeves County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48391",
    label: "Refugio County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48393",
    label: "Roberts County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48395",
    label: "Robertson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48397",
    label: "Rockwall County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48399",
    label: "Runnels County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48401",
    label: "Rusk County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48403",
    label: "Sabine County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48405",
    label: "San Augustine County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48407",
    label: "San Jacinto County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48409",
    label: "San Patricio County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48411",
    label: "San Saba County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48413",
    label: "Schleicher County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48415",
    label: "Scurry County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48417",
    label: "Shackelford County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48419",
    label: "Shelby County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48421",
    label: "Sherman County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48423",
    label: "Smith County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48425",
    label: "Somervell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48427",
    label: "Starr County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48429",
    label: "Stephens County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48431",
    label: "Sterling County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48433",
    label: "Stonewall County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48435",
    label: "Sutton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48437",
    label: "Swisher County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48439",
    label: "Tarrant County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48441",
    label: "Taylor County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48443",
    label: "Terrell County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48445",
    label: "Terry County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48447",
    label: "Throckmorton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48449",
    label: "Titus County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48451",
    label: "Tom Green County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48453",
    label: "Travis County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48455",
    label: "Trinity County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48457",
    label: "Tyler County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48459",
    label: "Upshur County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48461",
    label: "Upton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48463",
    label: "Uvalde County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48465",
    label: "Val Verde County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48467",
    label: "Van Zandt County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48469",
    label: "Victoria County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48471",
    label: "Walker County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48473",
    label: "Waller County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48475",
    label: "Ward County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48477",
    label: "Washington County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48479",
    label: "Webb County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48481",
    label: "Wharton County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48483",
    label: "Wheeler County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48485",
    label: "Wichita County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48487",
    label: "Wilbarger County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48489",
    label: "Willacy County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48491",
    label: "Williamson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48493",
    label: "Wilson County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48495",
    label: "Winkler County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48497",
    label: "Wise County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48499",
    label: "Wood County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48501",
    label: "Yoakum County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48503",
    label: "Young County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48505",
    label: "Zapata County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "48507",
    label: "Zavala County, Texas",
    stateCode: "48",
    stateAbbr: "TX",
  },
  {
    value: "49001",
    label: "Beaver County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49003",
    label: "Box Elder County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49005",
    label: "Cache County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49007",
    label: "Carbon County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49009",
    label: "Daggett County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49011",
    label: "Davis County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49013",
    label: "Duchesne County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49015",
    label: "Emery County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49017",
    label: "Garfield County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49019",
    label: "Grand County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49021",
    label: "Iron County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49023",
    label: "Juab County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49025",
    label: "Kane County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49027",
    label: "Millard County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49029",
    label: "Morgan County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49031",
    label: "Piute County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49033",
    label: "Rich County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49035",
    label: "Salt Lake County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49037",
    label: "San Juan County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49039",
    label: "Sanpete County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49041",
    label: "Sevier County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49043",
    label: "Summit County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49045",
    label: "Tooele County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49047",
    label: "Uintah County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49049",
    label: "Utah County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49051",
    label: "Wasatch County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49053",
    label: "Washington County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49055",
    label: "Wayne County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "49057",
    label: "Weber County, Utah",
    stateCode: "49",
    stateAbbr: "UT",
  },
  {
    value: "50001",
    label: "Addison County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50003",
    label: "Bennington County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50005",
    label: "Caledonia County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50007",
    label: "Chittenden County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50009",
    label: "Essex County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50011",
    label: "Franklin County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50013",
    label: "Grand Isle County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50015",
    label: "Lamoille County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50017",
    label: "Orange County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50019",
    label: "Orleans County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50021",
    label: "Rutland County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50023",
    label: "Washington County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50025",
    label: "Windham County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "50027",
    label: "Windsor County, Vermont",
    stateCode: "50",
    stateAbbr: "VT",
  },
  {
    value: "51001",
    label: "Accomack County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51003",
    label: "Albemarle County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51005",
    label: "Alleghany County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51007",
    label: "Amelia County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51009",
    label: "Amherst County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51011",
    label: "Appomattox County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51013",
    label: "Arlington County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51015",
    label: "Augusta County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51017",
    label: "Bath County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51019",
    label: "Bedford County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51021",
    label: "Bland County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51023",
    label: "Botetourt County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51025",
    label: "Brunswick County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51027",
    label: "Buchanan County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51029",
    label: "Buckingham County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51031",
    label: "Campbell County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51033",
    label: "Caroline County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51035",
    label: "Carroll County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51036",
    label: "Charles City County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51037",
    label: "Charlotte County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51041",
    label: "Chesterfield County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51043",
    label: "Clarke County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51045",
    label: "Craig County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51047",
    label: "Culpeper County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51049",
    label: "Cumberland County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51051",
    label: "Dickenson County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51053",
    label: "Dinwiddie County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51057",
    label: "Essex County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51059",
    label: "Fairfax County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51061",
    label: "Fauquier County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51063",
    label: "Floyd County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51065",
    label: "Fluvanna County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51067",
    label: "Franklin County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51069",
    label: "Frederick County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51071",
    label: "Giles County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51073",
    label: "Gloucester County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51075",
    label: "Goochland County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51077",
    label: "Grayson County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51079",
    label: "Greene County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51081",
    label: "Greensville County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51083",
    label: "Halifax County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51085",
    label: "Hanover County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51087",
    label: "Henrico County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51089",
    label: "Henry County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51091",
    label: "Highland County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51093",
    label: "Isle of Wight County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51095",
    label: "James City County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51097",
    label: "King and Queen County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51099",
    label: "King George County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51101",
    label: "King William County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51103",
    label: "Lancaster County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51105",
    label: "Lee County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51107",
    label: "Loudoun County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51109",
    label: "Louisa County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51111",
    label: "Lunenburg County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51113",
    label: "Madison County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51115",
    label: "Mathews County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51117",
    label: "Mecklenburg County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51119",
    label: "Middlesex County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51121",
    label: "Montgomery County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51125",
    label: "Nelson County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51127",
    label: "New Kent County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51131",
    label: "Northampton County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51133",
    label: "Northumberland County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51135",
    label: "Nottoway County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51137",
    label: "Orange County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51139",
    label: "Page County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51141",
    label: "Patrick County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51143",
    label: "Pittsylvania County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51145",
    label: "Powhatan County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51147",
    label: "Prince Edward County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51149",
    label: "Prince George County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51153",
    label: "Prince William County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51155",
    label: "Pulaski County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51157",
    label: "Rappahannock County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51159",
    label: "Richmond County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51161",
    label: "Roanoke County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51163",
    label: "Rockbridge County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51165",
    label: "Rockingham County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51167",
    label: "Russell County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51169",
    label: "Scott County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51171",
    label: "Shenandoah County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51173",
    label: "Smyth County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51175",
    label: "Southampton County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51177",
    label: "Spotsylvania County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51179",
    label: "Stafford County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51181",
    label: "Surry County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51183",
    label: "Sussex County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51185",
    label: "Tazewell County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51187",
    label: "Warren County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51191",
    label: "Washington County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51193",
    label: "Westmoreland County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51195",
    label: "Wise County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51197",
    label: "Wythe County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51199",
    label: "York County, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51510",
    label: "Alexandria city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51520",
    label: "Bristol city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51530",
    label: "Buena Vista city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51540",
    label: "Charlottesville city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51550",
    label: "Chesapeake city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51570",
    label: "Colonial Heights city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51580",
    label: "Covington city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51590",
    label: "Danville city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51595",
    label: "Emporia city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51600",
    label: "Fairfax city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51610",
    label: "Falls Church city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51620",
    label: "Franklin city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51630",
    label: "Fredericksburg city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51640",
    label: "Galax city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51650",
    label: "Hampton city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51660",
    label: "Harrisonburg city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51670",
    label: "Hopewell city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51678",
    label: "Lexington city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51680",
    label: "Lynchburg city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51683",
    label: "Manassas city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51685",
    label: "Manassas Park city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51690",
    label: "Martinsville city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51700",
    label: "Newport News city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51710",
    label: "Norfolk city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51720",
    label: "Norton city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51730",
    label: "Petersburg city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51735",
    label: "Poquoson city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51740",
    label: "Portsmouth city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51750",
    label: "Radford city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51760",
    label: "Richmond city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51770",
    label: "Roanoke city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51775",
    label: "Salem city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51790",
    label: "Staunton city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51800",
    label: "Suffolk city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51810",
    label: "Virginia Beach city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51820",
    label: "Waynesboro city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51830",
    label: "Williamsburg city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "51840",
    label: "Winchester city, Virginia",
    stateCode: "51",
    stateAbbr: "VA",
  },
  {
    value: "53001",
    label: "Adams County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53003",
    label: "Asotin County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53005",
    label: "Benton County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53007",
    label: "Chelan County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53009",
    label: "Clallam County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53011",
    label: "Clark County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53013",
    label: "Columbia County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53015",
    label: "Cowlitz County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53017",
    label: "Douglas County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53019",
    label: "Ferry County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53021",
    label: "Franklin County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53023",
    label: "Garfield County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53025",
    label: "Grant County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53027",
    label: "Grays Harbor County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53029",
    label: "Island County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53031",
    label: "Jefferson County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53033",
    label: "King County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53035",
    label: "Kitsap County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53037",
    label: "Kittitas County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53039",
    label: "Klickitat County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53041",
    label: "Lewis County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53043",
    label: "Lincoln County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53045",
    label: "Mason County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53047",
    label: "Okanogan County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53049",
    label: "Pacific County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53051",
    label: "Pend Oreille County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53053",
    label: "Pierce County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53055",
    label: "San Juan County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53057",
    label: "Skagit County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53059",
    label: "Skamania County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53061",
    label: "Snohomish County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53063",
    label: "Spokane County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53065",
    label: "Stevens County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53067",
    label: "Thurston County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53069",
    label: "Wahkiakum County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53071",
    label: "Walla Walla County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53073",
    label: "Whatcom County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53075",
    label: "Whitman County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "53077",
    label: "Yakima County, Washington",
    stateCode: "53",
    stateAbbr: "WA",
  },
  {
    value: "54001",
    label: "Barbour County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54003",
    label: "Berkeley County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54005",
    label: "Boone County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54007",
    label: "Braxton County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54009",
    label: "Brooke County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54011",
    label: "Cabell County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54013",
    label: "Calhoun County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54015",
    label: "Clay County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54017",
    label: "Doddridge County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54019",
    label: "Fayette County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54021",
    label: "Gilmer County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54023",
    label: "Grant County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54025",
    label: "Greenbrier County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54027",
    label: "Hampshire County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54029",
    label: "Hancock County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54031",
    label: "Hardy County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54033",
    label: "Harrison County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54035",
    label: "Jackson County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54037",
    label: "Jefferson County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54039",
    label: "Kanawha County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54041",
    label: "Lewis County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54043",
    label: "Lincoln County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54045",
    label: "Logan County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54047",
    label: "McDowell County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54049",
    label: "Marion County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54051",
    label: "Marshall County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54053",
    label: "Mason County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54055",
    label: "Mercer County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54057",
    label: "Mineral County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54059",
    label: "Mingo County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54061",
    label: "Monongalia County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54063",
    label: "Monroe County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54065",
    label: "Morgan County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54067",
    label: "Nicholas County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54069",
    label: "Ohio County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54071",
    label: "Pendleton County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54073",
    label: "Pleasants County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54075",
    label: "Pocahontas County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54077",
    label: "Preston County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54079",
    label: "Putnam County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54081",
    label: "Raleigh County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54083",
    label: "Randolph County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54085",
    label: "Ritchie County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54087",
    label: "Roane County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54089",
    label: "Summers County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54091",
    label: "Taylor County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54093",
    label: "Tucker County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54095",
    label: "Tyler County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54097",
    label: "Upshur County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54099",
    label: "Wayne County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54101",
    label: "Webster County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54103",
    label: "Wetzel County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54105",
    label: "Wirt County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54107",
    label: "Wood County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "54109",
    label: "Wyoming County, West Virginia",
    stateCode: "54",
    stateAbbr: "WV",
  },
  {
    value: "55001",
    label: "Adams County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55003",
    label: "Ashland County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55005",
    label: "Barron County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55007",
    label: "Bayfield County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55009",
    label: "Brown County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55011",
    label: "Buffalo County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55013",
    label: "Burnett County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55015",
    label: "Calumet County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55017",
    label: "Chippewa County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55019",
    label: "Clark County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55021",
    label: "Columbia County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55023",
    label: "Crawford County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55025",
    label: "Dane County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55027",
    label: "Dodge County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55029",
    label: "Door County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55031",
    label: "Douglas County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55033",
    label: "Dunn County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55035",
    label: "Eau Claire County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55037",
    label: "Florence County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55039",
    label: "Fond du Lac County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55041",
    label: "Forest County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55043",
    label: "Grant County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55045",
    label: "Green County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55047",
    label: "Green Lake County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55049",
    label: "Iowa County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55051",
    label: "Iron County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55053",
    label: "Jackson County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55055",
    label: "Jefferson County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55057",
    label: "Juneau County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55059",
    label: "Kenosha County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55061",
    label: "Kewaunee County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55063",
    label: "La Crosse County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55065",
    label: "Lafayette County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55067",
    label: "Langlade County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55069",
    label: "Lincoln County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55071",
    label: "Manitowoc County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55073",
    label: "Marathon County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55075",
    label: "Marinette County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55077",
    label: "Marquette County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55078",
    label: "Menominee County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55079",
    label: "Milwaukee County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55081",
    label: "Monroe County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55083",
    label: "Oconto County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55085",
    label: "Oneida County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55087",
    label: "Outagamie County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55089",
    label: "Ozaukee County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55091",
    label: "Pepin County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55093",
    label: "Pierce County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55095",
    label: "Polk County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55097",
    label: "Portage County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55099",
    label: "Price County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55101",
    label: "Racine County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55103",
    label: "Richland County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55105",
    label: "Rock County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55107",
    label: "Rusk County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55109",
    label: "St. Croix County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55111",
    label: "Sauk County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55113",
    label: "Sawyer County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55115",
    label: "Shawano County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55117",
    label: "Sheboygan County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55119",
    label: "Taylor County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55121",
    label: "Trempealeau County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55123",
    label: "Vernon County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55125",
    label: "Vilas County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55127",
    label: "Walworth County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55129",
    label: "Washburn County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55131",
    label: "Washington County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55133",
    label: "Waukesha County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55135",
    label: "Waupaca County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55137",
    label: "Waushara County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55139",
    label: "Winnebago County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "55141",
    label: "Wood County, Wisconsin",
    stateCode: "55",
    stateAbbr: "WI",
  },
  {
    value: "56001",
    label: "Albany County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56003",
    label: "Big Horn County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56005",
    label: "Campbell County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56007",
    label: "Carbon County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56009",
    label: "Converse County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56011",
    label: "Crook County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56013",
    label: "Fremont County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56015",
    label: "Goshen County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56017",
    label: "Hot Springs County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56019",
    label: "Johnson County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56021",
    label: "Laramie County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56023",
    label: "Lincoln County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56025",
    label: "Natrona County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56027",
    label: "Niobrara County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56029",
    label: "Park County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56031",
    label: "Platte County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56033",
    label: "Sheridan County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56035",
    label: "Sublette County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56037",
    label: "Sweetwater County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56039",
    label: "Teton County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56041",
    label: "Uinta County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56043",
    label: "Washakie County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
  {
    value: "56045",
    label: "Weston County, Wyoming",
    stateCode: "56",
    stateAbbr: "WY",
  },
];
