import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useWindowSize } from "react-use";

type Props = Exclude<ReactModal.Props, "style"> & {
  children: React.ReactNode;
  overlayStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  stateChanged?: boolean;
};

const defaultStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.09)",
    backdropFilter: "blur(2.5px)",
    zIndex: 1100,
  } as React.CSSProperties,
  content: {
    zIndex: 1050,
    maxWidth: "800px",
    width: "min-content",
    margin: "auto",
    background: "transparent",
    inset: 0,
    padding: 24,
    border: "none",
  } as React.CSSProperties,
};

export const Modal = ({
  children,
  overlayStyle = {},
  contentStyle = {},
  onRequestClose,
  ...modalProps
}: Props) => {
  const { height, width } = useWindowSize();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const router = useRouter();
  const [responsiveStyle, setResponsiveStyle] = useState<React.CSSProperties>({
    height: "fit-content",
    position: "absolute",
    marginTop: "auto",
    marginBottom: "auto",
  });

  useEffect(() => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      if (rect.height + 24 > height) {
        setResponsiveStyle({
          height: "auto",
          marginBottom: "10px",
          marginTop: "10px",
          position: "relative",
        });
      } else {
        setResponsiveStyle({
          height: "fit-content",
          position: "absolute",
          marginTop: "auto",
          marginBottom: "auto",
        });
      }
    }
  }, [ref, height, width, router, modalProps.isOpen, modalProps.stateChanged]);

  return (
    //@ts-ignore
    <ReactModal
      {...modalProps}
      isOpen={modalProps.isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: { ...defaultStyles.overlay, ...overlayStyle },
        content: {
          ...defaultStyles.content,
          ...responsiveStyle,
          ...contentStyle,
        },
      }}
      ariaHideApp={false}
      contentRef={(cref) => {
        setRef(cref);
      }}
    >
      {children}
    </ReactModal>
  );
};
