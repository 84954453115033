import { StateOption } from "types/options";

export const stateList: StateOption[] = [
  { value: "01", label: "Alabama", abbreviation: "AL" },
  { value: "02", label: "Alaska", abbreviation: "AK" },
  { value: "04", label: "Arizona", abbreviation: "AZ" },
  { value: "05", label: "Arkansas", abbreviation: "AR" },
  { value: "06", label: "California", abbreviation: "CA" },
  { value: "08", label: "Colorado", abbreviation: "CO" },
  { value: "09", label: "Connecticut", abbreviation: "CT" },
  { value: "10", label: "Delaware", abbreviation: "DE" },
  { value: "12", label: "Florida", abbreviation: "FL" },
  { value: "13", label: "Georgia", abbreviation: "GA" },
  { value: "15", label: "Hawaii", abbreviation: "HI" },
  { value: "16", label: "Idaho", abbreviation: "ID" },
  { value: "17", label: "Illinois", abbreviation: "IL" },
  { value: "18", label: "Indiana", abbreviation: "IN" },
  { value: "19", label: "Iowa", abbreviation: "IA" },
  { value: "20", label: "Kansas", abbreviation: "KS" },
  { value: "21", label: "Kentucky", abbreviation: "KY" },
  { value: "22", label: "Louisiana", abbreviation: "LA" },
  { value: "23", label: "Maine", abbreviation: "ME" },
  { value: "24", label: "Maryland", abbreviation: "MD" },
  { value: "25", label: "Massachusetts", abbreviation: "MA" },
  { value: "26", label: "Michigan", abbreviation: "MI" },
  { value: "27", label: "Minnesota", abbreviation: "MN" },
  { value: "28", label: "Mississippi", abbreviation: "MS" },
  { value: "29", label: "Missouri", abbreviation: "MO" },
  { value: "30", label: "Montana", abbreviation: "MT" },
  { value: "31", label: "Nebraska", abbreviation: "NE" },
  { value: "32", label: "Nevada", abbreviation: "NV" },
  { value: "33", label: "New Hampshire", abbreviation: "NH" },
  { value: "34", label: "New Jersey", abbreviation: "NJ" },
  { value: "35", label: "New Mexico", abbreviation: "NM" },
  { value: "36", label: "New York", abbreviation: "NY" },
  { value: "37", label: "North Carolina", abbreviation: "NC" },
  { value: "38", label: "North Dakota", abbreviation: "ND" },
  { value: "39", label: "Ohio", abbreviation: "OH" },
  { value: "40", label: "Oklahoma", abbreviation: "OK" },
  { value: "41", label: "Oregon", abbreviation: "OR" },
  { value: "42", label: "Pennsylvania", abbreviation: "PA" },
  { value: "44", label: "Rhode Island", abbreviation: "RI" },
  { value: "45", label: "South Carolina", abbreviation: "SC" },
  { value: "46", label: "South Dakota", abbreviation: "SD" },
  { value: "47", label: "Tennessee", abbreviation: "TN" },
  { value: "48", label: "Texas", abbreviation: "TX" },
  { value: "49", label: "Utah", abbreviation: "UT" },
  { value: "50", label: "Vermont", abbreviation: "VT" },
  { value: "51", label: "Virginia", abbreviation: "VA" },
  { value: "53", label: "Washington", abbreviation: "WA" },
  { value: "54", label: "West Virginia", abbreviation: "WV" },
  { value: "55", label: "Wisconsin", abbreviation: "WI" },
  { value: "56", label: "Wyoming", abbreviation: "WY" },
];
