export const countyZipMap = {
  "Suffolk County, New York": [
    501, 544, 6390, 11701, 11702, 11703, 11704, 11705, 11706, 11707, 11708,
    11713, 11715, 11716, 11717, 11718, 11719, 11720, 11721, 11722, 11724, 11725,
    11726, 11727, 11729, 11730, 11731, 11733, 11738, 11739, 11740, 11741, 11742,
    11743, 11746, 11747, 11749, 11750, 11751, 11752, 11754, 11755, 11757, 11760,
    11763, 11764, 11766, 11767, 11768, 11769, 11770, 11772, 11775, 11776, 11777,
    11778, 11779, 11780, 11782, 11784, 11786, 11787, 11788, 11789, 11790, 11792,
    11794, 11795, 11796, 11798, 11901, 11930, 11931, 11932, 11933, 11934, 11935,
    11937, 11939, 11940, 11941, 11942, 11944, 11946, 11947, 11948, 11949, 11950,
    11951, 11952, 11953, 11954, 11955, 11956, 11957, 11958, 11959, 11960, 11961,
    11962, 11963, 11964, 11965, 11967, 11968, 11969, 11970, 11971, 11972, 11973,
    11975, 11976, 11977, 11978, 11980,
  ],
  "Adjuntas Municipio, Puerto Rico": [601],
  "Aguada Municipio, Puerto Rico": [602],
  "Aguadilla Municipio, Puerto Rico": [603, 690],
  "undefined, Puerto Rico": [
    604, 605, 613, 614, 681, 721, 726, 732, 733, 734, 737, 742, 744, 785, 792,
    902, 908, 910, 914, 916, 919, 922, 928, 930, 940, 970, 977,
  ],
  "Maricao Municipio, Puerto Rico": [606],
  "Anasco Municipio, Puerto Rico": [610],
  "Utuado Municipio, Puerto Rico": [611, 641],
  "Arecibo Municipio, Puerto Rico": [612, 616, 652, 688],
  "Barceloneta Municipio, Puerto Rico": [617],
  "Cabo Rojo Municipio, Puerto Rico": [622, 623],
  "Penuelas Municipio, Puerto Rico": [624],
  "Camuy Municipio, Puerto Rico": [627],
  "Lares Municipio, Puerto Rico": [631, 669],
  "San German Municipio, Puerto Rico": [636, 683],
  "Sabana Grande Municipio, Puerto Rico": [637],
  "Ciales Municipio, Puerto Rico": [638],
  "Dorado Municipio, Puerto Rico": [646, 950],
  "Guanica Municipio, Puerto Rico": [647, 653],
  "Florida Municipio, Puerto Rico": [650],
  "Guayanilla Municipio, Puerto Rico": [656],
  "Hatillo Municipio, Puerto Rico": [659],
  "Hormigueros Municipio, Puerto Rico": [660],
  "Isabela Municipio, Puerto Rico": [662],
  "Jayuya Municipio, Puerto Rico": [664],
  "Lajas Municipio, Puerto Rico": [667],
  "Las Marias Municipio, Puerto Rico": [670],
  "Manati Municipio, Puerto Rico": [674],
  "Moca Municipio, Puerto Rico": [676],
  "Rincon Municipio, Puerto Rico": [677],
  "Quebradillas Municipio, Puerto Rico": [678],
  "Mayaguez Municipio, Puerto Rico": [680, 682],
  "San Sebastian Municipio, Puerto Rico": [685],
  "Morovis Municipio, Puerto Rico": [687],
  "Vega Alta Municipio, Puerto Rico": [692],
  "Vega Baja Municipio, Puerto Rico": [693, 694],
  "Yauco Municipio, Puerto Rico": [698],
  "Aguas Buenas Municipio, Puerto Rico": [703],
  "Salinas Municipio, Puerto Rico": [704, 751],
  "Aibonito Municipio, Puerto Rico": [705, 786],
  "Maunabo Municipio, Puerto Rico": [707],
  "Arroyo Municipio, Puerto Rico": [714],
  "Ponce Municipio, Puerto Rico": [715, 716, 717, 728, 730, 731, 780],
  "Naguabo Municipio, Puerto Rico": [718],
  "Naranjito Municipio, Puerto Rico": [719],
  "Orocovis Municipio, Puerto Rico": [720],
  "Patillas Municipio, Puerto Rico": [723],
  "Caguas Municipio, Puerto Rico": [725, 727],
  "Canovanas Municipio, Puerto Rico": [729],
  "Ceiba Municipio, Puerto Rico": [735],
  "Cayey Municipio, Puerto Rico": [736],
  "Fajardo Municipio, Puerto Rico": [738, 740],
  "Cidra Municipio, Puerto Rico": [739],
  "Humacao Municipio, Puerto Rico": [741, 791],
  "Rio Grande Municipio, Puerto Rico": [745],
  "San Lorenzo Municipio, Puerto Rico": [754],
  "Santa Isabel Municipio, Puerto Rico": [757],
  "Vieques Municipio, Puerto Rico": [765],
  "Villalba Municipio, Puerto Rico": [766],
  "Yabucoa Municipio, Puerto Rico": [767],
  "Coamo Municipio, Puerto Rico": [769],
  "Las Piedras Municipio, Puerto Rico": [771],
  "Loiza Municipio, Puerto Rico": [772],
  "Luquillo Municipio, Puerto Rico": [773],
  "Culebra Municipio, Puerto Rico": [775],
  "Juncos Municipio, Puerto Rico": [777],
  "Gurabo Municipio, Puerto Rico": [778],
  "Comerio Municipio, Puerto Rico": [782],
  "Corozal Municipio, Puerto Rico": [783],
  "Guayama Municipio, Puerto Rico": [784],
  "Barranquitas Municipio, Puerto Rico": [794],
  "Juana Diaz Municipio, Puerto Rico": [795],
  "undefined, Virgin Islands": [
    801, 802, 803, 804, 805, 820, 821, 822, 823, 824, 830, 831, 840, 841, 850,
    851,
  ],
  "San Juan Municipio, Puerto Rico": [
    901, 906, 907, 909, 911, 912, 913, 915, 917, 918, 920, 921, 923, 924, 925,
    926, 927, 936,
  ],
  "San Juan, Puerto Rico": [929, 931, 933, 935, 937, 939, 955, 975, 978],
  "Guaynabo Municipio, Puerto Rico": [934, 965, 966, 968, 969, 971],
  "Toa Baja Municipio, Puerto Rico": [949, 951, 952],
  "Toa Alta Municipio, Puerto Rico": [953],
  "Toa Alta, Puerto Rico": [954],
  "Bayamon Municipio, Puerto Rico": [956, 957, 959, 960, 961],
  "Bayamon, Puerto Rico": [958],
  "Catano Municipio, Puerto Rico": [962],
  "Catano, Puerto Rico": [963],
  "Trujillo Alto Municipio, Puerto Rico": [976],
  "Carolina Municipio, Puerto Rico": [979, 982, 983, 985, 987],
  "Carolina, Puerto Rico": [981, 984, 986, 988],
  "Hampden County, Massachusetts": [
    1001, 1008, 1009, 1010, 1011, 1013, 1014, 1020, 1021, 1022, 1028, 1030,
    1034, 1036, 1040, 1041, 1056, 1057, 1069, 1071, 1077, 1079, 1080, 1081,
    1085, 1086, 1089, 1090, 1095, 1097, 1101, 1102, 1103, 1104, 1105, 1106,
    1107, 1108, 1109, 1111, 1115, 1116, 1118, 1119, 1128, 1129, 1133, 1138,
    1139, 1144, 1151, 1152, 1195, 1199, 1521,
  ],
  "Hampshire County, Massachusetts": [
    1002, 1003, 1004, 1007, 1012, 1026, 1027, 1032, 1033, 1035, 1038, 1039,
    1050, 1053, 1059, 1060, 1061, 1062, 1063, 1066, 1070, 1073, 1075, 1082,
    1084, 1088, 1096, 1098, 1243,
  ],
  "Worcester County, Massachusetts": [
    1005, 1031, 1037, 1068, 1074, 1083, 1092, 1094, 1331, 1366, 1368, 1420,
    1430, 1434, 1436, 1438, 1440, 1441, 1451, 1452, 1453, 1462, 1467, 1468,
    1473, 1475, 1477, 1501, 1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510,
    1515, 1516, 1517, 1518, 1519, 1520, 1522, 1523, 1524, 1525, 1526, 1527,
    1529, 1531, 1532, 1534, 1535, 1536, 1537, 1538, 1540, 1541, 1542, 1543,
    1545, 1546, 1550, 1560, 1561, 1562, 1564, 1566, 1568, 1569, 1570, 1571,
    1580, 1581, 1582, 1583, 1585, 1586, 1588, 1590, 1601, 1602, 1603, 1604,
    1605, 1606, 1607, 1608, 1609, 1610, 1611, 1612, 1613, 1614, 1615, 1653,
    1654, 1655, 1740, 1745, 1747, 1756, 1757, 1772,
  ],
  "Berkshire County, Massachusetts": [
    1029, 1201, 1202, 1203, 1220, 1222, 1223, 1224, 1225, 1226, 1227, 1229,
    1230, 1235, 1236, 1237, 1238, 1240, 1242, 1244, 1245, 1247, 1252, 1253,
    1254, 1255, 1256, 1257, 1258, 1259, 1260, 1262, 1263, 1264, 1266, 1267,
    1270, 1343,
  ],
  "Franklin County, Massachusetts": [
    1054, 1072, 1093, 1301, 1302, 1330, 1337, 1338, 1339, 1340, 1341, 1342,
    1344, 1346, 1347, 1349, 1350, 1351, 1354, 1355, 1360, 1364, 1367, 1370,
    1373, 1375, 1376, 1378, 1379, 1380,
  ],
  "Middlesex County, Massachusetts": [
    1431, 1432, 1450, 1460, 1463, 1464, 1469, 1470, 1471, 1472, 1474, 1701,
    1702, 1703, 1704, 1705, 1718, 1719, 1720, 1721, 1730, 1731, 1741, 1742,
    1746, 1748, 1749, 1752, 1754, 1760, 1770, 1773, 1775, 1776, 1778, 1784,
    1801, 1803, 1805, 1806, 1807, 1808, 1813, 1815, 1821, 1822, 1824, 1826,
    1827, 1850, 1851, 1852, 1853, 1854, 1862, 1863, 1864, 1865, 1866, 1867,
    1876, 1879, 1880, 1886, 1887, 1888, 1889, 1890, 2138, 2139, 2140, 2141,
    2142, 2143, 2144, 2145, 2148, 2149, 2153, 2155, 2156, 2176, 2180, 2238,
    2239, 2420, 2421, 2451, 2452, 2453, 2454, 2455, 2456, 2458, 2459, 2460,
    2461, 2462, 2464, 2465, 2466, 2467, 2468, 2471, 2472, 2474, 2475, 2476,
    2477, 2478, 2479, 2493, 2495,
  ],
  "Essex County, Massachusetts": [
    1810, 1812, 1830, 1831, 1832, 1833, 1834, 1835, 1840, 1841, 1842, 1843,
    1844, 1845, 1860, 1885, 1899, 1901, 1902, 1903, 1904, 1905, 1906, 1907,
    1908, 1910, 1913, 1915, 1921, 1922, 1923, 1929, 1930, 1931, 1936, 1937,
    1938, 1940, 1944, 1945, 1949, 1950, 1951, 1952, 1960, 1961, 1965, 1966,
    1969, 1970, 1971, 1982, 1983, 1984, 1985, 5501, 5544,
  ],
  "Plymouth County, Massachusetts": [
    2018, 2020, 2040, 2041, 2043, 2044, 2045, 2047, 2050, 2051, 2055, 2059,
    2060, 2061, 2065, 2066, 2301, 2302, 2303, 2304, 2305, 2324, 2325, 2327,
    2330, 2331, 2332, 2333, 2337, 2338, 2339, 2340, 2341, 2344, 2345, 2346,
    2347, 2348, 2349, 2350, 2351, 2355, 2358, 2359, 2360, 2361, 2362, 2364,
    2366, 2367, 2370, 2379, 2381, 2382, 2538, 2558, 2571, 2576, 2738, 2739,
    2770,
  ],
  "Norfolk County, Massachusetts": [
    2019, 2021, 2025, 2026, 2027, 2030, 2032, 2035, 2038, 2052, 2053, 2054,
    2056, 2062, 2067, 2070, 2071, 2072, 2081, 2090, 2093, 2169, 2170, 2171,
    2184, 2185, 2186, 2187, 2188, 2189, 2190, 2191, 2269, 2322, 2343, 2368,
    2445, 2446, 2447, 2457, 2481, 2482, 2492, 2494, 2762,
  ],
  "Bristol County, Massachusetts": [
    2031, 2048, 2334, 2356, 2357, 2375, 2702, 2703, 2712, 2714, 2715, 2717,
    2718, 2719, 2720, 2721, 2722, 2723, 2724, 2725, 2726, 2740, 2741, 2742,
    2743, 2744, 2745, 2746, 2747, 2748, 2760, 2761, 2763, 2764, 2766, 2767,
    2768, 2769, 2771, 2777, 2779, 2780, 2783, 2790, 2791,
  ],
  "Suffolk County, Massachusetts": [
    2108, 2109, 2110, 2111, 2112, 2113, 2114, 2115, 2116, 2117, 2118, 2119,
    2120, 2121, 2122, 2123, 2124, 2125, 2126, 2127, 2128, 2129, 2130, 2131,
    2132, 2133, 2134, 2135, 2136, 2137, 2150, 2151, 2152, 2163, 2196, 2199,
    2201, 2203, 2204, 2205, 2206, 2207, 2210, 2211, 2212, 2215, 2216, 2217,
    2222, 2228, 2241, 2266, 2283, 2284, 2293, 2295, 2297, 2298,
  ],
  "Barnstable County, Massachusetts": [
    2532, 2534, 2536, 2537, 2540, 2541, 2542, 2543, 2553, 2556, 2559, 2561,
    2562, 2563, 2565, 2574, 2601, 2630, 2631, 2632, 2633, 2634, 2635, 2636,
    2637, 2638, 2639, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2649,
    2650, 2651, 2652, 2653, 2655, 2657, 2659, 2660, 2661, 2662, 2663, 2664,
    2666, 2667, 2668, 2669, 2670, 2671, 2672, 2673, 2675,
  ],
  "Dukes County, Massachusetts": [
    2535, 2539, 2552, 2557, 2568, 2573, 2575, 2713,
  ],
  "Nantucket County, Massachusetts": [2554, 2564, 2584],
  "Newport County, Rhode Island": [
    2801, 2835, 2837, 2840, 2841, 2842, 2871, 2872, 2878,
  ],
  "Providence County, Rhode Island": [
    2802, 2814, 2815, 2823, 2824, 2825, 2826, 2828, 2829, 2830, 2831, 2838,
    2839, 2857, 2858, 2859, 2860, 2861, 2862, 2863, 2864, 2865, 2876, 2895,
    2896, 2901, 2902, 2903, 2904, 2905, 2906, 2907, 2908, 2909, 2910, 2911,
    2912, 2914, 2915, 2916, 2917, 2918, 2919, 2920, 2921, 2940,
  ],
  "Washington County, Rhode Island": [
    2804, 2807, 2808, 2812, 2813, 2822, 2832, 2833, 2836, 2852, 2854, 2873,
    2874, 2875, 2877, 2879, 2880, 2881, 2882, 2883, 2891, 2892, 2894, 2898,
  ],
  "Bristol County, Rhode Island": [2806, 2809, 2885],
  "Kent County, Rhode Island": [
    2816, 2817, 2818, 2827, 2886, 2887, 2888, 2889, 2893,
  ],
  "Hillsborough County, New Hampshire": [
    3031, 3033, 3043, 3045, 3047, 3048, 3049, 3051, 3052, 3054, 3055, 3057,
    3060, 3061, 3062, 3063, 3064, 3070, 3071, 3076, 3082, 3084, 3086, 3101,
    3102, 3103, 3104, 3105, 3107, 3108, 3109, 3110, 3111, 3244, 3281, 3440,
    3442, 3449, 3458, 3468,
  ],
  "Rockingham County, New Hampshire": [
    3032, 3034, 3036, 3037, 3038, 3040, 3041, 3042, 3044, 3053, 3073, 3077,
    3079, 3087, 3261, 3290, 3291, 3801, 3802, 3803, 3804, 3805, 3811, 3819,
    3826, 3827, 3833, 3840, 3841, 3842, 3843, 3844, 3848, 3854, 3856, 3857,
    3858, 3859, 3862, 3865, 3870, 3871, 3873, 3874, 3885,
  ],
  "Merrimack County, New Hampshire": [
    3046, 3106, 3216, 3221, 3224, 3229, 3230, 3231, 3233, 3234, 3235, 3242,
    3243, 3255, 3257, 3258, 3260, 3263, 3268, 3272, 3273, 3275, 3276, 3278,
    3287, 3301, 3302, 3303, 3304, 3305, 3307,
  ],
  "Grafton County, New Hampshire": [
    3215, 3217, 3222, 3223, 3238, 3240, 3241, 3245, 3251, 3262, 3264, 3266,
    3274, 3279, 3282, 3285, 3293, 3561, 3574, 3580, 3585, 3586, 3740, 3741,
    3748, 3749, 3750, 3755, 3756, 3765, 3766, 3768, 3769, 3771, 3774, 3777,
    3779, 3780, 3784, 3785,
  ],
  "Belknap County, New Hampshire": [
    3218, 3220, 3225, 3226, 3237, 3246, 3247, 3249, 3252, 3253, 3256, 3269,
    3289, 3298, 3299, 3809, 3810, 3837,
  ],
  "Carroll County, New Hampshire": [
    3227, 3254, 3259, 3812, 3813, 3814, 3816, 3817, 3818, 3830, 3832, 3836,
    3838, 3845, 3846, 3847, 3849, 3850, 3853, 3860, 3864, 3872, 3875, 3882,
    3883, 3886, 3890, 3894, 3896, 3897,
  ],
  "Sullivan County, New Hampshire": [
    3280, 3284, 3601, 3603, 3605, 3607, 3743, 3745, 3746, 3751, 3752, 3753,
    3754, 3770, 3773, 3781, 3782,
  ],
  "Cheshire County, New Hampshire": [
    3431, 3435, 3441, 3443, 3444, 3445, 3446, 3447, 3448, 3450, 3451, 3452,
    3455, 3456, 3457, 3461, 3462, 3464, 3465, 3466, 3467, 3469, 3470, 3602,
    3604, 3608, 3609,
  ],
  "Coos County, New Hampshire": [
    3570, 3575, 3576, 3579, 3581, 3582, 3583, 3584, 3588, 3589, 3590, 3592,
    3593, 3595, 3597, 3598,
  ],
  "Strafford County, New Hampshire": [
    3815, 3820, 3821, 3822, 3823, 3824, 3825, 3835, 3839, 3851, 3852, 3855,
    3861, 3866, 3867, 3868, 3869, 3878, 3884, 3887,
  ],
  "York County, Maine": [
    3901, 3902, 3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 4001,
    4002, 4004, 4005, 4006, 4007, 4014, 4020, 4027, 4028, 4030, 4042, 4043,
    4046, 4047, 4048, 4049, 4054, 4056, 4061, 4063, 4064, 4072, 4073, 4076,
    4083, 4087, 4090, 4093, 4094, 4095,
  ],
  "Cumberland County, Maine": [
    4003, 4009, 4011, 4013, 4015, 4017, 4019, 4021, 4024, 4029, 4032, 4033,
    4034, 4038, 4039, 4040, 4050, 4055, 4057, 4062, 4066, 4069, 4070, 4071,
    4074, 4075, 4077, 4078, 4079, 4082, 4084, 4085, 4091, 4092, 4096, 4097,
    4098, 4101, 4102, 4103, 4104, 4105, 4106, 4107, 4108, 4109, 4110, 4112,
    4116, 4122, 4123, 4124, 4260,
  ],
  "Sagadahoc County, Maine": [
    4008, 4086, 4287, 4357, 4530, 4548, 4562, 4565, 4579,
  ],
  "Oxford County, Maine": [
    4010, 4016, 4022, 4037, 4041, 4051, 4068, 4088, 4216, 4217, 4219, 4220,
    4221, 4224, 4226, 4227, 4231, 4237, 4238, 4255, 4257, 4261, 4267, 4268,
    4270, 4271, 4275, 4276, 4281, 4286, 4289, 4290, 4292,
  ],
  "Androscoggin County, Maine": [
    4210, 4211, 4212, 4222, 4223, 4228, 4230, 4236, 4240, 4241, 4243, 4250,
    4252, 4253, 4254, 4256, 4258, 4263, 4266, 4274, 4280, 4282, 4288, 4291,
  ],
  "Franklin County, Maine": [
    4225, 4234, 4239, 4262, 4285, 4294, 4936, 4938, 4940, 4947, 4955, 4956,
    4964, 4966, 4970, 4982, 4983, 4984, 4992,
  ],
  "Kennebec County, Maine": [
    4259, 4265, 4284, 4330, 4332, 4333, 4336, 4338, 4343, 4344, 4345, 4346,
    4347, 4349, 4350, 4351, 4352, 4355, 4358, 4359, 4360, 4363, 4364, 4901,
    4903, 4910, 4917, 4918, 4926, 4927, 4935, 4962, 4963, 4989,
  ],
  "Lincoln County, Maine": [
    4341, 4342, 4348, 4353, 4535, 4537, 4538, 4539, 4541, 4543, 4544, 4549,
    4551, 4553, 4554, 4555, 4556, 4558, 4564, 4568, 4570, 4571, 4572, 4573,
    4575, 4576, 4578, 4852,
  ],
  "Waldo County, Maine": [
    4354, 4438, 4496, 4848, 4849, 4850, 4915, 4921, 4922, 4941, 4949, 4951,
    4952, 4972, 4973, 4974, 4981, 4986, 4987, 4988,
  ],
  "Penobscot County, Maine": [
    4401, 4402, 4410, 4411, 4412, 4417, 4418, 4419, 4422, 4427, 4428, 4429,
    4430, 4434, 4435, 4444, 4448, 4449, 4450, 4451, 4453, 4455, 4456, 4457,
    4459, 4460, 4461, 4462, 4467, 4468, 4469, 4473, 4474, 4475, 4487, 4488,
    4489, 4493, 4495, 4765, 4777, 4928, 4930, 4932, 4933, 4939, 4953, 4969,
  ],
  "Piscataquis County, Maine": [
    4406, 4414, 4415, 4426, 4441, 4442, 4443, 4463, 4464, 4479, 4481, 4485,
  ],
  "Hancock County, Maine": [
    4408, 4416, 4420, 4421, 4431, 4472, 4476, 4605, 4607, 4609, 4612, 4613,
    4614, 4616, 4617, 4624, 4625, 4627, 4629, 4634, 4635, 4640, 4642, 4644,
    4646, 4650, 4653, 4660, 4662, 4664, 4669, 4672, 4673, 4674, 4675, 4676,
    4677, 4679, 4681, 4683, 4684, 4685, 4693,
  ],
  "Washington County, Maine": [
    4413, 4424, 4454, 4490, 4491, 4492, 4606, 4611, 4619, 4622, 4623, 4626,
    4628, 4630, 4631, 4637, 4643, 4648, 4649, 4652, 4654, 4655, 4657, 4658,
    4666, 4667, 4668, 4671, 4680, 4686, 4691, 4694,
  ],
  "Aroostook County, Maine": [
    4471, 4497, 4730, 4732, 4733, 4734, 4735, 4736, 4737, 4738, 4739, 4740,
    4741, 4742, 4743, 4744, 4745, 4746, 4747, 4750, 4751, 4756, 4757, 4758,
    4760, 4761, 4762, 4763, 4764, 4766, 4768, 4769, 4772, 4773, 4774, 4775,
    4776, 4779, 4780, 4781, 4783, 4785, 4786, 4787,
  ],
  "Somerset County, Maine": [
    4478, 4911, 4912, 4920, 4923, 4924, 4925, 4929, 4937, 4942, 4943, 4944,
    4945, 4950, 4954, 4957, 4958, 4961, 4965, 4967, 4971, 4975, 4976, 4978,
    4979, 4985,
  ],
  "Knox County, Maine": [
    4547, 4563, 4574, 4645, 4841, 4843, 4846, 4847, 4851, 4853, 4854, 4855,
    4856, 4858, 4859, 4860, 4861, 4862, 4863, 4864, 4865,
  ],
  "Windsor County, Vermont": [
    5001, 5009, 5030, 5031, 5032, 5034, 5035, 5037, 5047, 5048, 5049, 5052,
    5053, 5055, 5056, 5059, 5062, 5065, 5067, 5068, 5071, 5073, 5084, 5088,
    5089, 5091, 5142, 5143, 5144, 5149, 5150, 5151, 5153, 5156, 5161, 5746,
    5767, 5772,
  ],
  "Orange County, Vermont": [
    5033, 5036, 5038, 5039, 5040, 5041, 5043, 5045, 5051, 5054, 5058, 5060,
    5061, 5070, 5072, 5074, 5075, 5076, 5077, 5079, 5081, 5083, 5085, 5086,
    5675, 5679,
  ],
  "Caledonia County, Vermont": [
    5042, 5046, 5050, 5069, 5819, 5821, 5828, 5832, 5836, 5838, 5843, 5848,
    5849, 5850, 5851, 5861, 5862, 5863, 5866, 5867, 5871, 5873,
  ],
  "Windham County, Vermont": [
    5101, 5141, 5146, 5148, 5154, 5155, 5158, 5159, 5301, 5302, 5303, 5304,
    5341, 5342, 5343, 5344, 5345, 5346, 5351, 5353, 5354, 5355, 5356, 5357,
    5358, 5359, 5360, 5361, 5362, 5363,
  ],
  "Bennington County, Vermont": [
    5152, 5201, 5250, 5251, 5252, 5253, 5254, 5255, 5257, 5260, 5261, 5262,
    5340, 5350, 5352, 5768, 5776,
  ],
  "Chittenden County, Vermont": [
    5401, 5402, 5403, 5404, 5405, 5406, 5407, 5408, 5439, 5445, 5446, 5449,
    5451, 5452, 5453, 5461, 5462, 5465, 5466, 5468, 5477, 5482, 5489, 5490,
    5494, 5495,
  ],
  "Grand Isle County, Vermont": [5440, 5458, 5463, 5474, 5486],
  "Franklin County, Vermont": [
    5441, 5444, 5447, 5448, 5450, 5454, 5455, 5457, 5459, 5460, 5470, 5471,
    5476, 5478, 5479, 5481, 5483, 5485, 5488,
  ],
  "Lamoille County, Vermont": [
    5442, 5464, 5492, 5652, 5653, 5655, 5656, 5657, 5661, 5662, 5665, 5672,
    5680,
  ],
  "Addison County, Vermont": [
    5443, 5456, 5469, 5472, 5473, 5487, 5491, 5734, 5740, 5747, 5748, 5753,
    5760, 5766, 5769, 5770, 5778,
  ],
  "Washington County, Vermont": [
    5601, 5602, 5603, 5604, 5609, 5620, 5633, 5640, 5641, 5647, 5648, 5649,
    5650, 5651, 5654, 5658, 5660, 5663, 5664, 5666, 5667, 5669, 5670, 5671,
    5673, 5674, 5676, 5677, 5678, 5681, 5682,
  ],
  "Rutland County, Vermont": [
    5701, 5702, 5730, 5731, 5732, 5733, 5735, 5736, 5737, 5738, 5739, 5741,
    5742, 5743, 5744, 5745, 5750, 5751, 5757, 5758, 5759, 5761, 5762, 5763,
    5764, 5765, 5773, 5774, 5775, 5777,
  ],
  "Orleans County, Vermont": [
    5820, 5822, 5823, 5825, 5826, 5827, 5829, 5830, 5833, 5839, 5841, 5842,
    5845, 5847, 5853, 5855, 5857, 5859, 5860, 5868, 5872, 5874, 5875,
  ],
  "Essex County, Vermont": [
    5824, 5837, 5840, 5846, 5858, 5901, 5902, 5903, 5904, 5905, 5906, 5907,
  ],
  "Hartford County, Connecticut": [
    6001, 6002, 6006, 6010, 6011, 6013, 6016, 6019, 6020, 6022, 6023, 6025,
    6026, 6027, 6028, 6030, 6032, 6033, 6034, 6035, 6037, 6040, 6041, 6042,
    6045, 6050, 6051, 6052, 6053, 6059, 6060, 6062, 6064, 6065, 6067, 6070,
    6073, 6074, 6078, 6080, 6081, 6082, 6083, 6085, 6087, 6088, 6089, 6090,
    6091, 6092, 6093, 6095, 6096, 6101, 6102, 6103, 6104, 6105, 6106, 6107,
    6108, 6109, 6110, 6111, 6112, 6114, 6115, 6117, 6118, 6119, 6120, 6123,
    6126, 6127, 6128, 6129, 6131, 6132, 6133, 6134, 6137, 6138, 6140, 6141,
    6142, 6143, 6144, 6145, 6146, 6147, 6150, 6151, 6152, 6153, 6154, 6155,
    6156, 6160, 6161, 6167, 6176, 6180, 6183, 6199, 6444, 6447, 6467, 6479,
    6489,
  ],
  "Litchfield County, Connecticut": [
    6018, 6021, 6024, 6031, 6039, 6057, 6058, 6061, 6063, 6068, 6069, 6079,
    6094, 6098, 6750, 6751, 6752, 6753, 6754, 6755, 6756, 6757, 6758, 6759,
    6763, 6776, 6777, 6778, 6779, 6781, 6782, 6783, 6785, 6786, 6787, 6790,
    6791, 6792, 6793, 6794, 6795, 6796, 6798,
  ],
  "Tolland County, Connecticut": [
    6029, 6043, 6066, 6071, 6072, 6075, 6076, 6077, 6084, 6231, 6232, 6237,
    6238, 6248, 6250, 6251, 6265, 6268, 6269, 6279,
  ],
  "Windham County, Connecticut": [
    6226, 6230, 6233, 6234, 6235, 6239, 6241, 6242, 6243, 6244, 6245, 6246,
    6247, 6255, 6256, 6258, 6259, 6260, 6262, 6263, 6264, 6266, 6267, 6277,
    6278, 6280, 6281, 6282, 6331, 6332, 6354, 6373, 6374, 6377, 6387,
  ],
  "New London County, Connecticut": [
    6249, 6254, 6320, 6330, 6333, 6334, 6335, 6336, 6338, 6339, 6340, 6349,
    6350, 6351, 6353, 6355, 6357, 6359, 6360, 6365, 6370, 6371, 6372, 6375,
    6376, 6378, 6379, 6380, 6382, 6383, 6384, 6385, 6386, 6388, 6389, 6415,
    6420, 6439, 6474,
  ],
  "New Haven County, Connecticut": [
    6401, 6403, 6405, 6408, 6410, 6411, 6418, 6437, 6443, 6450, 6451, 6454,
    6460, 6461, 6471, 6472, 6473, 6477, 6478, 6483, 6487, 6488, 6492, 6493,
    6494, 6495, 6501, 6502, 6503, 6504, 6505, 6506, 6507, 6508, 6509, 6510,
    6511, 6512, 6513, 6514, 6515, 6516, 6517, 6518, 6519, 6520, 6521, 6524,
    6525, 6530, 6531, 6532, 6533, 6534, 6535, 6536, 6537, 6538, 6540, 6701,
    6702, 6703, 6704, 6705, 6706, 6708, 6710, 6712, 6716, 6720, 6721, 6722,
    6723, 6724, 6725, 6726, 6749, 6762, 6770,
  ],
  "Fairfield County, Connecticut": [
    6404, 6440, 6468, 6470, 6482, 6484, 6491, 6497, 6601, 6602, 6604, 6605,
    6606, 6607, 6608, 6610, 6611, 6612, 6614, 6615, 6650, 6673, 6699, 6784,
    6801, 6804, 6807, 6810, 6811, 6812, 6813, 6814, 6816, 6817, 6820, 6824,
    6825, 6828, 6829, 6830, 6831, 6832, 6836, 6838, 6840, 6842, 6850, 6851,
    6852, 6853, 6854, 6855, 6856, 6857, 6858, 6859, 6860, 6870, 6875, 6876,
    6877, 6878, 6879, 6880, 6881, 6883, 6888, 6889, 6890, 6896, 6897, 6901,
    6902, 6903, 6904, 6905, 6906, 6907, 6910, 6911, 6912, 6913, 6914, 6920,
    6921, 6922, 6925, 6926, 6927, 6928,
  ],
  "Middlesex County, Connecticut": [
    6409, 6412, 6413, 6414, 6416, 6417, 6419, 6422, 6423, 6424, 6426, 6438,
    6441, 6442, 6455, 6456, 6457, 6459, 6469, 6475, 6480, 6481, 6498,
  ],
  "Middlesex County, New Jersey": [
    7001, 7008, 7064, 7067, 7077, 7080, 7095, 8536, 8810, 8812, 8816, 8817,
    8818, 8820, 8824, 8828, 8830, 8831, 8832, 8837, 8840, 8846, 8850, 8852,
    8854, 8855, 8857, 8859, 8861, 8862, 8863, 8871, 8872, 8879, 8882, 8884,
    8899, 8901, 8902, 8903, 8904, 8905, 8906, 8922, 8933, 8988, 8989,
  ],
  "Hudson County, New Jersey": [
    7002, 7029, 7030, 7032, 7047, 7086, 7087, 7093, 7094, 7096, 7097, 7099,
    7302, 7303, 7304, 7305, 7306, 7307, 7308, 7309, 7310, 7311, 7395, 7399,
  ],
  "Essex County, New Jersey": [
    7003, 7004, 7006, 7007, 7009, 7017, 7018, 7019, 7021, 7028, 7039, 7040,
    7041, 7042, 7043, 7044, 7050, 7051, 7052, 7068, 7078, 7079, 7101, 7102,
    7103, 7104, 7105, 7106, 7107, 7108, 7109, 7110, 7111, 7112, 7114, 7175,
    7182, 7184, 7188, 7189, 7191, 7192, 7193, 7194, 7195, 7198, 7199,
  ],
  "Morris County, New Jersey": [
    7005, 7034, 7035, 7045, 7046, 7054, 7058, 7082, 7405, 7438, 7440, 7444,
    7457, 7801, 7802, 7803, 7806, 7828, 7834, 7836, 7842, 7845, 7847, 7849,
    7850, 7852, 7853, 7856, 7857, 7866, 7869, 7870, 7876, 7878, 7885, 7926,
    7927, 7928, 7930, 7932, 7933, 7935, 7936, 7940, 7945, 7946, 7950, 7960,
    7961, 7962, 7963, 7970, 7976, 7980, 7981, 7983, 7999,
  ],
  "Bergen County, New Jersey": [
    7010, 7020, 7022, 7024, 7026, 7031, 7057, 7070, 7071, 7072, 7073, 7074,
    7075, 7401, 7407, 7410, 7417, 7423, 7430, 7432, 7436, 7446, 7450, 7451,
    7452, 7458, 7463, 7481, 7495, 7601, 7602, 7603, 7604, 7605, 7606, 7607,
    7608, 7620, 7621, 7624, 7626, 7627, 7628, 7630, 7631, 7632, 7640, 7641,
    7642, 7643, 7644, 7645, 7646, 7647, 7648, 7649, 7650, 7652, 7653, 7656,
    7657, 7660, 7661, 7662, 7663, 7666, 7670, 7675, 7676, 7677, 7699,
  ],
  "Passaic County, New Jersey": [
    7011, 7012, 7013, 7014, 7015, 7055, 7403, 7420, 7421, 7424, 7435, 7442,
    7456, 7465, 7470, 7474, 7477, 7480, 7501, 7502, 7503, 7504, 7505, 7506,
    7507, 7508, 7509, 7510, 7511, 7512, 7513, 7514, 7522, 7524, 7533, 7538,
    7543, 7544,
  ],
  "Union County, New Jersey": [
    7016, 7023, 7027, 7033, 7036, 7060, 7061, 7062, 7063, 7065, 7066, 7076,
    7081, 7083, 7088, 7090, 7091, 7092, 7201, 7202, 7203, 7204, 7205, 7206,
    7207, 7208, 7901, 7902, 7922, 7974,
  ],
  "Somerset County, New Jersey": [
    7059, 7069, 7920, 7921, 7924, 7931, 7934, 7938, 7939, 7977, 7978, 8502,
    8504, 8528, 8553, 8558, 8805, 8807, 8821, 8823, 8835, 8836, 8844, 8853,
    8869, 8873, 8875, 8876, 8880, 8890,
  ],
  "Sussex County, New Jersey": [
    7416, 7418, 7419, 7422, 7428, 7439, 7460, 7461, 7462, 7821, 7822, 7826,
    7827, 7837, 7839, 7843, 7848, 7851, 7855, 7860, 7871, 7874, 7875, 7877,
    7879, 7881, 7890,
  ],
  "Monmouth County, New Jersey": [
    7701, 7702, 7703, 7704, 7709, 7710, 7711, 7712, 7715, 7716, 7717, 7718,
    7719, 7720, 7721, 7722, 7723, 7724, 7726, 7727, 7728, 7730, 7731, 7732,
    7733, 7734, 7735, 7737, 7738, 7739, 7740, 7746, 7747, 7748, 7750, 7751,
    7752, 7753, 7754, 7755, 7756, 7757, 7758, 7760, 7762, 7763, 7764, 7765,
    7799, 8501, 8510, 8514, 8526, 8535, 8555, 8720, 8730, 8736, 8750,
  ],
  "Warren County, New Jersey": [
    7820, 7823, 7825, 7829, 7831, 7832, 7833, 7838, 7840, 7844, 7846, 7863,
    7865, 7880, 7882, 8808, 8865, 8886,
  ],
  "Hunterdon County, New Jersey": [
    7830, 7979, 8530, 8551, 8556, 8557, 8559, 8801, 8802, 8803, 8804, 8809,
    8822, 8825, 8826, 8827, 8829, 8833, 8834, 8848, 8858, 8867, 8868, 8870,
    8885, 8887, 8888, 8889,
  ],
  "Salem County, New Jersey": [
    8001, 8023, 8038, 8067, 8069, 8070, 8072, 8079, 8098, 8318, 8347,
  ],
  "Camden County, New Jersey": [
    8002, 8003, 8004, 8007, 8009, 8012, 8018, 8021, 8026, 8029, 8030, 8031,
    8033, 8034, 8035, 8043, 8045, 8049, 8059, 8078, 8081, 8083, 8084, 8089,
    8091, 8095, 8099, 8101, 8102, 8103, 8104, 8105, 8106, 8107, 8108, 8109,
    8110,
  ],
  "Ocean County, New Jersey": [
    8005, 8006, 8008, 8050, 8087, 8092, 8527, 8533, 8701, 8721, 8722, 8723,
    8724, 8731, 8732, 8733, 8734, 8735, 8738, 8739, 8740, 8741, 8742, 8751,
    8752, 8753, 8754, 8755, 8756, 8757, 8758, 8759,
  ],
  "Burlington County, New Jersey": [
    8010, 8011, 8015, 8016, 8019, 8022, 8036, 8041, 8042, 8046, 8048, 8052,
    8053, 8054, 8055, 8057, 8060, 8064, 8065, 8068, 8073, 8075, 8076, 8077,
    8088, 8224, 8505, 8511, 8515, 8518, 8554, 8562, 8640, 8641,
  ],
  "Gloucester County, New Jersey": [
    8014, 8020, 8025, 8027, 8028, 8032, 8039, 8051, 8056, 8061, 8062, 8063,
    8066, 8071, 8074, 8080, 8085, 8086, 8090, 8093, 8094, 8096, 8097, 8312,
    8322, 8328, 8343, 8344,
  ],
  "Atlantic County, New Jersey": [
    8037, 8201, 8203, 8205, 8213, 8215, 8217, 8220, 8221, 8225, 8231, 8232,
    8234, 8240, 8241, 8244, 8310, 8317, 8319, 8326, 8330, 8340, 8341, 8342,
    8346, 8350, 8401, 8402, 8403, 8404, 8405, 8406,
  ],
  "Cape May County, New Jersey": [
    8202, 8204, 8210, 8212, 8214, 8218, 8219, 8223, 8226, 8230, 8242, 8243,
    8245, 8246, 8247, 8248, 8250, 8251, 8252, 8260, 8270,
  ],
  "Cumberland County, New Jersey": [
    8302, 8311, 8313, 8314, 8315, 8316, 8320, 8321, 8323, 8324, 8327, 8329,
    8332, 8345, 8348, 8349, 8352, 8353, 8360, 8361, 8362,
  ],
  "Mercer County, New Jersey": [
    8512, 8520, 8525, 8534, 8540, 8541, 8542, 8543, 8544, 8550, 8560, 8561,
    8601, 8602, 8603, 8604, 8605, 8606, 8607, 8608, 8609, 8610, 8611, 8618,
    8619, 8620, 8625, 8628, 8629, 8638, 8645, 8646, 8647, 8648, 8650, 8666,
    8690, 8691, 8695,
  ],
  "undefined, New Jersey": [8644],
  "undefined, undefined": [
    9001, 9002, 9003, 9004, 9005, 9006, 9007, 9008, 9009, 9010, 9011, 9012,
    9013, 9014, 9015, 9016, 9017, 9018, 9020, 9021, 9028, 9031, 9033, 9034,
    9036, 9038, 9042, 9044, 9045, 9046, 9049, 9051, 9053, 9054, 9055, 9056,
    9058, 9059, 9060, 9063, 9067, 9068, 9069, 9074, 9075, 9076, 9079, 9080,
    9081, 9086, 9088, 9089, 9090, 9092, 9094, 9095, 9096, 9099, 9100, 9101,
    9102, 9103, 9104, 9107, 9110, 9112, 9113, 9114, 9115, 9116, 9123, 9125,
    9126, 9128, 9131, 9135, 9136, 9137, 9138, 9139, 9140, 9142, 9143, 9154,
    9160, 9161, 9165, 9166, 9169, 9170, 9171, 9172, 9173, 9174, 9175, 9176,
    9177, 9178, 9180, 9182, 9183, 9185, 9186, 9201, 9203, 9204, 9211, 9212,
    9213, 9214, 9216, 9225, 9226, 9227, 9229, 9237, 9240, 9241, 9242, 9244,
    9245, 9250, 9252, 9261, 9262, 9263, 9264, 9265, 9266, 9267, 9276, 9277,
    9278, 9279, 9280, 9283, 9285, 9287, 9289, 9290, 9291, 9292, 9300, 9301,
    9302, 9303, 9304, 9305, 9306, 9307, 9308, 9309, 9310, 9311, 9312, 9313,
    9314, 9315, 9316, 9317, 9318, 9319, 9320, 9321, 9322, 9323, 9324, 9327,
    9328, 9330, 9331, 9332, 9333, 9334, 9336, 9337, 9338, 9339, 9340, 9342,
    9343, 9344, 9346, 9347, 9348, 9350, 9351, 9352, 9353, 9354, 9355, 9356,
    9357, 9358, 9359, 9360, 9361, 9362, 9363, 9364, 9365, 9366, 9367, 9368,
    9369, 9370, 9371, 9372, 9373, 9374, 9375, 9376, 9377, 9378, 9379, 9380,
    9381, 9382, 9383, 9384, 9386, 9387, 9388, 9389, 9390, 9391, 9393, 9394,
    9396, 9397, 9399, 9401, 9402, 9403, 9409, 9410, 9420, 9421, 9447, 9454,
    9456, 9459, 9461, 9463, 9464, 9467, 9468, 9469, 9470, 9487, 9488, 9489,
    9490, 9491, 9494, 9496, 9497, 9498, 9499, 9501, 9502, 9503, 9504, 9505,
    9506, 9507, 9508, 9509, 9510, 9511, 9512, 9513, 9514, 9516, 9517, 9520,
    9522, 9523, 9524, 9532, 9533, 9534, 9541, 9542, 9543, 9544, 9545, 9549,
    9550, 9554, 9556, 9557, 9564, 9565, 9566, 9567, 9568, 9569, 9570, 9573,
    9574, 9575, 9576, 9577, 9578, 9579, 9581, 9582, 9583, 9586, 9587, 9588,
    9589, 9590, 9591, 9592, 9593, 9594, 9595, 9596, 9599, 9600, 9601, 9602,
    9603, 9604, 9605, 9606, 9607, 9608, 9609, 9610, 9611, 9612, 9613, 9614,
    9617, 9618, 9619, 9620, 9621, 9622, 9623, 9624, 9625, 9626, 9627, 9630,
    9631, 9633, 9634, 9636, 9642, 9643, 9644, 9645, 9647, 9648, 9649, 9701,
    9702, 9703, 9704, 9705, 9706, 9707, 9708, 9709, 9710, 9711, 9712, 9713,
    9714, 9715, 9716, 9717, 9718, 9719, 9720, 9721, 9722, 9723, 9724, 9725,
    9726, 9727, 9728, 9729, 9730, 9731, 9732, 9733, 9734, 9735, 9736, 9737,
    9738, 9739, 9740, 9741, 9742, 9743, 9744, 9745, 9746, 9747, 9748, 9749,
    9750, 9751, 9752, 9753, 9754, 9755, 9756, 9757, 9758, 9759, 9760, 9761,
    9762, 9769, 9771, 9777, 9780, 9789, 9790, 9798, 9800, 9801, 9802, 9803,
    9804, 9805, 9806, 9807, 9808, 9809, 9810, 9811, 9812, 9813, 9814, 9815,
    9816, 9817, 9818, 9819, 9820, 9821, 9822, 9823, 9824, 9825, 9826, 9827,
    9828, 9829, 9830, 9831, 9832, 9833, 9834, 9835, 9836, 9837, 9838, 9839,
    9840, 9841, 9842, 9843, 9844, 9845, 9846, 9847, 9848, 9851, 9852, 9853,
    9854, 9855, 9856, 9857, 9858, 9859, 9860, 9861, 9862, 9863, 9864, 9865,
    9867, 9868, 9869, 9870, 9871, 9872, 9873, 9874, 9875, 9876, 9877, 9880,
    9888, 9890, 9892, 9895, 9898, 9901, 9902, 9903, 9904, 9908, 9909, 9910,
    9974, 9975, 9976, 9977, 9978, 34001, 34002, 34004, 34006, 34007, 34008,
    34009, 34010, 34011, 34020, 34021, 34022, 34023, 34024, 34025, 34030, 34031,
    34032, 34033, 34034, 34035, 34036, 34037, 34038, 34039, 34041, 34042, 34043,
    34044, 34050, 34051, 34052, 34053, 34054, 34055, 34058, 34060, 34066, 34067,
    34068, 34069, 34071, 34076, 34078, 34079, 34080, 34081, 34082, 34083, 34084,
    34085, 34086, 34087, 34088, 34089, 34090, 34091, 34092, 34093, 34094, 34095,
    34096, 34098, 34099, 96201, 96202, 96203, 96204, 96205, 96206, 96207, 96208,
    96209, 96210, 96212, 96213, 96214, 96215, 96217, 96218, 96219, 96220, 96221,
    96224, 96251, 96257, 96258, 96259, 96260, 96262, 96264, 96266, 96267, 96269,
    96271, 96273, 96275, 96276, 96278, 96283, 96284, 96297, 96301, 96303, 96306,
    96309, 96310, 96311, 96313, 96315, 96319, 96321, 96322, 96323, 96326, 96328,
    96330, 96331, 96336, 96337, 96338, 96339, 96343, 96346, 96347, 96348, 96349,
    96350, 96351, 96362, 96365, 96367, 96368, 96370, 96371, 96372, 96373, 96374,
    96375, 96376, 96377, 96378, 96379, 96380, 96382, 96384, 96385, 96386, 96387,
    96388, 96389, 96400, 96401, 96426, 96427, 96444, 96447, 96501, 96502, 96503,
    96504, 96505, 96507, 96510, 96511, 96515, 96516, 96517, 96518, 96520, 96521,
    96522, 96530, 96531, 96532, 96534, 96535, 96536, 96537, 96538, 96539, 96540,
    96541, 96542, 96543, 96544, 96546, 96547, 96548, 96549, 96550, 96551, 96552,
    96553, 96554, 96555, 96557, 96562, 96577, 96578, 96595, 96598, 96599, 96601,
    96602, 96603, 96604, 96605, 96606, 96607, 96608, 96609, 96610, 96611, 96612,
    96613, 96614, 96615, 96616, 96617, 96619, 96620, 96621, 96622, 96624, 96628,
    96629, 96631, 96632, 96633, 96634, 96641, 96642, 96643, 96644, 96645, 96649,
    96650, 96657, 96660, 96661, 96662, 96663, 96664, 96665, 96666, 96667, 96668,
    96669, 96670, 96671, 96672, 96673, 96674, 96675, 96677, 96678, 96679, 96681,
    96682, 96683, 96686, 96687, 96691, 96692, 96693, 96694, 96695, 96696, 96698,
    96799, 96910, 96912, 96913, 96915, 96916, 96917, 96919, 96921, 96923, 96928,
    96929, 96931, 96932, 96939, 96940, 96941, 96942, 96943, 96944, 96950, 96951,
    96952, 96960, 96970,
  ],
  "New York County, New York": [
    10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011,
    10012, 10013, 10014, 10015, 10016, 10017, 10018, 10019, 10020, 10021, 10022,
    10023, 10024, 10025, 10026, 10027, 10028, 10029, 10030, 10031, 10032, 10033,
    10034, 10035, 10036, 10037, 10038, 10039, 10040, 10041, 10043, 10044, 10045,
    10046, 10047, 10048, 10055, 10060, 10065, 10069, 10072, 10075, 10079, 10080,
    10081, 10082, 10087, 10090, 10094, 10095, 10096, 10098, 10099, 10101, 10102,
    10103, 10104, 10105, 10106, 10107, 10108, 10109, 10110, 10111, 10112, 10113,
    10114, 10115, 10116, 10117, 10118, 10119, 10120, 10121, 10122, 10123, 10124,
    10125, 10126, 10128, 10129, 10130, 10131, 10132, 10133, 10138, 10149, 10150,
    10151, 10152, 10153, 10154, 10155, 10156, 10157, 10158, 10159, 10160, 10161,
    10162, 10163, 10164, 10165, 10166, 10167, 10168, 10169, 10170, 10171, 10172,
    10173, 10174, 10175, 10176, 10177, 10178, 10179, 10184, 10185, 10196, 10197,
    10199, 10203, 10211, 10212, 10213, 10242, 10249, 10256, 10257, 10258, 10259,
    10260, 10261, 10265, 10268, 10269, 10270, 10271, 10272, 10273, 10274, 10275,
    10276, 10277, 10278, 10279, 10280, 10281, 10282, 10285, 10286, 10292,
  ],
  "undefined, New York": [10200, 11437],
  "Richmond County, New York": [
    10301, 10302, 10303, 10304, 10305, 10306, 10307, 10308, 10309, 10310, 10311,
    10312, 10313, 10314,
  ],
  "Bronx County, New York": [
    10451, 10452, 10453, 10454, 10455, 10456, 10457, 10458, 10459, 10460, 10461,
    10462, 10463, 10464, 10465, 10466, 10467, 10468, 10469, 10470, 10471, 10472,
    10473, 10474, 10475, 10499,
  ],
  "Westchester County, New York": [
    10501, 10502, 10503, 10504, 10505, 10506, 10507, 10510, 10511, 10514, 10517,
    10518, 10519, 10520, 10521, 10522, 10523, 10526, 10527, 10528, 10530, 10532,
    10533, 10535, 10536, 10538, 10540, 10543, 10545, 10546, 10547, 10548, 10549,
    10550, 10551, 10552, 10553, 10557, 10558, 10560, 10562, 10566, 10567, 10570,
    10571, 10572, 10573, 10576, 10577, 10578, 10580, 10583, 10587, 10588, 10589,
    10590, 10591, 10594, 10595, 10596, 10597, 10598, 10601, 10602, 10603, 10604,
    10605, 10606, 10607, 10610, 10701, 10702, 10703, 10704, 10705, 10706, 10707,
    10708, 10709, 10710, 10801, 10802, 10803, 10804, 10805,
  ],
  "Putnam County, New York": [
    10509, 10512, 10516, 10524, 10537, 10541, 10542, 10579, 12563,
  ],
  "Rockland County, New York": [
    10901, 10911, 10913, 10920, 10923, 10927, 10931, 10952, 10954, 10956, 10960,
    10962, 10964, 10965, 10968, 10970, 10974, 10976, 10977, 10980, 10982, 10983,
    10984, 10986, 10989, 10993, 10994,
  ],
  "Orange County, New York": [
    10910, 10912, 10914, 10915, 10916, 10917, 10918, 10919, 10921, 10922, 10924,
    10925, 10926, 10928, 10930, 10932, 10933, 10940, 10941, 10943, 10949, 10950,
    10953, 10958, 10959, 10963, 10969, 10973, 10975, 10979, 10981, 10985, 10987,
    10988, 10990, 10992, 10996, 10997, 10998, 12518, 12520, 12543, 12549, 12550,
    12551, 12552, 12553, 12555, 12575, 12577, 12584, 12586, 12729, 12746, 12771,
    12780, 12785,
  ],
  "Nassau County, New York": [
    11001, 11003, 11010, 11020, 11021, 11022, 11023, 11024, 11025, 11026, 11027,
    11030, 11040, 11041, 11042, 11043, 11044, 11050, 11051, 11052, 11053, 11054,
    11055, 11096, 11099, 11501, 11507, 11509, 11510, 11514, 11516, 11518, 11520,
    11530, 11531, 11535, 11536, 11542, 11545, 11547, 11548, 11549, 11550, 11551,
    11552, 11553, 11554, 11555, 11556, 11557, 11558, 11559, 11560, 11561, 11563,
    11565, 11566, 11568, 11569, 11570, 11571, 11572, 11575, 11576, 11577, 11579,
    11580, 11581, 11582, 11590, 11592, 11594, 11595, 11596, 11597, 11598, 11599,
    11709, 11710, 11714, 11732, 11735, 11736, 11737, 11753, 11756, 11758, 11762,
    11765, 11771, 11773, 11774, 11783, 11791, 11793, 11797, 11801, 11802, 11803,
    11804, 11815, 11819, 11853, 11854, 11855,
  ],
  "Queens County, New York": [
    11002, 11004, 11005, 11101, 11102, 11103, 11104, 11105, 11106, 11109, 11120,
    11351, 11352, 11354, 11355, 11356, 11357, 11358, 11359, 11360, 11361, 11362,
    11363, 11364, 11365, 11366, 11367, 11368, 11369, 11370, 11371, 11372, 11373,
    11374, 11375, 11377, 11378, 11379, 11380, 11381, 11385, 11386, 11390, 11405,
    11411, 11412, 11413, 11414, 11415, 11416, 11417, 11418, 11419, 11420, 11421,
    11422, 11423, 11424, 11426, 11427, 11428, 11429, 11430, 11431, 11432, 11433,
    11434, 11435, 11436, 11439, 11451, 11499, 11690, 11691, 11692, 11693, 11694,
    11695, 11697,
  ],
  "Kings County, New York": [
    11201, 11202, 11203, 11204, 11205, 11206, 11207, 11208, 11209, 11210, 11211,
    11212, 11213, 11214, 11215, 11216, 11217, 11218, 11219, 11220, 11221, 11222,
    11223, 11224, 11225, 11226, 11228, 11229, 11230, 11231, 11232, 11233, 11234,
    11235, 11236, 11237, 11238, 11239, 11240, 11241, 11242, 11243, 11244, 11245,
    11247, 11248, 11249, 11251, 11252, 11254, 11255, 11256, 11425,
  ],
  "Albany County, New York": [
    12007, 12009, 12023, 12041, 12045, 12046, 12047, 12054, 12055, 12059, 12067,
    12077, 12084, 12085, 12107, 12110, 12120, 12128, 12143, 12147, 12158, 12159,
    12161, 12183, 12186, 12189, 12193, 12201, 12202, 12203, 12204, 12205, 12206,
    12207, 12208, 12209, 12210, 12211, 12212, 12214, 12220, 12222, 12223, 12224,
    12225, 12226, 12227, 12228, 12229, 12230, 12231, 12232, 12233, 12234, 12235,
    12236, 12237, 12238, 12239, 12240, 12241, 12242, 12243, 12244, 12245, 12246,
    12247, 12248, 12249, 12250, 12252, 12255, 12256, 12257, 12260, 12261, 12288,
    12469,
  ],
  "Schenectady County, New York": [
    12008, 12053, 12056, 12137, 12141, 12150, 12301, 12302, 12303, 12304, 12305,
    12306, 12307, 12308, 12309, 12325, 12345,
  ],
  "Montgomery County, New York": [
    12010, 12016, 12068, 12069, 12070, 12072, 12086, 12166, 12177, 13317, 13339,
    13410, 13428, 13452,
  ],
  "Greene County, New York": [
    12015, 12042, 12051, 12058, 12083, 12087, 12124, 12176, 12192, 12405, 12407,
    12413, 12414, 12418, 12422, 12423, 12424, 12427, 12431, 12436, 12439, 12442,
    12444, 12450, 12451, 12452, 12454, 12460, 12463, 12468, 12470, 12473, 12482,
    12485, 12492, 12496,
  ],
  "Columbia County, New York": [
    12017, 12024, 12029, 12037, 12050, 12060, 12075, 12106, 12115, 12125, 12130,
    12132, 12136, 12165, 12172, 12173, 12174, 12184, 12195, 12502, 12503, 12513,
    12516, 12517, 12521, 12523, 12526, 12529, 12530, 12534, 12541, 12544, 12565,
    12593,
  ],
  "Rensselaer County, New York": [
    12018, 12022, 12028, 12033, 12040, 12052, 12061, 12062, 12063, 12082, 12089,
    12090, 12094, 12121, 12123, 12133, 12138, 12140, 12144, 12153, 12154, 12156,
    12168, 12169, 12180, 12181, 12182, 12185, 12196, 12198,
  ],
  "Saratoga County, New York": [
    12019, 12020, 12027, 12065, 12074, 12118, 12148, 12151, 12170, 12188, 12803,
    12822, 12831, 12833, 12835, 12850, 12859, 12863, 12866, 12871, 12884,
  ],
  "Fulton County, New York": [12025, 12032, 12078, 12095, 12117, 12134, 13470],
  "Schoharie County, New York": [
    12031, 12035, 12036, 12043, 12066, 12071, 12073, 12076, 12092, 12093, 12122,
    12131, 12149, 12157, 12160, 12175, 12187, 12194, 13459,
  ],
  "Washington County, New York": [
    12057, 12809, 12816, 12819, 12821, 12823, 12827, 12828, 12832, 12834, 12837,
    12838, 12839, 12841, 12844, 12848, 12849, 12854, 12861, 12865, 12873, 12887,
  ],
  "Otsego County, New York": [
    12064, 12116, 12155, 12197, 13315, 13320, 13326, 13333, 13335, 13337, 13342,
    13348, 13415, 13439, 13450, 13457, 13468, 13482, 13488, 13747, 13776, 13796,
    13807, 13808, 13810, 13820, 13825, 13834, 13849, 13859, 13861,
  ],
  "Hamilton County, New York": [
    12108, 12139, 12164, 12190, 12812, 12842, 12847, 12864, 13353, 13360, 13436,
  ],
  "Delaware County, New York": [
    12167, 12406, 12421, 12430, 12434, 12438, 12455, 12459, 12474, 12760, 13731,
    13739, 13740, 13750, 13751, 13752, 13753, 13755, 13756, 13757, 13774, 13775,
    13782, 13783, 13786, 13788, 13804, 13806, 13837, 13838, 13839, 13842, 13846,
    13847, 13856, 13860,
  ],
  "Ulster County, New York": [
    12401, 12402, 12404, 12409, 12410, 12411, 12412, 12416, 12417, 12419, 12420,
    12428, 12429, 12432, 12433, 12435, 12440, 12441, 12443, 12446, 12448, 12449,
    12453, 12456, 12457, 12458, 12461, 12464, 12465, 12466, 12471, 12472, 12475,
    12477, 12480, 12481, 12483, 12484, 12486, 12487, 12489, 12490, 12491, 12493,
    12494, 12495, 12498, 12515, 12525, 12528, 12542, 12547, 12548, 12561, 12566,
    12568, 12588, 12589, 12725,
  ],
  "Dutchess County, New York": [
    12501, 12504, 12506, 12507, 12508, 12510, 12511, 12512, 12514, 12522, 12524,
    12527, 12531, 12533, 12537, 12538, 12540, 12545, 12546, 12564, 12567, 12569,
    12570, 12571, 12572, 12574, 12578, 12580, 12581, 12582, 12583, 12585, 12590,
    12592, 12594, 12601, 12602, 12603, 12604,
  ],
  "Sullivan County, New York": [
    12701, 12719, 12720, 12721, 12722, 12723, 12724, 12726, 12727, 12732, 12733,
    12734, 12736, 12737, 12738, 12740, 12741, 12742, 12743, 12745, 12747, 12748,
    12749, 12750, 12751, 12752, 12754, 12758, 12759, 12762, 12763, 12764, 12765,
    12766, 12767, 12768, 12769, 12770, 12775, 12776, 12777, 12778, 12779, 12781,
    12783, 12784, 12786, 12787, 12788, 12789, 12790, 12791, 12792,
  ],
  "Warren County, New York": [
    12801, 12804, 12808, 12810, 12811, 12814, 12815, 12817, 12820, 12824, 12836,
    12843, 12845, 12846, 12853, 12856, 12860, 12862, 12874, 12878, 12885, 12886,
  ],
  "Essex County, New York": [
    12851, 12852, 12855, 12857, 12858, 12870, 12872, 12879, 12883, 12913, 12928,
    12932, 12936, 12941, 12942, 12943, 12944, 12946, 12950, 12956, 12960, 12961,
    12964, 12974, 12975, 12977, 12987, 12993, 12996, 12997, 12998,
  ],
  "Clinton County, New York": [
    12901, 12903, 12910, 12911, 12912, 12918, 12919, 12921, 12923, 12924, 12929,
    12933, 12934, 12935, 12952, 12955, 12958, 12959, 12962, 12972, 12978, 12979,
    12981, 12985, 12992,
  ],
  "Franklin County, New York": [
    12914, 12915, 12916, 12917, 12920, 12926, 12930, 12937, 12939, 12945, 12953,
    12957, 12966, 12969, 12970, 12976, 12980, 12983, 12986, 12989, 12995, 13655,
  ],
  "St. Lawrence County, New York": [
    12922, 12927, 12965, 12967, 12973, 13613, 13614, 13617, 13621, 13623, 13625,
    13630, 13633, 13635, 13639, 13642, 13645, 13646, 13647, 13649, 13652, 13654,
    13658, 13660, 13662, 13664, 13666, 13667, 13668, 13669, 13670, 13672, 13676,
    13677, 13678, 13680, 13681, 13683, 13684, 13687, 13690, 13694, 13695, 13696,
    13697, 13699,
  ],
  "St Lawrence County, New York": [12949],
  "Onondaga County, New York": [
    13020, 13027, 13029, 13031, 13039, 13041, 13051, 13057, 13060, 13063, 13066,
    13078, 13080, 13084, 13088, 13089, 13090, 13104, 13108, 13110, 13112, 13116,
    13119, 13120, 13137, 13138, 13152, 13153, 13159, 13164, 13201, 13202, 13203,
    13204, 13205, 13206, 13207, 13208, 13209, 13210, 13211, 13212, 13214, 13215,
    13217, 13218, 13219, 13220, 13221, 13224, 13225, 13235, 13244, 13250, 13251,
    13252, 13261, 13290,
  ],
  "Cayuga County, New York": [
    13021, 13022, 13024, 13026, 13033, 13034, 13064, 13071, 13081, 13092, 13111,
    13113, 13117, 13118, 13139, 13140, 13147, 13156, 13160, 13166,
  ],
  "Oswego County, New York": [
    13028, 13036, 13044, 13069, 13074, 13076, 13083, 13093, 13103, 13107, 13114,
    13115, 13121, 13126, 13131, 13132, 13135, 13142, 13144, 13145, 13167, 13302,
    13426, 13437, 13493,
  ],
  "Madison County, New York": [
    13030, 13032, 13035, 13037, 13043, 13052, 13061, 13072, 13082, 13122, 13134,
    13163, 13310, 13314, 13332, 13334, 13346, 13355, 13364, 13402, 13408, 13409,
    13418, 13421, 13465, 13484, 13485,
  ],
  "Cortland County, New York": [
    13040, 13045, 13056, 13077, 13087, 13101, 13141, 13158, 13738, 13784, 13803,
    13863,
  ],
  "Oneida County, New York": [
    13042, 13054, 13123, 13157, 13162, 13301, 13303, 13304, 13308, 13309, 13313,
    13316, 13318, 13319, 13321, 13322, 13323, 13328, 13338, 13341, 13352, 13354,
    13362, 13363, 13401, 13403, 13413, 13417, 13424, 13425, 13435, 13438, 13440,
    13441, 13442, 13449, 13455, 13456, 13461, 13469, 13471, 13476, 13477, 13478,
    13479, 13480, 13483, 13486, 13490, 13492, 13494, 13495, 13501, 13502, 13503,
    13504, 13505, 13599,
  ],
  "Tompkins County, New York": [
    13053, 13062, 13068, 13073, 13102, 14817, 14850, 14851, 14852, 14853, 14854,
    14867, 14881, 14882, 14886,
  ],
  "Seneca County, New York": [
    13065, 13148, 13165, 14521, 14541, 14588, 14847, 14860,
  ],
  "Chenango County, New York": [
    13124, 13136, 13155, 13411, 13460, 13464, 13730, 13733, 13758, 13778, 13780,
    13801, 13809, 13814, 13815, 13830, 13832, 13841, 13843, 13844,
  ],
  "Wayne County, New York": [
    13143, 13146, 13154, 14413, 14433, 14449, 14489, 14502, 14505, 14513, 14516,
    14519, 14520, 14522, 14538, 14542, 14551, 14555, 14563, 14568, 14589, 14590,
  ],
  "Lewis County, New York": [
    13305, 13312, 13325, 13327, 13343, 13345, 13367, 13368, 13404, 13433, 13473,
    13489, 13620, 13626, 13627, 13631, 13648,
  ],
  "Herkimer County, New York": [
    13324, 13329, 13331, 13340, 13350, 13357, 13361, 13365, 13406, 13407, 13416,
    13420, 13431, 13454, 13472, 13475, 13491,
  ],
  "Jefferson County, New York": [
    13601, 13602, 13603, 13605, 13606, 13607, 13608, 13611, 13612, 13615, 13616,
    13618, 13619, 13622, 13624, 13628, 13632, 13634, 13636, 13637, 13638, 13640,
    13641, 13643, 13650, 13651, 13656, 13657, 13659, 13661, 13665, 13671, 13673,
    13674, 13675, 13679, 13682, 13685, 13691, 13692, 13693,
  ],
  "Tioga County, New York": [
    13732, 13734, 13736, 13743, 13811, 13812, 13827, 13835, 13840, 13845, 13864,
    14859, 14883, 14892,
  ],
  "Broome County, New York": [
    13737, 13744, 13745, 13746, 13748, 13749, 13754, 13760, 13761, 13762, 13763,
    13777, 13787, 13790, 13794, 13795, 13797, 13802, 13813, 13826, 13833, 13848,
    13850, 13851, 13862, 13865, 13901, 13902, 13903, 13904, 13905,
  ],
  "Erie County, New York": [
    14001, 14004, 14006, 14010, 14025, 14026, 14027, 14030, 14031, 14032, 14033,
    14034, 14035, 14038, 14043, 14047, 14051, 14052, 14055, 14057, 14059, 14061,
    14068, 14069, 14072, 14075, 14080, 14081, 14085, 14086, 14091, 14102, 14110,
    14111, 14112, 14127, 14134, 14139, 14140, 14141, 14150, 14151, 14169, 14170,
    14201, 14202, 14203, 14204, 14205, 14206, 14207, 14208, 14209, 14210, 14211,
    14212, 14213, 14214, 14215, 14216, 14217, 14218, 14219, 14220, 14221, 14222,
    14223, 14224, 14225, 14226, 14227, 14228, 14231, 14233, 14240, 14241, 14260,
    14261, 14263, 14264, 14265, 14267, 14269, 14270, 14272, 14273, 14276, 14280,
  ],
  "Genesee County, New York": [
    14005, 14013, 14020, 14021, 14036, 14040, 14054, 14056, 14058, 14125, 14143,
    14416, 14422, 14482, 14525, 14557,
  ],
  "Niagara County, New York": [
    14008, 14012, 14028, 14067, 14092, 14094, 14095, 14105, 14107, 14108, 14109,
    14120, 14126, 14131, 14132, 14144, 14172, 14174, 14301, 14302, 14303, 14304,
    14305,
  ],
  "Wyoming County, New York": [
    14009, 14011, 14024, 14037, 14039, 14066, 14082, 14083, 14113, 14130, 14145,
    14167, 14427, 14530, 14536, 14549, 14550, 14569, 14591,
  ],
  "Allegany County, New York": [
    14029, 14707, 14708, 14709, 14711, 14714, 14715, 14717, 14721, 14727, 14735,
    14739, 14744, 14745, 14754, 14774, 14777, 14786, 14802, 14803, 14804, 14806,
    14813, 14822, 14880, 14884, 14895, 14897,
  ],
  "Cattaraugus County, New York": [
    14041, 14042, 14060, 14065, 14070, 14101, 14129, 14133, 14138, 14168, 14171,
    14173, 14706, 14719, 14726, 14729, 14730, 14731, 14737, 14741, 14743, 14748,
    14751, 14753, 14755, 14760, 14766, 14770, 14772, 14778, 14779, 14783, 14788,
  ],
  "Chautauqua County, New York": [
    14048, 14062, 14063, 14135, 14136, 14166, 14701, 14702, 14710, 14712, 14716,
    14718, 14720, 14722, 14723, 14724, 14728, 14732, 14733, 14736, 14738, 14740,
    14742, 14747, 14750, 14752, 14756, 14757, 14758, 14767, 14769, 14775, 14781,
    14782, 14784, 14785, 14787,
  ],
  "Orleans County, New York": [
    14098, 14103, 14411, 14429, 14452, 14470, 14476, 14477, 14479, 14508, 14571,
  ],
  "Monroe County, New York": [
    14410, 14420, 14428, 14430, 14445, 14450, 14464, 14467, 14468, 14472, 14506,
    14511, 14514, 14515, 14526, 14534, 14543, 14546, 14559, 14580, 14586, 14602,
    14603, 14604, 14605, 14606, 14607, 14608, 14609, 14610, 14611, 14612, 14613,
    14614, 14615, 14616, 14617, 14618, 14619, 14620, 14621, 14622, 14623, 14624,
    14625, 14626, 14627, 14638, 14639, 14642, 14643, 14644, 14645, 14646, 14647,
    14649, 14650, 14651, 14652, 14653, 14664, 14673, 14683, 14692, 14694,
  ],
  "Livingston County, New York": [
    14414, 14423, 14435, 14437, 14454, 14462, 14480, 14481, 14485, 14486, 14487,
    14488, 14510, 14517, 14533, 14539, 14545, 14556, 14558, 14560, 14592, 14836,
    14846,
  ],
  "Yates County, New York": [
    14415, 14418, 14441, 14478, 14507, 14527, 14544, 14837, 14842, 14857,
  ],
  "Ontario County, New York": [
    14424, 14425, 14432, 14443, 14453, 14456, 14461, 14463, 14466, 14469, 14471,
    14475, 14504, 14512, 14518, 14532, 14537, 14547, 14548, 14561, 14564, 14585,
  ],
  "Steuben County, New York": [
    14529, 14572, 14801, 14807, 14808, 14809, 14810, 14819, 14820, 14821, 14823,
    14826, 14827, 14830, 14831, 14839, 14840, 14843, 14855, 14856, 14858, 14870,
    14873, 14874, 14877, 14879, 14885, 14893, 14898,
  ],
  "Schuyler County, New York": [
    14805, 14812, 14815, 14818, 14824, 14841, 14863, 14865, 14869, 14876, 14878,
    14887, 14891,
  ],
  "Chemung County, New York": [
    14814, 14816, 14825, 14838, 14845, 14861, 14864, 14871, 14872, 14889, 14894,
    14901, 14902, 14903, 14904, 14905, 14925,
  ],
  "Beaver County, Pennsylvania": [
    15001, 15003, 15005, 15009, 15010, 15026, 15027, 15042, 15043, 15050, 15052,
    15059, 15061, 15066, 15074, 15077, 15081, 16115, 16123, 16136,
  ],
  "Washington County, Pennsylvania": [
    15004, 15019, 15021, 15022, 15033, 15038, 15053, 15054, 15055, 15060, 15063,
    15067, 15078, 15301, 15311, 15312, 15313, 15314, 15317, 15321, 15323, 15324,
    15329, 15330, 15331, 15332, 15333, 15336, 15339, 15340, 15342, 15345, 15347,
    15348, 15350, 15358, 15360, 15361, 15363, 15365, 15366, 15367, 15368, 15376,
    15377, 15378, 15379, 15412, 15419, 15423, 15427, 15429, 15432, 15434, 15477,
    15483,
  ],
  "Allegheny County, Pennsylvania": [
    15006, 15007, 15014, 15015, 15017, 15018, 15020, 15024, 15025, 15028, 15030,
    15031, 15032, 15034, 15035, 15037, 15044, 15045, 15046, 15047, 15049, 15051,
    15056, 15057, 15064, 15065, 15071, 15075, 15076, 15082, 15084, 15086, 15088,
    15090, 15091, 15095, 15096, 15101, 15102, 15104, 15106, 15108, 15110, 15112,
    15116, 15120, 15122, 15123, 15126, 15127, 15129, 15131, 15132, 15133, 15134,
    15135, 15136, 15137, 15139, 15140, 15142, 15143, 15144, 15145, 15146, 15147,
    15148, 15201, 15202, 15203, 15204, 15205, 15206, 15207, 15208, 15209, 15210,
    15211, 15212, 15213, 15214, 15215, 15216, 15217, 15218, 15219, 15220, 15221,
    15222, 15223, 15224, 15225, 15226, 15227, 15228, 15229, 15230, 15231, 15232,
    15233, 15234, 15235, 15236, 15237, 15238, 15239, 15240, 15241, 15242, 15243,
    15244, 15250, 15251, 15252, 15253, 15254, 15255, 15257, 15258, 15259, 15260,
    15261, 15262, 15263, 15264, 15265, 15267, 15268, 15270, 15272, 15274, 15275,
    15276, 15277, 15278, 15279, 15281, 15282, 15283, 15285, 15286, 15289, 15290,
    15295,
  ],
  "Westmoreland County, Pennsylvania": [
    15012, 15062, 15068, 15069, 15072, 15083, 15085, 15087, 15089, 15448, 15479,
    15601, 15605, 15606, 15610, 15611, 15612, 15613, 15615, 15616, 15617, 15618,
    15619, 15620, 15621, 15623, 15624, 15625, 15626, 15627, 15628, 15629, 15632,
    15633, 15634, 15635, 15636, 15637, 15638, 15639, 15640, 15641, 15642, 15644,
    15646, 15647, 15650, 15655, 15656, 15658, 15660, 15661, 15662, 15663, 15664,
    15665, 15666, 15668, 15670, 15671, 15672, 15674, 15675, 15676, 15677, 15678,
    15679, 15680, 15683, 15684, 15685, 15687, 15688, 15689, 15690, 15691, 15692,
    15693, 15695, 15696, 15697, 15698, 15779, 15923, 15944,
  ],
  "undefined, Pennsylvania": [15266, 15273, 15288],
  "Greene County, Pennsylvania": [
    15310, 15315, 15316, 15320, 15322, 15325, 15327, 15334, 15337, 15338, 15341,
    15344, 15346, 15349, 15351, 15352, 15353, 15357, 15359, 15362, 15364, 15370,
    15380,
  ],
  "Fayette County, Pennsylvania": [
    15401, 15410, 15413, 15415, 15416, 15417, 15420, 15421, 15422, 15425, 15428,
    15430, 15431, 15433, 15435, 15436, 15437, 15438, 15440, 15442, 15443, 15444,
    15445, 15446, 15447, 15449, 15450, 15451, 15454, 15455, 15456, 15458, 15459,
    15460, 15461, 15462, 15463, 15464, 15465, 15466, 15467, 15468, 15469, 15470,
    15472, 15473, 15474, 15475, 15476, 15478, 15480, 15482, 15484, 15486, 15488,
    15489, 15490, 15492, 15622, 15631,
  ],
  "Somerset County, Pennsylvania": [
    15411, 15424, 15485, 15501, 15502, 15510, 15520, 15530, 15531, 15532, 15538,
    15540, 15541, 15542, 15544, 15546, 15547, 15548, 15549, 15551, 15552, 15553,
    15555, 15557, 15558, 15560, 15561, 15562, 15563, 15564, 15565, 15924, 15926,
    15928, 15935, 15936, 15937, 15953, 15959, 15963,
  ],
  "Monongalia County, Pennsylvania": [15439],
  "Bedford County, Pennsylvania": [
    15521, 15522, 15533, 15534, 15535, 15537, 15539, 15545, 15550, 15554, 15559,
    16633, 16650, 16655, 16659, 16664, 16667, 16670, 16672, 16678, 16679, 16695,
    17211,
  ],
  "Fulton County, Pennsylvania": [
    15536, 16689, 16691, 17212, 17215, 17223, 17228, 17229, 17233, 17238, 17267,
  ],
  "Armstrong County, Pennsylvania": [
    15673, 15682, 15686, 15736, 15774, 16028, 16201, 16210, 16212, 16215, 16218,
    16222, 16223, 16226, 16228, 16229, 16236, 16238, 16244, 16245, 16249, 16250,
    16253, 16259, 16261, 16262, 16263,
  ],
  "Indiana County, Pennsylvania": [
    15681, 15701, 15705, 15710, 15712, 15713, 15716, 15717, 15720, 15723, 15724,
    15725, 15727, 15728, 15729, 15731, 15732, 15734, 15739, 15741, 15742, 15745,
    15746, 15747, 15748, 15750, 15752, 15754, 15756, 15758, 15759, 15761, 15763,
    15765, 15771, 15772, 15777, 15783, 15920, 15929, 15949, 15954, 15957, 16211,
    16246, 16256,
  ],
  "Jefferson County, Pennsylvania": [
    15711, 15715, 15730, 15733, 15740, 15744, 15764, 15767, 15770, 15776, 15778,
    15780, 15781, 15784, 15824, 15825, 15828, 15829, 15840, 15847, 15851, 15860,
    15863, 15864, 15865,
  ],
  "Cambria County, Pennsylvania": [
    15714, 15722, 15737, 15738, 15760, 15762, 15773, 15775, 15901, 15902, 15904,
    15905, 15906, 15907, 15909, 15915, 15921, 15922, 15925, 15927, 15930, 15931,
    15934, 15938, 15940, 15942, 15943, 15945, 15946, 15948, 15951, 15952, 15955,
    15956, 15958, 15960, 15961, 15962, 16613, 16619, 16624, 16629, 16630, 16636,
    16639, 16640, 16641, 16644, 16646, 16668, 16675, 16699,
  ],
  "Clearfield County, Pennsylvania": [
    15721, 15753, 15757, 15801, 15848, 15849, 15856, 15866, 16616, 16620, 16627,
    16645, 16651, 16656, 16661, 16663, 16666, 16671, 16680, 16681, 16692, 16698,
    16821, 16825, 16830, 16833, 16834, 16836, 16837, 16838, 16839, 16840, 16843,
    16845, 16847, 16849, 16850, 16855, 16858, 16860, 16861, 16863, 16871, 16873,
    16876, 16878, 16879, 16881,
  ],
  "Elk County, Pennsylvania": [
    15821, 15822, 15823, 15827, 15831, 15841, 15845, 15846, 15853, 15857, 15868,
    15870, 16728, 16734,
  ],
  "Cameron County, Pennsylvania": [15832, 15834, 15861],
  "Butler County, Pennsylvania": [
    16001, 16002, 16003, 16016, 16017, 16018, 16020, 16021, 16022, 16023, 16024,
    16025, 16027, 16029, 16030, 16033, 16034, 16035, 16037, 16038, 16039, 16040,
    16041, 16045, 16046, 16048, 16050, 16051, 16052, 16053, 16055, 16056, 16057,
    16059, 16061, 16063, 16066,
  ],
  "Clarion County, Pennsylvania": [
    16036, 16049, 16054, 16058, 16213, 16214, 16220, 16221, 16224, 16225, 16230,
    16232, 16233, 16234, 16235, 16240, 16242, 16248, 16254, 16255, 16257, 16258,
    16260, 16326, 16331, 16332, 16334, 16361, 16375,
  ],
  "Lawrence County, Pennsylvania": [
    16101, 16102, 16103, 16105, 16107, 16108, 16112, 16116, 16117, 16120, 16132,
    16140, 16141, 16142, 16143, 16155, 16156, 16157, 16160, 16172,
  ],
  "Crawford County, Pennsylvania": [
    16110, 16111, 16131, 16134, 16314, 16316, 16327, 16328, 16335, 16354, 16360,
    16388, 16403, 16404, 16406, 16422, 16424, 16432, 16433, 16434, 16435, 16440,
  ],
  "Mercer County, Pennsylvania": [
    16113, 16114, 16121, 16124, 16125, 16127, 16130, 16133, 16137, 16145, 16146,
    16148, 16150, 16151, 16153, 16154, 16159, 16161, 16311,
  ],
  "Forest County, Pennsylvania": [16217, 16239, 16321, 16322, 16353, 16370],
  "Venango County, Pennsylvania": [
    16301, 16317, 16319, 16323, 16341, 16342, 16343, 16344, 16346, 16362, 16364,
    16372, 16373, 16374,
  ],
  "Warren County, Pennsylvania": [
    16312, 16313, 16329, 16340, 16345, 16347, 16350, 16351, 16352, 16365, 16366,
    16367, 16368, 16369, 16371, 16402, 16405, 16416, 16420, 16436,
  ],
  "McKean County, Pennsylvania": [
    16333, 16701, 16724, 16725, 16726, 16727, 16729, 16730, 16731, 16732, 16733,
    16735, 16738, 16740, 16743, 16744, 16745, 16749, 16750,
  ],
  "Erie County, Pennsylvania": [
    16401, 16407, 16410, 16411, 16412, 16413, 16415, 16417, 16421, 16423, 16426,
    16427, 16428, 16430, 16438, 16441, 16442, 16443, 16444, 16475, 16501, 16502,
    16503, 16504, 16505, 16506, 16507, 16508, 16509, 16510, 16511, 16512, 16514,
    16515, 16522, 16530, 16531, 16532, 16533, 16534, 16538, 16541, 16544, 16546,
    16550, 16553, 16554, 16563, 16565,
  ],
  "Blair County, Pennsylvania": [
    16601, 16602, 16603, 16617, 16625, 16631, 16635, 16637, 16648, 16662, 16665,
    16673, 16682, 16684, 16686, 16693,
  ],
  "Huntingdon County, Pennsylvania": [
    16611, 16621, 16622, 16623, 16634, 16638, 16647, 16652, 16654, 16657, 16660,
    16669, 16674, 16683, 16685, 16694, 16877, 17052, 17060, 17066, 17213, 17239,
    17243, 17249, 17253, 17255, 17260, 17264,
  ],
  "Centre County, Pennsylvania": [
    16677, 16801, 16802, 16803, 16804, 16805, 16820, 16823, 16826, 16827, 16828,
    16829, 16832, 16835, 16841, 16844, 16851, 16852, 16853, 16854, 16856, 16859,
    16864, 16865, 16866, 16868, 16870, 16872, 16874, 16875, 16882,
  ],
  "Potter County, Pennsylvania": [
    16720, 16746, 16748, 16915, 16922, 16923, 16927, 16937, 16941, 16948, 17729,
  ],
  "Clinton County, Pennsylvania": [
    16822, 16848, 17721, 17726, 17738, 17745, 17747, 17748, 17750, 17751, 17760,
    17764, 17767, 17773, 17778, 17779,
  ],
  "Tioga County, Pennsylvania": [
    16901, 16911, 16912, 16917, 16918, 16920, 16921, 16928, 16929, 16930, 16932,
    16933, 16935, 16936, 16938, 16939, 16940, 16942, 16943, 16946, 16950, 17765,
  ],
  "Bradford County, Pennsylvania": [
    16910, 16914, 16925, 16926, 16945, 16947, 17724, 17735, 18810, 18814, 18815,
    18817, 18829, 18831, 18832, 18833, 18837, 18840, 18845, 18846, 18848, 18850,
    18851, 18853, 18854,
  ],
  "Cumberland County, Pennsylvania": [
    17001, 17007, 17008, 17011, 17012, 17013, 17015, 17025, 17027, 17043, 17050,
    17055, 17065, 17070, 17072, 17081, 17089, 17093, 17240, 17241, 17257, 17266,
    17324,
  ],
  "Mifflin County, Pennsylvania": [
    17002, 17004, 17009, 17029, 17044, 17051, 17054, 17063, 17075, 17084, 17099,
    17841,
  ],
  "Lebanon County, Pennsylvania": [
    17003, 17010, 17016, 17026, 17038, 17039, 17041, 17042, 17046, 17064, 17067,
    17073, 17077, 17078, 17083, 17085, 17087, 17088, 17091,
  ],
  "Dauphin County, Pennsylvania": [
    17005, 17018, 17023, 17028, 17030, 17032, 17033, 17034, 17036, 17048, 17057,
    17061, 17080, 17097, 17098, 17101, 17102, 17103, 17104, 17105, 17106, 17107,
    17108, 17109, 17110, 17111, 17112, 17113, 17120, 17121, 17122, 17123, 17124,
    17125, 17126, 17127, 17128, 17129, 17130, 17140, 17177, 17978,
  ],
  "Perry County, Pennsylvania": [
    17006, 17020, 17024, 17037, 17040, 17045, 17047, 17053, 17062, 17068, 17069,
    17071, 17074, 17090,
  ],
  "Juniata County, Pennsylvania": [
    17014, 17021, 17035, 17049, 17056, 17058, 17059, 17076, 17082, 17086, 17094,
  ],
  "Northumberland County, Pennsylvania": [
    17017, 17730, 17749, 17772, 17777, 17801, 17823, 17824, 17830, 17832, 17834,
    17836, 17840, 17847, 17850, 17851, 17857, 17860, 17865, 17866, 17867, 17868,
    17872, 17877, 17881,
  ],
  "York County, Pennsylvania": [
    17019, 17302, 17309, 17311, 17312, 17313, 17314, 17315, 17317, 17318, 17319,
    17321, 17322, 17323, 17327, 17329, 17331, 17332, 17333, 17334, 17335, 17339,
    17342, 17345, 17347, 17349, 17352, 17354, 17355, 17356, 17358, 17360, 17361,
    17362, 17363, 17364, 17365, 17366, 17368, 17370, 17371, 17401, 17402, 17403,
    17404, 17405, 17406, 17407, 17408, 17415,
  ],
  "Lancaster County, Pennsylvania": [
    17022, 17501, 17502, 17503, 17504, 17505, 17506, 17507, 17508, 17509, 17512,
    17516, 17517, 17518, 17519, 17520, 17521, 17522, 17527, 17528, 17529, 17532,
    17533, 17534, 17535, 17536, 17537, 17538, 17540, 17543, 17545, 17547, 17549,
    17550, 17551, 17552, 17554, 17555, 17557, 17560, 17562, 17563, 17564, 17565,
    17566, 17567, 17568, 17569, 17570, 17572, 17573, 17575, 17576, 17578, 17579,
    17580, 17581, 17582, 17583, 17584, 17585, 17601, 17602, 17603, 17604, 17605,
    17606, 17607, 17608, 17611, 17622, 17699, 19501,
  ],
  "Franklin County, Pennsylvania": [
    17201, 17202, 17210, 17214, 17217, 17219, 17220, 17221, 17222, 17224, 17225,
    17231, 17232, 17235, 17236, 17237, 17244, 17246, 17247, 17250, 17251, 17252,
    17254, 17256, 17261, 17262, 17263, 17265, 17268, 17270, 17271, 17272,
  ],
  "Adams County, Pennsylvania": [
    17301, 17303, 17304, 17306, 17307, 17310, 17316, 17320, 17325, 17326, 17337,
    17340, 17343, 17344, 17350, 17353, 17372, 17375,
  ],
  "Lycoming County, Pennsylvania": [
    17701, 17702, 17703, 17705, 17720, 17723, 17727, 17728, 17737, 17739, 17740,
    17742, 17744, 17752, 17754, 17756, 17762, 17763, 17769, 17771, 17774, 17776,
  ],
  "Sullivan County, Pennsylvania": [
    17731, 17758, 17768, 18614, 18616, 18619, 18626, 18628, 18632,
  ],
  "Union County, Pennsylvania": [
    17810, 17829, 17835, 17837, 17844, 17845, 17855, 17856, 17880, 17883, 17885,
    17886, 17887, 17889,
  ],
  "Snyder County, Pennsylvania": [
    17812, 17813, 17827, 17831, 17833, 17842, 17843, 17853, 17861, 17862, 17864,
    17870, 17876, 17882,
  ],
  "Columbia County, Pennsylvania": [
    17814, 17815, 17820, 17839, 17846, 17858, 17859, 17878, 17888, 17920, 17945,
    18603, 18631,
  ],
  "Montour County, Pennsylvania": [17821, 17822, 17884],
  "Schuylkill County, Pennsylvania": [
    17901, 17921, 17922, 17923, 17925, 17929, 17930, 17931, 17932, 17933, 17934,
    17935, 17936, 17938, 17941, 17942, 17943, 17944, 17946, 17948, 17949, 17951,
    17952, 17953, 17954, 17957, 17959, 17960, 17961, 17963, 17964, 17965, 17966,
    17967, 17968, 17970, 17972, 17974, 17976, 17979, 17980, 17981, 17982, 17983,
    17985, 18211, 18214, 18218, 18220, 18231, 18237, 18242, 18245, 18248, 18252,
    19549,
  ],
  "Lehigh County, Pennsylvania": [
    18001, 18002, 18003, 18011, 18016, 18018, 18031, 18032, 18034, 18036, 18037,
    18046, 18049, 18051, 18052, 18053, 18059, 18060, 18062, 18065, 18066, 18068,
    18069, 18078, 18079, 18080, 18087, 18092, 18098, 18099, 18101, 18102, 18103,
    18104, 18105, 18106, 18109, 18175, 18195,
  ],
  "Northampton County, Pennsylvania": [
    18010, 18013, 18014, 18015, 18017, 18020, 18025, 18035, 18038, 18040, 18042,
    18043, 18044, 18045, 18050, 18055, 18063, 18064, 18067, 18072, 18083, 18085,
    18086, 18088, 18091, 18343, 18351,
  ],
  "Carbon County, Pennsylvania": [
    18012, 18030, 18071, 18210, 18212, 18216, 18229, 18230, 18232, 18235, 18240,
    18244, 18250, 18254, 18255, 18624,
  ],
  "Bucks County, Pennsylvania": [
    18039, 18077, 18081, 18901, 18902, 18910, 18911, 18912, 18913, 18914, 18916,
    18917, 18920, 18921, 18922, 18923, 18925, 18926, 18927, 18928, 18929, 18930,
    18931, 18932, 18933, 18934, 18935, 18938, 18940, 18942, 18943, 18944, 18946,
    18947, 18949, 18950, 18951, 18953, 18954, 18955, 18956, 18960, 18962, 18963,
    18966, 18968, 18970, 18972, 18974, 18976, 18977, 18980, 18981, 18991, 19007,
    19020, 19021, 19030, 19047, 19053, 19054, 19055, 19056, 19057, 19058, 19067,
  ],
  "Montgomery County, Pennsylvania": [
    18041, 18054, 18070, 18073, 18074, 18076, 18084, 18915, 18918, 18924, 18936,
    18957, 18958, 18964, 18969, 18971, 18979, 19001, 19002, 19003, 19004, 19006,
    19009, 19012, 19025, 19027, 19031, 19034, 19035, 19038, 19040, 19044, 19046,
    19048, 19049, 19066, 19072, 19075, 19090, 19095, 19096, 19401, 19403, 19404,
    19405, 19406, 19407, 19408, 19409, 19415, 19420, 19422, 19423, 19424, 19426,
    19428, 19429, 19430, 19435, 19436, 19437, 19438, 19440, 19441, 19443, 19444,
    19446, 19450, 19451, 19453, 19454, 19455, 19456, 19462, 19464, 19468, 19472,
    19473, 19474, 19477, 19478, 19481, 19482, 19483, 19484, 19485, 19486, 19487,
    19488, 19489, 19490, 19492, 19493, 19494, 19495, 19496, 19525,
  ],
  "Berks County, Pennsylvania": [
    18056, 19503, 19504, 19505, 19506, 19507, 19508, 19510, 19511, 19512, 19516,
    19518, 19519, 19522, 19523, 19526, 19529, 19530, 19533, 19534, 19535, 19536,
    19538, 19539, 19540, 19541, 19542, 19543, 19544, 19545, 19547, 19548, 19550,
    19551, 19554, 19555, 19559, 19560, 19562, 19564, 19565, 19567, 19601, 19602,
    19603, 19604, 19605, 19606, 19607, 19608, 19609, 19610, 19611, 19612, 19640,
  ],
  "Monroe County, Pennsylvania": [
    18058, 18301, 18302, 18320, 18321, 18322, 18323, 18325, 18326, 18327, 18330,
    18331, 18332, 18333, 18334, 18335, 18341, 18342, 18344, 18346, 18347, 18348,
    18349, 18350, 18352, 18353, 18354, 18355, 18356, 18357, 18360, 18370, 18372,
    18466, 18610,
  ],
  "Luzerne County, Pennsylvania": [
    18201, 18202, 18219, 18221, 18222, 18223, 18224, 18225, 18234, 18239, 18241,
    18246, 18247, 18249, 18251, 18256, 18601, 18602, 18611, 18612, 18617, 18618,
    18621, 18622, 18627, 18634, 18635, 18640, 18641, 18642, 18643, 18644, 18651,
    18654, 18655, 18656, 18660, 18661, 18690, 18701, 18702, 18703, 18704, 18705,
    18706, 18707, 18708, 18709, 18710, 18711, 18762, 18764, 18765, 18766, 18767,
    18769, 18773,
  ],
  "Pike County, Pennsylvania": [
    18324, 18328, 18336, 18337, 18340, 18371, 18373, 18425, 18426, 18428, 18435,
    18451, 18457, 18458, 18464,
  ],
  "Lackawanna County, Pennsylvania": [
    18403, 18407, 18410, 18411, 18414, 18416, 18420, 18424, 18433, 18434, 18440,
    18444, 18447, 18448, 18452, 18471, 18501, 18502, 18503, 18504, 18505, 18507,
    18508, 18509, 18510, 18512, 18514, 18515, 18517, 18518, 18519, 18522, 18540,
    18577, 18653,
  ],
  "Wayne County, Pennsylvania": [
    18405, 18415, 18417, 18427, 18431, 18436, 18437, 18438, 18439, 18443, 18445,
    18449, 18453, 18454, 18455, 18456, 18459, 18460, 18461, 18462, 18463, 18469,
    18472, 18473,
  ],
  "Susquehanna County, Pennsylvania": [
    18413, 18421, 18430, 18441, 18465, 18470, 18801, 18812, 18813, 18816, 18818,
    18820, 18821, 18822, 18823, 18824, 18825, 18826, 18827, 18828, 18830, 18834,
    18842, 18843, 18844, 18847,
  ],
  "Wyoming County, Pennsylvania": [
    18419, 18446, 18615, 18623, 18625, 18629, 18630, 18636, 18657,
  ],
  "Delaware County, Pennsylvania": [
    19008, 19010, 19013, 19014, 19015, 19016, 19017, 19018, 19022, 19023, 19026,
    19028, 19029, 19032, 19033, 19036, 19037, 19039, 19041, 19043, 19050, 19052,
    19060, 19061, 19063, 19064, 19065, 19070, 19073, 19074, 19076, 19078, 19079,
    19080, 19081, 19082, 19083, 19085, 19086, 19088, 19089, 19091, 19094, 19098,
    19113, 19317, 19319, 19331, 19339, 19340, 19342, 19373,
  ],
  "Philadelphia County, Pennsylvania": [
    19019, 19092, 19093, 19099, 19101, 19102, 19103, 19104, 19105, 19106, 19107,
    19108, 19109, 19110, 19111, 19112, 19114, 19115, 19116, 19118, 19119, 19120,
    19121, 19122, 19123, 19124, 19125, 19126, 19127, 19128, 19129, 19130, 19131,
    19132, 19133, 19134, 19135, 19136, 19137, 19138, 19139, 19140, 19141, 19142,
    19143, 19144, 19145, 19146, 19147, 19148, 19149, 19150, 19151, 19152, 19153,
    19154, 19155, 19160, 19161, 19162, 19170, 19171, 19172, 19173, 19175, 19176,
    19177, 19178, 19179, 19181, 19182, 19183, 19184, 19185, 19187, 19188, 19190,
    19191, 19192, 19193, 19194, 19195, 19196, 19197, 19244, 19255,
  ],
  "Chester County, Pennsylvania": [
    19087, 19301, 19310, 19311, 19312, 19316, 19318, 19320, 19330, 19333, 19335,
    19341, 19343, 19344, 19345, 19346, 19347, 19348, 19350, 19351, 19352, 19353,
    19354, 19355, 19357, 19358, 19360, 19362, 19363, 19365, 19366, 19367, 19369,
    19371, 19372, 19374, 19375, 19376, 19380, 19381, 19382, 19383, 19388, 19390,
    19395, 19397, 19398, 19399, 19421, 19425, 19432, 19442, 19457, 19460, 19465,
    19470, 19475, 19480, 19520,
  ],
  "New Castle County, Delaware": [
    19701, 19702, 19703, 19706, 19707, 19708, 19709, 19710, 19711, 19712, 19713,
    19714, 19715, 19716, 19717, 19718, 19720, 19721, 19725, 19726, 19730, 19731,
    19732, 19733, 19734, 19735, 19736, 19801, 19802, 19803, 19804, 19805, 19806,
    19807, 19808, 19809, 19810, 19850, 19880, 19884, 19885, 19886, 19887, 19889,
    19890, 19891, 19892, 19893, 19894, 19895, 19896, 19897, 19898, 19899,
  ],
  "Kent County, Delaware": [
    19901, 19902, 19903, 19904, 19905, 19906, 19934, 19936, 19938, 19943, 19946,
    19952, 19953, 19954, 19955, 19961, 19962, 19964, 19977, 19979, 19980,
  ],
  "Sussex County, Delaware": [
    19930, 19931, 19933, 19939, 19940, 19941, 19944, 19945, 19947, 19950, 19951,
    19956, 19958, 19960, 19963, 19966, 19967, 19968, 19969, 19970, 19971, 19973,
    19975,
  ],
  "District of Columbia, District of Columbia": [
    20001, 20002, 20003, 20004, 20005, 20006, 20007, 20008, 20009, 20010, 20011,
    20012, 20013, 20015, 20016, 20017, 20018, 20019, 20020, 20022, 20023, 20024,
    20026, 20027, 20029, 20030, 20032, 20033, 20035, 20036, 20037, 20038, 20039,
    20040, 20041, 20042, 20043, 20044, 20045, 20046, 20047, 20049, 20050, 20051,
    20052, 20053, 20055, 20056, 20057, 20058, 20059, 20060, 20061, 20062, 20063,
    20064, 20065, 20066, 20067, 20068, 20069, 20070, 20071, 20073, 20074, 20075,
    20076, 20077, 20078, 20080, 20081, 20082, 20088, 20090, 20091, 20097, 20098,
    20201, 20202, 20203, 20204, 20206, 20207, 20208, 20210, 20211, 20212, 20213,
    20214, 20215, 20216, 20217, 20218, 20219, 20220, 20221, 20222, 20223, 20224,
    20226, 20227, 20228, 20229, 20230, 20232, 20233, 20235, 20237, 20238, 20239,
    20240, 20241, 20242, 20244, 20245, 20250, 20251, 20254, 20260, 20261, 20262,
    20265, 20266, 20268, 20270, 20277, 20289, 20299, 20301, 20303, 20306, 20307,
    20310, 20314, 20317, 20318, 20319, 20330, 20340, 20350, 20355, 20370, 20372,
    20373, 20374, 20375, 20376, 20380, 20388, 20389, 20390, 20391, 20392, 20393,
    20394, 20395, 20398, 20401, 20402, 20403, 20404, 20405, 20406, 20407, 20408,
    20409, 20410, 20411, 20412, 20413, 20414, 20415, 20416, 20418, 20419, 20420,
    20421, 20422, 20423, 20424, 20425, 20426, 20427, 20428, 20429, 20431, 20433,
    20434, 20435, 20436, 20437, 20439, 20440, 20441, 20442, 20444, 20447, 20451,
    20453, 20456, 20460, 20463, 20468, 20469, 20470, 20472, 20500, 20501, 20502,
    20503, 20504, 20505, 20506, 20507, 20508, 20509, 20510, 20511, 20515, 20520,
    20521, 20522, 20523, 20524, 20525, 20526, 20527, 20528, 20529, 20530, 20531,
    20532, 20533, 20534, 20535, 20536, 20537, 20538, 20539, 20540, 20541, 20542,
    20543, 20544, 20546, 20547, 20548, 20549, 20551, 20552, 20553, 20554, 20555,
    20557, 20558, 20559, 20560, 20565, 20566, 20570, 20571, 20572, 20573, 20575,
    20576, 20577, 20578, 20579, 20580, 20581, 20585, 20586, 20590, 20591, 20593,
    20594, 20597, 20599, 56901, 56902, 56904, 56915, 56920, 56933, 56944, 56945,
    56950, 56965, 56972,
  ],
  "Loudoun County, Virginia": [
    20101, 20102, 20103, 20104, 20105, 20107, 20117, 20118, 20129, 20131, 20132,
    20134, 20141, 20142, 20146, 20147, 20148, 20149, 20152, 20158, 20159, 20160,
    20163, 20164, 20165, 20166, 20167, 20175, 20176, 20177, 20178, 20180, 20189,
    20197, 20199, 22093,
  ],
  "Culpeper County, Virginia": [
    20106, 22701, 22713, 22714, 22718, 22724, 22726, 22729, 22733, 22735, 22736,
    22737, 22741, 22746,
  ],
  "Manassas City, Virginia": [20108, 20113],
  "Prince William County, Virginia": [
    20109, 20111, 20112, 20136, 20143, 20155, 20156, 20168, 20169, 20181, 20182,
    22025, 22026, 22125, 22134, 22135, 22172, 22191, 22192, 22193, 22194, 22195,
  ],
  "Manassas city, Virginia": [20110],
  "Fauquier County, Virginia": [
    20115, 20116, 20119, 20128, 20137, 20138, 20139, 20140, 20144, 20184, 20185,
    20186, 20187, 20188, 20198, 22639, 22643, 22712, 22720, 22728, 22734, 22739,
    22742,
  ],
  "Fairfax County, Virginia": [
    20120, 20121, 20122, 20124, 20151, 20153, 20170, 20171, 20172, 20190, 20191,
    20192, 20193, 20194, 20195, 20196, 20598, 22003, 22009, 22015, 22027, 22030,
    22031, 22032, 22033, 22035, 22039, 22041, 22042, 22043, 22044, 22060, 22066,
    22067, 22079, 22081, 22082, 22092, 22095, 22096, 22101, 22102, 22103, 22106,
    22107, 22108, 22109, 22116, 22118, 22119, 22120, 22121, 22122, 22124, 22150,
    22151, 22152, 22153, 22156, 22158, 22159, 22160, 22161, 22180, 22181, 22182,
    22183, 22184, 22185, 22199, 22303, 22306, 22307, 22308, 22309, 22310, 22312,
    22315,
  ],
  "Clarke County, Virginia": [20130, 20135, 22611, 22620, 22646],
  "undefined, District of Columbia": [
    20252, 20417, 56908, 56935, 56964, 56966, 56967, 56968, 56969, 56970, 56971,
    56973, 56980, 56981, 56982, 56983, 56984, 56985, 56998, 56999, 88888,
  ],
  "Howard County, Maryland": [
    20588, 20723, 20759, 20763, 20777, 20794, 21029, 21036, 21041, 21042, 21043,
    21044, 21045, 21046, 21075, 21104, 21150, 21163, 21723, 21737, 21738, 21765,
    21794, 21797,
  ],
  "Charles County, Maryland": [
    20601, 20602, 20603, 20604, 20611, 20612, 20616, 20617, 20622, 20625, 20632,
    20637, 20640, 20643, 20645, 20646, 20658, 20661, 20662, 20664, 20675, 20677,
    20682, 20693, 20695,
  ],
  "St. Mary's County, Maryland": [
    20606, 20609, 20618, 20619, 20620, 20621, 20624, 20626, 20627, 20628, 20630,
    20634, 20636, 20650, 20653, 20656, 20659, 20660, 20667, 20670, 20674, 20680,
    20684, 20686, 20687, 20690, 20692,
  ],
  "Prince George's County, Maryland": [
    20607, 20608, 20613, 20623, 20697, 20703, 20704, 20705, 20706, 20707, 20708,
    20709, 20710, 20712, 20715, 20716, 20717, 20718, 20719, 20720, 20721, 20722,
    20725, 20726, 20731, 20735, 20737, 20738, 20740, 20741, 20742, 20743, 20744,
    20745, 20746, 20747, 20748, 20749, 20750, 20752, 20753, 20757, 20762, 20768,
    20769, 20770, 20771, 20772, 20773, 20774, 20775, 20781, 20782, 20783, 20784,
    20785, 20787, 20788, 20790, 20791, 20792, 20797, 20799,
  ],
  "Calvert County, Maryland": [
    20610, 20615, 20629, 20639, 20657, 20676, 20678, 20685, 20688, 20689, 20714,
    20732, 20736, 20754,
  ],
  "St Mary's County, Maryland": [20635],
  "Anne Arundel County, Maryland": [
    20701, 20711, 20724, 20733, 20751, 20755, 20758, 20764, 20765, 20776, 20778,
    20779, 21012, 21032, 21035, 21037, 21054, 21056, 21060, 21061, 21062, 21076,
    21077, 21090, 21098, 21106, 21108, 21113, 21114, 21122, 21123, 21140, 21144,
    21146, 21226, 21240, 21401, 21402, 21403, 21404, 21405, 21409, 21411, 21412,
  ],
  "Montgomery County, Maryland": [
    20810, 20811, 20812, 20813, 20814, 20815, 20816, 20817, 20818, 20824, 20825,
    20827, 20830, 20832, 20833, 20837, 20838, 20839, 20841, 20842, 20847, 20848,
    20849, 20850, 20851, 20852, 20853, 20854, 20855, 20857, 20859, 20860, 20861,
    20862, 20866, 20868, 20871, 20872, 20874, 20875, 20876, 20877, 20878, 20879,
    20880, 20882, 20883, 20884, 20885, 20886, 20889, 20891, 20892, 20894, 20895,
    20896, 20897, 20898, 20899, 20901, 20902, 20903, 20904, 20905, 20906, 20907,
    20908, 20910, 20911, 20912, 20913, 20914, 20915, 20916, 20918, 20993, 20997,
  ],
  "Harford County, Maryland": [
    21001, 21005, 21009, 21010, 21014, 21015, 21017, 21018, 21028, 21034, 21040,
    21047, 21050, 21078, 21084, 21085, 21130, 21132, 21154, 21160, 21161,
  ],
  "Baltimore County, Maryland": [
    21013, 21020, 21022, 21023, 21027, 21030, 21031, 21051, 21052, 21053, 21057,
    21065, 21071, 21082, 21087, 21092, 21093, 21094, 21105, 21111, 21117, 21120,
    21128, 21131, 21133, 21136, 21139, 21152, 21153, 21155, 21156, 21162, 21204,
    21207, 21208, 21209, 21219, 21220, 21221, 21222, 21227, 21228, 21234, 21236,
    21237, 21244, 21250, 21252, 21285, 21286,
  ],
  "Carroll County, Maryland": [
    21048, 21074, 21088, 21102, 21157, 21158, 21776, 21784, 21787, 21791,
  ],
  "Baltimore city, Maryland": [
    21201, 21202, 21205, 21206, 21210, 21211, 21212, 21213, 21214, 21215, 21216,
    21217, 21218, 21223, 21224, 21225, 21229, 21230, 21231, 21239, 21251,
  ],
  "Baltimore City, Maryland": [
    21203, 21233, 21235, 21241, 21260, 21261, 21263, 21264, 21265, 21268, 21270,
    21273, 21274, 21275, 21278, 21279, 21280, 21281, 21282, 21283, 21284, 21287,
    21288, 21289, 21290, 21297, 21298,
  ],
  "Allegany County, Maryland": [
    21501, 21502, 21503, 21504, 21505, 21521, 21524, 21528, 21529, 21530, 21532,
    21539, 21540, 21542, 21543, 21545, 21555, 21556, 21557, 21560, 21562, 21766,
  ],
  "Garrett County, Maryland": [
    21520, 21522, 21523, 21531, 21536, 21538, 21541, 21550, 21561,
  ],
  "Talbot County, Maryland": [
    21601, 21606, 21612, 21624, 21625, 21647, 21652, 21653, 21654, 21662, 21663,
    21665, 21671, 21673, 21676, 21679,
  ],
  "Queen Anne's County, Maryland": [
    21607, 21617, 21619, 21623, 21628, 21638, 21644, 21656, 21657, 21658, 21666,
    21668,
  ],
  "Caroline County, Maryland": [
    21609, 21629, 21632, 21636, 21639, 21640, 21641, 21649, 21655, 21660, 21670,
    21681, 21682, 21683, 21684, 21685, 21686, 21687, 21688,
  ],
  "Kent County, Maryland": [
    21610, 21620, 21635, 21645, 21650, 21651, 21661, 21667, 21678, 21690,
  ],
  "Dorchester County, Maryland": [
    21613, 21622, 21626, 21627, 21631, 21634, 21643, 21648, 21659, 21664, 21669,
    21672, 21675, 21677, 21835, 21869,
  ],
  "Frederick County, Maryland": [
    21701, 21702, 21703, 21704, 21705, 21709, 21710, 21714, 21716, 21717, 21718,
    21727, 21754, 21755, 21757, 21758, 21759, 21762, 21769, 21770, 21771, 21773,
    21774, 21775, 21777, 21778, 21780, 21788, 21790, 21792, 21793, 21798,
  ],
  "Washington County, Maryland": [
    21711, 21713, 21715, 21719, 21720, 21721, 21722, 21733, 21734, 21740, 21741,
    21742, 21746, 21747, 21748, 21749, 21750, 21756, 21767, 21779, 21781, 21782,
    21783, 21795,
  ],
  "Wicomico County, Maryland": [
    21801, 21802, 21803, 21804, 21810, 21814, 21822, 21826, 21830, 21837, 21840,
    21849, 21850, 21852, 21856, 21861, 21865, 21874, 21875,
  ],
  "Worcester County, Maryland": [
    21811, 21813, 21829, 21841, 21842, 21843, 21851, 21862, 21863, 21864, 21872,
  ],
  "Somerset County, Maryland": [
    21817, 21821, 21824, 21836, 21838, 21853, 21857, 21866, 21867, 21871, 21890,
  ],
  "Cecil County, Maryland": [
    21901, 21902, 21903, 21904, 21911, 21912, 21913, 21914, 21915, 21916, 21917,
    21918, 21919, 21920, 21921, 21922, 21930,
  ],
  "Fairfax City, Virginia": [22034, 22036, 22037, 22038],
  "Falls Church City, Virginia": [22040, 22047],
  "Falls Church city, Virginia": [22046],
  "Arlington County, Virginia": [
    22201, 22202, 22203, 22204, 22205, 22206, 22207, 22209, 22210, 22211, 22212,
    22213, 22214, 22215, 22216, 22217, 22218, 22219, 22222, 22223, 22225, 22226,
    22227, 22229, 22230, 22234, 22240, 22241, 22242, 22243, 22244, 22245, 22246,
  ],
  "Alexandria city, Virginia": [22301, 22302, 22304, 22305, 22311, 22314],
  "Alexandria City, Virginia": [
    22313, 22320, 22321, 22331, 22332, 22333, 22334, 22336,
  ],
  "undefined, Virginia": [22350],
  "Fredericksburg city, Virginia": [22401],
  "Fredericksburg City, Virginia": [22402, 22403, 22404, 22412],
  "Stafford County, Virginia": [
    22405, 22406, 22430, 22463, 22471, 22545, 22554, 22555, 22556,
  ],
  "Spotsylvania County, Virginia": [22407, 22408, 22534, 22551, 22553, 22565],
  "Caroline County, Virginia": [
    22427, 22428, 22446, 22501, 22514, 22535, 22538, 22546, 22552, 22580,
  ],
  "Northumberland County, Virginia": [
    22432, 22435, 22456, 22473, 22511, 22530, 22539, 22579,
  ],
  "Orange County, Virginia": [22433, 22508, 22542, 22567, 22957, 22960, 22972],
  "Essex County, Virginia": [
    22436, 22437, 22438, 22454, 22476, 22504, 22509, 22560, 23115,
  ],
  "Westmoreland County, Virginia": [
    22442, 22443, 22469, 22488, 22520, 22524, 22529, 22558, 22577, 22581,
  ],
  "King George County, Virginia": [
    22448, 22451, 22481, 22485, 22526, 22544, 22547,
  ],
  "Richmond County, Virginia": [22460, 22472, 22548, 22570, 22572],
  "Lancaster County, Virginia": [
    22480, 22482, 22503, 22507, 22513, 22517, 22523, 22528, 22576, 22578,
  ],
  "Winchester city, Virginia": [22601],
  "Frederick County, Virginia": [
    22602, 22603, 22622, 22624, 22625, 22637, 22645, 22649, 22654, 22655, 22656,
    22663,
  ],
  "Winchester City, Virginia": [22604],
  "Warren County, Virginia": [22610, 22630, 22642],
  "Rappahannock County, Virginia": [
    22623, 22627, 22640, 22716, 22740, 22747, 22749,
  ],
  "Shenandoah County, Virginia": [
    22626, 22641, 22644, 22652, 22657, 22660, 22664, 22810, 22824, 22842, 22844,
    22845, 22847,
  ],
  "Page County, Virginia": [22650, 22835, 22849, 22851],
  "Madison County, Virginia": [
    22709, 22711, 22715, 22719, 22721, 22722, 22723, 22725, 22727, 22730, 22731,
    22732, 22738, 22743, 22748, 22948, 22989,
  ],
  "Harrisonburg city, Virginia": [22801, 22802, 22807],
  "Harrisonburg City, Virginia": [22803],
  "Rockingham County, Virginia": [
    22811, 22812, 22815, 22820, 22821, 22827, 22830, 22831, 22832, 22833, 22834,
    22840, 22841, 22846, 22848, 22850, 22853, 24441, 24471,
  ],
  "Augusta County, Virginia": [
    22843, 22939, 22952, 24411, 24421, 24430, 24431, 24432, 24437, 24440, 24459,
    24463, 24467, 24469, 24476, 24477, 24479, 24482, 24485, 24486,
  ],
  "Albemarle County, Virginia": [
    22901, 22904, 22911, 22924, 22931, 22932, 22936, 22937, 22940, 22943, 22945,
    22946, 22947, 22959, 22969, 22987,
  ],
  "Charlottesville city, Virginia": [22902, 22903],
  "Charlottesville City, Virginia": [22905, 22906, 22907, 22908, 22909, 22910],
  "Nelson County, Virginia": [
    22920, 22922, 22938, 22949, 22958, 22964, 22967, 22971, 22976, 24464, 24581,
  ],
  "Greene County, Virginia": [22923, 22935, 22965, 22968, 22973],
  "Louisa County, Virginia": [22942, 23024, 23093, 23117, 23170],
  "Fluvanna County, Virginia": [22963, 22974, 23022, 23055, 23084, 24590],
  "Waynesboro city, Virginia": [22980],
  "Gloucester County, Virginia": [
    23001, 23003, 23018, 23061, 23062, 23072, 23107, 23131, 23154, 23155, 23178,
    23183, 23184, 23190,
  ],
  "Amelia County, Virginia": [23002, 23083, 23105, 23822],
  "Buckingham County, Virginia": [23004, 23123, 23921, 23936, 24562, 24599],
  "Hanover County, Virginia": [
    23005, 23015, 23047, 23069, 23111, 23116, 23146, 23162, 23192,
  ],
  "King William County, Virginia": [23009, 23086, 23106, 23181],
  "New Kent County, Virginia": [23011, 23089, 23124, 23140, 23141],
  "Powhatan County, Virginia": [23014, 23101, 23139, 23160],
  "Mathews County, Virginia": [
    23021, 23025, 23035, 23045, 23050, 23056, 23064, 23066, 23068, 23076, 23109,
    23119, 23125, 23128, 23130, 23138, 23163,
  ],
  "King and Queen County, Virginia": [
    23023, 23085, 23091, 23108, 23110, 23126, 23148, 23156, 23161, 23177,
  ],
  "Cumberland County, Virginia": [23027, 23040],
  "Charles City County, Virginia": [23030, 23147],
  "Middlesex County, Virginia": [
    23031, 23032, 23043, 23070, 23071, 23079, 23092, 23149, 23169, 23175, 23176,
    23180,
  ],
  "Goochland County, Virginia": [
    23038, 23039, 23063, 23065, 23067, 23102, 23103, 23129, 23153,
  ],
  "Henrico County, Virginia": [
    23058, 23059, 23060, 23075, 23150, 23223, 23227, 23228, 23229, 23230, 23231,
    23233, 23238, 23242, 23250, 23255, 23273, 23288, 23294,
  ],
  "James City County, Virginia": [23081, 23127, 23168, 23185, 23188],
  "James City, Virginia": [23090],
  "Chesterfield County, Virginia": [
    23112, 23113, 23114, 23120, 23234, 23235, 23236, 23237, 23806, 23831, 23832,
    23836, 23838,
  ],
  "Richmond city, Virginia": [
    23173, 23219, 23220, 23221, 23222, 23224, 23225, 23226,
  ],
  "Williamsburg City, Virginia": [23186],
  "Williamsburg city, Virginia": [23187],
  "Richmond City, Virginia": [
    23218, 23232, 23240, 23241, 23249, 23260, 23261, 23269, 23274, 23276, 23278,
    23279, 23282, 23284, 23285, 23286, 23289, 23290, 23291, 23292, 23293, 23295,
    23297, 23298,
  ],
  "Accomack County, Virginia": [
    23301, 23302, 23303, 23306, 23308, 23336, 23337, 23341, 23345, 23356, 23357,
    23358, 23359, 23389, 23395, 23396, 23399, 23401, 23404, 23407, 23409, 23410,
    23412, 23414, 23415, 23416, 23417, 23418, 23420, 23421, 23422, 23423, 23426,
    23427, 23440, 23441, 23442, 23480, 23483, 23488,
  ],
  "Isle of Wight County, Virginia": [
    23304, 23314, 23315, 23397, 23424, 23430, 23431, 23487, 23898,
  ],
  "Northampton County, Virginia": [
    23307, 23310, 23313, 23316, 23347, 23350, 23354, 23398, 23405, 23408, 23413,
    23419, 23429, 23443, 23482, 23486,
  ],
  "Chesapeake city, Virginia": [23320, 23321, 23322, 23323, 23324, 23325],
  "Chesapeake City, Virginia": [23326, 23327, 23328],
  "Suffolk city, Virginia": [23432, 23433, 23434, 23435, 23436, 23437, 23438],
  "Suffolk City, Virginia": [23439],
  "Virginia Beach City, Virginia": [
    23450, 23458, 23463, 23465, 23466, 23467, 23471, 23479, 23521,
  ],
  "Virginia Beach city, Virginia": [
    23451, 23452, 23453, 23454, 23455, 23456, 23457, 23459, 23460, 23461, 23462,
    23464,
  ],
  "Norfolk City, Virginia": [
    23501, 23506, 23512, 23514, 23515, 23519, 23520, 23529, 23541,
  ],
  "Norfolk city, Virginia": [
    23502, 23503, 23504, 23505, 23507, 23508, 23509, 23510, 23511, 23513, 23517,
    23518, 23523, 23551,
  ],
  "Newport News city, Virginia": [
    23601, 23602, 23603, 23604, 23605, 23606, 23607, 23608,
  ],
  "Newport News City, Virginia": [23609, 23612, 23628],
  "Hampton City, Virginia": [23630, 23667, 23668, 23670, 23681],
  "Hampton city, Virginia": [23651, 23661, 23663, 23664, 23666, 23669],
  "Poquoson city, Virginia": [23662],
  "York County, Virginia": [23665, 23690, 23691, 23692, 23693, 23694, 23696],
  "Portsmouth city, Virginia": [
    23701, 23702, 23703, 23704, 23707, 23708, 23709,
  ],
  "Portsmouth City, Virginia": [23705],
  "Prince George County, Virginia": [23801, 23830, 23842, 23875],
  "Petersburg city, Virginia": [23803, 23805],
  "Petersburg City, Virginia": [23804],
  "Brunswick County, Virginia": [
    23821, 23843, 23845, 23856, 23857, 23868, 23873, 23876, 23887, 23889, 23893,
    23920, 23938,
  ],
  "Nottoway County, Virginia": [23824, 23825, 23922, 23930, 23955],
  "Southampton County, Virginia": [
    23827, 23828, 23829, 23837, 23844, 23866, 23874, 23878,
  ],
  "Dinwiddie County, Virginia": [
    23833, 23840, 23841, 23850, 23872, 23885, 23894,
  ],
  "Colonial Heights city, Virginia": [23834],
  "Surry County, Virginia": [23839, 23846, 23881, 23883, 23899],
  "Greensville County, Virginia": [23847, 23867, 23870, 23879],
  "Franklin city, Virginia": [23851],
  "Hopewell city, Virginia": [23860],
  "Sussex County, Virginia": [23882, 23884, 23888, 23890, 23891, 23897],
  "Prince Edward County, Virginia": [
    23901, 23909, 23942, 23943, 23954, 23960, 23966,
  ],
  "Mecklenburg County, Virginia": [
    23915, 23917, 23919, 23924, 23927, 23950, 23968, 23970, 24529, 24580,
  ],
  "Charlotte County, Virginia": [
    23923, 23934, 23937, 23947, 23959, 23962, 23963, 23964, 23967, 23976,
  ],
  "Appomattox County, Virginia": [23939, 23958, 24522, 24553, 24593],
  "Lunenburg County, Virginia": [23941, 23944, 23952, 23974],
  "Roanoke City, Virginia": [
    24001, 24002, 24003, 24004, 24005, 24006, 24007, 24008, 24009, 24010, 24022,
    24023, 24024, 24025, 24026, 24027, 24028, 24029, 24030, 24031, 24032, 24033,
    24034, 24035, 24036, 24037, 24038, 24040, 24042, 24043, 24044, 24045, 24048,
    24050, 24155, 24157,
  ],
  "Roanoke city, Virginia": [24011, 24012, 24013, 24014, 24015, 24016, 24017],
  "Roanoke County, Virginia": [24018, 24019, 24020, 24059, 24070, 24179],
  "Patrick County, Virginia": [
    24053, 24076, 24082, 24120, 24133, 24171, 24177, 24185,
  ],
  "Henry County, Virginia": [
    24054, 24055, 24078, 24089, 24112, 24148, 24165, 24168,
  ],
  "Pulaski County, Virginia": [
    24058, 24084, 24111, 24126, 24129, 24132, 24301, 24324, 24347,
  ],
  "Montgomery County, Virginia": [
    24060, 24061, 24062, 24063, 24068, 24073, 24087, 24138, 24149, 24162,
  ],
  "Botetourt County, Virginia": [
    24064, 24066, 24077, 24083, 24085, 24090, 24130, 24175, 24438,
  ],
  "Franklin County, Virginia": [
    24065, 24067, 24088, 24092, 24101, 24102, 24137, 24146, 24151, 24176, 24184,
  ],
  "Pittsylvania County, Virginia": [
    24069, 24139, 24161, 24527, 24530, 24531, 24549, 24557, 24563, 24565, 24566,
    24569, 24586, 24594,
  ],
  "Floyd County, Virginia": [24072, 24079, 24091, 24105, 24380],
  "Giles County, Virginia": [
    24086, 24093, 24124, 24128, 24134, 24136, 24147, 24150, 24167,
  ],
  "Bedford County, Virginia": [
    24095, 24104, 24121, 24122, 24174, 24178, 24523, 24526, 24536, 24551, 24556,
    24570,
  ],
  "Martinsville City, Virginia": [24113, 24114, 24115],
  "Craig County, Virginia": [24127, 24131],
  "Radford city, Virginia": [24141, 24142],
  "Radford City, Virginia": [24143],
  "Salem city, Virginia": [24153],
  "Bristol city, Virginia": [24201],
  "Washington County, Virginia": [
    24202, 24210, 24211, 24212, 24236, 24270, 24327, 24340, 24361,
  ],
  "Bristol City, Virginia": [24203, 24205, 24209],
  "Wise County, Virginia": [
    24215, 24216, 24219, 24230, 24246, 24279, 24283, 24293,
  ],
  "Dickenson County, Virginia": [
    24217, 24220, 24226, 24228, 24237, 24256, 24269, 24272,
  ],
  "Lee County, Virginia": [
    24218, 24221, 24243, 24248, 24263, 24265, 24277, 24281, 24282,
  ],
  "Russell County, Virginia": [24224, 24225, 24260, 24266, 24280, 24649],
  "Buchanan County, Virginia": [
    24239, 24603, 24607, 24614, 24620, 24622, 24624, 24627, 24628, 24631, 24634,
    24646, 24647, 24656, 24657, 24658,
  ],
  "Scott County, Virginia": [24244, 24245, 24250, 24251, 24258, 24271, 24290],
  "Norton city, Virginia": [24273],
  "Grayson County, Virginia": [24292, 24326, 24330, 24348, 24363, 24378],
  "Smyth County, Virginia": [24311, 24319, 24354, 24370, 24375],
  "Carroll County, Virginia": [
    24312, 24317, 24325, 24328, 24343, 24351, 24352, 24381,
  ],
  "Wythe County, Virginia": [
    24313, 24322, 24323, 24350, 24360, 24368, 24374, 24382,
  ],
  "Bland County, Virginia": [24314, 24315, 24318, 24366],
  "Tazewell County, Virginia": [
    24316, 24377, 24601, 24602, 24604, 24605, 24606, 24608, 24609, 24612, 24613,
    24630, 24635, 24637, 24639, 24640, 24641, 24651,
  ],
  "Galax city, Virginia": [24333],
  "Staunton city, Virginia": [24401],
  "Staunton City, Virginia": [24402],
  "Bath County, Virginia": [24412, 24445, 24460, 24484, 24487],
  "Highland County, Virginia": [24413, 24433, 24442, 24458, 24465, 24468],
  "Rockbridge County, Virginia": [
    24415, 24435, 24439, 24450, 24472, 24473, 24483, 24555, 24578, 24579,
  ],
  "Buena Vista city, Virginia": [24416],
  "Alleghany County, Virginia": [24422, 24426, 24448, 24457, 24474],
  "Lynchburg city, Virginia": [24501, 24502, 24503, 24504],
  "Lynchburg City, Virginia": [24505, 24506, 24512, 24513, 24514, 24515],
  "Campbell County, Virginia": [
    24517, 24528, 24538, 24550, 24554, 24571, 24576, 24588,
  ],
  "Halifax County, Virginia": [
    24520, 24534, 24535, 24539, 24558, 24577, 24589, 24592, 24597, 24598,
  ],
  "Amherst County, Virginia": [24521, 24533, 24572, 24574, 24595],
  "Danville city, Virginia": [24540, 24541],
  "Danville City, Virginia": [24543, 24544],
  "McDowell County, Virginia": [24619],
  "Mercer County, West Virginia": [
    24701, 24712, 24714, 24715, 24724, 24729, 24731, 24732, 24733, 24736, 24737,
    24738, 24739, 24740, 24747, 24751, 25820, 25841, 25922, 25971,
  ],
  "Wyoming County, West Virginia": [
    24716, 24719, 24726, 24818, 24822, 24823, 24827, 24834, 24839, 24845, 24847,
    24849, 24854, 24857, 24859, 24860, 24867, 24869, 24870, 24874, 24880, 24882,
    24898, 25810, 25811, 25826, 25845, 25848, 25870, 25875, 25876, 25882, 25913,
    25916, 25928, 25943,
  ],
  "McDowell County, West Virginia": [
    24801, 24808, 24811, 24813, 24815, 24816, 24817, 24826, 24828, 24829, 24830,
    24831, 24836, 24842, 24843, 24844, 24848, 24850, 24853, 24855, 24861, 24862,
    24866, 24868, 24871, 24872, 24873, 24878, 24879, 24881, 24884, 24887, 24888,
    24892, 24894, 24895,
  ],
  "Mingo County, West Virginia": [
    24846, 24851, 25608, 25621, 25650, 25651, 25661, 25665, 25666, 25667, 25670,
    25671, 25672, 25674, 25676, 25678, 25685, 25686, 25688, 25690, 25691, 25692,
    25696,
  ],
  "Greenbrier County, West Virginia": [
    24901, 24902, 24910, 24916, 24925, 24931, 24938, 24943, 24957, 24961, 24966,
    24970, 24977, 24986, 24991, 25958, 25962, 25972, 25981, 25984,
  ],
  "Pocahontas County, West Virginia": [
    24915, 24920, 24924, 24927, 24934, 24944, 24946, 24954, 26209, 26264, 26291,
  ],
  "Monroe County, West Virginia": [
    24918, 24941, 24945, 24951, 24963, 24974, 24976, 24983, 24984, 24985, 24993,
  ],
  "Summers County, West Virginia": [
    24935, 24962, 24981, 25951, 25965, 25966, 25969, 25977, 25978, 25979, 25985,
  ],
  "Fayette County, West Virginia": [
    25002, 25031, 25036, 25040, 25057, 25059, 25085, 25090, 25115, 25118, 25119,
    25136, 25139, 25152, 25161, 25173, 25185, 25186, 25812, 25831, 25833, 25837,
    25840, 25846, 25854, 25855, 25862, 25864, 25866, 25868, 25879, 25901, 25904,
    25907, 25917, 25936, 25938, 25942, 25976, 25986, 26680, 26690,
  ],
  "Lincoln County, West Virginia": [
    25003, 25501, 25506, 25521, 25523, 25524, 25540, 25544, 25557, 25564, 25565,
    25567, 25571, 25572, 25573,
  ],
  "Roane County, West Virginia": [
    25005, 25243, 25251, 25259, 25266, 25270, 25276, 25286,
  ],
  "Raleigh County, West Virginia": [
    25007, 25008, 25044, 25048, 25060, 25062, 25140, 25174, 25180, 25801, 25802,
    25813, 25817, 25818, 25823, 25825, 25827, 25832, 25836, 25839, 25843, 25844,
    25849, 25851, 25853, 25857, 25860, 25865, 25871, 25873, 25878, 25880, 25902,
    25906, 25908, 25909, 25911, 25915, 25918, 25919, 25920, 25921, 25926, 25927,
    25932, 25989,
  ],
  "Boone County, West Virginia": [
    25009, 25021, 25024, 25028, 25049, 25051, 25053, 25081, 25093, 25108, 25114,
    25130, 25142, 25148, 25149, 25154, 25165, 25169, 25181, 25193, 25203, 25204,
    25205, 25206, 25208, 25209, 25529,
  ],
  "Putnam County, West Virginia": [
    25011, 25033, 25070, 25082, 25109, 25124, 25159, 25168, 25213, 25526, 25560,
    25569,
  ],
  "Kanawha County, West Virginia": [
    25015, 25025, 25026, 25035, 25039, 25045, 25054, 25061, 25064, 25067, 25071,
    25075, 25079, 25083, 25086, 25102, 25103, 25107, 25110, 25112, 25126, 25132,
    25134, 25143, 25156, 25160, 25162, 25177, 25201, 25202, 25214, 25301, 25302,
    25303, 25304, 25305, 25306, 25309, 25311, 25312, 25313, 25314, 25315, 25317,
    25320, 25321, 25322, 25323, 25324, 25325, 25326, 25327, 25328, 25329, 25330,
    25331, 25332, 25333, 25334, 25335, 25336, 25337, 25338, 25339, 25350, 25356,
    25357, 25358, 25360, 25361, 25362, 25364, 25365, 25375, 25387, 25389, 25392,
    25396,
  ],
  "Clay County, West Virginia": [
    25019, 25030, 25043, 25088, 25111, 25113, 25125, 25133, 25141, 25164, 25211,
    25285, 26617,
  ],
  "Logan County, West Virginia": [
    25022, 25047, 25076, 25121, 25183, 25505, 25508, 25547, 25601, 25606, 25607,
    25611, 25612, 25614, 25617, 25624, 25625, 25628, 25630, 25632, 25634, 25635,
    25637, 25638, 25639, 25644, 25646, 25647, 25649, 25652, 25653, 25654,
  ],
  "Braxton County, West Virginia": [
    25063, 26335, 26376, 26601, 26615, 26619, 26621, 26623, 26624, 26627, 26629,
    26631, 26636,
  ],
  "Mason County, West Virginia": [
    25106, 25123, 25187, 25247, 25253, 25260, 25265, 25287, 25502, 25503, 25515,
    25550,
  ],
  "Jackson County, West Virginia": [
    25231, 25239, 25241, 25244, 25245, 25248, 25252, 25262, 25264, 25271, 25275,
    26164,
  ],
  "Calhoun County, West Virginia": [
    25234, 25235, 25261, 25268, 26136, 26137, 26147, 26151, 26152,
  ],
  "Gilmer County, West Virginia": [
    25267, 26342, 26351, 26384, 26430, 26443, 26611, 26638,
  ],
  "Berkeley County, West Virginia": [
    25401, 25402, 25403, 25404, 25405, 25413, 25419, 25420, 25421, 25427, 25428,
    25429, 25440,
  ],
  "Washington County, West Virginia": [25410],
  "Morgan County, West Virginia": [25411, 25422],
  "Jefferson County, West Virginia": [
    25414, 25423, 25425, 25430, 25432, 25438, 25441, 25442, 25443, 25446,
  ],
  "Hampshire County, West Virginia": [
    25431, 25434, 25437, 25444, 26704, 26711, 26714, 26722, 26757, 26761, 26763,
    26808, 26817, 26823, 26852, 26865,
  ],
  "Cabell County, West Virginia": [
    25504, 25510, 25520, 25537, 25541, 25545, 25559, 25701, 25702, 25703, 25705,
    25706, 25707, 25708, 25709, 25710, 25711, 25712, 25713, 25714, 25715, 25716,
    25717, 25718, 25719, 25720, 25721, 25722, 25723, 25724, 25725, 25726, 25727,
    25728, 25729, 25755, 25770, 25771, 25772, 25773, 25774, 25775, 25776, 25777,
    25778, 25779,
  ],
  "Wayne County, West Virginia": [
    25507, 25511, 25512, 25514, 25517, 25530, 25534, 25535, 25555, 25562, 25570,
    25669, 25699, 25704,
  ],
  "undefined, West Virginia": [25888],
  "Ohio County, West Virginia": [26003, 26059, 26060, 26074],
  "Brooke County, West Virginia": [
    26030, 26032, 26035, 26037, 26058, 26070, 26075,
  ],
  "Marshall County, West Virginia": [
    26031, 26033, 26036, 26038, 26039, 26040, 26041, 26055,
  ],
  "Hancock County, West Virginia": [26034, 26047, 26050, 26056, 26062],
  "Wood County, West Virginia": [
    26101, 26102, 26103, 26104, 26105, 26106, 26120, 26121, 26133, 26142, 26150,
    26169, 26180, 26181, 26184, 26187,
  ],
  "Pleasants County, West Virginia": [26134, 26170],
  "Wirt County, West Virginia": [26138, 26141, 26143, 26160],
  "Tyler County, West Virginia": [26146, 26149, 26175, 26320, 26434],
  "Ritchie County, West Virginia": [
    26148, 26161, 26178, 26325, 26327, 26337, 26346, 26362, 26415, 26421,
  ],
  "Wetzel County, West Virginia": [
    26155, 26159, 26162, 26167, 26348, 26377, 26419, 26437, 26561, 26562, 26575,
    26581,
  ],
  "Upshur County, West Virginia": [
    26201, 26210, 26218, 26219, 26228, 26229, 26234, 26237, 26267, 26343,
  ],
  "Nicholas County, West Virginia": [
    26202, 26205, 26261, 26610, 26651, 26656, 26660, 26662, 26667, 26671, 26675,
    26676, 26678, 26679, 26681, 26684, 26691,
  ],
  "Webster County, West Virginia": [
    26203, 26206, 26208, 26215, 26217, 26222, 26266, 26288, 26298,
  ],
  "Randolph County, West Virginia": [
    26224, 26230, 26236, 26241, 26253, 26254, 26257, 26259, 26268, 26270, 26273,
    26276, 26278, 26280, 26282, 26283, 26285, 26293, 26294, 26296,
  ],
  "Barbour County, West Virginia": [26238, 26250, 26275, 26349, 26405, 26416],
  "Tucker County, West Virginia": [
    26260, 26263, 26269, 26271, 26287, 26289, 26292,
  ],
  "Harrison County, West Virginia": [
    26301, 26302, 26306, 26323, 26330, 26361, 26366, 26369, 26385, 26386, 26404,
    26408, 26422, 26426, 26431, 26438, 26448, 26451, 26461, 26463, 26568,
  ],
  "Lewis County, West Virginia": [
    26321, 26338, 26372, 26378, 26412, 26447, 26452,
  ],
  "Doddridge County, West Virginia": [26339, 26411, 26436, 26456],
  "Taylor County, West Virginia": [26347, 26354, 26424, 26435, 26440],
  "Preston County, West Virginia": [
    26374, 26410, 26425, 26444, 26519, 26520, 26524, 26525, 26537, 26542, 26547,
    26705, 26716, 26764,
  ],
  "Monongalia County, West Virginia": [
    26501, 26502, 26504, 26505, 26506, 26507, 26508, 26521, 26527, 26531, 26534,
    26541, 26543, 26544, 26546, 26570, 26590,
  ],
  "Marion County, West Virginia": [
    26554, 26555, 26559, 26560, 26563, 26566, 26571, 26572, 26574, 26576, 26578,
    26582, 26585, 26586, 26587, 26588, 26591,
  ],
  "Grant County, West Virginia": [
    26707, 26720, 26731, 26739, 26833, 26847, 26855,
  ],
  "Mineral County, West Virginia": [
    26710, 26717, 26719, 26726, 26743, 26750, 26753, 26767,
  ],
  "Hardy County, West Virginia": [
    26755, 26801, 26810, 26812, 26818, 26836, 26838, 26845, 26851,
  ],
  "Pendleton County, West Virginia": [
    26802, 26804, 26807, 26814, 26815, 26866, 26884, 26886,
  ],
  "Davie County, North Carolina": [27006, 27014, 27028],
  "Surry County, North Carolina": [
    27007, 27017, 27024, 27030, 27031, 27041, 27047, 27049, 28621, 28676, 28683,
  ],
  "Forsyth County, North Carolina": [
    27009, 27010, 27012, 27023, 27040, 27045, 27050, 27051, 27094, 27098, 27099,
    27101, 27102, 27103, 27104, 27105, 27106, 27107, 27108, 27109, 27110, 27111,
    27113, 27114, 27115, 27116, 27117, 27120, 27127, 27130, 27150, 27151, 27152,
    27155, 27156, 27157, 27198, 27199, 27284, 27285,
  ],
  "Yadkin County, North Carolina": [27011, 27018, 27020, 27055, 28642],
  "Rowan County, North Carolina": [
    27013, 27054, 28023, 28039, 28041, 28071, 28072, 28088, 28125, 28138, 28144,
    28145, 28146, 28147, 28159,
  ],
  "Stokes County, North Carolina": [
    27016, 27019, 27021, 27022, 27042, 27043, 27046, 27052, 27053,
  ],
  "Rockingham County, North Carolina": [
    27025, 27027, 27048, 27288, 27289, 27320, 27321, 27322, 27323, 27326, 27357,
    27375,
  ],
  "Alamance County, North Carolina": [
    27201, 27202, 27215, 27216, 27217, 27220, 27244, 27253, 27258, 27302, 27340,
    27349, 27359,
  ],
  "Randolph County, North Carolina": [
    27203, 27204, 27205, 27230, 27233, 27248, 27263, 27298, 27316, 27317, 27341,
    27350, 27355, 27370,
  ],
  "Chatham County, North Carolina": [
    27207, 27208, 27213, 27228, 27252, 27256, 27312, 27344, 27559,
  ],
  "Montgomery County, North Carolina": [
    27209, 27229, 27247, 27306, 27356, 27371,
  ],
  "Caswell County, North Carolina": [
    27212, 27291, 27305, 27311, 27314, 27315, 27379,
  ],
  "Guilford County, North Carolina": [
    27214, 27235, 27249, 27260, 27261, 27262, 27264, 27265, 27268, 27282, 27283,
    27301, 27310, 27313, 27342, 27358, 27377, 27395, 27401, 27402, 27403, 27404,
    27405, 27406, 27407, 27408, 27409, 27410, 27411, 27412, 27413, 27415, 27416,
    27417, 27419, 27420, 27425, 27427, 27429, 27435, 27438, 27455, 27480, 27495,
    27497, 27498, 27499,
  ],
  "Orange County, North Carolina": [
    27231, 27243, 27278, 27510, 27514, 27515, 27516, 27517, 27599,
  ],
  "Lee County, North Carolina": [27237, 27330, 27331, 27332, 28355],
  "Davidson County, North Carolina": [
    27239, 27292, 27293, 27294, 27295, 27299, 27351, 27360, 27361, 27373, 27374,
  ],
  "Moore County, North Carolina": [
    27242, 27259, 27281, 27325, 27376, 28315, 28327, 28350, 28370, 28373, 28374,
    28387, 28388, 28394,
  ],
  "Person County, North Carolina": [27343, 27541, 27572, 27573, 27574, 27583],
  "Harnett County, North Carolina": [
    27501, 27505, 27506, 27521, 27543, 27546, 27552, 28323, 28326, 28334, 28335,
    28339, 28368, 28390,
  ],
  "Wake County, North Carolina": [
    27502, 27511, 27512, 27513, 27518, 27519, 27523, 27526, 27529, 27539, 27540,
    27545, 27560, 27562, 27571, 27587, 27588, 27591, 27592, 27597, 27601, 27602,
    27603, 27604, 27605, 27606, 27607, 27608, 27609, 27610, 27611, 27612, 27613,
    27614, 27615, 27616, 27617, 27619, 27620, 27621, 27622, 27623, 27624, 27625,
    27626, 27627, 27628, 27629, 27634, 27635, 27636, 27640, 27650, 27656, 27658,
    27661, 27668, 27675, 27676, 27690, 27695, 27697, 27698, 27699,
  ],
  "Durham County, North Carolina": [
    27503, 27701, 27702, 27703, 27704, 27705, 27706, 27707, 27708, 27709, 27710,
    27711, 27712, 27713, 27715, 27717, 27722,
  ],
  "Johnston County, North Carolina": [
    27504, 27520, 27524, 27527, 27528, 27542, 27555, 27568, 27569, 27576, 27577,
    27593,
  ],
  "Granville County, North Carolina": [
    27507, 27509, 27522, 27564, 27565, 27581, 27582,
  ],
  "Franklin County, North Carolina": [27508, 27525, 27549, 27596],
  "Wayne County, North Carolina": [
    27530, 27531, 27532, 27533, 27534, 27830, 27863, 28333, 28365, 28578,
  ],
  "Vance County, North Carolina": [27536, 27537, 27544, 27556, 27584],
  "Warren County, North Carolina": [
    27551, 27553, 27563, 27570, 27586, 27589, 27594,
  ],
  "Nash County, North Carolina": [
    27557, 27802, 27803, 27804, 27807, 27809, 27816, 27856, 27868, 27878, 27882,
    27891,
  ],
  "Edgecombe County, North Carolina": [
    27801, 27815, 27819, 27852, 27864, 27881, 27886,
  ],
  "Bertie County, North Carolina": [
    27805, 27847, 27849, 27872, 27924, 27957, 27967, 27983,
  ],
  "Beaufort County, North Carolina": [
    27806, 27808, 27810, 27814, 27817, 27821, 27860, 27865, 27889,
  ],
  "Pitt County, North Carolina": [
    27811, 27812, 27827, 27828, 27829, 27833, 27834, 27835, 27836, 27837, 27858,
    27879, 27884, 28513, 28530, 28590,
  ],
  "Wilson County, North Carolina": [
    27813, 27822, 27851, 27873, 27880, 27883, 27893, 27894, 27895, 27896,
  ],
  "Hertford County, North Carolina": [27818, 27855, 27910, 27922, 27942, 27986],
  "Northampton County, North Carolina": [
    27820, 27831, 27832, 27842, 27845, 27853, 27854, 27862, 27866, 27867, 27869,
    27876, 27877, 27897,
  ],
  "Halifax County, North Carolina": [
    27823, 27839, 27843, 27844, 27850, 27870, 27874, 27887, 27890,
  ],
  "Hyde County, North Carolina": [27824, 27826, 27875, 27885, 27960],
  "Martin County, North Carolina": [
    27825, 27840, 27841, 27846, 27857, 27861, 27871, 27892,
  ],
  "Greene County, North Carolina": [27888, 28538, 28554, 28580],
  "Pasquotank County, North Carolina": [27906, 27907, 27909],
  "Dare County, North Carolina": [
    27915, 27920, 27936, 27943, 27948, 27949, 27953, 27954, 27959, 27968, 27972,
    27978, 27981, 27982,
  ],
  "Currituck County, North Carolina": [
    27916, 27917, 27923, 27927, 27929, 27939, 27941, 27947, 27950, 27956, 27958,
    27964, 27965, 27966, 27973,
  ],
  "Perquimans County, North Carolina": [27919, 27930, 27944, 27985],
  "Camden County, North Carolina": [27921, 27974, 27976],
  "Tyrrell County, North Carolina": [27925],
  "Gates County, North Carolina": [
    27926, 27935, 27937, 27938, 27946, 27969, 27979,
  ],
  "Washington County, North Carolina": [27928, 27962, 27970],
  "Chowan County, North Carolina": [27932, 27980],
  "Stanly County, North Carolina": [
    28001, 28002, 28009, 28097, 28109, 28127, 28128, 28129, 28137, 28163,
  ],
  "Gaston County, North Carolina": [
    28006, 28012, 28016, 28021, 28032, 28034, 28052, 28053, 28054, 28055, 28056,
    28077, 28098, 28101, 28120, 28164,
  ],
  "Anson County, North Carolina": [
    28007, 28091, 28102, 28119, 28133, 28135, 28170,
  ],
  "Iredell County, North Carolina": [
    28010, 28115, 28117, 28123, 28166, 28625, 28634, 28660, 28677, 28687, 28688,
    28689,
  ],
  "Cleveland County, North Carolina": [
    28017, 28020, 28038, 28042, 28073, 28086, 28089, 28090, 28136, 28150, 28151,
    28152, 28169,
  ],
  "Rutherford County, North Carolina": [
    28018, 28019, 28024, 28040, 28043, 28074, 28076, 28114, 28139, 28160, 28167,
    28720, 28746,
  ],
  "Cabarrus County, North Carolina": [
    28025, 28026, 28027, 28075, 28081, 28082, 28083, 28107, 28124,
  ],
  "Mecklenburg County, North Carolina": [
    28031, 28035, 28036, 28070, 28078, 28105, 28106, 28126, 28130, 28134, 28201,
    28202, 28203, 28204, 28205, 28206, 28207, 28208, 28209, 28210, 28211, 28212,
    28213, 28214, 28215, 28216, 28217, 28218, 28219, 28220, 28221, 28222, 28223,
    28224, 28226, 28227, 28228, 28229, 28230, 28231, 28232, 28233, 28234, 28235,
    28236, 28237, 28241, 28242, 28243, 28244, 28246, 28247, 28250, 28253, 28254,
    28255, 28256, 28258, 28260, 28262, 28263, 28265, 28266, 28269, 28270, 28271,
    28272, 28273, 28274, 28275, 28277, 28278, 28280, 28281, 28282, 28284, 28285,
    28287, 28288, 28289, 28290, 28296, 28297, 28299,
  ],
  "Lincoln County, North Carolina": [28033, 28037, 28080, 28092, 28093, 28168],
  "Union County, North Carolina": [
    28079, 28103, 28104, 28108, 28110, 28111, 28112, 28173, 28174,
  ],
  "Cumberland County, North Carolina": [
    28301, 28302, 28303, 28304, 28305, 28306, 28307, 28308, 28309, 28310, 28311,
    28312, 28314, 28331, 28342, 28348, 28356, 28371, 28391, 28395,
  ],
  "Sampson County, North Carolina": [
    28318, 28328, 28329, 28344, 28366, 28382, 28385, 28393, 28441, 28444, 28447,
  ],
  "Robeson County, North Carolina": [
    28319, 28340, 28357, 28358, 28359, 28360, 28362, 28364, 28369, 28372, 28375,
    28377, 28378, 28383, 28384, 28386,
  ],
  "Bladen County, North Carolina": [
    28320, 28332, 28337, 28392, 28399, 28433, 28434, 28448,
  ],
  "Duplin County, North Carolina": [
    28325, 28341, 28349, 28398, 28453, 28458, 28464, 28466, 28508, 28518, 28521,
    28572,
  ],
  "Richmond County, North Carolina": [
    28330, 28338, 28345, 28347, 28367, 28379, 28380,
  ],
  "Scotland County, North Carolina": [28343, 28351, 28352, 28353, 28363, 28396],
  "Hoke County, North Carolina": [28376],
  "New Hanover County, North Carolina": [
    28401, 28402, 28403, 28404, 28405, 28406, 28407, 28408, 28409, 28410, 28411,
    28412, 28428, 28429, 28449, 28480,
  ],
  "Brunswick County, North Carolina": [
    28420, 28422, 28451, 28452, 28459, 28461, 28462, 28465, 28467, 28468, 28469,
    28470, 28479,
  ],
  "Pender County, North Carolina": [28421, 28425, 28435, 28443, 28457, 28478],
  "Columbus County, North Carolina": [
    28423, 28424, 28430, 28431, 28432, 28436, 28438, 28439, 28442, 28450, 28455,
    28456, 28463, 28472,
  ],
  "Onslow County, North Carolina": [
    28445, 28454, 28460, 28539, 28540, 28541, 28542, 28543, 28544, 28545, 28546,
    28547, 28555, 28574,
  ],
  "Lenoir County, North Carolina": [28501, 28502, 28503, 28504, 28525, 28551],
  "Pamlico County, North Carolina": [
    28509, 28510, 28515, 28529, 28537, 28552, 28556, 28571, 28583, 28587,
  ],
  "Carteret County, North Carolina": [
    28511, 28512, 28516, 28520, 28524, 28528, 28531, 28553, 28557, 28570, 28575,
    28577, 28579, 28581, 28582, 28584, 28589, 28594,
  ],
  "Craven County, North Carolina": [
    28519, 28523, 28526, 28527, 28532, 28533, 28560, 28561, 28562, 28563, 28564,
    28586,
  ],
  "Jones County, North Carolina": [28522, 28573, 28585],
  "Catawba County, North Carolina": [
    28601, 28602, 28603, 28609, 28610, 28613, 28650, 28658, 28673, 28682,
  ],
  "Watauga County, North Carolina": [
    28604, 28605, 28607, 28608, 28618, 28679, 28691, 28692, 28698,
  ],
  "Wilkes County, North Carolina": [
    28606, 28624, 28635, 28649, 28651, 28654, 28656, 28659, 28665, 28669, 28670,
    28674, 28685, 28697,
  ],
  "Caldwell County, North Carolina": [
    28611, 28630, 28633, 28638, 28645, 28661, 28667,
  ],
  "Burke County, North Carolina": [
    28612, 28619, 28628, 28637, 28641, 28655, 28666, 28671, 28680, 28690,
  ],
  "Ashe County, North Carolina": [
    28615, 28617, 28626, 28629, 28631, 28640, 28643, 28644, 28672, 28684, 28693,
    28694,
  ],
  "Avery County, North Carolina": [
    28616, 28622, 28646, 28647, 28652, 28653, 28657, 28662, 28664,
  ],
  "Alleghany County, North Carolina": [28623, 28627, 28663, 28668, 28675],
  "Alexander County, North Carolina": [28636, 28678, 28681, 28699],
  "Buncombe County, North Carolina": [
    28701, 28704, 28709, 28711, 28715, 28728, 28730, 28748, 28757, 28770, 28776,
    28778, 28787, 28801, 28802, 28803, 28804, 28805, 28806, 28810, 28813, 28814,
    28815, 28816,
  ],
  "Graham County, North Carolina": [28702, 28733, 28771],
  "Mitchell County, North Carolina": [28705, 28765, 28777],
  "Jackson County, North Carolina": [
    28707, 28717, 28723, 28725, 28736, 28774, 28779, 28783, 28788, 28789,
  ],
  "Transylvania County, North Carolina": [
    28708, 28712, 28718, 28747, 28766, 28768, 28772,
  ],
  "Henderson County, North Carolina": [
    28710, 28724, 28726, 28727, 28729, 28731, 28732, 28735, 28739, 28742, 28758,
    28759, 28760, 28784, 28790, 28791, 28792, 28793,
  ],
  "Swain County, North Carolina": [28713, 28719],
  "Yancey County, North Carolina": [28714, 28740, 28755],
  "Haywood County, North Carolina": [
    28716, 28721, 28738, 28745, 28751, 28785, 28786,
  ],
  "Polk County, North Carolina": [28722, 28750, 28756, 28773, 28782],
  "Macon County, North Carolina": [28734, 28741, 28744, 28763, 28775, 28781],
  "McDowell County, North Carolina": [28737, 28749, 28752, 28761, 28762],
  "Madison County, North Carolina": [28743, 28753, 28754],
  "Cherokee County, North Carolina": [28901, 28903, 28905, 28906],
  "Clay County, North Carolina": [28902, 28904, 28909],
  "Clarendon County, South Carolina": [
    29001, 29041, 29102, 29111, 29143, 29148, 29162,
  ],
  "Richland County, South Carolina": [
    29002, 29016, 29044, 29052, 29061, 29063, 29147, 29177, 29201, 29202, 29203,
    29204, 29205, 29206, 29207, 29208, 29209, 29210, 29211, 29214, 29215, 29216,
    29217, 29218, 29219, 29220, 29221, 29222, 29223, 29224, 29225, 29226, 29227,
    29228, 29229, 29230, 29240, 29250, 29260, 29290, 29292,
  ],
  "Bamberg County, South Carolina": [29003, 29042, 29081, 29843],
  "Lexington County, South Carolina": [
    29006, 29033, 29036, 29053, 29054, 29070, 29071, 29072, 29073, 29123, 29160,
    29169, 29170, 29171, 29172, 29212,
  ],
  "Kershaw County, South Carolina": [
    29009, 29020, 29021, 29032, 29045, 29074, 29078, 29175,
  ],
  "Lee County, South Carolina": [29010, 29046, 29080],
  "Chester County, South Carolina": [
    29014, 29055, 29706, 29712, 29714, 29724, 29729,
  ],
  "Fairfield County, South Carolina": [29015, 29065, 29130, 29132, 29180],
  "Orangeburg County, South Carolina": [
    29018, 29038, 29039, 29047, 29048, 29059, 29107, 29112, 29113, 29115, 29116,
    29117, 29118, 29133, 29142, 29146, 29163, 29432,
  ],
  "Calhoun County, South Carolina": [29030, 29135],
  "Union County, South Carolina": [29031, 29321, 29353, 29364, 29379, 29395],
  "Newberry County, South Carolina": [
    29037, 29075, 29108, 29122, 29126, 29127, 29145, 29178,
  ],
  "Sumter County, South Carolina": [
    29040, 29051, 29062, 29104, 29125, 29128, 29150, 29151, 29152, 29153, 29154,
    29168,
  ],
  "Williamsburg County, South Carolina": [
    29056, 29518, 29554, 29556, 29564, 29580, 29590,
  ],
  "Lancaster County, South Carolina": [
    29058, 29067, 29707, 29720, 29721, 29722, 29744,
  ],
  "Darlington County, South Carolina": [
    29069, 29079, 29532, 29540, 29550, 29551, 29593,
  ],
  "Colleton County, South Carolina": [
    29082, 29433, 29435, 29446, 29452, 29474, 29475, 29481, 29488, 29493, 29929,
  ],
  "Chesterfield County, South Carolina": [
    29101, 29520, 29584, 29709, 29718, 29727, 29728, 29741,
  ],
  "Aiken County, South Carolina": [
    29105, 29129, 29137, 29164, 29801, 29802, 29803, 29804, 29805, 29808, 29809,
    29816, 29822, 29828, 29829, 29831, 29834, 29839, 29841, 29842, 29850, 29851,
    29856, 29861,
  ],
  "Florence County, South Carolina": [
    29114, 29161, 29501, 29502, 29503, 29504, 29505, 29506, 29530, 29541, 29555,
    29560, 29583, 29591,
  ],
  "Saluda County, South Carolina": [29138, 29166],
  "Spartanburg County, South Carolina": [
    29301, 29302, 29303, 29304, 29305, 29306, 29307, 29316, 29318, 29319, 29320,
    29322, 29323, 29324, 29329, 29330, 29331, 29333, 29334, 29335, 29336, 29338,
    29346, 29348, 29349, 29356, 29365, 29368, 29369, 29372, 29373, 29374, 29375,
    29376, 29377, 29378, 29385, 29386, 29388, 29390, 29391, 29652,
  ],
  "Laurens County, South Carolina": [
    29325, 29332, 29351, 29355, 29360, 29370, 29384, 29645, 29692,
  ],
  "Cherokee County, South Carolina": [29340, 29341, 29342, 29702],
  "Charleston County, South Carolina": [
    29401, 29402, 29403, 29404, 29405, 29406, 29407, 29409, 29412, 29413, 29414,
    29415, 29416, 29417, 29418, 29419, 29422, 29423, 29424, 29425, 29426, 29429,
    29438, 29439, 29449, 29451, 29455, 29457, 29458, 29464, 29465, 29466, 29470,
    29482, 29487,
  ],
  "Berkeley County, South Carolina": [
    29410, 29430, 29431, 29434, 29436, 29445, 29450, 29453, 29461, 29468, 29469,
    29476, 29479, 29486, 29492,
  ],
  "Dorchester County, South Carolina": [
    29420, 29437, 29447, 29448, 29456, 29471, 29472, 29477, 29483, 29484, 29485,
  ],
  "Georgetown County, South Carolina": [29440, 29442, 29510, 29585],
  "Horry County, South Carolina": [
    29511, 29526, 29527, 29528, 29544, 29545, 29566, 29568, 29569, 29572, 29575,
    29576, 29577, 29578, 29579, 29581, 29582, 29587, 29588, 29597, 29598,
  ],
  "Marlboro County, South Carolina": [29512, 29516, 29525, 29570, 29594, 29596],
  "Marion County, South Carolina": [29519, 29546, 29571, 29574, 29589],
  "Dillon County, South Carolina": [
    29536, 29543, 29547, 29563, 29565, 29567, 29573, 29592,
  ],
  "Greenville County, South Carolina": [
    29601, 29602, 29603, 29604, 29605, 29606, 29607, 29608, 29609, 29610, 29611,
    29612, 29613, 29614, 29615, 29616, 29617, 29635, 29636, 29644, 29650, 29651,
    29661, 29662, 29669, 29673, 29680, 29681, 29683, 29687, 29688, 29690, 29698,
  ],
  "Abbeville County, South Carolina": [29620, 29628, 29638, 29639, 29659],
  "Anderson County, South Carolina": [
    29621, 29622, 29623, 29624, 29625, 29626, 29627, 29654, 29655, 29656, 29670,
    29677, 29684, 29689, 29697,
  ],
  "Pickens County, South Carolina": [
    29630, 29631, 29632, 29633, 29634, 29640, 29641, 29642, 29657, 29667, 29671,
    29682, 29685,
  ],
  "Oconee County, South Carolina": [
    29643, 29658, 29664, 29665, 29672, 29675, 29676, 29678, 29679, 29686, 29691,
    29693, 29696,
  ],
  "Greenwood County, South Carolina": [
    29646, 29647, 29648, 29649, 29653, 29666, 29695, 29819, 29848,
  ],
  "York County, South Carolina": [
    29703, 29704, 29708, 29710, 29715, 29716, 29717, 29726, 29730, 29731, 29732,
    29733, 29734, 29742, 29743, 29745,
  ],
  "Allendale County, South Carolina": [29810, 29827, 29836, 29846, 29849],
  "Barnwell County, South Carolina": [29812, 29813, 29817, 29826, 29853],
  "McCormick County, South Carolina": [
    29821, 29835, 29838, 29840, 29844, 29845, 29899,
  ],
  "Edgefield County, South Carolina": [29824, 29832, 29847, 29860],
  "Beaufort County, South Carolina": [
    29901, 29902, 29903, 29904, 29905, 29906, 29907, 29909, 29910, 29914, 29915,
    29920, 29925, 29926, 29928, 29931, 29935, 29938, 29940, 29941,
  ],
  "Hampton County, South Carolina": [
    29911, 29913, 29916, 29918, 29921, 29922, 29923, 29924, 29932, 29933, 29939,
    29944, 29945,
  ],
  "Jasper County, South Carolina": [29912, 29927, 29934, 29936, 29943],
  "DeKalb County, Georgia": [
    30002, 30021, 30030, 30032, 30033, 30034, 30035, 30038, 30058, 30072, 30079,
    30083, 30084, 30087, 30088, 30294, 30307, 30316, 30317, 30319, 30322, 30329,
    30338, 30340, 30341, 30345, 30346, 30360,
  ],
  "Gwinnett County, Georgia": [
    30003, 30010, 30017, 30019, 30024, 30026, 30029, 30039, 30042, 30043, 30044,
    30045, 30046, 30047, 30048, 30049, 30052, 30071, 30078, 30091, 30092, 30093,
    30095, 30096, 30098, 30099, 30515, 30518, 30519,
  ],
  "Fulton County, Georgia": [
    30004, 30005, 30009, 30022, 30023, 30075, 30076, 30077, 30097, 30213, 30268,
    30272, 30291, 30301, 30302, 30303, 30304, 30305, 30306, 30308, 30309, 30310,
    30311, 30312, 30313, 30314, 30315, 30318, 30321, 30324, 30325, 30326, 30327,
    30328, 30330, 30331, 30332, 30333, 30334, 30336, 30337, 30342, 30343, 30344,
    30347, 30348, 30349, 30350, 30353, 30354, 30355, 30356, 30357, 30358, 30359,
    30361, 30362, 30363, 30364, 30366, 30368, 30369, 30370, 30371, 30374, 30375,
    30376, 30377, 30378, 30379, 30380, 30384, 30385, 30386, 30387, 30388, 30389,
    30390, 30392, 30394, 30396, 30398, 30399, 31106, 31107, 31119, 31120, 31126,
    31131, 31136, 31139, 31141, 31145, 31146, 31150, 31156, 31191, 31192, 31193,
    31195, 31196, 31197, 31198, 31199, 39901,
  ],
  "Cobb County, Georgia": [
    30006, 30007, 30008, 30060, 30061, 30062, 30063, 30064, 30065, 30066, 30067,
    30068, 30069, 30080, 30081, 30082, 30090, 30101, 30106, 30111, 30126, 30127,
    30144, 30152, 30156, 30160, 30168, 30339,
  ],
  "Barrow County, Georgia": [30011, 30620, 30666, 30680],
  "Rockdale County, Georgia": [30012, 30013, 30094],
  "Newton County, Georgia": [30014, 30015, 30016, 30054, 30070, 30164],
  "Walton County, Georgia": [30018, 30025, 30641, 30655, 30656],
  "Forsyth County, Georgia": [30028, 30040, 30041],
  "Dekalb County, Georgia": [30031, 30036, 30037, 30073, 30074, 30085, 30086],
  "Jasper County, Georgia": [30055, 30056, 31038, 31064, 31085],
  "Cherokee County, Georgia": [
    30102, 30107, 30114, 30115, 30142, 30146, 30169, 30183, 30188, 30189,
  ],
  "Bartow County, Georgia": [
    30103, 30120, 30121, 30123, 30137, 30145, 30171, 30178, 30184,
  ],
  "Polk County, Georgia": [30104, 30125, 30153],
  "Floyd County, Georgia": [
    30105, 30124, 30129, 30147, 30149, 30161, 30162, 30163, 30165, 30172, 30173,
  ],
  "Carroll County, Georgia": [
    30108, 30109, 30112, 30116, 30117, 30118, 30119, 30150, 30170, 30179, 30180,
    30182, 30185,
  ],
  "Haralson County, Georgia": [30110, 30113, 30140, 30176],
  "Douglas County, Georgia": [30122, 30133, 30134, 30135, 30154, 30187],
  "Paulding County, Georgia": [30132, 30141, 30157],
  "Cleburne County, Georgia": [30138],
  "Gordon County, Georgia": [
    30139, 30701, 30703, 30732, 30733, 30734, 30735, 30746,
  ],
  "Pickens County, Georgia": [30143, 30148, 30151, 30175, 30177],
  "Lamar County, Georgia": [30204, 30257],
  "Fayette County, Georgia": [30205, 30214, 30215, 30269, 30270, 30290, 31169],
  "Pike County, Georgia": [30206, 30256, 30258, 30292, 30295],
  "Spalding County, Georgia": [30212, 30223, 30224, 30266, 30284],
  "Butts County, Georgia": [30216, 30233, 30234],
  "Heard County, Georgia": [30217, 30219],
  "Meriwether County, Georgia": [30218, 30222, 30251, 30293, 31816, 31830],
  "Coweta County, Georgia": [
    30220, 30229, 30259, 30263, 30264, 30265, 30271, 30275, 30276, 30277, 30289,
  ],
  "Henry County, Georgia": [30228, 30248, 30252, 30253, 30281],
  "Troup County, Georgia": [30230, 30240, 30241, 30261, 31833],
  "Clayton County, Georgia": [
    30236, 30237, 30238, 30250, 30260, 30273, 30274, 30287, 30288, 30296, 30297,
    30298, 30320,
  ],
  "Upson County, Georgia": [30285, 30286, 31097],
  "Emanuel County, Georgia": [
    30401, 30425, 30441, 30447, 30448, 30464, 30471, 31002,
  ],
  "Montgomery County, Georgia": [30410, 30412, 30445, 30470, 30473],
  "Wheeler County, Georgia": [30411, 30428],
  "Jefferson County, Georgia": [
    30413, 30434, 30477, 30803, 30818, 30823, 30833,
  ],
  "Evans County, Georgia": [30414, 30417, 30423, 30429],
  "Bulloch County, Georgia": [30415, 30450, 30452, 30458, 30459, 30460, 30461],
  "Tattnall County, Georgia": [30420, 30421, 30427, 30438, 30453, 30499],
  "Screven County, Georgia": [30424, 30446, 30449, 30455, 30467],
  "Burke County, Georgia": [30426, 30456, 30811, 30816, 30830],
  "Toombs County, Georgia": [30436, 30474, 30475],
  "Candler County, Georgia": [30439, 30451],
  "Jenkins County, Georgia": [30442, 30822],
  "Laurens County, Georgia": [
    30454, 31009, 31019, 31021, 31022, 31027, 31040, 31065, 31075,
  ],
  "Treutlen County, Georgia": [30457],
  "Hall County, Georgia": [
    30501, 30502, 30503, 30504, 30506, 30507, 30527, 30542, 30543, 30554, 30564,
    30566,
  ],
  "Habersham County, Georgia": [
    30510, 30523, 30531, 30535, 30544, 30563, 30580, 30596,
  ],
  "Banks County, Georgia": [30511, 30530, 30547],
  "Union County, Georgia": [30512, 30514, 30572],
  "Fannin County, Georgia": [30513, 30541, 30555, 30559, 30560],
  "Hart County, Georgia": [30516, 30643],
  "Jackson County, Georgia": [
    30517, 30529, 30548, 30549, 30558, 30565, 30567, 30575, 30599,
  ],
  "Franklin County, Georgia": [30520, 30521, 30553, 30557, 30639, 30662],
  "Gilmer County, Georgia": [30522, 30536, 30539, 30540],
  "Rabun County, Georgia": [
    30525, 30537, 30552, 30562, 30568, 30573, 30576, 30581,
  ],
  "White County, Georgia": [30528, 30545, 30571],
  "Lumpkin County, Georgia": [30533, 30597],
  "Dawson County, Georgia": [30534],
  "Stephens County, Georgia": [30538, 30577, 30598],
  "Towns County, Georgia": [30546, 30582],
  "Clarke County, Georgia": [
    30601, 30602, 30603, 30604, 30605, 30606, 30607, 30608, 30609, 30612, 30683,
  ],
  "Oglethorpe County, Georgia": [30619, 30627, 30630, 30648, 30667, 30671],
  "Oconee County, Georgia": [30621, 30622, 30638, 30677],
  "Morgan County, Georgia": [30623, 30625, 30650, 30663],
  "Elbert County, Georgia": [30624, 30634, 30635],
  "Madison County, Georgia": [30628, 30629, 30633, 30646, 30647],
  "Taliaferro County, Georgia": [30631, 30664],
  "Greene County, Georgia": [30642, 30665, 30669, 30678],
  "undefined, Georgia": [30645, 31144],
  "Wilkes County, Georgia": [30660, 30668, 30673],
  "Murray County, Georgia": [30705, 30708, 30711, 30724, 30751],
  "Walker County, Georgia": [30707, 30725, 30728, 30739, 30741, 30750],
  "Whitfield County, Georgia": [
    30710, 30719, 30720, 30721, 30722, 30740, 30755, 30756,
  ],
  "Catoosa County, Georgia": [30726, 30736, 30742],
  "Chattooga County, Georgia": [30730, 30731, 30747, 30753],
  "Dade County, Georgia": [30738, 30752, 30757],
  "Columbia County, Georgia": [30802, 30809, 30813, 30814, 30903, 30907],
  "Richmond County, Georgia": [
    30805, 30812, 30815, 30901, 30904, 30905, 30906, 30909, 30911, 30912, 30913,
    30914, 30916, 30917, 30919, 30999,
  ],
  "McDuffie County, Georgia": [30806, 30808, 30824],
  "Warren County, Georgia": [30807, 30819, 30821, 30828, 31045],
  "Glascock County, Georgia": [30810, 30820],
  "Lincoln County, Georgia": [30817],
  "Wilcox County, Georgia": [31001, 31071, 31072, 31079, 31084],
  "Wilkinson County, Georgia": [31003, 31031, 31042, 31054, 31090],
  "Monroe County, Georgia": [31004, 31016, 31029, 31046, 31086],
  "Houston County, Georgia": [
    31005, 31013, 31025, 31028, 31047, 31069, 31088, 31093, 31095, 31098, 31099,
  ],
  "Taylor County, Georgia": [31006, 31039, 31076, 31081],
  "Dooly County, Georgia": [31007, 31051, 31070, 31091, 31092],
  "Peach County, Georgia": [31008, 31030],
  "Crisp County, Georgia": [31010, 31015, 31712],
  "Dodge County, Georgia": [31011, 31012, 31023, 31077],
  "Bleckley County, Georgia": [31014],
  "Twiggs County, Georgia": [31017, 31020, 31044],
  "Washington County, Georgia": [31018, 31035, 31067, 31082, 31089, 31094],
  "Putnam County, Georgia": [31024, 31026],
  "Jones County, Georgia": [31032, 31033],
  "Baldwin County, Georgia": [31034, 31059, 31061, 31062],
  "Pulaski County, Georgia": [31036],
  "Telfair County, Georgia": [31037, 31055, 31060, 31083, 31544, 31549],
  "Macon County, Georgia": [31041, 31057, 31063, 31068],
  "Johnson County, Georgia": [31049, 31096],
  "Crawford County, Georgia": [31050, 31066, 31078],
  "Bibb County, Georgia": [
    31052, 31201, 31202, 31203, 31204, 31205, 31206, 31207, 31208, 31209, 31210,
    31211, 31212, 31213, 31216, 31217, 31220, 31221, 31294, 31295, 31296, 31297,
  ],
  "Marion County, Georgia": [31058, 31801, 31803],
  "Hancock County, Georgia": [31087],
  "Liberty County, Georgia": [
    31301, 31309, 31310, 31313, 31314, 31315, 31320, 31323, 31333,
  ],
  "Effingham County, Georgia": [
    31302, 31303, 31307, 31312, 31318, 31326, 31329,
  ],
  "McIntosh County, Georgia": [31304, 31305, 31319, 31327, 31331],
  "Bryan County, Georgia": [31308, 31321, 31324],
  "Long County, Georgia": [31316],
  "Chatham County, Georgia": [
    31322, 31328, 31401, 31402, 31403, 31404, 31405, 31406, 31407, 31408, 31409,
    31410, 31411, 31412, 31414, 31415, 31416, 31418, 31419, 31420, 31421,
  ],
  "Ware County, Georgia": [31501, 31502, 31503, 31550, 31552, 31564],
  "Bacon County, Georgia": [31510],
  "Coffee County, Georgia": [31512, 31519, 31533, 31534, 31535, 31554, 31567],
  "Appling County, Georgia": [31513, 31515, 31518, 31563],
  "Pierce County, Georgia": [31516, 31551, 31556, 31557],
  "Glynn County, Georgia": [
    31520, 31521, 31522, 31523, 31524, 31525, 31527, 31561,
  ],
  "Jeff Davis County, Georgia": [31532, 31539],
  "Charlton County, Georgia": [31537, 31562],
  "Brantley County, Georgia": [31542, 31543, 31553, 31566],
  "Wayne County, Georgia": [31545, 31546, 31555, 31560, 31598, 31599],
  "Camden County, Georgia": [31547, 31548, 31558, 31565, 31568, 31569],
  "Lowndes County, Georgia": [
    31601, 31602, 31603, 31604, 31605, 31606, 31632, 31636, 31641, 31698, 31699,
  ],
  "Cook County, Georgia": [31620, 31627, 31637, 31647],
  "Berrien County, Georgia": [31622, 31639, 31645, 31749],
  "Clinch County, Georgia": [31623, 31630, 31631, 31634],
  "Atkinson County, Georgia": [31624, 31642, 31650],
  "Brooks County, Georgia": [31625, 31629, 31638, 31643],
  "Thomas County, Georgia": [
    31626, 31720, 31738, 31757, 31758, 31765, 31773, 31778, 31792, 31799,
  ],
  "Lanier County, Georgia": [31635, 31649],
  "Echols County, Georgia": [31648],
  "Dougherty County, Georgia": [
    31701, 31702, 31703, 31704, 31705, 31706, 31707, 31708, 31721, 31782,
  ],
  "Sumter County, Georgia": [31709, 31711, 31719, 31735, 31743, 31764, 31780],
  "Turner County, Georgia": [31714, 31783, 31790],
  "Mitchell County, Georgia": [31716, 31730, 31739, 31779, 31784],
  "Colquitt County, Georgia": [
    31722, 31744, 31747, 31753, 31756, 31768, 31771, 31776, 31788,
  ],
  "Tift County, Georgia": [31727, 31733, 31775, 31793, 31794, 31795],
  "Ben Hill County, Georgia": [31750],
  "Irwin County, Georgia": [31760, 31769, 31774, 31798],
  "Lee County, Georgia": [31763, 31787],
  "Worth County, Georgia": [31772, 31781, 31789, 31791, 31796],
  "Harris County, Georgia": [
    31804, 31807, 31808, 31811, 31822, 31823, 31826, 31831,
  ],
  "Chattahoochee County, Georgia": [31805, 31995],
  "Schley County, Georgia": [31806],
  "Talbot County, Georgia": [31810, 31812, 31827, 31836],
  "Stewart County, Georgia": [31814, 31815, 31821, 31825],
  "Muscogee County, Georgia": [
    31820, 31829, 31901, 31902, 31903, 31904, 31905, 31906, 31907, 31908, 31909,
    31914, 31917, 31993, 31997, 31998, 31999,
  ],
  "Webster County, Georgia": [31824, 31832],
  "Clay County, Florida": [
    32003, 32006, 32030, 32043, 32050, 32065, 32067, 32068, 32073, 32079, 32160,
    32656,
  ],
  "St. Johns County, Florida": [
    32004, 32033, 32080, 32081, 32082, 32084, 32085, 32086, 32092, 32095, 32145,
    32259,
  ],
  "Putnam County, Florida": [
    32007, 32112, 32131, 32138, 32139, 32140, 32147, 32148, 32149, 32157, 32177,
    32178, 32181, 32185, 32187, 32189, 32193, 32640, 32666,
  ],
  "Suwannee County, Florida": [32008, 32060, 32062, 32064, 32071, 32094],
  "Nassau County, Florida": [32009, 32011, 32034, 32035, 32041, 32046, 32097],
  "Lafayette County, Florida": [32013, 32066],
  "Columbia County, Florida": [32024, 32025, 32038, 32055, 32056, 32061],
  "Union County, Florida": [32026, 32054, 32083, 32697],
  "Baker County, Florida": [32040, 32063, 32072, 32087],
  "Bradford County, Florida": [32042, 32044, 32058, 32091, 32622],
  "Hamilton County, Florida": [32052, 32053, 32096],
  "Madison County, Florida": [32059, 32331, 32340, 32341, 32350],
  "Duval County, Florida": [
    32099, 32201, 32202, 32203, 32204, 32205, 32206, 32207, 32208, 32209, 32210,
    32211, 32212, 32214, 32215, 32216, 32217, 32218, 32219, 32220, 32221, 32222,
    32223, 32224, 32225, 32226, 32227, 32228, 32229, 32230, 32231, 32232, 32233,
    32234, 32235, 32236, 32237, 32238, 32239, 32240, 32241, 32244, 32245, 32246,
    32247, 32250, 32254, 32255, 32256, 32257, 32258, 32260, 32266, 32267, 32277,
    32290,
  ],
  "Lake County, Florida": [
    32102, 32158, 32159, 32702, 32726, 32727, 32735, 32736, 32756, 32757, 32767,
    32776, 32778, 32784, 34705, 34711, 34712, 34713, 34714, 34715, 34729, 34731,
    34736, 34737, 34748, 34749, 34753, 34755, 34756, 34762, 34788, 34789, 34797,
  ],
  "Volusia County, Florida": [
    32105, 32114, 32115, 32116, 32117, 32118, 32119, 32120, 32121, 32122, 32123,
    32124, 32125, 32126, 32127, 32128, 32129, 32130, 32132, 32141, 32168, 32169,
    32170, 32173, 32174, 32175, 32176, 32180, 32190, 32198, 32706, 32713, 32720,
    32721, 32722, 32723, 32724, 32725, 32728, 32738, 32739, 32744, 32753, 32759,
    32763, 32764, 32774,
  ],
  "Flagler County, Florida": [32110, 32135, 32136, 32137, 32142, 32143, 32164],
  "Marion County, Florida": [
    32111, 32113, 32133, 32134, 32179, 32182, 32183, 32192, 32195, 32617, 32633,
    32634, 32663, 32664, 32681, 32686, 34420, 34421, 34430, 34431, 34432, 34470,
    34471, 34472, 34473, 34474, 34475, 34476, 34477, 34478, 34479, 34480, 34481,
    34482, 34483, 34488, 34489, 34491, 34492,
  ],
  "Sumter County, Florida": [
    32162, 32163, 33513, 33514, 33521, 33538, 33585, 33597, 34484, 34785,
  ],
  "Leon County, Florida": [
    32301, 32302, 32303, 32304, 32305, 32306, 32307, 32308, 32309, 32310, 32311,
    32312, 32313, 32314, 32315, 32316, 32317, 32318, 32362, 32395, 32399,
  ],
  "Franklin County, Florida": [32320, 32322, 32323, 32328, 32329],
  "Liberty County, Florida": [32321, 32334, 32335, 32360],
  "Gadsden County, Florida": [
    32324, 32330, 32332, 32333, 32343, 32351, 32352, 32353,
  ],
  "Wakulla County, Florida": [32326, 32327, 32346, 32355, 32358],
  "Jefferson County, Florida": [32336, 32337, 32344, 32345, 32361],
  "Taylor County, Florida": [32347, 32348, 32356, 32357, 32359],
  "Bay County, Florida": [
    32401, 32402, 32403, 32404, 32405, 32406, 32407, 32408, 32409, 32410, 32411,
    32412, 32413, 32417, 32438, 32444, 32466,
  ],
  "Jackson County, Florida": [
    32420, 32423, 32426, 32431, 32432, 32440, 32442, 32443, 32445, 32446, 32447,
    32448, 32460,
  ],
  "Calhoun County, Florida": [32421, 32424, 32430, 32449],
  "Walton County, Florida": [
    32422, 32433, 32434, 32435, 32439, 32454, 32455, 32459, 32461, 32538, 32550,
  ],
  "Holmes County, Florida": [32425, 32452, 32464],
  "Washington County, Florida": [32427, 32428, 32437, 32462, 32463],
  "Gulf County, Florida": [32456, 32457, 32465],
  "Escambia County, Florida": [
    32501, 32502, 32503, 32504, 32505, 32506, 32507, 32508, 32509, 32511, 32512,
    32513, 32514, 32516, 32520, 32521, 32522, 32523, 32524, 32526, 32533, 32534,
    32535, 32559, 32560, 32568, 32577, 32590, 32591, 32592,
  ],
  "Santa Rosa County, Florida": [
    32530, 32561, 32562, 32563, 32565, 32566, 32570, 32571, 32572, 32583,
  ],
  "Okaloosa County, Florida": [
    32531, 32536, 32537, 32539, 32540, 32541, 32542, 32544, 32547, 32548, 32549,
    32564, 32567, 32569, 32578, 32579, 32580, 32588,
  ],
  "Alachua County, Florida": [
    32601, 32602, 32603, 32604, 32605, 32606, 32607, 32608, 32609, 32610, 32611,
    32612, 32613, 32614, 32615, 32616, 32618, 32627, 32631, 32635, 32641, 32643,
    32653, 32654, 32655, 32658, 32662, 32667, 32669, 32694,
  ],
  "Gilchrist County, Florida": [32619, 32693],
  "Levy County, Florida": [
    32621, 32625, 32626, 32639, 32644, 32668, 32683, 32696, 34449, 34498,
  ],
  "Dixie County, Florida": [32628, 32648, 32680, 32692],
  "Seminole County, Florida": [
    32701, 32707, 32708, 32714, 32715, 32716, 32718, 32719, 32730, 32732, 32745,
    32746, 32747, 32750, 32752, 32762, 32765, 32766, 32771, 32772, 32773, 32779,
    32791, 32795, 32799,
  ],
  "Orange County, Florida": [
    32703, 32704, 32709, 32710, 32712, 32733, 32751, 32768, 32777, 32789, 32790,
    32792, 32793, 32794, 32798, 32801, 32802, 32803, 32804, 32805, 32806, 32807,
    32808, 32809, 32810, 32811, 32812, 32814, 32816, 32817, 32818, 32819, 32820,
    32821, 32822, 32824, 32825, 32826, 32827, 32828, 32829, 32830, 32831, 32832,
    32833, 32834, 32835, 32836, 32837, 32839, 32853, 32854, 32855, 32856, 32857,
    32858, 32859, 32860, 32861, 32862, 32867, 32868, 32869, 32872, 32877, 32878,
    32885, 32886, 32887, 32890, 32891, 32893, 32896, 32897, 32898, 34734, 34740,
    34760, 34761, 34777, 34778, 34786, 34787,
  ],
  "Brevard County, Florida": [
    32754, 32775, 32780, 32781, 32782, 32783, 32796, 32815, 32899, 32901, 32902,
    32903, 32904, 32905, 32906, 32907, 32908, 32909, 32910, 32911, 32912, 32919,
    32920, 32922, 32923, 32924, 32925, 32926, 32927, 32931, 32932, 32934, 32935,
    32936, 32937, 32940, 32941, 32949, 32950, 32951, 32952, 32953, 32954, 32955,
    32956, 32959, 32976,
  ],
  "Indian River County, Florida": [
    32948, 32957, 32958, 32960, 32961, 32962, 32963, 32964, 32965, 32966, 32967,
    32968, 32969, 32970, 32971, 32978,
  ],
  "Monroe County, Florida": [
    33001, 33036, 33037, 33040, 33041, 33042, 33043, 33045, 33050, 33051, 33052,
    33070,
  ],
  "Miami-Dade County, Florida": [
    33002, 33010, 33011, 33012, 33013, 33014, 33015, 33016, 33017, 33018, 33030,
    33031, 33032, 33033, 33034, 33035, 33039, 33054, 33055, 33056, 33090, 33092,
    33101, 33102, 33107, 33109, 33110, 33111, 33112, 33114, 33116, 33119, 33121,
    33122, 33124, 33125, 33126, 33127, 33128, 33129, 33130, 33131, 33132, 33133,
    33134, 33135, 33136, 33137, 33138, 33139, 33140, 33141, 33142, 33143, 33144,
    33145, 33146, 33147, 33148, 33149, 33150, 33151, 33152, 33153, 33154, 33155,
    33156, 33157, 33158, 33159, 33160, 33161, 33162, 33163, 33164, 33165, 33166,
    33167, 33168, 33169, 33170, 33172, 33173, 33174, 33175, 33176, 33177, 33178,
    33179, 33180, 33181, 33182, 33183, 33184, 33185, 33186, 33187, 33188, 33189,
    33190, 33193, 33194, 33195, 33196, 33197, 33199, 33222, 33231, 33233, 33234,
    33238, 33239, 33242, 33243, 33245, 33247, 33255, 33256, 33257, 33261, 33265,
    33266, 33269, 33280, 33283, 33296, 33299,
  ],
  "Broward County, Florida": [
    33004, 33008, 33009, 33019, 33020, 33021, 33022, 33023, 33024, 33025, 33026,
    33027, 33028, 33029, 33060, 33061, 33062, 33063, 33064, 33065, 33066, 33067,
    33068, 33069, 33071, 33072, 33073, 33074, 33075, 33076, 33077, 33081, 33082,
    33083, 33084, 33093, 33097, 33301, 33302, 33303, 33304, 33305, 33306, 33307,
    33308, 33309, 33310, 33311, 33312, 33313, 33314, 33315, 33316, 33317, 33318,
    33319, 33320, 33321, 33322, 33323, 33324, 33325, 33326, 33327, 33328, 33329,
    33330, 33331, 33332, 33334, 33335, 33336, 33337, 33338, 33339, 33340, 33345,
    33346, 33348, 33349, 33351, 33355, 33359, 33388, 33394, 33441, 33442, 33443,
  ],
  "undefined, Florida": [33106, 33191, 33192, 33198, 33206, 34441],
  "Palm Beach County, Florida": [
    33401, 33402, 33403, 33404, 33405, 33406, 33407, 33408, 33409, 33410, 33411,
    33412, 33413, 33414, 33415, 33416, 33417, 33418, 33419, 33420, 33421, 33422,
    33424, 33425, 33426, 33427, 33428, 33429, 33430, 33431, 33432, 33433, 33434,
    33435, 33436, 33437, 33438, 33439, 33444, 33445, 33446, 33447, 33448, 33449,
    33454, 33458, 33459, 33460, 33461, 33462, 33463, 33464, 33465, 33466, 33467,
    33468, 33469, 33470, 33472, 33473, 33474, 33476, 33477, 33478, 33480, 33481,
    33482, 33483, 33484, 33486, 33487, 33488, 33493, 33496, 33497, 33498, 33499,
  ],
  "Hendry County, Florida": [33440, 33930, 33935, 33975],
  "Martin County, Florida": [
    33455, 33475, 34956, 34957, 34958, 34990, 34991, 34992, 34994, 34995, 34996,
    34997,
  ],
  "Glades County, Florida": [33471, 33944],
  "Hillsborough County, Florida": [
    33503, 33508, 33509, 33510, 33511, 33527, 33530, 33534, 33547, 33548, 33549,
    33550, 33556, 33558, 33559, 33563, 33564, 33565, 33566, 33567, 33568, 33569,
    33570, 33571, 33572, 33573, 33575, 33578, 33579, 33583, 33584, 33586, 33587,
    33592, 33594, 33595, 33596, 33598, 33601, 33602, 33603, 33604, 33605, 33606,
    33607, 33608, 33609, 33610, 33611, 33612, 33613, 33614, 33615, 33616, 33617,
    33618, 33619, 33620, 33621, 33622, 33623, 33624, 33625, 33626, 33629, 33630,
    33631, 33633, 33634, 33635, 33637, 33646, 33647, 33650, 33651, 33655, 33660,
    33661, 33662, 33663, 33664, 33672, 33673, 33674, 33675, 33677, 33679, 33680,
    33681, 33682, 33684, 33685, 33686, 33687, 33688, 33689, 33690, 33694,
  ],
  "Pasco County, Florida": [
    33523, 33524, 33525, 33526, 33537, 33539, 33540, 33541, 33542, 33543, 33544,
    33545, 33574, 33576, 33593, 34610, 34637, 34638, 34639, 34652, 34653, 34654,
    34655, 34656, 34667, 34668, 34669, 34673, 34674, 34679, 34680, 34690, 34691,
    34692,
  ],
  "Pinellas County, Florida": [
    33701, 33702, 33703, 33704, 33705, 33706, 33707, 33708, 33709, 33710, 33711,
    33712, 33713, 33714, 33715, 33716, 33729, 33730, 33731, 33732, 33733, 33734,
    33736, 33737, 33738, 33740, 33741, 33742, 33743, 33744, 33747, 33755, 33756,
    33757, 33758, 33759, 33760, 33761, 33762, 33763, 33764, 33765, 33766, 33767,
    33769, 33770, 33771, 33772, 33773, 33774, 33775, 33776, 33777, 33778, 33779,
    33780, 33781, 33782, 33784, 33785, 33786, 34660, 34677, 34681, 34682, 34683,
    34684, 34685, 34688, 34689, 34695, 34697, 34698,
  ],
  "Polk County, Florida": [
    33801, 33802, 33803, 33804, 33805, 33806, 33807, 33809, 33810, 33811, 33812,
    33813, 33815, 33820, 33823, 33827, 33830, 33831, 33835, 33836, 33837, 33838,
    33839, 33840, 33841, 33843, 33844, 33845, 33846, 33847, 33849, 33850, 33851,
    33853, 33854, 33855, 33856, 33858, 33859, 33860, 33863, 33867, 33868, 33877,
    33880, 33881, 33882, 33883, 33884, 33885, 33888, 33896, 33897, 33898, 34759,
  ],
  "Highlands County, Florida": [
    33825, 33826, 33852, 33857, 33862, 33870, 33871, 33872, 33875, 33876, 33960,
  ],
  "Hardee County, Florida": [33834, 33865, 33873, 33890],
  "Osceola County, Florida": [
    33848, 34739, 34741, 34742, 34743, 34744, 34745, 34746, 34747, 34758, 34769,
    34770, 34771, 34772, 34773,
  ],
  "Lee County, Florida": [
    33900, 33901, 33902, 33903, 33904, 33905, 33906, 33907, 33908, 33909, 33910,
    33911, 33912, 33913, 33914, 33915, 33916, 33917, 33918, 33919, 33920, 33921,
    33922, 33924, 33928, 33929, 33931, 33932, 33936, 33945, 33956, 33957, 33965,
    33966, 33967, 33970, 33971, 33972, 33973, 33974, 33976, 33990, 33991, 33993,
    33994, 34133, 34134, 34135, 34136,
  ],
  "Charlotte County, Florida": [
    33927, 33938, 33946, 33947, 33948, 33949, 33950, 33951, 33952, 33953, 33954,
    33955, 33980, 33981, 33982, 33983, 34224,
  ],
  "Collier County, Florida": [
    34101, 34102, 34103, 34104, 34105, 34106, 34107, 34108, 34109, 34110, 34112,
    34113, 34114, 34116, 34117, 34119, 34120, 34137, 34138, 34139, 34140, 34141,
    34142, 34143, 34145, 34146,
  ],
  "Manatee County, Florida": [
    34201, 34202, 34203, 34204, 34205, 34206, 34207, 34208, 34209, 34210, 34211,
    34212, 34215, 34216, 34217, 34218, 34219, 34220, 34221, 34222, 34243, 34250,
    34251, 34264, 34270, 34280, 34281, 34282,
  ],
  "Sarasota County, Florida": [
    34223, 34228, 34229, 34230, 34231, 34232, 34233, 34234, 34235, 34236, 34237,
    34238, 34239, 34240, 34241, 34242, 34249, 34260, 34272, 34274, 34275, 34276,
    34277, 34278, 34284, 34285, 34286, 34287, 34288, 34289, 34290, 34291, 34292,
    34293, 34295,
  ],
  "Desoto County, Florida": [34265, 34267],
  "DeSoto County, Florida": [34266, 34268, 34269],
  "Citrus County, Florida": [
    34423, 34428, 34429, 34433, 34434, 34436, 34442, 34445, 34446, 34447, 34448,
    34450, 34451, 34452, 34453, 34460, 34461, 34464, 34465, 34487,
  ],
  "Hernando County, Florida": [
    34601, 34602, 34603, 34604, 34605, 34606, 34607, 34608, 34609, 34611, 34613,
    34614, 34636, 34661,
  ],
  "St. Lucie County, Florida": [
    34945, 34946, 34947, 34948, 34949, 34950, 34951, 34952, 34953, 34954, 34979,
    34981, 34982, 34983, 34984, 34985, 34986, 34987, 34988,
  ],
  "Okeechobee County, Florida": [34972, 34973, 34974],
  "St. Clair County, Alabama": [
    35004, 35052, 35054, 35112, 35120, 35125, 35128, 35131, 35135, 35146, 35953,
    35987,
  ],
  "Jefferson County, Alabama": [
    35005, 35006, 35015, 35020, 35021, 35022, 35023, 35036, 35041, 35048, 35060,
    35061, 35062, 35064, 35068, 35071, 35073, 35091, 35094, 35116, 35117, 35118,
    35119, 35123, 35126, 35127, 35139, 35142, 35173, 35180, 35181, 35201, 35202,
    35203, 35204, 35205, 35206, 35207, 35208, 35209, 35210, 35211, 35212, 35213,
    35214, 35215, 35216, 35217, 35218, 35219, 35220, 35221, 35222, 35223, 35224,
    35225, 35226, 35228, 35229, 35230, 35231, 35232, 35233, 35234, 35235, 35236,
    35237, 35238, 35240, 35243, 35244, 35245, 35246, 35249, 35253, 35254, 35255,
    35259, 35260, 35261, 35263, 35266, 35277, 35278, 35279, 35280, 35281, 35282,
    35283, 35285, 35286, 35287, 35288, 35289, 35290, 35291, 35292, 35293, 35294,
    35295, 35296, 35297, 35298, 35299,
  ],
  "Shelby County, Alabama": [
    35007, 35040, 35043, 35051, 35078, 35080, 35114, 35115, 35124, 35137, 35143,
    35144, 35147, 35176, 35178, 35185, 35186, 35187, 35242,
  ],
  "Tallapoosa County, Alabama": [
    35010, 35011, 36023, 36256, 36850, 36853, 36861,
  ],
  "Blount County, Alabama": [
    35013, 35031, 35049, 35079, 35097, 35121, 35133, 35172,
  ],
  "Talladega County, Alabama": [
    35014, 35032, 35044, 35096, 35149, 35150, 35151, 35160, 35161, 36268,
  ],
  "Marshall County, Alabama": [
    35016, 35175, 35747, 35755, 35950, 35951, 35957, 35964, 35976, 35980,
  ],
  "Cullman County, Alabama": [
    35019, 35033, 35053, 35055, 35056, 35057, 35058, 35070, 35077, 35083, 35087,
    35098, 35179,
  ],
  "Bibb County, Alabama": [
    35034, 35035, 35042, 35074, 35184, 35188, 36792, 36793,
  ],
  "Walker County, Alabama": [
    35038, 35063, 35130, 35148, 35501, 35502, 35503, 35504, 35549, 35550, 35560,
    35573, 35578, 35579, 35580, 35584, 35587,
  ],
  "Chilton County, Alabama": [35045, 35046, 35085, 35171, 36091, 36750, 36790],
  "Coosa County, Alabama": [35072, 35089, 35136, 35183, 36026],
  "Clay County, Alabama": [35082, 36251, 36255, 36258, 36266, 36267],
  "Tuscaloosa County, Alabama": [
    35111, 35401, 35402, 35403, 35404, 35405, 35406, 35407, 35440, 35444, 35446,
    35449, 35452, 35453, 35456, 35457, 35458, 35463, 35468, 35473, 35475, 35476,
    35478, 35480, 35482, 35485, 35486, 35487, 35490,
  ],
  "St Clair County, Alabama": [35182],
  "undefined, Alabama": [35270],
  "Hale County, Alabama": [35441, 35474, 36744, 36765, 36776],
  "Pickens County, Alabama": [35442, 35447, 35461, 35466, 35471, 35481],
  "Greene County, Alabama": [35443, 35448, 35462, 35469, 35491, 36740],
  "Sumter County, Alabama": [
    35459, 35460, 35464, 35470, 35477, 36901, 36907, 36925,
  ],
  "Winston County, Alabama": [
    35540, 35541, 35551, 35553, 35565, 35572, 35575, 35577,
  ],
  "Fayette County, Alabama": [35542, 35545, 35546, 35554, 35555, 35559],
  "Marion County, Alabama": [35543, 35548, 35563, 35564, 35570, 35594],
  "Lamar County, Alabama": [35544, 35552, 35574, 35576, 35586, 35592],
  "Franklin County, Alabama": [35571, 35581, 35582, 35585, 35593, 35653, 35654],
  "Morgan County, Alabama": [
    35601, 35602, 35603, 35609, 35619, 35621, 35622, 35640, 35670, 35699, 35754,
    35775,
  ],
  "Lauderdale County, Alabama": [
    35610, 35617, 35630, 35631, 35632, 35633, 35634, 35645, 35648, 35652, 35677,
  ],
  "Limestone County, Alabama": [
    35611, 35612, 35613, 35614, 35615, 35620, 35647, 35649, 35671, 35739, 35742,
    35756,
  ],
  "Colbert County, Alabama": [35616, 35646, 35660, 35661, 35662, 35674],
  "Lawrence County, Alabama": [35618, 35643, 35650, 35651, 35672, 35673],
  "Jackson County, Alabama": [
    35740, 35744, 35745, 35746, 35751, 35752, 35764, 35765, 35766, 35768, 35769,
    35771, 35772, 35774, 35776, 35958, 35966,
  ],
  "Madison County, Alabama": [
    35741, 35748, 35749, 35750, 35757, 35758, 35759, 35760, 35761, 35762, 35763,
    35767, 35773, 35801, 35802, 35803, 35804, 35805, 35806, 35807, 35808, 35809,
    35810, 35811, 35812, 35813, 35814, 35815, 35816, 35824, 35893, 35894, 35895,
    35896, 35897, 35898, 35899,
  ],
  "Etowah County, Alabama": [
    35901, 35902, 35903, 35904, 35905, 35906, 35907, 35952, 35954, 35956, 35972,
    35990,
  ],
  "Cherokee County, Alabama": [35959, 35960, 35973, 35983, 36275],
  "DeKalb County, Alabama": [
    35961, 35962, 35963, 35967, 35968, 35971, 35974, 35975, 35978, 35979, 35981,
    35984, 35986, 35988, 35989,
  ],
  "Autauga County, Alabama": [
    36003, 36006, 36008, 36051, 36066, 36067, 36068, 36749,
  ],
  "Pike County, Alabama": [36005, 36010, 36034, 36035, 36079, 36081, 36082],
  "Crenshaw County, Alabama": [36009, 36041, 36042, 36046, 36049, 36062, 36071],
  "Montgomery County, Alabama": [
    36013, 36036, 36043, 36052, 36057, 36064, 36065, 36069, 36101, 36102, 36103,
    36104, 36105, 36106, 36107, 36108, 36109, 36110, 36111, 36112, 36113, 36114,
    36115, 36116, 36117, 36118, 36119, 36120, 36121, 36123, 36124, 36125, 36130,
    36131, 36132, 36133, 36134, 36135, 36140, 36141, 36142, 36177, 36191,
  ],
  "Butler County, Alabama": [36015, 36030, 36033, 36037, 36456],
  "Barbour County, Alabama": [36016, 36017, 36027, 36048, 36072],
  "Elmore County, Alabama": [
    36020, 36022, 36024, 36025, 36045, 36054, 36078, 36080, 36092, 36093,
  ],
  "Covington County, Alabama": [
    36028, 36038, 36420, 36421, 36442, 36455, 36467, 36474, 36476, 36483,
  ],
  "Bullock County, Alabama": [36029, 36053, 36061, 36089],
  "Macon County, Alabama": [36031, 36039, 36075, 36083, 36087, 36088, 36866],
  "Lowndes County, Alabama": [36032, 36040, 36047, 36752, 36785],
  "Calhoun County, Alabama": [
    36201, 36202, 36203, 36204, 36205, 36206, 36207, 36210, 36250, 36253, 36254,
    36257, 36260, 36265, 36271, 36272, 36277, 36279,
  ],
  "Cleburne County, Alabama": [36261, 36262, 36264, 36269, 36273],
  "Randolph County, Alabama": [36263, 36274, 36276, 36278, 36280],
  "Houston County, Alabama": [
    36301, 36302, 36303, 36304, 36305, 36312, 36319, 36320, 36321, 36343, 36352,
    36370, 36376,
  ],
  "Henry County, Alabama": [36310, 36317, 36345, 36353, 36373],
  "Dale County, Alabama": [
    36311, 36322, 36350, 36360, 36361, 36362, 36371, 36374,
  ],
  "Geneva County, Alabama": [
    36313, 36314, 36316, 36318, 36340, 36344, 36349, 36375, 36477,
  ],
  "Coffee County, Alabama": [36323, 36330, 36331, 36346, 36351, 36453],
  "Conecuh County, Alabama": [36401, 36429, 36432, 36454, 36473, 36475],
  "Monroe County, Alabama": [
    36425, 36439, 36444, 36445, 36449, 36457, 36458, 36460, 36461, 36462, 36470,
    36471, 36480, 36481,
  ],
  "Escambia County, Alabama": [36426, 36427, 36441, 36502, 36503, 36504, 36543],
  "Wilcox County, Alabama": [
    36435, 36720, 36721, 36722, 36723, 36726, 36728, 36741, 36751, 36753, 36766,
    36768, 36769,
  ],
  "Clarke County, Alabama": [
    36436, 36446, 36451, 36482, 36501, 36515, 36524, 36540, 36545, 36727, 36762,
    36784,
  ],
  "Mobile County, Alabama": [
    36505, 36509, 36512, 36521, 36522, 36523, 36525, 36528, 36541, 36544, 36560,
    36568, 36571, 36572, 36575, 36582, 36587, 36590, 36601, 36602, 36603, 36604,
    36605, 36606, 36607, 36608, 36609, 36610, 36611, 36612, 36613, 36615, 36616,
    36617, 36618, 36619, 36621, 36622, 36625, 36628, 36630, 36633, 36640, 36641,
    36644, 36652, 36660, 36663, 36670, 36671, 36675, 36685, 36688, 36689, 36690,
    36691, 36693, 36695,
  ],
  "Baldwin County, Alabama": [
    36507, 36511, 36526, 36527, 36530, 36532, 36533, 36535, 36536, 36542, 36547,
    36549, 36550, 36551, 36555, 36559, 36561, 36562, 36564, 36567, 36574, 36576,
    36577, 36578, 36579, 36580,
  ],
  "Washington County, Alabama": [
    36513, 36518, 36529, 36538, 36539, 36548, 36553, 36556, 36558, 36569, 36581,
    36583, 36584, 36585,
  ],
  "Dallas County, Alabama": [
    36701, 36702, 36703, 36758, 36759, 36761, 36767, 36773, 36775,
  ],
  "Marengo County, Alabama": [
    36732, 36736, 36738, 36742, 36745, 36748, 36754, 36763, 36764, 36782, 36783,
  ],
  "Perry County, Alabama": [36756, 36786],
  "Lee County, Alabama": [
    36801, 36802, 36803, 36804, 36830, 36831, 36832, 36849, 36852, 36865, 36870,
    36874, 36877, 36879,
  ],
  "Russell County, Alabama": [
    36851, 36856, 36858, 36859, 36860, 36867, 36868, 36869, 36871, 36875,
  ],
  "Chambers County, Alabama": [36854, 36855, 36862, 36863, 36872],
  "Choctaw County, Alabama": [
    36904, 36908, 36910, 36912, 36913, 36915, 36916, 36919, 36921, 36922,
  ],
  "Montgomery County, Tennessee": [
    37010, 37040, 37041, 37042, 37043, 37044, 37052, 37142, 37171, 37191,
  ],
  "Davidson County, Tennessee": [
    37011, 37013, 37070, 37072, 37076, 37080, 37115, 37116, 37138, 37189, 37201,
    37202, 37203, 37204, 37205, 37206, 37207, 37208, 37209, 37210, 37211, 37212,
    37213, 37214, 37215, 37216, 37217, 37218, 37219, 37220, 37221, 37222, 37224,
    37227, 37228, 37229, 37230, 37232, 37234, 37235, 37236, 37237, 37238, 37240,
    37241, 37242, 37243, 37244, 37245, 37246, 37247, 37248, 37249, 37250,
  ],
  "DeKalb County, Tennessee": [37012, 37059, 37095, 37166],
  "Williamson County, Tennessee": [
    37014, 37024, 37027, 37046, 37062, 37064, 37065, 37067, 37068, 37069, 37135,
    37174, 37179, 38476,
  ],
  "Cheatham County, Tennessee": [37015, 37035, 37082, 37143, 37146],
  "Cannon County, Tennessee": [37016, 37026, 37190],
  "Coffee County, Tennessee": [
    37018, 37342, 37349, 37355, 37360, 37382, 37388, 37389,
  ],
  "Marshall County, Tennessee": [37019, 37034, 37047, 37091],
  "Bedford County, Tennessee": [37020, 37160, 37161, 37162, 37180, 37183],
  "Sumner County, Tennessee": [
    37022, 37031, 37048, 37066, 37075, 37077, 37119, 37148, 37186,
  ],
  "Stewart County, Tennessee": [37023, 37028, 37050, 37058, 37079],
  "Hickman County, Tennessee": [37025, 37033, 37098, 37137, 37140, 38454],
  "Dickson County, Tennessee": [
    37029, 37036, 37051, 37055, 37056, 37165, 37181, 37187,
  ],
  "Smith County, Tennessee": [
    37030, 37145, 37151, 38547, 38552, 38560, 38563, 38567, 38569,
  ],
  "Robertson County, Tennessee": [
    37032, 37049, 37073, 37141, 37152, 37172, 37188,
  ],
  "Rutherford County, Tennessee": [
    37037, 37060, 37063, 37085, 37086, 37089, 37118, 37127, 37128, 37129, 37130,
    37131, 37132, 37133, 37149, 37153, 37167,
  ],
  "Macon County, Tennessee": [37057, 37083, 37150],
  "Houston County, Tennessee": [37061, 37175, 37178],
  "Wilson County, Tennessee": [
    37071, 37087, 37088, 37090, 37121, 37122, 37136, 37184,
  ],
  "Trousdale County, Tennessee": [37074],
  "Humphreys County, Tennessee": [37078, 37101, 37134, 37185],
  "Perry County, Tennessee": [37096, 37097],
  "Warren County, Tennessee": [37110, 37111, 37357, 37378, 37394, 38550, 38581],
  "Lincoln County, Tennessee": [
    37144, 37328, 37334, 37335, 37348, 37359, 38453, 38459, 38488,
  ],
  "Grundy County, Tennessee": [
    37301, 37305, 37313, 37339, 37356, 37365, 37366, 37387,
  ],
  "Hamilton County, Tennessee": [
    37302, 37304, 37308, 37315, 37341, 37343, 37350, 37351, 37363, 37373, 37377,
    37379, 37384, 37401, 37402, 37403, 37404, 37405, 37406, 37407, 37408, 37409,
    37410, 37411, 37412, 37414, 37415, 37416, 37419, 37421, 37422, 37424, 37450,
  ],
  "McMinn County, Tennessee": [37303, 37309, 37329, 37331, 37370, 37826],
  "Franklin County, Tennessee": [
    37306, 37318, 37324, 37330, 37345, 37375, 37376, 37383, 37398,
  ],
  "Polk County, Tennessee": [
    37307, 37316, 37317, 37325, 37326, 37333, 37361, 37362, 37369, 37391,
  ],
  "Bradley County, Tennessee": [
    37310, 37311, 37312, 37320, 37323, 37336, 37353, 37364,
  ],
  "Monroe County, Tennessee": [37314, 37354, 37385, 37874, 37885],
  "Rhea County, Tennessee": [37321, 37332, 37337, 37338, 37381],
  "Meigs County, Tennessee": [37322, 37880],
  "Sequatchie County, Tennessee": [37327],
  "Marion County, Tennessee": [37340, 37347, 37374, 37380, 37396, 37397],
  "Moore County, Tennessee": [37352],
  "Bledsoe County, Tennessee": [37367],
  "Mcminn County, Tennessee": [37371],
  "Shelby County, Tennessee": [
    37501, 37544, 38002, 38014, 38016, 38017, 38018, 38027, 38029, 38053, 38054,
    38055, 38083, 38088, 38101, 38103, 38104, 38105, 38106, 38107, 38108, 38109,
    38110, 38111, 38112, 38113, 38114, 38115, 38116, 38117, 38118, 38119, 38120,
    38122, 38124, 38125, 38126, 38127, 38128, 38130, 38131, 38132, 38133, 38134,
    38135, 38136, 38137, 38138, 38139, 38141, 38142, 38145, 38147, 38148, 38150,
    38151, 38152, 38157, 38159, 38161, 38163, 38165, 38166, 38167, 38168, 38173,
    38174, 38175, 38177, 38181, 38182, 38183, 38184, 38186, 38187, 38188, 38190,
    38193, 38194, 38197,
  ],
  "Washington County, Tennessee": [
    37601, 37602, 37604, 37605, 37614, 37615, 37656, 37659, 37681, 37684, 37690,
  ],
  "Greene County, Tennessee": [
    37616, 37641, 37743, 37744, 37745, 37809, 37810, 37818,
  ],
  "Sullivan County, Tennessee": [
    37617, 37618, 37620, 37621, 37625, 37660, 37662, 37663, 37664, 37665, 37669,
    37686, 37699,
  ],
  "Johnson County, Tennessee": [37640, 37680, 37683, 37688, 37691],
  "Hawkins County, Tennessee": [
    37642, 37645, 37711, 37731, 37811, 37857, 37873,
  ],
  "Carter County, Tennessee": [37643, 37644, 37658, 37682, 37687, 37694],
  "Unicoi County, Tennessee": [37650, 37657, 37692],
  "Blount County, Tennessee": [
    37701, 37737, 37777, 37801, 37802, 37803, 37804, 37853, 37878, 37882, 37886,
  ],
  "Anderson County, Tennessee": [
    37705, 37710, 37716, 37717, 37754, 37769, 37828, 37830, 37831,
  ],
  "Claiborne County, Tennessee": [
    37707, 37715, 37724, 37730, 37752, 37773, 37824, 37825, 37851, 37867, 37870,
    37879,
  ],
  "Grainger County, Tennessee": [37708, 37709, 37848, 37861, 37881, 37888],
  "Cocke County, Tennessee": [37713, 37722, 37727, 37753, 37821, 37822, 37843],
  "Campbell County, Tennessee": [37714, 37729, 37757, 37762, 37766, 37819],
  "Morgan County, Tennessee": [
    37719, 37726, 37733, 37770, 37829, 37845, 37872, 37887,
  ],
  "Knox County, Tennessee": [
    37721, 37806, 37849, 37901, 37902, 37909, 37912, 37914, 37915, 37916, 37917,
    37918, 37919, 37920, 37921, 37922, 37923, 37924, 37927, 37928, 37929, 37930,
    37931, 37932, 37933, 37934, 37938, 37939, 37940, 37950, 37990, 37995, 37996,
    37997, 37998,
  ],
  "Cumberland County, Tennessee": [
    37723, 38555, 38557, 38558, 38571, 38572, 38578,
  ],
  "Jefferson County, Tennessee": [37725, 37760, 37820, 37871, 37890],
  "Scott County, Tennessee": [37732, 37755, 37756, 37841, 37847, 37852, 37892],
  "Sevier County, Tennessee": [
    37738, 37764, 37862, 37863, 37864, 37865, 37868, 37876,
  ],
  "Loudon County, Tennessee": [37742, 37771, 37772, 37774, 37846],
  "Roane County, Tennessee": [37748, 37763, 37840, 37854],
  "Hancock County, Tennessee": [37765, 37869],
  "Hamblen County, Tennessee": [
    37778, 37813, 37814, 37815, 37816, 37860, 37877, 37891,
  ],
  "Union County, Tennessee": [37779, 37807, 37866],
  "Crockett County, Tennessee": [
    38001, 38006, 38021, 38034, 38050, 38336, 38337,
  ],
  "Tipton County, Tennessee": [
    38004, 38011, 38015, 38019, 38023, 38049, 38058, 38071,
  ],
  "Dyer County, Tennessee": [
    38007, 38024, 38025, 38030, 38047, 38059, 38070, 38259,
  ],
  "Hardeman County, Tennessee": [
    38008, 38039, 38042, 38044, 38052, 38061, 38067, 38075, 38377, 38381,
  ],
  "Fayette County, Tennessee": [
    38010, 38028, 38036, 38045, 38046, 38048, 38057, 38060, 38066, 38068, 38076,
  ],
  "Haywood County, Tennessee": [38012, 38069],
  "Lauderdale County, Tennessee": [38037, 38040, 38041, 38063],
  "Lake County, Tennessee": [38077, 38079, 38080],
  "Carroll County, Tennessee": [
    38201, 38220, 38235, 38258, 38317, 38318, 38321, 38324, 38342, 38344, 38348,
    38387, 38390,
  ],
  "Benton County, Tennessee": [38221, 38320, 38333, 38341],
  "Henry County, Tennessee": [
    38222, 38223, 38224, 38231, 38236, 38242, 38251, 38256,
  ],
  "Weakley County, Tennessee": [
    38225, 38226, 38229, 38230, 38237, 38238, 38241, 38255,
  ],
  "undefined, Tennessee": [38227],
  "Obion County, Tennessee": [
    38232, 38240, 38253, 38254, 38257, 38260, 38261, 38271, 38281,
  ],
  "Gibson County, Tennessee": [
    38233, 38316, 38330, 38331, 38338, 38343, 38346, 38355, 38358, 38369, 38382,
    38389,
  ],
  "Madison County, Tennessee": [
    38301, 38302, 38303, 38305, 38308, 38313, 38314, 38356, 38362, 38366, 38378,
    38391, 38392,
  ],
  "McNairy County, Tennessee": [
    38310, 38315, 38334, 38339, 38357, 38359, 38367, 38375, 38379, 38393,
  ],
  "Decatur County, Tennessee": [38311, 38329, 38363, 38380],
  "Hardin County, Tennessee": [
    38326, 38327, 38361, 38365, 38370, 38372, 38376, 38475,
  ],
  "Henderson County, Tennessee": [
    38328, 38345, 38351, 38368, 38371, 38374, 38388,
  ],
  "Chester County, Tennessee": [38332, 38340, 38347, 38352],
  "Maury County, Tennessee": [38401, 38402, 38451, 38461, 38474, 38482, 38487],
  "Wayne County, Tennessee": [38425, 38450, 38452, 38463, 38471, 38485],
  "Giles County, Tennessee": [38449, 38455, 38460, 38472, 38473, 38477, 38478],
  "Lawrence County, Tennessee": [
    38456, 38457, 38464, 38468, 38469, 38481, 38483, 38486,
  ],
  "Lewis County, Tennessee": [38462],
  "Putnam County, Tennessee": [
    38501, 38502, 38503, 38505, 38506, 38544, 38548, 38574, 38582,
  ],
  "Fentress County, Tennessee": [38504, 38553, 38556, 38565, 38589],
  "Overton County, Tennessee": [
    38541, 38542, 38543, 38554, 38568, 38570, 38573, 38580,
  ],
  "Jackson County, Tennessee": [38545, 38562, 38564, 38588],
  "Pickett County, Tennessee": [38549, 38577],
  "Clay County, Tennessee": [38551, 38575],
  "White County, Tennessee": [38559, 38579, 38583, 38587],
  "Van Buren County, Tennessee": [38585],
  "Lafayette County, Mississippi": [38601, 38655, 38673, 38675, 38677, 38949],
  "Tate County, Mississippi": [38602, 38618, 38638, 38665, 38668],
  "Benton County, Mississippi": [38603, 38633, 38642, 38647],
  "Panola County, Mississippi": [38606, 38619, 38620, 38621, 38658, 38666],
  "Quitman County, Mississippi": [
    38609, 38622, 38623, 38628, 38643, 38646, 38670, 38964,
  ],
  "Tippah County, Mississippi": [38610, 38625, 38629, 38663, 38674, 38683],
  "Marshall County, Mississippi": [
    38611, 38634, 38635, 38649, 38659, 38661, 38679, 38685,
  ],
  "Coahoma County, Mississippi": [
    38614, 38617, 38630, 38631, 38639, 38644, 38645, 38669, 38720, 38739, 38767,
  ],
  "Tunica County, Mississippi": [38626, 38664, 38676],
  "Union County, Mississippi": [38627, 38650, 38652, 38828],
  "DeSoto County, Mississippi": [
    38632, 38637, 38641, 38651, 38654, 38671, 38672, 38680,
  ],
  "Desoto County, Mississippi": [38686],
  "Washington County, Mississippi": [
    38701, 38703, 38722, 38723, 38731, 38744, 38748, 38756, 38760, 38776, 38780,
    38782,
  ],
  "Bolivar County, Mississippi": [
    38702, 38725, 38726, 38730, 38732, 38733, 38740, 38746, 38759, 38762, 38764,
    38769, 38772, 38773, 38774, 38781,
  ],
  "Issaquena County, Mississippi": [38704, 38745, 39113, 39177],
  "Sharkey County, Mississippi": [38721, 38765, 39054, 39061, 39088, 39159],
  "Sunflower County, Mississippi": [
    38736, 38737, 38738, 38749, 38751, 38753, 38761, 38768, 38771, 38778,
  ],
  "Humphreys County, Mississippi": [38754, 39038, 39097, 39115, 39166],
  "Lee County, Mississippi": [
    38801, 38802, 38803, 38804, 38826, 38849, 38857, 38862, 38866, 38868, 38879,
  ],
  "Pontotoc County, Mississippi": [
    38820, 38841, 38863, 38864, 38869, 38871, 38874,
  ],
  "Monroe County, Mississippi": [
    38821, 38825, 38844, 38848, 38858, 38870, 39730, 39746,
  ],
  "Prentiss County, Mississippi": [38824, 38829, 38859, 38880],
  "Tishomingo County, Mississippi": [38827, 38833, 38838, 38852, 38873],
  "Alcorn County, Mississippi": [38834, 38835, 38846, 38865],
  "Calhoun County, Mississippi": [
    38839, 38878, 38913, 38914, 38915, 38916, 38951,
  ],
  "Itawamba County, Mississippi": [38843, 38847, 38855, 38856, 38876],
  "Chickasaw County, Mississippi": [38850, 38851, 38860, 38875, 38877, 39776],
  "Grenada County, Mississippi": [38901, 38902, 38926, 38929, 38940, 38960],
  "Carroll County, Mississippi": [38917, 38923, 38943, 38947, 39176],
  "Tallahatchie County, Mississippi": [
    38920, 38921, 38927, 38928, 38950, 38957, 38962, 38963, 38966,
  ],
  "Yalobusha County, Mississippi": [38922, 38948, 38953, 38961, 38965],
  "Holmes County, Mississippi": [38924, 39063, 39079, 39095, 39146, 39169],
  "Montgomery County, Mississippi": [38925, 38967, 39747],
  "Leflore County, Mississippi": [
    38930, 38935, 38941, 38944, 38945, 38946, 38952, 38954, 38958, 38959,
  ],
  "Webster County, Mississippi": [
    38955, 39737, 39744, 39750, 39751, 39752, 39767, 39771,
  ],
  "Yazoo County, Mississippi": [39039, 39040, 39162, 39173, 39179, 39194],
  "Hinds County, Mississippi": [
    39041, 39056, 39058, 39060, 39066, 39072, 39154, 39170, 39175, 39201, 39202,
    39203, 39204, 39205, 39206, 39207, 39209, 39210, 39211, 39212, 39213, 39215,
    39216, 39217, 39225, 39235, 39236, 39250, 39269, 39271, 39272, 39282, 39283,
    39284, 39286, 39289, 39296, 39298,
  ],
  "Rankin County, Mississippi": [
    39042, 39043, 39047, 39073, 39145, 39148, 39151, 39161, 39167, 39193, 39208,
    39218, 39232, 39288,
  ],
  "Simpson County, Mississippi": [39044, 39062, 39082, 39111, 39114, 39149],
  "Madison County, Mississippi": [
    39045, 39046, 39071, 39110, 39130, 39157, 39158, 39163, 39174,
  ],
  "Leake County, Mississippi": [39051, 39094, 39109, 39171, 39189],
  "Newton County, Mississippi": [
    39057, 39323, 39327, 39332, 39336, 39337, 39345,
  ],
  "Copiah County, Mississippi": [39059, 39077, 39078, 39083, 39191],
  "Attala County, Mississippi": [39067, 39090, 39107, 39108, 39160, 39192],
  "Jefferson County, Mississippi": [39069, 39081, 39668],
  "Scott County, Mississippi": [
    39074, 39080, 39087, 39092, 39098, 39117, 39359,
  ],
  "Claiborne County, Mississippi": [39086, 39096, 39144, 39150],
  "Smith County, Mississippi": [39116, 39152, 39153, 39168],
  "Covington County, Mississippi": [39119, 39428, 39479],
  "Adams County, Mississippi": [39120, 39121, 39122, 39165, 39190],
  "Lawrence County, Mississippi": [39140, 39641, 39654, 39663, 39665],
  "Warren County, Mississippi": [39156, 39180, 39181, 39182, 39183],
  "Lauderdale County, Mississippi": [
    39301, 39302, 39303, 39304, 39305, 39307, 39309, 39320, 39325, 39326, 39335,
    39342, 39364,
  ],
  "Wayne County, Mississippi": [39322, 39324, 39362, 39367],
  "Kemper County, Mississippi": [39328, 39352, 39354, 39358],
  "Clarke County, Mississippi": [39330, 39355, 39360, 39363],
  "Jasper County, Mississippi": [
    39338, 39347, 39348, 39356, 39366, 39422, 39439, 39460, 39481,
  ],
  "Winston County, Mississippi": [39339, 39346],
  "Noxubee County, Mississippi": [39341, 39361, 39739],
  "Neshoba County, Mississippi": [39350, 39365],
  "Forrest County, Mississippi": [
    39401, 39403, 39404, 39406, 39407, 39425, 39465,
  ],
  "Lamar County, Mississippi": [39402, 39455, 39475, 39482],
  "Jefferson Davis County, Mississippi": [39421, 39427, 39474, 39656],
  "Perry County, Mississippi": [39423, 39462, 39476],
  "Pearl River County, Mississippi": [39426, 39457, 39463, 39466, 39470],
  "Marion County, Mississippi": [39429, 39478, 39483, 39643],
  "Jones County, Mississippi": [
    39436, 39437, 39440, 39441, 39442, 39443, 39459, 39464, 39477, 39480,
  ],
  "Greene County, Mississippi": [39451, 39456, 39461],
  "George County, Mississippi": [39452],
  "Harrison County, Mississippi": [
    39501, 39502, 39503, 39505, 39506, 39507, 39530, 39531, 39532, 39533, 39534,
    39535, 39540, 39560, 39571, 39574,
  ],
  "Hancock County, Mississippi": [
    39520, 39521, 39522, 39525, 39529, 39556, 39558, 39572, 39576,
  ],
  "Jackson County, Mississippi": [
    39552, 39553, 39555, 39562, 39563, 39564, 39565, 39566, 39567, 39568, 39569,
    39581, 39595,
  ],
  "Stone County, Mississippi": [39561, 39573, 39577],
  "Lincoln County, Mississippi": [39601, 39602, 39603, 39629, 39662],
  "Franklin County, Mississippi": [39630, 39647, 39653, 39661],
  "Wilkinson County, Mississippi": [39631, 39633, 39669],
  "Pike County, Mississippi": [39632, 39635, 39648, 39649, 39652, 39657, 39666],
  "Amite County, Mississippi": [39638, 39645, 39664],
  "Walthall County, Mississippi": [39667],
  "Lowndes County, Mississippi": [
    39701, 39702, 39703, 39704, 39705, 39710, 39736, 39740, 39743, 39753, 39766,
  ],
  "Choctaw County, Mississippi": [39735, 39745, 39772],
  "Clay County, Mississippi": [39741, 39754, 39755, 39756, 39773],
  "Oktibbeha County, Mississippi": [39759, 39760, 39762, 39769],
  "Calhoun County, Georgia": [39813, 39846, 39862, 39866],
  "Decatur County, Georgia": [39815, 39817, 39818, 39819, 39825, 39834, 39852],
  "Early County, Georgia": [39823, 39832, 39841, 39861],
  "Clay County, Georgia": [39824, 39851, 39867],
  "Terrell County, Georgia": [39826, 39842, 39877, 39885],
  "Grady County, Georgia": [39827, 39828, 39829, 39897],
  "Randolph County, Georgia": [39836, 39840, 39886],
  "Miller County, Georgia": [39837],
  "Seminole County, Georgia": [39845, 39859],
  "Quitman County, Georgia": [39854],
  "Baker County, Georgia": [39870],
  "Shelby County, Kentucky": [40003, 40022, 40065, 40066, 40067, 40076],
  "Nelson County, Kentucky": [
    40004, 40008, 40012, 40013, 40020, 40048, 40051, 40052, 40107,
  ],
  "Trimble County, Kentucky": [40006, 40045],
  "Henry County, Kentucky": [
    40007, 40011, 40019, 40036, 40050, 40055, 40057, 40058, 40068, 40070, 40075,
  ],
  "Marion County, Kentucky": [
    40009, 40033, 40037, 40049, 40060, 40062, 40063, 40328,
  ],
  "Oldham County, Kentucky": [40010, 40014, 40026, 40031, 40032, 40056, 40077],
  "Jefferson County, Kentucky": [
    40018, 40023, 40025, 40027, 40041, 40059, 40118, 40201, 40202, 40203, 40204,
    40205, 40206, 40207, 40208, 40209, 40210, 40211, 40212, 40213, 40214, 40215,
    40216, 40217, 40218, 40219, 40220, 40221, 40222, 40223, 40224, 40225, 40228,
    40229, 40231, 40232, 40233, 40241, 40242, 40243, 40245, 40250, 40251, 40252,
    40253, 40255, 40256, 40257, 40258, 40259, 40261, 40266, 40268, 40269, 40270,
    40272, 40280, 40281, 40282, 40283, 40285, 40287, 40289, 40290, 40291, 40292,
    40293, 40294, 40295, 40296, 40297, 40298, 40299,
  ],
  "Washington County, Kentucky": [40040, 40061, 40069, 40078],
  "Spencer County, Kentucky": [40046, 40071],
  "Bullitt County, Kentucky": [40047, 40109, 40110, 40129, 40150, 40165],
  "Meade County, Kentucky": [40104, 40108, 40117, 40142, 40155, 40157, 40161],
  "Breckinridge County, Kentucky": [
    40111, 40115, 40140, 40143, 40144, 40145, 40146, 40152, 40153, 40170, 40171,
    40176, 40178,
  ],
  "Grayson County, Kentucky": [40119, 42712, 42721, 42726, 42754, 42755, 42762],
  "Hardin County, Kentucky": [
    40121, 40122, 40159, 40160, 40162, 40175, 40177, 42701, 42702, 42724, 42732,
    42740, 42776, 42784, 42788,
  ],
  "undefined, Kentucky": [40166, 40750, 41021, 41025],
  "Mercer County, Kentucky": [40310, 40330, 40372],
  "Nicholas County, Kentucky": [40311, 40350],
  "Powell County, Kentucky": [40312, 40376, 40380],
  "Rowan County, Kentucky": [40313, 40317, 40319, 40351],
  "Menifee County, Kentucky": [40316, 40322, 40346, 40387],
  "Scott County, Kentucky": [40324, 40370, 40379],
  "Bath County, Kentucky": [40334, 40358, 40360, 40366, 40371, 40374],
  "Estill County, Kentucky": [40336, 40472, 40495],
  "Montgomery County, Kentucky": [40337, 40353],
  "Jessamine County, Kentucky": [40339, 40340, 40356, 40390],
  "Anderson County, Kentucky": [40342],
  "Woodford County, Kentucky": [40347, 40383, 40384, 40386],
  "Bourbon County, Kentucky": [40348, 40357, 40361, 40362],
  "Owen County, Kentucky": [40355, 40359, 40363, 41098],
  "Madison County, Kentucky": [40385, 40403, 40404, 40475, 40476],
  "Clark County, Kentucky": [40391, 40392],
  "Jackson County, Kentucky": [40402, 40405, 40434, 40447, 40481, 40486, 40488],
  "Rockcastle County, Kentucky": [40409, 40445, 40456, 40460, 40473, 40492],
  "Garrard County, Kentucky": [40410, 40444, 40446, 40461],
  "Lincoln County, Kentucky": [40419, 40437, 40442, 40448, 40484, 40489],
  "Boyle County, Kentucky": [40422, 40423, 40440, 40452, 40464, 40468],
  "Fayette County, Kentucky": [
    40502, 40503, 40504, 40505, 40506, 40507, 40508, 40509, 40510, 40511, 40512,
    40513, 40514, 40515, 40516, 40517, 40522, 40523, 40524, 40526, 40533, 40536,
    40544, 40546, 40550, 40555, 40574, 40575, 40576, 40577, 40578, 40579, 40580,
    40581, 40582, 40583, 40588, 40591, 40598,
  ],
  "Franklin County, Kentucky": [
    40601, 40602, 40603, 40604, 40618, 40619, 40620, 40621, 40622,
  ],
  "Whitley County, Kentucky": [
    40701, 40702, 40730, 40754, 40759, 40763, 40769, 40940,
  ],
  "Laurel County, Kentucky": [
    40724, 40729, 40737, 40740, 40741, 40742, 40743, 40744, 40745, 40755,
  ],
  "Knox County, Kentucky": [
    40734, 40771, 40903, 40906, 40915, 40921, 40923, 40930, 40935, 40943, 40946,
    40949, 40953, 40982, 40995, 40997, 40999,
  ],
  "Harlan County, Kentucky": [
    40801, 40806, 40807, 40810, 40815, 40818, 40819, 40820, 40823, 40824, 40828,
    40829, 40830, 40831, 40843, 40847, 40849, 40854, 40855, 40863, 40865, 40870,
    40873, 40927, 40964,
  ],
  "Leslie County, Kentucky": [
    40803, 40808, 40816, 40827, 40840, 40844, 40858, 40868, 40874, 40979, 41714,
    41749, 41762, 41764, 41766, 41775, 41776, 41777,
  ],
  "Bell County, Kentucky": [
    40813, 40845, 40856, 40902, 40939, 40955, 40958, 40965, 40977, 40988,
  ],
  "Letcher County, Kentucky": [
    40826, 40862, 41517, 41537, 41804, 41810, 41812, 41815, 41819, 41821, 41824,
    41825, 41826, 41832, 41833, 41835, 41837, 41838, 41840, 41845, 41848, 41849,
    41855, 41858,
  ],
  "Clay County, Kentucky": [
    40913, 40914, 40931, 40932, 40941, 40944, 40951, 40962, 40972, 40983,
  ],
  "Perry County, Kentucky": [
    40981, 41367, 41701, 41702, 41712, 41713, 41719, 41721, 41722, 41723, 41727,
    41729, 41731, 41735, 41736, 41739, 41745, 41746, 41747, 41751, 41754, 41760,
    41763, 41773, 41774, 41778,
  ],
  "Campbell County, Kentucky": [
    41001, 41007, 41059, 41071, 41072, 41073, 41074, 41075, 41076, 41085, 41099,
  ],
  "Bracken County, Kentucky": [41002, 41004, 41043, 41044, 41061],
  "Harrison County, Kentucky": [41003, 41031],
  "Boone County, Kentucky": [
    41005, 41022, 41042, 41048, 41080, 41091, 41092, 41094,
  ],
  "Pendleton County, Kentucky": [41006, 41033, 41040],
  "Carroll County, Kentucky": [41008, 41045, 41083],
  "Grant County, Kentucky": [41010, 41030, 41035, 41052, 41054, 41097],
  "Kenton County, Kentucky": [
    41011, 41012, 41014, 41015, 41016, 41017, 41018, 41019, 41051, 41053, 41063,
  ],
  "Mason County, Kentucky": [41034, 41055, 41056, 41062, 41096],
  "Fleming County, Kentucky": [41037, 41039, 41041, 41049, 41065, 41081, 41093],
  "Gallatin County, Kentucky": [41046, 41086, 41095],
  "Robertson County, Kentucky": [41064],
  "Boyd County, Kentucky": [41101, 41102, 41105, 41114, 41129, 41168],
  "Greenup County, Kentucky": [41121, 41139, 41144, 41169, 41174, 41175, 41183],
  "Lawrence County, Kentucky": [
    41124, 41159, 41160, 41180, 41201, 41230, 41232, 41264,
  ],
  "Carter County, Kentucky": [
    41128, 41132, 41142, 41143, 41146, 41164, 41173, 41181,
  ],
  "Lewis County, Kentucky": [41135, 41141, 41166, 41179, 41189],
  "Elliott County, Kentucky": [41149, 41171],
  "Martin County, Kentucky": [41203, 41214, 41224, 41231, 41250, 41262, 41267],
  "Johnson County, Kentucky": [
    41204, 41219, 41222, 41226, 41234, 41238, 41240, 41254, 41255, 41256, 41257,
    41260, 41263, 41265, 41268, 41271, 41274,
  ],
  "Floyd County, Kentucky": [
    41216, 41601, 41602, 41603, 41604, 41605, 41606, 41607, 41612, 41615, 41616,
    41619, 41621, 41622, 41630, 41631, 41635, 41636, 41640, 41642, 41645, 41647,
    41649, 41650, 41651, 41653, 41655, 41659, 41660, 41663, 41666, 41667, 41669,
  ],
  "Wolfe County, Kentucky": [41301, 41313, 41332, 41360, 41365],
  "Lee County, Kentucky": [41307, 41311, 41333, 41347, 41362, 41368, 41397],
  "Breathitt County, Kentucky": [
    41310, 41317, 41339, 41348, 41366, 41385, 41390,
  ],
  "Owsley County, Kentucky": [41314, 41338, 41351, 41364, 41386],
  "Morgan County, Kentucky": [
    41352, 41408, 41413, 41421, 41425, 41451, 41459, 41472, 41477,
  ],
  "Magoffin County, Kentucky": [41426, 41433, 41464, 41465, 41632],
  "Pike County, Kentucky": [
    41501, 41502, 41503, 41512, 41513, 41514, 41519, 41520, 41522, 41524, 41526,
    41527, 41528, 41531, 41534, 41535, 41538, 41539, 41540, 41542, 41543, 41544,
    41547, 41548, 41549, 41553, 41554, 41555, 41557, 41558, 41559, 41560, 41561,
    41562, 41563, 41564, 41566, 41567, 41568, 41571, 41572,
  ],
  "Knott County, Kentucky": [
    41643, 41725, 41740, 41743, 41759, 41772, 41817, 41822, 41828, 41831, 41834,
    41836, 41839, 41843, 41844, 41847, 41859, 41861, 41862,
  ],
  "McCracken County, Kentucky": [42001, 42003, 42053, 42086],
  "Mccracken County, Kentucky": [42002],
  "Calloway County, Kentucky": [42020, 42036, 42049, 42054, 42071, 42076],
  "Carlisle County, Kentucky": [42021, 42023, 42035, 42070],
  "Ballard County, Kentucky": [42022, 42024, 42056, 42060, 42087],
  "Marshall County, Kentucky": [42025, 42029, 42044, 42048],
  "Graves County, Kentucky": [
    42027, 42039, 42040, 42051, 42061, 42063, 42066, 42069, 42079, 42082, 42085,
    42088,
  ],
  "Livingston County, Kentucky": [
    42028, 42045, 42047, 42058, 42078, 42081, 42083,
  ],
  "Hickman County, Kentucky": [42031, 42032],
  "Crittenden County, Kentucky": [42033, 42037, 42064, 42084],
  "Lyon County, Kentucky": [42038, 42055],
  "Fulton County, Kentucky": [42041, 42050],
  "Warren County, Kentucky": [
    42101, 42102, 42103, 42104, 42122, 42128, 42159, 42170, 42171, 42274,
  ],
  "Allen County, Kentucky": [42120, 42153, 42164],
  "Barren County, Kentucky": [
    42123, 42127, 42130, 42131, 42141, 42142, 42152, 42156, 42160,
  ],
  "Metcalfe County, Kentucky": [42124, 42129, 42154, 42166, 42214],
  "Monroe County, Kentucky": [42133, 42140, 42151, 42157, 42167],
  "Simpson County, Kentucky": [42134, 42135],
  "Edmonson County, Kentucky": [42163, 42207, 42210, 42259, 42285],
  "Butler County, Kentucky": [
    42201, 42219, 42252, 42261, 42273, 42275, 42287, 42288,
  ],
  "Logan County, Kentucky": [42202, 42206, 42256, 42265, 42276, 42283],
  "Todd County, Kentucky": [42204, 42216, 42220, 42221, 42234, 42280, 42286],
  "Trigg County, Kentucky": [42211],
  "Christian County, Kentucky": [
    42215, 42217, 42223, 42232, 42236, 42240, 42241, 42254, 42262, 42266,
  ],
  "Daviess County, Kentucky": [
    42301, 42302, 42303, 42304, 42355, 42356, 42366, 42375, 42376, 42377, 42378,
  ],
  "Ohio County, Kentucky": [
    42320, 42328, 42333, 42338, 42343, 42347, 42349, 42354, 42361, 42369, 42370,
  ],
  "Muhlenberg County, Kentucky": [
    42321, 42323, 42324, 42325, 42326, 42330, 42332, 42337, 42339, 42344, 42345,
    42367, 42374,
  ],
  "McLean County, Kentucky": [42322, 42327, 42350, 42352, 42371, 42372],
  "Henderson County, Kentucky": [
    42334, 42402, 42406, 42419, 42420, 42451, 42452, 42457, 42458,
  ],
  "Hancock County, Kentucky": [42348, 42351, 42364, 42368],
  "Webster County, Kentucky": [
    42403, 42404, 42409, 42444, 42450, 42455, 42456, 42463,
  ],
  "Hopkins County, Kentucky": [
    42408, 42410, 42413, 42431, 42436, 42440, 42441, 42442, 42453, 42464,
  ],
  "Caldwell County, Kentucky": [42411, 42445],
  "Union County, Kentucky": [42437, 42459, 42460, 42461, 42462],
  "Pulaski County, Kentucky": [
    42501, 42502, 42503, 42518, 42519, 42533, 42544, 42553, 42558, 42564, 42567,
  ],
  "Casey County, Kentucky": [42516, 42528, 42539, 42541, 42565, 42566],
  "Clinton County, Kentucky": [42602],
  "Wayne County, Kentucky": [42603, 42633],
  "Russell County, Kentucky": [42629, 42642],
  "McCreary County, Kentucky": [
    42631, 42634, 42635, 42638, 42647, 42649, 42653,
  ],
  "Hart County, Kentucky": [42713, 42722, 42729, 42746, 42749, 42757, 42765],
  "Adair County, Kentucky": [42715, 42720, 42728, 42741, 42742, 42753],
  "Larue County, Kentucky": [42716, 42748],
  "Cumberland County, Kentucky": [42717, 42731, 42759],
  "Taylor County, Kentucky": [42718, 42719, 42733, 42758],
  "Green County, Kentucky": [42743, 42764, 42782],
  "Licking County, Ohio": [
    43001, 43008, 43013, 43018, 43023, 43025, 43027, 43030, 43031, 43033, 43055,
    43056, 43058, 43062, 43071, 43073, 43080, 43093, 43098, 43721, 43740,
  ],
  "Franklin County, Ohio": [
    43002, 43004, 43016, 43017, 43026, 43054, 43068, 43069, 43081, 43085, 43086,
    43109, 43110, 43119, 43123, 43125, 43126, 43137, 43194, 43195, 43196, 43198,
    43199, 43201, 43202, 43203, 43204, 43205, 43206, 43207, 43209, 43210, 43211,
    43212, 43213, 43214, 43215, 43216, 43217, 43218, 43219, 43220, 43221, 43222,
    43223, 43224, 43226, 43227, 43228, 43229, 43230, 43231, 43232, 43234, 43235,
    43236, 43251, 43260, 43265, 43266, 43268, 43270, 43271, 43272, 43279, 43287,
    43291, 43299,
  ],
  "Delaware County, Ohio": [
    43003, 43015, 43021, 43032, 43035, 43061, 43065, 43066, 43074, 43082, 43240,
  ],
  "Knox County, Ohio": [
    43005, 43011, 43014, 43019, 43022, 43028, 43037, 43048, 43050,
  ],
  "Holmes County, Ohio": [
    43006, 43804, 44610, 44611, 44617, 44624, 44628, 44633, 44637, 44638, 44654,
    44660, 44661, 44687, 44690,
  ],
  "Union County, Ohio": [
    43007, 43036, 43040, 43041, 43045, 43064, 43067, 43077, 43344,
  ],
  "Champaign County, Ohio": [
    43009, 43044, 43047, 43060, 43070, 43072, 43078, 43083, 43084, 45389,
  ],
  "Clark County, Ohio": [
    43010, 45319, 45323, 45341, 45344, 45349, 45368, 45369, 45372, 45501, 45502,
    45503, 45504, 45505, 45506,
  ],
  "Madison County, Ohio": [43029, 43140, 43143, 43151, 43153, 43162],
  "Fairfield County, Ohio": [
    43046, 43102, 43105, 43107, 43112, 43130, 43136, 43147, 43148, 43150, 43154,
    43155, 43157, 43163,
  ],
  "Perry County, Ohio": [
    43076, 43730, 43731, 43739, 43748, 43760, 43761, 43764, 43766, 43782, 43783,
  ],
  "Ross County, Ohio": [
    43101, 43115, 45601, 45612, 45617, 45628, 45633, 45644, 45647, 45673, 45681,
  ],
  "Pickaway County, Ohio": [
    43103, 43113, 43116, 43117, 43145, 43146, 43156, 43164,
  ],
  "Fayette County, Ohio": [43106, 43128, 43142, 43160],
  "Hocking County, Ohio": [
    43111, 43127, 43135, 43138, 43144, 43149, 43152, 43158,
  ],
  "Marion County, Ohio": [
    43301, 43302, 43306, 43307, 43314, 43322, 43332, 43335, 43337, 43341, 43342,
    43356,
  ],
  "Logan County, Ohio": [
    43310, 43311, 43318, 43319, 43324, 43331, 43333, 43336, 43343, 43347, 43348,
    43357, 43358, 43360,
  ],
  "Morrow County, Ohio": [
    43315, 43317, 43320, 43321, 43325, 43334, 43338, 43349, 43350,
  ],
  "Wyandot County, Ohio": [
    43316, 43323, 43330, 43351, 43359, 44844, 44849, 44882,
  ],
  "Hardin County, Ohio": [
    43326, 43340, 43345, 43346, 45810, 45812, 45835, 45836, 45843, 45859,
  ],
  "Wood County, Ohio": [
    43402, 43403, 43406, 43413, 43414, 43437, 43441, 43443, 43447, 43450, 43451,
    43457, 43460, 43462, 43463, 43465, 43466, 43467, 43511, 43522, 43525, 43529,
    43541, 43551, 43552, 43565, 43569, 43619, 44817, 45872,
  ],
  "Sandusky County, Ohio": [
    43407, 43410, 43420, 43431, 43435, 43442, 43464, 43469, 44811,
  ],
  "Ottawa County, Ohio": [
    43408, 43416, 43430, 43432, 43433, 43436, 43439, 43440, 43445, 43446, 43449,
    43452, 43456, 43458, 43468,
  ],
  "Lucas County, Ohio": [
    43412, 43434, 43504, 43528, 43537, 43542, 43547, 43560, 43566, 43571, 43601,
    43603, 43604, 43605, 43606, 43607, 43608, 43609, 43610, 43611, 43612, 43613,
    43614, 43615, 43616, 43617, 43618, 43620, 43623, 43635, 43652, 43654, 43656,
    43657, 43659, 43660, 43661, 43666, 43667, 43681, 43682, 43697, 43699,
  ],
  "Erie County, Ohio": [
    43438, 44089, 44814, 44816, 44824, 44839, 44846, 44870, 44871,
  ],
  "Williams County, Ohio": [
    43501, 43505, 43506, 43517, 43518, 43531, 43543, 43554, 43557, 43570,
  ],
  "Fulton County, Ohio": [
    43502, 43515, 43521, 43533, 43540, 43553, 43558, 43567,
  ],
  "Henry County, Ohio": [
    43510, 43516, 43523, 43524, 43527, 43532, 43534, 43535, 43545, 43548, 43550,
    43555,
  ],
  "Defiance County, Ohio": [
    43512, 43519, 43520, 43526, 43530, 43536, 43549, 43556,
  ],
  "Muskingum County, Ohio": [
    43701, 43702, 43720, 43727, 43734, 43735, 43738, 43746, 43762, 43767, 43771,
    43777, 43791, 43802, 43821, 43822, 43830, 43842,
  ],
  "Noble County, Ohio": [43711, 43717, 43724, 43779, 43788, 45727],
  "Belmont County, Ohio": [
    43713, 43718, 43719, 43759, 43902, 43905, 43906, 43909, 43912, 43916, 43927,
    43928, 43933, 43934, 43935, 43937, 43940, 43942, 43947, 43950, 43951, 43967,
    43972, 43977, 43983, 43985,
  ],
  "Monroe County, Ohio": [
    43716, 43747, 43752, 43754, 43757, 43786, 43789, 43793, 43914, 43915, 43931,
    43946, 45734,
  ],
  "Guernsey County, Ohio": [
    43722, 43723, 43725, 43732, 43733, 43736, 43749, 43750, 43755, 43768, 43772,
    43773, 43778, 43780,
  ],
  "Morgan County, Ohio": [43728, 43756, 43758, 43787],
  "Coshocton County, Ohio": [
    43803, 43805, 43811, 43812, 43824, 43828, 43836, 43843, 43844, 43845,
  ],
  "Tuscarawas County, Ohio": [
    43832, 43837, 43840, 44612, 44621, 44622, 44629, 44653, 44656, 44663, 44671,
    44678, 44680, 44681, 44682, 44683, 44697,
  ],
  "Jefferson County, Ohio": [
    43901, 43903, 43908, 43910, 43913, 43917, 43925, 43926, 43930, 43932, 43938,
    43939, 43941, 43943, 43944, 43948, 43952, 43953, 43961, 43963, 43964, 43970,
    43971,
  ],
  "Harrison County, Ohio": [
    43907, 43973, 43974, 43976, 43981, 43984, 43986, 43988, 44679, 44693, 44695,
    44699,
  ],
  "Columbiana County, Ohio": [
    43920, 43945, 43962, 43968, 44408, 44413, 44415, 44423, 44427, 44431, 44432,
    44441, 44445, 44455, 44460, 44490, 44492, 44493, 44625, 44634, 44665,
  ],
  "Lorain County, Ohio": [
    44001, 44011, 44012, 44028, 44035, 44036, 44039, 44044, 44049, 44050, 44052,
    44053, 44054, 44055, 44074, 44090,
  ],
  "Ashtabula County, Ohio": [
    44003, 44004, 44005, 44010, 44030, 44032, 44041, 44047, 44048, 44068, 44076,
    44082, 44084, 44085, 44088, 44093, 44099,
  ],
  "Cuyahoga County, Ohio": [
    44017, 44022, 44040, 44070, 44101, 44102, 44103, 44104, 44105, 44106, 44107,
    44108, 44109, 44110, 44111, 44112, 44113, 44114, 44115, 44116, 44117, 44118,
    44119, 44120, 44121, 44122, 44123, 44124, 44125, 44126, 44127, 44128, 44129,
    44130, 44131, 44132, 44133, 44134, 44135, 44136, 44137, 44138, 44139, 44140,
    44141, 44142, 44143, 44144, 44145, 44146, 44147, 44149, 44178, 44181, 44185,
    44188, 44189, 44190, 44191, 44192, 44193, 44194, 44195, 44197, 44198, 44199,
  ],
  "Geauga County, Ohio": [
    44021, 44023, 44024, 44026, 44033, 44046, 44062, 44064, 44065, 44072, 44073,
    44080, 44086,
  ],
  "Lake County, Ohio": [
    44045, 44057, 44060, 44061, 44077, 44081, 44092, 44094, 44095, 44096, 44097,
  ],
  "Summit County, Ohio": [
    44056, 44067, 44087, 44203, 44210, 44216, 44221, 44222, 44223, 44224, 44232,
    44236, 44237, 44250, 44262, 44264, 44278, 44286, 44301, 44302, 44303, 44304,
    44305, 44306, 44307, 44308, 44309, 44310, 44311, 44312, 44313, 44314, 44315,
    44316, 44317, 44319, 44320, 44321, 44322, 44325, 44326, 44328, 44333, 44334,
    44372, 44393, 44396, 44398, 44399, 44685,
  ],
  "Portage County, Ohio": [
    44201, 44202, 44211, 44231, 44234, 44240, 44241, 44242, 44243, 44255, 44260,
    44265, 44266, 44272, 44285, 44288, 44411, 44412,
  ],
  "Medina County, Ohio": [
    44212, 44215, 44233, 44235, 44251, 44253, 44254, 44256, 44258, 44273, 44274,
    44275, 44280, 44281, 44282,
  ],
  "Wayne County, Ohio": [
    44214, 44217, 44230, 44270, 44276, 44287, 44606, 44618, 44627, 44636, 44645,
    44659, 44667, 44676, 44677, 44691,
  ],
  "Mahoning County, Ohio": [
    44401, 44405, 44406, 44416, 44422, 44429, 44436, 44442, 44443, 44449, 44451,
    44452, 44454, 44471, 44501, 44502, 44503, 44504, 44505, 44506, 44507, 44509,
    44510, 44511, 44512, 44513, 44514, 44515, 44555, 44609, 44619, 44672,
  ],
  "Trumbull County, Ohio": [
    44402, 44403, 44404, 44410, 44417, 44418, 44420, 44424, 44425, 44428, 44430,
    44437, 44438, 44439, 44440, 44444, 44446, 44450, 44453, 44470, 44473, 44481,
    44482, 44483, 44484, 44485, 44486, 44488, 44491,
  ],
  "Stark County, Ohio": [
    44601, 44608, 44613, 44614, 44626, 44630, 44632, 44640, 44641, 44643, 44646,
    44647, 44648, 44650, 44652, 44657, 44662, 44666, 44669, 44670, 44688, 44689,
    44701, 44702, 44703, 44704, 44705, 44706, 44707, 44708, 44709, 44710, 44711,
    44714, 44718, 44720, 44721, 44730, 44735, 44750, 44767, 44799,
  ],
  "Carroll County, Ohio": [
    44607, 44615, 44620, 44631, 44639, 44644, 44651, 44675,
  ],
  "Seneca County, Ohio": [
    44802, 44807, 44809, 44815, 44818, 44828, 44830, 44836, 44841, 44845, 44853,
    44861, 44867, 44883,
  ],
  "Hancock County, Ohio": [
    44804, 45814, 45816, 45839, 45840, 45841, 45858, 45867, 45868, 45881, 45889,
    45890, 45897,
  ],
  "Ashland County, Ohio": [
    44805, 44838, 44840, 44842, 44848, 44859, 44864, 44866, 44874, 44880,
  ],
  "Richland County, Ohio": [
    44813, 44822, 44843, 44862, 44875, 44878, 44901, 44902, 44903, 44904, 44905,
    44906, 44907, 44999,
  ],
  "Crawford County, Ohio": [
    44820, 44825, 44827, 44833, 44854, 44856, 44860, 44881, 44887,
  ],
  "Huron County, Ohio": [
    44826, 44837, 44847, 44850, 44851, 44855, 44857, 44865, 44888, 44889, 44890,
  ],
  "Hamilton County, Ohio": [
    45001, 45002, 45030, 45033, 45041, 45051, 45052, 45111, 45174, 45201, 45202,
    45203, 45204, 45205, 45206, 45207, 45208, 45209, 45211, 45212, 45213, 45214,
    45215, 45216, 45217, 45218, 45219, 45220, 45221, 45222, 45223, 45224, 45225,
    45226, 45227, 45228, 45229, 45230, 45231, 45232, 45233, 45234, 45235, 45236,
    45237, 45238, 45239, 45240, 45241, 45242, 45243, 45244, 45246, 45247, 45248,
    45249, 45250, 45251, 45252, 45253, 45254, 45255, 45258, 45262, 45263, 45264,
    45267, 45268, 45269, 45270, 45271, 45273, 45274, 45275, 45277, 45280, 45296,
    45298, 45299, 45999,
  ],
  "Preble County, Ohio": [
    45003, 45070, 45311, 45320, 45321, 45330, 45338, 45347, 45378, 45381, 45382,
  ],
  "Butler County, Ohio": [
    45004, 45011, 45012, 45013, 45014, 45015, 45018, 45025, 45026, 45042, 45043,
    45044, 45050, 45053, 45055, 45056, 45061, 45062, 45063, 45064, 45067, 45069,
    45071,
  ],
  "Warren County, Ohio": [
    45005, 45032, 45034, 45036, 45039, 45040, 45054, 45065, 45066, 45068, 45152,
    45162,
  ],
  "Brown County, Ohio": [
    45101, 45115, 45118, 45119, 45121, 45130, 45131, 45154, 45167, 45168, 45171,
  ],
  "Clermont County, Ohio": [
    45102, 45103, 45106, 45112, 45120, 45122, 45140, 45145, 45147, 45150, 45153,
    45156, 45157, 45158, 45160, 45176, 45245,
  ],
  "Adams County, Ohio": [
    45105, 45144, 45616, 45618, 45650, 45660, 45679, 45693, 45697,
  ],
  "Clinton County, Ohio": [
    45107, 45113, 45114, 45138, 45146, 45148, 45159, 45164, 45166, 45169, 45177,
  ],
  "Highland County, Ohio": [
    45110, 45123, 45132, 45133, 45135, 45142, 45155, 45172,
  ],
  "Greene County, Ohio": [
    45301, 45305, 45307, 45314, 45316, 45324, 45335, 45370, 45384, 45385, 45387,
    45430, 45431, 45432, 45433, 45434, 45435,
  ],
  "Shelby County, Ohio": [
    45302, 45306, 45333, 45334, 45336, 45340, 45353, 45360, 45363, 45365, 45367,
    45845,
  ],
  "Darke County, Ohio": [
    45303, 45304, 45308, 45328, 45331, 45332, 45346, 45348, 45350, 45351, 45352,
    45358, 45362, 45380, 45388, 45390,
  ],
  "Montgomery County, Ohio": [
    45309, 45315, 45322, 45325, 45327, 45342, 45343, 45345, 45354, 45377, 45400,
    45401, 45402, 45403, 45404, 45405, 45406, 45408, 45409, 45410, 45412, 45413,
    45414, 45415, 45416, 45417, 45418, 45419, 45420, 45422, 45423, 45424, 45426,
    45427, 45428, 45429, 45437, 45439, 45440, 45441, 45448, 45449, 45454, 45458,
    45459, 45463, 45469, 45470, 45475, 45479, 45481, 45482, 45490,
  ],
  "Mercer County, Ohio": [
    45310, 45822, 45826, 45828, 45846, 45860, 45862, 45866, 45882, 45883,
  ],
  "Miami County, Ohio": [
    45312, 45317, 45318, 45326, 45337, 45339, 45356, 45359, 45361, 45371, 45373,
    45374, 45383,
  ],
  "Pike County, Ohio": [45613, 45624, 45642, 45646, 45661, 45683, 45687, 45690],
  "Gallia County, Ohio": [
    45614, 45620, 45623, 45631, 45643, 45658, 45674, 45685, 45686,
  ],
  "Lawrence County, Ohio": [
    45619, 45638, 45645, 45659, 45669, 45675, 45678, 45680, 45688, 45696,
  ],
  "Jackson County, Ohio": [45621, 45640, 45656, 45692],
  "Vinton County, Ohio": [45622, 45634, 45651, 45654, 45672, 45695, 45698],
  "Scioto County, Ohio": [
    45629, 45630, 45636, 45648, 45652, 45653, 45657, 45662, 45663, 45671, 45677,
    45682, 45684, 45694, 45699,
  ],
  "Athens County, Ohio": [
    45701, 45710, 45711, 45716, 45717, 45719, 45723, 45732, 45735, 45739, 45740,
    45761, 45764, 45766, 45777, 45778, 45780, 45782,
  ],
  "Washington County, Ohio": [
    45712, 45713, 45714, 45715, 45721, 45724, 45729, 45742, 45744, 45745, 45746,
    45750, 45767, 45768, 45773, 45784, 45786, 45787, 45788, 45789,
  ],
  "Meigs County, Ohio": [
    45720, 45741, 45743, 45760, 45769, 45770, 45771, 45772, 45775, 45776, 45779,
    45783,
  ],
  "Allen County, Ohio": [
    45801, 45802, 45804, 45805, 45806, 45807, 45808, 45809, 45817, 45820, 45833,
    45850, 45854, 45887,
  ],
  "Paulding County, Ohio": [
    45813, 45821, 45849, 45851, 45855, 45861, 45873, 45879, 45880,
  ],
  "Putnam County, Ohio": [
    45815, 45827, 45830, 45831, 45837, 45844, 45848, 45853, 45856, 45864, 45875,
    45876, 45877, 45893,
  ],
  "Auglaize County, Ohio": [
    45819, 45865, 45869, 45870, 45871, 45884, 45885, 45888, 45895, 45896,
  ],
  "Van Wert County, Ohio": [
    45832, 45838, 45863, 45874, 45886, 45891, 45894, 45898, 45899,
  ],
  "Madison County, Indiana": [
    46001, 46011, 46012, 46013, 46014, 46015, 46016, 46017, 46018, 46036, 46044,
    46048, 46051, 46056, 46063, 46064, 46070,
  ],
  "Hamilton County, Indiana": [
    46030, 46031, 46032, 46033, 46034, 46037, 46038, 46040, 46060, 46061, 46062,
    46069, 46074, 46082, 46085, 46280, 46290,
  ],
  "Clinton County, Indiana": [
    46035, 46039, 46041, 46050, 46057, 46058, 46065, 46067,
  ],
  "Tipton County, Indiana": [46045, 46047, 46049, 46068, 46072, 46076],
  "Boone County, Indiana": [46052, 46071, 46075, 46077, 46102, 46147],
  "Hancock County, Indiana": [46055, 46117, 46129, 46140, 46154, 46163, 46186],
  "Hendricks County, Indiana": [
    46103, 46112, 46118, 46122, 46123, 46149, 46165, 46167, 46168, 46180,
  ],
  "Rush County, Indiana": [
    46104, 46115, 46127, 46146, 46150, 46155, 46156, 46173,
  ],
  "Putnam County, Indiana": [
    46105, 46120, 46121, 46128, 46135, 46170, 46171, 46172, 46175,
  ],
  "Johnson County, Indiana": [
    46106, 46124, 46131, 46142, 46143, 46162, 46181, 46184,
  ],
  "Marion County, Indiana": [
    46107, 46113, 46183, 46201, 46202, 46203, 46204, 46205, 46206, 46207, 46208,
    46209, 46211, 46214, 46216, 46217, 46218, 46219, 46220, 46221, 46222, 46223,
    46224, 46225, 46226, 46227, 46228, 46229, 46230, 46231, 46234, 46235, 46236,
    46237, 46239, 46240, 46241, 46242, 46244, 46247, 46249, 46250, 46251, 46253,
    46254, 46255, 46256, 46259, 46260, 46262, 46266, 46268, 46274, 46275, 46277,
    46278, 46282, 46283, 46285, 46291, 46295, 46296, 46298,
  ],
  "Shelby County, Indiana": [
    46110, 46126, 46130, 46144, 46161, 46176, 46182, 47234,
  ],
  "Morgan County, Indiana": [46111, 46125, 46151, 46157, 46158, 46166],
  "Fayette County, Indiana": [46133, 47322, 47331],
  "Henry County, Indiana": [
    46148, 47337, 47344, 47351, 47352, 47356, 47360, 47361, 47362, 47366, 47384,
    47385, 47386, 47387, 47388,
  ],
  "Brown County, Indiana": [46160, 46164, 47435, 47448],
  "undefined, Indiana": [46197, 46210, 46213, 46288],
  "Porter County, Indiana": [
    46301, 46302, 46304, 46341, 46347, 46368, 46383, 46384, 46385, 46393,
  ],
  "Lake County, Indiana": [
    46303, 46307, 46308, 46311, 46312, 46319, 46320, 46321, 46322, 46323, 46324,
    46325, 46327, 46342, 46355, 46356, 46373, 46375, 46376, 46377, 46394, 46401,
    46402, 46403, 46404, 46405, 46406, 46407, 46408, 46409, 46410, 46411,
  ],
  "Jasper County, Indiana": [46310, 46380, 46392, 47943, 47977, 47978],
  "LaPorte County, Indiana": [
    46340, 46345, 46346, 46348, 46350, 46360, 46365, 46371, 46382, 46390, 46391,
  ],
  "Newton County, Indiana": [
    46349, 46372, 46379, 46381, 47922, 47948, 47951, 47963, 47964,
  ],
  "La Porte County, Indiana": [46352, 46361],
  "Starke County, Indiana": [46366, 46374, 46531, 46532, 46534, 46968],
  "Marshall County, Indiana": [
    46501, 46504, 46506, 46511, 46513, 46537, 46563, 46570, 46572,
  ],
  "Kosciusko County, Indiana": [
    46502, 46508, 46510, 46524, 46538, 46539, 46542, 46555, 46562, 46567, 46580,
    46581, 46582, 46590, 46982,
  ],
  "Elkhart County, Indiana": [
    46507, 46514, 46515, 46516, 46517, 46526, 46527, 46528, 46540, 46543, 46550,
    46553, 46573,
  ],
  "St. Joseph County, Indiana": [
    46530, 46536, 46544, 46545, 46546, 46552, 46554, 46556, 46561, 46574, 46595,
    46601, 46604, 46613, 46614, 46615, 46616, 46617, 46619, 46620, 46624, 46626,
    46628, 46634, 46635, 46637, 46660, 46680, 46699,
  ],
  "LaGrange County, Indiana": [46565, 46571, 46746, 46761, 46771, 46786, 46795],
  "Noble County, Indiana": [
    46701, 46710, 46732, 46755, 46760, 46763, 46767, 46784, 46794, 46796,
  ],
  "Huntington County, Indiana": [46702, 46713, 46750, 46783, 46792],
  "Steuben County, Indiana": [46703, 46737, 46742, 46747, 46776, 46779],
  "Allen County, Indiana": [
    46704, 46741, 46743, 46745, 46748, 46765, 46773, 46774, 46788, 46797, 46798,
    46801, 46802, 46803, 46804, 46805, 46806, 46807, 46808, 46809, 46814, 46815,
    46816, 46818, 46819, 46825, 46835, 46845, 46850, 46851, 46852, 46853, 46854,
    46855, 46856, 46857, 46858, 46859, 46860, 46861, 46862, 46863, 46864, 46865,
    46866, 46867, 46868, 46869, 46885, 46895, 46896, 46897, 46898, 46899,
  ],
  "DeKalb County, Indiana": [46705, 46706, 46721, 46730, 46738, 46785, 46793],
  "Adams County, Indiana": [46711, 46733, 46740, 46769, 46772, 46780, 46782],
  "Wells County, Indiana": [
    46714, 46731, 46759, 46766, 46770, 46777, 46778, 46781, 46791, 46799,
  ],
  "Whitley County, Indiana": [46723, 46725, 46764, 46787],
  "Lagrange County, Indiana": [46789],
  "Howard County, Indiana": [
    46901, 46902, 46903, 46904, 46936, 46937, 46965, 46979, 46995,
  ],
  "Fulton County, Indiana": [
    46910, 46912, 46922, 46931, 46935, 46939, 46945, 46975,
  ],
  "Miami County, Indiana": [
    46911, 46914, 46919, 46921, 46926, 46951, 46958, 46959, 46970, 46971,
  ],
  "Carroll County, Indiana": [
    46913, 46915, 46916, 46917, 46920, 46923, 46929, 46977, 47997,
  ],
  "Grant County, Indiana": [
    46928, 46930, 46933, 46938, 46952, 46953, 46957, 46986, 46987, 46989, 46991,
  ],
  "Cass County, Indiana": [
    46932, 46942, 46947, 46950, 46961, 46967, 46978, 46988, 46994, 46998,
  ],
  "Wabash County, Indiana": [
    46940, 46941, 46943, 46946, 46962, 46974, 46980, 46984, 46990, 46992,
  ],
  "Pulaski County, Indiana": [46960, 46985, 46996, 47946, 47957],
  "Dearborn County, Indiana": [47001, 47018, 47022, 47025, 47032, 47060],
  "Union County, Indiana": [47003, 47325, 47353],
  "Ripley County, Indiana": [
    47006, 47017, 47021, 47023, 47031, 47033, 47034, 47037, 47039, 47041, 47042,
    47261,
  ],
  "Franklin County, Indiana": [47010, 47012, 47016, 47024, 47030, 47035, 47036],
  "Switzerland County, Indiana": [47011, 47019, 47020, 47038, 47043, 47224],
  "Ohio County, Indiana": [47040],
  "Scott County, Indiana": [47102, 47138, 47170, 47177],
  "Clark County, Indiana": [
    47104, 47106, 47111, 47126, 47129, 47130, 47131, 47132, 47133, 47134, 47141,
    47143, 47144, 47147, 47162, 47163, 47172, 47190, 47199,
  ],
  "Harrison County, Indiana": [
    47107, 47110, 47112, 47114, 47115, 47117, 47135, 47142, 47160, 47161, 47164,
    47166,
  ],
  "Washington County, Indiana": [47108, 47120, 47125, 47139, 47165, 47167],
  "Crawford County, Indiana": [
    47116, 47118, 47123, 47137, 47140, 47145, 47174, 47175,
  ],
  "Floyd County, Indiana": [47119, 47122, 47124, 47136, 47146, 47150, 47151],
  "Bartholomew County, Indiana": [
    47201, 47202, 47203, 47226, 47232, 47236, 47244, 47246, 47247, 47280,
  ],
  "Jackson County, Indiana": [
    47220, 47228, 47229, 47235, 47249, 47260, 47264, 47274, 47281,
  ],
  "Jennings County, Indiana": [47223, 47227, 47245, 47265, 47270, 47273, 47282],
  "Decatur County, Indiana": [47225, 47240, 47263, 47272, 47283],
  "Jefferson County, Indiana": [47230, 47231, 47243, 47250],
  "Delaware County, Indiana": [
    47302, 47303, 47304, 47305, 47306, 47307, 47308, 47320, 47334, 47338, 47342,
    47367, 47383, 47396,
  ],
  "Wayne County, Indiana": [
    47324, 47327, 47330, 47335, 47339, 47341, 47345, 47346, 47357, 47370, 47374,
    47375, 47392, 47393,
  ],
  "Jay County, Indiana": [47326, 47336, 47369, 47371, 47373, 47381],
  "Randolph County, Indiana": [
    47340, 47354, 47355, 47358, 47368, 47380, 47382, 47390, 47394,
  ],
  "Blackford County, Indiana": [47348, 47359],
  "Monroe County, Indiana": [
    47401, 47402, 47403, 47404, 47405, 47406, 47407, 47408, 47426, 47429, 47434,
    47458, 47463, 47464, 47468, 47490,
  ],
  "Lawrence County, Indiana": [
    47420, 47421, 47430, 47436, 47437, 47446, 47451, 47462, 47467, 47470,
  ],
  "Greene County, Indiana": [
    47424, 47438, 47439, 47441, 47443, 47445, 47449, 47453, 47457, 47459, 47465,
    47471,
  ],
  "Owen County, Indiana": [
    47427, 47431, 47433, 47455, 47456, 47460, 47833, 47868,
  ],
  "Orange County, Indiana": [47432, 47452, 47454, 47469],
  "Daviess County, Indiana": [47501, 47519, 47529, 47558, 47562, 47568],
  "Knox County, Indiana": [
    47512, 47516, 47524, 47528, 47535, 47557, 47561, 47573, 47578, 47591, 47596,
    47597,
  ],
  "Dubois County, Indiana": [
    47513, 47521, 47527, 47532, 47541, 47542, 47545, 47546, 47547, 47549, 47575,
    47580,
  ],
  "Perry County, Indiana": [
    47514, 47515, 47520, 47525, 47551, 47574, 47576, 47586, 47588,
  ],
  "Martin County, Indiana": [47522, 47553, 47581],
  "Spencer County, Indiana": [
    47523, 47531, 47536, 47537, 47550, 47552, 47556, 47577, 47579, 47611, 47615,
    47617, 47634, 47635,
  ],
  "Pike County, Indiana": [47564, 47567, 47584, 47585, 47590, 47598],
  "Warrick County, Indiana": [
    47601, 47610, 47613, 47614, 47619, 47629, 47630, 47637,
  ],
  "Posey County, Indiana": [47612, 47616, 47620, 47631, 47633, 47638],
  "Vanderburgh County, Indiana": [
    47618, 47701, 47702, 47703, 47704, 47705, 47706, 47708, 47710, 47711, 47712,
    47713, 47714, 47715, 47716, 47719, 47720, 47721, 47722, 47724, 47725, 47727,
    47728, 47730, 47731, 47732, 47733, 47734, 47735, 47736, 47737, 47739, 47740,
    47741, 47744, 47747, 47750,
  ],
  "Gibson County, Indiana": [
    47639, 47640, 47647, 47648, 47649, 47654, 47660, 47665, 47666, 47670, 47683,
  ],
  "Vigo County, Indiana": [
    47801, 47802, 47803, 47804, 47805, 47807, 47808, 47809, 47811, 47812, 47851,
    47863, 47866, 47869, 47870, 47871, 47876, 47878, 47880, 47885,
  ],
  "Parke County, Indiana": [
    47830, 47832, 47836, 47856, 47859, 47860, 47862, 47872, 47874,
  ],
  "Vermillion County, Indiana": [
    47831, 47842, 47847, 47854, 47875, 47884, 47928, 47966, 47974,
  ],
  "Clay County, Indiana": [
    47834, 47837, 47840, 47841, 47845, 47846, 47853, 47857, 47858, 47881,
  ],
  "Sullivan County, Indiana": [
    47838, 47848, 47849, 47850, 47852, 47855, 47861, 47864, 47865, 47879, 47882,
  ],
  "Tippecanoe County, Indiana": [
    47901, 47902, 47903, 47904, 47905, 47906, 47907, 47909, 47920, 47924, 47930,
    47941, 47962, 47981, 47983, 47992, 47996,
  ],
  "Montgomery County, Indiana": [
    47916, 47933, 47934, 47935, 47936, 47937, 47938, 47939, 47940, 47954, 47955,
    47965, 47967, 47968, 47989, 47990, 47994,
  ],
  "Benton County, Indiana": [
    47917, 47921, 47942, 47944, 47970, 47971, 47984, 47986,
  ],
  "Fountain County, Indiana": [
    47918, 47932, 47949, 47952, 47958, 47969, 47987, 47988,
  ],
  "White County, Indiana": [
    47923, 47925, 47926, 47929, 47950, 47959, 47960, 47980, 47995,
  ],
  "Warren County, Indiana": [47975, 47982, 47991, 47993],
  "St. Clair County, Michigan": [
    48001, 48002, 48004, 48006, 48014, 48022, 48023, 48027, 48028, 48032, 48039,
    48040, 48041, 48049, 48054, 48059, 48060, 48061, 48063, 48064, 48074, 48079,
    48097,
  ],
  "Lapeer County, Michigan": [
    48003, 48412, 48421, 48428, 48440, 48444, 48446, 48455, 48461, 48464, 48727,
  ],
  "Macomb County, Michigan": [
    48005, 48015, 48021, 48026, 48035, 48036, 48038, 48042, 48043, 48044, 48045,
    48046, 48047, 48048, 48050, 48051, 48062, 48065, 48066, 48080, 48081, 48082,
    48088, 48089, 48090, 48091, 48092, 48093, 48094, 48095, 48096, 48310, 48311,
    48312, 48313, 48314, 48315, 48316, 48317, 48318, 48397,
  ],
  "Oakland County, Michigan": [
    48007, 48009, 48012, 48017, 48025, 48030, 48033, 48034, 48037, 48067, 48068,
    48069, 48070, 48071, 48072, 48073, 48075, 48076, 48083, 48084, 48085, 48086,
    48098, 48099, 48165, 48167, 48178, 48220, 48237, 48301, 48302, 48303, 48304,
    48306, 48307, 48308, 48309, 48320, 48321, 48322, 48323, 48324, 48325, 48326,
    48327, 48328, 48329, 48330, 48331, 48332, 48333, 48334, 48335, 48336, 48340,
    48341, 48342, 48343, 48346, 48347, 48348, 48350, 48356, 48357, 48359, 48360,
    48361, 48362, 48363, 48366, 48367, 48370, 48371, 48374, 48375, 48376, 48377,
    48380, 48381, 48382, 48383, 48386, 48387, 48390, 48391, 48393, 48442, 48462,
  ],
  "Wayne County, Michigan": [
    48101, 48111, 48112, 48120, 48121, 48122, 48123, 48124, 48125, 48126, 48127,
    48128, 48134, 48135, 48136, 48138, 48141, 48146, 48150, 48151, 48152, 48153,
    48154, 48164, 48168, 48170, 48173, 48174, 48180, 48183, 48184, 48185, 48186,
    48187, 48188, 48192, 48193, 48195, 48201, 48202, 48203, 48204, 48205, 48206,
    48207, 48208, 48209, 48210, 48211, 48212, 48213, 48214, 48215, 48216, 48217,
    48218, 48219, 48221, 48222, 48223, 48224, 48225, 48226, 48227, 48228, 48229,
    48230, 48231, 48232, 48233, 48234, 48235, 48236, 48238, 48239, 48240, 48242,
    48243, 48244, 48255, 48260, 48264, 48265, 48266, 48267, 48268, 48269, 48272,
    48275, 48277, 48278, 48279, 48288,
  ],
  "Washtenaw County, Michigan": [
    48103, 48104, 48105, 48106, 48107, 48108, 48109, 48113, 48115, 48118, 48130,
    48158, 48160, 48175, 48176, 48189, 48190, 48191, 48197, 48198,
  ],
  "Monroe County, Michigan": [
    48110, 48117, 48131, 48133, 48140, 48144, 48145, 48157, 48159, 48161, 48162,
    48166, 48177, 48179, 48182, 49267, 49270,
  ],
  "Livingston County, Michigan": [
    48114, 48116, 48137, 48139, 48143, 48169, 48353, 48816, 48836, 48843, 48844,
    48855, 48863,
  ],
  "Sanilac County, Michigan": [
    48401, 48410, 48416, 48419, 48422, 48426, 48427, 48434, 48450, 48453, 48454,
    48456, 48465, 48466, 48469, 48471, 48472,
  ],
  "Genesee County, Michigan": [
    48411, 48420, 48423, 48430, 48433, 48436, 48437, 48438, 48439, 48451, 48457,
    48458, 48463, 48473, 48480, 48501, 48502, 48503, 48504, 48505, 48506, 48507,
    48509, 48519, 48529, 48531, 48532, 48550, 48551, 48552, 48553, 48554, 48555,
    48556, 48557, 48559,
  ],
  "Huron County, Michigan": [
    48413, 48432, 48441, 48445, 48467, 48468, 48470, 48475, 48720, 48725, 48731,
    48754, 48755, 48759,
  ],
  "Shiawassee County, Michigan": [
    48414, 48418, 48429, 48449, 48460, 48476, 48817, 48841, 48848, 48857, 48867,
    48872, 48882,
  ],
  "Saginaw County, Michigan": [
    48415, 48417, 48601, 48602, 48603, 48604, 48605, 48606, 48607, 48608, 48609,
    48614, 48616, 48623, 48626, 48637, 48638, 48649, 48655, 48663, 48722, 48724,
    48734, 48787,
  ],
  "Tuscola County, Michigan": [
    48435, 48701, 48723, 48726, 48729, 48733, 48735, 48736, 48741, 48744, 48746,
    48757, 48758, 48760, 48767, 48768, 48769,
  ],
  "Ogemaw County, Michigan": [48610, 48635, 48654, 48661, 48756],
  "Bay County, Michigan": [
    48611, 48613, 48631, 48634, 48650, 48706, 48707, 48708, 48710, 48732, 48747,
  ],
  "Gladwin County, Michigan": [48612, 48624, 48652],
  "Gratiot County, Michigan": [
    48615, 48662, 48801, 48802, 48806, 48807, 48830, 48832, 48847, 48856, 48862,
    48871, 48874, 48877, 48880, 48889,
  ],
  "Clare County, Michigan": [48617, 48622, 48625, 48632, 48633],
  "Midland County, Michigan": [
    48618, 48620, 48628, 48640, 48641, 48642, 48657, 48667, 48670, 48674, 48686,
  ],
  "Oscoda County, Michigan": [48619, 48621, 48636, 48647],
  "Roscommon County, Michigan": [48627, 48629, 48630, 48651, 48653, 48656],
  "Arenac County, Michigan": [48658, 48659, 48703, 48749, 48765, 48766],
  "Alcona County, Michigan": [
    48705, 48721, 48728, 48737, 48738, 48740, 48742, 48745, 48762,
  ],
  "Iosco County, Michigan": [
    48730, 48739, 48743, 48748, 48750, 48761, 48763, 48764, 48770,
  ],
  "Isabella County, Michigan": [
    48804, 48858, 48859, 48878, 48883, 48893, 48896, 49310,
  ],
  "Ingham County, Michigan": [
    48805, 48819, 48823, 48824, 48825, 48826, 48840, 48842, 48854, 48864, 48892,
    48895, 48901, 48906, 48908, 48909, 48910, 48911, 48912, 48913, 48915, 48916,
    48918, 48919, 48921, 48922, 48924, 48929, 48930, 48933, 48937, 48950, 48951,
    48956, 48980, 49251, 49264, 49285,
  ],
  "Clinton County, Michigan": [
    48808, 48820, 48822, 48831, 48833, 48835, 48853, 48866, 48879, 48894,
  ],
  "Ionia County, Michigan": [
    48809, 48815, 48845, 48846, 48849, 48851, 48860, 48865, 48870, 48873, 48875,
    48881, 48887,
  ],
  "Montcalm County, Michigan": [
    48811, 48812, 48818, 48829, 48834, 48838, 48850, 48852, 48884, 48885, 48886,
    48888, 48891, 49322, 49329, 49339, 49347,
  ],
  "Eaton County, Michigan": [
    48813, 48821, 48827, 48837, 48861, 48876, 48890, 48917, 49021, 49076, 49096,
  ],
  "Barry County, Michigan": [
    48897, 49035, 49046, 49050, 49058, 49060, 49073, 49325, 49333,
  ],
  "Kalamazoo County, Michigan": [
    49001, 49002, 49003, 49004, 49005, 49006, 49007, 49008, 49009, 49012, 49019,
    49024, 49034, 49041, 49048, 49052, 49053, 49074, 49077, 49081, 49083, 49087,
    49088, 49097,
  ],
  "Allegan County, Michigan": [
    49010, 49070, 49078, 49080, 49311, 49314, 49323, 49328, 49335, 49344, 49348,
    49406, 49408, 49416, 49419, 49450, 49453,
  ],
  "Calhoun County, Michigan": [
    49011, 49014, 49015, 49016, 49017, 49018, 49020, 49029, 49033, 49037, 49051,
    49068, 49069, 49092, 49224, 49245,
  ],
  "Van Buren County, Michigan": [
    49013, 49026, 49027, 49043, 49045, 49055, 49056, 49057, 49062, 49063, 49064,
    49065, 49071, 49079, 49090,
  ],
  "Berrien County, Michigan": [
    49022, 49023, 49038, 49039, 49084, 49085, 49098, 49101, 49102, 49103, 49104,
    49106, 49107, 49111, 49113, 49115, 49116, 49117, 49119, 49120, 49121, 49125,
    49126, 49127, 49128, 49129,
  ],
  "Branch County, Michigan": [49028, 49036, 49082, 49089, 49094, 49255],
  "St. Joseph County, Michigan": [
    49030, 49032, 49040, 49042, 49066, 49072, 49075, 49091, 49093, 49099,
  ],
  "Cass County, Michigan": [49031, 49047, 49061, 49067, 49095, 49112, 49130],
  "Jackson County, Michigan": [
    49201, 49202, 49203, 49204, 49230, 49234, 49237, 49240, 49241, 49246, 49254,
    49259, 49261, 49263, 49269, 49272, 49277, 49283, 49284,
  ],
  "Lenawee County, Michigan": [
    49220, 49221, 49228, 49229, 49235, 49236, 49238, 49247, 49248, 49253, 49256,
    49265, 49268, 49276, 49279, 49286, 49287, 49289,
  ],
  "Hillsdale County, Michigan": [
    49227, 49232, 49233, 49239, 49242, 49249, 49250, 49252, 49257, 49258, 49262,
    49266, 49271, 49274, 49281, 49282, 49288,
  ],
  "Kent County, Michigan": [
    49301, 49302, 49306, 49315, 49316, 49317, 49319, 49321, 49326, 49330, 49331,
    49341, 49343, 49345, 49351, 49355, 49356, 49357, 49418, 49468, 49501, 49502,
    49503, 49504, 49505, 49506, 49507, 49508, 49509, 49510, 49512, 49514, 49515,
    49516, 49518, 49519, 49523, 49525, 49528, 49530, 49534, 49544, 49546, 49548,
    49550, 49555, 49560, 49588, 49599,
  ],
  "Muskegon County, Michigan": [
    49303, 49318, 49415, 49425, 49437, 49440, 49441, 49442, 49443, 49444, 49445,
    49451, 49457, 49461, 49463,
  ],
  "Lake County, Michigan": [49304, 49623, 49642, 49644, 49656],
  "Mecosta County, Michigan": [
    49305, 49307, 49320, 49332, 49336, 49338, 49340, 49342, 49346,
  ],
  "Newaygo County, Michigan": [49309, 49312, 49327, 49337, 49349, 49412, 49413],
  "Ottawa County, Michigan": [
    49401, 49403, 49404, 49409, 49417, 49422, 49423, 49424, 49426, 49427, 49428,
    49429, 49430, 49434, 49435, 49448, 49456, 49460, 49464,
  ],
  "Mason County, Michigan": [49402, 49405, 49410, 49411, 49431, 49454, 49458],
  "Oceana County, Michigan": [
    49420, 49421, 49436, 49446, 49449, 49452, 49455, 49459,
  ],
  "Wexford County, Michigan": [49601, 49618, 49620, 49638, 49663, 49668],
  "Grand Traverse County, Michigan": [
    49610, 49637, 49643, 49649, 49666, 49673, 49684, 49685, 49686, 49690, 49696,
  ],
  "Antrim County, Michigan": [
    49611, 49612, 49615, 49622, 49627, 49629, 49648, 49659, 49729, 49730,
  ],
  "Manistee County, Michigan": [
    49613, 49614, 49619, 49625, 49626, 49634, 49645, 49660, 49675, 49689,
  ],
  "Benzie County, Michigan": [49616, 49617, 49628, 49635, 49640, 49650, 49683],
  "Leelanau County, Michigan": [
    49621, 49630, 49636, 49653, 49654, 49664, 49670, 49674, 49682,
  ],
  "Osceola County, Michigan": [49631, 49639, 49655, 49665, 49677, 49679, 49688],
  "Missaukee County, Michigan": [49632, 49651, 49657, 49667],
  "Kalkaska County, Michigan": [49633, 49646, 49676, 49680],
  "Emmet County, Michigan": [
    49701, 49706, 49716, 49718, 49722, 49723, 49737, 49740, 49755, 49764, 49769,
    49770,
  ],
  "Cheboygan County, Michigan": [
    49705, 49717, 49721, 49749, 49761, 49791, 49792, 49799,
  ],
  "Alpena County, Michigan": [49707, 49744, 49747, 49753, 49766],
  "Montmorency County, Michigan": [49709, 49746, 49756],
  "Chippewa County, Michigan": [
    49710, 49715, 49724, 49725, 49726, 49728, 49736, 49748, 49752, 49768, 49774,
    49780, 49783, 49784, 49785, 49786, 49788, 49790, 49793,
  ],
  "Charlevoix County, Michigan": [
    49711, 49712, 49713, 49720, 49727, 49782, 49796,
  ],
  "Mackinac County, Michigan": [
    49719, 49745, 49757, 49760, 49762, 49775, 49781, 49820, 49827, 49838,
  ],
  "Crawford County, Michigan": [49733, 49738, 49739],
  "Otsego County, Michigan": [49734, 49735, 49751, 49795, 49797],
  "Presque Isle County, Michigan": [49743, 49759, 49765, 49776, 49777, 49779],
  "Dickinson County, Michigan": [
    49801, 49802, 49815, 49831, 49834, 49852, 49870, 49876, 49877, 49881, 49892,
  ],
  "Keweenaw County, Michigan": [49805, 49901, 49918, 49950],
  "Alger County, Michigan": [
    49806, 49816, 49822, 49825, 49826, 49839, 49862, 49884, 49891, 49895,
  ],
  "Delta County, Michigan": [
    49807, 49818, 49829, 49835, 49837, 49864, 49872, 49878, 49880, 49894,
  ],
  "Marquette County, Michigan": [
    49808, 49814, 49819, 49833, 49841, 49849, 49855, 49861, 49865, 49866, 49871,
    49879, 49885,
  ],
  "Menominee County, Michigan": [
    49812, 49821, 49845, 49847, 49848, 49858, 49863, 49873, 49874, 49886, 49887,
    49893, 49896,
  ],
  "Schoolcraft County, Michigan": [49817, 49836, 49840, 49854, 49883],
  "Luce County, Michigan": [49853, 49868],
  "Iron County, Michigan": [49902, 49903, 49915, 49920, 49927, 49935, 49964],
  "Houghton County, Michigan": [
    49905, 49913, 49916, 49917, 49921, 49922, 49930, 49931, 49934, 49942, 49945,
    49952, 49955, 49958, 49961, 49963, 49965,
  ],
  "Baraga County, Michigan": [49908, 49919, 49946, 49962, 49970],
  "Ontonagon County, Michigan": [
    49910, 49912, 49925, 49929, 49948, 49953, 49960, 49967, 49971,
  ],
  "Gogebic County, Michigan": [49911, 49938, 49947, 49959, 49968, 49969],
  "Warren County, Iowa": [
    50001, 50047, 50061, 50118, 50125, 50139, 50145, 50160, 50166, 50210, 50211,
    50229, 50241,
  ],
  "Adair County, Iowa": [50002, 50837, 50846, 50849, 50858],
  "Dallas County, Iowa": [
    50003, 50038, 50039, 50063, 50066, 50069, 50070, 50146, 50167, 50220, 50233,
    50261, 50263, 50266, 50276, 50323,
  ],
  "Marshall County, Iowa": [
    50005, 50051, 50078, 50106, 50120, 50141, 50142, 50148, 50158, 50162, 50234,
    50239, 50247,
  ],
  "Hardin County, Iowa": [
    50006, 50043, 50102, 50122, 50126, 50206, 50230, 50258, 50259, 50269, 50601,
    50627, 50672,
  ],
  "Polk County, Iowa": [
    50007, 50009, 50021, 50023, 50032, 50035, 50073, 50109, 50111, 50131, 50169,
    50226, 50237, 50243, 50265, 50301, 50302, 50303, 50304, 50305, 50306, 50307,
    50308, 50309, 50310, 50311, 50312, 50313, 50314, 50315, 50316, 50317, 50318,
    50319, 50320, 50321, 50322, 50324, 50325, 50327, 50328, 50329, 50330, 50331,
    50332, 50333, 50334, 50335, 50336, 50339, 50340, 50347, 50359, 50360, 50361,
    50362, 50363, 50364, 50367, 50368, 50369, 50380, 50381, 50391, 50392, 50393,
    50394, 50395, 50396, 50397, 50398, 50936, 50940, 50947, 50950, 50980, 50981,
  ],
  "Wayne County, Iowa": [
    50008, 50052, 50060, 50123, 50147, 50165, 52583, 52590,
  ],
  "Story County, Iowa": [
    50010, 50011, 50012, 50013, 50014, 50046, 50055, 50056, 50105, 50124, 50134,
    50154, 50161, 50201, 50236, 50244, 50248, 50278,
  ],
  "Cass County, Iowa": [50020, 50022, 50274, 50843, 50853, 51535, 51544, 51552],
  "Audubon County, Iowa": [50025, 50042, 50076, 50110, 50117, 51543],
  "Guthrie County, Iowa": [
    50026, 50029, 50048, 50115, 50128, 50164, 50216, 50250, 50277,
  ],
  "Mahaska County, Iowa": [
    50027, 50143, 50207, 52534, 52543, 52561, 52577, 52586, 52595,
  ],
  "Jasper County, Iowa": [
    50028, 50054, 50127, 50135, 50137, 50153, 50168, 50170, 50208, 50228, 50232,
    50251,
  ],
  "Boone County, Iowa": [
    50031, 50036, 50037, 50040, 50099, 50152, 50156, 50212, 50223,
  ],
  "Madison County, Iowa": [
    50033, 50072, 50155, 50218, 50222, 50240, 50257, 50273,
  ],
  "Hamilton County, Iowa": [
    50034, 50075, 50130, 50132, 50231, 50246, 50249, 50271, 50595,
  ],
  "Franklin County, Iowa": [
    50041, 50227, 50420, 50427, 50431, 50441, 50452, 50475, 50633,
  ],
  "Marion County, Iowa": [
    50044, 50057, 50062, 50116, 50119, 50138, 50163, 50214, 50219, 50225, 50252,
    50256,
  ],
  "Lucas County, Iowa": [50049, 50068, 50151, 50238, 50272],
  "Greene County, Iowa": [
    50050, 50059, 50064, 50107, 50129, 50217, 50235, 51462,
  ],
  "Carroll County, Iowa": [
    50058, 51401, 51430, 51436, 51440, 51443, 51444, 51451, 51452, 51455, 51459,
    51463,
  ],
  "Decatur County, Iowa": [
    50065, 50067, 50103, 50108, 50140, 50144, 50262, 50264,
  ],
  "Wright County, Iowa": [
    50071, 50101, 50421, 50470, 50525, 50526, 50533, 50542, 50599,
  ],
  "Ringgold County, Iowa": [50074, 50133, 50835, 50845, 50854, 50860, 50863],
  "Keokuk County, Iowa": [
    50104, 50136, 50255, 50268, 52231, 52248, 52335, 52355, 52550, 52562, 52563,
    52568, 52576, 52585, 52591,
  ],
  "Poweshiek County, Iowa": [
    50112, 50157, 50171, 50242, 52211, 52221, 52222, 52232,
  ],
  "Union County, Iowa": [50149, 50254, 50801, 50830, 50831, 50842, 50861],
  "Monroe County, Iowa": [50150, 52531, 52569],
  "Tama County, Iowa": [
    50173, 50612, 50632, 50635, 50652, 50675, 52215, 52217, 52224, 52225, 52339,
    52342, 52348,
  ],
  "Clarke County, Iowa": [50174, 50213, 50275],
  "Cerro Gordo County, Iowa": [
    50401, 50402, 50428, 50433, 50457, 50464, 50467, 50469, 50477, 50479, 50482,
  ],
  "Hancock County, Iowa": [
    50423, 50430, 50432, 50438, 50439, 50447, 50449, 50484,
  ],
  "Winnebago County, Iowa": [50424, 50436, 50450, 50453, 50465, 50473, 50478],
  "Mitchell County, Iowa": [
    50426, 50454, 50455, 50460, 50461, 50466, 50472, 50476, 50481,
  ],
  "Worth County, Iowa": [50434, 50440, 50444, 50446, 50448, 50456, 50459],
  "Floyd County, Iowa": [50435, 50458, 50468, 50471, 50616, 50620, 50653],
  "Kossuth County, Iowa": [
    50451, 50480, 50483, 50511, 50517, 50522, 50539, 50556, 50559, 50560, 50590,
    50598,
  ],
  "Webster County, Iowa": [
    50501, 50516, 50518, 50521, 50523, 50524, 50530, 50532, 50543, 50544, 50557,
    50566, 50569, 50594,
  ],
  "Buena Vista County, Iowa": [
    50510, 50565, 50568, 50576, 50585, 50588, 50592, 51002, 51033,
  ],
  "Emmet County, Iowa": [50514, 50531, 50578, 51334, 51344, 51365],
  "Palo Alto County, Iowa": [
    50515, 50527, 50528, 50536, 50562, 50597, 51342, 51358,
  ],
  "Humboldt County, Iowa": [
    50519, 50520, 50529, 50541, 50545, 50548, 50558, 50570, 50577, 50582, 50591,
  ],
  "Sac County, Iowa": [
    50535, 50561, 50567, 50583, 51053, 51433, 51450, 51458, 51466,
  ],
  "Calhoun County, Iowa": [
    50538, 50551, 50552, 50563, 50575, 50579, 50586, 51449, 51453,
  ],
  "Pocahontas County, Iowa": [
    50540, 50546, 50554, 50571, 50573, 50574, 50581, 50593,
  ],
  "Butler County, Iowa": [
    50602, 50604, 50605, 50608, 50611, 50619, 50625, 50636, 50649, 50660, 50665,
    50670,
  ],
  "Chickasaw County, Iowa": [50603, 50630, 50645, 50658, 50659, 50661, 52154],
  "Fayette County, Iowa": [
    50606, 50655, 50662, 50664, 50681, 52135, 52141, 52142, 52147, 52164, 52166,
    52169, 52171, 52175,
  ],
  "Buchanan County, Iowa": [
    50607, 50629, 50641, 50644, 50648, 50650, 50671, 50682, 52210, 52326, 52329,
  ],
  "Grundy County, Iowa": [
    50609, 50621, 50624, 50638, 50642, 50657, 50669, 50673, 50680,
  ],
  "Black Hawk County, Iowa": [
    50613, 50614, 50623, 50626, 50634, 50643, 50651, 50667, 50701, 50702, 50703,
    50704, 50706, 50707,
  ],
  "Bremer County, Iowa": [
    50622, 50631, 50647, 50666, 50668, 50674, 50676, 50677,
  ],
  "Howard County, Iowa": [50628, 52134, 52136, 52155, 52163],
  "Delaware County, Iowa": [
    50654, 52035, 52036, 52038, 52041, 52050, 52057, 52223, 52237, 52330,
  ],
  "Taylor County, Iowa": [50833, 50836, 50840, 50848, 50851, 50862, 51646],
  "Adams County, Iowa": [50839, 50841, 50857, 50859],
  "Montgomery County, Iowa": [50847, 50864, 51532, 51566, 51573, 51591],
  "undefined, Iowa": [50982, 50983],
  "Plymouth County, Iowa": [
    51001, 51008, 51024, 51028, 51031, 51038, 51045, 51050, 51062,
  ],
  "Sioux County, Iowa": [
    51003, 51011, 51022, 51023, 51027, 51036, 51041, 51234, 51238, 51239, 51244,
    51247, 51250,
  ],
  "Woodbury County, Iowa": [
    51004, 51007, 51015, 51016, 51018, 51019, 51026, 51030, 51039, 51044, 51048,
    51052, 51054, 51055, 51056, 51101, 51102, 51103, 51104, 51105, 51106, 51108,
    51109, 51111,
  ],
  "Cherokee County, Iowa": [
    51005, 51012, 51014, 51029, 51035, 51037, 51049, 51061,
  ],
  "Ida County, Iowa": [51006, 51020, 51025, 51431, 51445],
  "O'Brien County, Iowa": [
    51009, 51046, 51058, 51201, 51231, 51245, 51248, 51346,
  ],
  "Monona County, Iowa": [
    51010, 51034, 51040, 51051, 51060, 51063, 51523, 51558, 51572,
  ],
  "Clay County, Iowa": [
    51047, 51301, 51333, 51338, 51340, 51341, 51343, 51357, 51366,
  ],
  "Lyon County, Iowa": [51230, 51235, 51237, 51240, 51241, 51242, 51243, 51246],
  "Osceola County, Iowa": [51232, 51249, 51345, 51350, 51354],
  "Dickinson County, Iowa": [51331, 51347, 51351, 51355, 51360, 51363, 51364],
  "Crawford County, Iowa": [
    51432, 51439, 51441, 51442, 51448, 51454, 51460, 51461, 51465, 51467, 51520,
    51528,
  ],
  "Shelby County, Iowa": [
    51446, 51447, 51527, 51530, 51531, 51537, 51562, 51565, 51570, 51578, 51593,
  ],
  "Pottawattamie County, Iowa": [
    51501, 51502, 51503, 51510, 51521, 51525, 51526, 51536, 51542, 51548, 51549,
    51553, 51559, 51560, 51575, 51576, 51577,
  ],
  "Harrison County, Iowa": [
    51529, 51545, 51546, 51550, 51555, 51556, 51557, 51563, 51564, 51579,
  ],
  "Mills County, Iowa": [
    51533, 51534, 51540, 51541, 51551, 51554, 51561, 51571,
  ],
  "Page County, Iowa": [
    51601, 51602, 51603, 51630, 51631, 51632, 51636, 51637, 51638, 51647, 51651,
    51656,
  ],
  "Fremont County, Iowa": [
    51639, 51640, 51645, 51648, 51649, 51650, 51652, 51653, 51654,
  ],
  "Dubuque County, Iowa": [
    52001, 52002, 52003, 52004, 52032, 52033, 52039, 52040, 52045, 52046, 52053,
    52056, 52065, 52068, 52073, 52078, 52099,
  ],
  "Jackson County, Iowa": [
    52030, 52031, 52054, 52060, 52064, 52069, 52070, 52071, 52074, 52075, 52079,
    52207, 52309,
  ],
  "Clinton County, Iowa": [
    52037, 52254, 52701, 52727, 52729, 52730, 52731, 52732, 52733, 52734, 52736,
    52742, 52750, 52751, 52757, 52771, 52774, 52777,
  ],
  "Clayton County, Iowa": [
    52042, 52043, 52044, 52047, 52048, 52049, 52052, 52066, 52072, 52076, 52077,
    52156, 52157, 52158, 52159,
  ],
  "Winneshiek County, Iowa": [
    52101, 52132, 52133, 52144, 52149, 52161, 52165, 52168,
  ],
  "Allamakee County, Iowa": [52140, 52146, 52151, 52160, 52162, 52170, 52172],
  "Washington County, Iowa": [
    52201, 52247, 52327, 52353, 52356, 52359, 52540, 52621,
  ],
  "Linn County, Iowa": [
    52202, 52213, 52214, 52218, 52219, 52227, 52228, 52233, 52253, 52302, 52314,
    52324, 52328, 52336, 52341, 52344, 52350, 52352, 52401, 52402, 52403, 52404,
    52405, 52406, 52407, 52408, 52409, 52410, 52411, 52497, 52498, 52499,
  ],
  "Iowa County, Iowa": [
    52203, 52204, 52220, 52236, 52251, 52301, 52307, 52308, 52316, 52325, 52334,
    52347, 52361,
  ],
  "Jones County, Iowa": [
    52205, 52212, 52252, 52305, 52310, 52312, 52320, 52321, 52323, 52362,
  ],
  "Benton County, Iowa": [
    52206, 52208, 52209, 52229, 52249, 52257, 52313, 52315, 52318, 52332, 52345,
    52346, 52349, 52351, 52354,
  ],
  "Cedar County, Iowa": [
    52216, 52255, 52306, 52337, 52358, 52721, 52747, 52772,
  ],
  "Johnson County, Iowa": [
    52235, 52240, 52241, 52242, 52243, 52244, 52245, 52246, 52317, 52319, 52322,
    52333, 52338, 52340, 52755,
  ],
  "Wapello County, Iowa": [52501, 52530, 52536, 52548, 52553, 52554, 52566],
  "Jefferson County, Iowa": [52533, 52556, 52557, 52567, 52580, 52635],
  "Van Buren County, Iowa": [
    52535, 52542, 52551, 52565, 52570, 52573, 52588, 52620, 52626, 52651,
  ],
  "Davis County, Iowa": [52537, 52538, 52552, 52560, 52584],
  "Appanoose County, Iowa": [
    52544, 52549, 52555, 52571, 52572, 52574, 52581, 52593, 52594,
  ],
  "Des Moines County, Iowa": [52601, 52623, 52637, 52638, 52650, 52655, 52660],
  "Lee County, Iowa": [
    52619, 52624, 52625, 52627, 52631, 52632, 52639, 52648, 52656, 52657, 52658,
  ],
  "Henry County, Iowa": [
    52630, 52641, 52642, 52644, 52645, 52647, 52649, 52652, 52654, 52659,
  ],
  "Louisa County, Iowa": [52640, 52646, 52653, 52737, 52738, 52752, 52754],
  "Muscatine County, Iowa": [
    52720, 52739, 52749, 52759, 52760, 52761, 52766, 52769, 52776, 52778,
  ],
  "Scott County, Iowa": [
    52722, 52726, 52728, 52745, 52746, 52748, 52753, 52756, 52758, 52765, 52767,
    52768, 52773, 52801, 52802, 52803, 52804, 52805, 52806, 52807, 52808, 52809,
  ],
  "Sheboygan County, Wisconsin": [
    53001, 53011, 53013, 53020, 53023, 53026, 53031, 53044, 53070, 53073, 53075,
    53081, 53082, 53083, 53085, 53093,
  ],
  "Washington County, Wisconsin": [
    53002, 53017, 53022, 53027, 53033, 53037, 53040, 53060, 53076, 53086, 53090,
    53095,
  ],
  "Dodge County, Wisconsin": [
    53003, 53006, 53016, 53032, 53034, 53035, 53039, 53047, 53048, 53050, 53059,
    53078, 53091, 53098, 53099, 53557, 53579, 53916, 53922, 53933, 53956, 53963,
  ],
  "Ozaukee County, Wisconsin": [
    53004, 53012, 53021, 53024, 53074, 53080, 53092, 53097,
  ],
  "Waukesha County, Wisconsin": [
    53005, 53007, 53008, 53018, 53029, 53045, 53046, 53051, 53052, 53056, 53058,
    53064, 53066, 53069, 53072, 53089, 53103, 53118, 53119, 53122, 53127, 53146,
    53149, 53150, 53151, 53153, 53183, 53186, 53187, 53188, 53189, 53702,
  ],
  "Fond du Lac County, Wisconsin": [
    53010, 53019, 53049, 53057, 53065, 53079, 53919, 53931, 54932, 54935, 54936,
    54937, 54971, 54974, 54979,
  ],
  "Calumet County, Wisconsin": [
    53014, 53061, 53062, 53088, 54110, 54123, 54129, 54160, 54169,
  ],
  "Manitowoc County, Wisconsin": [
    53015, 53042, 53063, 54207, 54214, 54215, 54220, 54221, 54227, 54228, 54230,
    54232, 54240, 54241, 54245, 54247,
  ],
  "Jefferson County, Wisconsin": [
    53036, 53038, 53094, 53137, 53156, 53178, 53538, 53549, 53551, 53594,
  ],
  "Kenosha County, Wisconsin": [
    53101, 53102, 53104, 53109, 53140, 53141, 53142, 53143, 53144, 53152, 53158,
    53159, 53168, 53170, 53171, 53179, 53181, 53192, 53194, 53199,
  ],
  "Racine County, Wisconsin": [
    53105, 53108, 53126, 53139, 53167, 53177, 53182, 53185, 53401, 53402, 53403,
    53404, 53405, 53406, 53407, 53408,
  ],
  "Milwaukee County, Wisconsin": [
    53110, 53129, 53130, 53132, 53154, 53172, 53201, 53202, 53203, 53204, 53205,
    53206, 53207, 53208, 53209, 53210, 53211, 53212, 53213, 53214, 53215, 53216,
    53217, 53218, 53219, 53220, 53221, 53222, 53223, 53224, 53225, 53226, 53227,
    53228, 53233, 53234, 53235, 53237, 53244, 53259, 53263, 53267, 53268, 53274,
    53278, 53288, 53290, 53293, 53295,
  ],
  "Walworth County, Wisconsin": [
    53114, 53115, 53120, 53121, 53125, 53128, 53138, 53147, 53148, 53157, 53176,
    53184, 53190, 53191, 53195, 53585,
  ],
  "Rock County, Wisconsin": [
    53501, 53505, 53511, 53512, 53525, 53534, 53536, 53537, 53542, 53545, 53546,
    53547, 53548, 53563, 53576,
  ],
  "Green County, Wisconsin": [53502, 53520, 53522, 53550, 53566, 53570, 53574],
  "Iowa County, Wisconsin": [
    53503, 53506, 53507, 53526, 53533, 53535, 53543, 53544, 53553, 53565, 53580,
    53582, 53595,
  ],
  "Lafayette County, Wisconsin": [
    53504, 53510, 53516, 53530, 53541, 53586, 53587, 53599, 53803,
  ],
  "Dane County, Wisconsin": [
    53508, 53515, 53517, 53521, 53523, 53527, 53528, 53529, 53531, 53532, 53558,
    53559, 53560, 53562, 53571, 53572, 53575, 53589, 53590, 53593, 53596, 53597,
    53598, 53701, 53703, 53704, 53705, 53706, 53707, 53708, 53711, 53713, 53714,
    53715, 53716, 53717, 53718, 53719, 53725, 53726, 53744, 53774, 53777, 53778,
    53779, 53782, 53783, 53784, 53785, 53786, 53788, 53789, 53790, 53791, 53792,
    53793, 53794,
  ],
  "Richland County, Wisconsin": [
    53518, 53540, 53556, 53581, 53584, 53924, 54664,
  ],
  "Grant County, Wisconsin": [
    53554, 53569, 53573, 53801, 53802, 53804, 53805, 53806, 53807, 53808, 53809,
    53810, 53811, 53812, 53813, 53816, 53817, 53818, 53820, 53824, 53825, 53827,
  ],
  "Columbia County, Wisconsin": [
    53555, 53901, 53911, 53923, 53925, 53928, 53932, 53935, 53954, 53955, 53957,
    53960, 53969,
  ],
  "Sauk County, Wisconsin": [
    53561, 53577, 53578, 53583, 53588, 53913, 53937, 53940, 53941, 53942, 53943,
    53951, 53958, 53959, 53961, 53965,
  ],
  "Crawford County, Wisconsin": [
    53821, 53826, 54626, 54628, 54631, 54640, 54645, 54654, 54655, 54657,
  ],
  "Adams County, Wisconsin": [53910, 53927, 53934, 53936, 53952, 54613],
  "Marquette County, Wisconsin": [53920, 53930, 53949, 53953, 53964, 54960],
  "Green Lake County, Wisconsin": [
    53926, 53939, 53946, 53947, 54923, 54941, 54968,
  ],
  "Juneau County, Wisconsin": [
    53929, 53944, 53948, 53950, 53962, 53968, 54618, 54637, 54646,
  ],
  "Polk County, Wisconsin": [
    54001, 54004, 54005, 54006, 54009, 54020, 54024, 54026, 54810, 54824, 54837,
    54853, 54858,
  ],
  "St. Croix County, Wisconsin": [
    54002, 54007, 54013, 54015, 54016, 54017, 54023, 54025, 54027, 54028, 54082,
  ],
  "Pierce County, Wisconsin": [
    54003, 54010, 54011, 54014, 54021, 54022, 54723, 54740, 54750, 54761, 54767,
  ],
  "Oconto County, Wisconsin": [
    54101, 54124, 54138, 54139, 54141, 54149, 54153, 54154, 54171, 54174, 54175,
  ],
  "Marinette County, Wisconsin": [
    54102, 54104, 54112, 54114, 54119, 54125, 54143, 54151, 54156, 54157, 54159,
    54161, 54177,
  ],
  "Forest County, Wisconsin": [54103, 54511, 54520, 54541, 54566],
  "Outagamie County, Wisconsin": [
    54106, 54113, 54130, 54131, 54136, 54140, 54152, 54165, 54170, 54911, 54912,
    54913, 54914, 54915, 54919, 54922, 54931, 54942, 54944,
  ],
  "Shawano County, Wisconsin": [
    54107, 54111, 54127, 54128, 54137, 54166, 54182, 54414, 54416, 54450, 54486,
    54499, 54928, 54948, 54978,
  ],
  "Brown County, Wisconsin": [
    54115, 54126, 54155, 54162, 54173, 54180, 54208, 54229, 54301, 54302, 54303,
    54304, 54305, 54306, 54307, 54308, 54311, 54313, 54324, 54344,
  ],
  "Florence County, Wisconsin": [54120, 54121, 54542],
  "Menominee County, Wisconsin": [54135, 54150],
  "Kewaunee County, Wisconsin": [54201, 54205, 54216, 54217],
  "Door County, Wisconsin": [
    54202, 54204, 54209, 54210, 54211, 54212, 54213, 54226, 54234, 54235, 54246,
  ],
  "Marathon County, Wisconsin": [
    54401, 54402, 54403, 54408, 54411, 54417, 54426, 54427, 54429, 54432, 54440,
    54448, 54455, 54471, 54474, 54476, 54479, 54484,
  ],
  "Wood County, Wisconsin": [
    54404, 54410, 54412, 54413, 54415, 54441, 54449, 54454, 54457, 54466, 54469,
    54472, 54475, 54489, 54494, 54495,
  ],
  "Clark County, Wisconsin": [
    54405, 54420, 54421, 54422, 54425, 54436, 54437, 54446, 54456, 54460, 54488,
    54493, 54498, 54746, 54771,
  ],
  "Portage County, Wisconsin": [
    54406, 54407, 54423, 54443, 54458, 54467, 54473, 54481, 54482, 54492, 54909,
    54921,
  ],
  "Langlade County, Wisconsin": [
    54409, 54418, 54424, 54428, 54430, 54462, 54464, 54465, 54485, 54491,
  ],
  "Taylor County, Wisconsin": [
    54433, 54434, 54439, 54447, 54451, 54470, 54480, 54490,
  ],
  "Lincoln County, Wisconsin": [54435, 54442, 54452, 54487, 54532],
  "Price County, Wisconsin": [
    54459, 54513, 54515, 54524, 54537, 54552, 54555, 54556,
  ],
  "Oneida County, Wisconsin": [
    54463, 54501, 54529, 54531, 54539, 54543, 54548, 54562, 54564,
  ],
  "Vilas County, Wisconsin": [
    54512, 54519, 54521, 54538, 54540, 54545, 54554, 54557, 54558, 54560, 54561,
    54568,
  ],
  "Ashland County, Wisconsin": [
    54514, 54517, 54527, 54546, 54806, 54846, 54850, 54855, 54861,
  ],
  "Iron County, Wisconsin": [54525, 54534, 54536, 54547, 54550, 54559, 54565],
  "Rusk County, Wisconsin": [
    54526, 54530, 54563, 54731, 54766, 54819, 54848, 54895,
  ],
  "La Crosse County, Wisconsin": [
    54601, 54602, 54603, 54614, 54636, 54644, 54650, 54653, 54669,
  ],
  "Buffalo County, Wisconsin": [54610, 54622, 54629, 54743, 54755, 54756],
  "Jackson County, Wisconsin": [
    54611, 54615, 54635, 54641, 54642, 54643, 54659, 54754,
  ],
  "Trempealeau County, Wisconsin": [
    54612, 54616, 54625, 54627, 54630, 54661, 54747, 54758, 54760, 54770, 54773,
  ],
  "Monroe County, Wisconsin": [
    54619, 54620, 54638, 54648, 54649, 54656, 54660, 54662, 54666, 54670,
  ],
  "Vernon County, Wisconsin": [
    54621, 54623, 54624, 54632, 54634, 54639, 54651, 54652, 54658, 54665, 54667,
  ],
  "Eau Claire County, Wisconsin": [
    54701, 54702, 54703, 54720, 54722, 54738, 54741, 54742,
  ],
  "Pepin County, Wisconsin": [54721, 54736, 54759, 54769],
  "Chippewa County, Wisconsin": [
    54724, 54726, 54727, 54729, 54732, 54745, 54748, 54757, 54768, 54774,
  ],
  "Dunn County, Wisconsin": [
    54725, 54730, 54734, 54735, 54737, 54739, 54749, 54751, 54763, 54764, 54765,
    54772,
  ],
  "Barron County, Wisconsin": [
    54728, 54733, 54762, 54805, 54812, 54813, 54818, 54822, 54826, 54829, 54841,
    54857, 54868, 54889,
  ],
  "Washburn County, Wisconsin": [
    54801, 54817, 54859, 54870, 54871, 54875, 54888,
  ],
  "Bayfield County, Wisconsin": [
    54814, 54816, 54821, 54827, 54832, 54839, 54844, 54847, 54856, 54865, 54891,
  ],
  "Douglas County, Wisconsin": [
    54820, 54836, 54838, 54842, 54849, 54854, 54864, 54873, 54874, 54880, 54890,
  ],
  "Sawyer County, Wisconsin": [
    54828, 54834, 54835, 54843, 54862, 54867, 54876, 54896,
  ],
  "Burnett County, Wisconsin": [54830, 54840, 54845, 54872, 54893],
  "Winnebago County, Wisconsin": [
    54901, 54902, 54903, 54904, 54906, 54927, 54934, 54947, 54952, 54956, 54957,
    54963, 54964, 54980, 54985, 54986,
  ],
  "Waupaca County, Wisconsin": [
    54926, 54929, 54933, 54940, 54945, 54946, 54949, 54950, 54961, 54962, 54969,
    54977, 54981, 54983, 54990,
  ],
  "Waushara County, Wisconsin": [
    54930, 54943, 54965, 54966, 54967, 54970, 54976, 54982, 54984,
  ],
  "Washington County, Minnesota": [
    55001, 55003, 55016, 55025, 55038, 55042, 55043, 55047, 55055, 55071, 55073,
    55082, 55083, 55090, 55115, 55125, 55128, 55129,
  ],
  "Chisago County, Minnesota": [
    55002, 55012, 55013, 55032, 55045, 55056, 55069, 55074, 55078, 55079, 55084,
    55092,
  ],
  "Anoka County, Minnesota": [
    55005, 55011, 55014, 55070, 55303, 55304, 55421, 55432, 55433, 55434, 55448,
    55449,
  ],
  "Isanti County, Minnesota": [55006, 55008, 55017, 55029, 55040, 55080],
  "Kanabec County, Minnesota": [55007, 55051, 56358],
  "Goodhue County, Minnesota": [
    55009, 55018, 55026, 55027, 55066, 55089, 55946, 55963, 55983, 55992,
  ],
  "Dakota County, Minnesota": [
    55010, 55024, 55031, 55033, 55044, 55065, 55068, 55075, 55076, 55077, 55085,
    55118, 55120, 55121, 55122, 55123, 55124, 55150, 55306, 55337,
  ],
  "Rice County, Minnesota": [
    55019, 55021, 55046, 55052, 55053, 55057, 55087, 55088,
  ],
  "Scott County, Minnesota": [
    55020, 55054, 55352, 55372, 55378, 55379, 56011, 56071,
  ],
  "Pine County, Minnesota": [
    55030, 55036, 55037, 55063, 55067, 55072, 55704, 55712, 55735, 55756, 55783,
    55795,
  ],
  "Wabasha County, Minnesota": [
    55041, 55932, 55945, 55956, 55957, 55964, 55968, 55981, 55991,
  ],
  "Steele County, Minnesota": [55049, 55060, 55917, 56026, 56046],
  "Ramsey County, Minnesota": [
    55101, 55102, 55103, 55104, 55105, 55106, 55107, 55108, 55109, 55110, 55112,
    55113, 55114, 55116, 55117, 55119, 55126, 55127, 55130, 55131, 55133, 55144,
    55145, 55146, 55155, 55161, 55164, 55165, 55166, 55168, 55169, 55170, 55171,
    55172, 55175, 55177, 55187, 55188, 55191,
  ],
  "Hennepin County, Minnesota": [
    55111, 55305, 55311, 55316, 55323, 55327, 55331, 55340, 55343, 55344, 55345,
    55346, 55347, 55348, 55356, 55357, 55359, 55361, 55364, 55369, 55374, 55375,
    55384, 55391, 55392, 55393, 55401, 55402, 55403, 55404, 55405, 55406, 55407,
    55408, 55409, 55410, 55411, 55412, 55413, 55414, 55415, 55416, 55417, 55418,
    55419, 55420, 55422, 55423, 55424, 55425, 55426, 55427, 55428, 55429, 55430,
    55431, 55435, 55436, 55437, 55438, 55439, 55440, 55441, 55442, 55443, 55444,
    55445, 55446, 55447, 55450, 55454, 55455, 55458, 55459, 55460, 55467, 55468,
    55470, 55472, 55473, 55474, 55478, 55479, 55480, 55483, 55484, 55485, 55486,
    55487, 55488, 55569, 55570, 55571, 55574, 55576, 55578, 55579, 55592, 55593,
    55595, 55596, 55597, 55598, 55599,
  ],
  "Wright County, Minnesota": [
    55301, 55302, 55313, 55320, 55321, 55328, 55341, 55349, 55358, 55362, 55363,
    55365, 55373, 55376, 55380, 55390, 55561, 55563, 55565, 55572, 55575, 55577,
    55580, 55581, 55582, 55584, 55585, 55586, 55587, 55588, 55589, 55590, 55591,
  ],
  "Sibley County, Minnesota": [55307, 55334, 55335, 55338, 55366, 55396, 56044],
  "Sherburne County, Minnesota": [
    55308, 55309, 55319, 55330, 55377, 55398, 56304,
  ],
  "Renville County, Minnesota": [
    55310, 55314, 55332, 55333, 55342, 56230, 56277, 56284, 56285,
  ],
  "McLeod County, Minnesota": [
    55312, 55336, 55350, 55354, 55370, 55381, 55385, 55395,
  ],
  "Carver County, Minnesota": [
    55315, 55317, 55318, 55322, 55339, 55360, 55367, 55368, 55383, 55386, 55387,
    55388, 55394, 55397, 55399, 55550, 55551, 55552, 55553, 55554, 55555, 55556,
    55557, 55558, 55559, 55560, 55562, 55564, 55566, 55567, 55568, 55573, 55583,
    55594,
  ],
  "Meeker County, Minnesota": [55324, 55325, 55329, 55355, 55389, 56228, 56243],
  "Stearns County, Minnesota": [
    55353, 55382, 56301, 56302, 56303, 56307, 56310, 56312, 56316, 56320, 56321,
    56325, 56331, 56335, 56340, 56352, 56356, 56362, 56368, 56369, 56371, 56372,
    56374, 56375, 56376, 56377, 56378, 56387, 56388, 56393, 56395, 56396, 56397,
    56398, 56399,
  ],
  "Mille Lacs County, Minnesota": [
    55371, 56313, 56330, 56342, 56353, 56359, 56363, 56386,
  ],
  "Lake County, Minnesota": [55601, 55603, 55607, 55609, 55614, 55616],
  "St. Louis County, Minnesota": [
    55602, 55702, 55703, 55705, 55706, 55708, 55710, 55711, 55713, 55717, 55719,
    55723, 55724, 55725, 55731, 55732, 55734, 55736, 55738, 55741, 55746, 55747,
    55750, 55751, 55758, 55763, 55765, 55766, 55768, 55771, 55777, 55779, 55781,
    55782, 55790, 55792, 55796, 55802, 55803, 55804, 55805, 55806, 55807, 55808,
    55810, 55811, 55812, 55814, 55815, 55816,
  ],
  "Cook County, Minnesota": [55604, 55605, 55606, 55612, 55613, 55615],
  "St Louis County, Minnesota": [55701, 55791, 55801],
  "Carlton County, Minnesota": [
    55707, 55718, 55720, 55726, 55733, 55749, 55757, 55767, 55780, 55797, 55798,
  ],
  "Itasca County, Minnesota": [
    55709, 55716, 55721, 55722, 55730, 55742, 55744, 55745, 55753, 55764, 55769,
    55775, 55784, 55786, 55793, 56628, 56631, 56636, 56637, 56639, 56657, 56659,
    56680, 56681, 56688,
  ],
  "Aitkin County, Minnesota": [
    55748, 55752, 55760, 55785, 55787, 56350, 56431, 56469,
  ],
  "Koochiching County, Minnesota": [
    55772, 56627, 56629, 56649, 56653, 56654, 56658, 56660, 56661, 56668, 56669,
    56679,
  ],
  "Olmsted County, Minnesota": [
    55901, 55902, 55903, 55904, 55905, 55906, 55920, 55929, 55934, 55960, 55976,
  ],
  "Mower County, Minnesota": [
    55909, 55912, 55918, 55926, 55933, 55936, 55950, 55951, 55953, 55967, 55970,
    55973, 55977, 55982,
  ],
  "Winona County, Minnesota": [
    55910, 55925, 55942, 55952, 55959, 55969, 55972, 55979, 55987, 55988,
  ],
  "Houston County, Minnesota": [
    55919, 55921, 55931, 55941, 55943, 55947, 55974,
  ],
  "Fillmore County, Minnesota": [
    55922, 55923, 55935, 55939, 55949, 55954, 55961, 55962, 55965, 55971, 55975,
    55990,
  ],
  "Dodge County, Minnesota": [55924, 55927, 55940, 55944, 55955, 55985],
  "Blue Earth County, Minnesota": [
    56001, 56002, 56006, 56010, 56024, 56034, 56037, 56055, 56063, 56065, 56078,
    56080, 56090,
  ],
  "Nicollet County, Minnesota": [56003, 56021, 56054, 56074, 56082],
  "Freeborn County, Minnesota": [
    56007, 56009, 56016, 56020, 56029, 56032, 56035, 56036, 56042, 56043, 56045,
    56089,
  ],
  "Faribault County, Minnesota": [
    56013, 56014, 56023, 56025, 56027, 56033, 56047, 56051, 56068, 56097, 56098,
  ],
  "Le Sueur County, Minnesota": [
    56017, 56028, 56050, 56052, 56057, 56058, 56069, 56096,
  ],
  "Brown County, Minnesota": [56019, 56030, 56041, 56073, 56084, 56085, 56087],
  "Watonwan County, Minnesota": [
    56022, 56056, 56060, 56062, 56081, 56120, 56160,
  ],
  "Martin County, Minnesota": [
    56031, 56039, 56075, 56088, 56121, 56127, 56162, 56171, 56176, 56181,
  ],
  "Waseca County, Minnesota": [56048, 56072, 56091, 56093],
  "Redwood County, Minnesota": [
    56083, 56152, 56166, 56180, 56214, 56224, 56255, 56263, 56266, 56270, 56283,
    56287, 56292, 56293, 56294,
  ],
  "Cottonwood County, Minnesota": [56101, 56118, 56145, 56159, 56174, 56183],
  "Nobles County, Minnesota": [
    56110, 56117, 56119, 56129, 56153, 56155, 56165, 56167, 56168, 56185, 56187,
  ],
  "Jackson County, Minnesota": [56111, 56137, 56143, 56150, 56161],
  "Lincoln County, Minnesota": [56113, 56136, 56142, 56149, 56178],
  "Murray County, Minnesota": [
    56114, 56122, 56123, 56125, 56131, 56141, 56151, 56172,
  ],
  "Lyon County, Minnesota": [
    56115, 56132, 56157, 56169, 56175, 56229, 56239, 56258, 56264, 56291,
  ],
  "Rock County, Minnesota": [
    56116, 56134, 56138, 56146, 56147, 56156, 56158, 56173,
  ],
  "Pipestone County, Minnesota": [
    56128, 56139, 56140, 56144, 56164, 56170, 56177, 56186,
  ],
  "Kandiyohi County, Minnesota": [
    56201, 56209, 56216, 56251, 56253, 56273, 56279, 56281, 56282, 56288, 56289,
  ],
  "Stevens County, Minnesota": [56207, 56221, 56235, 56244, 56267],
  "Swift County, Minnesota": [56208, 56215, 56226, 56231, 56249, 56252, 56271],
  "Big Stone County, Minnesota": [
    56210, 56211, 56225, 56227, 56240, 56276, 56278,
  ],
  "Lac qui Parle County, Minnesota": [56212, 56218, 56232, 56256, 56257],
  "Traverse County, Minnesota": [56219, 56236, 56296, 56583],
  "Yellow Medicine County, Minnesota": [
    56220, 56223, 56237, 56241, 56245, 56280, 56297,
  ],
  "Chippewa County, Minnesota": [56222, 56260, 56262, 56265, 56295],
  "Grant County, Minnesota": [56248, 56274, 56309, 56311, 56339, 56531, 56590],
  "Douglas County, Minnesota": [
    56308, 56315, 56319, 56326, 56327, 56332, 56341, 56343, 56354, 56355, 56360,
  ],
  "Morrison County, Minnesota": [
    56314, 56317, 56328, 56338, 56344, 56345, 56364, 56373, 56382, 56384, 56443,
    56466, 56475,
  ],
  "Todd County, Minnesota": [
    56318, 56336, 56347, 56389, 56434, 56437, 56438, 56440, 56446, 56453, 56479,
  ],
  "Pope County, Minnesota": [56323, 56334, 56349, 56381, 56385],
  "Otter Tail County, Minnesota": [
    56324, 56361, 56515, 56518, 56524, 56527, 56528, 56533, 56534, 56537, 56538,
    56551, 56567, 56571, 56572, 56573, 56576, 56586, 56587, 56588,
  ],
  "Benton County, Minnesota": [56329, 56333, 56357, 56367, 56379],
  "Crow Wing County, Minnesota": [
    56401, 56425, 56441, 56442, 56444, 56447, 56448, 56449, 56450, 56455, 56456,
    56459, 56465, 56468, 56472,
  ],
  "Cass County, Minnesota": [
    56430, 56435, 56452, 56473, 56474, 56484, 56626, 56633, 56641, 56655, 56662,
    56672,
  ],
  "Hubbard County, Minnesota": [56433, 56436, 56458, 56461, 56467, 56470],
  "Wadena County, Minnesota": [56464, 56477, 56478, 56481, 56482],
  "Becker County, Minnesota": [
    56501, 56502, 56511, 56521, 56544, 56554, 56569, 56570, 56575, 56577, 56578,
    56591, 56593,
  ],
  "Norman County, Minnesota": [
    56510, 56519, 56541, 56545, 56548, 56550, 56574, 56581, 56584,
  ],
  "Clay County, Minnesota": [
    56514, 56525, 56529, 56536, 56546, 56547, 56549, 56552, 56560, 56561, 56562,
    56563, 56580, 56585,
  ],
  "Mahnomen County, Minnesota": [56516, 56557, 56566, 56589, 56651],
  "Polk County, Minnesota": [
    56517, 56523, 56535, 56540, 56542, 56556, 56568, 56592, 56646, 56684, 56716,
    56721, 56722, 56723, 56736,
  ],
  "Wilkin County, Minnesota": [56520, 56522, 56543, 56553, 56565, 56579, 56594],
  "Beltrami County, Minnesota": [
    56601, 56619, 56630, 56647, 56650, 56663, 56666, 56667, 56670, 56671, 56678,
    56683, 56685, 56687,
  ],
  "Clearwater County, Minnesota": [56621, 56634, 56644, 56652, 56676],
  "Lake of the Woods County, Minnesota": [56623, 56686, 56711, 56741],
  "Roseau County, Minnesota": [
    56673, 56682, 56714, 56726, 56751, 56756, 56759, 56761, 56763,
  ],
  "Pennington County, Minnesota": [56701, 56725, 56754],
  "Marshall County, Minnesota": [
    56710, 56713, 56724, 56727, 56737, 56738, 56744, 56757, 56758, 56760, 56762,
  ],
  "Red Lake County, Minnesota": [56715, 56742, 56748, 56750],
  "Kittson County, Minnesota": [
    56720, 56728, 56729, 56731, 56732, 56733, 56734, 56735, 56740, 56755,
  ],
  "Union County, South Dakota": [57001, 57004, 57025, 57038, 57049],
  "Brookings County, South Dakota": [
    57002, 57006, 57007, 57026, 57061, 57071, 57220, 57276,
  ],
  "Minnehaha County, South Dakota": [
    57003, 57005, 57018, 57020, 57022, 57030, 57033, 57035, 57041, 57055, 57056,
    57068, 57101, 57103, 57104, 57105, 57106, 57107, 57109, 57110, 57117, 57118,
    57186, 57188, 57189, 57192, 57193, 57194, 57195, 57196, 57197, 57198,
  ],
  "Clay County, South Dakota": [57010, 57069, 57073],
  "McCook County, South Dakota": [57012, 57048, 57058, 57319, 57374],
  "Lincoln County, South Dakota": [
    57013, 57027, 57032, 57034, 57039, 57064, 57077, 57108,
  ],
  "Turner County, South Dakota": [
    57014, 57015, 57021, 57036, 57043, 57047, 57053, 57070,
  ],
  "Lake County, South Dakota": [
    57016, 57042, 57050, 57054, 57057, 57075, 57076,
  ],
  "Moody County, South Dakota": [57017, 57024, 57028, 57065],
  "Hutchinson County, South Dakota": [
    57029, 57045, 57052, 57331, 57354, 57366, 57376,
  ],
  "Yankton County, South Dakota": [
    57031, 57037, 57040, 57046, 57067, 57072, 57078, 57079,
  ],
  "Kingsbury County, South Dakota": [
    57051, 57212, 57214, 57231, 57233, 57249, 57353,
  ],
  "Bon Homme County, South Dakota": [57059, 57062, 57063, 57066, 57315],
  "Codington County, South Dakota": [57201, 57235, 57243, 57245, 57263, 57272],
  "Deuel County, South Dakota": [57213, 57218, 57226, 57237, 57238, 57268],
  "Grant County, South Dakota": [
    57216, 57246, 57251, 57252, 57253, 57256, 57259, 57264, 57265, 57269,
  ],
  "Clark County, South Dakota": [57217, 57225, 57236, 57258, 57271, 57278],
  "Day County, South Dakota": [57219, 57239, 57261, 57273, 57274, 57422, 57468],
  "Hamlin County, South Dakota": [57221, 57223, 57234, 57241, 57242, 57248],
  "Roberts County, South Dakota": [
    57224, 57227, 57255, 57257, 57260, 57262, 57266, 57279,
  ],
  "Marshall County, South Dakota": [57232, 57247, 57270, 57421, 57430, 57454],
  "Davison County, South Dakota": [57301, 57334, 57363],
  "Hanson County, South Dakota": [57311, 57332, 57340],
  "Jerauld County, South Dakota": [57312, 57358, 57382],
  "Douglas County, South Dakota": [57313, 57328, 57330, 57344, 57364],
  "Sanborn County, South Dakota": [57314, 57359, 57385],
  "Gregory County, South Dakota": [
    57317, 57335, 57523, 57529, 57533, 57538, 57571,
  ],
  "Miner County, South Dakota": [57321, 57323, 57337, 57349],
  "Beadle County, South Dakota": [
    57322, 57324, 57348, 57350, 57379, 57381, 57384, 57386, 57399,
  ],
  "Brule County, South Dakota": [57325, 57326, 57355, 57370],
  "Charles Mix County, South Dakota": [
    57329, 57342, 57356, 57361, 57367, 57369, 57380,
  ],
  "Buffalo County, South Dakota": [57339, 57341],
  "Hyde County, South Dakota": [57345, 57346, 57540],
  "Hand County, South Dakota": [57362, 57371, 57373, 57467],
  "Lyman County, South Dakota": [57365, 57544, 57548, 57568, 57569, 57576],
  "Aurora County, South Dakota": [57368, 57375, 57383],
  "Brown County, South Dakota": [
    57401, 57402, 57426, 57427, 57432, 57433, 57439, 57441, 57445, 57446, 57449,
    57460, 57474, 57479, 57481,
  ],
  "Walworth County, South Dakota": [57420, 57452, 57472, 57601, 57631],
  "Spink County, South Dakota": [
    57424, 57429, 57434, 57436, 57440, 57461, 57465, 57469, 57476, 57477,
  ],
  "Edmunds County, South Dakota": [57428, 57448, 57451, 57471],
  "Faulk County, South Dakota": [57435, 57438, 57466, 57470, 57473],
  "McPherson County, South Dakota": [57437, 57456, 57457],
  "Potter County, South Dakota": [57442, 57450, 57455, 57475],
  "Hughes County, South Dakota": [57501, 57522, 57536],
  "Sully County, South Dakota": [57520, 57564],
  "Jackson County, South Dakota": [57521, 57543, 57547, 57577, 57750],
  "Tripp County, South Dakota": [57528, 57534, 57541, 57580, 57584],
  "Jones County, South Dakota": [57531, 57559, 57562],
  "Stanley County, South Dakota": [57532, 57537],
  "Bennett County, South Dakota": [57551, 57574, 57714],
  "Haakon County, South Dakota": [57552, 57553, 57567],
  "Todd County, South Dakota": [57555, 57563, 57566, 57570, 57572],
  "Mellette County, South Dakota": [57560, 57579, 57585],
  "Perkins County, South Dakota": [57620, 57638, 57640, 57644, 57649],
  "Corson County, South Dakota": [
    57621, 57634, 57639, 57641, 57642, 57645, 57657, 57658, 57659, 57660,
  ],
  "Ziebach County, South Dakota": [57622, 57623, 57748],
  "Dewey County, South Dakota": [
    57625, 57630, 57633, 57636, 57652, 57656, 57661,
  ],
  "Meade County, South Dakota": [
    57626, 57706, 57718, 57737, 57741, 57758, 57769, 57785, 57787, 57792,
  ],
  "Campbell County, South Dakota": [57632, 57646, 57648],
  "Harding County, South Dakota": [57650, 57651, 57720, 57724, 57755, 57776],
  "Pennington County, South Dakota": [
    57701, 57702, 57703, 57709, 57719, 57725, 57745, 57751, 57761, 57767, 57775,
    57780, 57790, 57791,
  ],
  "Oglala Lakota County, South Dakota": [
    57716, 57752, 57756, 57764, 57770, 57772, 57794,
  ],
  "Butte County, South Dakota": [57717, 57760, 57762, 57788],
  "Custer County, South Dakota": [57722, 57730, 57738, 57744, 57773],
  "Lawrence County, South Dakota": [
    57732, 57754, 57759, 57779, 57783, 57793, 57799,
  ],
  "Fall River County, South Dakota": [57735, 57747, 57763, 57766, 57782],
  "Richland County, North Dakota": [
    58001, 58008, 58015, 58018, 58030, 58041, 58053, 58057, 58058, 58061, 58074,
    58075, 58076, 58077, 58081,
  ],
  "Cass County, North Dakota": [
    58002, 58004, 58005, 58006, 58007, 58011, 58012, 58021, 58029, 58036, 58038,
    58042, 58047, 58048, 58051, 58052, 58059, 58064, 58071, 58078, 58079, 58102,
    58103, 58104, 58105, 58106, 58107, 58108, 58109, 58121, 58122, 58124, 58125,
    58126,
  ],
  "Traill County, North Dakota": [
    58009, 58016, 58035, 58045, 58218, 58219, 58223, 58240, 58257, 58274,
  ],
  "Sargent County, North Dakota": [
    58013, 58017, 58032, 58040, 58043, 58060, 58067, 58069,
  ],
  "Ransom County, North Dakota": [58027, 58033, 58054, 58068],
  "Barnes County, North Dakota": [
    58031, 58049, 58056, 58062, 58063, 58065, 58072, 58429, 58461, 58479, 58480,
    58481, 58492,
  ],
  "Steele County, North Dakota": [58046, 58230, 58277],
  "Grand Forks County, North Dakota": [
    58201, 58202, 58203, 58204, 58205, 58206, 58207, 58208, 58214, 58228, 58235,
    58244, 58251, 58256, 58258, 58266, 58267, 58275, 58278,
  ],
  "Walsh County, North Dakota": [
    58210, 58227, 58229, 58231, 58233, 58237, 58243, 58250, 58261, 58270, 58273,
  ],
  "Nelson County, North Dakota": [
    58212, 58224, 58254, 58259, 58272, 58344, 58361, 58380,
  ],
  "Pembina County, North Dakota": [
    58216, 58220, 58222, 58225, 58236, 58238, 58241, 58262, 58265, 58271, 58276,
    58282,
  ],
  "Cavalier County, North Dakota": [
    58239, 58249, 58255, 58260, 58269, 58281, 58311, 58323, 58352, 58355, 58372,
  ],
  "Ramsey County, North Dakota": [
    58301, 58321, 58327, 58330, 58338, 58345, 58362, 58377, 58382,
  ],
  "Rolette County, North Dakota": [
    58310, 58316, 58329, 58353, 58366, 58367, 58369,
  ],
  "Pierce County, North Dakota": [58313, 58368, 58385],
  "Towner County, North Dakota": [58317, 58324, 58331, 58339, 58363, 58365],
  "Bottineau County, North Dakota": [
    58318, 58384, 58711, 58748, 58750, 58760, 58762, 58783, 58793,
  ],
  "Wells County, North Dakota": [
    58319, 58341, 58418, 58422, 58423, 58438, 58451, 58486,
  ],
  "Benson County, North Dakota": [
    58325, 58332, 58335, 58343, 58346, 58348, 58351, 58357, 58370, 58379, 58381,
    58386,
  ],
  "Eddy County, North Dakota": [58356, 58374],
  "Stutsman County, North Dakota": [
    58401, 58402, 58405, 58420, 58424, 58426, 58455, 58467, 58472, 58476, 58483,
    58496, 58497,
  ],
  "McIntosh County, North Dakota": [58413, 58495, 58581],
  "LaMoure County, North Dakota": [
    58415, 58431, 58433, 58454, 58456, 58458, 58466, 58490,
  ],
  "Griggs County, North Dakota": [58416, 58425, 58448, 58452, 58484],
  "Foster County, North Dakota": [58421, 58443, 58445, 58464],
  "Kidder County, North Dakota": [58428, 58475, 58478, 58482, 58487, 58488],
  "Sheridan County, North Dakota": [58430, 58444, 58463, 58758],
  "Dickey County, North Dakota": [58436, 58441, 58474],
  "McPherson County, North Dakota": [58439],
  "Logan County, North Dakota": [58440, 58442, 58460, 58561],
  "Burleigh County, North Dakota": [
    58477, 58494, 58501, 58502, 58503, 58504, 58505, 58506, 58507, 58521, 58532,
    58558, 58560, 58572,
  ],
  "Morton County, North Dakota": [
    58520, 58535, 58554, 58563, 58566, 58570, 58631, 58638,
  ],
  "Mercer County, North Dakota": [58523, 58541, 58545, 58571, 58580],
  "Emmons County, North Dakota": [58524, 58542, 58544, 58549, 58552, 58573],
  "Sioux County, North Dakota": [58528, 58538, 58568],
  "Grant County, North Dakota": [58529, 58533, 58562, 58564, 58569],
  "Oliver County, North Dakota": [58530],
  "McLean County, North Dakota": [
    58531, 58540, 58559, 58565, 58575, 58576, 58577, 58579, 58716, 58723, 58759,
    58775, 58778,
  ],
  "Stark County, North Dakota": [
    58601, 58602, 58622, 58630, 58641, 58652, 58655, 58656,
  ],
  "Slope County, North Dakota": [58620, 58643],
  "Golden Valley County, North Dakota": [58621, 58632, 58654],
  "Bowman County, North Dakota": [58623, 58651, 58653],
  "Dunn County, North Dakota": [58625, 58626, 58636, 58640, 58642, 58644],
  "Billings County, North Dakota": [58627, 58645],
  "McKenzie County, North Dakota": [
    58634, 58757, 58831, 58835, 58838, 58847, 58854,
  ],
  "Adams County, North Dakota": [58639, 58649],
  "Hettinger County, North Dakota": [58646, 58647, 58650],
  "Ward County, North Dakota": [
    58701, 58702, 58703, 58704, 58705, 58707, 58718, 58722, 58725, 58733, 58734,
    58735, 58746, 58756, 58768, 58779, 58781, 58785,
  ],
  "McHenry County, North Dakota": [
    58710, 58712, 58713, 58731, 58736, 58741, 58744, 58788, 58789, 58790, 58792,
  ],
  "Burke County, North Dakota": [58721, 58727, 58737, 58752, 58772, 58773],
  "Divide County, North Dakota": [58730, 58765, 58833, 58844],
  "Renville County, North Dakota": [58740, 58761, 58782, 58787],
  "Williams County, North Dakota": [
    58755, 58795, 58801, 58802, 58830, 58843, 58845, 58849, 58852, 58853, 58856,
  ],
  "Mountrail County, North Dakota": [
    58763, 58769, 58770, 58771, 58776, 58784, 58794,
  ],
  "undefined, North Dakota": [58803],
  "Stillwater County, Montana": [
    59001, 59019, 59028, 59061, 59063, 59067, 59069,
  ],
  "Yellowstone County, Montana": [
    59002, 59006, 59015, 59024, 59037, 59044, 59057, 59064, 59079, 59088, 59101,
    59102, 59103, 59104, 59105, 59106, 59107, 59108, 59111, 59112, 59114, 59115,
    59116, 59117,
  ],
  "Rosebud County, Montana": [
    59003, 59004, 59012, 59039, 59043, 59083, 59323, 59327, 59333, 59347,
  ],
  "Carbon County, Montana": [
    59007, 59008, 59013, 59014, 59026, 59029, 59041, 59068, 59070, 59071,
  ],
  "Treasure County, Montana": [59010, 59038, 59076],
  "Sweet Grass County, Montana": [59011, 59033, 59052, 59055],
  "Big Horn County, Montana": [
    59016, 59022, 59025, 59031, 59034, 59035, 59050, 59066, 59075, 59089,
  ],
  "Park County, Montana": [
    59018, 59020, 59027, 59030, 59047, 59065, 59081, 59082, 59086,
  ],
  "Fergus County, Montana": [
    59032, 59424, 59430, 59441, 59451, 59457, 59464, 59471, 59489,
  ],
  "Wheatland County, Montana": [59036, 59078, 59085, 59453],
  "Golden Valley County, Montana": [59046, 59074],
  "Meagher County, Montana": [59053, 59642, 59645],
  "Musselshell County, Montana": [59054, 59059, 59072, 59073],
  "Garfield County, Montana": [59058, 59077, 59312, 59318, 59322, 59337],
  "Powder River County, Montana": [59062, 59314, 59317, 59343, 59351],
  "Petroleum County, Montana": [59084, 59087],
  "Roosevelt County, Montana": [59201, 59212, 59213, 59218, 59226, 59255],
  "Sheridan County, Montana": [
    59211, 59219, 59242, 59247, 59252, 59254, 59256, 59257, 59258, 59275,
  ],
  "McCone County, Montana": [59214, 59215, 59223, 59274],
  "Richland County, Montana": [59217, 59221, 59243, 59262, 59270],
  "Daniels County, Montana": [59222, 59253, 59263, 59276],
  "Valley County, Montana": [
    59225, 59230, 59231, 59240, 59241, 59244, 59248, 59250, 59260, 59273,
  ],
  "Dawson County, Montana": [59259, 59315, 59330, 59339],
  "Phillips County, Montana": [59261, 59524, 59537, 59538, 59544, 59546],
  "Custer County, Montana": [59301, 59336, 59338, 59345],
  "Carter County, Montana": [59311, 59316, 59319, 59324, 59332],
  "Fallon County, Montana": [59313, 59344, 59354],
  "Prairie County, Montana": [59326, 59341, 59349],
  "Wibaux County, Montana": [59353],
  "Cascade County, Montana": [
    59401, 59402, 59403, 59404, 59405, 59406, 59412, 59414, 59421, 59443, 59463,
    59465, 59472, 59477, 59480, 59483, 59485, 59487,
  ],
  "Lewis and Clark County, Montana": [
    59410, 59601, 59602, 59604, 59620, 59623, 59624, 59625, 59626, 59633, 59635,
    59636, 59639, 59640, 59648,
  ],
  "Glacier County, Montana": [59411, 59417, 59427, 59434],
  "Pondera County, Montana": [59416, 59425, 59432, 59448, 59486],
  "Judith Basin County, Montana": [59418, 59447, 59452, 59462, 59469, 59479],
  "Teton County, Montana": [59419, 59422, 59433, 59436, 59467, 59468],
  "Chouteau County, Montana": [59420, 59440, 59442, 59446, 59450, 59460, 59520],
  "Toole County, Montana": [59435, 59454, 59456, 59466, 59474, 59482, 59484],
  "Liberty County, Montana": [59444, 59461, 59522, 59531, 59545],
  "Hill County, Montana": [59501, 59521, 59525, 59528, 59530, 59532, 59540],
  "Blaine County, Montana": [59523, 59526, 59527, 59529, 59535, 59542, 59547],
  "Jefferson County, Montana": [59631, 59632, 59634, 59638, 59721, 59759],
  "Broadwater County, Montana": [59641, 59643, 59644, 59647],
  "Silver Bow County, Montana": [
    59701, 59702, 59703, 59707, 59727, 59743, 59748, 59750,
  ],
  "Madison County, Montana": [
    59710, 59720, 59729, 59735, 59740, 59745, 59747, 59749, 59751, 59754, 59755,
  ],
  "Deer Lodge County, Montana": [59711, 59756],
  "Powell County, Montana": [59713, 59722, 59728, 59731, 59733, 59843, 59854],
  "Gallatin County, Montana": [
    59714, 59715, 59716, 59717, 59718, 59719, 59730, 59741, 59752, 59758, 59760,
    59771, 59772, 59773,
  ],
  "Beaverhead County, Montana": [
    59724, 59725, 59732, 59736, 59739, 59746, 59761, 59762,
  ],
  "Missoula County, Montana": [
    59801, 59802, 59803, 59804, 59806, 59807, 59808, 59812, 59823, 59825, 59826,
    59834, 59846, 59847, 59851, 59868,
  ],
  "Mineral County, Montana": [59820, 59830, 59842, 59866, 59867, 59872],
  "Lake County, Montana": [
    59821, 59824, 59855, 59860, 59863, 59864, 59865, 59910, 59914, 59915, 59929,
    59931,
  ],
  "Ravalli County, Montana": [
    59827, 59828, 59829, 59833, 59835, 59840, 59841, 59870, 59871, 59875,
  ],
  "Sanders County, Montana": [
    59831, 59844, 59845, 59848, 59853, 59856, 59859, 59873, 59874,
  ],
  "Granite County, Montana": [59832, 59837, 59858],
  "Flathead County, Montana": [
    59901, 59903, 59904, 59911, 59912, 59913, 59916, 59919, 59920, 59921, 59922,
    59925, 59926, 59927, 59928, 59932, 59936, 59937,
  ],
  "Lincoln County, Montana": [59917, 59918, 59923, 59930, 59933, 59934, 59935],
  "McHenry County, Illinois": [
    60001, 60012, 60013, 60014, 60021, 60033, 60034, 60042, 60050, 60051, 60071,
    60072, 60081, 60097, 60098, 60102, 60142, 60152, 60156, 60180,
  ],
  "Lake County, Illinois": [
    60002, 60010, 60015, 60020, 60030, 60031, 60035, 60037, 60040, 60041, 60044,
    60045, 60046, 60047, 60048, 60049, 60060, 60061, 60064, 60069, 60073, 60075,
    60079, 60083, 60084, 60085, 60086, 60087, 60088, 60089, 60092, 60096, 60099,
  ],
  "Cook County, Illinois": [
    60004, 60005, 60006, 60007, 60008, 60009, 60011, 60016, 60017, 60018, 60019,
    60022, 60025, 60026, 60029, 60038, 60043, 60053, 60055, 60056, 60062, 60065,
    60067, 60068, 60070, 60074, 60076, 60077, 60078, 60082, 60090, 60091, 60093,
    60094, 60095, 60104, 60107, 60120, 60130, 60131, 60133, 60141, 60153, 60154,
    60155, 60159, 60160, 60161, 60162, 60163, 60164, 60165, 60168, 60169, 60171,
    60173, 60176, 60179, 60192, 60193, 60194, 60195, 60196, 60201, 60202, 60203,
    60204, 60208, 60209, 60290, 60301, 60302, 60303, 60304, 60305, 60402, 60406,
    60409, 60411, 60412, 60415, 60418, 60419, 60422, 60425, 60426, 60428, 60429,
    60430, 60438, 60439, 60443, 60445, 60452, 60453, 60454, 60455, 60456, 60457,
    60458, 60459, 60461, 60462, 60463, 60464, 60465, 60466, 60467, 60469, 60471,
    60472, 60473, 60476, 60477, 60478, 60480, 60482, 60487, 60499, 60501, 60513,
    60525, 60526, 60534, 60546, 60558, 60601, 60602, 60603, 60604, 60605, 60606,
    60607, 60608, 60609, 60610, 60611, 60612, 60613, 60614, 60615, 60616, 60617,
    60618, 60619, 60620, 60621, 60622, 60623, 60624, 60625, 60626, 60628, 60629,
    60630, 60631, 60632, 60633, 60634, 60636, 60637, 60638, 60639, 60640, 60641,
    60642, 60643, 60644, 60645, 60646, 60647, 60649, 60651, 60652, 60653, 60654,
    60655, 60656, 60657, 60659, 60660, 60661, 60663, 60664, 60666, 60668, 60669,
    60670, 60673, 60674, 60675, 60677, 60678, 60679, 60680, 60681, 60682, 60684,
    60685, 60686, 60687, 60688, 60689, 60690, 60691, 60693, 60694, 60695, 60696,
    60697, 60699, 60701, 60706, 60707, 60712, 60714, 60803, 60804, 60805, 60827,
  ],
  "Mchenry County, Illinois": [60039],
  "DuPage County, Illinois": [
    60101, 60103, 60106, 60108, 60126, 60137, 60139, 60143, 60148, 60157, 60172,
    60181, 60184, 60185, 60187, 60188, 60189, 60190, 60191, 60502, 60504, 60514,
    60515, 60516, 60517, 60519, 60521, 60523, 60527, 60532, 60540, 60555, 60559,
    60561, 60563, 60565,
  ],
  "Dupage County, Illinois": [
    60105, 60116, 60117, 60122, 60125, 60128, 60132, 60138, 60186, 60197, 60199,
    60399, 60522, 60566, 60567, 60570, 60597, 60599,
  ],
  "Kane County, Illinois": [
    60109, 60110, 60118, 60119, 60121, 60123, 60124, 60134, 60136, 60140, 60144,
    60147, 60151, 60170, 60174, 60175, 60177, 60183, 60505, 60506, 60507, 60510,
    60511, 60539, 60542, 60554, 60568, 60572, 60598,
  ],
  "DeKalb County, Illinois": [
    60111, 60112, 60115, 60129, 60135, 60145, 60146, 60150, 60178, 60520, 60548,
    60550, 60556,
  ],
  "Ogle County, Illinois": [
    60113, 61007, 61010, 61015, 61020, 61030, 61043, 61047, 61049, 61052, 61054,
    61061, 61064, 61068, 61084, 61091,
  ],
  "Will County, Illinois": [
    60401, 60403, 60404, 60408, 60410, 60417, 60421, 60423, 60431, 60432, 60433,
    60434, 60435, 60436, 60440, 60441, 60442, 60446, 60448, 60449, 60451, 60468,
    60475, 60481, 60484, 60490, 60491, 60503, 60544, 60564, 60585, 60586,
  ],
  "Grundy County, Illinois": [
    60407, 60416, 60424, 60437, 60444, 60447, 60450, 60474, 60479,
  ],
  "Livingston County, Illinois": [
    60420, 60460, 60920, 60921, 60929, 60934, 61311, 61313, 61319, 61333, 61739,
    61740, 61741, 61743, 61764, 61769, 61775,
  ],
  "LaSalle County, Illinois": [
    60470, 60518, 60531, 60537, 60549, 60551, 60552, 60557, 61301, 61316, 61321,
    61325, 61332, 61334, 61341, 61342, 61348, 61350, 61354, 61358, 61360, 61364,
    61370, 61371, 61372, 61373,
  ],
  "Kendall County, Illinois": [60512, 60536, 60538, 60541, 60543, 60545, 60560],
  "Lee County, Illinois": [
    60530, 60553, 61006, 61021, 61031, 61042, 61057, 61310, 61318, 61324, 61331,
    61353, 61367, 61378,
  ],
  "undefined, Illinois": [60569],
  "Kankakee County, Illinois": [
    60901, 60910, 60913, 60914, 60915, 60917, 60922, 60935, 60940, 60941, 60944,
    60950, 60954, 60958, 60961, 60964, 60969,
  ],
  "Iroquois County, Illinois": [
    60911, 60912, 60918, 60924, 60926, 60927, 60928, 60930, 60931, 60938, 60939,
    60945, 60948, 60951, 60953, 60955, 60956, 60966, 60967, 60968, 60970, 60973,
    60974,
  ],
  "Ford County, Illinois": [
    60919, 60933, 60936, 60946, 60952, 60957, 60959, 60962, 61773,
  ],
  "Vermilion County, Illinois": [
    60932, 60942, 60960, 60963, 61810, 61811, 61812, 61814, 61817, 61831, 61832,
    61833, 61834, 61841, 61844, 61846, 61848, 61850, 61857, 61858, 61865, 61870,
    61876, 61883,
  ],
  "Champaign County, Illinois": [
    60949, 61801, 61802, 61803, 61815, 61816, 61820, 61821, 61822, 61824, 61825,
    61826, 61840, 61843, 61845, 61847, 61849, 61851, 61852, 61853, 61859, 61862,
    61863, 61864, 61866, 61871, 61872, 61873, 61874, 61875, 61877, 61878, 61880,
  ],
  "Jo Daviess County, Illinois": [
    61001, 61025, 61028, 61036, 61041, 61059, 61075, 61085, 61087,
  ],
  "Boone County, Illinois": [61008, 61011, 61012, 61038, 61065],
  "Stephenson County, Illinois": [
    61013, 61018, 61019, 61027, 61032, 61039, 61044, 61048, 61050, 61060, 61062,
    61067, 61070, 61089,
  ],
  "Carroll County, Illinois": [61014, 61046, 61051, 61053, 61074, 61078, 61285],
  "Winnebago County, Illinois": [
    61016, 61024, 61063, 61072, 61073, 61077, 61079, 61080, 61088, 61101, 61102,
    61103, 61104, 61105, 61106, 61107, 61108, 61109, 61110, 61111, 61112, 61114,
    61115, 61125, 61126, 61130, 61131, 61132,
  ],
  "Whiteside County, Illinois": [
    61037, 61071, 61081, 61230, 61243, 61250, 61251, 61252, 61261, 61270, 61277,
    61283,
  ],
  "Rock Island County, Illinois": [
    61201, 61204, 61232, 61236, 61237, 61239, 61240, 61242, 61244, 61256, 61257,
    61259, 61264, 61265, 61266, 61275, 61278, 61279, 61282, 61284, 61299,
  ],
  "Mercer County, Illinois": [
    61231, 61260, 61263, 61272, 61276, 61281, 61442, 61465, 61466, 61476, 61486,
  ],
  "Henry County, Illinois": [
    61233, 61234, 61235, 61238, 61241, 61254, 61258, 61262, 61273, 61274, 61413,
    61419, 61434, 61443, 61468, 61490,
  ],
  "Bureau County, Illinois": [
    61312, 61314, 61315, 61317, 61320, 61322, 61323, 61328, 61329, 61330, 61337,
    61338, 61344, 61345, 61346, 61349, 61356, 61359, 61361, 61362, 61368, 61374,
    61376, 61379,
  ],
  "Putnam County, Illinois": [61326, 61327, 61335, 61336, 61340, 61363, 61560],
  "Marshall County, Illinois": [
    61369, 61375, 61377, 61424, 61537, 61540, 61541, 61565,
  ],
  "Knox County, Illinois": [
    61401, 61402, 61410, 61414, 61428, 61430, 61436, 61439, 61448, 61458, 61467,
    61472, 61474, 61485, 61488, 61489, 61572,
  ],
  "McDonough County, Illinois": [
    61411, 61416, 61420, 61422, 61438, 61440, 61455, 61470, 61475, 62326, 62374,
  ],
  "Warren County, Illinois": [
    61412, 61417, 61423, 61435, 61447, 61453, 61462, 61473, 61478,
  ],
  "Fulton County, Illinois": [
    61415, 61427, 61431, 61432, 61433, 61441, 61459, 61477, 61482, 61484, 61501,
    61519, 61520, 61524, 61531, 61542, 61543, 61544, 61553, 61563,
  ],
  "Henderson County, Illinois": [
    61418, 61425, 61437, 61454, 61460, 61469, 61471, 61480,
  ],
  "Stark County, Illinois": [61421, 61426, 61449, 61479, 61483, 61491],
  "Hancock County, Illinois": [
    61450, 62311, 62313, 62316, 62321, 62329, 62330, 62334, 62336, 62341, 62354,
    62358, 62367, 62373, 62379, 62380,
  ],
  "Peoria County, Illinois": [
    61451, 61517, 61523, 61525, 61526, 61528, 61529, 61533, 61536, 61539, 61547,
    61552, 61559, 61562, 61569, 61601, 61602, 61603, 61604, 61605, 61606, 61607,
    61612, 61613, 61614, 61615, 61616, 61625, 61629, 61630, 61633, 61634, 61635,
    61636, 61637, 61638, 61639, 61641, 61643, 61650, 61651, 61652, 61653, 61654,
    61655, 61656,
  ],
  "Schuyler County, Illinois": [61452, 62319, 62344, 62624, 62639, 62681],
  "Woodford County, Illinois": [
    61516, 61530, 61545, 61548, 61561, 61570, 61729, 61738, 61742, 61760, 61771,
  ],
  "Mason County, Illinois": [
    61532, 61546, 61567, 62617, 62633, 62644, 62655, 62664, 62682,
  ],
  "Tazewell County, Illinois": [
    61534, 61535, 61550, 61554, 61555, 61558, 61564, 61568, 61571, 61610, 61611,
    61721, 61733, 61734, 61747, 61755, 61759,
  ],
  "McLean County, Illinois": [
    61701, 61704, 61705, 61720, 61722, 61724, 61725, 61726, 61728, 61730, 61731,
    61732, 61736, 61737, 61744, 61745, 61748, 61752, 61753, 61754, 61761, 61770,
    61772, 61774, 61776,
  ],
  "Mclean County, Illinois": [61702, 61709, 61710, 61758, 61790, 61791, 61799],
  "Logan County, Illinois": [
    61723, 61751, 62512, 62518, 62519, 62541, 62543, 62548, 62634, 62635, 62643,
    62656, 62666, 62671,
  ],
  "De Witt County, Illinois": [
    61727, 61735, 61749, 61750, 61777, 61778, 61842, 61882,
  ],
  "Macon County, Illinois": [
    61756, 62501, 62513, 62514, 62521, 62522, 62523, 62524, 62525, 62526, 62532,
    62535, 62537, 62544, 62549, 62551, 62554, 62573,
  ],
  "Piatt County, Illinois": [
    61813, 61818, 61830, 61839, 61854, 61855, 61856, 61884, 61929, 61936,
  ],
  "Douglas County, Illinois": [
    61910, 61911, 61913, 61919, 61930, 61941, 61942, 61953, 61956,
  ],
  "Coles County, Illinois": [61912, 61920, 61931, 61938, 61943, 62435, 62440],
  "Moultrie County, Illinois": [61914, 61925, 61928, 61937, 61951],
  "Edgar County, Illinois": [
    61917, 61924, 61932, 61933, 61940, 61944, 61949, 61955,
  ],
  "Shelby County, Illinois": [
    61957, 62422, 62431, 62438, 62444, 62462, 62463, 62465, 62534, 62550, 62553,
    62565, 62571,
  ],
  "Madison County, Illinois": [
    62001, 62002, 62010, 62018, 62021, 62024, 62025, 62026, 62034, 62035, 62040,
    62046, 62048, 62058, 62060, 62061, 62062, 62067, 62074, 62084, 62087, 62090,
    62095, 62097, 62234, 62249, 62281, 62294,
  ],
  "Calhoun County, Illinois": [
    62006, 62013, 62036, 62045, 62047, 62053, 62065, 62070,
  ],
  "Macoupin County, Illinois": [
    62009, 62012, 62014, 62023, 62033, 62063, 62069, 62079, 62085, 62088, 62093,
    62626, 62630, 62640, 62649, 62667, 62672, 62674, 62683, 62685, 62690,
  ],
  "Fayette County, Illinois": [
    62011, 62080, 62247, 62418, 62458, 62471, 62838, 62857, 62880, 62885,
  ],
  "Montgomery County, Illinois": [
    62015, 62017, 62019, 62032, 62049, 62051, 62056, 62075, 62076, 62077, 62089,
    62091, 62094, 62533, 62538, 62560, 62572,
  ],
  "Greene County, Illinois": [
    62016, 62027, 62044, 62050, 62054, 62078, 62081, 62082, 62092, 62098,
  ],
  "Jersey County, Illinois": [62022, 62028, 62030, 62031, 62037, 62052],
  "St. Clair County, Illinois": [
    62059, 62071, 62201, 62202, 62203, 62204, 62205, 62206, 62207, 62208, 62220,
    62221, 62222, 62223, 62224, 62225, 62226, 62232, 62239, 62240, 62243, 62254,
    62255, 62257, 62258, 62260, 62264, 62269, 62282, 62285, 62289,
  ],
  "Christian County, Illinois": [
    62083, 62510, 62517, 62531, 62540, 62546, 62547, 62555, 62556, 62557, 62567,
    62568, 62570,
  ],
  "Bond County, Illinois": [62086, 62246, 62253, 62262, 62273, 62275, 62284],
  "Washington County, Illinois": [
    62214, 62263, 62268, 62271, 62803, 62808, 62831, 62848, 62876, 62877,
  ],
  "Clinton County, Illinois": [
    62215, 62216, 62218, 62219, 62230, 62231, 62245, 62250, 62252, 62265, 62266,
    62293,
  ],
  "Randolph County, Illinois": [
    62217, 62233, 62237, 62241, 62242, 62259, 62261, 62272, 62277, 62278, 62280,
    62286, 62288, 62292, 62297,
  ],
  "Monroe County, Illinois": [62236, 62244, 62248, 62256, 62279, 62295, 62298],
  "Perry County, Illinois": [62238, 62274, 62832, 62888, 62997],
  "Adams County, Illinois": [
    62301, 62305, 62306, 62320, 62324, 62325, 62338, 62339, 62346, 62347, 62348,
    62349, 62351, 62359, 62360, 62365, 62376,
  ],
  "Pike County, Illinois": [
    62312, 62314, 62323, 62340, 62343, 62345, 62352, 62355, 62356, 62357, 62361,
    62362, 62363, 62366, 62370,
  ],
  "Brown County, Illinois": [62353, 62375, 62378],
  "Effingham County, Illinois": [
    62401, 62411, 62414, 62424, 62426, 62443, 62461, 62467, 62473,
  ],
  "Wabash County, Illinois": [62410, 62811, 62852, 62855, 62863],
  "Crawford County, Illinois": [
    62413, 62427, 62433, 62449, 62451, 62454, 62464, 62478,
  ],
  "Lawrence County, Illinois": [62417, 62439, 62460, 62466],
  "Richland County, Illinois": [62419, 62421, 62425, 62450, 62452, 62868],
  "Clark County, Illinois": [62420, 62423, 62441, 62442, 62474, 62477],
  "Cumberland County, Illinois": [62428, 62436, 62445, 62447, 62468, 62469],
  "Jasper County, Illinois": [62432, 62448, 62459, 62475, 62479, 62480, 62481],
  "Clay County, Illinois": [62434, 62824, 62839, 62858, 62879, 62899],
  "Wayne County, Illinois": [
    62446, 62809, 62823, 62833, 62837, 62842, 62843, 62850, 62851, 62878, 62886,
    62895,
  ],
  "Edwards County, Illinois": [62476, 62806, 62815, 62818],
  "Sangamon County, Illinois": [
    62515, 62520, 62530, 62536, 62539, 62545, 62558, 62561, 62563, 62615, 62625,
    62629, 62661, 62662, 62670, 62677, 62684, 62689, 62693, 62701, 62702, 62703,
    62704, 62705, 62706, 62707, 62708, 62711, 62712, 62713, 62715, 62716, 62719,
    62721, 62722, 62723, 62726, 62736, 62739, 62746, 62756, 62757, 62761, 62762,
    62763, 62764, 62765, 62766, 62767, 62769, 62776, 62777, 62781, 62786, 62791,
    62794, 62796,
  ],
  "Morgan County, Illinois": [
    62601, 62628, 62631, 62638, 62650, 62651, 62660, 62665, 62668, 62692, 62695,
  ],
  "Scott County, Illinois": [62610, 62621, 62663, 62694],
  "Cass County, Illinois": [62611, 62612, 62618, 62622, 62627, 62691],
  "Menard County, Illinois": [62613, 62642, 62659, 62673, 62675, 62688],
  "Marion County, Illinois": [
    62801, 62807, 62849, 62853, 62854, 62870, 62875, 62881, 62882, 62892, 62893,
  ],
  "Franklin County, Illinois": [
    62805, 62812, 62819, 62822, 62825, 62836, 62840, 62856, 62860, 62865, 62874,
    62884, 62890, 62891, 62896, 62897, 62983, 62999,
  ],
  "Jefferson County, Illinois": [
    62810, 62814, 62816, 62830, 62846, 62864, 62866, 62872, 62883, 62889, 62894,
    62898,
  ],
  "Hamilton County, Illinois": [62817, 62828, 62829, 62859],
  "White County, Illinois": [
    62820, 62821, 62827, 62834, 62835, 62844, 62861, 62862, 62869, 62887,
  ],
  "Williamson County, Illinois": [
    62841, 62915, 62918, 62921, 62922, 62933, 62948, 62949, 62951, 62959, 62974,
  ],
  "Gallatin County, Illinois": [62867, 62871, 62934, 62954, 62979, 62984],
  "Jackson County, Illinois": [
    62901, 62902, 62903, 62907, 62916, 62924, 62927, 62932, 62940, 62942, 62950,
    62958, 62966, 62971, 62975, 62994,
  ],
  "Union County, Illinois": [62905, 62906, 62920, 62926, 62952, 62961, 62998],
  "Massac County, Illinois": [62908, 62910, 62953, 62960],
  "Johnson County, Illinois": [
    62909, 62912, 62923, 62939, 62943, 62967, 62972, 62985, 62995,
  ],
  "Alexander County, Illinois": [
    62914, 62957, 62962, 62969, 62988, 62990, 62993,
  ],
  "Saline County, Illinois": [62917, 62930, 62935, 62946, 62965, 62977, 62987],
  "Hardin County, Illinois": [62919, 62931, 62947, 62955, 62982],
  "Pope County, Illinois": [62928, 62938],
  "Pulaski County, Illinois": [
    62941, 62956, 62963, 62964, 62970, 62973, 62976, 62992, 62996,
  ],
  "St. Louis County, Missouri": [
    63001, 63005, 63006, 63011, 63017, 63021, 63022, 63024, 63025, 63026, 63031,
    63032, 63033, 63034, 63038, 63040, 63042, 63043, 63044, 63045, 63074, 63088,
    63099, 63105, 63114, 63117, 63119, 63121, 63122, 63123, 63124, 63125, 63126,
    63127, 63128, 63129, 63130, 63131, 63132, 63133, 63134, 63135, 63136, 63137,
    63138, 63140, 63141, 63143, 63144, 63146,
  ],
  "Jefferson County, Missouri": [
    63010, 63012, 63016, 63019, 63020, 63023, 63028, 63047, 63048, 63049, 63050,
    63051, 63052, 63053, 63057, 63065, 63066, 63070,
  ],
  "Franklin County, Missouri": [
    63013, 63014, 63015, 63037, 63039, 63041, 63055, 63056, 63060, 63061, 63068,
    63069, 63072, 63073, 63077, 63079, 63080, 63084, 63089, 63090,
  ],
  "Washington County, Missouri": [
    63030, 63071, 63622, 63626, 63630, 63631, 63648, 63660, 63664, 63674,
  ],
  "St. Francois County, Missouri": [
    63036, 63087, 63601, 63624, 63628, 63637, 63640, 63653,
  ],
  "Gasconade County, Missouri": [
    63091, 65014, 65036, 65041, 65061, 65062, 65066,
  ],
  "St. Louis city, Missouri": [
    63101, 63102, 63103, 63104, 63106, 63107, 63108, 63109, 63110, 63111, 63112,
    63113, 63115, 63116, 63118, 63120, 63139, 63147, 63155,
  ],
  "St Louis County, Missouri": [63145],
  "St. Louis City, Missouri": [
    63150, 63151, 63156, 63157, 63158, 63160, 63163, 63164, 63166, 63167, 63169,
    63171, 63177, 63178, 63179, 63180, 63182, 63188, 63190, 63195, 63196, 63197,
    63198, 63199,
  ],
  "St. Charles County, Missouri": [
    63301, 63302, 63303, 63304, 63332, 63338, 63341, 63346, 63348, 63365, 63366,
    63367, 63368, 63373, 63376, 63385, 63386,
  ],
  "Pike County, Missouri": [
    63330, 63334, 63336, 63339, 63344, 63353, 63433, 63441,
  ],
  "Montgomery County, Missouri": [
    63333, 63350, 63351, 63359, 63361, 63363, 63384, 65069,
  ],
  "Warren County, Missouri": [63342, 63357, 63378, 63380, 63381, 63383, 63390],
  "Lincoln County, Missouri": [
    63343, 63347, 63349, 63362, 63369, 63370, 63377, 63379, 63387, 63389,
  ],
  "Audrain County, Missouri": [
    63345, 63352, 63382, 65232, 65264, 65265, 65280, 65285,
  ],
  "Callaway County, Missouri": [
    63388, 65043, 65059, 65063, 65067, 65077, 65080, 65231, 65251, 65262,
  ],
  "Marion County, Missouri": [63401, 63454, 63461, 63463, 63471],
  "Clark County, Missouri": [63430, 63445, 63453, 63465, 63466, 63472, 63474],
  "Macon County, Missouri": [
    63431, 63530, 63532, 63534, 63538, 63539, 63549, 63552, 63558, 65247,
  ],
  "Scotland County, Missouri": [63432, 63442, 63543, 63555, 63563],
  "Shelby County, Missouri": [
    63434, 63437, 63439, 63443, 63450, 63451, 63468, 63469,
  ],
  "Lewis County, Missouri": [
    63435, 63438, 63440, 63447, 63448, 63452, 63457, 63473,
  ],
  "Ralls County, Missouri": [63436, 63459, 63462, 63467],
  "Knox County, Missouri": [63446, 63458, 63460, 63464, 63531, 63537, 63547],
  "Monroe County, Missouri": [63456, 65258, 65263, 65275, 65282, 65283],
  "Adair County, Missouri": [63501, 63533, 63540, 63546, 63559],
  "Schuyler County, Missouri": [63535, 63536, 63541, 63548, 63561],
  "Sullivan County, Missouri": [
    63544, 63545, 63556, 63560, 63566, 64645, 64646, 64667,
  ],
  "Putnam County, Missouri": [63551, 63565, 63567, 64655, 64672],
  "Linn County, Missouri": [
    63557, 64628, 64630, 64631, 64651, 64653, 64658, 64659, 64674,
  ],
  "Iron County, Missouri": [
    63620, 63621, 63623, 63636, 63650, 63656, 63663, 63675, 65439, 65566,
  ],
  "Reynolds County, Missouri": [
    63625, 63629, 63633, 63638, 63654, 63665, 63666,
  ],
  "Ste. Genevieve County, Missouri": [63627, 63670, 63673],
  "Wayne County, Missouri": [
    63632, 63763, 63934, 63944, 63950, 63951, 63952, 63956, 63957, 63963, 63964,
    63966, 63967,
  ],
  "Madison County, Missouri": [63645, 63655],
  "St Francois County, Missouri": [63651],
  "Bollinger County, Missouri": [
    63662, 63750, 63751, 63760, 63764, 63781, 63782, 63787,
  ],
  "Cape Girardeau County, Missouri": [
    63701, 63702, 63703, 63739, 63743, 63744, 63745, 63747, 63752, 63755, 63766,
    63769, 63770, 63779, 63785,
  ],
  "Stoddard County, Missouri": [
    63730, 63735, 63738, 63822, 63825, 63841, 63846, 63850, 63936, 63960,
  ],
  "Perry County, Missouri": [63732, 63737, 63746, 63748, 63775, 63776, 63783],
  "Scott County, Missouri": [
    63736, 63740, 63742, 63758, 63767, 63771, 63774, 63780, 63784, 63801, 63824,
  ],
  "Mississippi County, Missouri": [63820, 63823, 63834, 63845, 63881, 63882],
  "Dunklin County, Missouri": [
    63821, 63829, 63837, 63847, 63849, 63852, 63855, 63857, 63863, 63875, 63876,
    63880, 63933,
  ],
  "Pemiscot County, Missouri": [
    63826, 63827, 63830, 63839, 63840, 63851, 63853, 63877, 63879,
  ],
  "New Madrid County, Missouri": [
    63828, 63833, 63848, 63860, 63862, 63866, 63867, 63868, 63869, 63870, 63873,
    63874, 63878,
  ],
  "Butler County, Missouri": [
    63901, 63902, 63932, 63938, 63940, 63945, 63954, 63961, 63962,
  ],
  "Ripley County, Missouri": [63931, 63935, 63939, 63942, 63953, 63955],
  "Carter County, Missouri": [63937, 63941, 63943, 63965],
  "Lafayette County, Missouri": [
    64001, 64011, 64020, 64021, 64022, 64037, 64067, 64071, 64074, 64076, 64096,
    64097,
  ],
  "Jackson County, Missouri": [
    64002, 64013, 64014, 64015, 64016, 64029, 64030, 64034, 64050, 64051, 64052,
    64053, 64054, 64055, 64056, 64057, 64058, 64063, 64064, 64065, 64066, 64070,
    64075, 64081, 64082, 64086, 64088, 64101, 64102, 64105, 64106, 64108, 64109,
    64110, 64111, 64112, 64113, 64114, 64120, 64121, 64123, 64124, 64125, 64126,
    64127, 64128, 64129, 64130, 64131, 64132, 64133, 64134, 64136, 64137, 64138,
    64139, 64141, 64144, 64145, 64146, 64147, 64148, 64149, 64168, 64170, 64171,
    64172, 64179, 64180, 64183, 64184, 64185, 64187, 64188, 64190, 64191, 64192,
    64193, 64194, 64195, 64196, 64197, 64198, 64199, 64944, 64999,
  ],
  "Cass County, Missouri": [
    64012, 64078, 64080, 64083, 64090, 64701, 64725, 64734, 64739, 64742, 64743,
    64746, 64747,
  ],
  "Ray County, Missouri": [64017, 64035, 64036, 64062, 64077, 64084, 64085],
  "Platte County, Missouri": [
    64018, 64028, 64079, 64092, 64098, 64150, 64151, 64152, 64153, 64154, 64163,
    64164, 64439, 64444,
  ],
  "Johnson County, Missouri": [
    64019, 64040, 64061, 64093, 64733, 64761, 65305, 65336,
  ],
  "Clay County, Missouri": [
    64024, 64048, 64060, 64068, 64069, 64072, 64073, 64089, 64116, 64117, 64118,
    64119, 64155, 64156, 64157, 64158, 64161, 64165, 64166, 64167,
  ],
  "undefined, Missouri": [64162],
  "Buchanan County, Missouri": [
    64401, 64440, 64443, 64448, 64484, 64501, 64502, 64503, 64504, 64505, 64506,
    64507, 64508,
  ],
  "Gentry County, Missouri": [64402, 64438, 64453, 64463, 64489, 64657],
  "Worth County, Missouri": [64420, 64441, 64456, 64486, 64499],
  "Andrew County, Missouri": [
    64421, 64427, 64436, 64449, 64459, 64480, 64483, 64485,
  ],
  "DeKalb County, Missouri": [64422, 64430, 64469, 64474, 64490, 64494, 64497],
  "Nodaway County, Missouri": [
    64423, 64428, 64431, 64432, 64433, 64434, 64445, 64455, 64457, 64461, 64468,
    64475, 64476, 64479, 64487,
  ],
  "Harrison County, Missouri": [
    64424, 64426, 64442, 64458, 64467, 64471, 64481, 64632, 64642,
  ],
  "Clinton County, Missouri": [64429, 64454, 64465, 64477, 64492, 64493],
  "Holt County, Missouri": [64437, 64451, 64466, 64470, 64473],
  "Atchison County, Missouri": [64446, 64482, 64491, 64496, 64498],
  "Dekalb County, Missouri": [64447],
  "Livingston County, Missouri": [
    64601, 64635, 64638, 64656, 64664, 64686, 64688,
  ],
  "Daviess County, Missouri": [
    64620, 64636, 64640, 64647, 64648, 64654, 64670, 64689,
  ],
  "Carroll County, Missouri": [
    64622, 64623, 64633, 64639, 64643, 64668, 64680, 64682,
  ],
  "Caldwell County, Missouri": [
    64624, 64625, 64637, 64644, 64649, 64650, 64671,
  ],
  "Grundy County, Missouri": [64641, 64652, 64679, 64683],
  "Chariton County, Missouri": [
    64660, 64676, 64681, 65236, 65246, 65261, 65281, 65286,
  ],
  "Mercer County, Missouri": [64661, 64673],
  "Bates County, Missouri": [
    64720, 64722, 64723, 64730, 64745, 64752, 64779, 64780,
  ],
  "St. Clair County, Missouri": [64724, 64738, 64763, 64776, 64781, 64789],
  "Henry County, Missouri": [64726, 64735, 64740, 64770, 64788, 65323, 65360],
  "Vernon County, Missouri": [
    64728, 64741, 64750, 64765, 64767, 64771, 64772, 64778, 64783, 64784, 64790,
  ],
  "Cedar County, Missouri": [64744, 64756, 65607, 65785],
  "Barton County, Missouri": [64748, 64759, 64762, 64766, 64769],
  "Jasper County, Missouri": [
    64755, 64801, 64802, 64803, 64804, 64830, 64832, 64833, 64834, 64835, 64836,
    64841, 64849, 64855, 64857, 64859, 64862, 64869, 64870,
  ],
  "McDonald County, Missouri": [
    64831, 64843, 64847, 64854, 64856, 64861, 64863, 64867, 64868, 65730,
  ],
  "Newton County, Missouri": [
    64840, 64842, 64844, 64850, 64853, 64858, 64864, 64865, 64866,
  ],
  "Lawrence County, Missouri": [
    64848, 64873, 65605, 65654, 65664, 65705, 65707, 65712, 65723, 65756, 65769,
  ],
  "Barry County, Missouri": [
    64874, 65623, 65625, 65641, 65647, 65658, 65708, 65734, 65745, 65747, 65772,
  ],
  "Osage County, Missouri": [
    65001, 65016, 65024, 65035, 65048, 65051, 65054, 65085,
  ],
  "Boone County, Missouri": [
    65010, 65039, 65201, 65202, 65203, 65205, 65211, 65212, 65215, 65216, 65217,
    65218, 65240, 65255, 65256, 65279, 65284, 65299,
  ],
  "Morgan County, Missouri": [
    65011, 65034, 65037, 65038, 65072, 65078, 65084, 65329, 65354,
  ],
  "Maries County, Missouri": [65013, 65058, 65443, 65580, 65582],
  "Miller County, Missouri": [
    65017, 65026, 65032, 65047, 65064, 65075, 65082, 65083, 65486,
  ],
  "Moniteau County, Missouri": [
    65018, 65025, 65042, 65046, 65050, 65055, 65081,
  ],
  "Camden County, Missouri": [
    65020, 65049, 65052, 65065, 65079, 65324, 65567, 65591, 65786, 65787,
  ],
  "Cole County, Missouri": [
    65023, 65040, 65053, 65074, 65076, 65101, 65102, 65103, 65104, 65105, 65106,
    65107, 65108, 65109, 65110, 65111,
  ],
  "Cooper County, Missouri": [65068, 65233, 65237, 65276, 65287, 65322, 65348],
  "Howard County, Missouri": [65230, 65248, 65250, 65254, 65274],
  "Randolph County, Missouri": [
    65239, 65243, 65244, 65257, 65259, 65260, 65270, 65278,
  ],
  "Pettis County, Missouri": [
    65301, 65302, 65332, 65333, 65334, 65335, 65337, 65345, 65350,
  ],
  "Saline County, Missouri": [
    65320, 65321, 65327, 65330, 65339, 65340, 65344, 65347, 65349, 65351,
  ],
  "Benton County, Missouri": [65325, 65326, 65338, 65355],
  "Phelps County, Missouri": [
    65401, 65402, 65409, 65436, 65461, 65462, 65529, 65550, 65559,
  ],
  "Shannon County, Missouri": [65438, 65466, 65546, 65588],
  "Dent County, Missouri": [65440, 65501, 65532, 65541, 65560],
  "Crawford County, Missouri": [
    65441, 65446, 65449, 65453, 65456, 65535, 65565, 65586,
  ],
  "Texas County, Missouri": [
    65444, 65464, 65468, 65479, 65483, 65484, 65542, 65552, 65555, 65557, 65564,
    65570, 65571, 65589, 65689,
  ],
  "Pulaski County, Missouri": [
    65452, 65457, 65459, 65473, 65534, 65556, 65583, 65584,
  ],
  "Laclede County, Missouri": [65463, 65470, 65536, 65543, 65632, 65722],
  "Howell County, Missouri": [
    65548, 65626, 65688, 65775, 65776, 65777, 65788, 65789, 65790, 65793,
  ],
  "Dallas County, Missouri": [65590, 65622, 65644, 65685, 65764, 65767, 65783],
  "Polk County, Missouri": [
    65601, 65613, 65617, 65640, 65645, 65649, 65663, 65674, 65710, 65725, 65727,
  ],
  "Dade County, Missouri": [65603, 65635, 65646, 65661, 65682, 65752],
  "Greene County, Missouri": [
    65604, 65612, 65619, 65648, 65738, 65757, 65765, 65770, 65781, 65801, 65802,
    65803, 65804, 65805, 65806, 65807, 65808, 65809, 65810, 65814, 65817, 65890,
    65897, 65898, 65899,
  ],
  "Oregon County, Missouri": [65606, 65690, 65692, 65778, 65791],
  "Douglas County, Missouri": [65608, 65638, 65755, 65768],
  "Ozark County, Missouri": [
    65609, 65618, 65637, 65655, 65666, 65676, 65715, 65729, 65741, 65760, 65761,
    65762, 65766, 65773, 65784,
  ],
  "Christian County, Missouri": [
    65610, 65620, 65629, 65630, 65631, 65657, 65669, 65714, 65720, 65721, 65753,
    65754,
  ],
  "Stone County, Missouri": [
    65611, 65624, 65633, 65656, 65675, 65681, 65686, 65728, 65737,
  ],
  "Taney County, Missouri": [
    65614, 65615, 65616, 65627, 65653, 65672, 65673, 65679, 65680, 65701, 65726,
    65731, 65733, 65739, 65740, 65744, 65759, 65771,
  ],
  "Hickory County, Missouri": [
    65634, 65650, 65668, 65724, 65732, 65735, 65774, 65779,
  ],
  "Webster County, Missouri": [65636, 65652, 65706, 65713, 65742, 65746, 72643],
  "Wright County, Missouri": [65660, 65662, 65667, 65702, 65704, 65711, 65717],
  "Atchison County, Kansas": [66002, 66016, 66023, 66041, 66058, 66077],
  "Douglas County, Kansas": [
    66006, 66025, 66044, 66045, 66046, 66047, 66049, 66050,
  ],
  "Leavenworth County, Kansas": [
    66007, 66020, 66027, 66043, 66048, 66052, 66086,
  ],
  "Doniphan County, Kansas": [66008, 66017, 66024, 66035, 66087, 66090],
  "Linn County, Kansas": [66010, 66014, 66040, 66056, 66072, 66075, 66767],
  "Wyandotte County, Kansas": [
    66012, 66101, 66102, 66103, 66104, 66105, 66106, 66109, 66110, 66111, 66112,
    66113, 66115, 66117, 66118, 66119, 66160,
  ],
  "Johnson County, Kansas": [
    66013, 66018, 66019, 66021, 66030, 66031, 66051, 66061, 66062, 66063, 66083,
    66085, 66201, 66202, 66203, 66204, 66205, 66206, 66207, 66208, 66209, 66210,
    66211, 66212, 66213, 66214, 66215, 66216, 66217, 66218, 66219, 66220, 66221,
    66222, 66223, 66224, 66225, 66226, 66227, 66250, 66251, 66276, 66279, 66282,
    66283, 66285, 66286,
  ],
  "Anderson County, Kansas": [66015, 66032, 66033, 66039, 66091, 66093],
  "Miami County, Kansas": [66026, 66036, 66053, 66064, 66071],
  "Franklin County, Kansas": [
    66042, 66067, 66076, 66078, 66079, 66080, 66092, 66095,
  ],
  "Jefferson County, Kansas": [
    66054, 66060, 66066, 66070, 66073, 66088, 66097, 66429, 66512,
  ],
  "Brown County, Kansas": [
    66094, 66424, 66425, 66434, 66439, 66515, 66527, 66532,
  ],
  "Wabaunsee County, Kansas": [66401, 66423, 66431, 66501, 66507, 66526, 66834],
  "Shawnee County, Kansas": [
    66402, 66409, 66420, 66533, 66539, 66542, 66546, 66601, 66603, 66604, 66605,
    66606, 66607, 66608, 66609, 66610, 66611, 66612, 66614, 66615, 66616, 66617,
    66618, 66619, 66620, 66621, 66622, 66624, 66625, 66626, 66628, 66629, 66636,
    66637, 66642, 66647, 66652, 66653, 66667, 66675, 66683, 66692, 66699,
  ],
  "Marshall County, Kansas": [
    66403, 66406, 66411, 66412, 66427, 66438, 66508, 66518, 66541, 66544, 66548,
  ],
  "Nemaha County, Kansas": [
    66404, 66408, 66415, 66417, 66428, 66522, 66534, 66538, 66550,
  ],
  "Pottawatomie County, Kansas": [
    66407, 66422, 66426, 66432, 66520, 66521, 66535, 66536, 66547, 66549,
  ],
  "Osage County, Kansas": [
    66413, 66414, 66451, 66510, 66523, 66524, 66528, 66537, 66543,
  ],
  "Jackson County, Kansas": [
    66416, 66418, 66419, 66436, 66440, 66509, 66516, 66540, 66552,
  ],
  "Geary County, Kansas": [66441, 66442, 66514],
  "Riley County, Kansas": [
    66449, 66502, 66503, 66505, 66506, 66517, 66531, 66554,
  ],
  "undefined, Kansas": [66630],
  "Bourbon County, Kansas": [66701, 66716, 66738, 66741, 66754, 66769, 66779],
  "Wilson County, Kansas": [66710, 66714, 66717, 66736, 66757],
  "Crawford County, Kansas": [
    66711, 66712, 66724, 66734, 66735, 66743, 66746, 66753, 66756, 66760, 66762,
    66763, 66780,
  ],
  "Cherokee County, Kansas": [
    66713, 66725, 66728, 66739, 66770, 66773, 66778, 66781, 66782,
  ],
  "Neosho County, Kansas": [66720, 66733, 66740, 66771, 66775, 66776],
  "Allen County, Kansas": [66732, 66742, 66748, 66749, 66751, 66755, 66772],
  "Woodson County, Kansas": [66758, 66761, 66777, 66783],
  "Elk County, Kansas": [66759, 67345, 67346, 67349, 67352, 67353],
  "Lyon County, Kansas": [
    66801, 66830, 66833, 66835, 66854, 66864, 66865, 66868,
  ],
  "Morris County, Kansas": [66838, 66846, 66849, 66872, 66873],
  "Coffey County, Kansas": [66839, 66852, 66856, 66857, 66871],
  "Marion County, Kansas": [
    66840, 66851, 66858, 66859, 66861, 66866, 67053, 67063, 67073, 67438, 67475,
    67483,
  ],
  "Butler County, Kansas": [
    66842, 67002, 67010, 67012, 67017, 67039, 67041, 67042, 67072, 67074, 67123,
    67132, 67133, 67144, 67154,
  ],
  "Chase County, Kansas": [66843, 66845, 66850, 66862, 66869],
  "Greenwood County, Kansas": [
    66853, 66855, 66860, 66863, 66870, 67045, 67047, 67122, 67137,
  ],
  "Cloud County, Kansas": [66901, 66938, 66948, 67417, 67445, 67466],
  "Republic County, Kansas": [
    66930, 66935, 66939, 66940, 66959, 66960, 66961, 66964, 66966,
  ],
  "Smith County, Kansas": [66932, 66951, 66952, 66967, 67628, 67638],
  "Washington County, Kansas": [
    66933, 66937, 66943, 66944, 66945, 66946, 66953, 66955, 66958, 66962, 66968,
  ],
  "Jewell County, Kansas": [66936, 66941, 66942, 66949, 66956, 66963, 66970],
  "Sedgwick County, Kansas": [
    67001, 67016, 67025, 67026, 67030, 67037, 67050, 67052, 67055, 67060, 67067,
    67101, 67108, 67110, 67147, 67149, 67201, 67202, 67203, 67204, 67205, 67206,
    67207, 67208, 67209, 67210, 67211, 67212, 67213, 67214, 67215, 67216, 67217,
    67218, 67219, 67220, 67221, 67223, 67226, 67227, 67228, 67230, 67232, 67235,
    67260, 67275, 67276, 67277, 67278,
  ],
  "Harper County, Kansas": [67003, 67009, 67018, 67036, 67049, 67058, 67150],
  "Sumner County, Kansas": [
    67004, 67013, 67022, 67031, 67051, 67103, 67105, 67106, 67119, 67120, 67140,
    67152,
  ],
  "Cowley County, Kansas": [
    67005, 67008, 67019, 67023, 67038, 67102, 67131, 67146, 67156,
  ],
  "Harvey County, Kansas": [67020, 67056, 67062, 67114, 67117, 67135, 67151],
  "Pratt County, Kansas": [67021, 67028, 67066, 67124, 67134],
  "Chautauqua County, Kansas": [67024, 67334, 67355, 67360, 67361],
  "Comanche County, Kansas": [67029, 67127, 67155],
  "Kingman County, Kansas": [67035, 67068, 67111, 67112, 67118, 67142, 67159],
  "Kiowa County, Kansas": [67054, 67059, 67109],
  "Barber County, Kansas": [
    67057, 67061, 67065, 67070, 67071, 67104, 67138, 67143,
  ],
  "McPherson County, Kansas": [
    67107, 67428, 67443, 67456, 67460, 67464, 67476, 67491, 67546,
  ],
  "Montgomery County, Kansas": [
    67301, 67333, 67335, 67337, 67340, 67344, 67347, 67351, 67363, 67364,
  ],
  "Labette County, Kansas": [
    67330, 67332, 67336, 67341, 67342, 67354, 67356, 67357,
  ],
  "Saline County, Kansas": [67401, 67402, 67416, 67425, 67442, 67448, 67470],
  "Dickinson County, Kansas": [
    67410, 67431, 67441, 67449, 67451, 67480, 67482, 67492,
  ],
  "Lincoln County, Kansas": [67418, 67423, 67455, 67481],
  "Mitchell County, Kansas": [67420, 67430, 67446, 67452, 67478, 67485],
  "Ottawa County, Kansas": [67422, 67436, 67467, 67484],
  "Rice County, Kansas": [
    67427, 67444, 67457, 67512, 67524, 67554, 67573, 67579,
  ],
  "Clay County, Kansas": [67432, 67447, 67458, 67468, 67487],
  "Osborne County, Kansas": [67437, 67473, 67474, 67623, 67651],
  "Ellsworth County, Kansas": [67439, 67450, 67454, 67459, 67490],
  "Reno County, Kansas": [
    67501, 67502, 67504, 67505, 67510, 67514, 67522, 67543, 67561, 67566, 67568,
    67570, 67581, 67583, 67585,
  ],
  "Barton County, Kansas": [67511, 67525, 67526, 67530, 67544, 67564, 67567],
  "Rush County, Kansas": [
    67513, 67520, 67548, 67553, 67556, 67559, 67565, 67575,
  ],
  "Ness County, Kansas": [67515, 67516, 67518, 67521, 67560, 67572, 67584],
  "Edwards County, Kansas": [67519, 67547, 67552, 67563],
  "Pawnee County, Kansas": [67523, 67529, 67550, 67574],
  "Stafford County, Kansas": [67545, 67557, 67576, 67578],
  "Ellis County, Kansas": [67601, 67627, 67637, 67660, 67667, 67671, 67674],
  "Phillips County, Kansas": [67621, 67639, 67644, 67646, 67647, 67661, 67664],
  "Norton County, Kansas": [67622, 67629, 67645, 67654],
  "Graham County, Kansas": [67625, 67642, 67650, 67659],
  "Russell County, Kansas": [
    67626, 67634, 67640, 67648, 67649, 67658, 67665, 67673,
  ],
  "Trego County, Kansas": [67631, 67656, 67672],
  "Rooks County, Kansas": [67632, 67657, 67663, 67669, 67675],
  "Decatur County, Kansas": [67635, 67643, 67653, 67749],
  "Thomas County, Kansas": [67701, 67732, 67734, 67743, 67753],
  "Rawlins County, Kansas": [67730, 67739, 67744, 67745],
  "Cheyenne County, Kansas": [67731, 67756],
  "Sherman County, Kansas": [67733, 67735, 67741],
  "Gove County, Kansas": [67736, 67737, 67738, 67751, 67752],
  "Sheridan County, Kansas": [67740, 67757],
  "Logan County, Kansas": [67747, 67748, 67764],
  "Wallace County, Kansas": [67758, 67761, 67762],
  "Ford County, Kansas": [67801, 67834, 67842, 67843, 67876, 67882],
  "Clark County, Kansas": [67831, 67840, 67865],
  "Gray County, Kansas": [67835, 67837, 67841, 67853, 67867],
  "Hamilton County, Kansas": [67836, 67857, 67878],
  "Kearny County, Kansas": [67838, 67860],
  "Lane County, Kansas": [67839, 67850],
  "Meade County, Kansas": [67844, 67864, 67869],
  "Finney County, Kansas": [67846, 67851, 67868],
  "Hodgeman County, Kansas": [67849, 67854],
  "Stanton County, Kansas": [67855, 67862],
  "Seward County, Kansas": [67859, 67901, 67905],
  "Wichita County, Kansas": [67861, 67863],
  "Haskell County, Kansas": [67870, 67877],
  "Scott County, Kansas": [67871],
  "Greeley County, Kansas": [67879],
  "Grant County, Kansas": [67880],
  "Morton County, Kansas": [67950, 67953, 67954],
  "Stevens County, Kansas": [67951, 67952],
  "Butler County, Nebraska": [
    68001, 68014, 68036, 68624, 68626, 68632, 68635, 68658, 68667, 68669,
  ],
  "Washington County, Nebraska": [
    68002, 68008, 68009, 68023, 68029, 68034, 68068,
  ],
  "Saunders County, Nebraska": [
    68003, 68015, 68017, 68018, 68033, 68040, 68041, 68042, 68050, 68065, 68066,
    68070, 68073, 68648,
  ],
  "Cuming County, Nebraska": [68004, 68716, 68788, 68791],
  "Sarpy County, Nebraska": [
    68005, 68028, 68046, 68056, 68059, 68113, 68123, 68128, 68133, 68136, 68138,
    68147, 68157,
  ],
  "Douglas County, Nebraska": [
    68007, 68010, 68022, 68064, 68069, 68101, 68102, 68103, 68104, 68105, 68106,
    68107, 68108, 68109, 68110, 68111, 68112, 68114, 68116, 68117, 68118, 68119,
    68120, 68122, 68124, 68127, 68130, 68131, 68132, 68134, 68135, 68137, 68139,
    68142, 68144, 68145, 68152, 68154, 68155, 68164, 68172, 68175, 68176, 68178,
    68179, 68180, 68181, 68182, 68183, 68197, 68198,
  ],
  "Cass County, Nebraska": [
    68016, 68037, 68048, 68058, 68304, 68307, 68347, 68349, 68366, 68403, 68407,
    68409, 68413, 68455, 68463,
  ],
  "Burt County, Nebraska": [68019, 68020, 68038, 68045, 68061],
  "Dodge County, Nebraska": [
    68025, 68026, 68031, 68044, 68057, 68063, 68072, 68621, 68633, 68649, 68664,
  ],
  "Dakota County, Nebraska": [68030, 68731, 68741, 68743, 68776],
  "Thurston County, Nebraska": [68039, 68047, 68055, 68062, 68067, 68071],
  "Gage County, Nebraska": [
    68301, 68309, 68310, 68318, 68328, 68331, 68357, 68381, 68415, 68422, 68458,
    68466,
  ],
  "Thayer County, Nebraska": [
    68303, 68315, 68322, 68325, 68326, 68327, 68335, 68340, 68362, 68370, 68375,
  ],
  "Nemaha County, Nebraska": [68305, 68320, 68321, 68378, 68379, 68414, 68421],
  "Seward County, Nebraska": [
    68313, 68314, 68330, 68360, 68364, 68405, 68423, 68434, 68439, 68456,
  ],
  "York County, Nebraska": [68316, 68319, 68367, 68371, 68401, 68460, 68467],
  "Lancaster County, Nebraska": [
    68317, 68336, 68339, 68358, 68368, 68372, 68402, 68404, 68419, 68428, 68430,
    68438, 68461, 68462, 68501, 68502, 68503, 68504, 68505, 68506, 68507, 68508,
    68509, 68510, 68512, 68514, 68516, 68517, 68520, 68521, 68522, 68523, 68524,
    68526, 68527, 68528, 68529, 68531, 68532, 68542, 68583, 68588,
  ],
  "Pawnee County, Nebraska": [68323, 68345, 68380, 68420, 68441, 68447],
  "Otoe County, Nebraska": [
    68324, 68344, 68346, 68382, 68410, 68417, 68418, 68446, 68448, 68454,
  ],
  "Johnson County, Nebraska": [68329, 68332, 68348, 68443, 68450],
  "Saline County, Nebraska": [
    68333, 68341, 68343, 68359, 68445, 68453, 68464, 68465,
  ],
  "Richardson County, Nebraska": [
    68337, 68355, 68376, 68431, 68433, 68437, 68442, 68457,
  ],
  "Jefferson County, Nebraska": [
    68338, 68342, 68350, 68352, 68377, 68424, 68429, 68440,
  ],
  "Fillmore County, Nebraska": [
    68351, 68354, 68361, 68365, 68406, 68416, 68436, 68444,
  ],
  "Clay County, Nebraska": [
    68452, 68933, 68934, 68935, 68938, 68941, 68944, 68954, 68975, 68979, 68980,
  ],
  "undefined, Nebraska": [68544],
  "Platte County, Nebraska": [
    68601, 68602, 68631, 68634, 68642, 68644, 68647, 68653,
  ],
  "Boone County, Nebraska": [68620, 68627, 68652, 68655, 68660],
  "Wheeler County, Nebraska": [68622, 68637],
  "Nance County, Nebraska": [68623, 68638, 68640],
  "Merrick County, Nebraska": [68628, 68663, 68816, 68826, 68827, 68864],
  "Colfax County, Nebraska": [68629, 68641, 68643, 68659, 68661],
  "Antelope County, Nebraska": [
    68636, 68720, 68726, 68756, 68761, 68764, 68773,
  ],
  "Polk County, Nebraska": [68651, 68654, 68662, 68666],
  "Greeley County, Nebraska": [68665, 68842, 68875, 68882],
  "Madison County, Nebraska": [68701, 68702, 68715, 68748, 68752, 68758, 68781],
  "Dixon County, Nebraska": [
    68710, 68728, 68732, 68733, 68751, 68757, 68770, 68784, 68785,
  ],
  "Holt County, Nebraska": [
    68711, 68713, 68725, 68734, 68735, 68742, 68763, 68766, 68780,
  ],
  "Rock County, Nebraska": [68714, 68759],
  "Cedar County, Nebraska": [
    68717, 68727, 68736, 68739, 68745, 68749, 68771, 68774, 68792,
  ],
  "Knox County, Nebraska": [
    68718, 68724, 68729, 68730, 68760, 68783, 68786, 68789,
  ],
  "Boyd County, Nebraska": [68719, 68722, 68746, 68755, 68777],
  "Wayne County, Nebraska": [68723, 68740, 68787, 68790],
  "Pierce County, Nebraska": [68738, 68747, 68765, 68767, 68769],
  "Keya Paha County, Nebraska": [68753, 68778],
  "Stanton County, Nebraska": [68768, 68779],
  "Hall County, Nebraska": [68801, 68802, 68803, 68810, 68824, 68832, 68883],
  "Buffalo County, Nebraska": [
    68812, 68836, 68840, 68845, 68847, 68848, 68849, 68858, 68861, 68866, 68869,
    68870, 68876,
  ],
  "Custer County, Nebraska": [
    68813, 68814, 68822, 68825, 68828, 68855, 68856, 68860, 68874, 68881, 69120,
  ],
  "Valley County, Nebraska": [68815, 68837, 68859, 68862],
  "Sherman County, Nebraska": [68817, 68844, 68852, 68853, 68871],
  "Hamilton County, Nebraska": [68818, 68841, 68843, 68846, 68854, 68865],
  "Howard County, Nebraska": [68820, 68831, 68835, 68838, 68872, 68873],
  "Blaine County, Nebraska": [68821, 68833, 69157],
  "Garfield County, Nebraska": [68823],
  "Dawson County, Nebraska": [
    68834, 68850, 68863, 68878, 69029, 69130, 69138, 69171,
  ],
  "Loup County, Nebraska": [68879],
  "Adams County, Nebraska": [68901, 68902, 68925, 68950, 68955, 68956, 68973],
  "Harlan County, Nebraska": [68920, 68966, 68969, 68971, 68977],
  "Furnas County, Nebraska": [
    68922, 68926, 68936, 68946, 68948, 68967, 69022, 69046,
  ],
  "Phelps County, Nebraska": [68923, 68927, 68940, 68949, 68958],
  "Kearney County, Nebraska": [68924, 68945, 68959, 68982],
  "Webster County, Nebraska": [68928, 68930, 68942, 68952, 68970],
  "Franklin County, Nebraska": [
    68929, 68932, 68939, 68947, 68960, 68972, 68981,
  ],
  "Gosper County, Nebraska": [68937, 68976],
  "Nuckolls County, Nebraska": [68943, 68957, 68961, 68964, 68974, 68978],
  "Red Willow County, Nebraska": [69001, 69020, 69026, 69034, 69036],
  "Dundy County, Nebraska": [69021, 69030, 69037, 69041],
  "Chase County, Nebraska": [69023, 69027, 69033, 69045],
  "Hitchcock County, Nebraska": [69024, 69040, 69043, 69044],
  "Frontier County, Nebraska": [69025, 69028, 69038, 69039, 69042],
  "Hayes County, Nebraska": [69032],
  "Lincoln County, Nebraska": [
    69101, 69103, 69123, 69132, 69143, 69151, 69165, 69169, 69170,
  ],
  "Arthur County, Nebraska": [69121],
  "Deuel County, Nebraska": [69122, 69129],
  "Morrill County, Nebraska": [69125, 69331, 69334, 69336],
  "Keith County, Nebraska": [69127, 69144, 69146, 69153, 69155],
  "Kimball County, Nebraska": [69128, 69133, 69145],
  "Cheyenne County, Nebraska": [69131, 69141, 69149, 69156, 69160, 69162],
  "Perkins County, Nebraska": [69134, 69140, 69150, 69168],
  "Cherry County, Nebraska": [
    69135, 69201, 69211, 69216, 69218, 69219, 69220, 69221,
  ],
  "Thomas County, Nebraska": [69142, 69161, 69166],
  "Garden County, Nebraska": [69147, 69148, 69154, 69190],
  "Hooker County, Nebraska": [69152],
  "Logan County, Nebraska": [69163],
  "McPherson County, Nebraska": [69167],
  "Brown County, Nebraska": [69210, 69214, 69217],
  "Todd County, Nebraska": [69212],
  "Box Butte County, Nebraska": [69301, 69348],
  "Grant County, Nebraska": [69333, 69350, 69366],
  "Sheridan County, Nebraska": [
    69335, 69340, 69343, 69347, 69351, 69360, 69365,
  ],
  "Dawes County, Nebraska": [69337, 69339, 69354, 69367],
  "Scotts Bluff County, Nebraska": [
    69341, 69352, 69353, 69355, 69356, 69357, 69358, 69361, 69363,
  ],
  "Banner County, Nebraska": [69345],
  "Sioux County, Nebraska": [69346],
  "Jefferson Parish, Louisiana": [
    70001, 70002, 70003, 70004, 70005, 70006, 70009, 70010, 70011, 70033, 70036,
    70053, 70054, 70055, 70056, 70058, 70059, 70060, 70062, 70063, 70064, 70065,
    70067, 70072, 70073, 70094, 70096, 70097, 70121, 70123, 70358,
  ],
  "St. Charles Parish, Louisiana": [
    70030, 70031, 70039, 70047, 70057, 70070, 70078, 70079, 70080, 70087,
  ],
  "St. Bernard Parish, Louisiana": [70032, 70043, 70075, 70085, 70092],
  "Plaquemines Parish, Louisiana": [
    70037, 70038, 70040, 70041, 70050, 70081, 70082, 70083, 70091, 70093,
  ],
  "St Bernard Parish, Louisiana": [70044],
  "St. John the Baptist Parish, Louisiana": [
    70049, 70051, 70068, 70069, 70076, 70084,
  ],
  "St. James Parish, Louisiana": [
    70052, 70071, 70086, 70090, 70723, 70743, 70763,
  ],
  "Orleans Parish, Louisiana": [
    70112, 70113, 70114, 70115, 70116, 70117, 70118, 70119, 70122, 70124, 70125,
    70126, 70127, 70128, 70129, 70130, 70131, 70139, 70140, 70141, 70142, 70143,
    70145, 70146, 70148, 70149, 70150, 70151, 70152, 70153, 70154, 70156, 70157,
    70158, 70159, 70160, 70161, 70162, 70163, 70164, 70165, 70166, 70167, 70170,
    70172, 70174, 70175, 70176, 70177, 70178, 70179, 70181, 70182, 70183, 70184,
    70185, 70186, 70187, 70189, 70190, 70195,
  ],
  "Lafourche Parish, Louisiana": [
    70301, 70302, 70310, 70345, 70354, 70355, 70357, 70371, 70373, 70374, 70375,
    70394,
  ],
  "Assumption Parish, Louisiana": [70339, 70341, 70372, 70390, 70391, 70393],
  "St. Mary Parish, Louisiana": [
    70340, 70342, 70380, 70381, 70392, 70514, 70522, 70523, 70538,
  ],
  "Terrebonne Parish, Louisiana": [
    70343, 70344, 70352, 70353, 70356, 70359, 70360, 70361, 70363, 70364, 70377,
    70395, 70397,
  ],
  "Ascension Parish, Louisiana": [
    70346, 70707, 70718, 70725, 70728, 70734, 70737, 70738, 70769, 70774, 70778,
  ],
  "Tangipahoa Parish, Louisiana": [
    70401, 70402, 70403, 70404, 70421, 70422, 70436, 70442, 70443, 70444, 70446,
    70451, 70454, 70455, 70456, 70465, 70466,
  ],
  "St. Tammany Parish, Louisiana": [
    70420, 70431, 70433, 70434, 70435, 70437, 70445, 70447, 70448, 70452, 70457,
    70458, 70459, 70460, 70461, 70463, 70464, 70469, 70471,
  ],
  "Washington Parish, Louisiana": [70426, 70427, 70429, 70438, 70450, 70467],
  "St. Helena Parish, Louisiana": [70441, 70453],
  "Livingston Parish, Louisiana": [
    70449, 70462, 70706, 70711, 70726, 70727, 70733, 70744, 70754, 70785, 70786,
  ],
  "St Tammany Parish, Louisiana": [70470],
  "Lafayette Parish, Louisiana": [
    70500, 70501, 70502, 70503, 70504, 70505, 70506, 70507, 70508, 70509, 70518,
    70520, 70529, 70558, 70583, 70592, 70593, 70595, 70596, 70598,
  ],
  "Vermilion Parish, Louisiana": [
    70510, 70511, 70528, 70533, 70542, 70548, 70555, 70575,
  ],
  "St. Landry Parish, Louisiana": [
    70512, 70535, 70541, 70550, 70551, 70570, 70571, 70577, 70584, 70589, 70750,
    71345, 71353, 71356, 71358,
  ],
  "Iberia Parish, Louisiana": [70513, 70544, 70552, 70560, 70562, 70563, 70569],
  "Evangeline Parish, Louisiana": [
    70515, 70524, 70554, 70576, 70580, 70585, 70586, 71367,
  ],
  "Acadia Parish, Louisiana": [
    70516, 70525, 70526, 70527, 70531, 70534, 70537, 70543, 70556, 70559, 70578,
  ],
  "St. Martin Parish, Louisiana": [70517, 70519, 70582],
  "St Martin Parish, Louisiana": [70521],
  "Jefferson Davis Parish, Louisiana": [
    70532, 70546, 70549, 70581, 70591, 70640, 70650,
  ],
  "St Mary Parish, Louisiana": [70540],
  "Calcasieu Parish, Louisiana": [
    70601, 70602, 70605, 70606, 70607, 70609, 70611, 70612, 70615, 70616, 70629,
    70630, 70633, 70646, 70647, 70661, 70663, 70664, 70665, 70668, 70669,
  ],
  "Cameron Parish, Louisiana": [70631, 70632, 70643, 70645],
  "Beauregard Parish, Louisiana": [70634, 70652, 70653, 70657, 70660, 70662],
  "Allen Parish, Louisiana": [
    70637, 70638, 70644, 70648, 70651, 70654, 70655, 70658, 71463,
  ],
  "Vernon Parish, Louisiana": [
    70639, 70656, 70659, 71403, 71439, 71443, 71446, 71459, 71461, 71474, 71475,
    71496,
  ],
  "East Baton Rouge Parish, Louisiana": [
    70704, 70714, 70739, 70770, 70791, 70801, 70802, 70803, 70804, 70805, 70806,
    70807, 70808, 70809, 70810, 70811, 70812, 70813, 70814, 70815, 70816, 70817,
    70818, 70819, 70820, 70821, 70822, 70823, 70825, 70826, 70827, 70831, 70833,
    70835, 70836, 70837, 70873, 70874, 70879, 70883, 70884, 70891, 70892, 70893,
    70894, 70895, 70896, 70898,
  ],
  "West Baton Rouge Parish, Louisiana": [70710, 70719, 70729, 70767],
  "West Feliciana Parish, Louisiana": [70712, 70775, 70782, 70784, 70787],
  "Pointe Coupee Parish, Louisiana": [
    70715, 70732, 70736, 70747, 70749, 70752, 70753, 70755, 70756, 70759, 70760,
    70762, 70773, 70783,
  ],
  "Iberville Parish, Louisiana": [
    70721, 70740, 70757, 70764, 70765, 70772, 70776, 70780, 70788,
  ],
  "East Feliciana Parish, Louisiana": [
    70722, 70730, 70748, 70761, 70777, 70789,
  ],
  "St James Parish, Louisiana": [70792],
  "Bienville Parish, Louisiana": [
    71001, 71008, 71016, 71028, 71045, 71068, 71070, 71080,
  ],
  "Natchitoches Parish, Louisiana": [
    71002, 71031, 71066, 71411, 71414, 71416, 71428, 71434, 71450, 71452, 71456,
    71457, 71458, 71468, 71469, 71497,
  ],
  "Claiborne Parish, Louisiana": [71003, 71038, 71040, 71048, 71079],
  "Caddo Parish, Louisiana": [
    71004, 71007, 71009, 71029, 71033, 71043, 71044, 71047, 71060, 71061, 71069,
    71082, 71101, 71102, 71103, 71104, 71105, 71106, 71107, 71108, 71109, 71115,
    71118, 71119, 71120, 71129, 71130, 71133, 71134, 71135, 71136, 71137, 71138,
    71148, 71149, 71150, 71151, 71152, 71153, 71154, 71156, 71161, 71162, 71163,
    71164, 71165, 71166,
  ],
  "Bossier Parish, Louisiana": [
    71006, 71037, 71051, 71064, 71067, 71110, 71111, 71112, 71113, 71171, 71172,
  ],
  "Webster Parish, Louisiana": [
    71018, 71021, 71023, 71024, 71039, 71055, 71058, 71071, 71072, 71073, 71075,
  ],
  "Red River Parish, Louisiana": [71019, 71034],
  "De Soto Parish, Louisiana": [
    71027, 71030, 71032, 71046, 71049, 71050, 71052, 71063, 71078,
  ],
  "Sabine Parish, Louisiana": [
    71065, 71406, 71419, 71426, 71429, 71449, 71460, 71462, 71486,
  ],
  "Ouachita Parish, Louisiana": [
    71201, 71202, 71203, 71207, 71208, 71209, 71210, 71211, 71212, 71213, 71217,
    71225, 71238, 71240, 71280, 71281, 71291, 71292, 71294,
  ],
  "Richland Parish, Louisiana": [71218, 71232, 71259, 71264, 71269, 71279],
  "Franklin Parish, Louisiana": [
    71219, 71230, 71243, 71249, 71295, 71324, 71336, 71378,
  ],
  "Morehouse Parish, Louisiana": [71220, 71221, 71223, 71229, 71250, 71261],
  "Union Parish, Louisiana": [71222, 71234, 71241, 71256, 71260, 71277],
  "Jackson Parish, Louisiana": [71226, 71247, 71251, 71268],
  "Lincoln Parish, Louisiana": [
    71227, 71235, 71245, 71270, 71272, 71273, 71275,
  ],
  "Madison Parish, Louisiana": [71233, 71282, 71284],
  "West Carroll Parish, Louisiana": [71237, 71242, 71253, 71263, 71266],
  "East Carroll Parish, Louisiana": [71254, 71276, 71286],
  "Rapides Parish, Louisiana": [
    71301, 71302, 71303, 71306, 71307, 71309, 71315, 71325, 71328, 71330, 71346,
    71348, 71359, 71360, 71361, 71365, 71405, 71409, 71424, 71427, 71430, 71431,
    71433, 71438, 71447, 71448, 71455, 71466, 71472, 71477, 71485,
  ],
  "Concordia Parish, Louisiana": [71316, 71326, 71334, 71354, 71373, 71377],
  "Avoyelles Parish, Louisiana": [
    71320, 71322, 71323, 71327, 71329, 71331, 71333, 71339, 71341, 71350, 71351,
    71355, 71362, 71369,
  ],
  "Catahoula Parish, Louisiana": [71340, 71343, 71363, 71368, 71401, 71425],
  "LaSalle Parish, Louisiana": [71342, 71371, 71465, 71479, 71480],
  "Tensas Parish, Louisiana": [71357, 71366, 71375],
  "Winn Parish, Louisiana": [71404, 71410, 71422, 71440, 71471, 71473, 71483],
  "Grant Parish, Louisiana": [71407, 71417, 71423, 71432, 71454, 71467],
  "Caldwell Parish, Louisiana": [71415, 71418, 71435, 71441],
  "Jefferson County, Arkansas": [
    71601, 71602, 71603, 71611, 71612, 71613, 71659, 72004, 72079, 72132, 72133,
    72152, 72168, 72175, 72182,
  ],
  "Desha County, Arkansas": [
    71630, 71639, 71654, 71662, 71666, 71670, 71674, 72379,
  ],
  "Bradley County, Arkansas": [71631, 71647, 71651, 71671],
  "Ashley County, Arkansas": [71635, 71642, 71646, 71658, 71661, 71663, 71676],
  "Chicot County, Arkansas": [71638, 71640, 71653],
  "Lincoln County, Arkansas": [71643, 71644, 71667, 71678],
  "Cleveland County, Arkansas": [71652, 71660, 71665],
  "Drew County, Arkansas": [71655, 71656, 71657, 71675, 71677],
  "Ouachita County, Arkansas": [71701, 71720, 71726, 71751, 71764],
  "Calhoun County, Arkansas": [71711, 71744, 71745, 71766],
  "Clark County, Arkansas": [
    71721, 71728, 71743, 71772, 71920, 71921, 71923, 71962, 71998, 71999,
  ],
  "Nevada County, Arkansas": [71722, 71828, 71835, 71844, 71857, 71858, 71864],
  "Union County, Arkansas": [
    71724, 71730, 71731, 71747, 71749, 71750, 71758, 71759, 71762, 71765, 71768,
  ],
  "Dallas County, Arkansas": [71725, 71742, 71748, 71763],
  "Columbia County, Arkansas": [71740, 71752, 71753, 71754, 71770, 71861],
  "Hempstead County, Arkansas": [
    71801, 71802, 71825, 71838, 71847, 71855, 71859, 71862,
  ],
  "Little River County, Arkansas": [71820, 71822, 71836, 71853, 71865, 71866],
  "Sevier County, Arkansas": [71823, 71832, 71841, 71842, 71846],
  "Lafayette County, Arkansas": [71826, 71827, 71845, 71860],
  "Howard County, Arkansas": [71831, 71833, 71851, 71852, 71971],
  "Miller County, Arkansas": [71834, 71837, 71839, 71840, 71854],
  "Garland County, Arkansas": [
    71901, 71902, 71903, 71909, 71913, 71914, 71933, 71949, 71951, 71956, 71964,
    71968,
  ],
  "Saline County, Arkansas": [
    71910, 72002, 72011, 72015, 72018, 72019, 72022, 72065, 72087, 72089, 72103,
    72122, 72158, 72167,
  ],
  "Pike County, Arkansas": [71922, 71940, 71943, 71950, 71952, 71958, 71959],
  "Hot Spring County, Arkansas": [71929, 71941, 71942, 72104, 72105],
  "Polk County, Arkansas": [71932, 71937, 71944, 71945, 71953, 71972, 71973],
  "Montgomery County, Arkansas": [
    71935, 71957, 71960, 71961, 71965, 71966, 71969, 71970,
  ],
  "Perry County, Arkansas": [72001, 72016, 72025, 72070, 72125, 72126],
  "Arkansas County, Arkansas": [
    72003, 72026, 72038, 72042, 72048, 72055, 72073, 72140, 72160, 72166,
  ],
  "Jackson County, Arkansas": [
    72005, 72014, 72043, 72075, 72112, 72169, 72431, 72471, 72473,
  ],
  "Woodruff County, Arkansas": [
    72006, 72036, 72059, 72074, 72101, 72123, 72189,
  ],
  "Lonoke County, Arkansas": [
    72007, 72023, 72024, 72037, 72046, 72072, 72083, 72086, 72176,
  ],
  "White County, Arkansas": [
    72010, 72012, 72020, 72045, 72052, 72060, 72068, 72081, 72082, 72085, 72102,
    72121, 72136, 72137, 72139, 72143, 72145, 72149, 72178,
  ],
  "Van Buren County, Arkansas": [
    72013, 72028, 72031, 72088, 72141, 72153, 72629,
  ],
  "Prairie County, Arkansas": [72017, 72040, 72041, 72064, 72066, 72170],
  "Monroe County, Arkansas": [72021, 72029, 72069, 72108, 72134],
  "Conway County, Arkansas": [
    72027, 72030, 72063, 72080, 72107, 72110, 72127, 72156, 72157,
  ],
  "Faulkner County, Arkansas": [
    72032, 72033, 72034, 72035, 72039, 72047, 72058, 72061, 72106, 72111, 72173,
    72181,
  ],
  "Cleburne County, Arkansas": [
    72044, 72067, 72130, 72131, 72179, 72523, 72530, 72543, 72545, 72546, 72581,
  ],
  "Stone County, Arkansas": [
    72051, 72533, 72555, 72560, 72567, 72657, 72663, 72680,
  ],
  "Pulaski County, Arkansas": [
    72053, 72076, 72078, 72099, 72113, 72114, 72115, 72116, 72117, 72118, 72119,
    72120, 72124, 72135, 72142, 72164, 72180, 72183, 72190, 72198, 72199, 72201,
    72202, 72203, 72204, 72205, 72206, 72207, 72209, 72210, 72211, 72212, 72214,
    72215, 72216, 72217, 72219, 72221, 72222, 72223, 72225, 72227, 72231, 72260,
    72295,
  ],
  "Grant County, Arkansas": [72057, 72084, 72128, 72129, 72150],
  "Independence County, Arkansas": [
    72165, 72501, 72503, 72522, 72524, 72526, 72527, 72534, 72550, 72553, 72562,
    72564, 72568, 72571, 72575, 72579,
  ],
  "undefined, Arkansas": [72255],
  "Crittenden County, Arkansas": [
    72301, 72303, 72325, 72327, 72331, 72332, 72339, 72364, 72376, 72384,
  ],
  "Mississippi County, Arkansas": [
    72310, 72313, 72315, 72316, 72319, 72321, 72329, 72330, 72338, 72350, 72351,
    72358, 72370, 72391, 72395, 72426, 72428, 72438, 72442,
  ],
  "Lee County, Arkansas": [72311, 72320, 72341, 72352, 72360, 72368],
  "Phillips County, Arkansas": [
    72312, 72328, 72333, 72342, 72353, 72355, 72366, 72367, 72369, 72374, 72383,
    72389, 72390,
  ],
  "St. Francis County, Arkansas": [
    72322, 72326, 72335, 72336, 72340, 72346, 72348, 72359, 72372, 72392, 72394,
  ],
  "Cross County, Arkansas": [72324, 72347, 72373, 72387, 72396],
  "Poinsett County, Arkansas": [
    72354, 72365, 72377, 72386, 72429, 72432, 72472, 72475, 72479,
  ],
  "Craighead County, Arkansas": [
    72401, 72402, 72403, 72404, 72405, 72411, 72414, 72416, 72417, 72419, 72421,
    72427, 72437, 72447, 72467,
  ],
  "Lawrence County, Arkansas": [
    72410, 72415, 72433, 72434, 72440, 72445, 72457, 72458, 72459, 72465, 72466,
    72469, 72476, 72572,
  ],
  "Greene County, Arkansas": [
    72412, 72425, 72436, 72439, 72443, 72450, 72451, 72474,
  ],
  "Randolph County, Arkansas": [
    72413, 72444, 72449, 72455, 72460, 72462, 72478,
  ],
  "Clay County, Arkansas": [
    72422, 72424, 72430, 72435, 72441, 72453, 72454, 72456, 72461, 72464, 72470,
  ],
  "Sharp County, Arkansas": [72482, 72513, 72521, 72529, 72532, 72542, 72569],
  "Izard County, Arkansas": [
    72512, 72517, 72519, 72528, 72536, 72540, 72556, 72561, 72565, 72566, 72573,
    72577, 72584, 72585, 72587,
  ],
  "Fulton County, Arkansas": [
    72515, 72520, 72525, 72538, 72539, 72554, 72576, 72578, 72583,
  ],
  "Baxter County, Arkansas": [
    72531, 72537, 72544, 72617, 72623, 72626, 72635, 72642, 72651, 72653, 72654,
    72658, 72659,
  ],
  "Boone County, Arkansas": [72601, 72602, 72615, 72630, 72633, 72644, 72662],
  "Carroll County, Arkansas": [72611, 72613, 72616, 72631, 72632, 72638, 72660],
  "Marion County, Arkansas": [
    72619, 72634, 72661, 72668, 72672, 72677, 72682, 72687,
  ],
  "Newton County, Arkansas": [
    72624, 72628, 72640, 72641, 72648, 72655, 72666, 72670, 72683, 72685, 72856,
  ],
  "Searcy County, Arkansas": [
    72636, 72639, 72645, 72650, 72669, 72675, 72679, 72686,
  ],
  "Washington County, Arkansas": [
    72701, 72702, 72703, 72704, 72717, 72727, 72729, 72730, 72735, 72737, 72741,
    72744, 72749, 72753, 72762, 72764, 72765, 72766, 72769, 72770, 72774, 72959,
  ],
  "Benton County, Arkansas": [
    72711, 72712, 72713, 72714, 72715, 72716, 72718, 72719, 72722, 72728, 72732,
    72733, 72734, 72736, 72739, 72745, 72747, 72751, 72756, 72757, 72758, 72761,
    72768,
  ],
  "Madison County, Arkansas": [
    72721, 72738, 72740, 72742, 72752, 72760, 72773, 72776,
  ],
  "Pope County, Arkansas": [
    72801, 72802, 72811, 72812, 72823, 72837, 72843, 72847, 72858,
  ],
  "Franklin County, Arkansas": [72820, 72821, 72928, 72930, 72933, 72949],
  "Yell County, Arkansas": [
    72824, 72827, 72828, 72829, 72833, 72834, 72838, 72842, 72853, 72857, 72860,
  ],
  "Logan County, Arkansas": [
    72826, 72835, 72851, 72855, 72863, 72865, 72927, 72943, 72951,
  ],
  "Johnson County, Arkansas": [
    72830, 72832, 72839, 72840, 72845, 72846, 72852, 72854,
  ],
  "Scott County, Arkansas": [72841, 72926, 72950, 72958],
  "Sebastian County, Arkansas": [
    72901, 72902, 72903, 72904, 72905, 72906, 72908, 72913, 72914, 72916, 72917,
    72918, 72919, 72923, 72936, 72937, 72938, 72940, 72941, 72944, 72945,
  ],
  "Crawford County, Arkansas": [
    72921, 72932, 72934, 72935, 72946, 72947, 72948, 72952, 72955, 72956, 72957,
  ],
  "Caddo County, Oklahoma": [
    73001, 73005, 73006, 73009, 73015, 73017, 73029, 73033, 73038, 73042, 73047,
    73048, 73053, 73094,
  ],
  "Grady County, Oklahoma": [
    73002, 73004, 73011, 73018, 73023, 73059, 73067, 73079, 73082, 73089, 73092,
  ],
  "Oklahoma County, Oklahoma": [
    73003, 73007, 73008, 73012, 73013, 73020, 73025, 73034, 73045, 73049, 73054,
    73066, 73083, 73084, 73097, 73101, 73102, 73103, 73104, 73105, 73106, 73107,
    73108, 73109, 73110, 73111, 73112, 73113, 73114, 73115, 73116, 73117, 73118,
    73119, 73120, 73121, 73122, 73123, 73124, 73125, 73126, 73127, 73129, 73130,
    73131, 73132, 73134, 73135, 73136, 73137, 73139, 73140, 73141, 73142, 73143,
    73144, 73145, 73146, 73147, 73148, 73149, 73150, 73151, 73152, 73153, 73154,
    73155, 73156, 73157, 73159, 73162, 73163, 73164, 73167, 73169, 73172, 73178,
    73179, 73184, 73185, 73189, 73190, 73193, 73194, 73195, 73196, 73197, 73198,
    73199,
  ],
  "McClain County, Oklahoma": [73010, 73031, 73065, 73080, 73093, 73095, 74831],
  "Canadian County, Oklahoma": [
    73014, 73022, 73036, 73064, 73078, 73085, 73090, 73099, 73128,
  ],
  "Kingfisher County, Oklahoma": [
    73016, 73734, 73742, 73750, 73756, 73762, 73764,
  ],
  "Cleveland County, Oklahoma": [
    73019, 73026, 73051, 73068, 73069, 73070, 73071, 73072, 73160, 73165, 73170,
    73173, 74857,
  ],
  "Washita County, Oklahoma": [
    73021, 73024, 73622, 73624, 73626, 73632, 73641, 73647, 73661, 73664,
  ],
  "Logan County, Oklahoma": [
    73027, 73028, 73044, 73050, 73056, 73058, 73063, 73073,
  ],
  "Murray County, Oklahoma": [73030, 73032, 73039, 73086],
  "Blaine County, Oklahoma": [
    73040, 73043, 73646, 73724, 73744, 73755, 73763, 73770, 73772,
  ],
  "Kiowa County, Oklahoma": [73041, 73062, 73559, 73564, 73566, 73651, 73655],
  "Garvin County, Oklahoma": [73052, 73057, 73074, 73075, 73098, 73433, 74872],
  "Stephens County, Oklahoma": [
    73055, 73425, 73434, 73442, 73491, 73529, 73533, 73534, 73536, 73548,
  ],
  "Noble County, Oklahoma": [73061, 73077, 73757, 74630, 74644, 74651],
  "Custer County, Oklahoma": [73096, 73601, 73620, 73625, 73639, 73669],
  "Travis County, Texas": [
    73301, 73344, 78615, 78617, 78645, 78652, 78653, 78660, 78669, 78691, 78701,
    78702, 78703, 78704, 78705, 78708, 78709, 78710, 78711, 78712, 78713, 78714,
    78715, 78716, 78718, 78719, 78720, 78721, 78722, 78723, 78724, 78725, 78726,
    78727, 78728, 78730, 78731, 78732, 78733, 78734, 78735, 78736, 78738, 78739,
    78741, 78742, 78744, 78745, 78746, 78747, 78748, 78749, 78750, 78751, 78752,
    78753, 78754, 78755, 78756, 78757, 78758, 78759, 78760, 78761, 78762, 78763,
    78764, 78765, 78766, 78767, 78768, 78769, 78772, 78773, 78774, 78778, 78779,
    78780, 78781, 78783, 78785, 78786, 78788, 78789, 78798, 78799,
  ],
  "Carter County, Oklahoma": [
    73401, 73402, 73403, 73435, 73436, 73437, 73438, 73443, 73444, 73458, 73463,
    73481, 73487, 73488,
  ],
  "Love County, Oklahoma": [73430, 73441, 73448, 73453, 73459],
  "Johnston County, Oklahoma": [
    73432, 73447, 73450, 73455, 73460, 73461, 74530, 74748, 74836, 74856,
  ],
  "Marshall County, Oklahoma": [73439, 73440, 73446],
  "Bryan County, Oklahoma": [
    73449, 74701, 74702, 74720, 74721, 74723, 74726, 74729, 74730, 74731, 74733,
    74741, 74747, 74753,
  ],
  "undefined, Oklahoma": [73451, 73454],
  "Jefferson County, Oklahoma": [73456, 73520, 73561, 73565, 73569, 73573],
  "Comanche County, Oklahoma": [
    73501, 73502, 73503, 73505, 73506, 73507, 73527, 73528, 73538, 73540, 73541,
    73543, 73552, 73557, 73558, 73567,
  ],
  "Jackson County, Oklahoma": [
    73521, 73522, 73523, 73526, 73532, 73537, 73539, 73549, 73556, 73560,
  ],
  "Tillman County, Oklahoma": [73530, 73542, 73546, 73551, 73553, 73555, 73570],
  "Cotton County, Oklahoma": [73531, 73562, 73568, 73572],
  "Harmon County, Oklahoma": [73544, 73550, 73571],
  "Greer County, Oklahoma": [73547, 73554, 73673],
  "Beckham County, Oklahoma": [73627, 73644, 73645, 73648, 73662, 73668],
  "Roger Mills County, Oklahoma": [73628, 73638, 73642, 73650, 73660, 73666],
  "Dewey County, Oklahoma": [73654, 73658, 73659, 73663, 73667, 73835, 73859],
  "Garfield County, Oklahoma": [
    73701, 73702, 73703, 73705, 73706, 73720, 73727, 73730, 73733, 73735, 73736,
    73738, 73743, 73753, 73754, 73773, 74640,
  ],
  "Alfalfa County, Oklahoma": [
    73716, 73719, 73722, 73726, 73728, 73739, 73741, 73749,
  ],
  "Woods County, Oklahoma": [73717, 73731, 73746, 73842, 73860],
  "Major County, Oklahoma": [73718, 73729, 73737, 73747, 73760, 73768, 73838],
  "Grant County, Oklahoma": [73758, 73759, 73761, 73766, 73771, 74636, 74643],
  "Woodward County, Oklahoma": [73801, 73802, 73841, 73852, 73853, 73857],
  "Ellis County, Oklahoma": [73832, 73840, 73843, 73858],
  "Harper County, Oklahoma": [73834, 73848, 73851, 73855],
  "Beaver County, Oklahoma": [73844, 73931, 73932, 73938, 73950],
  "Texas County, Oklahoma": [73901, 73939, 73942, 73944, 73945, 73949, 73951],
  "Cimarron County, Oklahoma": [73933, 73937, 73946, 73947],
  "Sherman County, Texas": [73960, 79084],
  "Osage County, Oklahoma": [
    74001, 74002, 74035, 74054, 74056, 74060, 74070, 74084, 74633, 74637, 74652,
  ],
  "Washington County, Oklahoma": [
    74003, 74004, 74005, 74006, 74022, 74029, 74051, 74061, 74082,
  ],
  "Tulsa County, Oklahoma": [
    74008, 74011, 74012, 74021, 74033, 74037, 74043, 74055, 74063, 74073, 74101,
    74102, 74103, 74104, 74105, 74106, 74107, 74108, 74110, 74112, 74114, 74115,
    74116, 74117, 74119, 74120, 74121, 74126, 74127, 74128, 74129, 74130, 74132,
    74133, 74134, 74135, 74136, 74137, 74141, 74145, 74146, 74147, 74148, 74149,
    74150, 74152, 74153, 74155, 74156, 74157, 74158, 74159, 74169, 74170, 74171,
    74172, 74182, 74183, 74184, 74186, 74187, 74189, 74192, 74193, 74194,
  ],
  "Creek County, Oklahoma": [
    74010, 74028, 74030, 74039, 74041, 74044, 74046, 74050, 74052, 74066, 74067,
    74068, 74071, 74131,
  ],
  "Wagoner County, Oklahoma": [
    74013, 74014, 74429, 74446, 74454, 74458, 74467, 74477,
  ],
  "Rogers County, Oklahoma": [
    74015, 74016, 74017, 74018, 74019, 74031, 74036, 74053, 74080,
  ],
  "Pawnee County, Oklahoma": [74020, 74034, 74038, 74045, 74058, 74081, 74650],
  "Payne County, Oklahoma": [
    74023, 74032, 74059, 74062, 74074, 74075, 74076, 74077, 74078, 74085,
  ],
  "Lincoln County, Oklahoma": [
    74026, 74079, 74824, 74832, 74834, 74855, 74864, 74869, 74875, 74881,
  ],
  "Nowata County, Oklahoma": [74027, 74042, 74048, 74072, 74083],
  "Okmulgee County, Oklahoma": [
    74047, 74421, 74431, 74437, 74445, 74447, 74456, 74460,
  ],
  "Craig County, Oklahoma": [74301, 74333, 74349, 74369],
  "Mayes County, Oklahoma": [
    74330, 74332, 74337, 74340, 74350, 74352, 74361, 74362, 74365, 74366, 74367,
    74452,
  ],
  "Delaware County, Oklahoma": [
    74331, 74338, 74342, 74344, 74345, 74346, 74347, 74359, 74364, 74368,
  ],
  "Ottawa County, Oklahoma": [
    74335, 74339, 74343, 74354, 74355, 74358, 74360, 74363, 74370,
  ],
  "Muskogee County, Oklahoma": [
    74401, 74402, 74403, 74422, 74423, 74434, 74436, 74439, 74450, 74455, 74463,
    74468, 74469, 74470,
  ],
  "Pittsburg County, Oklahoma": [
    74425, 74430, 74442, 74501, 74502, 74522, 74528, 74529, 74546, 74547, 74553,
    74554, 74560, 74561, 74565,
  ],
  "McIntosh County, Oklahoma": [
    74426, 74428, 74432, 74438, 74459, 74461, 74845,
  ],
  "Cherokee County, Oklahoma": [
    74427, 74441, 74444, 74451, 74457, 74464, 74465, 74471,
  ],
  "Sequoyah County, Oklahoma": [
    74435, 74936, 74945, 74946, 74948, 74954, 74955, 74962,
  ],
  "Haskell County, Oklahoma": [74440, 74462, 74472, 74552, 74941, 74943, 74944],
  "Pushmataha County, Oklahoma": [
    74521, 74523, 74536, 74543, 74557, 74558, 74562, 74567, 74574,
  ],
  "Atoka County, Oklahoma": [74525, 74533, 74540, 74542, 74555, 74569, 74576],
  "Hughes County, Oklahoma": [74531, 74570, 74827, 74839, 74848, 74850, 74883],
  "Coal County, Oklahoma": [74534, 74535, 74538, 74556, 74572],
  "Latimer County, Oklahoma": [74545, 74559, 74563, 74578],
  "Le Flore County, Oklahoma": [
    74549, 74571, 74577, 74901, 74902, 74930, 74932, 74935, 74937, 74939, 74940,
    74942, 74947, 74949, 74951, 74953, 74956, 74959, 74966,
  ],
  "Kay County, Oklahoma": [
    74601, 74602, 74604, 74631, 74632, 74641, 74646, 74647, 74653,
  ],
  "McCurtain County, Oklahoma": [
    74722, 74724, 74728, 74734, 74736, 74737, 74740, 74745, 74750, 74752, 74754,
    74755, 74764, 74766, 74957, 74963,
  ],
  "Choctaw County, Oklahoma": [
    74727, 74735, 74738, 74743, 74756, 74759, 74760, 74761,
  ],
  "Pottawatomie County, Oklahoma": [
    74801, 74802, 74804, 74826, 74840, 74851, 74852, 74854, 74866, 74873, 74878,
  ],
  "Seminole County, Oklahoma": [
    74818, 74830, 74837, 74849, 74867, 74868, 74884,
  ],
  "Pontotoc County, Oklahoma": [
    74820, 74821, 74825, 74842, 74843, 74844, 74865, 74871,
  ],
  "Okfuskee County, Oklahoma": [74829, 74833, 74859, 74860, 74880],
  "Adair County, Oklahoma": [74931, 74960, 74964, 74965],
  "Dallas County, Texas": [
    75001, 75006, 75011, 75014, 75015, 75016, 75017, 75019, 75030, 75037, 75038,
    75039, 75040, 75041, 75042, 75043, 75044, 75045, 75046, 75047, 75048, 75049,
    75050, 75051, 75052, 75053, 75060, 75061, 75062, 75063, 75080, 75081, 75083,
    75085, 75088, 75089, 75099, 75104, 75106, 75115, 75116, 75123, 75134, 75137,
    75138, 75141, 75146, 75149, 75150, 75159, 75172, 75180, 75181, 75182, 75185,
    75187, 75201, 75202, 75203, 75204, 75205, 75206, 75207, 75208, 75209, 75210,
    75211, 75212, 75214, 75215, 75216, 75217, 75218, 75219, 75220, 75221, 75222,
    75223, 75224, 75225, 75226, 75227, 75228, 75229, 75230, 75231, 75232, 75233,
    75234, 75235, 75236, 75237, 75238, 75240, 75241, 75242, 75243, 75244, 75245,
    75246, 75247, 75248, 75249, 75250, 75251, 75253, 75254, 75258, 75260, 75262,
    75263, 75264, 75265, 75266, 75267, 75270, 75275, 75277, 75283, 75284, 75285,
    75286, 75301, 75303, 75310, 75312, 75313, 75315, 75320, 75323, 75326, 75334,
    75336, 75339, 75340, 75342, 75343, 75344, 75353, 75354, 75355, 75356, 75357,
    75358, 75359, 75360, 75363, 75364, 75367, 75368, 75370, 75371, 75372, 75373,
    75374, 75376, 75378, 75379, 75380, 75381, 75382, 75386, 75387, 75388, 75389,
    75390, 75391, 75392, 75393, 75394, 75395, 75396, 75397, 75398,
  ],
  "Collin County, Texas": [
    75002, 75009, 75013, 75023, 75024, 75025, 75026, 75034, 75035, 75069, 75070,
    75071, 75072, 75074, 75075, 75078, 75082, 75086, 75093, 75094, 75097, 75098,
    75121, 75164, 75166, 75173, 75252, 75407, 75409, 75424, 75442, 75454, 75485,
  ],
  "Denton County, Texas": [
    75007, 75010, 75022, 75027, 75028, 75029, 75033, 75036, 75056, 75057, 75065,
    75067, 75068, 75077, 75287, 76201, 76202, 76203, 76204, 76205, 76206, 76207,
    76208, 76209, 76210, 76226, 76227, 76247, 76249, 76258, 76259, 76262, 76266,
    76299,
  ],
  "Grayson County, Texas": [
    75020, 75021, 75058, 75076, 75090, 75091, 75092, 75414, 75459, 75489, 75491,
    75495, 76233, 76245, 76264, 76268, 76271, 76273,
  ],
  "Rockwall County, Texas": [75032, 75087, 75132, 75189],
  "Tarrant County, Texas": [
    75054, 75261, 76001, 76002, 76003, 76004, 76005, 76006, 76007, 76010, 76011,
    76012, 76013, 76014, 76015, 76016, 76017, 76018, 76019, 76020, 76021, 76022,
    76034, 76036, 76039, 76040, 76051, 76052, 76053, 76054, 76060, 76063, 76092,
    76094, 76095, 76096, 76098, 76099, 76101, 76102, 76103, 76104, 76105, 76106,
    76107, 76108, 76109, 76110, 76111, 76112, 76113, 76114, 76115, 76116, 76117,
    76118, 76119, 76120, 76121, 76122, 76123, 76124, 76126, 76127, 76129, 76130,
    76131, 76132, 76133, 76134, 76135, 76136, 76137, 76140, 76147, 76148, 76150,
    76155, 76161, 76162, 76163, 76164, 76166, 76177, 76179, 76180, 76181, 76182,
    76185, 76191, 76192, 76193, 76195, 76196, 76197, 76198, 76199, 76244, 76248,
  ],
  "undefined, Texas": [75059, 75064, 76190],
  "Ellis County, Texas": [
    75101, 75119, 75120, 75125, 75152, 75154, 75165, 75167, 75168, 76041, 76064,
    76065, 76623, 76651, 76670,
  ],
  "Navarro County, Texas": [
    75102, 75105, 75109, 75110, 75144, 75151, 75153, 75155, 76626, 76639, 76641,
    76679, 76681,
  ],
  "Van Zandt County, Texas": [75103, 75117, 75127, 75140, 75169, 75754, 75790],
  "Kaufman County, Texas": [
    75114, 75118, 75126, 75142, 75147, 75157, 75158, 75160, 75161,
  ],
  "Henderson County, Texas": [
    75124, 75143, 75148, 75156, 75163, 75751, 75752, 75756, 75758, 75770, 75778,
    75782,
  ],
  "Hunt County, Texas": [
    75135, 75401, 75402, 75403, 75404, 75422, 75423, 75428, 75429, 75453, 75458,
    75474, 75496,
  ],
  "Wood County, Texas": [75410, 75444, 75494, 75497, 75765, 75773, 75783],
  "Lamar County, Texas": [
    75411, 75416, 75421, 75425, 75434, 75435, 75460, 75461, 75462, 75468, 75470,
    75473, 75477, 75486,
  ],
  "Red River County, Texas": [75412, 75417, 75426, 75436, 75550, 75554],
  "Fannin County, Texas": [
    75413, 75418, 75438, 75439, 75443, 75446, 75447, 75449, 75452, 75475, 75476,
    75479, 75488, 75490, 75492,
  ],
  "Delta County, Texas": [75415, 75432, 75441, 75448, 75450, 75469],
  "Hopkins County, Texas": [
    75420, 75431, 75433, 75437, 75471, 75478, 75481, 75482, 75483,
  ],
  "Rains County, Texas": [75440, 75472],
  "Camp County, Texas": [75451, 75686],
  "Titus County, Texas": [75455, 75456, 75487, 75493, 75558],
  "Franklin County, Texas": [75457, 75480],
  "Bowie County, Texas": [
    75501, 75503, 75504, 75505, 75507, 75559, 75561, 75567, 75569, 75570, 75573,
    75574, 75599,
  ],
  "Cass County, Texas": [
    75551, 75555, 75556, 75560, 75562, 75563, 75564, 75565, 75566, 75572, 75656,
  ],
  "Morris County, Texas": [75568, 75571, 75636, 75638, 75668],
  "Gregg County, Texas": [
    75601, 75602, 75603, 75604, 75605, 75606, 75607, 75608, 75615, 75641, 75647,
    75660, 75662, 75663, 75693,
  ],
  "Marion County, Texas": [75630, 75657],
  "Panola County, Texas": [75631, 75633, 75637, 75639, 75643, 75669, 75685],
  "Upshur County, Texas": [75640, 75644, 75645, 75683, 75755, 75797],
  "Harrison County, Texas": [
    75642, 75650, 75651, 75659, 75661, 75670, 75671, 75672, 75688, 75692, 75694,
  ],
  "Rusk County, Texas": [
    75652, 75653, 75654, 75658, 75666, 75667, 75680, 75681, 75682, 75684, 75687,
    75689, 75691, 75784,
  ],
  "Smith County, Texas": [
    75701, 75702, 75703, 75704, 75705, 75706, 75707, 75708, 75709, 75710, 75711,
    75712, 75713, 75750, 75757, 75762, 75771, 75789, 75791, 75792, 75798, 75799,
  ],
  "Cherokee County, Texas": [
    75759, 75764, 75766, 75772, 75780, 75785, 75925, 75976,
  ],
  "Nacogdoches County, Texas": [
    75760, 75788, 75937, 75943, 75944, 75946, 75958, 75961, 75962, 75963, 75964,
    75965, 75978,
  ],
  "Anderson County, Texas": [
    75763, 75779, 75801, 75802, 75803, 75832, 75839, 75853, 75861, 75880, 75882,
    75884, 75886,
  ],
  "Leon County, Texas": [
    75831, 75833, 75846, 75850, 75855, 77850, 77855, 77865, 77871,
  ],
  "Trinity County, Texas": [75834, 75845, 75856, 75862, 75865, 75926],
  "Houston County, Texas": [75835, 75844, 75847, 75849, 75851, 75858],
  "Freestone County, Texas": [75838, 75840, 75848, 75859, 75860, 76693],
  "Madison County, Texas": [75852, 77864, 77872],
  "Angelina County, Texas": [
    75901, 75902, 75903, 75904, 75915, 75941, 75949, 75969, 75980,
  ],
  "Newton County, Texas": [75928, 75932, 75933, 75966, 75977, 77614],
  "San Augustine County, Texas": [75929, 75972],
  "Sabine County, Texas": [75930, 75948, 75959, 75968],
  "Jasper County, Texas": [75931, 75951, 75956, 77612, 77615],
  "Polk County, Texas": [
    75934, 75939, 75960, 77326, 77332, 77335, 77350, 77351, 77360, 77399,
  ],
  "Shelby County, Texas": [75935, 75954, 75973, 75974, 75975],
  "Tyler County, Texas": [
    75936, 75938, 75942, 75979, 75990, 77616, 77624, 77660, 77664,
  ],
  "Parker County, Texas": [
    76008, 76035, 76066, 76068, 76082, 76085, 76086, 76087, 76088, 76439, 76485,
    76487, 76490,
  ],
  "Johnson County, Texas": [
    76009, 76028, 76031, 76033, 76044, 76050, 76058, 76059, 76061, 76084, 76093,
    76097,
  ],
  "Wise County, Texas": [
    76023, 76071, 76073, 76078, 76225, 76234, 76246, 76267, 76426, 76431,
  ],
  "Somervell County, Texas": [76043, 76070, 76077],
  "Hood County, Texas": [76048, 76049, 76462, 76467, 76476],
  "Hill County, Texas": [
    76055, 76621, 76622, 76627, 76628, 76631, 76636, 76645, 76648, 76650, 76660,
    76666, 76673, 76676, 76692,
  ],
  "Palo Pinto County, Texas": [76067, 76449, 76453, 76463, 76472, 76475, 76484],
  "Clay County, Texas": [76228, 76352, 76357, 76365, 76377],
  "Montague County, Texas": [76230, 76239, 76251, 76255, 76261, 76265, 76270],
  "Cooke County, Texas": [
    76238, 76240, 76241, 76250, 76252, 76253, 76263, 76272,
  ],
  "Wichita County, Texas": [
    76301, 76302, 76305, 76306, 76307, 76308, 76309, 76310, 76311, 76354, 76360,
    76367, 76369,
  ],
  "Archer County, Texas": [76351, 76366, 76370, 76379, 76389],
  "Knox County, Texas": [76363, 76371, 79505, 79529],
  "Wilbarger County, Texas": [76364, 76373, 76384, 76385, 79247],
  "Young County, Texas": [76372, 76374, 76450, 76460, 76481],
  "Baylor County, Texas": [76380],
  "Haskell County, Texas": [76388, 79521, 79539, 79544, 79547, 79548],
  "Erath County, Texas": [76401, 76402, 76433, 76446, 76461, 76465],
  "Stephens County, Texas": [76424, 76429],
  "Jack County, Texas": [76427, 76458, 76459, 76486],
  "Shackelford County, Texas": [76430, 76464],
  "Brown County, Texas": [
    76432, 76801, 76802, 76803, 76804, 76823, 76827, 76857, 76890,
  ],
  "Eastland County, Texas": [
    76435, 76437, 76445, 76448, 76454, 76466, 76470, 76471,
  ],
  "Hamilton County, Texas": [76436, 76457, 76531, 76565],
  "Comanche County, Texas": [76442, 76444, 76452, 76455, 76468, 76474],
  "Callahan County, Texas": [76443, 76469, 79504, 79510],
  "Throckmorton County, Texas": [76483, 76491],
  "Bell County, Texas": [
    76501, 76502, 76503, 76504, 76505, 76508, 76513, 76519, 76533, 76534, 76540,
    76541, 76542, 76543, 76545, 76546, 76547, 76548, 76549, 76554, 76559, 76564,
    76569, 76571, 76579,
  ],
  "Williamson County, Texas": [
    76511, 76527, 76530, 76537, 76573, 76574, 76578, 78613, 78626, 78627, 78628,
    78630, 78633, 78634, 78641, 78642, 78646, 78651, 78664, 78665, 78673, 78674,
    78680, 78681, 78682, 78683, 78717, 78729,
  ],
  "Milam County, Texas": [76518, 76520, 76523, 76556, 76567, 76577, 77857],
  "Coryell County, Texas": [
    76522, 76525, 76526, 76528, 76538, 76544, 76558, 76561, 76566, 76596, 76597,
    76598, 76599,
  ],
  "McLennan County, Texas": [
    76524, 76557, 76624, 76630, 76633, 76638, 76640, 76643, 76654, 76655, 76657,
    76664, 76682, 76684, 76691, 76701, 76704, 76705, 76706, 76707, 76708, 76710,
    76711, 76712, 76798,
  ],
  "Lampasas County, Texas": [76539, 76550, 76853],
  "Falls County, Texas": [76570, 76632, 76656, 76661, 76680, 76685],
  "Robertson County, Texas": [76629, 77837, 77856, 77859, 77867, 77870, 77882],
  "Bosque County, Texas": [
    76634, 76637, 76644, 76649, 76652, 76665, 76671, 76689, 76690,
  ],
  "Limestone County, Texas": [76635, 76642, 76653, 76667, 76678, 76686, 76687],
  "Mclennan County, Texas": [
    76702, 76703, 76714, 76715, 76716, 76795, 76797, 76799,
  ],
  "Mason County, Texas": [76820, 76842, 76856, 76869],
  "Runnels County, Texas": [76821, 76861, 76865, 76875, 79567],
  "San Saba County, Texas": [76824, 76832, 76871, 76877],
  "McCulloch County, Texas": [76825, 76836, 76852, 76858, 76872, 76887],
  "Coleman County, Texas": [
    76828, 76834, 76845, 76873, 76878, 76882, 76884, 76888, 79519, 79538,
  ],
  "Llano County, Texas": [
    76831, 76885, 78607, 78609, 78639, 78643, 78657, 78672,
  ],
  "Concho County, Texas": [76837, 76855, 76862, 76866],
  "Menard County, Texas": [76841, 76848, 76859],
  "Mills County, Texas": [76844, 76864, 76870, 76880],
  "Kimble County, Texas": [76849, 76854, 76883],
  "Sutton County, Texas": [76874, 76950],
  "Tom Green County, Texas": [
    76886, 76901, 76902, 76903, 76904, 76905, 76906, 76908, 76909, 76934, 76935,
    76937, 76939, 76940, 76955, 76957, 76958,
  ],
  "Irion County, Texas": [76930, 76941],
  "Reagan County, Texas": [76932],
  "Coke County, Texas": [76933, 76945, 76949, 76953],
  "Schleicher County, Texas": [76936],
  "Crockett County, Texas": [76943, 79942],
  "Sterling County, Texas": [76951],
  "Harris County, Texas": [
    77001, 77002, 77003, 77004, 77005, 77006, 77007, 77008, 77009, 77010, 77011,
    77012, 77013, 77014, 77015, 77016, 77017, 77018, 77019, 77020, 77021, 77022,
    77023, 77024, 77025, 77026, 77027, 77028, 77029, 77030, 77031, 77032, 77033,
    77034, 77035, 77036, 77037, 77038, 77039, 77040, 77041, 77042, 77043, 77044,
    77045, 77046, 77047, 77048, 77049, 77050, 77051, 77052, 77054, 77055, 77056,
    77057, 77058, 77059, 77060, 77061, 77062, 77063, 77064, 77065, 77066, 77067,
    77068, 77069, 77070, 77071, 77072, 77073, 77074, 77075, 77076, 77077, 77078,
    77079, 77080, 77081, 77082, 77083, 77084, 77085, 77086, 77087, 77088, 77089,
    77090, 77091, 77092, 77093, 77094, 77095, 77096, 77097, 77098, 77099, 77201,
    77202, 77203, 77204, 77205, 77206, 77207, 77208, 77209, 77210, 77212, 77213,
    77215, 77216, 77217, 77218, 77219, 77220, 77221, 77222, 77223, 77224, 77225,
    77226, 77227, 77228, 77229, 77230, 77231, 77233, 77234, 77235, 77236, 77237,
    77238, 77240, 77241, 77242, 77243, 77244, 77245, 77246, 77247, 77248, 77249,
    77250, 77251, 77252, 77253, 77254, 77255, 77256, 77257, 77258, 77259, 77260,
    77261, 77262, 77263, 77265, 77266, 77267, 77268, 77269, 77270, 77271, 77272,
    77273, 77274, 77275, 77276, 77277, 77278, 77279, 77280, 77282, 77284, 77285,
    77286, 77287, 77288, 77289, 77290, 77291, 77292, 77293, 77294, 77296, 77297,
    77298, 77299, 77315, 77325, 77336, 77337, 77338, 77339, 77345, 77346, 77347,
    77373, 77375, 77377, 77379, 77383, 77387, 77388, 77389, 77391, 77393, 77396,
    77401, 77402, 77410, 77411, 77413, 77429, 77433, 77447, 77449, 77450, 77493,
    77501, 77502, 77503, 77504, 77505, 77506, 77507, 77508, 77520, 77521, 77522,
    77530, 77532, 77536, 77547, 77562, 77571, 77572, 77586, 77587, 77598,
  ],
  "Fort Bend County, Texas": [
    77053, 77406, 77407, 77417, 77441, 77444, 77451, 77459, 77461, 77464, 77469,
    77471, 77476, 77477, 77478, 77479, 77481, 77487, 77489, 77494, 77496, 77497,
    77498, 77545,
  ],
  "Montgomery County, Texas": [
    77301, 77302, 77303, 77304, 77305, 77306, 77316, 77318, 77328, 77333, 77353,
    77354, 77355, 77356, 77357, 77362, 77365, 77372, 77378, 77380, 77381, 77382,
    77384, 77385, 77386,
  ],
  "Walker County, Texas": [
    77320, 77334, 77340, 77341, 77342, 77343, 77344, 77348, 77349, 77358, 77367,
  ],
  "Liberty County, Texas": [
    77327, 77368, 77369, 77533, 77535, 77538, 77561, 77564, 77575, 77582,
  ],
  "San Jacinto County, Texas": [77331, 77359, 77364, 77371],
  "Grimes County, Texas": [
    77363, 77830, 77831, 77861, 77868, 77869, 77873, 77875, 77876,
  ],
  "Hardin County, Texas": [
    77374, 77376, 77519, 77585, 77625, 77656, 77657, 77659, 77663,
  ],
  "Matagorda County, Texas": [
    77404, 77414, 77415, 77419, 77428, 77440, 77456, 77457, 77458, 77465, 77468,
    77482, 77483,
  ],
  "Colorado County, Texas": [
    77412, 77434, 77442, 77460, 77470, 77475, 78934, 78935, 78943, 78951, 78962,
  ],
  "Austin County, Texas": [
    77418, 77452, 77473, 77474, 77485, 78931, 78933, 78944, 78950,
  ],
  "Wharton County, Texas": [
    77420, 77432, 77435, 77436, 77437, 77443, 77448, 77453, 77454, 77455, 77467,
    77488,
  ],
  "Brazoria County, Texas": [
    77422, 77430, 77431, 77463, 77480, 77486, 77511, 77512, 77515, 77516, 77531,
    77534, 77541, 77542, 77566, 77577, 77578, 77581, 77583, 77584, 77588,
  ],
  "Waller County, Texas": [77423, 77445, 77446, 77466, 77484, 77491, 77492],
  "Washington County, Texas": [77426, 77833, 77834, 77835, 77880],
  "Galveston County, Texas": [
    77510, 77517, 77518, 77539, 77546, 77549, 77550, 77551, 77552, 77553, 77554,
    77555, 77563, 77565, 77568, 77573, 77574, 77590, 77591, 77592, 77617, 77623,
    77650,
  ],
  "Chambers County, Texas": [77514, 77523, 77560, 77580, 77597, 77661, 77665],
  "Orange County, Texas": [
    77611, 77626, 77630, 77631, 77632, 77639, 77662, 77670,
  ],
  "Jefferson County, Texas": [
    77613, 77619, 77622, 77627, 77629, 77640, 77641, 77642, 77643, 77651, 77655,
    77701, 77702, 77703, 77704, 77705, 77706, 77707, 77708, 77709, 77710, 77713,
    77720, 77725, 77726,
  ],
  "Brazos County, Texas": [
    77801, 77802, 77803, 77805, 77806, 77807, 77808, 77840, 77841, 77842, 77843,
    77844, 77845, 77862, 77866, 77881,
  ],
  "Burleson County, Texas": [77836, 77838, 77852, 77863, 77878, 77879],
  "Lee County, Texas": [77853, 78942, 78946, 78947, 78948],
  "Victoria County, Texas": [
    77901, 77902, 77903, 77904, 77905, 77951, 77968, 77973, 77974, 77976, 77977,
    77988,
  ],
  "Refugio County, Texas": [77950, 77990, 78340, 78377, 78393],
  "DeWitt County, Texas": [77954, 77994, 78141, 78164],
  "Jackson County, Texas": [77957, 77961, 77962, 77969, 77970, 77971, 77991],
  "Goliad County, Texas": [77960, 77963, 77993, 78107],
  "Lavaca County, Texas": [77964, 77975, 77984, 77986, 77987, 77995],
  "De Witt County, Texas": [77967, 77989],
  "Calhoun County, Texas": [77978, 77979, 77982, 77983],
  "La Salle County, Texas": [78001, 78014, 78019, 78021],
  "Bexar County, Texas": [
    78002, 78015, 78023, 78054, 78073, 78109, 78112, 78148, 78150, 78152, 78201,
    78202, 78203, 78204, 78205, 78206, 78207, 78208, 78209, 78210, 78211, 78212,
    78213, 78214, 78215, 78216, 78217, 78218, 78219, 78220, 78221, 78222, 78223,
    78224, 78225, 78226, 78227, 78228, 78229, 78230, 78231, 78232, 78233, 78234,
    78235, 78236, 78237, 78238, 78239, 78240, 78241, 78242, 78243, 78244, 78245,
    78246, 78247, 78248, 78249, 78250, 78251, 78252, 78253, 78254, 78255, 78256,
    78257, 78258, 78259, 78260, 78261, 78262, 78263, 78264, 78265, 78268, 78269,
    78270, 78275, 78278, 78279, 78280, 78283, 78284, 78285, 78286, 78287, 78288,
    78289, 78291, 78292, 78293, 78294, 78295, 78296, 78297, 78298, 78299,
  ],
  "Bandera County, Texas": [78003, 78055, 78063, 78883, 78885],
  "Kendall County, Texas": [78004, 78006, 78013, 78027, 78074],
  "Frio County, Texas": [78005, 78017, 78057, 78061],
  "McMullen County, Texas": [78007, 78072],
  "Atascosa County, Texas": [
    78008, 78011, 78012, 78026, 78050, 78052, 78062, 78064, 78065, 78069,
  ],
  "Medina County, Texas": [
    78009, 78016, 78039, 78056, 78059, 78066, 78850, 78861, 78886,
  ],
  "Kerr County, Texas": [78010, 78024, 78025, 78028, 78029, 78058],
  "Live Oak County, Texas": [78022, 78060, 78071, 78075, 78350],
  "Webb County, Texas": [
    78040, 78041, 78042, 78043, 78044, 78045, 78046, 78049, 78344, 78369, 78371,
  ],
  "Zapata County, Texas": [78067, 78076, 78564],
  "Comal County, Texas": [
    78070, 78130, 78131, 78132, 78133, 78135, 78163, 78266, 78623,
  ],
  "Wilson County, Texas": [
    78101, 78113, 78114, 78121, 78143, 78147, 78160, 78161,
  ],
  "Bee County, Texas": [
    78102, 78104, 78125, 78142, 78145, 78146, 78162, 78389, 78391,
  ],
  "Guadalupe County, Texas": [
    78108, 78115, 78123, 78124, 78154, 78155, 78156, 78638, 78670,
  ],
  "Karnes County, Texas": [78111, 78116, 78117, 78118, 78119, 78144, 78151],
  "Gonzales County, Texas": [
    78122, 78140, 78159, 78604, 78614, 78629, 78632, 78658, 78677, 78959,
  ],
  "Nueces County, Texas": [
    78330, 78335, 78339, 78343, 78347, 78351, 78373, 78380, 78401, 78402, 78403,
    78404, 78405, 78406, 78407, 78408, 78409, 78410, 78411, 78412, 78413, 78414,
    78415, 78416, 78417, 78418, 78419, 78426, 78427, 78460, 78461, 78463, 78465,
    78466, 78467, 78468, 78469, 78470, 78471, 78472, 78473, 78474, 78475, 78476,
    78477, 78478, 78480,
  ],
  "Jim Wells County, Texas": [78332, 78333, 78342, 78372, 78375, 78383],
  "San Patricio County, Texas": [
    78336, 78352, 78359, 78362, 78368, 78370, 78374, 78387, 78390,
  ],
  "Kenedy County, Texas": [78338, 78385],
  "Duval County, Texas": [78341, 78349, 78357, 78376, 78384],
  "Brooks County, Texas": [78353, 78355],
  "Aransas County, Texas": [78358, 78381, 78382],
  "Jim Hogg County, Texas": [78360, 78361],
  "Kleberg County, Texas": [78363, 78364, 78379],
  "Hidalgo County, Texas": [
    78501, 78502, 78503, 78504, 78505, 78516, 78537, 78538, 78539, 78540, 78541,
    78542, 78543, 78549, 78557, 78558, 78560, 78562, 78563, 78565, 78570, 78572,
    78573, 78574, 78576, 78577, 78579, 78589, 78595, 78596, 78599,
  ],
  "Cameron County, Texas": [
    78520, 78521, 78522, 78523, 78526, 78535, 78550, 78551, 78552, 78553, 78559,
    78566, 78567, 78568, 78575, 78578, 78583, 78586, 78592, 78593, 78597,
  ],
  "Starr County, Texas": [
    78536, 78545, 78547, 78548, 78582, 78584, 78585, 78588, 78591,
  ],
  "Willacy County, Texas": [78561, 78569, 78580, 78590, 78594, 78598],
  "Bastrop County, Texas": [
    78602, 78612, 78621, 78650, 78659, 78662, 78953, 78957,
  ],
  "Burnet County, Texas": [78605, 78608, 78611, 78654],
  "Blanco County, Texas": [78606, 78635, 78636, 78663],
  "Hays County, Texas": [
    78610, 78619, 78620, 78640, 78666, 78667, 78676, 78737,
  ],
  "Caldwell County, Texas": [78616, 78622, 78644, 78648, 78655, 78656, 78661],
  "Gillespie County, Texas": [78618, 78624, 78631, 78671, 78675],
  "Uvalde County, Texas": [78801, 78802, 78838, 78870, 78881, 78884],
  "Dimmit County, Texas": [78827, 78830, 78834, 78836],
  "Edwards County, Texas": [78828, 78880],
  "Zavala County, Texas": [78829, 78839, 78872],
  "Kinney County, Texas": [78832],
  "Real County, Texas": [78833, 78873, 78879],
  "Val Verde County, Texas": [78837, 78840, 78841, 78842, 78843, 78847, 78871],
  "Terrell County, Texas": [78851, 79848],
  "Maverick County, Texas": [78852, 78853, 78860, 78877],
  "Fayette County, Texas": [
    78932, 78938, 78940, 78941, 78945, 78949, 78952, 78954, 78956, 78960, 78961,
    78963,
  ],
  "Oldham County, Texas": [79001, 79092, 79098],
  "Gray County, Texas": [79002, 79054, 79057, 79065, 79066],
  "Wheeler County, Texas": [79003, 79011, 79061, 79079, 79096],
  "Lipscomb County, Texas": [79005, 79024, 79034, 79046, 79056],
  "Hutchinson County, Texas": [79007, 79008, 79036, 79078, 79083],
  "Parmer County, Texas": [79009, 79035, 79053, 79325],
  "Potter County, Texas": [
    79010, 79058, 79101, 79102, 79103, 79104, 79106, 79107, 79108, 79111, 79114,
    79116, 79117, 79120, 79124, 79159, 79166, 79168, 79172, 79174, 79178, 79185,
    79187, 79189,
  ],
  "Randall County, Texas": [
    79012, 79015, 79016, 79091, 79109, 79110, 79118, 79119, 79121,
  ],
  "Moore County, Texas": [79013, 79029, 79086, 79105],
  "Hemphill County, Texas": [79014],
  "Hartley County, Texas": [79018, 79044],
  "Armstrong County, Texas": [79019, 79094],
  "Hale County, Texas": [79021, 79032, 79041, 79072, 79073, 79250, 79311],
  "Dallam County, Texas": [79022, 79051, 79087],
  "Deaf Smith County, Texas": [79025, 79045],
  "Castro County, Texas": [79027, 79043, 79063, 79085],
  "Lamb County, Texas": [
    79031, 79064, 79082, 79312, 79326, 79339, 79369, 79371,
  ],
  "Ochiltree County, Texas": [79033, 79070, 79093],
  "Carson County, Texas": [79039, 79068, 79080, 79097],
  "Hansford County, Texas": [79040, 79062, 79081],
  "Swisher County, Texas": [79042, 79052, 79088],
  "Roberts County, Texas": [79059],
  "Collingsworth County, Texas": [79077, 79095, 79230, 79251],
  "Childress County, Texas": [79201, 79259],
  "Dickens County, Texas": [79220, 79229, 79243, 79370],
  "Floyd County, Texas": [79221, 79231, 79235, 79241, 79258],
  "Cottle County, Texas": [79223, 79248],
  "Hardeman County, Texas": [79225, 79252],
  "Donley County, Texas": [79226, 79237, 79240],
  "Foard County, Texas": [79227],
  "Hall County, Texas": [79233, 79239, 79245, 79261],
  "Motley County, Texas": [79234, 79244, 79256],
  "King County, Texas": [79236],
  "Briscoe County, Texas": [79255, 79257],
  "Hockley County, Texas": [
    79313, 79336, 79338, 79353, 79358, 79367, 79372, 79380,
  ],
  "Cochran County, Texas": [79314, 79346, 79379],
  "Terry County, Texas": [79316, 79345, 79376, 79378],
  "Bailey County, Texas": [79320, 79324, 79344, 79347],
  "Crosby County, Texas": [79322, 79343, 79357],
  "Yoakum County, Texas": [79323, 79355],
  "Lubbock County, Texas": [
    79329, 79350, 79363, 79364, 79366, 79382, 79401, 79402, 79403, 79404, 79405,
    79406, 79407, 79408, 79409, 79410, 79411, 79412, 79413, 79414, 79415, 79416,
    79423, 79424, 79430, 79452, 79453, 79457, 79464, 79490, 79491, 79493, 79499,
  ],
  "Garza County, Texas": [79330, 79356],
  "Dawson County, Texas": [79331, 79377, 79713],
  "Gaines County, Texas": [79342, 79359, 79360],
  "Lynn County, Texas": [79351, 79373, 79381, 79383],
  "Jones County, Texas": [79501, 79503, 79520, 79525, 79533, 79553],
  "Stonewall County, Texas": [79502, 79540],
  "Nolan County, Texas": [79506, 79535, 79537, 79545, 79556],
  "Taylor County, Texas": [
    79508, 79530, 79536, 79541, 79561, 79562, 79563, 79566, 79601, 79602, 79603,
    79604, 79605, 79606, 79607, 79608, 79697, 79698, 79699,
  ],
  "Howard County, Texas": [79511, 79720, 79721, 79733, 79748],
  "Mitchell County, Texas": [79512, 79532, 79565],
  "Scurry County, Texas": [79516, 79517, 79526, 79527, 79549, 79550],
  "Kent County, Texas": [79518, 79528],
  "Fisher County, Texas": [79534, 79543, 79546, 79560],
  "Midland County, Texas": [
    79701, 79702, 79703, 79704, 79705, 79706, 79707, 79708, 79710, 79711, 79712,
  ],
  "Andrews County, Texas": [79714],
  "Reeves County, Texas": [79718, 79770, 79772, 79780, 79785, 79786],
  "Ward County, Texas": [79719, 79742, 79756, 79777, 79788],
  "Pecos County, Texas": [79730, 79735, 79743, 79744, 79781],
  "Crane County, Texas": [79731, 79740],
  "Jeff Davis County, Texas": [79734, 79854],
  "Borden County, Texas": [79738],
  "Glasscock County, Texas": [79739],
  "Ector County, Texas": [
    79741, 79758, 79759, 79760, 79761, 79762, 79763, 79764, 79765, 79766, 79768,
    79769, 79776,
  ],
  "Winkler County, Texas": [79745, 79789],
  "Martin County, Texas": [79749, 79782, 79783],
  "Upton County, Texas": [79752, 79755, 79778],
  "Loving County, Texas": [79754],
  "El Paso County, Texas": [
    79821, 79835, 79836, 79838, 79849, 79853, 79901, 79902, 79903, 79904, 79905,
    79906, 79907, 79908, 79910, 79911, 79912, 79913, 79914, 79915, 79916, 79917,
    79918, 79920, 79922, 79923, 79924, 79925, 79926, 79927, 79928, 79929, 79930,
    79931, 79932, 79934, 79935, 79936, 79937, 79938, 79940, 79941, 79943, 79944,
    79945, 79946, 79947, 79948, 79949, 79950, 79951, 79952, 79953, 79954, 79955,
    79958, 79960, 79961, 79968, 79976, 79978, 79980, 79990, 79995, 79996, 79997,
    79998, 79999, 88510, 88511, 88512, 88513, 88514, 88515, 88516, 88517, 88518,
    88519, 88520, 88521, 88523, 88524, 88525, 88526, 88527, 88528, 88529, 88530,
    88531, 88532, 88533, 88534, 88535, 88536, 88538, 88539, 88540, 88541, 88542,
    88543, 88544, 88545, 88546, 88547, 88548, 88549, 88550, 88553, 88554, 88555,
    88556, 88557, 88558, 88559, 88560, 88561, 88562, 88563, 88565, 88566, 88567,
    88568, 88569, 88570, 88571, 88572, 88573, 88574, 88575, 88576, 88577, 88578,
    88579, 88580, 88581, 88582, 88583, 88584, 88585, 88586, 88587, 88588, 88589,
    88590, 88595,
  ],
  "Brewster County, Texas": [79830, 79831, 79832, 79834, 79842, 79852],
  "Hudspeth County, Texas": [79837, 79839, 79847, 79851],
  "Presidio County, Texas": [79843, 79845, 79846],
  "Culberson County, Texas": [79855],
  "Jefferson County, Colorado": [
    80001, 80002, 80003, 80004, 80005, 80006, 80007, 80021, 80033, 80034, 80035,
    80036, 80123, 80127, 80128, 80214, 80215, 80226, 80227, 80228, 80232, 80235,
    80401, 80402, 80403, 80419, 80425, 80433, 80437, 80439, 80453, 80454, 80457,
    80465, 80470,
  ],
  "Arapahoe County, Colorado": [
    80010, 80011, 80012, 80013, 80014, 80015, 80016, 80017, 80018, 80040, 80041,
    80042, 80044, 80046, 80047, 80103, 80105, 80110, 80111, 80112, 80113, 80120,
    80121, 80122, 80137, 80150, 80151, 80155, 80160, 80161, 80162, 80163, 80165,
    80166, 80247,
  ],
  "Adams County, Colorado": [
    80019, 80022, 80024, 80030, 80031, 80037, 80045, 80102, 80136, 80221, 80229,
    80233, 80234, 80241, 80260, 80601, 80602, 80614, 80640,
  ],
  "Broomfield County, Colorado": [80020, 80023, 80038],
  "Boulder County, Colorado": [
    80025, 80026, 80027, 80028, 80301, 80302, 80303, 80304, 80305, 80306, 80307,
    80308, 80309, 80310, 80314, 80321, 80322, 80323, 80328, 80329, 80455, 80466,
    80471, 80481, 80501, 80502, 80503, 80504, 80510, 80533, 80540, 80544,
  ],
  "Elbert County, Colorado": [80101, 80107, 80117, 80830, 80835],
  "Douglas County, Colorado": [
    80104, 80108, 80109, 80116, 80118, 80124, 80125, 80126, 80129, 80130, 80131,
    80134, 80135, 80138,
  ],
  "El Paso County, Colorado": [
    80106, 80132, 80133, 80808, 80809, 80817, 80819, 80829, 80831, 80832, 80833,
    80840, 80841, 80864, 80901, 80902, 80903, 80904, 80905, 80906, 80907, 80908,
    80909, 80910, 80911, 80912, 80913, 80914, 80915, 80916, 80917, 80918, 80919,
    80920, 80921, 80922, 80923, 80924, 80925, 80926, 80927, 80928, 80929, 80930,
    80931, 80932, 80933, 80934, 80935, 80936, 80937, 80938, 80939, 80940, 80941,
    80942, 80943, 80944, 80945, 80946, 80947, 80949, 80950, 80951, 80960, 80962,
    80970, 80977, 80995, 80997,
  ],
  "Denver County, Colorado": [
    80201, 80202, 80203, 80204, 80205, 80206, 80207, 80208, 80209, 80210, 80211,
    80212, 80216, 80217, 80218, 80219, 80220, 80222, 80223, 80224, 80225, 80230,
    80231, 80236, 80237, 80238, 80239, 80243, 80244, 80246, 80248, 80249, 80250,
    80251, 80252, 80256, 80257, 80259, 80261, 80262, 80263, 80264, 80265, 80266,
    80271, 80273, 80274, 80279, 80280, 80281, 80290, 80291, 80293, 80294, 80295,
    80299,
  ],
  "Park County, Colorado": [
    80420, 80421, 80432, 80440, 80448, 80449, 80456, 80475, 80820, 80827,
  ],
  "Gilpin County, Colorado": [80422, 80427, 80474],
  "Eagle County, Colorado": [
    80423, 80426, 81620, 81621, 81631, 81632, 81637, 81645, 81649, 81655, 81657,
    81658,
  ],
  "Summit County, Colorado": [80424, 80435, 80443, 80497, 80498],
  "Routt County, Colorado": [
    80428, 80463, 80467, 80469, 80477, 80479, 80483, 80487, 80488, 81639, 81653,
  ],
  "Lake County, Colorado": [80429, 80461, 81251],
  "Jackson County, Colorado": [80430, 80434, 80473, 80480],
  "Clear Creek County, Colorado": [80436, 80438, 80444, 80452, 80476],
  "Grand County, Colorado": [
    80442, 80446, 80447, 80451, 80459, 80468, 80478, 80482,
  ],
  "Larimer County, Colorado": [
    80511, 80512, 80513, 80515, 80517, 80521, 80522, 80523, 80524, 80525, 80526,
    80527, 80528, 80532, 80535, 80536, 80537, 80538, 80539, 80541, 80545, 80547,
    80549, 80553,
  ],
  "Weld County, Colorado": [
    80514, 80516, 80520, 80530, 80534, 80542, 80543, 80546, 80550, 80551, 80603,
    80610, 80611, 80612, 80615, 80620, 80621, 80622, 80623, 80624, 80631, 80632,
    80633, 80634, 80638, 80639, 80642, 80643, 80644, 80645, 80646, 80648, 80650,
    80651, 80652, 80729, 80732, 80742, 80754,
  ],
  "Morgan County, Colorado": [
    80649, 80653, 80654, 80701, 80705, 80723, 80733, 80750,
  ],
  "Washington County, Colorado": [80720, 80740, 80743, 80757, 80801, 80812],
  "Phillips County, Colorado": [80721, 80731, 80734, 80746],
  "Logan County, Colorado": [
    80722, 80726, 80728, 80736, 80741, 80745, 80747, 80751,
  ],
  "Yuma County, Colorado": [80727, 80735, 80755, 80758, 80759, 80822, 80824],
  "Sedgwick County, Colorado": [80737, 80744, 80749],
  "Cheyenne County, Colorado": [80802, 80810, 80825, 80862],
  "Lincoln County, Colorado": [80804, 80818, 80821, 80823, 80826, 80828],
  "Kit Carson County, Colorado": [80805, 80807, 80815, 80834, 80836, 80861],
  "Teller County, Colorado": [80813, 80814, 80816, 80860, 80863, 80866],
  "Pueblo County, Colorado": [
    81001, 81002, 81003, 81004, 81005, 81006, 81007, 81008, 81009, 81010, 81011,
    81012, 81019, 81022, 81023, 81025, 81069,
  ],
  "Las Animas County, Colorado": [
    81020, 81024, 81027, 81046, 81049, 81059, 81081, 81082, 81091,
  ],
  "Kiowa County, Colorado": [81021, 81036, 81045, 81071],
  "Baca County, Colorado": [81029, 81064, 81073, 81084, 81087, 81090],
  "Otero County, Colorado": [81030, 81039, 81050, 81058, 81067, 81077],
  "Crowley County, Colorado": [81033, 81034, 81062, 81063, 81076],
  "Bent County, Colorado": [81038, 81044, 81054, 81057],
  "Huerfano County, Colorado": [81040, 81055, 81089],
  "Prowers County, Colorado": [81041, 81043, 81047, 81052, 81092],
  "undefined, Colorado": [81075],
  "Alamosa County, Colorado": [81101, 81102, 81136, 81146],
  "Conejos County, Colorado": [81120, 81124, 81129, 81140, 81141, 81148, 81151],
  "Archuleta County, Colorado": [81121, 81127, 81128, 81147, 81157],
  "La Plata County, Colorado": [
    81122, 81137, 81301, 81302, 81303, 81326, 81329,
  ],
  "Costilla County, Colorado": [
    81123, 81126, 81133, 81134, 81138, 81152, 81153,
  ],
  "Saguache County, Colorado": [81125, 81131, 81143, 81149, 81155, 81248],
  "Mineral County, Colorado": [81130],
  "Rio Grande County, Colorado": [81132, 81135, 81144, 81154],
  "Chaffee County, Colorado": [81201, 81211, 81227, 81228, 81236, 81242],
  "Gunnison County, Colorado": [
    81210, 81220, 81224, 81225, 81230, 81231, 81237, 81239, 81241, 81243, 81247,
    81434,
  ],
  "Fremont County, Colorado": [
    81212, 81215, 81221, 81222, 81223, 81226, 81232, 81233, 81240, 81244, 81290,
  ],
  "Hinsdale County, Colorado": [81235],
  "Custer County, Colorado": [81252, 81253],
  "Dolores County, Colorado": [81320, 81324, 81332],
  "Montezuma County, Colorado": [
    81321, 81323, 81327, 81328, 81330, 81331, 81334, 81335,
  ],
  "San Miguel County, Colorado": [81325, 81423, 81426, 81430, 81435],
  "Montrose County, Colorado": [
    81401, 81402, 81403, 81411, 81422, 81424, 81425, 81429, 81431,
  ],
  "Delta County, Colorado": [
    81410, 81413, 81414, 81415, 81416, 81418, 81419, 81420, 81428,
  ],
  "Ouray County, Colorado": [81427, 81432],
  "San Juan County, Colorado": [81433],
  "Mesa County, Colorado": [
    81501, 81502, 81503, 81504, 81505, 81506, 81507, 81520, 81521, 81522, 81523,
    81524, 81525, 81526, 81527, 81624, 81630, 81643, 81646,
  ],
  "Garfield County, Colorado": [
    81601, 81602, 81623, 81635, 81636, 81647, 81650, 81652,
  ],
  "Moffat County, Colorado": [81610, 81625, 81626, 81633, 81638, 81640],
  "Pitkin County, Colorado": [81611, 81612, 81615, 81642, 81654, 81656],
  "Rio Blanco County, Colorado": [81641, 81648],
  "Laramie County, Wyoming": [
    82001, 82002, 82003, 82005, 82006, 82007, 82008, 82009, 82010, 82050, 82053,
    82054, 82059, 82060, 82061, 82081, 82082,
  ],
  "Albany County, Wyoming": [
    82051, 82052, 82055, 82058, 82063, 82070, 82071, 82072, 82073, 82083, 82084,
  ],
  "Park County, Wyoming": [82190, 82414, 82433, 82435, 82440, 82450],
  "Platte County, Wyoming": [82201, 82210, 82213, 82214, 82215],
  "Goshen County, Wyoming": [
    82212, 82217, 82218, 82219, 82221, 82223, 82240, 82243, 82244,
  ],
  "Niobrara County, Wyoming": [82222, 82225, 82227, 82242],
  "Converse County, Wyoming": [82224, 82229, 82633, 82637],
  "Carbon County, Wyoming": [
    82301, 82321, 82323, 82324, 82325, 82327, 82329, 82331, 82332, 82334, 82335,
    82615,
  ],
  "Fremont County, Wyoming": [
    82310, 82501, 82510, 82512, 82513, 82514, 82515, 82516, 82520, 82523, 82524,
    82642, 82649,
  ],
  "Sweetwater County, Wyoming": [
    82322, 82336, 82901, 82902, 82929, 82932, 82934, 82935, 82938, 82942, 82943,
    82945,
  ],
  "Washakie County, Wyoming": [82401, 82442],
  "Big Horn County, Wyoming": [
    82410, 82411, 82412, 82420, 82421, 82422, 82423, 82426, 82428, 82431, 82432,
    82434, 82441,
  ],
  "Hot Springs County, Wyoming": [82430, 82443],
  "Natrona County, Wyoming": [
    82601, 82602, 82604, 82605, 82609, 82620, 82630, 82635, 82636, 82638, 82643,
    82644, 82646, 82648,
  ],
  "Johnson County, Wyoming": [82639, 82640, 82834, 82840],
  "Weston County, Wyoming": [82701, 82715, 82723, 82730],
  "Crook County, Wyoming": [82710, 82711, 82712, 82714, 82720, 82721, 82729],
  "Campbell County, Wyoming": [82716, 82717, 82718, 82725, 82727, 82731, 82732],
  "Sheridan County, Wyoming": [
    82801, 82831, 82832, 82833, 82835, 82836, 82837, 82838, 82839, 82842, 82844,
    82845,
  ],
  "Sublette County, Wyoming": [82922, 82923, 82925, 82941, 83113, 83115],
  "Uinta County, Wyoming": [82930, 82931, 82933, 82936, 82937, 82939, 82944],
  "Teton County, Wyoming": [
    83001, 83002, 83011, 83012, 83013, 83014, 83025, 83414,
  ],
  "Lincoln County, Wyoming": [
    83101, 83110, 83111, 83112, 83114, 83116, 83118, 83119, 83120, 83121, 83122,
    83123, 83124, 83126, 83127, 83128,
  ],
  "Bannock County, Idaho": [
    83201, 83202, 83204, 83205, 83206, 83209, 83214, 83234, 83245, 83246, 83250,
    83281,
  ],
  "Bingham County, Idaho": [
    83203, 83210, 83215, 83218, 83221, 83236, 83256, 83262, 83274, 83277,
  ],
  "Power County, Idaho": [83211, 83212, 83271],
  "Butte County, Idaho": [83213, 83244, 83255],
  "Caribou County, Idaho": [83217, 83230, 83241, 83276, 83285],
  "Bear Lake County, Idaho": [
    83220, 83223, 83233, 83238, 83239, 83254, 83261, 83272, 83287,
  ],
  "Custer County, Idaho": [83226, 83227, 83235, 83251, 83253, 83278],
  "Franklin County, Idaho": [83228, 83232, 83237, 83263, 83283, 83286],
  "Lemhi County, Idaho": [
    83229, 83462, 83463, 83464, 83465, 83466, 83467, 83468, 83469,
  ],
  "Oneida County, Idaho": [83243, 83252],
  "Twin Falls County, Idaho": [
    83301, 83302, 83303, 83316, 83321, 83328, 83334, 83341, 83344,
  ],
  "Cassia County, Idaho": [83311, 83312, 83318, 83323, 83342, 83346],
  "Blaine County, Idaho": [83313, 83320, 83333, 83340, 83348, 83353, 83354],
  "Gooding County, Idaho": [83314, 83330, 83332, 83355],
  "Camas County, Idaho": [83322, 83327, 83337],
  "Lincoln County, Idaho": [83324, 83349, 83352],
  "Jerome County, Idaho": [83325, 83335, 83338],
  "Minidoka County, Idaho": [83336, 83343, 83347, 83350],
  "Bonneville County, Idaho": [
    83401, 83402, 83403, 83404, 83405, 83406, 83415, 83427, 83428, 83449, 83454,
  ],
  "Fremont County, Idaho": [
    83420, 83421, 83429, 83433, 83436, 83438, 83445, 83451,
  ],
  "Teton County, Idaho": [83422, 83424, 83452, 83455],
  "Clark County, Idaho": [83423, 83446],
  "Jefferson County, Idaho": [
    83425, 83431, 83434, 83435, 83442, 83443, 83444, 83450,
  ],
  "Madison County, Idaho": [83440, 83441, 83448, 83460],
  "Nez Perce County, Idaho": [83501, 83524, 83540, 83545, 83548],
  "Clearwater County, Idaho": [83520, 83541, 83544, 83546, 83553, 83827],
  "Idaho County, Idaho": [
    83522, 83525, 83526, 83530, 83531, 83533, 83536, 83539, 83542, 83547, 83549,
    83552, 83554, 83671,
  ],
  "Lewis County, Idaho": [83523, 83543, 83555],
  "Latah County, Idaho": [
    83535, 83537, 83806, 83823, 83832, 83834, 83843, 83844, 83855, 83857, 83871,
    83872,
  ],
  "Elmore County, Idaho": [83601, 83623, 83627, 83633, 83647, 83648],
  "Boise County, Idaho": [83602, 83622, 83629, 83631, 83637, 83666],
  "Owyhee County, Idaho": [83604, 83624, 83628, 83639, 83650],
  "Canyon County, Idaho": [
    83605, 83606, 83607, 83626, 83630, 83641, 83644, 83651, 83652, 83653, 83656,
    83660, 83676, 83686, 83687,
  ],
  "Washington County, Idaho": [83610, 83645, 83672],
  "Valley County, Idaho": [83611, 83615, 83635, 83638, 83677],
  "Adams County, Idaho": [83612, 83632, 83643, 83654],
  "Ada County, Idaho": [
    83616, 83634, 83642, 83646, 83669, 83680, 83701, 83702, 83703, 83704, 83705,
    83706, 83707, 83708, 83709, 83711, 83712, 83713, 83714, 83715, 83716, 83717,
    83719, 83720, 83721, 83722, 83724, 83725, 83726, 83727, 83728, 83729, 83730,
    83731, 83732, 83733, 83735, 83756, 83757, 83799,
  ],
  "Gem County, Idaho": [83617, 83636, 83657, 83670],
  "Payette County, Idaho": [83619, 83655, 83661],
  "Kootenai County, Idaho": [
    83801, 83803, 83810, 83814, 83815, 83816, 83833, 83835, 83842, 83854, 83858,
    83869, 83876, 83877,
  ],
  "Shoshone County, Idaho": [
    83802, 83808, 83812, 83837, 83839, 83846, 83849, 83850, 83867, 83868, 83873,
    83874,
  ],
  "Bonner County, Idaho": [
    83804, 83809, 83811, 83813, 83821, 83822, 83825, 83836, 83840, 83841, 83848,
    83852, 83856, 83860, 83864, 83865,
  ],
  "Boundary County, Idaho": [83805, 83826, 83845, 83847, 83853],
  "Benewah County, Idaho": [83824, 83830, 83851, 83861, 83866, 83870],
  "Duchesne County, Utah": [
    84001, 84002, 84007, 84021, 84027, 84031, 84051, 84052, 84053, 84066, 84072,
    84073,
  ],
  "Utah County, Utah": [
    84003, 84004, 84005, 84013, 84042, 84043, 84045, 84057, 84058, 84059, 84062,
    84097, 84601, 84602, 84603, 84604, 84605, 84606, 84626, 84633, 84651, 84653,
    84655, 84660, 84663, 84664,
  ],
  "Salt Lake County, Utah": [
    84006, 84009, 84020, 84044, 84047, 84065, 84070, 84081, 84084, 84088, 84090,
    84091, 84092, 84093, 84094, 84095, 84096, 84101, 84102, 84103, 84104, 84105,
    84106, 84107, 84108, 84109, 84110, 84111, 84112, 84113, 84114, 84115, 84116,
    84117, 84118, 84119, 84120, 84121, 84122, 84123, 84124, 84125, 84126, 84127,
    84128, 84129, 84130, 84131, 84132, 84133, 84134, 84136, 84138, 84139, 84141,
    84143, 84144, 84145, 84147, 84148, 84150, 84151, 84152, 84157, 84158, 84165,
    84170, 84171, 84180, 84184, 84189, 84190, 84199,
  ],
  "Uintah County, Utah": [
    84008, 84026, 84035, 84039, 84063, 84076, 84078, 84079, 84085,
  ],
  "Davis County, Utah": [
    84010, 84011, 84014, 84015, 84016, 84025, 84037, 84040, 84041, 84054, 84056,
    84075, 84087, 84089,
  ],
  "Summit County, Utah": [
    84017, 84024, 84033, 84036, 84055, 84060, 84061, 84068, 84098,
  ],
  "Morgan County, Utah": [84018, 84050],
  "Tooele County, Utah": [
    84022, 84029, 84034, 84069, 84071, 84074, 84080, 84083,
  ],
  "Daggett County, Utah": [84023, 84046],
  "Rich County, Utah": [84028, 84038, 84064, 84086],
  "Wasatch County, Utah": [84032, 84049, 84082],
  "Weber County, Utah": [
    84067, 84201, 84244, 84310, 84315, 84317, 84401, 84402, 84403, 84404, 84405,
    84407, 84408, 84409, 84412, 84414, 84415,
  ],
  "Box Elder County, Utah": [
    84301, 84302, 84306, 84307, 84309, 84311, 84312, 84313, 84314, 84316, 84324,
    84329, 84330, 84331, 84334, 84336, 84337, 84340,
  ],
  "Cache County, Utah": [
    84304, 84305, 84308, 84318, 84319, 84320, 84321, 84322, 84323, 84325, 84326,
    84327, 84328, 84332, 84333, 84335, 84338, 84339, 84341,
  ],
  "Carbon County, Utah": [84501, 84520, 84526, 84529, 84539, 84542],
  "San Juan County, Utah": [
    84510, 84511, 84512, 84530, 84531, 84534, 84535, 84536,
  ],
  "Emery County, Utah": [
    84513, 84516, 84518, 84521, 84522, 84523, 84525, 84528, 84537,
  ],
  "Grand County, Utah": [84515, 84532, 84540],
  "Kane County, Utah": [84533, 84710, 84729, 84741, 84755, 84758, 84762],
  "Sevier County, Utah": [
    84620, 84652, 84654, 84657, 84701, 84711, 84724, 84730, 84739, 84744, 84754,
    84766,
  ],
  "Sanpete County, Utah": [
    84621, 84622, 84623, 84627, 84629, 84630, 84632, 84634, 84642, 84643, 84646,
    84647, 84662, 84665, 84667,
  ],
  "Millard County, Utah": [
    84624, 84631, 84635, 84636, 84637, 84638, 84640, 84644, 84649, 84656, 84728,
  ],
  "Juab County, Utah": [84628, 84639, 84645, 84648],
  "Garfield County, Utah": [
    84712, 84716, 84717, 84718, 84726, 84735, 84736, 84759, 84764, 84776,
  ],
  "Beaver County, Utah": [84713, 84731, 84751, 84752],
  "Iron County, Utah": [
    84714, 84719, 84720, 84721, 84742, 84753, 84756, 84760, 84761, 84772,
  ],
  "Wayne County, Utah": [84715, 84734, 84747, 84749, 84773, 84775],
  "Washington County, Utah": [
    84722, 84725, 84733, 84737, 84738, 84745, 84746, 84757, 84763, 84765, 84767,
    84770, 84771, 84774, 84779, 84780, 84781, 84782, 84783, 84784, 84790, 84791,
  ],
  "Piute County, Utah": [84723, 84732, 84740, 84743, 84750],
  "Maricopa County, Arizona": [
    85001, 85002, 85003, 85004, 85005, 85006, 85007, 85008, 85009, 85010, 85011,
    85012, 85013, 85014, 85015, 85016, 85017, 85018, 85019, 85020, 85021, 85022,
    85023, 85024, 85025, 85026, 85027, 85028, 85029, 85030, 85031, 85032, 85033,
    85034, 85035, 85036, 85037, 85038, 85039, 85040, 85041, 85042, 85043, 85044,
    85045, 85046, 85048, 85050, 85051, 85053, 85054, 85055, 85060, 85061, 85062,
    85063, 85064, 85065, 85066, 85067, 85068, 85069, 85070, 85071, 85072, 85073,
    85074, 85075, 85076, 85077, 85078, 85079, 85080, 85082, 85083, 85085, 85086,
    85087, 85096, 85097, 85098, 85099, 85127, 85142, 85190, 85201, 85202, 85203,
    85204, 85205, 85206, 85207, 85208, 85209, 85210, 85211, 85212, 85213, 85214,
    85215, 85216, 85224, 85225, 85226, 85227, 85233, 85234, 85236, 85240, 85242,
    85243, 85244, 85246, 85248, 85249, 85250, 85251, 85252, 85253, 85254, 85255,
    85256, 85257, 85258, 85259, 85260, 85261, 85262, 85263, 85264, 85266, 85267,
    85268, 85269, 85271, 85274, 85275, 85277, 85280, 85281, 85282, 85283, 85284,
    85285, 85286, 85287, 85288, 85289, 85290, 85295, 85296, 85297, 85298, 85299,
    85301, 85302, 85303, 85304, 85305, 85306, 85307, 85308, 85309, 85310, 85311,
    85312, 85313, 85318, 85320, 85322, 85323, 85326, 85327, 85329, 85331, 85335,
    85337, 85338, 85339, 85340, 85342, 85343, 85345, 85351, 85353, 85354, 85355,
    85358, 85361, 85363, 85372, 85373, 85374, 85375, 85376, 85377, 85378, 85379,
    85380, 85381, 85382, 85383, 85385, 85387, 85388, 85390, 85392, 85395, 85396,
  ],
  "Pinal County, Arizona": [
    85117, 85118, 85119, 85120, 85121, 85122, 85123, 85128, 85130, 85131, 85132,
    85137, 85138, 85139, 85140, 85141, 85143, 85145, 85147, 85172, 85173, 85178,
    85191, 85192, 85193, 85194, 85217, 85218, 85219, 85220, 85221, 85222, 85223,
    85228, 85230, 85231, 85232, 85237, 85238, 85239, 85241, 85245, 85247, 85272,
    85273, 85278, 85279, 85291, 85292, 85293, 85294, 85618, 85623, 85631, 85739,
  ],
  "Gila County, Arizona": [
    85135, 85235, 85501, 85502, 85532, 85539, 85541, 85544, 85545, 85547, 85550,
    85553, 85554,
  ],
  "Pima County, Arizona": [
    85321, 85341, 85601, 85614, 85619, 85622, 85629, 85633, 85634, 85639, 85641,
    85645, 85652, 85653, 85654, 85658, 85701, 85702, 85703, 85704, 85705, 85706,
    85707, 85708, 85709, 85710, 85711, 85712, 85713, 85714, 85715, 85716, 85717,
    85718, 85719, 85720, 85721, 85722, 85723, 85724, 85725, 85726, 85728, 85730,
    85731, 85732, 85733, 85734, 85735, 85736, 85737, 85738, 85740, 85741, 85742,
    85743, 85744, 85745, 85746, 85747, 85748, 85749, 85750, 85751, 85752, 85754,
    85755, 85756, 85757, 85775, 85777,
  ],
  "Yavapai County, Arizona": [
    85324, 85332, 85362, 86301, 86302, 86303, 86304, 86305, 86312, 86313, 86314,
    86315, 86321, 86322, 86323, 86324, 86325, 86326, 86327, 86329, 86330, 86331,
    86332, 86333, 86334, 86335, 86336, 86337, 86338, 86339, 86340, 86341, 86342,
    86343, 86351,
  ],
  "La Paz County, Arizona": [
    85325, 85328, 85334, 85344, 85346, 85348, 85357, 85359, 85371,
  ],
  "Yuma County, Arizona": [
    85333, 85336, 85347, 85349, 85350, 85352, 85356, 85364, 85365, 85366, 85367,
    85369,
  ],
  "Mohave County, Arizona": [
    85360, 86021, 86401, 86402, 86403, 86404, 86405, 86406, 86409, 86411, 86412,
    86413, 86426, 86427, 86429, 86430, 86431, 86432, 86433, 86434, 86436, 86437,
    86438, 86439, 86440, 86441, 86442, 86443, 86444, 86445, 86446,
  ],
  "Graham County, Arizona": [
    85530, 85531, 85535, 85536, 85542, 85543, 85546, 85548, 85551, 85552,
  ],
  "Greenlee County, Arizona": [85533, 85534, 85540, 85922],
  "Cochise County, Arizona": [
    85602, 85603, 85605, 85606, 85607, 85608, 85609, 85610, 85613, 85615, 85616,
    85617, 85620, 85625, 85626, 85627, 85630, 85632, 85635, 85636, 85638, 85643,
    85644, 85650, 85655, 85670, 85671, 86555,
  ],
  "Santa Cruz County, Arizona": [
    85611, 85621, 85624, 85628, 85637, 85640, 85646, 85648, 85662,
  ],
  "Navajo County, Arizona": [
    85901, 85902, 85911, 85912, 85923, 85926, 85928, 85929, 85930, 85933, 85934,
    85935, 85937, 85939, 85941, 85942, 86025, 86029, 86030, 86031, 86032, 86033,
    86034, 86039, 86042, 86043, 86047, 86054, 86510, 86520,
  ],
  "Apache County, Arizona": [
    85920, 85924, 85925, 85927, 85932, 85936, 85938, 85940, 86028, 86502, 86503,
    86504, 86505, 86506, 86507, 86508, 86511, 86512, 86514, 86515, 86535, 86538,
    86540, 86544, 86545, 86547, 86556,
  ],
  "Coconino County, Arizona": [
    85931, 86001, 86002, 86003, 86004, 86005, 86011, 86015, 86016, 86017, 86018,
    86020, 86022, 86023, 86024, 86035, 86036, 86038, 86040, 86044, 86045, 86046,
    86052, 86053, 86320, 86435,
  ],
  "Sandoval County, New Mexico": [
    87001, 87004, 87013, 87018, 87024, 87025, 87027, 87041, 87043, 87044, 87046,
    87048, 87052, 87053, 87072, 87083, 87124, 87144, 87174,
  ],
  "Valencia County, New Mexico": [87002, 87023, 87031, 87042, 87060, 87068],
  "Cibola County, New Mexico": [
    87005, 87007, 87014, 87020, 87021, 87026, 87034, 87038, 87040, 87049, 87051,
    87315, 87321, 87357,
  ],
  "Socorro County, New Mexico": [
    87006, 87028, 87062, 87801, 87823, 87825, 87828, 87831, 87832,
  ],
  "Bernalillo County, New Mexico": [
    87008, 87022, 87047, 87059, 87101, 87102, 87103, 87104, 87105, 87106, 87107,
    87108, 87109, 87110, 87111, 87112, 87113, 87114, 87115, 87116, 87117, 87119,
    87120, 87121, 87122, 87123, 87125, 87131, 87151, 87153, 87154, 87158, 87165,
    87176, 87181, 87184, 87185, 87187, 87190, 87191, 87192, 87193, 87194, 87195,
    87196, 87197, 87198, 87199,
  ],
  "Torrance County, New Mexico": [
    87009, 87016, 87032, 87035, 87036, 87061, 87063, 87070, 88321,
  ],
  "Santa Fe County, New Mexico": [
    87010, 87015, 87056, 87501, 87502, 87503, 87504, 87505, 87506, 87507, 87508,
    87509, 87535, 87540, 87567, 87574, 87592, 87594,
  ],
  "Lincoln County, New Mexico": [
    87011, 88301, 88312, 88316, 88318, 88323, 88324, 88336, 88338, 88341, 88343,
    88345, 88346, 88348, 88351, 88355,
  ],
  "Rio Arriba County, New Mexico": [
    87012, 87017, 87029, 87064, 87510, 87511, 87515, 87516, 87518, 87520, 87522,
    87523, 87527, 87528, 87530, 87531, 87532, 87533, 87537, 87539, 87548, 87549,
    87551, 87554, 87566, 87575, 87578, 87581, 87582,
  ],
  "San Juan County, New Mexico": [
    87037, 87364, 87401, 87402, 87410, 87412, 87413, 87415, 87416, 87417, 87418,
    87419, 87420, 87421, 87455, 87461, 87499,
  ],
  "McKinley County, New Mexico": [
    87045, 87301, 87305, 87310, 87311, 87312, 87313, 87316, 87317, 87319, 87320,
    87322, 87323, 87325, 87326, 87327, 87328, 87347, 87365, 87375,
  ],
  "Mckinley County, New Mexico": [87302],
  "Taos County, New Mexico": [
    87512, 87513, 87514, 87517, 87519, 87521, 87524, 87525, 87529, 87543, 87553,
    87556, 87557, 87558, 87564, 87571, 87576, 87577, 87579, 87580,
  ],
  "San Miguel County, New Mexico": [
    87538, 87552, 87560, 87562, 87565, 87569, 87573, 87583, 87701, 87731, 87742,
    87745, 87746, 88416, 88421, 88431, 88439,
  ],
  "Los Alamos County, New Mexico": [87544, 87545, 87547],
  "undefined, New Mexico": [87654],
  "Colfax County, New Mexico": [
    87710, 87714, 87718, 87728, 87729, 87740, 87747, 87749,
  ],
  "Guadalupe County, New Mexico": [87711, 87724, 88353, 88417, 88435],
  "Mora County, New Mexico": [
    87712, 87713, 87715, 87722, 87723, 87732, 87734, 87735, 87736, 87750, 87752,
    87753,
  ],
  "Harding County, New Mexico": [87730, 87733, 87743],
  "Catron County, New Mexico": [
    87820, 87821, 87824, 87827, 87829, 87830, 88039,
  ],
  "Sierra County, New Mexico": [
    87901, 87930, 87931, 87933, 87935, 87939, 87942, 87943, 88042,
  ],
  "Dona Ana County, New Mexico": [
    87936, 87937, 87940, 87941, 88001, 88002, 88003, 88004, 88005, 88006, 88007,
    88008, 88011, 88012, 88013, 88021, 88024, 88027, 88032, 88033, 88044, 88046,
    88047, 88048, 88052, 88054, 88058, 88063, 88072,
  ],
  "Hidalgo County, New Mexico": [88009, 88020, 88045, 88056],
  "Grant County, New Mexico": [
    88022, 88023, 88025, 88026, 88028, 88034, 88036, 88038, 88040, 88041, 88043,
    88049, 88051, 88053, 88055, 88061, 88062, 88065,
  ],
  "Luna County, New Mexico": [88029, 88030, 88031],
  "Otero County, New Mexico": [
    88081, 88310, 88311, 88314, 88317, 88325, 88330, 88337, 88339, 88340, 88342,
    88344, 88347, 88349, 88350, 88352, 88354,
  ],
  "Curry County, New Mexico": [
    88101, 88102, 88103, 88112, 88120, 88124, 88133, 88135,
  ],
  "Roosevelt County, New Mexico": [
    88113, 88115, 88116, 88118, 88122, 88123, 88125, 88126, 88130, 88132,
  ],
  "Lea County, New Mexico": [
    88114, 88213, 88231, 88240, 88241, 88242, 88244, 88252, 88260, 88262, 88264,
    88265, 88267,
  ],
  "De Baca County, New Mexico": [88119, 88134, 88136],
  "Quay County, New Mexico": [
    88121, 88401, 88411, 88426, 88427, 88430, 88433, 88434,
  ],
  "Chaves County, New Mexico": [88201, 88202, 88203, 88230, 88232, 88253],
  "Eddy County, New Mexico": [
    88210, 88211, 88220, 88221, 88250, 88254, 88255, 88256, 88263, 88268,
  ],
  "Union County, New Mexico": [
    88410, 88414, 88415, 88418, 88419, 88422, 88424, 88436,
  ],
  "Clark County, Nevada": [
    88901, 88905, 89002, 89004, 89005, 89006, 89007, 89009, 89011, 89012, 89014,
    89015, 89016, 89018, 89019, 89021, 89024, 89025, 89026, 89027, 89028, 89029,
    89030, 89031, 89032, 89033, 89034, 89036, 89037, 89039, 89040, 89044, 89046,
    89052, 89053, 89054, 89070, 89074, 89077, 89081, 89084, 89085, 89086, 89087,
    89101, 89102, 89103, 89104, 89105, 89106, 89107, 89108, 89109, 89110, 89111,
    89112, 89113, 89114, 89115, 89116, 89117, 89118, 89119, 89120, 89121, 89122,
    89123, 89124, 89125, 89126, 89127, 89128, 89129, 89130, 89131, 89132, 89133,
    89134, 89135, 89136, 89137, 89138, 89139, 89140, 89141, 89142, 89143, 89144,
    89145, 89146, 89147, 89148, 89149, 89150, 89151, 89152, 89153, 89154, 89155,
    89156, 89157, 89158, 89159, 89160, 89161, 89162, 89163, 89164, 89165, 89166,
    89169, 89170, 89173, 89177, 89178, 89179, 89180, 89183, 89185, 89191, 89193,
    89195, 89199,
  ],
  "Lincoln County, Nevada": [89001, 89008, 89017, 89042, 89043, 89067],
  "Nye County, Nevada": [
    89003, 89020, 89022, 89023, 89041, 89045, 89048, 89049, 89060, 89061, 89314,
    89409,
  ],
  "Esmeralda County, Nevada": [89010, 89013, 89047],
  "White Pine County, Nevada": [89301, 89311, 89315, 89317, 89318, 89319],
  "Lander County, Nevada": [89310, 89820],
  "Eureka County, Nevada": [89316, 89821],
  "Washoe County, Nevada": [
    89402, 89405, 89412, 89424, 89431, 89432, 89433, 89434, 89435, 89436, 89439,
    89441, 89442, 89450, 89451, 89452, 89501, 89502, 89503, 89504, 89505, 89506,
    89507, 89508, 89509, 89510, 89511, 89512, 89513, 89515, 89519, 89520, 89521,
    89523, 89533, 89555, 89557, 89570, 89595, 89599, 89704,
  ],
  "Lyon County, Nevada": [89403, 89408, 89428, 89429, 89430, 89447],
  "Humboldt County, Nevada": [
    89404, 89414, 89421, 89425, 89426, 89438, 89445, 89446,
  ],
  "Churchill County, Nevada": [89406, 89407, 89496],
  "Douglas County, Nevada": [
    89410, 89411, 89413, 89423, 89444, 89448, 89449, 89460, 89705,
  ],
  "Mineral County, Nevada": [89415, 89420, 89422, 89427],
  "Pershing County, Nevada": [89418, 89419],
  "Storey County, Nevada": [89437, 89440],
  "Carson City, Nevada": [
    89701, 89702, 89703, 89706, 89711, 89712, 89713, 89714, 89721,
  ],
  "Elko County, Nevada": [
    89801, 89802, 89803, 89815, 89822, 89823, 89824, 89825, 89826, 89828, 89830,
    89831, 89832, 89833, 89834, 89835, 89883,
  ],
  "Los Angeles County, California": [
    90001, 90002, 90003, 90004, 90005, 90006, 90007, 90008, 90009, 90010, 90011,
    90012, 90013, 90014, 90015, 90016, 90017, 90018, 90019, 90020, 90021, 90022,
    90023, 90024, 90025, 90026, 90027, 90028, 90029, 90030, 90031, 90032, 90033,
    90034, 90035, 90036, 90037, 90038, 90039, 90040, 90041, 90042, 90043, 90044,
    90045, 90046, 90047, 90048, 90049, 90050, 90051, 90052, 90053, 90054, 90055,
    90056, 90057, 90058, 90059, 90060, 90061, 90062, 90063, 90064, 90065, 90066,
    90067, 90068, 90069, 90070, 90071, 90072, 90073, 90074, 90075, 90076, 90077,
    90078, 90079, 90080, 90081, 90082, 90083, 90084, 90086, 90087, 90088, 90089,
    90090, 90091, 90093, 90094, 90095, 90096, 90099, 90101, 90102, 90103, 90134,
    90189, 90201, 90202, 90209, 90210, 90211, 90212, 90213, 90220, 90221, 90222,
    90223, 90224, 90230, 90231, 90232, 90233, 90239, 90240, 90241, 90242, 90245,
    90247, 90248, 90249, 90250, 90251, 90254, 90255, 90260, 90261, 90262, 90263,
    90264, 90265, 90266, 90267, 90270, 90272, 90274, 90275, 90277, 90278, 90280,
    90290, 90291, 90292, 90293, 90294, 90295, 90296, 90301, 90302, 90303, 90304,
    90305, 90306, 90307, 90308, 90309, 90310, 90311, 90312, 90313, 90397, 90398,
    90401, 90402, 90403, 90404, 90405, 90406, 90407, 90408, 90409, 90410, 90411,
    90501, 90502, 90503, 90504, 90505, 90506, 90507, 90508, 90509, 90510, 90601,
    90602, 90603, 90604, 90605, 90606, 90607, 90608, 90609, 90610, 90612, 90637,
    90638, 90639, 90640, 90650, 90651, 90652, 90659, 90660, 90661, 90662, 90670,
    90671, 90701, 90702, 90703, 90704, 90706, 90707, 90710, 90711, 90712, 90713,
    90714, 90715, 90716, 90717, 90723, 90731, 90732, 90733, 90734, 90744, 90745,
    90746, 90747, 90748, 90749, 90755, 90801, 90802, 90803, 90804, 90805, 90806,
    90807, 90808, 90809, 90810, 90813, 90814, 90815, 90822, 90831, 90832, 90833,
    90834, 90835, 90840, 90842, 90844, 90845, 90846, 90847, 90848, 90853, 90888,
    90895, 90899, 91001, 91003, 91006, 91007, 91008, 91009, 91010, 91011, 91012,
    91016, 91017, 91020, 91021, 91023, 91024, 91025, 91030, 91031, 91040, 91041,
    91042, 91043, 91046, 91066, 91077, 91101, 91102, 91103, 91104, 91105, 91106,
    91107, 91108, 91109, 91110, 91114, 91115, 91116, 91117, 91118, 91121, 91123,
    91124, 91125, 91126, 91129, 91131, 91182, 91184, 91185, 91188, 91189, 91191,
    91199, 91201, 91202, 91203, 91204, 91205, 91206, 91207, 91208, 91209, 91210,
    91214, 91221, 91222, 91224, 91225, 91226, 91301, 91302, 91303, 91304, 91305,
    91306, 91307, 91308, 91309, 91310, 91311, 91313, 91316, 91321, 91322, 91324,
    91325, 91326, 91327, 91328, 91329, 91330, 91331, 91333, 91334, 91335, 91337,
    91340, 91341, 91342, 91343, 91344, 91345, 91346, 91350, 91351, 91352, 91353,
    91354, 91355, 91356, 91357, 91359, 91363, 91364, 91365, 91367, 91371, 91372,
    91376, 91380, 91381, 91382, 91383, 91384, 91385, 91386, 91387, 91388, 91390,
    91392, 91393, 91394, 91395, 91396, 91399, 91401, 91402, 91403, 91404, 91405,
    91406, 91407, 91408, 91409, 91410, 91411, 91412, 91413, 91416, 91423, 91426,
    91436, 91470, 91482, 91495, 91496, 91497, 91499, 91501, 91502, 91503, 91504,
    91505, 91506, 91507, 91508, 91510, 91521, 91522, 91523, 91526, 91601, 91602,
    91603, 91604, 91605, 91606, 91607, 91608, 91609, 91610, 91611, 91612, 91614,
    91615, 91616, 91617, 91618, 91702, 91706, 91711, 91714, 91715, 91716, 91722,
    91723, 91724, 91731, 91732, 91733, 91734, 91735, 91740, 91741, 91744, 91745,
    91746, 91747, 91748, 91749, 91750, 91754, 91755, 91756, 91765, 91766, 91767,
    91768, 91769, 91770, 91771, 91772, 91773, 91775, 91776, 91778, 91780, 91788,
    91789, 91790, 91791, 91792, 91793, 91795, 91797, 91799, 91801, 91802, 91803,
    91804, 91841, 91896, 91899, 93510, 93532, 93534, 93535, 93536, 93539, 93543,
    93544, 93550, 93551, 93552, 93553, 93563, 93584, 93586, 93590, 93591, 93599,
  ],
  "Orange County, California": [
    90620, 90621, 90622, 90623, 90624, 90630, 90631, 90632, 90633, 90680, 90720,
    90721, 90740, 90742, 90743, 92602, 92603, 92604, 92605, 92606, 92607, 92609,
    92610, 92612, 92614, 92615, 92616, 92617, 92618, 92619, 92620, 92623, 92624,
    92625, 92626, 92627, 92628, 92629, 92630, 92637, 92646, 92647, 92648, 92649,
    92650, 92651, 92652, 92653, 92654, 92655, 92656, 92657, 92658, 92659, 92660,
    92661, 92662, 92663, 92672, 92673, 92674, 92675, 92676, 92677, 92678, 92679,
    92683, 92684, 92685, 92688, 92690, 92691, 92692, 92693, 92694, 92697, 92698,
    92701, 92702, 92703, 92704, 92705, 92706, 92707, 92708, 92709, 92710, 92711,
    92712, 92725, 92728, 92735, 92780, 92781, 92782, 92799, 92801, 92802, 92803,
    92804, 92805, 92806, 92807, 92808, 92809, 92811, 92812, 92814, 92815, 92816,
    92817, 92821, 92822, 92823, 92825, 92831, 92832, 92833, 92834, 92835, 92836,
    92837, 92838, 92840, 92841, 92842, 92843, 92844, 92845, 92846, 92850, 92856,
    92857, 92859, 92861, 92862, 92863, 92864, 92865, 92866, 92867, 92868, 92869,
    92870, 92871, 92885, 92886, 92887, 92899,
  ],
  "Ventura County, California": [
    91319, 91320, 91358, 91360, 91361, 91362, 91377, 93001, 93002, 93003, 93004,
    93005, 93006, 93007, 93009, 93010, 93011, 93012, 93015, 93016, 93020, 93021,
    93022, 93023, 93024, 93030, 93031, 93032, 93033, 93034, 93035, 93036, 93040,
    93041, 93042, 93043, 93044, 93060, 93061, 93062, 93063, 93064, 93065, 93066,
    93093, 93094, 93099,
  ],
  "San Bernardino County, California": [
    91701, 91708, 91709, 91710, 91729, 91730, 91737, 91739, 91743, 91758, 91759,
    91761, 91762, 91763, 91764, 91784, 91785, 91786, 91798, 92242, 92252, 92256,
    92267, 92268, 92277, 92278, 92280, 92284, 92285, 92286, 92301, 92304, 92305,
    92307, 92308, 92309, 92310, 92311, 92312, 92313, 92314, 92315, 92316, 92317,
    92318, 92321, 92322, 92323, 92324, 92325, 92326, 92327, 92329, 92331, 92332,
    92333, 92334, 92335, 92336, 92337, 92338, 92339, 92340, 92341, 92342, 92344,
    92345, 92346, 92347, 92350, 92352, 92354, 92356, 92357, 92358, 92359, 92363,
    92364, 92365, 92366, 92368, 92369, 92371, 92372, 92373, 92374, 92375, 92376,
    92377, 92378, 92382, 92385, 92386, 92391, 92392, 92393, 92394, 92395, 92397,
    92398, 92399, 92401, 92402, 92403, 92404, 92405, 92406, 92407, 92408, 92410,
    92411, 92412, 92413, 92414, 92415, 92418, 92423, 92424, 92427, 93558, 93562,
  ],
  "Riverside County, California": [
    91752, 92201, 92202, 92203, 92210, 92211, 92220, 92223, 92225, 92226, 92230,
    92234, 92235, 92236, 92239, 92240, 92241, 92247, 92248, 92253, 92254, 92255,
    92258, 92260, 92261, 92262, 92263, 92264, 92270, 92274, 92276, 92282, 92292,
    92320, 92501, 92502, 92503, 92504, 92505, 92506, 92507, 92508, 92509, 92513,
    92514, 92515, 92516, 92517, 92518, 92519, 92521, 92522, 92530, 92531, 92532,
    92536, 92539, 92543, 92544, 92545, 92546, 92548, 92549, 92551, 92552, 92553,
    92554, 92555, 92556, 92557, 92561, 92562, 92563, 92564, 92567, 92570, 92571,
    92572, 92581, 92582, 92583, 92584, 92585, 92586, 92587, 92589, 92590, 92591,
    92592, 92593, 92595, 92596, 92599, 92860, 92877, 92878, 92879, 92880, 92881,
    92882, 92883,
  ],
  "San Diego County, California": [
    91901, 91902, 91903, 91905, 91906, 91908, 91909, 91910, 91911, 91912, 91913,
    91914, 91915, 91916, 91917, 91921, 91931, 91932, 91933, 91934, 91935, 91941,
    91942, 91943, 91944, 91945, 91946, 91947, 91948, 91950, 91951, 91962, 91963,
    91976, 91977, 91978, 91979, 91980, 91987, 91990, 92003, 92004, 92007, 92008,
    92009, 92010, 92011, 92013, 92014, 92018, 92019, 92020, 92021, 92022, 92023,
    92024, 92025, 92026, 92027, 92028, 92029, 92030, 92033, 92036, 92037, 92038,
    92039, 92040, 92046, 92049, 92051, 92052, 92054, 92055, 92056, 92057, 92058,
    92059, 92060, 92061, 92064, 92065, 92066, 92067, 92068, 92069, 92070, 92071,
    92072, 92074, 92075, 92078, 92079, 92081, 92082, 92083, 92084, 92085, 92086,
    92088, 92090, 92091, 92092, 92093, 92096, 92101, 92102, 92103, 92104, 92105,
    92106, 92107, 92108, 92109, 92110, 92111, 92112, 92113, 92114, 92115, 92116,
    92117, 92118, 92119, 92120, 92121, 92122, 92123, 92124, 92126, 92127, 92128,
    92129, 92130, 92131, 92132, 92133, 92134, 92135, 92136, 92137, 92138, 92139,
    92140, 92142, 92143, 92145, 92147, 92149, 92150, 92152, 92153, 92154, 92155,
    92158, 92159, 92160, 92161, 92162, 92163, 92164, 92165, 92166, 92167, 92168,
    92169, 92170, 92171, 92172, 92173, 92174, 92175, 92176, 92177, 92178, 92179,
    92182, 92184, 92186, 92187, 92190, 92191, 92192, 92193, 92194, 92195, 92196,
    92197, 92198, 92199,
  ],
  "Imperial County, California": [
    92222, 92227, 92231, 92232, 92233, 92243, 92244, 92249, 92250, 92251, 92257,
    92259, 92266, 92273, 92275, 92281, 92283,
  ],
  "Inyo County, California": [
    92328, 92384, 92389, 93513, 93514, 93515, 93522, 93526, 93530, 93542, 93545,
    93549, 93592,
  ],
  "Santa Barbara County, California": [
    93013, 93014, 93067, 93101, 93102, 93103, 93105, 93106, 93107, 93108, 93109,
    93110, 93111, 93116, 93117, 93118, 93120, 93121, 93130, 93140, 93150, 93160,
    93190, 93199, 93254, 93427, 93429, 93434, 93436, 93437, 93438, 93440, 93441,
    93454, 93455, 93456, 93457, 93458, 93460, 93463, 93464,
  ],
  "Tulare County, California": [
    93201, 93207, 93208, 93218, 93219, 93221, 93223, 93227, 93235, 93237, 93244,
    93247, 93256, 93257, 93258, 93260, 93261, 93262, 93265, 93267, 93270, 93271,
    93272, 93274, 93275, 93277, 93278, 93279, 93282, 93286, 93290, 93291, 93292,
    93603, 93615, 93618, 93633, 93647, 93666, 93670, 93673,
  ],
  "Kings County, California": [
    93202, 93204, 93212, 93230, 93232, 93239, 93245, 93246, 93266,
  ],
  "Kern County, California": [
    93203, 93205, 93206, 93215, 93216, 93220, 93222, 93224, 93225, 93226, 93238,
    93240, 93241, 93243, 93249, 93250, 93251, 93252, 93255, 93263, 93268, 93276,
    93280, 93283, 93285, 93287, 93301, 93302, 93303, 93304, 93305, 93306, 93307,
    93308, 93309, 93311, 93312, 93313, 93314, 93380, 93381, 93382, 93383, 93384,
    93385, 93386, 93387, 93388, 93389, 93390, 93501, 93502, 93504, 93505, 93516,
    93518, 93519, 93523, 93524, 93527, 93528, 93531, 93554, 93555, 93556, 93560,
    93561, 93581, 93596,
  ],
  "Fresno County, California": [
    93210, 93234, 93242, 93602, 93605, 93606, 93607, 93608, 93609, 93611, 93612,
    93613, 93616, 93619, 93621, 93622, 93624, 93625, 93626, 93627, 93628, 93630,
    93631, 93634, 93640, 93641, 93642, 93646, 93648, 93649, 93650, 93651, 93652,
    93654, 93656, 93657, 93660, 93662, 93664, 93667, 93668, 93675, 93701, 93702,
    93703, 93704, 93705, 93706, 93707, 93708, 93709, 93710, 93711, 93712, 93714,
    93715, 93716, 93717, 93718, 93720, 93721, 93722, 93723, 93724, 93725, 93726,
    93727, 93728, 93729, 93730, 93737, 93740, 93741, 93744, 93745, 93747, 93750,
    93755, 93760, 93761, 93764, 93765, 93771, 93772, 93773, 93774, 93775, 93776,
    93777, 93778, 93779, 93780, 93784, 93786, 93790, 93791, 93792, 93793, 93794,
    93844, 93888,
  ],
  "San Luis Obispo County, California": [
    93401, 93402, 93403, 93405, 93406, 93407, 93408, 93409, 93410, 93412, 93420,
    93421, 93422, 93423, 93424, 93428, 93430, 93432, 93433, 93435, 93442, 93443,
    93444, 93445, 93446, 93447, 93448, 93449, 93451, 93452, 93453, 93461, 93465,
    93475, 93483,
  ],
  "Monterey County, California": [
    93426, 93450, 93901, 93902, 93905, 93906, 93907, 93908, 93912, 93915, 93920,
    93921, 93922, 93923, 93924, 93925, 93926, 93927, 93928, 93930, 93932, 93933,
    93940, 93942, 93943, 93944, 93950, 93953, 93954, 93955, 93960, 93962, 95004,
    95012, 95039,
  ],
  "Mono County, California": [93512, 93517, 93529, 93541, 93546, 96107, 96133],
  "Madera County, California": [
    93601, 93604, 93610, 93614, 93636, 93637, 93638, 93639, 93643, 93644, 93645,
    93653, 93669,
  ],
  "Merced County, California": [
    93620, 93635, 93661, 93665, 95301, 95303, 95312, 95315, 95317, 95322, 95324,
    95333, 95334, 95340, 95341, 95343, 95344, 95348, 95365, 95369, 95374, 95388,
  ],
  "Mariposa County, California": [
    93623, 95306, 95311, 95318, 95325, 95338, 95345, 95389,
  ],
  "San Mateo County, California": [
    94002, 94005, 94010, 94011, 94013, 94014, 94015, 94016, 94017, 94018, 94019,
    94020, 94021, 94025, 94026, 94027, 94028, 94030, 94037, 94038, 94044, 94060,
    94061, 94062, 94063, 94064, 94065, 94066, 94070, 94074, 94080, 94083, 94128,
    94303, 94401, 94402, 94403, 94404, 94497,
  ],
  "Santa Clara County, California": [
    94022, 94023, 94024, 94035, 94039, 94040, 94041, 94042, 94043, 94085, 94086,
    94087, 94088, 94089, 94301, 94302, 94304, 94305, 94306, 94309, 95002, 95008,
    95009, 95011, 95013, 95014, 95015, 95020, 95021, 95026, 95030, 95031, 95032,
    95035, 95036, 95037, 95038, 95042, 95044, 95046, 95050, 95051, 95052, 95053,
    95054, 95055, 95056, 95070, 95071, 95101, 95103, 95106, 95108, 95109, 95110,
    95111, 95112, 95113, 95115, 95116, 95117, 95118, 95119, 95120, 95121, 95122,
    95123, 95124, 95125, 95126, 95127, 95128, 95129, 95130, 95131, 95132, 95133,
    95134, 95135, 95136, 95138, 95139, 95140, 95141, 95148, 95150, 95151, 95152,
    95153, 95154, 95155, 95156, 95157, 95158, 95159, 95160, 95161, 95164, 95170,
    95172, 95173, 95190, 95191, 95192, 95193, 95194, 95196,
  ],
  "San Francisco County, California": [
    94101, 94102, 94103, 94104, 94105, 94106, 94107, 94108, 94109, 94110, 94111,
    94112, 94114, 94115, 94116, 94117, 94118, 94119, 94120, 94121, 94122, 94123,
    94124, 94125, 94126, 94127, 94129, 94130, 94131, 94132, 94133, 94134, 94135,
    94136, 94137, 94138, 94139, 94140, 94141, 94142, 94143, 94144, 94145, 94146,
    94147, 94150, 94151, 94152, 94153, 94154, 94155, 94156, 94158, 94159, 94160,
    94161, 94162, 94163, 94164, 94171, 94172, 94175, 94177, 94188, 94199,
  ],
  "Sacramento County, California": [
    94203, 94204, 94205, 94206, 94207, 94208, 94209, 94211, 94229, 94230, 94232,
    94234, 94235, 94236, 94237, 94239, 94240, 94244, 94245, 94246, 94247, 94248,
    94249, 94250, 94252, 94254, 94256, 94257, 94258, 94259, 94261, 94262, 94263,
    94267, 94268, 94269, 94271, 94273, 94274, 94277, 94278, 94279, 94280, 94282,
    94283, 94284, 94285, 94286, 94287, 94288, 94289, 94290, 94291, 94293, 94294,
    94295, 94296, 94297, 94298, 94299, 95608, 95609, 95610, 95611, 95615, 95621,
    95624, 95626, 95628, 95630, 95632, 95638, 95639, 95641, 95652, 95655, 95660,
    95662, 95670, 95671, 95673, 95680, 95683, 95690, 95693, 95741, 95742, 95757,
    95758, 95759, 95763, 95811, 95812, 95813, 95814, 95815, 95816, 95817, 95818,
    95819, 95820, 95821, 95822, 95823, 95824, 95825, 95826, 95827, 95828, 95829,
    95830, 95831, 95832, 95833, 95834, 95835, 95836, 95837, 95838, 95840, 95841,
    95842, 95843, 95851, 95852, 95853, 95860, 95864, 95865, 95866, 95867, 95887,
    95894, 95899,
  ],
  "Alameda County, California": [
    94501, 94502, 94536, 94537, 94538, 94539, 94540, 94541, 94542, 94543, 94544,
    94545, 94546, 94550, 94551, 94552, 94555, 94557, 94560, 94566, 94568, 94577,
    94578, 94579, 94580, 94586, 94587, 94588, 94601, 94602, 94603, 94604, 94605,
    94606, 94607, 94608, 94609, 94610, 94611, 94612, 94613, 94614, 94615, 94617,
    94618, 94619, 94620, 94621, 94622, 94623, 94624, 94625, 94649, 94659, 94660,
    94661, 94662, 94666, 94701, 94702, 94703, 94704, 94705, 94706, 94707, 94708,
    94709, 94710, 94712, 94720,
  ],
  "Napa County, California": [
    94503, 94508, 94515, 94558, 94559, 94562, 94567, 94573, 94574, 94576, 94581,
    94599,
  ],
  "Contra Costa County, California": [
    94505, 94506, 94507, 94509, 94511, 94513, 94514, 94516, 94517, 94518, 94519,
    94520, 94521, 94522, 94523, 94524, 94525, 94526, 94527, 94528, 94529, 94530,
    94531, 94547, 94548, 94549, 94553, 94556, 94561, 94563, 94564, 94565, 94569,
    94570, 94572, 94575, 94582, 94583, 94595, 94596, 94597, 94598, 94801, 94802,
    94803, 94804, 94805, 94806, 94807, 94808, 94820, 94850,
  ],
  "Solano County, California": [
    94510, 94512, 94533, 94534, 94535, 94571, 94585, 94589, 94590, 94591, 94592,
    95620, 95625, 95687, 95688, 95696,
  ],
  "Marin County, California": [
    94901, 94903, 94904, 94912, 94913, 94914, 94915, 94920, 94924, 94925, 94929,
    94930, 94933, 94937, 94938, 94939, 94940, 94941, 94942, 94945, 94946, 94947,
    94948, 94949, 94950, 94956, 94957, 94960, 94963, 94964, 94965, 94966, 94970,
    94971, 94973, 94974, 94976, 94977, 94978, 94979, 94998,
  ],
  "Sonoma County, California": [
    94922, 94923, 94926, 94927, 94928, 94931, 94951, 94952, 94953, 94954, 94955,
    94972, 94975, 94999, 95401, 95402, 95403, 95404, 95405, 95406, 95407, 95409,
    95412, 95416, 95419, 95421, 95425, 95430, 95431, 95433, 95436, 95439, 95441,
    95442, 95444, 95446, 95448, 95450, 95452, 95462, 95465, 95471, 95472, 95473,
    95476, 95480, 95486, 95487, 95492, 95497,
  ],
  "Santa Cruz County, California": [
    95001, 95003, 95005, 95006, 95007, 95010, 95017, 95018, 95019, 95033, 95041,
    95060, 95061, 95062, 95063, 95064, 95065, 95066, 95067, 95073, 95076, 95077,
  ],
  "San Benito County, California": [95023, 95024, 95043, 95045, 95075],
  "San Joaquin County, California": [
    95201, 95202, 95203, 95204, 95205, 95206, 95207, 95208, 95209, 95210, 95211,
    95212, 95213, 95215, 95219, 95220, 95227, 95230, 95231, 95234, 95236, 95237,
    95240, 95241, 95242, 95253, 95258, 95267, 95269, 95296, 95297, 95304, 95320,
    95330, 95336, 95337, 95366, 95376, 95377, 95378, 95391, 95686,
  ],
  "undefined, California": [95214],
  "Calaveras County, California": [
    95221, 95222, 95223, 95224, 95225, 95226, 95228, 95229, 95232, 95233, 95245,
    95246, 95247, 95248, 95249, 95250, 95251, 95252, 95254, 95255, 95257,
  ],
  "Tuolumne County, California": [
    95305, 95309, 95310, 95314, 95321, 95327, 95329, 95335, 95346, 95347, 95364,
    95370, 95372, 95373, 95375, 95379, 95383,
  ],
  "Stanislaus County, California": [
    95307, 95313, 95316, 95319, 95323, 95326, 95328, 95350, 95351, 95352, 95353,
    95354, 95355, 95356, 95357, 95358, 95360, 95361, 95363, 95367, 95368, 95380,
    95381, 95382, 95385, 95386, 95387, 95397,
  ],
  "Mendocino County, California": [
    95410, 95415, 95417, 95418, 95420, 95427, 95428, 95429, 95432, 95437, 95445,
    95449, 95454, 95456, 95459, 95460, 95463, 95466, 95468, 95469, 95470, 95481,
    95482, 95488, 95490, 95494, 95585, 95587,
  ],
  "Lake County, California": [
    95422, 95423, 95424, 95426, 95435, 95443, 95451, 95453, 95457, 95458, 95461,
    95464, 95467, 95485, 95493,
  ],
  "Humboldt County, California": [
    95501, 95502, 95503, 95511, 95514, 95518, 95519, 95521, 95524, 95525, 95526,
    95528, 95534, 95536, 95537, 95540, 95542, 95545, 95546, 95547, 95549, 95550,
    95551, 95553, 95554, 95555, 95556, 95558, 95559, 95560, 95562, 95564, 95565,
    95569, 95570, 95571, 95573, 95589,
  ],
  "Trinity County, California": [
    95527, 95552, 95563, 95595, 96010, 96024, 96041, 96046, 96048, 96052, 96076,
    96091, 96093,
  ],
  "Del Norte County, California": [95531, 95532, 95538, 95543, 95548, 95567],
  "Siskiyou County, California": [
    95568, 96014, 96023, 96025, 96027, 96031, 96032, 96034, 96037, 96038, 96039,
    96044, 96049, 96050, 96057, 96058, 96064, 96067, 96085, 96086, 96094, 96097,
    96134,
  ],
  "Amador County, California": [
    95601, 95629, 95640, 95642, 95654, 95665, 95666, 95669, 95675, 95685, 95689,
    95699,
  ],
  "Placer County, California": [
    95602, 95603, 95604, 95631, 95648, 95650, 95658, 95661, 95663, 95677, 95678,
    95681, 95701, 95703, 95713, 95714, 95715, 95717, 95722, 95724, 95728, 95736,
    95746, 95747, 95765, 96140, 96141, 96143, 96145, 96146, 96148, 96162,
  ],
  "Yolo County, California": [
    95605, 95606, 95607, 95612, 95616, 95617, 95618, 95627, 95637, 95645, 95653,
    95679, 95691, 95694, 95695, 95697, 95698, 95776, 95798, 95799, 95937,
  ],
  "El Dorado County, California": [
    95613, 95614, 95619, 95623, 95633, 95634, 95635, 95636, 95644, 95651, 95656,
    95664, 95667, 95672, 95682, 95684, 95709, 95720, 95721, 95726, 95735, 95762,
    96142, 96150, 96151, 96152, 96154, 96155, 96157, 96158,
  ],
  "Alpine County, California": [95646, 96120, 96156],
  "Sutter County, California": [
    95659, 95668, 95674, 95676, 95953, 95957, 95982, 95991, 95992, 95993,
  ],
  "Yuba County, California": [
    95692, 95901, 95903, 95918, 95919, 95922, 95925, 95935, 95961, 95962, 95972,
  ],
  "Nevada County, California": [
    95712, 95924, 95945, 95946, 95949, 95959, 95960, 95975, 95977, 95986, 96111,
    96160, 96161,
  ],
  "Sierra County, California": [
    95910, 95936, 95944, 96118, 96124, 96125, 96126,
  ],
  "Colusa County, California": [
    95912, 95932, 95950, 95955, 95970, 95979, 95987,
  ],
  "Glenn County, California": [95913, 95920, 95939, 95943, 95951, 95963, 95988],
  "Butte County, California": [
    95914, 95916, 95917, 95926, 95927, 95928, 95929, 95930, 95938, 95940, 95941,
    95942, 95948, 95954, 95958, 95965, 95966, 95967, 95968, 95969, 95973, 95974,
    95976, 95978,
  ],
  "Plumas County, California": [
    95915, 95923, 95934, 95947, 95956, 95971, 95980, 95981, 95983, 95984, 96020,
    96103, 96105, 96106, 96122, 96129, 96135,
  ],
  "Shasta County, California": [
    96001, 96002, 96003, 96007, 96008, 96011, 96013, 96016, 96017, 96019, 96028,
    96033, 96040, 96047, 96051, 96056, 96062, 96065, 96069, 96070, 96071, 96073,
    96079, 96084, 96087, 96088, 96089, 96095, 96096, 96099,
  ],
  "Modoc County, California": [
    96006, 96015, 96054, 96101, 96104, 96108, 96110, 96112, 96115, 96116,
  ],
  "Lassen County, California": [
    96009, 96068, 96109, 96113, 96114, 96117, 96119, 96121, 96123, 96127, 96128,
    96130, 96132, 96136, 96137,
  ],
  "Tehama County, California": [
    96021, 96022, 96029, 96035, 96055, 96059, 96061, 96063, 96074, 96075, 96078,
    96080, 96090, 96092,
  ],
  "Honolulu County, Hawaii": [
    96701, 96706, 96707, 96709, 96712, 96717, 96730, 96731, 96734, 96744, 96759,
    96762, 96782, 96786, 96789, 96791, 96792, 96795, 96797, 96801, 96802, 96803,
    96804, 96805, 96806, 96807, 96808, 96809, 96810, 96811, 96812, 96813, 96814,
    96815, 96816, 96817, 96818, 96819, 96820, 96821, 96822, 96823, 96824, 96825,
    96826, 96827, 96828, 96830, 96835, 96836, 96837, 96838, 96839, 96840, 96841,
    96843, 96844, 96846, 96847, 96848, 96849, 96850, 96853, 96854, 96857, 96858,
    96859, 96860, 96861, 96863, 96898,
  ],
  "Kauai County, Hawaii": [
    96703, 96705, 96714, 96715, 96716, 96722, 96741, 96746, 96747, 96751, 96752,
    96754, 96756, 96765, 96766, 96769, 96796,
  ],
  "Hawaii County, Hawaii": [
    96704, 96710, 96718, 96719, 96720, 96721, 96725, 96726, 96727, 96728, 96737,
    96738, 96739, 96740, 96743, 96745, 96749, 96750, 96755, 96760, 96764, 96771,
    96772, 96773, 96774, 96776, 96777, 96778, 96780, 96781, 96783, 96785,
  ],
  "Maui County, Hawaii": [
    96708, 96713, 96729, 96732, 96733, 96748, 96753, 96757, 96761, 96763, 96767,
    96768, 96770, 96779, 96784, 96788, 96790, 96793,
  ],
  "Kalawao County, Hawaii": [96742],
  "Wasco County, Oregon": [97001, 97021, 97037, 97040, 97057, 97058, 97063],
  "Marion County, Oregon": [
    97002, 97020, 97026, 97032, 97071, 97137, 97301, 97302, 97303, 97305, 97306,
    97307, 97308, 97309, 97310, 97311, 97312, 97313, 97314, 97317, 97325, 97342,
    97346, 97350, 97352, 97362, 97373, 97375, 97381, 97383, 97384, 97385, 97392,
  ],
  "Washington County, Oregon": [
    97003, 97005, 97006, 97007, 97008, 97062, 97075, 97076, 97077, 97078, 97106,
    97109, 97113, 97116, 97117, 97119, 97123, 97124, 97125, 97133, 97140, 97144,
    97223, 97224, 97225, 97229,
  ],
  "Clackamas County, Oregon": [
    97004, 97009, 97011, 97013, 97015, 97017, 97022, 97023, 97027, 97028, 97034,
    97035, 97036, 97038, 97042, 97045, 97049, 97055, 97067, 97068, 97070, 97086,
    97089, 97222, 97267,
  ],
  "Multnomah County, Oregon": [
    97010, 97019, 97024, 97030, 97060, 97080, 97201, 97202, 97203, 97204, 97205,
    97206, 97207, 97208, 97209, 97210, 97211, 97212, 97213, 97214, 97215, 97216,
    97217, 97218, 97219, 97220, 97221, 97227, 97228, 97230, 97231, 97232, 97233,
    97236, 97238, 97239, 97240, 97242, 97251, 97253, 97254, 97255, 97256, 97258,
    97259, 97266, 97268, 97269, 97271, 97272, 97280, 97281, 97282, 97283, 97286,
    97290, 97291, 97292, 97293, 97294, 97296, 97298, 97299,
  ],
  "Hood River County, Oregon": [97014, 97031, 97041, 97044],
  "Columbia County, Oregon": [
    97016, 97018, 97048, 97051, 97053, 97054, 97056, 97064,
  ],
  "Sherman County, Oregon": [97029, 97033, 97039, 97050, 97065],
  "undefined, Oregon": [97079, 97129, 97250, 97252, 97475],
  "Yamhill County, Oregon": [
    97101, 97111, 97114, 97115, 97127, 97128, 97132, 97148, 97378, 97396,
  ],
  "Clatsop County, Oregon": [97102, 97103, 97110, 97121, 97138, 97145, 97146],
  "Tillamook County, Oregon": [
    97107, 97108, 97112, 97118, 97122, 97130, 97131, 97134, 97135, 97136, 97141,
    97143, 97147, 97149,
  ],
  "Polk County, Oregon": [97304, 97338, 97344, 97347, 97351, 97361, 97371],
  "Linn County, Oregon": [
    97321, 97322, 97327, 97329, 97335, 97336, 97345, 97348, 97355, 97358, 97360,
    97374, 97377, 97386, 97389, 97446,
  ],
  "Benton County, Oregon": [
    97324, 97326, 97330, 97331, 97333, 97339, 97370, 97456,
  ],
  "Lincoln County, Oregon": [
    97341, 97343, 97357, 97364, 97365, 97366, 97367, 97368, 97369, 97372, 97376,
    97380, 97388, 97390, 97391, 97394, 97498,
  ],
  "Lane County, Oregon": [
    97401, 97402, 97403, 97404, 97405, 97408, 97409, 97412, 97413, 97419, 97424,
    97426, 97427, 97430, 97431, 97434, 97437, 97438, 97439, 97440, 97448, 97451,
    97452, 97453, 97454, 97455, 97461, 97463, 97472, 97477, 97478, 97480, 97482,
    97487, 97488, 97489, 97490, 97492, 97493,
  ],
  "Curry County, Oregon": [
    97406, 97415, 97444, 97450, 97464, 97465, 97476, 97491,
  ],
  "Coos County, Oregon": [
    97407, 97411, 97414, 97420, 97423, 97449, 97458, 97459, 97466,
  ],
  "Douglas County, Oregon": [
    97410, 97416, 97417, 97428, 97429, 97432, 97435, 97436, 97441, 97442, 97443,
    97447, 97457, 97462, 97467, 97469, 97470, 97471, 97473, 97479, 97481, 97484,
    97486, 97494, 97495, 97496, 97499,
  ],
  "Klamath County, Oregon": [
    97425, 97601, 97602, 97603, 97604, 97621, 97622, 97623, 97624, 97625, 97626,
    97627, 97632, 97633, 97634, 97639, 97731, 97733, 97737,
  ],
  "Josephine County, Oregon": [
    97497, 97523, 97526, 97527, 97528, 97531, 97532, 97533, 97534, 97538, 97543,
    97544,
  ],
  "Jackson County, Oregon": [
    97501, 97502, 97503, 97504, 97520, 97522, 97524, 97525, 97530, 97535, 97536,
    97537, 97539, 97540, 97541,
  ],
  "Lake County, Oregon": [
    97620, 97630, 97636, 97637, 97638, 97640, 97641, 97735,
  ],
  "Modoc County, Oregon": [97635],
  "Deschutes County, Oregon": [
    97701, 97702, 97703, 97707, 97708, 97709, 97712, 97739, 97756, 97759,
  ],
  "Harney County, Oregon": [
    97710, 97720, 97721, 97722, 97732, 97736, 97738, 97758, 97904,
  ],
  "Jefferson County, Oregon": [97711, 97730, 97734, 97741, 97760, 97761],
  "Wheeler County, Oregon": [97750, 97830, 97874],
  "Crook County, Oregon": [97751, 97752, 97753, 97754],
  "Umatilla County, Oregon": [
    97801, 97810, 97813, 97826, 97835, 97838, 97859, 97862, 97868, 97875, 97880,
    97882, 97886,
  ],
  "Gilliam County, Oregon": [97812, 97823, 97861],
  "Baker County, Oregon": [
    97814, 97819, 97833, 97834, 97837, 97840, 97870, 97877, 97884, 97905, 97907,
  ],
  "Grant County, Oregon": [
    97817, 97820, 97825, 97845, 97848, 97856, 97864, 97865, 97869, 97873,
  ],
  "Morrow County, Oregon": [97818, 97836, 97839, 97843, 97844],
  "Union County, Oregon": [97824, 97827, 97841, 97850, 97867, 97876, 97883],
  "Wallowa County, Oregon": [97828, 97842, 97846, 97857, 97885],
  "Malheur County, Oregon": [
    97901, 97902, 97903, 97906, 97908, 97909, 97910, 97911, 97913, 97914, 97917,
    97918, 97920,
  ],
  "King County, Washington": [
    98001, 98002, 98003, 98004, 98005, 98006, 98007, 98008, 98009, 98010, 98011,
    98013, 98014, 98015, 98019, 98022, 98023, 98024, 98025, 98027, 98028, 98029,
    98030, 98031, 98032, 98033, 98034, 98035, 98038, 98039, 98040, 98041, 98042,
    98045, 98047, 98050, 98051, 98052, 98053, 98054, 98055, 98056, 98057, 98058,
    98059, 98062, 98063, 98064, 98065, 98070, 98071, 98072, 98073, 98074, 98075,
    98077, 98083, 98089, 98092, 98093, 98101, 98102, 98103, 98104, 98105, 98106,
    98107, 98108, 98109, 98111, 98112, 98113, 98114, 98115, 98116, 98117, 98118,
    98119, 98121, 98122, 98124, 98125, 98126, 98127, 98129, 98131, 98132, 98133,
    98134, 98136, 98138, 98139, 98141, 98144, 98145, 98146, 98148, 98151, 98154,
    98155, 98158, 98160, 98161, 98164, 98165, 98166, 98168, 98170, 98171, 98174,
    98175, 98177, 98178, 98181, 98184, 98185, 98188, 98190, 98191, 98194, 98195,
    98198, 98199, 98224, 98288,
  ],
  "Snohomish County, Washington": [
    98012, 98020, 98021, 98026, 98036, 98037, 98043, 98046, 98087, 98201, 98203,
    98204, 98205, 98206, 98207, 98208, 98213, 98223, 98241, 98251, 98252, 98256,
    98258, 98259, 98270, 98271, 98272, 98275, 98287, 98290, 98291, 98292, 98293,
    98294, 98296,
  ],
  "Kitsap County, Washington": [
    98061, 98110, 98310, 98311, 98312, 98314, 98315, 98322, 98337, 98340, 98342,
    98345, 98346, 98353, 98359, 98364, 98366, 98367, 98370, 98378, 98380, 98383,
    98384, 98386, 98392, 98393,
  ],
  "Kittitas County, Washington": [
    98068, 98922, 98925, 98926, 98934, 98940, 98941, 98943, 98946, 98950,
  ],
  "undefined, Washington": [98082, 98189],
  "Whatcom County, Washington": [
    98220, 98225, 98226, 98227, 98228, 98229, 98230, 98231, 98240, 98244, 98247,
    98248, 98262, 98264, 98266, 98276, 98281, 98295,
  ],
  "Skagit County, Washington": [
    98221, 98232, 98233, 98235, 98237, 98238, 98255, 98257, 98263, 98267, 98273,
    98274, 98283, 98284,
  ],
  "San Juan County, Washington": [
    98222, 98243, 98245, 98250, 98261, 98279, 98280, 98286, 98297,
  ],
  "Island County, Washington": [
    98236, 98239, 98249, 98253, 98260, 98277, 98278, 98282,
  ],
  "Pierce County, Washington": [
    98303, 98304, 98321, 98323, 98327, 98328, 98329, 98330, 98332, 98333, 98335,
    98338, 98344, 98349, 98351, 98352, 98354, 98360, 98371, 98372, 98373, 98374,
    98375, 98385, 98387, 98388, 98390, 98391, 98394, 98395, 98396, 98397, 98401,
    98402, 98403, 98404, 98405, 98406, 98407, 98408, 98409, 98411, 98412, 98413,
    98415, 98416, 98417, 98418, 98419, 98421, 98422, 98424, 98430, 98431, 98433,
    98438, 98439, 98442, 98443, 98444, 98445, 98446, 98447, 98448, 98450, 98455,
    98460, 98464, 98465, 98466, 98467, 98471, 98477, 98481, 98490, 98492, 98493,
    98496, 98497, 98498, 98499, 98558, 98580,
  ],
  "Clallam County, Washington": [
    98305, 98324, 98326, 98331, 98343, 98350, 98357, 98362, 98363, 98381, 98382,
  ],
  "Jefferson County, Washington": [
    98320, 98325, 98339, 98358, 98365, 98368, 98376,
  ],
  "Lewis County, Washington": [
    98336, 98355, 98356, 98361, 98377, 98398, 98522, 98531, 98532, 98533, 98538,
    98539, 98542, 98544, 98564, 98565, 98570, 98572, 98582, 98585, 98591, 98593,
    98596,
  ],
  "Thurston County, Washington": [
    98348, 98501, 98502, 98503, 98504, 98505, 98506, 98507, 98508, 98509, 98511,
    98512, 98513, 98516, 98530, 98540, 98556, 98576, 98579, 98589, 98597, 98599,
  ],
  "Grays Harbor County, Washington": [
    98520, 98526, 98535, 98536, 98537, 98541, 98547, 98550, 98552, 98557, 98559,
    98562, 98563, 98566, 98568, 98569, 98571, 98575, 98583, 98587, 98595,
  ],
  "Mason County, Washington": [
    98524, 98528, 98546, 98548, 98555, 98560, 98584, 98588, 98592,
  ],
  "Pacific County, Washington": [
    98527, 98554, 98561, 98577, 98586, 98590, 98614, 98624, 98631, 98637, 98638,
    98640, 98641, 98644,
  ],
  "Cowlitz County, Washington": [
    98581, 98603, 98609, 98611, 98616, 98625, 98626, 98632, 98645, 98649, 98674,
  ],
  "Clark County, Washington": [
    98601, 98604, 98606, 98607, 98622, 98629, 98642, 98660, 98661, 98662, 98663,
    98664, 98665, 98666, 98667, 98668, 98671, 98675, 98682, 98683, 98684, 98685,
    98686, 98687,
  ],
  "Klickitat County, Washington": [
    98602, 98605, 98613, 98617, 98619, 98620, 98623, 98628, 98635, 98650, 98670,
    98672, 98673, 99322, 99356,
  ],
  "Skamania County, Washington": [98610, 98639, 98648, 98651],
  "Wahkiakum County, Washington": [98612, 98621, 98643, 98647],
  "Chelan County, Washington": [
    98801, 98807, 98811, 98815, 98816, 98817, 98821, 98822, 98826, 98828, 98831,
    98836, 98847, 98852,
  ],
  "Douglas County, Washington": [
    98802, 98813, 98830, 98843, 98845, 98850, 98858,
  ],
  "Okanogan County, Washington": [
    98812, 98814, 98819, 98827, 98829, 98833, 98834, 98840, 98841, 98844, 98846,
    98849, 98855, 98856, 98859, 98862, 99116, 99124, 99155,
  ],
  "Grant County, Washington": [
    98823, 98824, 98832, 98837, 98848, 98851, 98853, 98857, 98860, 99115, 99123,
    99133, 99135, 99321, 99349, 99357,
  ],
  "Yakima County, Washington": [
    98901, 98902, 98903, 98904, 98907, 98908, 98909, 98920, 98921, 98923, 98929,
    98930, 98932, 98933, 98935, 98936, 98937, 98938, 98939, 98942, 98944, 98947,
    98948, 98951, 98952, 98953,
  ],
  "Spokane County, Washington": [
    99001, 99003, 99004, 99005, 99006, 99009, 99011, 99012, 99014, 99016, 99018,
    99019, 99020, 99021, 99022, 99023, 99025, 99027, 99030, 99031, 99036, 99037,
    99039, 99201, 99202, 99203, 99204, 99205, 99206, 99207, 99208, 99209, 99210,
    99211, 99212, 99213, 99214, 99215, 99216, 99217, 99218, 99219, 99220, 99223,
    99224, 99228, 99251, 99252, 99256, 99258, 99260, 99299,
  ],
  "Lincoln County, Washington": [
    99008, 99029, 99032, 99103, 99117, 99122, 99134, 99144, 99147, 99154, 99159,
    99185,
  ],
  "Stevens County, Washington": [
    99013, 99026, 99034, 99040, 99101, 99109, 99110, 99114, 99126, 99129, 99131,
    99137, 99141, 99148, 99151, 99157, 99167, 99173, 99181,
  ],
  "Whitman County, Washington": [
    99017, 99033, 99102, 99104, 99111, 99113, 99125, 99128, 99130, 99136, 99143,
    99149, 99158, 99161, 99163, 99164, 99165, 99170, 99171, 99174, 99176, 99179,
    99333,
  ],
  "Adams County, Washington": [99105, 99169, 99341, 99344, 99371],
  "Ferry County, Washington": [
    99107, 99118, 99121, 99138, 99140, 99146, 99150, 99160, 99166,
  ],
  "Pend Oreille County, Washington": [99119, 99139, 99152, 99153, 99156, 99180],
  "Franklin County, Washington": [99301, 99302, 99326, 99330, 99335, 99343],
  "Benton County, Washington": [
    99320, 99336, 99337, 99338, 99345, 99346, 99350, 99352, 99353, 99354,
  ],
  "Walla Walla County, Washington": [
    99323, 99324, 99329, 99348, 99360, 99361, 99362, 99363,
  ],
  "Columbia County, Washington": [99328, 99359],
  "Garfield County, Washington": [99347],
  "Asotin County, Washington": [99401, 99402, 99403],
  "Anchorage Municipality, Alaska": [
    99501, 99502, 99503, 99504, 99505, 99506, 99507, 99508, 99513, 99515, 99516,
    99517, 99518, 99530, 99540, 99567, 99577, 99587,
  ],
  "Municipality of Anchorage, Alaska": [
    99509, 99511, 99514, 99520, 99521, 99522, 99523, 99524, 99529, 99599,
  ],
  "North Slope Borough, Alaska": [
    99510, 99519, 99721, 99723, 99734, 99747, 99759, 99766, 99782, 99789, 99791,
  ],
  "Bethel Census Area, Alaska": [
    99545, 99551, 99552, 99557, 99559, 99561, 99575, 99578, 99589, 99607, 99609,
    99614, 99621, 99622, 99626, 99630, 99634, 99637, 99641, 99651, 99655, 99656,
    99668, 99679, 99680, 99681, 99690,
  ],
  "Aleutians West Census Area, Alaska": [
    99546, 99547, 99591, 99638, 99660, 99685, 99692,
  ],
  "Lake and Peninsula Borough, Alaska": [
    99548, 99549, 99564, 99565, 99579, 99606, 99625, 99640, 99647, 99648, 99649,
    99653, 99695,
  ],
  "Kodiak Island Borough, Alaska": [
    99550, 99608, 99615, 99619, 99624, 99643, 99644, 99697,
  ],
  "Aleutians East Borough, Alaska": [99553, 99571, 99583, 99612, 99661],
  "Kusilvak Census Area, Alaska": [
    99554, 99563, 99581, 99585, 99604, 99620, 99632, 99650, 99657, 99658, 99662,
    99666,
  ],
  "Dillingham Census Area, Alaska": [
    99555, 99569, 99576, 99580, 99628, 99636, 99678,
  ],
  "Kenai Peninsula Borough, Alaska": [
    99556, 99568, 99572, 99603, 99605, 99610, 99611, 99631, 99635, 99639, 99663,
    99664, 99669, 99672, 99682,
  ],
  "Yukon-Koyukuk Census Area, Alaska": [
    99558, 99590, 99602, 99627, 99665, 99675, 99691, 99720, 99722, 99724, 99726,
    99730, 99733, 99740, 99741, 99745, 99746, 99748, 99754, 99756, 99757, 99758,
    99760, 99765, 99767, 99768, 99774, 99777, 99781, 99788, 99790,
  ],
  "Copper River Census Area, Alaska": [99566, 99573, 99586, 99588],
  "Chugach Census Area, Alaska": [99574, 99677, 99686, 99693],
  "Bristol Bay Borough, Alaska": [99613, 99633, 99670],
  "Matanuska-Susitna Borough, Alaska": [
    99623, 99629, 99645, 99652, 99654, 99667, 99674, 99676, 99683, 99687, 99688,
    99694,
  ],
  "Nome Census Area, Alaska": [
    99659, 99671, 99684, 99739, 99742, 99753, 99762, 99769, 99771, 99772, 99778,
    99783, 99784, 99785,
  ],
  "Yakutat City and Borough, Alaska": [99689],
  "Fairbanks North Star Borough, Alaska": [
    99701, 99702, 99703, 99705, 99707, 99708, 99709, 99710, 99711, 99712, 99714,
    99716, 99725, 99775,
  ],
  "Denali Borough, Alaska": [99704, 99729, 99743, 99744, 99755],
  "Southeast Fairbanks Census Area, Alaska": [
    99706, 99731, 99732, 99737, 99738, 99764, 99776, 99779, 99780,
  ],
  "Northwest Arctic Borough, Alaska": [
    99727, 99736, 99749, 99750, 99751, 99752, 99761, 99763, 99770, 99773, 99786,
  ],
  "Juneau City and Borough, Alaska": [99801, 99824],
  "City and Borough of Juneau, Alaska": [99802, 99803, 99811, 99812, 99821],
  "Hoonah-Angoon Census Area, Alaska": [
    99820, 99825, 99826, 99829, 99832, 99841,
  ],
  "Haines Borough, Alaska": [99827, 99850],
  "Prince of Wales-Hyder Census Area, Alaska": [
    99830, 99836, 99918, 99919, 99921, 99922, 99923, 99925, 99926, 99927,
  ],
  "Petersburg Borough, Alaska": [99833],
  "Sitka City and Borough, Alaska": [99835],
  "Skagway Municipality, Alaska": [99840],
  "Ketchikan Gateway Borough, Alaska": [99901, 99928],
  "Wrangell City and Borough, Alaska": [99903, 99929],
  "Prince of Wales-Outer Ketchikan Borough, Alaska": [99950],
};
