import { UserData } from "types/user";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CardForm } from "./CardForm";
import { Modal } from "components/Modal";
import { useTheme } from "@emotion/react";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { toast } from "react-toastify";
import { getStripePromise } from "lib/helpers/getStripePromise";

type Props = {
  user: Partial<UserData>;
  onClose: () => void;
  showEditModal: boolean;
  setCardUpdating: (cardDigits: string) => void;
};

const stripePromise = getStripePromise();
export const EditCard = ({
  user,
  onClose,
  showEditModal,
  setCardUpdating,
}: Props) => {
  const theme = useTheme();
  const options = {
    fonts: [
      {
        family: "Lato",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap",
      },
    ],
    // Fully customizable with appearance API.
    appearance: {
      variables: {
        fontSizeSm: "16px",
        fontFamily: "Lato",
      },
    },
  } as StripeElementsOptions;

  const handleSuccess = () => {
    setCardUpdating(user?.paymentMethod?.last4!);
    onClose();
    toast.success("Updating card...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <Modal
      isOpen={showEditModal}
      contentStyle={{
        maxWidth: 330,
        background: "white",
        width: "100%",
        padding: 0,
      }}
    >
      <Flex css={{ padding: "14px 24px" }} justify="space-between">
        <Text large bolder>
          Edit Card
        </Text>
        <Flex
          justify="center"
          align="center"
          css={{
            borderRadius: theme.radii.smaller,
            background: theme.colors.lightBg,
            height: 30,
            width: 30,
            cursor: "pointer",
          }}
          onClick={() => onClose()}
        >
          <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
        </Flex>
      </Flex>
      <div
        css={{
          borderBottom: `1px solid ${theme.colors.radioBorder}`,
        }}
      />
      <Flex gap={25} direction="column" css={{ padding: "28px 24px" }}>
        <Elements options={options} stripe={stripePromise as any}>
          <CardForm onSuccess={handleSuccess} user={user} />
        </Elements>
      </Flex>
    </Modal>
  );
};
