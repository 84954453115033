import { doc, FirestoreError, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export const useFirestoreSubscribe = <T>(path: string, enabled?: boolean) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FirestoreError | null>(null);

  const fetchDocument = async () => {
    try {
      const docRef = doc(db, path);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData({ id: docSnap.id, ...docSnap.data() } as T & { id: string });
      } else {
        throw new Error("[FIREBASE] - No document found at the path");
      }
    } catch (err) {
      // log
      if (path.includes("UeLBbZDmzlResZGdMq6RE3QDhxt1")) {
        console.log("[SIGN-IN LOG] Nick get data static Failed");
      }
      //
      console.error("[FIREBASE] - Fallback document fetch failed", err);
      Sentry.captureException(err, {
        tags: {
          keyword: "[FIREBASE------] Fallback Fetch Error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enabled) {
      setLoading(true);
      const unsubscribe = onSnapshot(
        doc(db, path),
        (doc) => {
          let docData;
          try {
            docData = doc.data();
            if (docData) {
              setData({ id: doc.id, ...docData } as T & { id: string });
            } else {
              throw new Error("[FIREBASE] - Error Fetching User Details");
            }
          } catch (error) {
            // log
            if (path.includes("UeLBbZDmzlResZGdMq6RE3QDhxt1")) {
              console.log("[SIGN-IN LOG] Firebase snapshot Failed", error);
            }
            //
            console.error("[FIREBASE] - User fetch error", {
              responseData: docData,
              docData: doc.data(),
            });
            Sentry.captureException(error, {
              tags: {
                keyword:
                  "[FIREBASE------] Error Fetching User Details - Description",
              },
            });
            setError(error as FirestoreError);
            fetchDocument(); // Fallback to manual fetch if snapshot fails
          } finally {
            setLoading(false);
          }
        },
        (error: FirestoreError) => {
          // log
          if (path.includes("UeLBbZDmzlResZGdMq6RE3QDhxt1")) {
            console.log("[SIGN-IN LOG] Firebase snapshot Failed", error);
          }
          //
          console.error("[FIREBASE] - snapshot listener failed", error);
          Sentry.captureException(error, {
            tags: {
              keyword: "[FIREBASE------] Snapshot Listener Failed",
            },
          });
          setError(error);
          fetchDocument(); // Fallback to manual fetch if snapshot fails
          setLoading(false);
        },
      );
      return () => {
        unsubscribe();
      };
    }
  }, [path, enabled]);

  return { data, loading, error, setData };
};
