import styled from "@emotion/styled";
import theme from "../../lib/theme";

export const CheckboxInput = styled.input`
  appearance: none;
  outline: none;
  margin: 0;
  position: relative;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: background-color 0.2s ease;

  &:checked {
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }

  &:checked::after {
    content: "\\2713";
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`;
