import { Price } from "backend/types/stripe.type";
import { createContext, useState } from "react";
import { useLocalStorage } from "react-use";

type TPlanModalContext = {
  showPlanModal: boolean;
  setShowPlanModal: (value: boolean) => void;
  planPopupTrigger: string | null | undefined;
  setPlanPopupTrigger: (value: string) => void;
  plans: Price[];
  setPlans: (value: Price[]) => void;
};

export const PlanModalContext = createContext<TPlanModalContext>({
  setShowPlanModal: (_v) => {},
  showPlanModal: false,
  planPopupTrigger: null,
  setPlanPopupTrigger: (_v) => {},
  plans: [],
  setPlans: (_v) => {},
});

export const PlanModalProvider = ({ children }) => {
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [plans, setPlans] = useState<Price[]>([]);
  const [planPopupTrigger, setPlanPopupTrigger] = useLocalStorage<
    string | undefined | null
  >("lastPlanPopupTriggeredFrom", null);
  return (
    <PlanModalContext.Provider
      value={{
        showPlanModal,
        setShowPlanModal,
        plans,
        setPlans,
        planPopupTrigger,
        setPlanPopupTrigger,
      }}
    >
      {children}
    </PlanModalContext.Provider>
  );
};
