import { Geo } from "types/MapContext";

export const excludedFilters: Record<
  string,
  { value: string; label: string }[]
> = {
  [Geo.STATE]: [{ label: "state_code", value: "state_code" }],
  [Geo.METRO]: [],
  [Geo.COUNTY]: [],
  [Geo.ZIP]: [
    { value: "state_code", label: "state_code" },
    { value: "percent_crash_great_recession", label: "% Crash from 2007-12" },
    { value: "rent_for_apartments", label: "Rental Rate" },
    { value: "rent_for_houses", label: "Rent For Houses" },
    { value: "buy_versus_rent_percentage", label: "Buy v Rent Differential" },
    { value: "cap_rate", label: "Cap Rate" },
    { value: "rent_percentage_of_income", label: "Rent as % of Income" },
  ],
};
