import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import {
  getQueryCodeMap,
  getQueryList,
  getScoreQueryCodeMap,
} from "lib/cube/utils";
import { countyZipMap } from "lib/options/countyZipMap";
import metroZipMap from "lib/options/metroZipMap";
import { stateZipMap } from "lib/options/stateZipMap";
import { mapKeys } from "lodash";
import { createContext, useContext, useMemo } from "react";
import { Geo } from "types/MapContext";
import { Cube, CurrentDataPoints, RawCurrentDataPoints } from "types/cube";
import useGeoSearch from "./useGeoSearch";
import { useUserData } from "./useUser";
import { useRouter } from "next/router";
import { stateCoordinates } from "lib/constants";

interface MapData {
  isCurrentLoading: boolean;
  currentData: CurrentDataPoints[];
}

const MapDataContext = createContext<MapData | undefined>(undefined);

const createQuery = (
    geo: Geo,
    additionalFilters: Query["filters"] = [],
    skipPremium?: boolean,
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getQueryList("current", skipPremium),
    ...getQueryList("score-current", skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

export const MapDataProvider = ({ children }) => {
  const {
    geo,
    selectedFeature,
    searchedGeo,
    filteredMetros,
    filteredCounties,
    setZoom,
    setLat,
    setLong,
    setSearchId,
    searchZipsViaState,
  } = useGeoSearch();
  const user = useUserData();

  const router = useRouter();

  const filterCodes = (): number[] => {
    if (searchedGeo === Geo.STATE && selectedFeature) {
      return stateZipMap[selectedFeature.text] || [];
    }

    if (searchedGeo === Geo.COUNTY && filteredCounties.length) {
      return filteredCounties
          .map((county) => countyZipMap[county.label])
          .flat();
    }

    if (searchedGeo === Geo.ZIP || !searchedGeo) {
      const zips: number[] = [];

      filteredMetros.forEach((metro) => {
        if (metroZipMap[metro.value]) {
          zips.push(...metroZipMap[metro.value]);
        }
      });

      filteredCounties.forEach((county) => {
        if (countyZipMap[county.label]) {
          zips.push(...countyZipMap[county.label]);
        }
      });

      return zips;
    }

    return [];
  };
  const filterZipCodes = (list) => {
    return list.map((code) => {
      let stringifiedCode = code.toString();
      if (stringifiedCode.length == 3) stringifiedCode = "00" + stringifiedCode;
      if (stringifiedCode.length == 4) stringifiedCode = "0" + stringifiedCode;
      return stringifiedCode;
    });
  };

  const filteredZips = useMemo(() => {
    if (geo !== Geo.ZIP) return [];
    const zips =
        searchZipsViaState !== "All States"
            ? stateZipMap[searchZipsViaState].slice(0, 3000)
            : filterCodes();
    return filterZipCodes(zips);
  }, [selectedFeature, geo, filteredMetros, searchZipsViaState]);

  const cubeQuery = useMemo(() => {
    return createQuery(
        geo,
        filteredZips?.length
            ? [
              {
                member: "geo_data.geo_code",
                operator: "equals",
                values: filteredZips,
              },
            ]
            : [],
        !user.isPremiumOrBasic,
    );
  }, [geo, filteredZips, user.isPremiumOrBasic]);

  const shouldReturnData = !(geo === Geo.ZIP) || !!filteredZips?.length;

  const { isLoading: isCurrentLoading, resultSet: currentResultSet } =
      useCubeQuery<RawCurrentDataPoints>(cubeQuery, {
        skip: !shouldReturnData || router.pathname.includes("/report-pdf"),
        resetResultSetOnChange: true,
        castNumerics: true,
      });

  const currentData = useMemo(() => {
    if (!shouldReturnData) return [];
    if (searchZipsViaState !== "All States" && geo === Geo.ZIP) {
      const zipMetro = stateCoordinates?.find(
          (item) => item.name === searchZipsViaState,
      );
      if (zipMetro) {
        setZoom(6);
        setLat(zipMetro.lat);
        setLong(zipMetro.lon);
        setSearchId({
          lat: zipMetro.lat,
          long: zipMetro.lon,
          zoom: 6,
        });
      }
    }

    const queryCodeMap = getQueryCodeMap("current");
    const queryScoreCodeMap = getScoreQueryCodeMap();
    return (
        currentResultSet?.rawData()?.map((item) => {
          const areaData = mapKeys(item, (v, k) => {
            return queryCodeMap[k] || queryScoreCodeMap[k] || k.split(".")[1];
          });
          areaData.geo = geo;
          return areaData;
        }) || []
    );
  }, [currentResultSet, shouldReturnData]) as unknown as CurrentDataPoints[];

  return (
      <MapDataContext.Provider
          value={{
            currentData,
            isCurrentLoading,
          }}
      >
        {children}
      </MapDataContext.Provider>
  );
};

const useMapData = () => {
  const values = useContext(MapDataContext);

  if (!values) {
    throw new Error("Cannot use MapData outside MapDataContext");
  }

  return values;
};

export default useMapData;
