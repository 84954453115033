import {ITransport} from "@cubejs-client/core";
import fetch from "cross-fetch";
import "url-search-params-polyfill";

interface HttpTransportOptions {
  apiUrl: string;
  credentials?: "omit" | "same-origin" | "include";
  headers?: Record<string, string>;
  method?: "GET" | "POST";
  authorization?: string;
}

class HttpTransport implements ITransport<any> {
  private apiUrl: string;
  private headers: Record<string, string>;
  private credentials?: "omit" | "same-origin" | "include";
  private method?: "GET" | "POST";
  private authorization?: string;

  constructor({
    authorization,
    apiUrl,
    method,
    headers = {},
    credentials,
  }: HttpTransportOptions) {
    this.authorization = authorization;
    this.apiUrl = apiUrl;
    this.method = method;
    this.headers = headers;
    this.credentials = credentials;
  }

  request(method, { baseRequestId, ...params }) {
    let spanCounter = 1;
    const searchParams = new URLSearchParams(
      params &&
        Object.keys(params)
          .map((k) => ({
            [k]:
              typeof params[k] === "object"
                ? JSON.stringify(params[k])
                : params[k],
          }))
          .reduce((a, b) => ({ ...a, ...b }), {}),
    );

    let url = `${this.apiUrl}/${method}${
      searchParams.toString().length ? `?${searchParams}` : ""
    }`;

    const requestMethod = this.method || (url.length < 2000 ? "GET" : "POST");
    if (requestMethod === "POST") {
      url = `${this.apiUrl}/${method}`;
      this.headers["Content-Type"] = "application/json";
    }

    if (this.authorization) {
      this.headers.Authorization = this.authorization;
    }

    // Currently, all methods make GET requests. If a method makes a request with a body payload,
    // remember to add {'Content-Type': 'application/json'} to the header.
    const runRequest = () =>
      fetch(url, {
        method: requestMethod,
        headers: {
          "x-request-id":
            baseRequestId && `${baseRequestId}-span-${spanCounter++}`,
          ...this.headers,
        },
        credentials: this.credentials,
        body: requestMethod === "POST" ? JSON.stringify(params) : null,
      });

    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    return {
      /* eslint no-unsafe-finally: off */
      async subscribe(callback) {
        let result = {
          error: "network Error", // add default error message
        };
        let retries = 0;
        let success = false;
        try {
          while (retries < 3 && !success) {
            try {
              // @ts-ignore
              result = await runRequest();
              success = true;
            } catch (error) {
              console.error(error)
              // @ts-ignore
              const isNetworkError = error.message.includes("ERR_FAILED");
              if (retries < 2) {
                // Wait for 15 seconds for network errors, otherwise wait for 5 seconds
                await delay(isNetworkError ? 15000 : 5000);
              }
            }
            retries++;
          }
        } finally {}
        return callback(result, () => this.subscribe(callback));
      },
    };
  }
}

export default HttpTransport;
