import { ScoreDatapoints } from "types/cube";
import scoreDatapointsMap, { ScoreDatapointsMap } from "./scoreDatapointsMap";
import { Geo } from "types/MapContext";

export interface ScoreConfig extends ScoreDatapointsMap {
  geo: Geo[];
  extraData?: ScoreDatapointsMap[];
}

const scoreConfigs: ScoreConfig[] = [
  {
    ...scoreDatapointsMap[ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE],
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    extraData: [
      scoreDatapointsMap[ScoreDatapoints.RECENT_APPRECIATION_SCORE],
      scoreDatapointsMap[ScoreDatapoints.DAYS_ON_MARKET_SCORE],
      scoreDatapointsMap[ScoreDatapoints.MORTGAGE_RATES_SCORE],
      scoreDatapointsMap[ScoreDatapoints.INVENTORY_LEVELS_SCORE],
      scoreDatapointsMap[ScoreDatapoints.PRICE_CUTS_SCORE],
    ],
  },
  {
    ...scoreDatapointsMap[ScoreDatapoints.INVESTOR_SCORE],
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    extraData: [
      scoreDatapointsMap[ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.CAP_RATE_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.RENT_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.OVERVALUATION_PERCENTILE],
    ],
  },
  {
    ...scoreDatapointsMap[ScoreDatapoints.HOME_BUYER_SCORE],
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    extraData: [
      scoreDatapointsMap[ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.WEALTH_INCOME_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE],
      scoreDatapointsMap[ScoreDatapoints.OVERVALUATION_PERCENTILE],
    ],
  },
];

export default scoreConfigs;
