export const currencyFormatter = (
  number: number,
  options?: Intl.NumberFormatOptions,
) => {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    ...(options || {}),
  });
};
