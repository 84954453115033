import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Plan, PlanCycle, PlanType } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import Radio from "components/Radio";
import { Text } from "components/Text";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { useUserData } from "hooks/useUser";
import { currencyFormatter } from "lib/currencyFormatter";
import { getRelativePathname } from "lib/getRelativePathname";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";

export const CancelSubscription = () => {
  const user = useUserData();
  const theme = useTheme();
  const { plans } = useContext(PlanModalContext);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(-1);
  const router = useRouter();

  const tailoredPlans = useMemo(() => {
    return plans
      .filter((plan) =>
        [Plan.Premium_Yearly, Plan.Premium_Monthly].includes(plan.name),
      )
      .sort((a, b) => {
        return a.amount - b.amount;
      });
  }, [plans]);

  useEffect(() => {
    tailoredPlans.forEach((plan, index) => {
      if (plan.name === `${user.plan} ${user.planCycle}`) {
        setSelectedPlanIndex(index);
      }
    });
  }, [user.plan, tailoredPlans, router.query.showCancelSubscription]);

  useEffect(() => {
    const plan = tailoredPlans[selectedPlanIndex];
    if (plan && user) {
      const currentPlan = `${user.plan} ${user.planCycle}`;
      if (currentPlan && plan.name !== currentPlan) {
        if (
          user.planCycle === PlanCycle.Monthly ||
          user.plan == PlanType.State_Level_Access
        ) {
          router.push(
            `${getRelativePathname(router)}?selectedPlanId=${
              plan.id
            }&openUpgradeModal=true&previousModal=cancelSubscription`,
          );
        } else if (
            user.planCycle === PlanCycle.Yearly ||
            user.plan == PlanType.Premium
        ) {
          router.replace(
            `${getRelativePathname(router)}?selectedPlanId=${
              plan.id
            }&downgradeConfirmationModal=true&previousModal=cancelSubscription`,
          );
        }
      }
    }
  }, [selectedPlanIndex]);

  const handleClose = () => {
    router.replace(getRelativePathname(router));
  };

  const handleCancel = () => {
    router.replace(
      `${getRelativePathname(router)}?showCheaperSubscription=true`,
    );
  };

  return (
    <Modal
      overlayStyle={{ overflow: "auto", padding: "34px 12px" }}
      contentStyle={{
        maxWidth: 360,
        background: "white",
        width: "100%",
        padding: "15px 24px",
        border: `1px solid ${theme.colors.radioBorder}`,
        overflow: "unset",
      }}
      isOpen={!!router.query.showCancelSubscription}
    >
      <Flex
        justify="center"
        align="center"
        css={{
          borderRadius: theme.radii.smaller,
          background: theme.colors.lightBg,
          height: 30,
          position: "absolute",
          width: 30,
          right: 15,
          top: 15,
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
      </Flex>
      <div
        css={{
          marginTop: -45,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            border: "10px solid white",
            borderRadius: theme.radii.full,
          }}
        >
          <Image
            alt="headshot"
            src="/nick-headshot.png"
            css={{ borderRadius: theme.radii.full }}
            width={96}
            height={96}
          />
        </div>
      </div>
      <Flex direction="column" align="center">
        <h1
          css={{
            fontSize: theme.fontSizes.larger,
            fontWeight: theme.fontWeights.heading,
            margin: 0,
            textAlign: "center",
          }}
        >
          Are you sure you want to cancel your subscription?
        </h1>
        <Text css={{ opacity: 0.5, width: "92%" }} align="center">
          Do you want to explore alternative options instead of cancelling?
        </Text>
      </Flex>
      <Flex gap={18} css={{ marginTop: 20 }} direction="column">
        {tailoredPlans.map((plan, index) => (
          <Flex
            align="center"
            css={{
              borderRadius: theme.radii.smaller,
              border: `1px solid ${theme.colors.radioBorder}`,
              padding: "10px 20px",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedPlanIndex(index);
            }}
            key={plan.id}
            justify="space-between"
          >
            <Flex align="center" gap={21}>
              <div
                css={{
                  "input[type=radio] + .radio-label:before": {
                    width: "28px !important",
                    height: "28px !important",
                  },
                  padding: "6px 0px",
                }}
              >
                <Radio
                  checked={index === selectedPlanIndex}
                  variant="primary"
                  hideBorder
                />
              </div>
              <div
                css={{
                  borderRight: `1px solid ${theme.colors.radioBorder}`,
                  alignSelf: "stretch",
                }}
              />
              <Text bold large>
                {plan.name === Plan.Premium_Monthly ? "Monthly" : "Yearly"}:{" "}
                {currencyFormatter(plan.amount / 100, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Text>
            </Flex>
            {plan.name === `${user.plan} ${user.planCycle}` && (
              <Text
                css={{
                  background: theme.colors.cultured,
                  color: theme.colors.bangladeshGreen,
                  padding: "2px 5px",
                  borderRadius: theme.radii.large,
                }}
                fontSize="small"
              >
                Current
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
      <Button
        onClick={handleCancel}
        css={{ width: "100%", marginTop: 20 }}
        variant="alternate"
      >
        No, Cancel anyways
      </Button>
      <Button
        css={{
          width: "100%",
          marginTop: 8,
          border: `1px solid ${theme.colors.primary}`,
          color: theme.colors.primary,
        }}
        variant="secondary"
        onClick={handleClose}
      >
        Nevermind, I don&apos;t want to cancel
      </Button>
    </Modal>
  );
};
